import React from 'react';
import Tooltip from 'react-tooltip';
import { Button } from 'rebass';
import { FiClipboard } from 'react-icons/fi';

/**
 * Transform list of lists to csv and transfer to clipboard
 *
 * @param {Object} props
 * @param {Array} props.data Array of arrays
 * @param {String} props.id Unique ID for the tooltip
 * @param {String?} props.className Optional class name for the button
 */
export function CSVClipboard({ data, id, className }) {
    /**
     * Data structure:
     * [
     *      ['a', 'b', 'c'],
     *      ['d', 'e', 'f'],
     *      ['g', 'h', 'i']
     * ]
     */
    async function onCopy() {
        const csv = data.map(row => row.join(',')).join('\n');

        try {
            await navigator.clipboard.writeText(csv);
        } catch (error) {
            // eslint-disable-next-line no-console
            console.error('Failed to copy: ', error);
        }
    }

    return (
        <>
            <Button
                variant="secondary-gray"
                data-for={id}
                type="button"
                data-delay-hide="1000"
                data-event="mousedown"
                data-event-off="mouseup"
                onClick={onCopy}
                data-tip="Copied to clipboard!"
                className={className}
            >
                <FiClipboard />
                Copy CSV
            </Button>
            <Tooltip id={id} effect="solid" />
        </>
    );
}

export default CSVClipboard;
