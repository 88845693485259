import React from 'react';
import { Flex, Box } from 'rebass';

export function LoadingAttachedFileStrip({ progress, title, className, fileExtension }) {
    return (
        <Flex
            className={className}
            marginBottom="24px"
            paddingBottom="16px"
            sx={{
                borderBottom: '1px solid #D4D9DD'
            }}
        >
            <Flex
                sx={{
                    borderRadius: '4px',
                    width: '56px',
                    height: '56px',
                    minHeight: '56px',
                    minWidth: '56px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginRight: '12px'
                }}
                backgroundColor="#F6F6F6"
            >
                <Box as="span" fontWeight={500} color="#000">
                    .{fileExtension}
                </Box>
            </Flex>
            <Box flexGrow={1}>
                <Box as="p" color="#000" fontWeight={500} lineHeight="20px" marginBottom="16px" marginTop="10px">
                    {title}
                </Box>
                <Box
                    sx={{
                        width: '345px',
                        height: '2px',
                        background: '#D4D9DD',
                        position: 'relative',

                        '::before': {
                            position: 'absolute',
                            content: '""',
                            top: '0',
                            left: '0',
                            width: `${progress}%`,
                            minWidth: '30%',
                            height: '2px',
                            background: '#0A73EB',
                            transition: 'width 0.5s ease'
                        }
                    }}
                />
            </Box>
        </Flex>
    );
}

export default LoadingAttachedFileStrip;
