import React, { useMemo } from 'react';
import { Box, Button } from 'rebass';
import { Redirect, useParams } from 'react-router-dom';
import { FiPrinter } from 'react-icons/fi';
import { Helmet } from 'react-helmet';
import MetaTags from 'react-meta-tags';

import { ReactFormGenerator } from 'components/ReactFormBuilder';
import helpers from 'utils/helpers';
import PublicScreenPageAdminTools from 'containers/PublicScreenPage/components/PublicScreenPageAdminTools';
import PublicScreenPageFormWrapper from 'containers/PublicScreenPage/components/PublicScreenPageFormWrapper';
import useQuery from 'hooks/useQuery';
import services from 'services/services';
import ScreenerAnswersMetadata from 'components/ScreenerAnswersMetadata';

const PAGE_TITLE = 'Panelist Form';
const PAGE_DESCRIPTION = 'Printable panelist form';

/**
 * ParticipantFormPage
 *
 * @param {object} props
 * @param {object} props.auth
 * @param {boolean} props.auth.isAuthenticated is the user authenticated
 * @param {boolean} props.auth.isFetchingAuth is the user authentication being fetched
 * @param {object} props.auth.account the user's account
 * @param {object} props.auth.account.logo the user's account logo
 * @param {object} props.auth.account.screener_bg the user's account screener background
 */
export function ParticipantFormPage({ auth }) {
    const { participantId, formId } = useParams();
    const { data, isLoading } = useQuery({
        queryFn: variables => services.getFormAnswers(variables.personId, variables.formId),
        variables: {
            personId: participantId,
            formId: formId
        }
    });
    const answerData = useMemo(() => {
        if (!data || !data.answers) return [];

        return JSON.parse(data.answers);
    }, [data]);
    const isNoAnswers = Object.keys(answerData).length === 0;

    if (isLoading) return null;

    if (!data) return <Redirect to="/404" />;

    return (
        <>
            <Helmet titleTemplate="%s">
                <title>{PAGE_TITLE}</title>
                {helpers.renderFavicon(auth.account)}
            </Helmet>
            <MetaTags>
                <meta property="og:site_name" content={helpers.getAccountTitleForOgTag(auth.account)} />
                <meta property="og:title" content={PAGE_TITLE} />
                <meta property="og:description" content={PAGE_DESCRIPTION} />
                <meta property="og:url" content={location.href} />
                {auth.account.logo && <meta property="og:image" content={auth.account.logo} />}
            </MetaTags>
            <Box className="bg-gray" minHeight="100vh" overflow="auto" width="100%">
                <Box textAlign="center" my="40px">
                    {auth.account.logo ? (
                        <img
                            src={auth.account.logo}
                            style={{
                                maxWidth: '100%',
                                maxHeight: helpers.getPanelistLogoHeight(auth.account)
                            }}
                            alt="Account Logo"
                        />
                    ) : (
                        <img src="/logo.svg" alt="Panelfox Logo" style={{ height: '32px' }} />
                    )}
                </Box>
                <PublicScreenPageAdminTools>
                    <Button type="button" variant="secondary-gray" onClick={() => window.print()} mr={0}>
                        <FiPrinter /> Print
                    </Button>
                    {!isNoAnswers && (
                        <ScreenerAnswersMetadata
                            style={{ marginTop: '8px' }}
                            ip={data.ip}
                            answeredAt={data.created_at}
                            timezone={auth.user.timezone}
                        />
                    )}
                </PublicScreenPageAdminTools>
                {!isNoAnswers && (
                    <ScreenerAnswersMetadata
                        isHiddenPrint
                        ip={data.ip}
                        answeredAt={data.created_at}
                        timezone={auth.user.timezone}
                    />
                )}
                <PublicScreenPageFormWrapper screener_bg={auth.account.screener_bg}>
                    <ReactFormGenerator data={data.panel_form.form} answer_data={answerData} read_only hide_actions />
                </PublicScreenPageFormWrapper>
                <Box textAlign="center">{helpers.renderScreenerFooter(auth.account)}</Box>
                {helpers.renderPoweredByPanelfox(auth.account)}
            </Box>
        </>
    );
}

export default ParticipantFormPage;
