/**
 * NotFoundPage
 *
 * This is the page we show when the user visits a url that doesn't have a route
 */

import React from 'react';
import { FormattedMessage } from 'react-intl';
import { Box } from 'rebass';
import H1 from 'components/H1';
import { Link } from 'react-router-dom';
import messages from './messages';
import helpers from 'utils/helpers';

export default function NotFound() {
    helpers.googleTagManagerEvent404();
    return (
        <article>
            <Box m={4}>
                <H1>
                    <FormattedMessage {...messages.header} />
                </H1>
                <a href="/">Home</a>
            </Box>
        </article>
    );
}
