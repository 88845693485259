import xhr from 'xhr.js';

const service_scheduling = {
    getScheduledStudyPerson: async (study_uuid, sp_uuid) => {
        const res = await xhr.get(`/studies/${study_uuid}/study-people/${sp_uuid}/public-scheduling`, {
            withCredentials: true
        });
        return res.data;
    },
    getAvailabilityPublic: async study_uuid => {
        const res = await xhr.get(`/studies/${study_uuid}/public-availability`, {
            withCredentials: true
        });
        return res.data;
    },
    getAllTimezones: async () => {
        const res = await xhr.get(`/me/get-all-timezones`, {
            withCredentials: true
        });
        return res.data;
    },
    confirmSchedule: async (token, study_uuid, sp_uuid, selected_spot, client_timezone, is_preview) => {
        const res = await xhr.post(
            `/session/confirm`,
            { token, study_uuid, sp_uuid, selected_spot, client_timezone, is_preview },
            { withCredentials: true }
        );
        return res.data;
    },
    cancelSession: async (sp_uuid, is_preview) => {
        const res = await xhr.post(`/session/cancel`, { sp_uuid, is_preview }, { withCredentials: true });
        return res.data;
    }
};

export default service_scheduling;
