import React from 'react';
import { Helmet } from 'react-helmet';
import 'styles/react-kanban.css';
import { withToastManager } from 'react-toast-notifications';
import H1 from 'components/H1';
import H2 from 'components/H2';
import { Flex, Box, Button } from 'rebass';
import { Input, Label, Textarea, Select } from '@rebass/forms';
import StudyPageSubNav from 'components/Study/StudyPageSubNav';
import LoadingIndicator from 'components/LoadingIndicator';
import LoadingWrapper from 'components/LoadingIndicator/LoadingWrapper';
import ReactTooltip from 'react-tooltip';
import TooltipIcon from 'components/TooltipIcon';
import config from 'config';
import helpers from 'utils/helpers';
import helpersScreener from 'utils/helpers-screener';
import auth from 'services/auth';
import { FiPlayCircle, FiCopy } from 'react-icons/fi';

import myxss from 'components/ReactFormBuilder/form-elements/myxss';
import VariablesHelper from 'components/EmailTools/VariablesHelper';
import EmailEditor from 'components/EmailTools/EmailEditor';
import AppPageWrapper from 'components/AppPageWrapper';
import AppPageWrapperSectionHeader from 'components/AppPageWrapper/AppPageWrapperSectionHeader';
import AppPageWrapperSectionBody from 'components/AppPageWrapper/AppPageWrapperSectionBody';
import { CollapsibleContentContainer } from 'components/CollapsibleContent';
import { ReactFormBuilder } from 'components/ReactFormBuilder';
import ScreenerTranslationComponent from 'components/ScreenerTranslationComponent';
import { COL_EMAIL } from 'utils/constants';

import xhr from 'xhr.js';
import services from 'services/services';

/**
 * @deprecated
 */
class StudyRecruitPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            study: {
                title: '',
                description: '',
                screener: []
            },
            screener_translation: null,
            editorData: null,
            available_columns: []
        };

        this.handleTranslationChange = this.handleTranslationChange.bind(this);
        this.onFormChange = this.onFormChange.bind(this);
        this.onChangeEditor = this.onChangeEditor.bind(this);
    }

    onChangeEditor(editorData) {
        this.onFormChange({ target: { value: editorData, name: 'email_screener_body' } });
    }

    getCustomDataColumnsXHR() {
        const { toastManager } = this.props;
        services
            .getCustomDataColumnsXHR()
            .then(available_columns => {
                this.setState({ available_columns });
            })
            .catch(error => {
                let errorText = services.parseAndTrackXhrErrors(error);

                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    onFormChange(e) {
        // console.log('PARENT FORM CHANGE');
        const { toastManager } = this.props;
        if (!e.target.name) {
            return;
        }

        if (e.target.name == 'participant_external') {
            // if we allow non-panelists to take the screener we need to create profiles for them
            // this means requiring an email
            if (e.target.value == 1) {
                let found_email_question = false;
                console.log('allow!');
                // verify that there is an email-mapped question in the screener
                const email_custom_column = this.state.available_columns.find(column => column.title == COL_EMAIL);
                console.log(this.state.available_columns, email_custom_column);
                if (email_custom_column) {
                    // find a screener question that maps to this
                    this.state.study.screener.forEach(screener_page_questions => {
                        screener_page_questions.forEach(question => {
                            console.log(question.label, question);
                            if (
                                question.personCustomDataId == email_custom_column.id &&
                                question.save_to_panel == true &&
                                question.required == true
                            ) {
                                found_email_question = true;
                            }
                        });
                    });
                }

                if (!found_email_question) {
                    const errorMsg =
                        'In order to Allow anyone to take the screener, please (1) Create a question in your screener asking for their email, (2) require it, and (3) enable "Save answers to the participant profile = Email".';
                    toastManager.add(errorMsg, {
                        appearance: 'error',
                        autoDismiss: true
                    });
                    return;
                }
            }
        }

        let study = { ...this.state.study };
        study[e.target.name] = e.target.value;

        this.setState({ study });

        // detect change, and clear timeout if exists
        if (this.saveDelay) {
            clearTimeout(this.saveDelay);
        }

        this.saveDelay = setTimeout(() => {
            services
                .updateStudy(study)
                .then(() => {
                    /* toastManager.add('Saved', {
                        appearance: 'success',
                        autoDismiss: true
                    }); */
                })
                .catch(error => {
                    const errorText = services.parseAndTrackXhrErrors(error);

                    toastManager.add(errorText, {
                        appearance: 'error',
                        autoDismiss: true
                    });
                });
        }, 500);
    }

    /**
     * Update the state of the study on the server
     *
     * @param {any} study
     */
    async updateStudy(study) {
        const { toastManager } = this.props;
        try {
            await services.updateStudy(study);
        } catch (error) {
            const errorText = services.parseAndTrackXhrErrors(error);
            toastManager.add(errorText, {
                appearance: 'error',
                autoDismiss: true
            });
        }
    }

    /**
     *
     * @param {{ body: any; subject: string; type: number; id: number }} template
     */
    onTemplate(template) {
        this.setState(
            ({ study }) => {
                study.email_screener_body = template.body;
                study.email_screener_subject = template.subject;
                study.email_screener_type = template.type;

                return { study };
            },
            () => this.updateStudy(this.state.study)
        );
    }

    handleTranslationChange(e) {
        const { screener_translation } = this.state.study;
        screener_translation[e.target.name] = e.target.value;

        const that = this;
        this.setState(screener_translation);

        // detect change, and clear timeout if exists
        if (this.saveDelay) {
            clearTimeout(this.saveDelay);
        }

        this.saveDelay = setTimeout(() => {
            screener_translation['confirmation_page_text'] = myxss.process(
                screener_translation['confirmation_page_text']
            );
            screener_translation['disqualification_page_text'] = myxss.process(
                screener_translation['disqualification_page_text']
            );

            that.xhrScreenerTranslation(screener_translation);
        }, 500);
    }

    xhrScreenerTranslation(screener_translation) {
        const { toastManager } = this.props;

        services
            .updateScreenerTranslation(this.props.match.params.id, screener_translation)
            .then(() => {
                // good
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);

                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    onSwitchChange(e) {
        console.log(e);

        const study = { ...this.state.study };
        const newStatus = study.status == 'active' ? 'paused' : 'active';

        console.log(newStatus);

        study.status = newStatus;
        this.setState({ study });

        this.updateStatusXhr(newStatus);
    }

    componentDidMount() {
        xhr.get(`/studies/${this.props.match.params.id}?account_id=${this.props.auth.account.id}`, {
            withCredentials: true
        }).then(res => {
            const study = res.data;
            const editorData_xhr = helpers.isObject(study.email_screener_body)
                ? study.email_screener_body
                : helpers.makeBlocksFromText(study.email_screener_body);
            console.log('editorData_xhr', editorData_xhr);
            this.setState({
                study,
                loading: false,
                screener_translation: study.screener_translation,
                editorData: editorData_xhr
            });
        });

        this.getCustomDataColumnsXHR();
    }

    render() {
        const screener_link = helpersScreener.getScreenerLink(this.state.study.uuid, this.props.auth.account);
        const preview_screener_button = (
            <Button
                variant="tertiary"
                mr={0}
                style={{ position: 'absolute', top: '21px', right: '32px' }}
                onClick={e => {
                    e.stopPropagation();
                    helpers.popupWindow({ url: `${screener_link}?is_preview=1`, w: 800, h: 600 });
                }}
            >
                Preview Screener
            </Button>
        );

        return (
            <Flex style={{ width: '100%' }}>
                <StudyPageSubNav study_id={this.props.match.params.id} study={this.state.study} />
                <AppPageWrapper>
                    <Helmet>
                        <title>{this.state.study.title}</title>

                        {/* <link
                            rel="stylesheet"
                            href="https://cdn.jsdelivr.net/npm/bootstrap@4.5.3/dist/css/bootstrap.min.css"
                            integrity="sha384-TX8t27EcRE3e/ihU7zmQxVncDAy5uIKz4rEkgIXeMed4M0jlfIDPvg6uqKI2xXr2"
                            crossOrigin="anonymous"
                        /> */}
                    </Helmet>

                    <AppPageWrapperSectionHeader>
                        <H1>Screener</H1>
                    </AppPageWrapperSectionHeader>

                    <AppPageWrapperSectionBody>
                        {this.state.loading ? (
                            <div style={{ width: '100%' }}>
                                <LoadingWrapper>
                                    <LoadingIndicator />
                                </LoadingWrapper>
                            </div>
                        ) : (
                            <>
                                <Box mx={4} style={{ width: '100%' }}>
                                    <CollapsibleContentContainer
                                        show={0}
                                        title="Screener Builder"
                                        end={preview_screener_button}
                                    >
                                        <Flex className="form-wrapper plain" style={{ width: '100%' }}>
                                            <Box width={1 / 3} mb={4}>
                                                <H2>Who can take the screener?</H2>
                                                <Label className="quiet">
                                                    If you want to publicly share a link to your study - you need to
                                                    create and require an "Email" question, and "Save answer to panelist
                                                    profile" with the "Email" custom column.
                                                </Label>
                                            </Box>
                                            <Box width={2 / 3} mb={4} sx={{ flexWrap: 'wrap', display: 'flex' }}>
                                                <Box width={1 / 3} className="form-label">
                                                    Allow non-panelists
                                                </Box>
                                                <Box width={2 / 3} className="form-data">
                                                    <Select
                                                        name="participant_external"
                                                        value={this.state.study.participant_external}
                                                        onChange={this.onFormChange}
                                                        width={300}
                                                        mb={3}
                                                    >
                                                        <option value="0">Do not allow</option>
                                                        <option value="1">Allow</option>
                                                    </Select>
                                                    {this.state.study.participant_external == 1 && (
                                                        <>
                                                            <a href={screener_link} target="_blank">
                                                                <Button type="button" variant="link">
                                                                    Public Screener Link
                                                                </Button>
                                                            </a>
                                                            <Button
                                                                variant="transparent"
                                                                ml={2}
                                                                onClick={e => {
                                                                    e.stopPropagation();
                                                                    const { toastManager } = this.props;
                                                                    helpers.copyToClipboard(screener_link);
                                                                    toastManager.add('Copied link', {
                                                                        appearance: 'success',
                                                                        autoDismiss: true
                                                                    });
                                                                }}
                                                            >
                                                                <FiCopy /> Copy
                                                            </Button>
                                                        </>
                                                    )}
                                                </Box>
                                            </Box>
                                            <Box width={1 / 3} mb={4}>
                                                <H2>What if someone qualifies?</H2>
                                                <Label className="quiet">
                                                    You can forward the qualified participant to schedule themselves,
                                                    based on the Study availability.
                                                </Label>
                                            </Box>
                                            <Box width={2 / 3} mb={4} sx={{ flexWrap: 'wrap', display: 'flex' }}>
                                                <Box width={1 / 3} className="form-label">
                                                    If participant qualifies
                                                </Box>
                                                <Box width={2 / 3} className="form-data">
                                                    <Select
                                                        name="participant_schedule"
                                                        value={this.state.study.participant_schedule}
                                                        onChange={this.onFormChange}
                                                        width={300}
                                                        mb={4}
                                                    >
                                                        <option value="automatic">
                                                            Automatically allow them to schedule themselves
                                                        </option>
                                                        <option value="manual">
                                                            Wait for me to send them a scheduling email
                                                        </option>
                                                    </Select>
                                                </Box>
                                            </Box>
                                        </Flex>

                                        <H2>Screener Pages</H2>
                                        <ReactFormBuilder
                                            url={`/studies/${this.props.match.params.id}/screener?account_id=${this.props.auth.account.id}`}
                                            saveUrl={`/studies/${this.props.match.params.id}/screener?account_id=${this.props.auth.account.id}`}
                                            read_only={1}
                                            onChange={screener_data => {
                                                let { study } = this.state;
                                                study.screener = screener_data;
                                                this.setState({ study });
                                            }}
                                        />
                                    </CollapsibleContentContainer>

                                    <CollapsibleContentContainer show={0} title="Bulk Screener Email">
                                        <Flex mb={4} sx={{ gridGap: 4 }}>
                                            <Box width={1 / 2}>
                                                <Label>Preview your email</Label>
                                                <Button
                                                    variant="secondary"
                                                    sx={{ width: '100%' }}
                                                    onClick={e => {
                                                        e.stopPropagation();
                                                        helpers.popupWindow({
                                                            url: `${config.API_URL}/studies/${this.state.study.id}/screener-email-preview?account_id=${this.state.study.account_id}`,
                                                            w: 800,
                                                            h: 600
                                                        });
                                                    }}
                                                >
                                                    <FiPlayCircle /> Preview Email
                                                </Button>
                                            </Box>
                                            <Box width={1 / 2}>
                                                <VariablesHelper
                                                    study={this.state.study}
                                                    templateType={this.state.study.email_screener_type}
                                                    elementId="email_screener_body"
                                                    content={this.state.study.email_screener_body}
                                                    onChange={val => {
                                                        this.onFormChange({
                                                            target: { value: val, name: 'email_screener_body' }
                                                        });
                                                    }}
                                                />
                                            </Box>
                                        </Flex>

                                        <Label htmlFor="email_screener_subject">Email Subject</Label>
                                        <Input
                                            type="text"
                                            name="email_screener_subject"
                                            value={this.state.study.email_screener_subject}
                                            onChange={this.onFormChange}
                                            placeholder="Email Subject"
                                        />

                                        <Label htmlFor="email_screener_body" mt={3}>
                                            Email Body
                                        </Label>
                                        {/* <Textarea
                                            id="email_screener_body"
                                            name="email_screener_body"
                                            placeholder="Email Body"
                                            value={this.state.study.email_screener_body}
                                            onChange={this.onFormChange}
                                            height="200px"
                                        /> */}

                                        {this.state.editorData && (
                                            <EmailEditor
                                                editorData={this.state.editorData}
                                                onChange={this.onChangeEditor}
                                                onTemplate={template => this.onTemplate(template)}
                                            />
                                        )}
                                    </CollapsibleContentContainer>

                                    <CollapsibleContentContainer show={0} title="Language and Translation">
                                        {this.state.screener_translation && (
                                            <ScreenerTranslationComponent
                                                screener_translation={this.state.screener_translation}
                                                handleTranslationChange={this.handleTranslationChange}
                                            />
                                        )}
                                    </CollapsibleContentContainer>
                                    <br />
                                    <br />
                                    <br />
                                    <br />

                                    {/* <ul>
                                 <li>You can set up a "Web Intercept" below, to recruit users from a website.</li>
                                 <li>Recruiting via email campaigns coming soon (ie. like MailChimp).</li>
                             </ul>

                            <OnboardingScript auth={this.props.auth} /> */}

                                    {/* <H2>Select Recruiting Type</H2>
                            <Button>Create Intercept</Button>
                            <Button>Create Email Campaign</Button> */}

                                    {/* <div>
                                <h1>Results</h1>
                                     {JSON.stringify(this.state.screenerAnswers)}
                              </div> */}
                                </Box>
                            </>
                        )}
                    </AppPageWrapperSectionBody>
                </AppPageWrapper>
            </Flex>
        );
    }
}

export default withToastManager(StudyRecruitPage);

/*
contact
--
first name
last name
email
phone


other
--
scheduling
magic drilldown
hidden external variable
image & text
formatted text block
opinion scale
qualifying question


close-ended
--
checkboxes
drop down
radio buttons
yes/no

open-ended
--
single line
paragraph
*/
