import React from 'react';
import { Box } from 'rebass';

const SchedulingPageAdminNote = props => (
    <Box pl="18px" sx={{ borderLeft: '2px solid #F2A001' }}>
        <Box pb="8px">
            <span className="fs-title-14 color-text-primary">Note to admin:</span>
            <span className="fs-body-14 color-text-secondary"> {props.children}</span>
        </Box>
        <Box pb="1px">
            <span className="fs-title-12 color-warning-dark">This message is visible to you only</span>
        </Box>
    </Box>
);

export default SchedulingPageAdminNote;
