import React from 'react';
import { Box } from 'rebass';
import styled from 'styled-components';

const AdminToolsContainer = styled(Box)`
    max-width: 800px;
    width: 100%;
    margin: 0 auto 20px auto !important;
    padding: 24px 40px 24px 36px;
    border-radius: 8px;
    border-left: 4px solid #f2a001;
`;

const PublicScreenPageAdminTools = props => (
    <AdminToolsContainer className="nice-boxshadow-light color-bg-light no-print">
        <Box className="fs-headline-20 color-text-primary" mb={2}>
            Admin tools
        </Box>
        <Box className="fs-title-12 color-warning-dark" mb={3}>
            This block is visible to you only
        </Box>
        {props.children}
    </AdminToolsContainer>
);

export default PublicScreenPageAdminTools;
