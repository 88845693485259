import React from 'react';
import { createPortal } from 'react-dom';

/**
 * Portal component. It renders the children in the body of the document.
 * Useful for modals, dropdowns, etc.
 *
 * @param {Object} props
 * @param {React.ReactNode} props.children
 */
export function Portal({ children }) {
    return createPortal(children, document.body);
}

export default Portal;
