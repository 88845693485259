/**
 * <ToolbarItem />
 */

import React from 'react';
import { DragSource } from 'react-dnd';
import ItemTypes from './ItemTypes';
import ID from './UUID';
import { Button } from 'rebass';

const cardSource = {
    beginDrag(props) {
        return {
            id: ID.uuid(),
            index: -1,
            data: props.data,
            onCreate: props.onCreate
        };
    }
};

class ToolbarItem extends React.Component {
    render() {
        const { connectDragSource, data, onClick } = this.props;
        //if (!connectDragSource) return null;
        return (
            <Button variant="tertiary" onClick={onClick} mb={2} mr={2}>
                {data.icon}
                {data.name}
            </Button>
        );
    }
}

export default DragSource(ItemTypes.CARD, cardSource, connect => ({
    connectDragSource: connect.dragSource()
}))(ToolbarItem);
