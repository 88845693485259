import styled from 'styled-components';

const MarketingHeadline = styled.div`
    margin: 180px 0 0 0;
    font-size: 60px;
    line-height: 70px;
    font-weight: 600;
    color: #000;
    @media (max-width: 768px) {
        font-size: 40px;
        line-height: 50px;
        margin: 60px 0 0 0;
    }
`;

export default MarketingHeadline;
