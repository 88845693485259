import React, { useCallback } from 'react';
import { FiEye, FiLock } from 'react-icons/fi';
import styled from 'styled-components';
import { useToasts } from 'react-toast-notifications';

import services from 'services/services';
import service_people from 'services/people';

const MaskedValue = styled.div`
    background: #f9f9f9;
    border-radius: 5px;
    color: #929db0;
    text-align: center;
    white-space: nowrap;
    height: 28px;
    width: 100%;
    min-width: 100px;
    max-width: 200px;
    cursor: pointer;
    padding: 2px 5px;
    font-size: 12px;
    line-height: 24px;
    &:hover div {
        visibility: visible !important;
    }
`;

/**
 * This component is used when the participant data value is pii_masked.
 * Allows the admin to unmask the value.
 *
 * @param {Object} props props
 * @param {Number} props.keyId participant data key ID
 * @param {string} props.label additional text for the masked/unmask label
 * @param {Number} props.participantId ID of the participant
 * @param {'admin' | 'user' | 'view'} props.role auth account_role type
 * @param {Function} props.setValue a callback function, called after the value is unmasked
 * @returns {React.ReactNode} ParticipantMaskedValue component
 */
export function ParticipantMaskedValue({ keyId, label, participantId, role, setValue, ...props }) {
    const { addToast } = useToasts();
    const isAdmin = role === 'admin';

    const unmask = useCallback(() => {
        if (!isAdmin) {
            return;
        }
        service_people
            .getMaskedValue(participantId, keyId)
            .then(data => {
                setValue(data.value);
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);
                addToast(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }, [isAdmin, keyId, participantId, setValue]);

    return (
        <MaskedValue onClick={unmask} {...props}>
            <div style={{ visibility: 'hidden' }}>
                {isAdmin ? (
                    <>
                        <FiEye
                            style={{
                                margin: '0px 6px 3px 0px'
                            }}
                        />
                        Unmask {label}
                    </>
                ) : (
                    <>
                        <FiLock
                            style={{
                                margin: '0px 6px 3px 0px'
                            }}
                        />
                        Masked {label}
                    </>
                )}
            </div>
        </MaskedValue>
    );
}

export default ParticipantMaskedValue;
