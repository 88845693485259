import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import MetaTags from 'react-meta-tags';
import { Link } from 'react-router-dom';
import NiceWrapper from 'components/NiceWrapper';
import { Flex, Box, Heading, Button } from 'rebass';
import { Label, Switch, Input } from '@rebass/forms';
import helpers from 'utils/helpers';
import services from 'services/services';
import service_people from 'services/people';
import service_accounts from 'services/accounts';
import LoadingWrapper from 'components/LoadingIndicator/LoadingWrapper';
import LoadingIndicator from 'components/LoadingIndicator';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';

function PanelistLogin(props) {
    const [loading, setLoading] = useState(true);
    const [personData, setPersonData] = useState({});
    const [account, setAccount] = useState({});
    const history = useHistory();
    const { addToast } = useToasts();

    useEffect(() => {
        getAccountXHR(props.match.params.account_uuid);
        checkPersonAuth(props.match.params.account_uuid);
    }, [props.match.params.account_uuid]);

    function getAccountXHR(account_uuid) {
        console.log(account_uuid);

        service_accounts
            .getAccountBySlug(account_uuid)
            .then(account => {
                setAccount(account);
                setLoading(false);
            })
            .catch(e => {
                services.parseAndTrackXhrErrors(e);
                setLoading(false);
            });
    }

    function checkPersonAuth(account_uuid) {
        service_people
            .checkAuthPanelistExperience(account_uuid)
            .then(person => {
                console.log('person', person);
                redirectToPanelistProfile();
            })
            .catch(e => {
                //console.log(e)
            });
    }

    function redirectToPanelistProfile() {
        history.push(`/panelist/${props.match.params.account_uuid}/profile`);
    }

    function loginPanelistXHR() {
        const email = document.getElementById('email').value;
        const password = document.getElementById('password').value;

        //console.log(person, account)

        //setLoading(true);

        service_people
            .loginPersonPanelistExperience(account.uuid, email, password)
            .then(jwt_token => {
                helpers.setPanelistJWTToken(jwt_token);
                //console.log('jwt_token', jwt_token)
                setLoading(false);
                redirectToPanelistProfile();
            })
            .catch(e => {
                //console.log('ERROR', e);
                setLoading(false);

                const errorText = services.parseAndTrackXhrErrors(e);
                addToast(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    return (
        <div style={{ margin: 'auto' }}>
            <Helmet titleTemplate="%s">
                <title>{account && account.title ? account.title : 'Panel Profile'}</title>
                {helpers.renderFavicon(account)}
            </Helmet>
            <MetaTags>
                <meta property="og:site_name" content={helpers.getAccountTitleForOgTag(account)} />
                <meta property="og:title" content={account && account.title ? account.title : 'Panel Log In'} />
                <meta property="og:description" content={'Log in to your panel profile.'} />
                <meta property="og:url" content={location.href} />
                {helpers.getPageImage(account) != null && (
                    <meta property="og:image" content={helpers.getPageImage(account)} />
                )}
            </MetaTags>
            {loading ? (
                <Box my={4} style={{ width: '100%', height: '80px', position: 'relative' }}>
                    <LoadingWrapper>
                        <LoadingIndicator />
                    </LoadingWrapper>
                </Box>
            ) : account && account.title ? (
                <>
                    <Box style={{ textAlign: 'center' }} mt={4} mb={3}>
                        {account.logo ? (
                            <img
                                src={account.logo}
                                style={{ maxWidth: '100%', maxHeight: helpers.getPanelistLogoHeight(account) }}
                                alt="Account Logo"
                            />
                        ) : (
                            <div style={{ height: '32px' }}></div>
                        )}
                        <Heading fontSize={[3, 4]} mt={2}>
                            <br />
                            Sign In
                        </Heading>
                    </Box>
                    <NiceWrapper style={{ background: 'white', color: 'black', padding: '0' }}>
                        <Box pt={4} px={4} pb={3} sx={{ textAlign: 'center' }}>
                            <Box fontSize={1} style={{ minWidth: '320px' }}>
                                <form
                                    onSubmit={e => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        loginPanelistXHR();
                                    }}
                                >
                                    <Label htmlFor="email">E-mail</Label>
                                    <Input type="email" placeholder="E-mail" id="email" required autoFocus />
                                    <Label htmlFor="password" mt={3}>
                                        Password
                                    </Label>
                                    <Input type="password" placeholder="Password" id="password" required />

                                    <Box sx={{ textAlign: 'right' }} mt={1}>
                                        <Link
                                            to={`/panelist/${props.match.params.account_uuid}/forgot-password`}
                                            style={helpers.accountThemeLinkStyle(account)}
                                        >
                                            Forgot password?
                                        </Link>
                                    </Box>
                                    <Box mt={3}>
                                        <Button
                                            type="submit"
                                            variant="primary"
                                            mr={0}
                                            mb={3}
                                            style={helpers.accountThemeButtonStyle(account, { width: '100%' })}
                                        >
                                            Sign In
                                        </Button>
                                    </Box>
                                </form>
                            </Box>
                        </Box>
                        <Box fontSize={1} p={3} sx={{ textAlign: 'center', background: '#f7f7f7' }}>
                            Don't have an account?{' '}
                            <Link
                                to={`/panelist/${props.match.params.account_uuid}/signup`}
                                style={helpers.accountThemeLinkStyle(account)}
                            >
                                Sign Up
                            </Link>
                        </Box>
                    </NiceWrapper>
                    {helpers.renderScreenerFooter(account)}
                </>
            ) : (
                <Box mt={4}>Account not found.</Box>
            )}
        </div>
    );
}

export default PanelistLogin;
