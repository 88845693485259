import React from 'react';

/**
 * Display a toast message for a payment
 *
 * @param {Object} param0
 * @param {string} param0.message The message to display
 * @param {string} param0.studyPersonId The study person ID
 * @param {string} param0.studyId The study ID
 */
export function PaymentToastMessage({ message, studyPersonId, studyId }) {
    if (!studyPersonId) return <>{message}</>;

    return (
        <>
            {message}
            <br />
            <a target="_blank" href={`/studies/${studyId}/participants/${studyPersonId}`}>
                View Participant
            </a>
        </>
    );
}

export default PaymentToastMessage;
