import React from 'react';
import { Box, Button } from 'rebass';
import { useToasts } from 'react-toast-notifications';
import { Label, Input } from '@rebass/forms';

import { useMutation } from 'hooks/useMutation';
import NiceModal from 'components/NiceModal';
import { useForm } from 'hooks/useForm';
import services from 'services/services';
import Portal from 'components/Portal';

/**
 * Create a new panel properties group modal
 *
 * @param {Object} props
 * @param {() => void} props.onClose close modal callback
 * @param {Boolean} props.isOpen modal open state
 * @param {(data: any) => void?} props.onSuccess success callback
 */
export function CreatePanelPropertiesGroupModal({ onClose, isOpen, onSuccess }) {
    const { addToast } = useToasts();
    const { handleSubmit, reset, register } = useForm();
    const { mutate: createGroup, isLoading: isCreateGroupLoading } = useMutation({
        mutationFn: services.createPanelPropertiesGroup,
        onSuccess: data => {
            addToast('New group created', {
                appearance: 'success',
                autoDismiss: true
            });
            reset();
            onSuccess && onSuccess(data);
            onClose();
        },
        onError: (_, message) => {
            addToast(message, {
                appearance: 'error',
                autoDismiss: true
            });
        }
    });

    /**
     * onSubmit handler for the create group form
     */
    const onSubmit = handleSubmit(data => {
        createGroup(data);
    });

    return (
        <Portal>
            <NiceModal isOpen={isOpen} shouldCloseOnOverlayClick onRequestClose={onClose} title="Create a group">
                <form id="create-group-form" onSubmit={onSubmit}>
                    <Box>
                        <Label>Group title</Label>
                        <Input {...register('name')} autoFocus placeholder="Contact Information" />
                    </Box>
                </form>
                <Box className="modal-actions">
                    <Button type="button" mr={2} onClick={onClose} variant="secondary-gray">
                        Cancel
                    </Button>
                    <Button
                        type="submit"
                        variant="primary"
                        mr={0}
                        disabled={isCreateGroupLoading}
                        className="modal-primary"
                        form="create-group-form"
                    >
                        Save
                    </Button>
                </Box>
            </NiceModal>
        </Portal>
    );
}

export default CreatePanelPropertiesGroupModal;
