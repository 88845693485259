/*
 * StudyTagsPage
 *
 * This is the first thing users see of our App, at the '/' route
 */

import React, { useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import xhr from 'xhr.js';
import ReactModal from 'react-modal';
import 'styles/react-kanban.css';
import { withToastManager } from 'react-toast-notifications';
import ContentEditable from 'react-contenteditable';
import moment from 'moment-timezone';
import cloneDeep from 'lodash/cloneDeep';
import NiceWrapper from 'components/NiceWrapper';

import { FiEye, FiCode, FiClock, FiDollarSign, FiPlus, FiSettings, FiUsers, FiLayers } from 'react-icons/fi';

import { ID } from 'utils/helpers';
import config from 'config';
import BreadcrumbDivider from 'components/Breadcrumb/BreadcrumbDivider';
import { Link } from 'react-router-dom';
import H1 from 'components/H1';
import H2 from 'components/H2';
import ListWrapper from 'components/ListWrapper';
import { Flex, Box, Button } from 'rebass';
import { Label, Input, Switch } from '@rebass/forms';
import StudyItem from 'components/Study/StudyItem';
import StudyScript from 'components/Study/StudyScript';
import StudyTldr from 'components/Study/StudyTldr';
import StudyPageSubNav from 'components/Study/StudyPageSubNav';
import ParticipantNotes from 'components/Participant/ParticipantNotes';
import ParticipantPay from 'components/Participant/ParticipantPay';
import LoadingIndicator from 'components/LoadingIndicator';
import LoadingWrapper from 'components/LoadingIndicator/LoadingWrapper';

import AppPageWrapper from 'components/AppPageWrapper';
import AppPageWrapperSectionHeader from 'components/AppPageWrapper/AppPageWrapperSectionHeader';
import AppPageWrapperSectionBody from 'components/AppPageWrapper/AppPageWrapperSectionBody';

class StudyTagDiv extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            h_sp: []
        };

        this.getHighlights = this.getHighlights.bind(this);
    }

    getHighlights() {
        xhr.get(
            `/study-tags/${this.props.tag.id}?account_id=${this.props.study.account_id}&study_id=${this.props.study.id}`,
            {
                withCredentials: true
            }
        ).then(res => {
            const h_sp = res.data;

            this.setState({
                h_sp,
                loading: false
            });
        });
    }
    render() {
        let renderHighlights = [];
        this.state.h_sp.forEach(h_sp_single => {
            let singleBox = [];
            singleBox.push(<div>{h_sp_single.study_person.person.email}</div>);
            h_sp_single.highlights.forEach(highlight => {
                singleBox.push(
                    <span style={{ background: 'yellow', margin: '0 4px 4px', display: 'inline-block' }}>
                        {highlight}
                    </span>
                );
            });

            renderHighlights.push(
                <div style={{ marginBottom: '8px', border: '1px solid #eee', borderRadius: '4px', padding: '8px' }}>
                    {singleBox}
                </div>
            );
        });
        return (
            <Box mb={3}>
                <div>
                    <strong>{this.props.tag.title}</strong> / Count:{' '}
                    {this.props.tag.popularity ? this.props.tag.popularity : 0} /
                    <Button variant="tertiary" onClick={this.getHighlights}>
                        Show Highlights
                    </Button>
                </div>
                {renderHighlights}
                {/*this.props.tag.popularity ?
                   <div style={{minWidth: '30px', display: 'inline-block', width: 'calc('+tag_width+'% - 220px)', background: '#0A73EB', color: '#fff', borderRadius: '4px', fontSize: '14px', padding: '4px 8px'}}>
                       {this.props.tag.popularity ? this.props.tag.popularity : ''}
                   </div>
                   : ''
               */}
            </Box>
        );
    }
}

class StudyTagsPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            loading: true,
            study: {
                title: '',
                description: ''
            },
            tags: []
        };

        this.onSearchKeywordChange = this.onSearchKeywordChange.bind(this);
    }

    onSearchKeywordChange(event) {
        const kw = event.target.value;
        this.setState({ searchKeyword: kw });
    }

    componentDidMount() {
        this.getTags();
        this.getStudy();
    }

    getTags() {
        xhr.get(
            `/study-tags?account_id=${this.props.auth.account.id}&study_id=${this.props.match.params.id}&sort=popular`,
            {
                withCredentials: true
            }
        ).then(res => {
            const tags = res.data;

            this.setState({
                tags,
                loading: false
            });
        });
    }

    getStudy() {
        xhr.get(`/studies/${this.props.match.params.id}?account_id=${this.props.auth.account.id}`, {
            withCredentials: true
        }).then(res => {
            const study = res.data;

            this.setState({
                study
            });
        });
    }

    onFormChange(e) {
        console.log(e.target.name);

        const study = { ...this.state.study };
        study[e.target.name] = e.target.value;
        this.setState({ study });

        this.saveStudy(study);
    }

    onTitleChange(e) {
        console.log(e);

        const study = { ...this.state.study };
        const newTitle = e.target.value;
        if (newTitle == study.title) {
            return;
        }

        study.title = newTitle;
        this.setState({ study });

        this.saveStudy(study);
    }

    render() {
        let render_tags = [];
        let all_tags_count = 0;
        let max_tags_count = 0;
        let tag_width = 0;
        if (this.state.tags.length > 0) {
            this.state.tags.forEach(tag => {
                if (tag.popularity) {
                    all_tags_count += tag.popularity;

                    if (tag.popularity > max_tags_count) {
                        max_tags_count = tag.popularity;
                    }
                }
            });

            this.state.tags.forEach(tag => {
                tag_width = tag.popularity ? (tag.popularity * 100) / max_tags_count : 0;
                render_tags.push(<StudyTagDiv study={this.state.study} tag={tag} />);
            });
        } else {
            render_tags.push('You have not tagged any notes yet!');
        }

        return (
            <AppPageWrapper>
                <Helmet>
                    <title>{this.state.study.title}</title>
                </Helmet>

                <AppPageWrapperSectionHeader style={{ flexDirection: 'column' }}>
                    <H1>
                        <Link to="/studies">Studies</Link>
                        <BreadcrumbDivider />
                        <Link to={`/studies/${this.state.study.id}`}>{this.state.study.title}</Link>
                    </H1>

                    <StudyPageSubNav study={this.state.study} activePage="tags" />
                </AppPageWrapperSectionHeader>

                <AppPageWrapperSectionBody>
                    {this.state.loading ? (
                        <div style={{ width: '100%' }}>
                            <LoadingWrapper>
                                <LoadingIndicator />
                            </LoadingWrapper>
                        </div>
                    ) : (
                        <Box m={3} width={'100%'}>
                            {render_tags}
                        </Box>
                    )}
                </AppPageWrapperSectionBody>
            </AppPageWrapper>
        );
    }
}

export default withToastManager(StudyTagsPage);
