import React, { useCallback, useState } from 'react';
import { withToastManager } from 'react-toast-notifications';
import styled from 'styled-components';
import { FiCalendar, FiCheck, FiCopy, FiEdit, FiX } from 'react-icons/fi';
import { Box } from 'rebass';
import ParticipantInputElement from './ParticipantInputElement';
import ParticipantMaskedValue from './ParticipantMaskedValue';
import helpers from 'utils/helpers';

const AttributeItemWrapper = styled.div`
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    min-height: 52px;
    border-bottom: 1px solid #495f781f;
    color: #929db0;
    &:hover {
        background: #fbfdff;
    }
    & > div {
        padding: 8px 0;
    }
`;

const ActionButton = styled.a`
    &:not(.primary) {
        color: #606e85;
    }
    &:not(.primary):hover {
        color: #6200ff;
    }
    & svg {
        margin-right: 4px;
    }
`;

/**
 * This component displays the ItemWrapper with the participant attribute
 * used for ParticipantSettings component, includes 3 columns: Title, Value, Actions
 *
 * @param {Object} props props
 * @param {Object} props.cdk
 * @param {Object} props.cdv
 * @param {?String} props.className CSS class name
 * @param {Number} props.participantId ID of the participant
 * @param {'admin' | 'user' | 'view'} props.role auth account_role type
 * @param {Object} props.user auth user object
 * @param {Function} props.onChange - A callback function to be called after the value is changed
 * @param {Function} props.onUnmask - A callback function to be called after the value is unmasked
 * @returns {React.ReactNode} ParticipantAttrubuteItemWrapper component
 */

function ParticipantAttrubuteItemWrapper(props) {
    const cdk = props.cdk;
    const cdv = props.cdv;

    const [unmasked, setUnmasked] = useState(false);
    const [editing, setEditing] = useState(false);

    const handleUnmask = useCallback(value => {
        setUnmasked(true);
        props.onUnmask({ target: { name: cdk.id, value: value } });
    }, []);

    const handleChange = useCallback(e => {
        const value = e.target.type === 'checkbox' ? helpers.getCheckboxValuesAsArray(cdk.id) : e.target.value;
        setEditing(value);
    }, []);

    const handleClickEdit = e => {
        e.preventDefault();
        setEditing(cdv.value);
    };

    const handleClickCancel = e => {
        e.preventDefault();
        setEditing(false);
    };

    const handleClickSave = e => {
        e.preventDefault();
        setEditing(false);
        props.onChange({ target: { name: cdk.id, type: cdk.type, value: editing } });
    };

    const displayValue = helpers.getDisplayValue(cdk, cdv.value, props.user.timezone);

    const actionButtons = [];
    if (cdk.type === 'uuid') {
        actionButtons.push(
            <ActionButton
                onClick={() => {
                    const { toastManager } = props;
                    helpers.copyToClipboard(cdv.value);
                    toastManager.add('Copied to clipboard', {
                        appearance: 'success',
                        autoDismiss: true
                    });
                }}
            >
                <FiCopy />
                Copy
            </ActionButton>
        );
    } else if (helpers.isEditableAttribute(cdk.title) && (!cdk.pii_masked || unmasked || cdv.value)) {
        if (editing === false) {
            actionButtons.push(
                <ActionButton onClick={handleClickEdit}>
                    {cdk.type === 'date' ? (
                        <>
                            <FiCalendar />
                            Set date
                        </>
                    ) : (
                        <>
                            <FiEdit />
                            Edit
                        </>
                    )}
                </ActionButton>
            );
        } else {
            actionButtons.push(
                <ActionButton onClick={handleClickCancel}>
                    <FiX />
                    Cancel
                </ActionButton>
            );
            actionButtons.push(
                <ActionButton className="primary" onClick={handleClickSave}>
                    <FiCheck />
                    Save
                </ActionButton>
            );
        }
    }

    return (
        <AttributeItemWrapper className={props.className} style={{ paddingLeft: 0, paddingRight: 0 }}>
            <Box width={5 / 16} className="color-text-primary medium">
                {cdk.title}
            </Box>
            <Box width={cdk.type === 'uuid' ? 8 / 16 : 7 / 16} className="color-text-primary">
                {editing === false ? (
                    <>
                        {!cdk.pii_masked || unmasked || cdv.value ? (
                            <>{displayValue}</>
                        ) : (
                            <ParticipantMaskedValue
                                role={props.role}
                                participantId={props.participantId}
                                setValue={handleUnmask}
                                keyId={cdk.id}
                            />
                        )}
                    </>
                ) : (
                    <ParticipantInputElement
                        {...props}
                        cdv={{ value: editing }}
                        onCancel={handleClickCancel}
                        onSave={handleClickSave}
                        onChange={handleChange}
                    />
                )}
            </Box>
            <Box width={cdk.type === 'uuid' ? 3 / 16 : 4 / 16} className="text-right">
                {actionButtons}
            </Box>
        </AttributeItemWrapper>
    );
}

export default withToastManager(ParticipantAttrubuteItemWrapper);
