import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Flex, Box, Heading, Button } from 'rebass';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import {
    MarketingHeader,
    MarketingFooter,
    MarketingPageSection,
    MarketingPageWrapper,
    MarketingHeadline
} from 'components/Marketing';
import NiceWrapper from 'components/NiceWrapper';
import ListWrapper from 'components/ListWrapper';
import ItemWrapperText from 'components/ItemWrapper/ItemWrapperText';
import { FiCheckCircle } from 'react-icons/fi';

const Check = <FiCheckCircle style={{ color: 'green', margin: '-2px 8px 0 0' }} />;

function SecurityPage(props) {
    return (
        <Flex flexDirection="column" style={{ margin: '0px auto', width: '100%' }}>
            <Helmet>
                <title>Home</title>
            </Helmet>
            <MarketingHeader auth={props.auth} />
            <MarketingPageWrapper isMobile={isMobile}>
                <MarketingPageSection>
                    <Heading fontSize={[5, 6, 6]} color="black" mt={4}>
                        Data security and privacy.
                    </Heading>
                    <Box mb={3} mt={4}>
                        Our application is built on a modern cloud infrastructure designed to ensure the safety of your
                        data, and we’ve chosen proven third party cloud providers like AWS, who have a consistently
                        excellent track record.
                    </Box>
                    <Box mb={3}>
                        We’re not in the business of selling your data (anonymized or otherwise). You own your data and
                        we will never sell it to third parties. We also won’t look at your data unless you give us
                        permission for a support case.
                    </Box>

                    <Heading fontSize={[4, 5, 5]} color="black" mt={4}>
                        Security features
                    </Heading>
                    <ListWrapper className="border" mt={4}>
                        <ItemWrapperText className="header-row">
                            <Heading fontSize={[3]} color="black">
                                Product security
                            </Heading>
                        </ItemWrapperText>
                        <ItemWrapperText>
                            <Box width={[1, 1 / 6]} color="black">
                                <strong>{Check}Permissions</strong>
                            </Box>
                            <Box width={[1, 5 / 6]}>
                                Global access roles allow admins to set permission levels for everyone in the workspace.
                            </Box>
                        </ItemWrapperText>
                        <ItemWrapperText>
                            <Box width={[1, 1 / 6]} color="black">
                                <strong>{Check}Secure passwords</strong>
                            </Box>
                            <Box width={[1, 5 / 6]}>
                                Panelfox enforces a password complexity standard and credentials are stored using BCrypt
                                with salts.
                            </Box>
                        </ItemWrapperText>
                        <ItemWrapperText>
                            <Box width={[1, 1 / 6]} color="black">
                                <strong>{Check}SSO via SAML & Google</strong>
                            </Box>
                            <Box width={[1, 5 / 6]}>
                                Admins can instruct users to authenticate to Panelfox in one click using their Google
                                account, or via SAML. They’ll never need to set a password with us to log in to their
                                account or to sign up, even if they’re creating a new account.
                            </Box>
                        </ItemWrapperText>
                        <ItemWrapperText>
                            <Box width={[1, 1 / 6]} color="black">
                                <strong>{Check}Permanent deletion</strong>
                            </Box>
                            <Box width={[1, 5 / 6]}>
                                Users can delete panelists, panelist data, studies and study data within Panelfox if
                                they have the correct access rights.
                            </Box>
                        </ItemWrapperText>
                        <ItemWrapperText>
                            <Box width={[1, 1 / 6]} color="black">
                                <strong>{Check}High availability</strong>
                            </Box>
                            <Box width={[1, 5 / 6]}>
                                We ensure high availability with automated and manual testing, statically typed
                                languages, regular performance benchmarking, production logging and alerts, 24/7 on-call
                                rotations, fast continuous deployments, and industry-standard cloud infrastructure.
                            </Box>
                        </ItemWrapperText>
                    </ListWrapper>

                    <ListWrapper className="border" mt={4}>
                        <ItemWrapperText className="header-row">
                            <Heading fontSize={[3]} color="black">
                                Network and application security
                            </Heading>
                        </ItemWrapperText>
                        <ItemWrapperText>
                            <Box width={[1, 1 / 6]} color="black">
                                <strong>{Check}Hosting and storage</strong>
                            </Box>
                            <Box width={[1, 5 / 6]}>
                                Panelfox services and data are hosted in Amazon Web Services (AWS) facilities in the
                                USA, and Germany.
                            </Box>
                        </ItemWrapperText>
                        <ItemWrapperText>
                            <Box width={[1, 1 / 6]} color="black">
                                <strong>{Check}Encryption</strong>
                            </Box>
                            <Box width={[1, 5 / 6]}>
                                Data is encrypted while moving between us and the browser with Transport Level Security
                                (TLS). All SSL certificates are issued and managed through AWS, and we enable HTTP
                                Strict Transport Security (HSTS).
                            </Box>
                        </ItemWrapperText>
                        {/*<ItemWrapperText>
                            <Box width={[1, 1 / 6]} color="black">
                                <strong>{Check}Vulnerability scanning</strong>
                            </Box>
                            <Box width={[1, 5 / 6]}>
                                Panelfox uses third party security tools to scan for vulnerabilities. Our engineers
                                respond to issues raised. We have no vulnerabilities on the OWASP Top 10 and a maximum
                                CVSS score of 0.0.
                            </Box>
                        </ItemWrapperText>
                        <ItemWrapperText>
                            <Box width={[1, 1 / 6]} color="black">
                                <strong>{Check}Penetration testing</strong>
                            </Box>
                            <Box width={[1, 5 / 6]}>
                                We perform independent third-party manual penetration testing on an annual basis.
                            </Box>
                        </ItemWrapperText>
                        <ItemWrapperText>
                            <Box width={[1, 1 / 6]} color="black">
                                <strong>{Check}Brute force prevention</strong>
                            </Box>
                            <Box width={[1, 5 / 6]}>
                                We employ password strength requirements, Cross-Site Request Forgery (CSRF) protection,
                                secure password reset practices, and log in attempt rate limiting with automated account
                                lockout. We also use a large email domain deny list to prevent malicious actors and
                                spam.
                            </Box>
                        </ItemWrapperText>*/}
                        <ItemWrapperText>
                            <Box width={[1, 1 / 6]} color="black">
                                <strong>{Check}Backups & monitoring</strong>
                            </Box>
                            <Box width={[1, 5 / 6]}>
                                We use AWS RDS’ backup solution for datastores that contain customer data. On an
                                application level, we store logs for all activity through Papertrail.
                            </Box>
                        </ItemWrapperText>
                        <ItemWrapperText>
                            <Box width={[1, 1 / 6]} color="black">
                                <strong>{Check}Incident response</strong>
                            </Box>
                            <Box width={[1, 5 / 6]}>
                                Our engineering team has a 24 / 7 on-call rotation and escalation policy, with
                                production alerts captured and automatically escalated.
                            </Box>
                        </ItemWrapperText>
                    </ListWrapper>

                    <ListWrapper className="border" mt={4}>
                        <ItemWrapperText className="header-row">
                            <Heading fontSize={[3]} color="black">
                                Compliance
                            </Heading>
                        </ItemWrapperText>
                        <ItemWrapperText>
                            <Box width={[1, 1 / 6]} color="black">
                                <strong>{Check}PCI DSS</strong>
                            </Box>
                            <Box width={[1, 5 / 6]}>
                                All payments made to us go through our payments provider, Stripe. Details about their
                                security setup and PCI compliance can be found on Stripe’s security page.
                            </Box>
                        </ItemWrapperText>
                        {/*<ItemWrapperText>
                            <Box width={[1, 1 / 6]} color="black">
                                <strong>{Check}CAIQ</strong>
                            </Box>
                            <Box width={[1, 5 / 6]}>
                                We have completed the Cloud Security Alliance (CSA) CAIQ self-assessment questionnaire,
                                which is available through the CSA’s STAR registry.
                            </Box>
                        </ItemWrapperText>
                        <ItemWrapperText>
                            <Box width={[1, 1 / 6]} color="black">
                                <strong>{Check}McAfee Enterprise-Ready™</strong>
                            </Box>
                            <Box width={[1, 5 / 6]}>
                                We have been awarded the McAfee Enterprise-Ready™ seal, having earned the highest
                                CloudTrust™ rating possible based on attributes across the data, user and device,
                                security, business, and legal evaluation categories.
                            </Box>
                        </ItemWrapperText>
                        <ItemWrapperText>
                            <Box width={[1, 1 / 6]} color="black">
                                <strong>{Check}VSA</strong>
                            </Box>
                            <Box width={[1, 5 / 6]}>
                                We have completed the Vendor Security Alliance (VSA) Core self-assessment questionnaire,
                                contact us for a copy.
                            </Box>
                        </ItemWrapperText>
                        <ItemWrapperText>
                            <Box width={[1, 1 / 6]} color="black">
                                <strong>{Check}Google VSAQ</strong>
                            </Box>
                            <Box width={[1, 5 / 6]}>
                                We have completed responses for Google’s open source vendor security assessment
                                questionnaire (VSAQ) tool, contact us for a copy.
                            </Box>
                        </ItemWrapperText>
                        <ItemWrapperText>
                            <Box width={[1, 1 / 6]} color="black">
                                <strong>{Check}OWASP</strong>
                            </Box>
                            <Box width={[1, 5 / 6]}>
                                The most recent penetration test reported no vulnerabilities on the OWASP 2013 Top 10
                                and OWASP 2017 Top 10.
                            </Box>
                        </ItemWrapperText>*/}
                    </ListWrapper>
                </MarketingPageSection>
            </MarketingPageWrapper>
            <MarketingPageWrapper isMobile={isMobile}>
                <MarketingFooter props={props} />
            </MarketingPageWrapper>
        </Flex>
    );
}

export default SecurityPage;
