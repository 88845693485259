import React from 'react';
import { withToastManager } from 'react-toast-notifications';
import { Button } from 'rebass';
import { FiCopy } from 'react-icons/fi';
import helpers from 'utils/helpers';

const QuickCopy = props => (
    <a
        href="#"
        mr={0}
        style={{ height: 'auto' }}
        onClick={e => {
            e.stopPropagation();
            const { toastManager } = props;
            helpers.copyToClipboard(props.value);
            toastManager.add('Copied to clipboard', {
                appearance: 'success',
                autoDismiss: true
            });
        }}
    >
        <FiCopy />
    </a>
);

export default withToastManager(QuickCopy);
