import React from 'react';
import { Helmet } from 'react-helmet';
import cloneDeep from 'lodash/cloneDeep';
import { isEqual } from 'lodash';
import ReactQuill from 'react-quill';
import StudyFilters from 'components/Study/StudyFilters';
import 'styles/react-kanban.css';
import { withToastManager } from 'react-toast-notifications';
import TextAreaAutosize from 'react-textarea-autosize';
import H1 from 'components/H1';
import H2 from 'components/H2';
import SideNav from 'components/SideNav';

import { Link, withRouter } from 'react-router-dom';

import { Flex, Box, Button } from 'rebass';
import { Input, Label, Textarea, Select, Checkbox, Switch } from '@rebass/forms';
import StudyPageSubNav from 'components/Study/StudyPageSubNav';
import LoadingIndicator from 'components/LoadingIndicator';
import LoadingWrapper from 'components/LoadingIndicator/LoadingWrapper';
import EmailTemplateTypeLabel from 'components/EmailTemplateTypeLabel';
import ReactTooltip from 'react-tooltip';
import TooltipIcon from 'components/TooltipIcon';
import config from 'config';
import helpers from 'utils/helpers';
import helpersScreener from 'utils/helpers-screener';
import moment from 'moment-timezone';
import {
    FiBookOpen,
    FiRefreshCw,
    FiTrash2,
    FiHelpCircle,
    FiLink,
    FiEdit,
    FiPlayCircle,
    FiCopy,
    FiEye,
    FiFileText,
    FiMail,
    FiSettings,
    FiType,
    FiPieChart,
    FiArrowRight,
    FiExternalLink,
    FiArrowUp
} from 'react-icons/fi';

import myxss from 'components/ReactFormBuilder/form-elements/myxss';
import ScrollToTopButton from 'components/ScrollToTopButton';
import ListWrapper from 'components/ListWrapper';
import ItemWrapper from 'components/ItemWrapper';
import SideOverlay from 'components/SideOverlay';
import EventEmitter from 'utils/events';
import BreadcrumbDivider from 'components/Breadcrumb/BreadcrumbDivider';
import { HeaderSubnavButton } from 'components/Header/HeaderSubnavButton';
import VariablesHelper from 'components/EmailTools/VariablesHelper';
import EmailEditor from 'components/EmailTools/EmailEditor';
import AppPageWrapper from 'components/AppPageWrapper';
import AppPageWrapperSectionHeader from 'components/AppPageWrapper/AppPageWrapperSectionHeader';
import AppPageWrapperSectionBody from 'components/AppPageWrapper/AppPageWrapperSectionBody';
import AppPageWrapperSectionSubHeader from 'components/AppPageWrapper/AppPageWrapperSectionSubHeader';
import { CollapsibleContentContainer } from 'components/CollapsibleContent';
import { ReactFormBuilder } from 'components/ReactFormBuilder';
import ScreenerTranslationComponent from 'components/ScreenerTranslationComponent';
import {
    COL_EMAIL,
    SCREENER_STUDY_ONLY,
    SCREENER_ANYONE,
    SCREENER_INTERNAL_ONLY,
    SCREENER_INACTIVE,
    SCREENER_QUOTA_TYPES,
    SURVEY_QUOTA_FILTER_MAX_RESPONSES,
    SCREENER_QUOTA_TYPE_VALUE_ORQ,
    SCREENER_QUOTA_TYPE_VALUE_LQ,
    QUILL_MODULES,
    EMAIL_TEMPLATE_TYPE
} from 'utils/constants';
import { ActionButton } from 'components/Button/ActionButton';
import NiceModal from 'components/NiceModal';
import CreatePanel from 'components/Participant/CreatePanel';
import PanelSelect from 'components/PanelSelect';
import Progress from 'components/Progress';

import xhr from 'xhr.js';
import auth from 'services/auth';
import services from 'services/services';
import studies_service from 'services/studies';
import people_service from 'services/people';
import ReactFormBuilder_store from 'components/ReactFormBuilder/stores/store';
import PropTypes from 'prop-types';
import BreadcrumbBack, { BreadcrumbTitle } from 'components/BreadcrumbBack';
import classNames from 'classnames';

class StudyScreenerIndividual extends React.Component {
    constructor(props) {
        super(props);

        this.tab_screener_builder = 'screener-builder';
        this.tab_screener_email = 'bulk-screener-email';
        this.tab_screener_configuration = 'screener-configuration';
        this.tab_screener_redirect = 'screener-redirect';
        this.tab_screener_quotas = 'screener-quotas';
        this.tab_screener_configuration_labels = [
            'screener-configuration-internal-details',
            'screener-configuration-public-details',
            'screener-configuration-automatic-scheduling',
            'screener-configuration-custom-javascript'
        ];

        this.state = {
            isScreenerLoading: true,
            loading: true,
            study: {
                title: '',
                description: '',
                screener: []
            },
            screener: {
                links: [],
                quotas: []
            },
            screener_translation: null,
            editorData: null,
            editorType: null,
            available_columns: [],
            page_tabs: [
                {
                    title: 'Screener Builder',
                    label: this.tab_screener_builder,
                    icon: <FiFileText />,
                    external: false
                },
                {
                    title: 'Quotas',
                    label: this.tab_screener_quotas,
                    icon: <FiPieChart />,
                    external: false
                },
                {
                    title: 'Redirects & Language',
                    label: this.tab_screener_redirect,
                    icon: <FiType />,
                    external: false
                },
                {
                    title: 'Email Template',
                    label: this.tab_screener_email,
                    icon: <FiMail />,
                    external: true
                },
                {
                    title: 'Screener Settings',
                    label: this.tab_screener_configuration,
                    icon: <FiSettings />,
                    external: true
                }
            ],
            page_tab_selected: this.tab_screener_builder,
            should_warn_no_email_sync: false,
            //quotas: [{"id":"n95898","title":"Title of quota","type":"orq"}],
            create_quota: {},
            edit_quota: {},
            panels: [],
            showModalCreatePanel: false
        };

        this.handleTranslationChange = this.handleTranslationChange.bind(this);
        this.onFormChange = this.onFormChange.bind(this);
        this.onAssociatedPanelChange = this.onAssociatedPanelChange.bind(this);
        this.onChangeEditor = this.onChangeEditor.bind(this);
        this.removeCustomLink = this.removeCustomLink.bind(this);
        this.setCustomLink = this.setCustomLink.bind(this);
        this.onCreateQuota = this.onCreateQuota.bind(this);
        this.onDeleteQuota = this.onDeleteQuota.bind(this);
        this.xhrSaveQuotas = this.xhrSaveQuotas.bind(this);
        this.xhrGetPanels = this.xhrGetPanels.bind(this);
        this.onCreatePanel = this.onCreatePanel.bind(this);

        this.internalDetailsRef = React.createRef();
        this.publicDetailsRef = React.createRef();
        this.automaticSchedulingRef = React.createRef();
        this.customJavascriptRef = React.createRef();
        this.analyticsRef = React.createRef();
    }

    static propTypes = {
        match: PropTypes.object.isRequired,
        location: PropTypes.object.isRequired,
        history: PropTypes.object.isRequired
    };

    setPageTab(tab) {
        //if (tab != this.tab_screener_builder) {
        //console.log('getting screener...')
        this.getScreener();
        //}
        this.props.history.push(`#${tab}`);
        // this.setState({ page_tab_selected: tab });
    }

    getCustomDataColumnsXHR(cb) {
        const { toastManager } = this.props;
        services
            .getCustomDataColumnsXHR()
            .then(available_columns => {
                this.setState({ available_columns });

                cb();
            })
            .catch(error => {
                let errorText = services.parseAndTrackXhrErrors(error);

                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    onChangeEditor(editorData, editorType) {
        this.onFormChange({
            target: {
                value: {
                    editorData,
                    editorType
                },
                name: 'email_screener'
            }
        });
    }

    onFormChange(e) {
        //console.log('PARENT FORM CHANGE', e);
        const { toastManager } = this.props;
        if (!e.target.name) {
            return;
        }

        if (e.target.name == 'participant_external') {
            // if we allow non-panelists to take the screener we need to create profiles for them
            // this means requiring an email
            if (e.target.value == 1) {
                let found_email_question = false;
                //console.log('allow!');
                // verify that there is an email-mapped question in the screener
                const email_custom_column = this.state.available_columns.find(column => column.title == COL_EMAIL);
                //console.log(this.state.available_columns, email_custom_column)
                if (email_custom_column) {
                    // find a screener question that maps to this
                    this.state.screener.form.forEach(screener_page_questions => {
                        screener_page_questions.forEach(question => {
                            //console.log(question.label, question)
                            if (
                                question.personCustomDataId == email_custom_column.id &&
                                question.save_to_panel == true &&
                                question.required == true
                            ) {
                                found_email_question = true;
                            }
                        });
                    });
                }

                if (!found_email_question) {
                    const errorMsg =
                        'In order to allow Anyone to take the screener, please (1) Create a question in your screener asking for their email, (2) require it, and (3) enable "Save answers to the participant profile = Email".';
                    toastManager.add(errorMsg, {
                        appearance: 'error',
                        autoDismiss: true
                    });
                    return;
                }
            }
        }

        const screener = { ...this.state.screener };

        let editorType,
            editorData = null;
        if (e.target.name === 'custom_js') {
            // deprecated 2023.09.12 because of security concerns
            //screener[e.target.name] = [{ when: '', execute: e.target.value }];
        } else if (e.target.name === 'email_screener') {
            editorType = e.target.value.editorType;
            editorData = this.formatEditorData(e.target.value.editorData, editorType);

            screener['email_screener_body'] = editorData;
            screener['email_screener_type'] = editorType;
        } else {
            screener[e.target.name] = e.target.value;
        }

        this.setState({ screener }, () => {
            this.saveScreener();

            if (e && e.target && e.target.name === 'email_screener') {
                //console.log('here1');
                this.setState({ editorData, editorType });
            }
        });
    }

    /**
     * Update screener data and save it to the server
     *
     * @param {{ body: any; subject: string; type: number; id: number }} template
     */
    onTemplate(template) {
        this.setState(
            ({ screener }) => {
                screener.email_screener_body = template.body;
                screener.email_screener_subject = template.subject;
                screener.email_screener_type = template.type;
                return { screener, editorData: template.body, editorType: template.type };
            },
            () => this.saveScreener()
        );
    }

    onAssociatedPanelChange(panel_id) {
        const screener = { ...this.state.screener };
        screener.panel_id = panel_id;
        this.setState({ screener }, () => {
            this.saveScreener();
        });
    }

    saveScreener() {
        const { toastManager } = this.props;

        // detect change, and clear timeout if exists
        if (this.saveDelay) {
            clearTimeout(this.saveDelay);
        }

        this.saveDelay = setTimeout(() => {
            const screener = { ...this.state.screener };

            // clear out any XSS
            screener.screener_translation.confirmation_page_text = myxss.process(
                screener.screener_translation.confirmation_page_text
            );
            screener.screener_translation.disqualification_page_text = myxss.process(
                screener.screener_translation.disqualification_page_text
            );

            studies_service
                .updateScreener(this.state.screener.study_id, this.state.screener.id, screener)
                .then(() => {
                    /* toastManager.add('Saved', {
                        appearance: 'success',
                        autoDismiss: true
                    }); */
                })
                .catch(error => {
                    const errorText = services.parseAndTrackXhrErrors(error);

                    toastManager.add(errorText, {
                        appearance: 'error',
                        autoDismiss: true
                    });
                });
        }, 500);
    }

    handleTranslationChange(e) {
        const { screener } = this.state;
        screener.screener_translation[e.target.name] = e.target.value;

        this.setState({ screener }, () => {
            this.saveScreener();
        });
    }

    onSwitchChange(e) {
        console.log(e);

        const study = { ...this.state.study };
        const newStatus = study.status == 'active' ? 'paused' : 'active';

        console.log(newStatus);

        study.status = newStatus;
        this.setState({ study });

        this.updateStatusXhr(newStatus);
    }

    formatEditorData(editor_data, editorType) {
        if (editorType === EMAIL_TEMPLATE_TYPE.BLOCKS) {
            return helpers.isObject(editor_data) ? editor_data : helpers.makeBlocksFromText(editor_data);
        }

        return helpers.isObject(editor_data) ? JSON.stringify(editor_data) : editor_data;
    }

    getScreenerFormSaveUrl(screener) {
        const url = `/studies/${this.props.match.params.study_id}/screeners/${this.props.match.params.screener_id}/form?account_id=${this.props.auth.account.id}&form_updated_at=${screener.form_updated_at}`;
        //console.log('getting new url...', url)
        return url;
    }

    emitUpdatedFormSaveUrl(screenerObject) {
        ReactFormBuilder_store.dispatch('updateSaveUrl', this.getScreenerFormSaveUrl(screenerObject));
    }

    componentDidMount() {
        const that = this;
        EventEmitter.subscribe('screener.save.success', screenerObject => {
            //console.log('screener.save.success', 'ping!')
            that.emitUpdatedFormSaveUrl(screenerObject);
            this.checkForEmailIfAnyone(screenerObject);
        });
        EventEmitter.subscribe('screener.save.error', error => {
            //console.log('screener.save.error', error)
            const { toastManager } = this.props;
            const errorText = services.parseAndTrackXhrErrors(error);

            toastManager.add(errorText, {
                appearance: 'error',
                autoDismiss: false
            });
        });
        EventEmitter.subscribe('screener.save.slow', error => {
            const { toastManager } = this.props;
            const warningText =
                'Saving screener took longer than usual, this may be your "upload" internet connection. If you keep seeing errors, please restart your internet.';

            toastManager.add(warningText, {
                appearance: 'warning',
                autoDismiss: true
            });
        });

        this.getStudy();

        // we should get the columns first, as they're a prerequisite to building the screener
        this.getCustomDataColumnsXHR(() => {
            this.getScreener();
        });
        this.xhrGetPanels();
        this.onRouteChanged();
    }

    componentDidUpdate(prevProps) {
        if (this.props.location !== prevProps.location) {
            this.onRouteChanged();
        }
    }

    onRouteChanged() {
        const { location } = this.props;
        const tabLabel = location.hash.replace('#', '');
        if (this.state.page_tabs.filter(tab => tab.label === tabLabel).length > 0)
            this.setState({ page_tab_selected: tabLabel || this.state.page_tabs[0].label });
        else if (this.tab_screener_configuration_labels.filter(label => label === tabLabel).length > 0)
            this.setState({ page_tab_selected: this.tab_screener_configuration });
    }

    getStudy() {
        xhr.get(`/studies/${this.props.match.params.study_id}?account_id=${this.props.auth.account.id}`, {
            withCredentials: true
        }).then(res => {
            const study = res.data;
            //console.log('getStudy', study)
            this.setState({
                study,
                loading: false
            });
        });
    }

    getScreener() {
        studies_service
            .getScreener(this.props.match.params.study_id, this.props.match.params.screener_id)
            .then(screener => {
                //setScreeners(screeners);
                //setIsLoading(false);
                //console.log(screener.form_updated_at)
                const editorData_xhr = this.formatEditorData(
                    screener.email_screener_body,
                    screener.email_screener_type
                );

                this.emitUpdatedFormSaveUrl(screener);

                ReactFormBuilder_store.dispatch('updateOrder', { elements: screener.form, saveData: false });

                if (helpersScreener.isExternalSurveyConnected(screener)) {
                    const filteredTabs = this.state.page_tabs.filter(tab => tab.external);
                    const foundSelectedTab = filteredTabs.find(tab => tab.label == this.state.page_tab_selected);
                    this.setState({
                        page_tabs: filteredTabs,
                        page_tab_selected: foundSelectedTab
                            ? this.state.page_tab_selected
                            : this.tab_screener_configuration
                    });
                }

                this.setState(
                    {
                        screener,
                        editorData: editorData_xhr,
                        editorType: screener.email_screener_type,
                        isScreenerLoading: false
                    },
                    () => {
                        this.checkForEmailIfAnyone(screener);
                    }
                );
            })
            .catch(error => {});
    }

    xhrGetPanels() {
        const { toastManager } = this.props;
        people_service
            .getPanels()
            .then(panels_response => {
                this.setState({ panels: panels_response });
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);
                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    onCreatePanel(panel) {
        const { toastManager } = this.props;
        toastManager.add('Successfully created a new panel', {
            appearance: 'success',
            autoDismiss: true
        });
        this.xhrGetPanels();
        this.onAssociatedPanelChange(panel.id);
    }

    removeCustomLink() {
        const { toastManager } = this.props;
        const isConfirmed = confirm(
            'Are you sure you want to permanently delete this link? If you shared this link with anyone, they will no longer be able to access this screener.'
        );
        if (isConfirmed) {
            studies_service
                .deleteScreenerCustomLink(this.state.screener.study_id, this.state.screener.id)
                .then(custom_links => {
                    toastManager.add('Removed custom link', {
                        appearance: 'success',
                        autoDismiss: true
                    });
                    let screener = this.state.screener;
                    screener.links = custom_links;
                    this.setState({ screener });
                })
                .catch(error => {
                    const errorText = services.parseAndTrackXhrErrors(error);

                    toastManager.add(errorText, {
                        appearance: 'error',
                        autoDismiss: true
                    });
                });
        } else {
            console.log('not deleting');
        }
    }

    setCustomLink() {
        const { toastManager } = this.props;
        const newLink = prompt('What would you like the link to be?');
        if (newLink) {
            studies_service
                .setScreenerCustomLink(this.state.screener.study_id, this.state.screener.id, newLink)
                .then(custom_links => {
                    toastManager.add('Added custom link', {
                        appearance: 'success',
                        autoDismiss: true
                    });
                    let screener = this.state.screener;
                    screener.links = custom_links;
                    this.setState({ screener });
                })
                .catch(error => {
                    const errorText = services.parseAndTrackXhrErrors(error);

                    toastManager.add(errorText, {
                        appearance: 'error',
                        autoDismiss: true
                    });
                });
        }
    }

    checkForEmailIfAnyone(screener) {
        //console.log('checkForEmailIfAnyone running', screener)
        let should_warn_no_email_sync = false;
        if (screener && SCREENER_ANYONE == screener.participant_external) {
            should_warn_no_email_sync = true;
            const email_custom_column = this.state.available_columns.find(column => column.title == COL_EMAIL);
            //console.log('for anyone..', email_custom_column)
            screener.form.forEach(screener_page_questions => {
                screener_page_questions.forEach(question => {
                    if (
                        question.required == true &&
                        question.save_to_panel == true &&
                        email_custom_column &&
                        question.personCustomDataId == email_custom_column.id
                    ) {
                        //console.log('found email sync')
                        should_warn_no_email_sync = false;
                    }
                });
            });
        }
        //console.log('should_warn_no_email_sync', should_warn_no_email_sync)
        this.setState({ should_warn_no_email_sync: should_warn_no_email_sync });
        //return should_warn_no_email_sync;
    }

    componentDidCatch(error, errorInfo) {
        helpers.trackError(error);
    }

    makeDefaultQuotaObject(title, type) {
        return {
            id: helpers.generateUID(),
            title: title,
            type: type,
            options: {
                limit: null,
                message_dq_max: '<p>Sorry, the maximum number of responses has been met. Thanks for your time!</p>'
            },
            // ensure this takes GROUPS
            filters: []
        };

        /*
            "filters": [{
                "type": "group",
                "id": "o9fxjn",
                "values": [{
                    "type": "screener",
                    "and_or_value": "and",
                    "field_name": "radiobuttons_7EAA9F72-DD4F-4BA4-B391-BB0D342FF713",
                    "compare": "is_one_of",
                    "values": ["option_C70C227E-D4E0-4AF3-A382-07E15DD0034A"]
                }, {
                    "type": "screener",
                    "and_or_value": "and",
                    "field_name": "radiobuttons_6DA95E14-C9A0-4F64-AAC1-DF3255899EB7",
                    "compare": "is_one_of",
                    "values": ["option_CC577BE9-0B20-47F9-A119-99D23471A094"]
                }]
            }, {
                "type": "screener",
                "and_or_value": "and",
                "field_name": "text_input_A129D380-EB39-4779-A5CA-64AB32D12550",
                "compare": "is",
                "values": ["123"]
            }],
            "when": [],
            "title": "foob"
        */
    }

    makeMaxResponsesFilter(max_responses) {
        return {
            id: helpers.generateUID(),
            type: SURVEY_QUOTA_FILTER_MAX_RESPONSES,
            values: [max_responses]
        };
    }

    xhrSaveQuotas(quotas, cb) {
        const { toastManager } = this.props;
        studies_service
            .updateScreenerQuotas(this.state.screener.study_id, this.state.screener.id, quotas)
            .then(ss => {
                cb();
            })
            .catch(error => {
                cb(error);

                let errorText = services.parseAndTrackXhrErrors(error);

                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    xhrRefreshQuotas(quota_id) {
        const { toastManager } = this.props;
        this.setState({ recalculatingQuotaId: quota_id });
        studies_service
            .refreshScreenerQuotaCount(this.state.screener.study_id, this.state.screener.id, quota_id)
            .then(quota_counts => {
                let screener = cloneDeep(this.state.screener);
                screener.quota_counts = quota_counts;
                this.setState({ screener });
                toastManager.add('Quota successfully re-calculated', {
                    appearance: 'success',
                    autoDismiss: true
                });
                this.setState({ recalculatingQuotaId: null });
            })
            .catch(error => {
                let errorText = services.parseAndTrackXhrErrors(error);

                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    onClickEditQuota(quota) {
        this.setState({ showEditQuota: true, edit_quota: quota });
    }

    onDeleteQuota(quota_id) {
        const { toastManager } = this.props;
        const isConfirmed = confirm('Are you sure you want to permanently delete this quota?');
        if (isConfirmed) {
            let screener = cloneDeep(this.state.screener);
            let quota_index_found = screener.quotas.findIndex(q => q.id == quota_id);
            if (quota_index_found >= 0) {
                screener.quotas.splice(quota_index_found, 1);

                this.xhrSaveQuotas(screener.quotas, err => {
                    if (err) {
                        //this.setState({createQuotaProcessing: false});
                    } else {
                        this.setState({ screener });
                        toastManager.add('Quota successfully deleted', {
                            appearance: 'success',
                            autoDismiss: true
                        });
                    }
                });
            }
        }
    }

    onEditQuota(quota_updated) {
        const { toastManager } = this.props;

        let screener = cloneDeep(this.state.screener);
        let quota_index_found = screener.quotas.findIndex(q => q.id == quota_updated.id);
        if (quota_index_found >= 0) {
            const validation_error = this.onValidateQuotaFilters(quota_updated);
            if (validation_error) {
                return;
            }

            screener.quotas[quota_index_found] = quota_updated;

            this.xhrSaveQuotas(screener.quotas, err => {
                if (err) {
                    //this.setState({createQuotaProcessing: false});
                } else {
                    this.setState({ screener, showEditQuota: false, onEditQuota: {} });
                    toastManager.add('Quota successfully updated', {
                        appearance: 'success',
                        autoDismiss: true
                    });
                }
            });
        }
    }

    onCreateQuota(e) {
        e.preventDefault();

        let type, max_responses_value;
        const { toastManager } = this.props;
        const title = document.querySelector('input[name="create_quota_title"]').value;
        const type_checked = document.querySelector('input[name="create_quota_type"]:checked');
        const max_responses_element = document.querySelector('input[name="create_quota_value_max_responses"]');
        if (max_responses_element) {
            max_responses_value = max_responses_element.value;
        }

        if (type_checked) {
            type = type_checked.value;
        }

        if (!title) {
            toastManager.add('Please set a Title', {
                appearance: 'error',
                autoDismiss: true
            });
            return;
        }

        if (!type) {
            toastManager.add('Please set a Type', {
                appearance: 'error',
                autoDismiss: true
            });
            return;
        }

        // if new type to be created is ORQ..
        // double check if there already exists an SCREENER_QUOTA_TYPE_VALUE_ORQ
        if (type == SCREENER_QUOTA_TYPE_VALUE_ORQ) {
            const found_existing_max_resp_quota = this.state.screener.quotas.find(
                q => q.type == SCREENER_QUOTA_TYPE_VALUE_ORQ
            );
            console.log(this.state.screener.quotas, found_existing_max_resp_quota);
            if (found_existing_max_resp_quota) {
                toastManager.add('An "overall response quota" already exists for this survey.', {
                    appearance: 'error',
                    autoDismiss: true
                });
                return;
            }
        }

        this.setState({ createQuotaProcessing: true });

        let screener = cloneDeep(this.state.screener);
        const new_quota = this.makeDefaultQuotaObject(title, type);
        if (helpers.isNumber(max_responses_value) && max_responses_value > 0) {
            new_quota.options.limit = max_responses_value;
            //new_quota.filters.push(this.makeMaxResponsesFilter(max_responses_value));
        }
        screener.quotas.push(new_quota);

        this.xhrSaveQuotas(screener.quotas, err => {
            if (err) {
                this.setState({ createQuotaProcessing: false });
            } else {
                this.setState({ screener, showModalCreateQuota: false, createQuotaProcessing: false });
                if (type == SCREENER_QUOTA_TYPE_VALUE_LQ) {
                    this.onClickEditQuota(new_quota);
                }

                toastManager.add('Quota successfully created', {
                    appearance: 'success',
                    autoDismiss: true
                });

                this.xhrRefreshQuotas(new_quota.id);
            }
        });
    }

    renderQuotaProgress(quota) {
        let render = [];
        if (quota && quota.options) {
            let max_res_value = quota.options.limit;

            // find quota calculations
            let matched_quota_value = 0;
            if (this.state.screener.quota_counts && this.state.screener.quota_counts.hasOwnProperty(quota.id)) {
                matched_quota_value = this.state.screener.quota_counts[quota.id];
            }
            const percentage = helpers.getPercentage(matched_quota_value, max_res_value);
            render.push(
                <Box>
                    <span className="text-primary">
                        {matched_quota_value}/{max_res_value}
                    </span>
                </Box>
            );

            /*if (quota.filters && quota.filters.length) {
                render.push(<Box>{JSON.stringify(quota.filters)}</Box>)
            }*/
        }
        return render;
    }

    getQuotaPercent(quota) {
        if (quota && quota.options) {
            let max_res_value = quota.options.limit;

            // find quota calculations
            let matched_quota_value = 0;
            if (this.state.screener.quota_counts && this.state.screener.quota_counts.hasOwnProperty(quota.id)) {
                matched_quota_value = this.state.screener.quota_counts[quota.id];
            }
            return helpers.getPercentage(matched_quota_value, max_res_value);
        }
    }

    renderValidateQuotaFilters(edit_quota) {
        const error = this.onValidateQuotaFilters(edit_quota);
        if (error) {
            return (
                <Box className="error fs-12" mt={1}>
                    {error}
                </Box>
            );
        }
    }

    onValidateQuotaFilters(edit_quota) {
        let error = false;
        if (edit_quota && edit_quota.filters && edit_quota.filters.length) {
            edit_quota.filters.forEach((filter, filter_index) => {
                if (filter && filter.type == SURVEY_QUOTA_FILTER_MAX_RESPONSES) {
                    let max_res_value = filter.values && filter.values[0] ? filter.values[0] : null;
                    if (!max_res_value || max_res_value <= 0) {
                        error = 'Must be greater than 0.';
                    }
                }
            });
        }
        return error;
    }

    renderQuotaFiltersToEdit() {
        let edit_quota = cloneDeep(this.state.edit_quota);
        const original_quota = this.state.screener.quotas.find(q => q.id == edit_quota.id);
        let render = [];
        if (edit_quota) {
            render.push(
                <Box mb={3}>
                    <Label>Title</Label>
                    <Input
                        type="text"
                        value={edit_quota.title}
                        sx={{ width: '100%' }}
                        onChange={e => {
                            edit_quota.title = e.target.value;
                            this.setState({ edit_quota });
                        }}
                        required
                    />
                </Box>
            );

            render.push(
                <Box mb={3}>
                    <Label>Maximum responses</Label>
                    <Input
                        type="number"
                        value={edit_quota.options.limit}
                        sx={{ width: '160px' }}
                        onChange={e => {
                            edit_quota.options.limit = e.target.value;
                            this.setState({ edit_quota });
                        }}
                        required
                        min={1}
                    />
                </Box>
            );

            if (edit_quota.type == SCREENER_QUOTA_TYPE_VALUE_LQ) {
                render.push(
                    <Box mb={3}>
                        <Label>Filters</Label>
                        <StudyFilters
                            disallowStandardFilters={true}
                            disallowPanelFilters={true}
                            study={this.state.study}
                            screenerIdsOnly={[this.state.screener.id]}
                            screenerFilters={edit_quota.filters}
                            onScreenerFiltersChange={sf => {
                                edit_quota.filters = sf;
                                this.setState({ edit_quota });
                            }}
                            onClose={() => {
                                this.setState({
                                    showEditQuota: false
                                });
                            }}
                        />
                    </Box>
                );
            }

            render.push(
                <Box mb={3}>
                    <Label>Quota Disqualify Message</Label>
                    <ReactQuill
                        theme="snow"
                        className="quill-render"
                        modules={QUILL_MODULES}
                        value={edit_quota.options.message_dq_max}
                        onChange={html => {
                            edit_quota.options.message_dq_max = html;
                            this.setState({ edit_quota });
                        }}
                    />
                </Box>
            );

            console.log('compare', edit_quota, original_quota, _.isEqual(edit_quota, original_quota));

            render.push(
                <Flex
                    mt={3}
                    sx={{
                        position: 'sticky',
                        bottom: 0,
                        left: 0,
                        right: 0,
                        padding: '16px 0 24px',
                        background: 'white',
                        width: '100%'
                    }}
                >
                    <Button variant="primary" type="submit" mr={2}>
                        Update Quota
                    </Button>
                    <Button
                        variant="secondary-gray"
                        type="button"
                        mr={3}
                        onClick={() => {
                            this.setState({ showEditQuota: false });
                        }}
                    >
                        Cancel
                    </Button>
                    {_.isEqual(edit_quota, original_quota) ? (
                        ''
                    ) : (
                        <Box className="fs-12 warning" mt={'6px'}>
                            Changes not saved
                        </Box>
                    )}
                </Flex>
            );

            return (
                <form
                    onSubmit={e => {
                        e.preventDefault();
                        this.onEditQuota(this.state.edit_quota);
                    }}
                >
                    {render}
                </form>
            );
        }
    }

    renderQuotaItemInList(q) {
        return (
            <ItemWrapper key={q.id}>
                <Box
                    width={3 / 16}
                    className="text-primary"
                    onClick={() => this.onClickEditQuota(q)}
                    sx={{ cursor: 'pointer' }}
                >
                    <Box>{q.title}</Box>
                </Box>
                <Box width={3 / 16} className="text-primary">
                    {helpers.getScreenerQuotaTypeHumanReadable(q.type)}
                </Box>

                <Box width={2 / 16} className="text-primary">
                    {this.renderQuotaProgress(q)}
                </Box>
                <Flex width={4 / 16} className="text-primary flex items-center">
                    <Box mr="16px">{this.getQuotaPercent(q)}%</Box>
                    <Progress style={{ width: '200px' }} value={this.getQuotaPercent(q)} />
                </Flex>
                <Box width={1 / 16} className="text-primary">
                    {q.filters.length} filters
                </Box>
                <Box width={3 / 16} style={{ textAlign: 'right' }}>
                    <Button
                        type="button"
                        variant="secondary-gray" // className='secondary-icon'
                        onClick={() => this.onClickEditQuota(q)}
                        mr={2}
                    >
                        <FiEdit /> Edit
                    </Button>
                    <Button
                        type="button"
                        variant="secondary-gray"
                        className="secondary-icon"
                        onClick={() => this.xhrRefreshQuotas(q.id)}
                        mr={2}
                        disabled={this.state.recalculatingQuotaId == q.id}
                    >
                        <FiRefreshCw />
                    </Button>
                    <Button
                        type="button"
                        variant="secondary-gray"
                        className="secondary-icon secondary-red"
                        onClick={() => this.onDeleteQuota(q.id)}
                        mr={0}
                    >
                        <FiTrash2 />
                    </Button>
                </Box>
            </ItemWrapper>
        );
    }

    render() {
        //console.log('StudyScreenerIndividual rendering...');
        const screener_link = helpersScreener.getScreenerCustomLink(this.state.screener, this.props.auth.account);
        const preview_screener_button = (
            <a href={`${screener_link}?is_preview=1`} target="_blank">
                <Button variant="secondary-gray" mr={0}>
                    <FiEye />
                    Preview Screener
                </Button>
            </a>
        );
        const studyId = this.props.match.params.study_id;

        return (
            <Flex style={{ width: '100%' }}>
                <AppPageWrapper>
                    <Helmet>
                        <title>{this.state.study.title} - Screener</title>

                        {/* <link
                            rel="stylesheet"
                            href="https://cdn.jsdelivr.net/npm/bootstrap@4.5.3/dist/css/bootstrap.min.css"
                            integrity="sha384-TX8t27EcRE3e/ihU7zmQxVncDAy5uIKz4rEkgIXeMed4M0jlfIDPvg6uqKI2xXr2"
                            crossOrigin="anonymous"
                        /> */}
                    </Helmet>
                    <AppPageWrapperSectionHeader className="experience experience-no-subnav">
                        <Box>
                            <BreadcrumbBack to={`/studies/${this.props.match.params.study_id}/screeners`}>
                                Back to all screeners
                            </BreadcrumbBack>
                            <BreadcrumbTitle
                                title={this.state.screener.title}
                                isLoading={this.state.isScreenerLoading}
                            />
                        </Box>
                    </AppPageWrapperSectionHeader>
                    <Flex sx={{ flexDirection: 'row' }} px={4} className="subnav-border">
                        {/*tertiary-nav-padded*/}
                        {this.state.page_tabs.map(pt => {
                            return (
                                <HeaderSubnavButton
                                    //style={{ padding: '0 0 8px 0' }}
                                    className={this.state.page_tab_selected == pt.label && 'nav-item-active'}
                                    onClick={() => this.setPageTab(pt.label)}
                                >
                                    {pt.icon}
                                    {pt.title}
                                </HeaderSubnavButton>
                            );
                        })}
                    </Flex>

                    <AppPageWrapperSectionBody
                        style={{
                            background: this.state.page_tab_selected == this.tab_screener_builder ? '#f6f6f6' : '#fff'
                        }}
                    >
                        {this.state.loading ? (
                            <div style={{ width: '100%' }}>
                                <LoadingWrapper>
                                    <LoadingIndicator />
                                </LoadingWrapper>
                            </div>
                        ) : (
                            <Flex mt={3} style={{ width: '100%' }}>
                                <Box mx={4} style={{ width: '100%' }}>
                                    {this.state.page_tab_selected == this.tab_screener_quotas && (
                                        <Box>
                                            <AppPageWrapperSectionHeader className="experience" style={{ padding: 0 }}>
                                                <Box>
                                                    <H1>Quotas</H1>

                                                    <Box className="experience-description">
                                                        Quotas are limits you can set for the number of responses your
                                                        survey collects.
                                                        <br />
                                                        <a href="#" data-beacon-article="6306a79ab67994108536aaf4">
                                                            <FiBookOpen /> How to use Quotas
                                                        </a>
                                                    </Box>
                                                </Box>
                                                <Box mt={3}>
                                                    <ActionButton
                                                        title="New Quota"
                                                        action={() => this.setState({ showModalCreateQuota: true })}
                                                        keyLabel="N"
                                                        mr={0}
                                                    />
                                                </Box>
                                            </AppPageWrapperSectionHeader>
                                            <Box mt={4} sx={{ width: '100%' }}>
                                                {this.state.screener.quotas.length == 0 ? (
                                                    <strong>No quotas created.</strong>
                                                ) : (
                                                    <>
                                                        <ItemWrapper className="header-row">
                                                            <Box width={3 / 16}>Title</Box>
                                                            <Box width={3 / 16}>Type</Box>
                                                            <Box width={2 / 16}>Responses</Box>
                                                            <Box width={4 / 16}>Progress</Box>
                                                            <Box width={1 / 16}>Filters</Box>
                                                            <Box width={3 / 16} sx={{ textAlign: 'right' }}></Box>
                                                        </ItemWrapper>
                                                        {this.state.screener.quotas.map(q => {
                                                            if (q.type == SCREENER_QUOTA_TYPE_VALUE_ORQ) {
                                                                return this.renderQuotaItemInList(q);
                                                            }
                                                        })}
                                                        {this.state.screener.quotas.map(q => {
                                                            if (q.type == SCREENER_QUOTA_TYPE_VALUE_LQ) {
                                                                return this.renderQuotaItemInList(q);
                                                            }
                                                        })}
                                                    </>
                                                )}
                                            </Box>
                                        </Box>
                                    )}
                                    {this.state.page_tab_selected == this.tab_screener_configuration && (
                                        <Flex flex="1 1 0%" justifyContent="space-between">
                                            <Box flex="0 0 auto">
                                                <Box
                                                    id="screener-configuration-internal-details"
                                                    ref={this.internalDetailsRef}
                                                    className="form-wrapper2 form-wrapper2-first"
                                                >
                                                    <Box className="settings-header">
                                                        <H2>Internal Details</H2>
                                                        <Box className="form-section-information">
                                                            This information will not be public.
                                                        </Box>
                                                    </Box>
                                                    <Box className="form-data">
                                                        <Box className="form-label">Associated Panel</Box>
                                                        <Box>
                                                            <PanelSelect
                                                                width="100%"
                                                                variant="gray"
                                                                items={this.state.panels}
                                                                value={this.state.screener.panel_id}
                                                                onChange={this.onAssociatedPanelChange}
                                                                onCreatePanel={() =>
                                                                    this.setState({
                                                                        showModalCreatePanel: true
                                                                    })
                                                                }
                                                            />
                                                        </Box>
                                                        <Box className="form-label form-row-margin">Internal Title</Box>
                                                        <Box>
                                                            <Input
                                                                type="text"
                                                                name="title"
                                                                value={this.state.screener.title}
                                                                onChange={this.onFormChange}
                                                            />
                                                        </Box>
                                                        <Box className="form-label form-row-margin">
                                                            Internal Description
                                                        </Box>
                                                        <Box>
                                                            <Textarea
                                                                name="description"
                                                                value={this.state.screener.description}
                                                                onChange={this.onFormChange}
                                                            />
                                                        </Box>

                                                        <Box className="form-label form-row-margin">
                                                            Who can take the screener?{' '}
                                                            <a
                                                                href="#"
                                                                data-beacon-article="6305543bb67994108536a455"
                                                                className="help-question"
                                                            >
                                                                ?
                                                            </a>
                                                        </Box>
                                                        <Box>
                                                            <Select
                                                                name="participant_external"
                                                                value={this.state.screener.participant_external}
                                                                onChange={this.onFormChange}
                                                                //sx={{maxWiwiddth: '320px'}}
                                                                disabled={helpersScreener.isExternalSurveyConnected(
                                                                    this.state.screener
                                                                )}
                                                            >
                                                                <option value={SCREENER_STUDY_ONLY}>
                                                                    Study Participants
                                                                </option>
                                                                <option value={SCREENER_ANYONE}>
                                                                    Anyone (even people outside my panel)
                                                                </option>
                                                                <option value={SCREENER_INTERNAL_ONLY}>
                                                                    Private (internal-facing screener)
                                                                </option>
                                                                <option value={SCREENER_INACTIVE}>
                                                                    Nobody (screener inactive)
                                                                </option>
                                                            </Select>

                                                            {[SCREENER_ANYONE, SCREENER_STUDY_ONLY].includes(
                                                                parseInt(this.state.screener.participant_external)
                                                            ) &&
                                                                !helpersScreener.isExternalSurveyConnected(
                                                                    this.state.screener
                                                                ) && (
                                                                    <Box className="form-row-margin">
                                                                        <Flex sx={{ gridGap: '24px' }}>
                                                                            <Box width={1 / 2}>
                                                                                <Box className="form-label">
                                                                                    Profile Verification
                                                                                </Box>
                                                                                <Switch
                                                                                    className="switch-small"
                                                                                    name="verify_email"
                                                                                    checked={
                                                                                        this.state.screener.verify_email
                                                                                            ? true
                                                                                            : false
                                                                                    }
                                                                                    onClick={e => {
                                                                                        this.onFormChange({
                                                                                            target: {
                                                                                                name: 'verify_email',
                                                                                                // set the opposite
                                                                                                value: this.state
                                                                                                    .screener
                                                                                                    .verify_email
                                                                                                    ? 0
                                                                                                    : 1
                                                                                            }
                                                                                        });
                                                                                    }}
                                                                                />

                                                                                <span className="text-primary switch-label">
                                                                                    Respondents verify email{' '}
                                                                                    <TooltipIcon
                                                                                        for={`verify_email-tooltip`}
                                                                                        margin="-2px 0 0 4px"
                                                                                        verticalAlign="top"
                                                                                    />
                                                                                </span>

                                                                                <ReactTooltip
                                                                                    id={`verify_email-tooltip`}
                                                                                    effect="solid"
                                                                                    place="top"
                                                                                >
                                                                                    <Box>
                                                                                        When a respondent loads a
                                                                                        "personal" screener link,
                                                                                        <br />
                                                                                        at the start of the screener the
                                                                                        respondent will be
                                                                                        <br />
                                                                                        prompted to type in their email
                                                                                        for verification.
                                                                                        <br />
                                                                                        They will not be able to proceed
                                                                                        if they type an
                                                                                        <br />
                                                                                        email which does not match their
                                                                                        panel profile.
                                                                                    </Box>
                                                                                </ReactTooltip>
                                                                            </Box>
                                                                            <Box width={1 / 2}>
                                                                                <Box className="form-label">
                                                                                    Fraud Prevention
                                                                                </Box>
                                                                                <Box>
                                                                                    <Switch
                                                                                        className="switch-small"
                                                                                        name="show_dupe_ip"
                                                                                        checked={
                                                                                            this.state.screener
                                                                                                .show_dupe_ip
                                                                                                ? true
                                                                                                : false
                                                                                        }
                                                                                        onClick={e => {
                                                                                            this.onFormChange({
                                                                                                target: {
                                                                                                    name:
                                                                                                        'show_dupe_ip',
                                                                                                    // set the opposite
                                                                                                    value: this.state
                                                                                                        .screener
                                                                                                        .show_dupe_ip
                                                                                                        ? 0
                                                                                                        : 1
                                                                                                }
                                                                                            });
                                                                                        }}
                                                                                    />

                                                                                    <span className="text-primary switch-label">
                                                                                        Flag duplicate IPs{' '}
                                                                                        <a
                                                                                            href="#"
                                                                                            data-beacon-article="6321057ac713d51da3ede31e"
                                                                                            className="help-question"
                                                                                        >
                                                                                            ?
                                                                                        </a>
                                                                                    </span>
                                                                                </Box>
                                                                            </Box>
                                                                        </Flex>
                                                                    </Box>
                                                                )}
                                                        </Box>

                                                        <Box className="form-label form-row-margin">Screener UUID</Box>
                                                        <Box className="fs-12" sx={{ marginTop: '3px' }}>
                                                            {this.state.screener.uuid}
                                                        </Box>
                                                    </Box>
                                                </Box>
                                                {[SCREENER_ANYONE, SCREENER_STUDY_ONLY].includes(
                                                    parseInt(this.state.screener.participant_external)
                                                ) && (
                                                    <>
                                                        <Box
                                                            id="screener-configuration-public-details"
                                                            ref={this.publicDetailsRef}
                                                            className="form-wrapper2 form-wrapper2-border"
                                                        >
                                                            <Box className="settings-header">
                                                                <H2>Public Details</H2>
                                                                <Box className="form-section-information">
                                                                    How the screener will look for Panelists, and on
                                                                    social media like Facebook & Twitter.
                                                                </Box>
                                                            </Box>
                                                            <Box className="form-data">
                                                                {!helpersScreener.isExternalSurveyConnected(
                                                                    this.state.screener
                                                                ) && (
                                                                    <>
                                                                        <Box className="form-label">Public Title</Box>
                                                                        <Box>
                                                                            <Input
                                                                                type="text"
                                                                                name="public_title"
                                                                                value={this.state.screener.public_title}
                                                                                onChange={this.onFormChange}
                                                                            />
                                                                        </Box>
                                                                        <Box className="form-label form-row-margin">
                                                                            Public Description
                                                                        </Box>
                                                                        <Box>
                                                                            <Textarea
                                                                                name="public_description"
                                                                                value={
                                                                                    this.state.screener
                                                                                        .public_description
                                                                                }
                                                                                onChange={this.onFormChange}
                                                                            />
                                                                        </Box>
                                                                    </>
                                                                )}

                                                                <Box
                                                                    className={classNames(
                                                                        'form-label',
                                                                        !helpersScreener.isExternalSurveyConnected(
                                                                            this.state.screener
                                                                        ) && 'form-row-margin'
                                                                    )}
                                                                >
                                                                    Public Link
                                                                </Box>
                                                                <Box>
                                                                    {this.state.screener.participant_external ==
                                                                    SCREENER_ANYONE ? (
                                                                        <Box>
                                                                            <Flex
                                                                                fontSize={1}
                                                                                mb={1}
                                                                                sx={{ width: '100%' }}
                                                                                className="mimic-theme rounded"
                                                                            >
                                                                                <Box
                                                                                    p={2}
                                                                                    sx={{
                                                                                        borderRight: '1px solid #eee',
                                                                                        flexShrink: 0
                                                                                    }}
                                                                                >
                                                                                    <FiLink
                                                                                        style={{
                                                                                            margin: '9px 8px 7px',
                                                                                            fontSize: '16px',
                                                                                            color: '#777'
                                                                                        }}
                                                                                    />
                                                                                </Box>

                                                                                <Box
                                                                                    className="ellipsis quiet"
                                                                                    sx={{
                                                                                        padding: '14px 16px 12px',
                                                                                        flexGrow: 1
                                                                                    }}
                                                                                >
                                                                                    <a
                                                                                        href={screener_link}
                                                                                        target="_blank"
                                                                                        className="quiet"
                                                                                    >
                                                                                        {screener_link}
                                                                                    </a>
                                                                                </Box>

                                                                                <Box p={2} sx={{ flexShrink: 0 }}>
                                                                                    {/*<Button
                                                                                        variant="secondary-gray"
                                                                                        ml={2}
                                                                                        mr={0}
                                                                                        onClick={(e) => {
                                                                                            this.setState({editCustomLink: true})
                                                                                        }}
                                                                                    >
                                                                                        Edit
                                                                                    </Button>*/}

                                                                                    <Button
                                                                                        variant="primary"
                                                                                        mr={0}
                                                                                        onClick={e => {
                                                                                            e.stopPropagation();
                                                                                            const {
                                                                                                toastManager
                                                                                            } = this.props;
                                                                                            helpers.copyToClipboard(
                                                                                                screener_link
                                                                                            );
                                                                                            toastManager.add(
                                                                                                'Copied link',
                                                                                                {
                                                                                                    appearance:
                                                                                                        'success',
                                                                                                    autoDismiss: true
                                                                                                }
                                                                                            );
                                                                                        }}
                                                                                    >
                                                                                        <FiCopy /> Copy
                                                                                    </Button>
                                                                                </Box>
                                                                            </Flex>
                                                                            {!helpersScreener.isExternalSurveyConnected(
                                                                                this.state.screener
                                                                            ) && (
                                                                                <Box>
                                                                                    {this.state.screener.links.length >
                                                                                    0 ? (
                                                                                        <Button
                                                                                            variant="link"
                                                                                            fontSize={1}
                                                                                            className="quiet"
                                                                                            onClick={
                                                                                                this.removeCustomLink
                                                                                            }
                                                                                        >
                                                                                            <u>
                                                                                                Remove custom link "
                                                                                                {
                                                                                                    this.state.screener
                                                                                                        .links[0].slug
                                                                                                }
                                                                                                "
                                                                                            </u>
                                                                                        </Button>
                                                                                    ) : (
                                                                                        <Button
                                                                                            variant="link"
                                                                                            fontSize={1}
                                                                                            className="quiet"
                                                                                            onClick={this.setCustomLink}
                                                                                        >
                                                                                            <u>Create custom link</u>
                                                                                        </Button>
                                                                                    )}
                                                                                    {helpers.newFeatureTag(
                                                                                        moment(
                                                                                            '10-28-2021',
                                                                                            'MM-DD-YYYY'
                                                                                        ),
                                                                                        { margin: '7px 0 0 8px' }
                                                                                    )}
                                                                                </Box>
                                                                            )}
                                                                        </Box>
                                                                    ) : (
                                                                        <Box
                                                                            className="fs-12"
                                                                            sx={{ marginTop: '3px' }}
                                                                        >
                                                                            Turned off. To get a public link, set "Who
                                                                            can take the screener?" to "Anyone".
                                                                        </Box>
                                                                    )}
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                        {!helpersScreener.isExternalSurveyConnected(
                                                            this.state.screener
                                                        ) && (
                                                            <>
                                                                <Box
                                                                    id="screener-configuration-automatic-scheduling"
                                                                    ref={this.automaticSchedulingRef}
                                                                    className="form-wrapper2 form-wrapper2-border"
                                                                >
                                                                    <Box className="settings-header">
                                                                        <H2>Automatic Scheduling</H2>
                                                                        <Box className="form-section-information">
                                                                            You can forward the qualified participant to
                                                                            schedule themselves, based on the Study
                                                                            availability.
                                                                        </Box>
                                                                    </Box>
                                                                    <Box className="form-data">
                                                                        <Box mt={3}>
                                                                            <Select
                                                                                name="participant_schedule"
                                                                                value={
                                                                                    this.state.screener
                                                                                        .participant_schedule
                                                                                }
                                                                                onChange={this.onFormChange}
                                                                                sx={{}}
                                                                            >
                                                                                <option value="automatic">
                                                                                    Automatically allow them to schedule
                                                                                    themselves
                                                                                </option>
                                                                                <option value="manual">
                                                                                    Wait for me to send them a
                                                                                    scheduling email
                                                                                </option>
                                                                            </Select>
                                                                        </Box>
                                                                    </Box>
                                                                </Box>
                                                                <Box
                                                                    id="screener-configuration-analytics"
                                                                    ref={this.analyticsRef}
                                                                    className="form-wrapper2 form-wrapper2-border"
                                                                >
                                                                    <Box className="settings-header">
                                                                        <H2>Analytics</H2>
                                                                        <Box className="form-section-information">
                                                                            Track analytics using Google Analytics.
                                                                        </Box>
                                                                    </Box>
                                                                    <Box className="form-data">
                                                                        <Box className="form-label">
                                                                            Google Analytics Tag Id (gtag.js)
                                                                        </Box>
                                                                        <Box>
                                                                            <Input
                                                                                name="gtag_id"
                                                                                value={this.state.screener.gtag_id}
                                                                                onChange={this.onFormChange}
                                                                                width="100%"
                                                                                minRows={3}
                                                                                className="theme-input"
                                                                            />
                                                                        </Box>
                                                                    </Box>
                                                                </Box>
                                                            </>
                                                        )}
                                                        {/*<Box
                                                            id="screener-configuration-custom-javascript"
                                                            ref={this.customJavascriptRef}
                                                            className="form-wrapper2 form-wrapper2-border"
                                                        >
                                                            <Box className="settings-header">
                                                                <H2>Custom HTML</H2>
                                                                <Box className="form-section-information">
                                                                    Define custom code that will be executed on this
                                                                    screener.{' '}
                                                                    <a
                                                                        href="#"
                                                                        data-beacon-article="6410db04a0408f7cb1038a44"
                                                                        className="help-question"
                                                                    >
                                                                        ?
                                                                    </a>
                                                                </Box>
                                                            </Box>
                                                            <Box className="form-data">
                                                                <Box className="form-label">Code</Box>
                                                                <Box>
                                                                    <TextAreaAutosize
                                                                        name="custom_js"
                                                                        value={
                                                                            this.state.screener.custom_js
                                                                                ? this.state.screener.custom_js[0].execute
                                                                                : ''
                                                                        }
                                                                        onChange={this.onFormChange}
                                                                        width="100%"
                                                                        minRows={3}
                                                                        className="theme-input"
                                                                    />
                                                                </Box>
                                                            </Box>
                                                        </Box>*/}
                                                    </>
                                                )}
                                            </Box>
                                            <Box flex="0 0 auto">
                                                <SideNav
                                                    sections={[
                                                        {
                                                            id: 'screener-configuration-internal-details',
                                                            ref: this.internalDetailsRef,
                                                            label: 'Internal Details',
                                                            display: true
                                                        },
                                                        {
                                                            id: 'screener-configuration-public-details',
                                                            ref: this.publicDetailsRef,
                                                            label: 'Public Details',
                                                            display: [SCREENER_ANYONE, SCREENER_STUDY_ONLY].includes(
                                                                parseInt(this.state.screener.participant_external)
                                                            )
                                                        },
                                                        {
                                                            id: 'screener-configuration-automatic-scheduling',
                                                            ref: this.automaticSchedulingRef,
                                                            label: 'Automatic Scheduling',
                                                            display: [SCREENER_ANYONE, SCREENER_STUDY_ONLY].includes(
                                                                parseInt(this.state.screener.participant_external)
                                                            )
                                                        },
                                                        {
                                                            id: 'screener-configuration-analytics',
                                                            ref: this.analyticsRef,
                                                            label: 'Analytics',
                                                            display: true
                                                        }
                                                        /*{
                                                            id: 'screener-configuration-custom-javascript',
                                                            ref: this.customJavascriptRef,
                                                            label: 'Custom HTML',
                                                            display: [SCREENER_ANYONE, SCREENER_STUDY_ONLY].includes(parseInt(this.state.screener.participant_external))
                                                        }*/
                                                    ]}
                                                />
                                            </Box>
                                        </Flex>
                                    )}

                                    {this.state.page_tab_selected == this.tab_screener_builder && (
                                        <Box style={{ width: '100%', minHeight: '560px' }}>
                                            {this.state.should_warn_no_email_sync == true && (
                                                <Box
                                                    className="label-red rounded"
                                                    fontSize={1}
                                                    p={3}
                                                    style={{ position: 'sticky', top: 0, zIndex: 1000 }}
                                                >
                                                    This screener can be taken by <u>Anyone</u> but "Email Two-way sync"
                                                    is missing. To fix, create an "Email" question, click require, click
                                                    on the "Two-way sync" checkbox, and select "Email" from the
                                                    dropdown.
                                                </Box>
                                            )}
                                            {this.state.screener && this.state.screener.id && (
                                                <Box sx={{ position: 'relative' }}>
                                                    <ReactFormBuilder
                                                        isEmailRequired={
                                                            // if screener is for anyone that means email is required
                                                            SCREENER_ANYONE === this.state.screener.participant_external
                                                        }
                                                        uuid={this.state.screener.uuid}
                                                        data={this.state.screener.form}
                                                        saveUrl={this.getScreenerFormSaveUrl(this.state.screener)}
                                                        read_only={1}
                                                        page_logic={this.state.screener.page_logic}
                                                        form_updated_at={this.state.screener.form_updated_at}
                                                    />
                                                    <ScrollToTopButton />
                                                </Box>
                                            )}

                                            {/*<Box className='border' p={4} fontSize={1}>
                                                <H2>Screener FAQ</H2>
                                                <Box mt={3}>
                                                    <Label className=''>How to use simple piping?</Label>
                                                    <Box>
                                                        To show an answer from a previous question in a future question or option,
                                                        use the pattern &#123;pg=#,q=#&#125;.<br/>
                                                        <em>Example: What do you like about &#123;pg=1,q=1&#125;?</em><br/>
                                                        <em>Example: Why did you choose &#123;pg=2,q=3&#125;?</em>
                                                    </Box>
                                                </Box>
                                                <Box mt={4}>
                                                    <Label className=''>How do qualification & disqualification work?</Label>
                                                    <Box>
                                                        Qualification logic executes at the end of a page. If a person submits
                                                        a disqualifying answer, and there are more questions on the same page,
                                                        nothing will happen until "Next" or "Submit" are clicked.
                                                    </Box>
                                                </Box>
                                            </Box>*/}
                                        </Box>
                                    )}

                                    {this.state.page_tab_selected == this.tab_screener_redirect &&
                                        this.state.screener.screener_translation && (
                                            <ScreenerTranslationComponent
                                                study={this.state.study}
                                                screener_translation={this.state.screener.screener_translation}
                                                handleTranslationChange={this.handleTranslationChange}
                                            />
                                        )}

                                    {this.state.page_tab_selected == this.tab_screener_email && (
                                        <Box>
                                            <AppPageWrapperSectionHeader className="experience" style={{ padding: 0 }}>
                                                <Box>
                                                    <H1>Email template</H1>
                                                    <Box className="experience-description">
                                                        Invite respondents to take this screener.
                                                    </Box>
                                                </Box>
                                                <Box>
                                                    {this.state.editorType === EMAIL_TEMPLATE_TYPE.BLOCKS && (
                                                        <Button
                                                            variant="primary"
                                                            onClick={e => {
                                                                e.stopPropagation();
                                                                helpers.popupWindow({
                                                                    url: `${config.API_URL}/studies/${this.props.match.params.study_id}/screeners/${this.props.match.params.screener_id}/email-preview?account_id=${this.state.study.account_id}`,
                                                                    w: 800,
                                                                    h: 600
                                                                });
                                                            }}
                                                            mr={0}
                                                        >
                                                            <FiEye style={{ marginRight: '8px' }} />
                                                            Preview Email
                                                        </Button>
                                                    )}
                                                </Box>
                                            </AppPageWrapperSectionHeader>
                                            <Flex className="form-wrapper2" width="100%" flexDirection="column">
                                                <Flex sx={{ gridGap: '32px' }} my="24px" alignItems="flex-end">
                                                    <Box flex="2">
                                                        <Box className="form-label">Email Subject</Box>
                                                        <Box>
                                                            <Input
                                                                type="text"
                                                                name="email_screener_subject"
                                                                value={this.state.screener.email_screener_subject}
                                                                onChange={this.onFormChange}
                                                                placeholder="Email Subject"
                                                            />
                                                        </Box>
                                                    </Box>
                                                    <VariablesHelper
                                                        study={this.state.study}
                                                        templateType={this.state.editorType}
                                                        elementId="email_screener_body"
                                                        content={this.state.screener.email_screener_body}
                                                        onChange={val => {
                                                            this.onFormChange({
                                                                target: { value: val, name: 'email_screener_body' }
                                                            });
                                                        }}
                                                    />
                                                    <EmailTemplateTypeLabel type={this.state.editorType} />
                                                </Flex>
                                            </Flex>

                                            {/* <Textarea
                                                id="email_screener_body"
                                                name="email_screener_body"
                                                placeholder="Email Body"
                                                value={this.state.screener.email_screener_body}
                                                onChange={this.onFormChange}
                                                height="200px"
                                            /> */}

                                            {this.state.editorData && (
                                                <Box style={{ width: '100%' }}>
                                                    <EmailEditor
                                                        editorData={this.state.editorData}
                                                        type={this.state.editorType}
                                                        onChange={this.onChangeEditor}
                                                        allowScreenerButton
                                                        allowDscoutButton
                                                        onTemplate={template => this.onTemplate(template)}
                                                    />
                                                </Box>
                                            )}
                                        </Box>
                                    )}
                                    <br />
                                    <br />
                                    <br />
                                    <br />

                                    {/* <ul>
                                 <li>You can set up a "Web Intercept" below, to recruit users from a website.</li>
                                 <li>Recruiting via email campaigns coming soon (ie. like MailChimp).</li>
                             </ul>

                            <OnboardingScript auth={this.props.auth} /> */}

                                    {/* <H2>Select Recruiting Type</H2>
                            <Button>Create Intercept</Button>
                            <Button>Create Email Campaign</Button> */}

                                    {/* <div>
                                <h1>Results</h1>
                                     {JSON.stringify(this.state.screenerAnswers)}
                              </div> */}
                                </Box>
                            </Flex>
                        )}
                    </AppPageWrapperSectionBody>
                </AppPageWrapper>
                <NiceModal
                    isOpen={this.state.showModalCreateQuota}
                    shouldCloseOnOverlayClick
                    onRequestClose={() => this.setState({ showModalCreateQuota: false })}
                    title="Create a new quota"
                >
                    <form onSubmit={this.onCreateQuota}>
                        <Box>
                            <Label>Title</Label>
                            <Input type="text" name="create_quota_title" autoFocus required />
                        </Box>
                        <Box mt={3}>
                            <Label>Type</Label>

                            {SCREENER_QUOTA_TYPES.map(sqt => (
                                <Label
                                    className={
                                        sqt.enabled ? 'screener-quota-type-enabled' : 'screener-quota-type-disabled'
                                    }
                                >
                                    <Flex>
                                        <Box
                                            sx={{
                                                flexShrink: 0,
                                                marginRight: '8px',
                                                marginTop: '5px'
                                            }}
                                        >
                                            <input
                                                type="radio"
                                                name="create_quota_type"
                                                value={sqt.value}
                                                required
                                                disabled={sqt.enabled ? false : true}
                                                onChange={e => {
                                                    let create_quota = this.state.create_quota;
                                                    create_quota.type = e.target.value;
                                                    this.setState({ create_quota });
                                                }}
                                            />
                                        </Box>
                                        <Box>
                                            <Box className="text-primary medium">{sqt.label}</Box>
                                            <Box className="fs-12 text-secondary">{sqt.description}</Box>
                                        </Box>
                                    </Flex>
                                </Label>
                            ))}
                        </Box>

                        <Box mt={3}>
                            <Label>Maximum responses</Label>
                            <Input type="number" name="create_quota_value_max_responses" required min={1} />
                        </Box>

                        <Box className="modal-actions">
                            <Button
                                type="button"
                                variant="secondary-gray"
                                className="modal-primary"
                                mr={3}
                                onClick={() => this.setState({ showModalCreateQuota: false })}
                            >
                                Cancel
                            </Button>
                            <Button
                                type="submit"
                                variant="primary"
                                className="modal-primary"
                                mr={0}
                                disabled={this.state.createQuotaProcessing ? true : false}
                            >
                                Create quota
                            </Button>
                        </Box>
                    </form>
                </NiceModal>

                {this.state.showEditQuota && (
                    <SideOverlay
                        isExpanded={1}
                        onClose={() => {
                            this.setState({ showEditQuota: false });
                        }}
                    >
                        <Box my={3} mx={4}>
                            <H1>Edit Quota</H1>
                            {this.renderQuotaFiltersToEdit()}
                        </Box>
                    </SideOverlay>
                )}

                <NiceModal
                    isOpen={this.state.showModalCreatePanel}
                    shouldCloseOnOverlayClick
                    onRequestClose={() => this.setState({ showModalCreatePanel: false })}
                    title={`Create new panel`}
                >
                    <CreatePanel
                        onCreate={this.onCreatePanel}
                        onClose={() => this.setState({ showModalCreatePanel: false })}
                    />
                </NiceModal>
            </Flex>
        );
    }
}

export default withRouter(withToastManager(StudyScreenerIndividual));

/*
contact
--
first name
last name
email
phone


other
--
scheduling
magic drilldown
hidden external variable
image & text
formatted text block
opinion scale
qualifying question


close-ended
--
checkboxes
drop down
radio buttons
yes/no

open-ended
--
single line
paragraph
*/
