import React from 'react';
import moment from 'moment-timezone';
import { Redirect } from 'react-router';
import { Button, Heading, Box, Flex } from 'rebass';
import { Label, Input, Textarea, Select } from '@rebass/forms';
import xhr from 'xhr.js';
import services from 'services/services';
import { withToastManager } from 'react-toast-notifications';
import H1 from 'components/H1';
import {
    FiXCircle,
    FiSave,
    FiPlus,
    FiFolderPlus,
    FiChevronLeft,
    FiX,
    FiChevronDown,
    FiCalendar,
    FiMoreVertical
} from 'react-icons/fi';
import helpers from 'utils/helpers';
import helpersStudy from 'utils/helpers-study';
import helpersScreener from 'utils/helpers-screener';
import NiceDropdown from 'components/NiceDropdown';
import DatePicker from 'react-datepicker';
import ReactTooltip from 'react-tooltip';
import {
    SP_STATUS_KEY_TITLE,
    STANDARD_FILTERS,
    COMPARE_FILTERS,
    PERSON_KEY_TYPE_CHECKBOX,
    PERSON_KEY_TYPE_DROPDOWN
} from 'utils/constants';
import cloneDeep from 'lodash/cloneDeep';

class StudyFilters extends React.Component {
    constructor(props) {
        super(props);
        //console.log('this.props', this.props)
        this.state = {
            panel_columns: this.props.panel_columns,
            study: this.props.study,
            users: this.props.users,
            availability: this.props.availability,
            showScreenerFiltersEditorIndividual: false,
            segment: this.props.segment,
            screenerFilters: this.props.screenerFilters,
            screenerFiltersAndOr: this.props.screenerFiltersAndOr,
            showScreenerFilters: false,
            showScreenerFiltersDD1: false,
            editScreenerFilterInfo: {}
            // screenerFilters: [{"type":"standard","id":"any_screener","values":[1]},{"type":"screener","field_name":"text_input_4AC675A8-41EA-443E-A0BD-0455BF5CF41A","values":["pan"]},{"type":"screener","field_name":"radiobuttons_35A82506-F968-42C6-B9CB-5958E5DEC2D9","values":["option_19979F21-044D-498A-B95B-19421564A396"]}],
        };

        //this.removeScreenerFilter = this.removeScreenerFilter.bind(this);
    }

    generateFilterGroup() {
        return {
            type: 'group',
            id: helpers.generateUID(),
            values: []
        };
    }

    getScreenerFiltersAndOrValue() {
        let and_or_value = 'and';
        if (this.state.screenerFilters && this.state.screenerFilters.length) {
            and_or_value =
                this.state.screenerFilters[0] &&
                this.state.screenerFilters[0].and_or_value &&
                this.state.screenerFilters[0].and_or_value == 'or'
                    ? 'or'
                    : 'and';
        }
        return and_or_value;
    }

    changeScreenerFilterTypeId(index, filterType, id) {
        console.log('changeScreenerFilterTypeId', this.state.editScreenerFilterInfo, index, filterType, id);

        const { screenerFilters, editScreenerFilterInfo } = this.state;
        let filter_save = {};

        // update existing filter data
        if (index != 'new') {
            const filterFound = screenerFilters[index];
            if (filterFound) {
                filter_save = filterFound;
            }
        }

        filter_save.type = filterType;
        filter_save.and_or_value = this.getScreenerFiltersAndOrValue();

        if (filterType == 'screener') {
            filter_save.field_name = id;
        } else {
            filter_save.id = id;
        }

        // reset compare; @TODO set it to a good default
        filter_save.compare = null;
        if (filter_save.hasOwnProperty('values')) {
            delete filter_save.values;
        }

        if (index == 'new') {
            screenerFilters.push(filter_save);
            editScreenerFilterInfo.index = screenerFilters.length - 1;
        } else {
            screenerFilters[index] = filter_save;
        }

        editScreenerFilterInfo.filter = filter_save;

        this.setState({ screenerFilters, editScreenerFilterInfo }, () => {
            if (
                this.shouldShowDD2(this.state.editScreenerFilterInfo) == true &&
                this.state.editScreenerFilterInfo.filter.compare == null
            ) {
                console.log('dont filter yet! no condition');
            } else {
                this.props.onScreenerFiltersChange(screenerFilters);
            }
        });

        // [{"field_name":"checkboxes_C6173128-78E6-4C18-A865-0278281ED78A","compare":"contains","values":["checkboxes_option_B76254CD-A3F8-42A0-85D1-77047AC3AB07"]}]
    }

    changeScreenerFilterQuestionChild(index, questionChildId) {
        console.log(index, questionChildId);

        const { screenerFilters, editScreenerFilterInfo } = this.state;
        let filter_save = {};

        // update existing filter data
        if (index != 'new') {
            const filterFound = screenerFilters[index];
            if (filterFound) {
                filter_save = filterFound;
            }
        }

        filter_save.id_child = questionChildId;

        if (index == 'new') {
            screenerFilters.push(filter_save);
        } else {
            screenerFilters[index] = filter_save;
        }

        editScreenerFilterInfo.filter = filter_save;

        this.setState({ screenerFilters, editScreenerFilterInfo }, () => {
            // do this manually later!
        });
    }

    changeScreenerFilterCompare(index, compare) {
        //console.log(index, compare);

        const { screenerFilters, editScreenerFilterInfo } = this.state;
        let filter_save = {};

        // update existing filter data
        if (index != 'new') {
            const filterFound = screenerFilters[index];
            if (filterFound) {
                filter_save = filterFound;
            }
        }

        filter_save.compare = compare;

        if (index == 'new') {
            screenerFilters.push(filter_save);
        } else {
            screenerFilters[index] = filter_save;
        }

        editScreenerFilterInfo.filter = filter_save;

        this.setState({ screenerFilters, editScreenerFilterInfo }, () => {
            // do this manually later!
        });
    }

    changeScreenerFilterValue(e, index, field_name, answer_index) {
        //console.log(e.target, index, field_name)

        const { screenerFilters, editScreenerFilterInfo } = this.state;
        const filterType = screenerFilters[index].type;
        let newValues = [];
        let filter_save;

        //console.log('type', e.target)

        // update existing filter data
        if (index != 'new') {
            const filterFound = screenerFilters[index];
            if (filterFound) {
                filter_save = filterFound;
                if (filterFound.values) {
                    newValues = filterFound.values;
                }
            }
        }

        if (filterType == 'screener') {
            if (e.target.type == 'checkbox') {
                // get checked checkboxes by field name
                const checkboxes = document.getElementsByName(field_name);
                const checked_values = [];
                checkboxes.forEach(cb => {
                    if (cb.checked) {
                        checked_values.push(cb.value);
                    }
                });
                // console.log(checked_values);
                newValues = checked_values;
            } else {
                if (e.target.value) {
                    if (
                        editScreenerFilterInfo &&
                        editScreenerFilterInfo.filter &&
                        editScreenerFilterInfo.filter.compare &&
                        editScreenerFilterInfo.filter.compare == 'between'
                    ) {
                        //console.log('!!!!!xxx', editScreenerFilterInfo)
                        if (answer_index == 0) {
                            newValues[0] = e.target.value;
                        } else if (answer_index == 1) {
                            newValues[1] = e.target.value;
                        }
                    } else {
                        //console.log('....xxx', editScreenerFilterInfo)
                        newValues = [e.target.value];
                    }
                } else {
                    newValues = [];
                }
            }
        } else if (filterType == 'standard') {
            if (e.target.type == 'checkbox') {
                if (e.target.checked) {
                    newValues = [1];
                }
            } else if (e.target.value) {
                newValues = [e.target.value];
            } else {
                newValues = [];
            }
        } else if (filterType == 'screener_answered') {
            if (e.target.checked) {
                newValues = [1];
            }
        } else if (filterType == 'screener_qualified') {
            if (e.target.checked) {
                newValues = [1];
            }
        } else if (filterType == 'screener_disqualified') {
            if (e.target.checked) {
                newValues = [1];
            }
        } else if (filterType == 'screener_incomplete') {
            if (e.target.checked) {
                newValues = [1];
            }
        } else if (filterType == 'custom_attribute') {
            if (e.target.type == 'checkbox') {
                // get checked checkboxes by field name
                const checkboxes = document.getElementsByName(field_name);
                const checked_values = [];
                checkboxes.forEach(cb => {
                    if (cb.checked) {
                        checked_values.push(cb.value);
                    }
                });
                // console.log(checked_values);
                newValues = checked_values;
            } else if (e.target.value) {
                newValues = [e.target.value];
            } else {
                newValues = [];
            }
        } else if (filterType == 'assigned') {
            if (e.target.type == 'checkbox') {
                // get checked checkboxes by field name
                const checkboxes = document.getElementsByName(field_name);
                const checked_values = [];
                checkboxes.forEach(cb => {
                    if (cb.checked) {
                        checked_values.push(parseInt(cb.value));
                    }
                });
                // console.log(checked_values);
                newValues = checked_values;

                console.log('newValues', newValues);
            }
        } else if (filterType == 'scheduled') {
            if (e.target.type == 'checkbox') {
                // get checked checkboxes by field name
                const checkboxes = document.getElementsByName(field_name);
                const checked_values = [];
                checkboxes.forEach(cb => {
                    if (cb.checked) {
                        checked_values.push(cb.value);
                    }
                });
                // console.log(checked_values);
                newValues = checked_values;

                //console.log('newValues',newValues)
            }
        } else if (filterType == 'sp_status') {
            if (e.target.type == 'checkbox') {
                // get checked checkboxes by field name
                const checkboxes = document.getElementsByName(field_name);
                const checked_values = [];
                checkboxes.forEach(cb => {
                    if (cb.checked) {
                        checked_values.push(cb.value);
                    }
                });
                // console.log(checked_values);
                newValues = checked_values;

                //console.log('newValues',newValues)
            }
        } else if (filterType == 'last_contacted_at') {
            if (e.target.value) {
                if (
                    editScreenerFilterInfo &&
                    editScreenerFilterInfo.filter &&
                    editScreenerFilterInfo.filter.compare &&
                    editScreenerFilterInfo.filter.compare == 'between'
                ) {
                    //console.log('!!!!!xxx', editScreenerFilterInfo)
                    if (answer_index == 0) {
                        newValues[0] = e.target.value;
                    } else if (answer_index == 1) {
                        newValues[1] = e.target.value;
                    }
                } else {
                    //console.log('....xxx', editScreenerFilterInfo)
                    newValues = [e.target.value];
                }
            } else {
                newValues = [];
            }
        } else if (filterType == 'panel') {
            //console.log('editScreenerFilterInfo...',editScreenerFilterInfo)
            //console.log(e.target.type);
            if (e.target.type == 'checkbox') {
                // get checked checkboxes by field name
                const checkboxes = document.getElementsByName(field_name);
                const checked_values = [];
                checkboxes.forEach(cb => {
                    if (cb.checked) {
                        checked_values.push(cb.value);
                    }
                });
                //console.log('checked_values', checked_values);
                newValues = checked_values;
            } else {
                if (e.target.value) {
                    if (
                        editScreenerFilterInfo &&
                        editScreenerFilterInfo.filter &&
                        editScreenerFilterInfo.filter.compare &&
                        editScreenerFilterInfo.filter.compare == 'between'
                    ) {
                        //console.log('!!!!!xxx', editScreenerFilterInfo)
                        if (answer_index == 0) {
                            newValues[0] = e.target.value;
                        } else if (answer_index == 1) {
                            newValues[1] = e.target.value;
                        }
                    } else {
                        //console.log('....xxx', editScreenerFilterInfo)
                        newValues = [e.target.value];
                    }
                } else {
                    newValues = [];
                }
            }
        } else if (filterType == 'paid') {
            if (e.target.value) {
                if (
                    editScreenerFilterInfo &&
                    editScreenerFilterInfo.filter &&
                    editScreenerFilterInfo.filter.compare &&
                    editScreenerFilterInfo.filter.compare == 'between'
                ) {
                    //console.log('!!!!!xxx', editScreenerFilterInfo)
                    if (answer_index == 0) {
                        newValues[0] = e.target.value;
                    } else if (answer_index == 1) {
                        newValues[1] = e.target.value;
                    }
                } else {
                    //console.log('....xxx', editScreenerFilterInfo)
                    newValues = [e.target.value];
                }
            } else {
                newValues = [];
            }
        }

        filter_save.values = newValues;

        if (index == 'new') {
            screenerFilters.push(filter_save);
        } else {
            screenerFilters[index] = filter_save;
        }

        editScreenerFilterInfo.filter = filter_save;

        this.setState({ screenerFilters, editScreenerFilterInfo }, () => {
            // FORCE MANUAL FILTER SAVE
        });

        /* if (filterFound) {
            if (newValues && newValues.length) {
                filterFound.values = newValues;
            } else {
                const index = screenerFilters.indexOf(filterFound);
                if (index > -1) {
                    screenerFilters.splice(index, 1);
                }
            }
        } else {
            if (newValues) {
                if (filterType == 'screener') {
                    screenerFilters.push({
                        type: filterType,
                        field_name: field_name,
                        values: newValues,
                        compare: 'contains'
                    });
                } else if (filterType == 'standard') {
                    screenerFilters.push({
                        type: filterType,
                        id: field_name,
                        values: newValues
                    });
                } else if (filterType == 'screener_answered') {
                    screenerFilters.push({
                        type: filterType,
                        id: field_name,
                        values: newValues
                    });
                } else if (filterType == 'screener_qualified') {
                    screenerFilters.push({
                        type: filterType,
                        id: field_name,
                        values: newValues
                    });
                } else if (filterType == 'screener_disqualified') {
                    screenerFilters.push({
                        type: filterType,
                        id: field_name,
                        values: newValues
                    });
                } else if (filterType == 'custom_attribute') {
                    screenerFilters.push({
                        type: filterType,
                        id: field_name,
                        values: newValues
                    });
                }
            }
        }

        //console.log(screenerFilters);

        this.setState({ screenerFilters }, () => {
            
        }); */
    }

    filterCheckboxIsChecked(filter_index, identifier, val) {
        let isChecked = false;
        const sf = this.state.screenerFilters[filter_index];
        if (sf) {
            //console.log(sf, identifier, val, sf.hasOwnProperty('id'), sf.id == identifier, sf.values && sf.values.includes(val))
            if (
                sf.hasOwnProperty('field_name') &&
                sf.field_name == identifier &&
                sf.values &&
                sf.values.includes(val)
            ) {
                isChecked = true;
            } else if (sf.hasOwnProperty('id') && sf.id == identifier && sf.values && sf.values.includes(val)) {
                isChecked = true;
            }
        }

        return isChecked;
    }

    renderScreenerFilterIndividual(sf) {
        const el = helpersStudy.renderScreenerFilterIndividual(
            sf,
            this.props.study,
            this.props.panel_columns,
            this.props.users,
            this.props.availability
        );
        //console.log('el', el);
        return el;
    }

    areNewFiltersDifferent() {
        let are_different = false;

        try {
            const original_filters = this.state.screenerFilters;
            if (this.state.study && this.state.study.study_states) {
                const ss = this.state.study.study_states.find(ss => ss.id == this.state.segment);
                //console.log(ss.filters_original, original_filters, ss.filters_original != original_filters)
                if (ss && JSON.stringify(ss.filters_original) != JSON.stringify(original_filters)) {
                    are_different = true;
                }
            }
            //console.log('are_different', are_different)
        } catch (e) {
            helpers.trackError(e);
        }

        return are_different;
    }

    getScreenerFilterDD1Value(filterInfo) {
        if (filterInfo && filterInfo.filter) {
            const { filter } = filterInfo;
            // console.log('hi', filter);
            if (filter.type == 'custom_attribute') {
                const cspa_found = this.props.study.custom_sp_attributes.find(cspa => cspa.id == filter.id);
                if (cspa_found) {
                    return cspa_found.title;
                }
            } else if (filter.type == 'screener') {
                const all_questions = helpersStudy.getAllScreenerQuestionsList(
                    this.props.study.screener,
                    this.props.study.screeners
                );
                const q = helpersScreener.getQuestionByFieldName(all_questions, filter.field_name);
                if (q) {
                    return helpers.removeHtml(q.label);
                }
            } else if (filter.type == 'standard') {
                const standard_filter_found = STANDARD_FILTERS.find(SF => SF.id == filter.id);
                if (standard_filter_found) {
                    return standard_filter_found.title;
                } else if ((filter.id = 'paid')) {
                    return 'We have updated the PAID filter, please re-select it.';
                }
            } else if (filter.type == 'assigned') {
                return `Assigned`;
            } else if (filter.type == 'scheduled') {
                return `Scheduled`;
            } else if (filter.type == 'paid') {
                return `Paid`;
            } else if (filter.type == 'sp_status') {
                return `Participant Status`;
            } else if (filter.type == 'last_contacted_at') {
                return `Last Contacted At (In Study)`;
            } else if (filter.type == 'screener_answered') {
                const screener_found = this.props.study.screeners.find(s => s.id == filter.id);
                if (screener_found) {
                    return `Complete: ${screener_found.title}`;
                }
            } else if (filter.type == 'screener_qualified') {
                const screener_found = this.props.study.screeners.find(s => s.id == filter.id);
                if (screener_found) {
                    return `Qualified: ${screener_found.title}`;
                }
            } else if (filter.type == 'screener_disqualified') {
                const screener_found = this.props.study.screeners.find(s => s.id == filter.id);
                if (screener_found) {
                    return `Disqualified: ${screener_found.title}`;
                }
            } else if (filter.type == 'screener_incomplete') {
                const screener_found = this.props.study.screeners.find(s => s.id == filter.id);
                if (screener_found) {
                    return `Incomplete: ${screener_found.title}`;
                }
            } else if (filter.type == 'panel') {
                const panel_col_found = this.props.panel_columns.find(panel_col => panel_col.id == filter.id);
                if (panel_col_found) {
                    return panel_col_found.title;
                }
            }
        }
        return 'Choose filter';
    }

    shouldShowDD2(filterInfo) {
        const should_not_show = [
            'standard',
            'screener_answered',
            'screener_qualified',
            'screener_disqualified',
            'screener_incomplete'
        ];
        if (
            !filterInfo ||
            !filterInfo.filter ||
            should_not_show.includes(filterInfo.filter.type) ||
            (!filterInfo.filter.id && !filterInfo.filter.field_name)
        ) {
            return false;
        }
        return true;
    }

    getScreenerFilterDD2Value(filterInfo) {
        const filter = filterInfo.filter;
        if (filter && filter.compare) {
            const compare_found = helpersStudy.getCompareTitleFromKey(filter.compare);

            if (compare_found) {
                return compare_found.title;
            }
            console.log('COMPARE NOT FOUND', filterInfo);
        } else {
            return <span className="error">Choose condition</span>;
        }
    }

    shouldShowDD3(filterInfo) {
        if (this.shouldShowDD2(filterInfo)) {
            const filter = filterInfo.filter;
            if (['is_known', 'is_unknown'].includes(filter.compare)) {
                return false;
            }
            return true;
        } else {
            return false;
        }
    }

    getScreenerFilterDD3Value(filterInfo) {
        if (filterInfo && filterInfo.filter && filterInfo.filter.values) {
            return filterInfo.filter.values.map(v => `${v}, `);
        }
        return 'Choose value';
    }

    getScreenersArray() {
        let q;
        const screenersArray = [];
        if (this.props.study.screeners) {
            this.props.study.screeners.forEach(s => {
                q = [];
                s.form.forEach(page => {
                    page.forEach(question => {
                        q.push(question);
                    });
                });

                screenersArray.push({
                    id: s.id,
                    title: s.title,
                    elements: q
                });
            });
        }

        return screenersArray;
    }

    screenerFiltersDD1Items(index) {
        // console.log(this.state.screenerFilters);
        const filters = [];
        const screenersArray = this.getScreenersArray();
        const q = [];

        if (!this.props.disallowStandardFilters) {
            filters.push({
                label: true,
                title: 'Study Participation'
            });
            STANDARD_FILTERS.forEach(standard_filter => {
                filters.push({
                    id: JSON.stringify({ index, filter: { id: standard_filter.id, filter_type: 'standard' } }),
                    title: standard_filter.title
                });
            });

            filters.push({
                id: JSON.stringify({ index, filter: { id: 'assigned', filter_type: 'assigned' } }),
                title: `Assigned`
            });

            filters.push({
                id: JSON.stringify({ index, filter: { id: 'scheduled', filter_type: 'scheduled' } }),
                title: `Scheduled`
            });

            filters.push({
                id: JSON.stringify({ index, filter: { id: 'paid', filter_type: 'paid' } }),
                title: `Paid`
            });

            filters.push({
                id: JSON.stringify({ index, filter: { id: 'last_contacted_at', filter_type: 'last_contacted_at' } }),
                title: `Last Contacted At`
            });

            filters.push({
                id: JSON.stringify({ index, filter: { id: 'sp_status', filter_type: 'sp_status' } }),
                title: `Participant Status`
            });

            filters.push({
                label: true,
                title: 'Screener Participation'
            });

            if (screenersArray && screenersArray.length) {
                screenersArray.forEach(screener => {
                    filters.push({
                        id: JSON.stringify({ index, filter: { id: screener.id, filter_type: 'screener_answered' } }),
                        title: `Completed: ${screener.title}`
                    });
                    filters.push({
                        id: JSON.stringify({
                            index: index,
                            filter: { id: screener.id, filter_type: 'screener_qualified' }
                        }),
                        title: `Qualified: ${screener.title}`
                    });
                    filters.push({
                        id: JSON.stringify({
                            index: index,
                            filter: { id: screener.id, filter_type: 'screener_disqualified' }
                        }),
                        title: `Disqualified: ${screener.title}`
                    });
                    filters.push({
                        id: JSON.stringify({
                            index: index,
                            filter: { id: screener.id, filter_type: 'screener_incomplete' }
                        }),
                        title: `Incomplete: ${screener.title}`
                    });
                });
            }
        }

        if (this.props.study && this.props.study.custom_sp_attributes && this.props.study.custom_sp_attributes.length) {
            filters.push({
                label: true,
                title: 'Custom Study Attributes'
            });
            this.props.study.custom_sp_attributes.forEach(cspa => {
                filters.push({
                    id: JSON.stringify({ index, filter: { id: cspa.id, filter_type: 'custom_attribute' } }),
                    title: cspa.title
                });
            });
        }

        if (screenersArray && screenersArray.length) {
            screenersArray.forEach(screener => {
                let skip = false;
                if (this.props.screenerIdsOnly) {
                    skip = true;
                    if (this.props.screenerIdsOnly.includes(screener.id)) {
                        skip = false;
                    }
                }

                if (!skip) {
                    filters.push({
                        label: true,
                        title: `Screener Questions: ${screener.title}`
                    });

                    screener.elements.forEach(q => {
                        if (!q.static) {
                            // console.log('qqq', q)
                            filters.push({
                                id: JSON.stringify({ index, filter: { id: q.field_name, filter_type: 'screener' } }),
                                title: helpers.removeHtml(q.label)
                            });
                        }
                    });
                }
            });
        }

        if (!this.props.disallowPanelFilters) {
            // add panel attributes
            filters.push({
                label: true,
                title: 'Panel Properties'
            });
            this.props.panel_columns.forEach(column => {
                filters.push({
                    id: JSON.stringify({ index, filter: { id: column.id, filter_type: 'panel' } }),
                    title: column.title
                });
            });
        }

        return filters;
    }

    screenerFiltersDD2Items(index) {
        //console.log('screenerFiltersDD2Items', );

        let sf_found = this.state.screenerFilters[index];
        //console.log('sf_found', sf_found)
        if (sf_found) {
            if (sf_found.type == 'screener') {
                const all_questions = helpersStudy.getAllScreenerQuestionsList(
                    this.props.study.screener,
                    this.props.study.screeners
                );
                const q = helpersScreener.getQuestionByFieldName(all_questions, sf_found.field_name);
                if (q) {
                    if (helpersScreener.isElementTextMatrix(q)) {
                        return COMPARE_FILTERS.TEXT;
                    } else if (helpersScreener.getElementNamesWithMultiSelect().includes(q.element)) {
                        return COMPARE_FILTERS.MULTI_SELECT;
                    } else if (helpersScreener.getElementNamesWithSingleSelect().includes(q.element)) {
                        return COMPARE_FILTERS.SINGLE_SELECT;
                    } else if (helpersScreener.getElementNamesBasicInputs().includes(q.element)) {
                        return COMPARE_FILTERS.TEXT;
                    } else if (helpersScreener.getElementNamesNumericInputs().includes(q.element)) {
                        return COMPARE_FILTERS.NUMBER;
                    } else if (helpersScreener.getElementNamesDates().includes(q.element)) {
                        return COMPARE_FILTERS.DATE;
                    } else if (['Signature'].includes(q.element)) {
                        return COMPARE_FILTERS.BOOLEAN;
                    }
                }
            } else if (sf_found.type == 'panel') {
                let column_found = this.props.panel_columns.find(col => sf_found.id == col.id);
                //console.log('sf_found PANEL', sf_found, column_found);
                if (column_found) {
                    if (column_found.type == 'date') {
                        return COMPARE_FILTERS.DATE;
                    } else if (column_found.type == PERSON_KEY_TYPE_CHECKBOX) {
                        return COMPARE_FILTERS.MULTI_SELECT;
                    } else if (column_found.type == PERSON_KEY_TYPE_DROPDOWN) {
                        return COMPARE_FILTERS.SINGLE_SELECT;
                    }
                }
            } else if (sf_found.type == 'custom_attribute') {
                let cspa_found = this.props.study.custom_sp_attributes.find(cspa => cspa.id == sf_found.id);
                if (cspa_found) {
                    if (cspa_found.type && cspa_found.type == 'checkbox') {
                        return COMPARE_FILTERS.MULTI_SELECT;
                    } else if (cspa_found.type && cspa_found.type == 'dropdown') {
                        return COMPARE_FILTERS.SINGLE_SELECT;
                    } else {
                        return COMPARE_FILTERS.TEXT;
                    }
                }
            } else if (sf_found.type == 'assigned') {
                //console.log('ASSIGNED FOUND');
                return COMPARE_FILTERS.SINGLE_SELECT;
            } else if (sf_found.type == 'scheduled') {
                //console.log('scheduled FOUND');
                return COMPARE_FILTERS.SINGLE_SELECT;
            } else if (sf_found.type == 'paid') {
                return COMPARE_FILTERS.NUMBER;
            } else if (sf_found.type == 'sp_status') {
                //console.log('scheduled FOUND');
                return COMPARE_FILTERS.SINGLE_SELECT;
            } else if (sf_found.type == 'last_contacted_at') {
                //console.log('scheduled FOUND');
                return COMPARE_FILTERS.DATE;
            }
        }
        return COMPARE_FILTERS.TEXT;
    }

    screenerFiltersDD3Items(filterInfo) {
        let answers_rendered;
        const that = this;
        const { filter } = filterInfo;
        const filter_index = filterInfo.index;
        if (filter && filter.type == 'screener' && filter.field_name) {
            const screenersArray = this.getScreenersArray();
            let question_found = false;
            screenersArray.forEach(screener => {
                const is_found = screener.elements.find(q => q.field_name == filter.field_name);
                if (is_found) {
                    question_found = is_found;
                }
            });
            const q = question_found;
            if (question_found) {
                let all_options = [];
                if (helpersScreener.getElementNamesWithOptions().includes(q.element)) {
                    all_options = helpersScreener.getAllOptionsForPiping(q, screenersArray);
                }

                if (
                    q.element === 'Checkboxes' ||
                    q.element === 'RadioButtons' ||
                    q.element === 'Dropdown' ||
                    q.element === 'Captcha'
                ) {
                    let options = [];

                    all_options.forEach(option => {
                        let option_text = option.text;
                        if (option.is_add_other) {
                            option_text = 'Other';
                        } else if (option.is_none_of_the_above) {
                            option_text = 'None of the above';
                        }

                        const val = q.element == 'Dropdown' ? option.value : option.key;
                        options.push(
                            <>
                                <label>
                                    <input
                                        type="checkbox"
                                        id={option.key}
                                        checked={this.filterCheckboxIsChecked(filter_index, q.field_name, val)}
                                        name={q.field_name}
                                        value={val}
                                        onChange={e => this.changeScreenerFilterValue(e, filter_index, q.field_name)}
                                    />
                                    &nbsp;{option_text}
                                </label>
                                <br />
                            </>
                        );
                    });
                    answers_rendered = options;
                    // answers_rendered = <select multiple style={{width: '100%'}} value={screenerFilter ? screenerFilter.values[0] : ''}>{options}</select>
                } else if (
                    q.element == 'Matrix' &&
                    //purposefully skip this so that we render a simple input
                    !helpersScreener.isElementTextMatrix(q)
                ) {
                    let options = [];
                    const matrix_options = helpersStudy.generateMatrixAnswerCombinations(all_options);

                    if (matrix_options) {
                        matrix_options.forEach(matrix_option => {
                            options.push(
                                <>
                                    <label>
                                        <input
                                            type="checkbox"
                                            id={matrix_option.row_col_key}
                                            checked={this.filterCheckboxIsChecked(
                                                filter_index,
                                                q.field_name,
                                                matrix_option.row_col_key
                                            )}
                                            name={q.field_name}
                                            value={matrix_option.row_col_key}
                                            onChange={e =>
                                                that.changeScreenerFilterValue(e, filter_index, q.field_name)
                                            }
                                        />
                                        &nbsp;&nbsp;{matrix_option.row_col_text}
                                    </label>
                                    <br />
                                </>
                            );
                        });
                    }

                    answers_rendered = options;
                } else if (q.element == 'DatePicker') {
                    let selected_val = '';

                    if (filter && filter.values && filter.values[0]) {
                        if (filter.values[0] instanceof Date == false) {
                            const mom = moment(filter.values[0]);
                            if (mom.isValid()) {
                                selected_val = mom.toDate();
                            }
                        } else {
                            selected_val = filter.values[0];
                        }
                    }

                    answers_rendered = (
                        <Box sx={{ position: 'relative' }}>
                            <DatePicker
                                className="theme-input datepicker-input"
                                selected={selected_val}
                                onChange={date => {
                                    that.changeScreenerFilterValue(
                                        { target: { value: date } },
                                        filter_index,
                                        q.field_name
                                    );
                                }}
                                showYearDropdown
                                dropdownMode="select"
                                showMonthDropdown
                            />
                            <Label sx={{ position: 'absolute', right: '4px', top: '4px' }}>
                                <FiCalendar />
                            </Label>
                        </Box>
                    );
                } else {
                    if (filter.compare == 'between') {
                        answers_rendered = (
                            <>
                                <Input
                                    autoFocus
                                    style={{ width: '100%' }}
                                    value={filter && filter.values ? filter.values[0] : ''}
                                    type="text"
                                    onChange={e => that.changeScreenerFilterValue(e, filter_index, q.field_name, 0)}
                                />
                                <Box my={2}>and</Box>
                                <Input
                                    autoFocus
                                    style={{ width: '100%' }}
                                    value={filter && filter.values ? filter.values[1] : ''}
                                    type="text"
                                    onChange={e => that.changeScreenerFilterValue(e, filter_index, q.field_name, 1)}
                                />
                            </>
                        );
                    } else {
                        answers_rendered = (
                            <Input
                                autoFocus
                                style={{ width: '100%' }}
                                value={filter && filter.values ? filter.values[0] : ''}
                                type="text"
                                onChange={e => that.changeScreenerFilterValue(e, filter_index, q.field_name)}
                            />
                        );
                    }
                }
            }
        } else if (filter && filter.type == 'custom_attribute' && filter.id) {
            let cspa_found = this.props.study.custom_sp_attributes.find(cspa => filter.id == cspa.id);

            if (cspa_found) {
                if (cspa_found.type && (cspa_found.type == 'dropdown' || cspa_found.type == 'checkbox')) {
                    //answers_rendered = JSON.stringify(cspa_found.definition);
                    const options = [];
                    cspa_found.definition.forEach(dd_item => {
                        if (!dd_item) {
                            return;
                        }
                        const field_name = 'cspa_' + cspa_found.id;
                        options.push(
                            <>
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={this.filterCheckboxIsChecked(
                                            filter_index,
                                            cspa_found.id,
                                            dd_item.value
                                        )}
                                        name={field_name}
                                        value={dd_item.value}
                                        onChange={e => that.changeScreenerFilterValue(e, filter_index, field_name)}
                                    />
                                    &nbsp;&nbsp;{dd_item.label}
                                </label>
                                <br />
                            </>
                        );
                    });

                    answers_rendered = options;
                } else {
                    answers_rendered = (
                        <Input
                            autoFocus
                            style={{ width: '100%' }}
                            value={filter && filter.values ? filter.values[0] : ''}
                            type="text"
                            onChange={e => that.changeScreenerFilterValue(e, filter_index, filter.id)}
                        />
                    );
                }
            }
        } else if (filter && filter.type == 'panel' && filter.id) {
            let column_found = this.props.panel_columns.find(col => filter.id == col.id);

            if (column_found) {
                if (column_found.type == 'dropdown' || column_found.type == 'checkbox') {
                    const options = [];
                    column_found.definition.forEach(dd_item => {
                        const field_name = 'panel_' + column_found.id;
                        options.push(
                            <>
                                <label>
                                    <input
                                        type="checkbox"
                                        checked={this.filterCheckboxIsChecked(
                                            filter_index,
                                            column_found.id,
                                            dd_item.value
                                        )}
                                        name={field_name}
                                        value={dd_item.value}
                                        onChange={e => that.changeScreenerFilterValue(e, filter_index, field_name)}
                                    />
                                    &nbsp;&nbsp;{dd_item.label}
                                </label>
                                <br />
                            </>
                        );
                    });

                    answers_rendered = options;
                } else if (column_found.type == 'date') {
                    let selected_val = '';

                    if (filter && filter.values && filter.values[0]) {
                        if (filter.values[0] instanceof Date == false) {
                            const mom = moment(filter.values[0]);
                            if (mom.isValid()) {
                                selected_val = mom.toDate();
                            }
                        } else {
                            selected_val = filter.values[0];
                        }
                    }

                    answers_rendered = (
                        <Box sx={{ position: 'relative' }}>
                            <DatePicker
                                className="theme-input datepicker-input"
                                selected={selected_val}
                                onChange={date => {
                                    that.changeScreenerFilterValue(
                                        { target: { value: date } },
                                        filter_index,
                                        filter.id
                                    );
                                }}
                                showYearDropdown
                                dropdownMode="select"
                                showMonthDropdown
                            />
                            <Label sx={{ position: 'absolute', right: '4px', top: '4px' }}>
                                <FiCalendar />
                            </Label>
                        </Box>
                    );
                } else {
                    if (filter.compare == 'between') {
                        answers_rendered = (
                            <>
                                <Input
                                    autoFocus
                                    style={{ width: '100%' }}
                                    value={filter && filter.values ? filter.values[0] : ''}
                                    type="text"
                                    onChange={e => that.changeScreenerFilterValue(e, filter_index, filter.id, 0)}
                                />
                                <Box my={2}>and</Box>
                                <Input
                                    autoFocus
                                    style={{ width: '100%' }}
                                    value={filter && filter.values ? filter.values[1] : ''}
                                    type="text"
                                    onChange={e => that.changeScreenerFilterValue(e, filter_index, filter.id, 1)}
                                />
                            </>
                        );
                    } else {
                        answers_rendered = (
                            <Input
                                autoFocus
                                style={{ width: '100%' }}
                                value={filter && filter.values ? filter.values[0] : ''}
                                type="text"
                                onChange={e => that.changeScreenerFilterValue(e, filter_index, filter.id)}
                            />
                        );
                    }
                }
            }
        } else if (filter && filter.type == 'assigned') {
            let user_found = this.props.users.find(u => filter.id == u.id);

            const options = [];
            this.props.users.forEach(user => {
                if (!user) {
                    return;
                }
                const field_name = 'user_id_assigned';
                options.push(
                    <>
                        <label>
                            <input
                                type="checkbox"
                                checked={this.filterCheckboxIsChecked(filter_index, 'assigned', user.id)}
                                name={field_name}
                                value={user.id}
                                onChange={e => that.changeScreenerFilterValue(e, filter_index, field_name)}
                            />
                            &nbsp;&nbsp;{user.name}
                        </label>
                        <br />
                    </>
                );
            });

            answers_rendered = options;
        } else if (filter && filter.type == 'scheduled') {
            let options = [];

            let avail_found = this.props.availability.find(a => filter.id == a.id);

            console.log(this.props.availability);

            this.props.availability.forEach(avail => {
                if (!avail) {
                    return;
                }

                if (avail.id) {
                    const field_name = 'session_spot';
                    options.push(
                        <Box>
                            <label>
                                <input
                                    type="checkbox"
                                    checked={this.filterCheckboxIsChecked(filter_index, 'scheduled', avail.id)}
                                    name={field_name}
                                    value={avail.id}
                                    onChange={e => that.changeScreenerFilterValue(e, filter_index, field_name)}
                                />
                                &nbsp;&nbsp;{avail.title}
                            </label>
                        </Box>
                    );
                } else {
                    if (avail.label && avail.title) {
                        options.push(
                            <Box sx={{ fontWeight: 500 }} mt={2}>
                                <label>{avail.title}</label>
                            </Box>
                        );
                    }
                }
            });

            answers_rendered = options;
        } else if (filter && filter.type == 'paid') {
            if (filter.compare == 'between') {
                answers_rendered = (
                    <>
                        <Input
                            autoFocus
                            style={{ width: '100%' }}
                            value={filter && filter.values ? filter.values[0] : ''}
                            type="number"
                            onChange={e => that.changeScreenerFilterValue(e, filter_index, filter.id, 0)}
                            onKeyPress={helpers.ignoreNonNumericInput}
                        />
                        <Box my={2}>and</Box>
                        <Input
                            autoFocus
                            style={{ width: '100%' }}
                            value={filter && filter.values ? filter.values[1] : ''}
                            type="number"
                            onChange={e => that.changeScreenerFilterValue(e, filter_index, filter.id, 1)}
                            onKeyPress={helpers.ignoreNonNumericInput}
                        />
                    </>
                );
            } else {
                answers_rendered = (
                    <Input
                        autoFocus
                        style={{ width: '100%' }}
                        value={filter && filter.values ? filter.values[0] : ''}
                        type="number"
                        onChange={e => that.changeScreenerFilterValue(e, filter_index, filter.id)}
                        onKeyPress={helpers.ignoreNonNumericInput}
                    />
                );
            }
        } else if (filter && filter.type == 'sp_status') {
            let options = [];

            Object.keys(SP_STATUS_KEY_TITLE).forEach(sps_id => {
                // ignore the 0 key
                if (sps_id == 0) {
                    return;
                }

                const sps_label = SP_STATUS_KEY_TITLE[sps_id];

                const field_name = 'sp_status_selection';
                options.push(
                    <Box>
                        <label>
                            <input
                                type="checkbox"
                                checked={this.filterCheckboxIsChecked(filter_index, 'sp_status', sps_id)}
                                name={field_name}
                                value={sps_id}
                                onChange={e => that.changeScreenerFilterValue(e, filter_index, field_name)}
                            />
                            &nbsp;&nbsp;{sps_label}
                        </label>
                    </Box>
                );
            });

            answers_rendered = options;
        } else if (filter && filter.type == 'last_contacted_at') {
            let selected_val = '';

            if (filter && filter.values && filter.values[0]) {
                if (filter.values[0] instanceof Date == false) {
                    const mom = moment(filter.values[0]);
                    if (mom.isValid()) {
                        selected_val = mom.toDate();
                    }
                } else {
                    selected_val = filter.values[0];
                }
            }

            //console.log(selected_val)

            answers_rendered = (
                <Flex sx={{ position: 'relative' }}>
                    <DatePicker
                        className="theme-input datepicker-input"
                        selected={selected_val}
                        onChange={date => {
                            console.log(date);
                            that.changeScreenerFilterValue({ target: { value: date } }, filter_index, filter.id);
                        }}
                        showYearDropdown
                        dropdownMode="select"
                        showMonthDropdown
                        timeInputLabel="Time:"
                        dateFormat="MM/dd/yyyy h:mm aa"
                        showTimeInput
                    />
                    <Label sx={{ position: 'absolute', right: '4px', top: '4px' }}>
                        <FiCalendar />
                    </Label>
                </Flex>
            );
        }

        return answers_rendered;
    }

    shouldShowUpdateFilters() {
        const hide_in = [
            'standard',
            'screener_answered',
            'screener_qualified',
            'screener_disqualified',
            'screener_incomplete'
        ];
        if (
            this.state.editScreenerFilterInfo &&
            this.state.editScreenerFilterInfo.filter &&
            this.state.editScreenerFilterInfo.filter.type &&
            !hide_in.includes(this.state.editScreenerFilterInfo.filter.type)
        ) {
            return true;
        } else {
            return false;
        }
    }

    removeScreenerFilter(sf_key) {
        const { screenerFilters } = this.state;
        screenerFilters.splice(sf_key, 1);
        // this.setState({ screenerFilters });

        this.setState({ screenerFilters }, () => {
            this.props.onScreenerFiltersChange(screenerFilters);
        });
    }

    updateAndOr(e) {
        const and_or_value = e.target.value == 'or' ? 'or' : 'and';
        let { screenerFilters } = this.state;
        if (screenerFilters && screenerFilters.length > 0) {
            screenerFilters.forEach(sf => {
                sf.and_or_value = and_or_value;
            });
        }

        this.setState({ screenerFilters }, () => {
            this.props.onScreenerFiltersChange(screenerFilters);
        });
    }

    getDD1ChildDropdown(filterInfo) {
        //console.log(filterInfo)
        let question_element;
        let additional_question_options = [];
        if (filterInfo && filterInfo.filter) {
            const { filter } = filterInfo;
            if (filter.type == 'screener') {
                const all_questions = helpersStudy.getAllScreenerQuestionsList(
                    this.props.study.screener,
                    this.props.study.screeners
                );
                question_element = helpersScreener.getQuestionByFieldName(all_questions, filter.field_name);
            }
        }

        if (helpersScreener.isElementTextMatrix(question_element)) {
            additional_question_options = [];
            additional_question_options.push(<option></option>);
            let matrix_options = helpersStudy.generateMatrixAnswerCombinations(question_element.options);
            if (matrix_options) {
                //console.log(matrix_options);
                matrix_options.forEach(option => {
                    additional_question_options.push(<option value={option.row_col_key}>{option.row_col_text}</option>);
                });
            }
            return (
                <>
                    <Box mt={3}>
                        <Label className="quiet">Which matrix field?</Label>
                    </Box>
                    <Select
                        value={filterInfo.filter.id_child}
                        onChange={e => this.changeScreenerFilterQuestionChild(filterInfo.index, e.target.value)}
                    >
                        {additional_question_options}
                    </Select>
                </>
            );
        } else {
            return null;
        }
    }

    render() {
        let filters_more_menu_items = [];

        /*filters_more_menu_items.push({
            title: 'Clear Filters',
            onClick: () => {
                const screenerFilters = [];
                this.setState({ screenerFilters: screenerFilters }, () => {
                    this.props.onScreenerFiltersChange(screenerFilters);
                });
            }
        });*/

        if (this.props.filtersOnStudyPage) {
            filters_more_menu_items.push({
                title: 'Reset Segment',
                onClick: e => {
                    let screenerFilters = [];
                    if (this.state.study && this.state.study.study_states) {
                        const ss = this.state.study.study_states.find(ss => ss.id == this.state.segment);
                        if (ss) {
                            screenerFilters = ss.filters_original;
                        }
                    }

                    this.setState({ screenerFilters: screenerFilters }, () => {
                        this.props.onScreenerFiltersChange(screenerFilters);
                    });
                }
            });

            filters_more_menu_items.push({
                title: 'Rename Segment',
                onClick: e => {
                    this.props.onScreenerFiltersRename();
                }
            });

            if (this.props.onScreenerFiltersDelete) {
                filters_more_menu_items.push({ id: 'divider', divider: true });
                filters_more_menu_items.push({
                    title: 'Delete Segment',
                    color: 'red',
                    onClick: () => {
                        this.props.onScreenerFiltersDelete();
                    }
                });
            }
        }

        return (
            <Box fontSize={1} sx={{ position: 'relative' }}>
                {this.props.filterIsChild == true ? (
                    <></>
                ) : (
                    <Box>
                        {this.props.filtersOnStudyPage == true &&
                            (this.state.showScreenerFiltersEditorIndividual == true ? (
                                <Box mx={4} my={3}>
                                    <H1>Edit Filter</H1>
                                </Box>
                            ) : (
                                <Box
                                    px={4}
                                    py={3}
                                    sx={{
                                        borderBottom: '1px solid #eee',
                                        position: 'sticky',
                                        top: 0,
                                        background: '#fff',
                                        zIndex: 10
                                    }}
                                >
                                    <Flex sx={{ justifyContent: 'space-between' }}>
                                        <H1 style={{ marginBottom: '0' }}>Filters</H1>
                                        <Flex>
                                            <Box sx={{ position: 'relative' }}>
                                                <Button
                                                    variant={
                                                        this.areNewFiltersDifferent() == true
                                                            ? 'primary'
                                                            : 'secondary-gray'
                                                    }
                                                    type="button"
                                                    mr={2}
                                                    onClick={e => this.setState({ showSaveFiltersMenu: true })}
                                                >
                                                    Save Segment <FiChevronDown style={{ marginRight: 0 }} />
                                                </Button>
                                                {this.state.showSaveFiltersMenu && (
                                                    <NiceDropdown
                                                        width="180px"
                                                        //positionLeft="auto"
                                                        positionRight="8px"
                                                        onClose={() => this.setState({ showSaveFiltersMenu: false })}
                                                        items={[
                                                            {
                                                                title: 'Save to current segment',
                                                                color: '#0A73EB',
                                                                icon: <FiSave />,
                                                                onClick: () => {
                                                                    this.props.onScreenerFiltersSave(
                                                                        this.state.screenerFilters
                                                                    );
                                                                }
                                                            },
                                                            { id: 'divider', divider: true },
                                                            {
                                                                title: 'Save as new segment',
                                                                icon: <FiPlus />,
                                                                onClick: () => {
                                                                    this.props.onScreenerFiltersSaveAsNew(
                                                                        this.state.screenerFilters
                                                                    );
                                                                }
                                                            }
                                                        ]}
                                                    />
                                                )}
                                            </Box>

                                            <Box sx={{ position: 'relative' }}>
                                                <Button
                                                    type="button"
                                                    variant={'secondary-gray'}
                                                    mr={0}
                                                    onClick={e => this.setState({ showFiltersMoreMenu: true })}
                                                >
                                                    <FiMoreVertical style={{ marginRight: 0 }} />
                                                </Button>
                                                {this.state.showFiltersMoreMenu && (
                                                    <NiceDropdown
                                                        width="96px"
                                                        //positionLeft="auto"
                                                        positionRight="0px"
                                                        onClose={() => this.setState({ showFiltersMoreMenu: false })}
                                                        items={filters_more_menu_items}
                                                    />
                                                )}
                                            </Box>
                                        </Flex>
                                    </Flex>
                                </Box>
                            ))}
                    </Box>
                )}
                <Box m={this.props.filterIsChild == true ? 3 : this.props.filtersOnStudyPage == true ? 4 : 0}>
                    {this.state.showScreenerFiltersEditorIndividual == false ? (
                        <>
                            {this.state.screenerFilters && this.state.screenerFilters.length > 0 ? (
                                this.state.screenerFilters.map((sf, sf_key) => {
                                    let map_return = [];

                                    if (this.state.screenerFilters.length > 1 && sf_key == 0) {
                                        map_return.push(
                                            <Box mb={3} className="gray">
                                                <Select
                                                    value={sf.and_or_value}
                                                    onChange={e => this.updateAndOr(e)}
                                                    style={{ marginRight: '4px', maxWidth: '80px' }}
                                                >
                                                    <option value="and">AND</option>
                                                    <option value="or">OR</option>
                                                </Select>
                                            </Box>
                                        );
                                    }

                                    if (sf.type == 'group') {
                                        try {
                                            map_return.push(
                                                <Box
                                                    className="border rounded"
                                                    style={{ background: 'rgba(240, 240, 240, .4)' }}
                                                >
                                                    <Flex
                                                        px={3}
                                                        py={2}
                                                        sx={{
                                                            justifyContent: 'space-between',
                                                            borderBottom: '1px solid #eee'
                                                        }}
                                                    >
                                                        <Box style={{ marginTop: '6px' }}>
                                                            <Label className="quiet">Filter Group</Label>
                                                        </Box>
                                                        <Box>
                                                            <Button
                                                                type="button"
                                                                variant="transparent-icon"
                                                                onClick={e => {
                                                                    e.stopPropagation();
                                                                    this.removeScreenerFilter(sf_key);
                                                                }}
                                                            >
                                                                <FiX />
                                                            </Button>
                                                        </Box>
                                                    </Flex>

                                                    <StudyFilters
                                                        disallowStandardFilters={true}
                                                        disallowPanelFilters={true}
                                                        study={this.props.study}
                                                        screenerIdsOnly={this.props.screenerIdsOnly}
                                                        panel_columns={this.props.panel_columns}
                                                        users={this.props.users}
                                                        availability={this.props.study_availability_array}
                                                        screenerFilters={sf.values}
                                                        onScreenerFiltersChange={sf => {
                                                            //console.log('group change', sf_key, sf)
                                                            let { screenerFilters } = this.state;
                                                            //console.log('sf index', screenerFilters[sf_key])
                                                            if (screenerFilters[sf_key]) {
                                                                screenerFilters[sf_key].values = sf;
                                                                const that = this;
                                                                this.setState({ screenerFilters }, () => {
                                                                    that.props.onScreenerFiltersChange(screenerFilters);
                                                                });
                                                            }
                                                        }}
                                                        onClose={() => {
                                                            //this.setState({
                                                            //    showScreenerFilters: false
                                                            //});
                                                        }}
                                                        filterIsChild={true}
                                                    />
                                                </Box>
                                            );
                                        } catch (e) {
                                            helpers.trackError(e);
                                        }
                                    } else {
                                        map_return.push(
                                            <Box>
                                                <Flex
                                                    className="border border-hover clickable"
                                                    p={3}
                                                    sx={{ justifyContent: 'space-between', background: '#fefefe' }}
                                                    onClick={e => {
                                                        this.setState({
                                                            showScreenerFiltersEditorIndividual: true,
                                                            showScreenerFiltersDD1: false,
                                                            editScreenerFilterInfo: { filter: sf, index: sf_key }
                                                        });
                                                    }}
                                                >
                                                    <Box>{this.renderScreenerFilterIndividual(sf)}</Box>
                                                    <Box sx={{ flexShrink: 0 }}>
                                                        <Button
                                                            type="button"
                                                            variant="transparent-icon"
                                                            onClick={e => {
                                                                e.stopPropagation();
                                                                this.removeScreenerFilter(sf_key);
                                                            }}
                                                        >
                                                            <FiX />
                                                        </Button>
                                                    </Box>
                                                </Flex>
                                            </Box>
                                        );
                                    }

                                    if (this.state.screenerFilters.length > sf_key + 1) {
                                        map_return.push(
                                            <Box my={2} className="gray">
                                                {this.getScreenerFiltersAndOrValue()}
                                            </Box>
                                        );
                                    }

                                    return map_return;
                                })
                            ) : (
                                <Box className="text-primary strong">0 filters added</Box>
                            )}

                            <Flex justifyContent="space-between" mt={2}>
                                <Box>
                                    <Button
                                        type="button"
                                        variant={this.props.filterIsChild == true ? 'secondary-gray' : 'secondary-gray'}
                                        mr={2}
                                        onClick={e => {
                                            this.setState({
                                                showScreenerFiltersEditorIndividual: true,
                                                showScreenerFiltersDD1: true,
                                                editScreenerFilterInfo: { index: 'new', filter: {} }
                                            });
                                        }}
                                    >
                                        <FiPlus />
                                        Add filter
                                    </Button>

                                    {this.props.filterIsChild != true && (
                                        <Button
                                            type="button"
                                            variant="secondary-gray"
                                            onClick={e => {
                                                let { screenerFilters } = this.state;
                                                screenerFilters.push(this.generateFilterGroup());
                                                this.setState({ screenerFilters });
                                            }}
                                        >
                                            Add group
                                        </Button>
                                    )}
                                </Box>
                                <Box>
                                    {this.props.filterIsChild != true && this.state.screenerFilters.length > 0 && (
                                        <Button
                                            type="button"
                                            variant="transparent"
                                            ml={2}
                                            onClick={() => {
                                                const screenerFilters = [];
                                                this.setState({ screenerFilters: screenerFilters }, () => {
                                                    this.props.onScreenerFiltersChange(screenerFilters);
                                                });
                                            }}
                                        >
                                            Clear All
                                        </Button>
                                    )}
                                </Box>
                            </Flex>
                        </>
                    ) : (
                        <Box>
                            <Button
                                type="button"
                                variant="link"
                                mb={2}
                                onClick={e => {
                                    this.setState({ showScreenerFiltersEditorIndividual: false });
                                }}
                            >
                                <FiChevronLeft /> {this.props.filterIsChild == true ? 'Back to filter group' : 'Back'}
                            </Button>

                            <Label className="quiet">Filter</Label>
                            <Box sx={{ position: 'relative' }} mb={3}>
                                <Button
                                    className="mw-100"
                                    type="button"
                                    variant="tertiary"
                                    onClick={() => this.setState({ showScreenerFiltersDD1: true })}
                                >
                                    <FiChevronDown
                                        style={{
                                            marginRight: 0,
                                            float: 'right'
                                        }}
                                    />
                                    {this.getScreenerFilterDD1Value(this.state.editScreenerFilterInfo)}
                                </Button>
                                {this.state.showScreenerFiltersDD1 && (
                                    <NiceDropdown
                                        revealEllipsis={true}
                                        width="100%"
                                        showSearch
                                        onClose={() => this.setState({ showScreenerFiltersDD1: false })}
                                        items={this.screenerFiltersDD1Items(this.state.editScreenerFilterInfo.index)}
                                        onChange={json_string => {
                                            const json = JSON.parse(json_string);
                                            const { filter } = json;
                                            const { index } = json;

                                            //console.log(json);
                                            const { editScreenerFilterInfo } = this.state;
                                            editScreenerFilterInfo.filter = {};
                                            editScreenerFilterInfo.filter.type = filter.filter_type;

                                            if (filter.filter_type == 'screener') {
                                                editScreenerFilterInfo.filter.field_name = filter.id;
                                            } else {
                                                editScreenerFilterInfo.filter.id = filter.id;
                                            }

                                            const that = this;
                                            this.setState({ editScreenerFilterInfo }, () => {
                                                that.changeScreenerFilterTypeId(index, filter.filter_type, filter.id);
                                            });
                                        }}
                                    />
                                )}

                                {this.getDD1ChildDropdown(this.state.editScreenerFilterInfo)}
                            </Box>

                            {this.shouldShowDD2(this.state.editScreenerFilterInfo) == true && (
                                <>
                                    <Label className="quiet">Condition</Label>
                                    <Box sx={{ position: 'relative' }} mb={3}>
                                        <Button
                                            type="button"
                                            variant="tertiary"
                                            onClick={() => this.setState({ showScreenerFiltersDD2: true })}
                                        >
                                            {this.getScreenerFilterDD2Value(this.state.editScreenerFilterInfo)}
                                            <FiChevronDown
                                                style={{
                                                    marginRight: 0
                                                }}
                                            />
                                        </Button>
                                        {this.state.showScreenerFiltersDD2 && (
                                            <NiceDropdown
                                                revealEllipsis={true}
                                                showSearch
                                                onClose={() => this.setState({ showScreenerFiltersDD2: false })}
                                                items={this.screenerFiltersDD2Items(
                                                    this.state.editScreenerFilterInfo.index
                                                )}
                                                onChange={compare => {
                                                    const { editScreenerFilterInfo } = this.state;
                                                    editScreenerFilterInfo.compare = compare;
                                                    this.setState({ editScreenerFilterInfo });

                                                    this.changeScreenerFilterCompare(
                                                        editScreenerFilterInfo.index,
                                                        compare
                                                    );
                                                }}
                                            />
                                        )}
                                    </Box>
                                </>
                            )}

                            {this.shouldShowDD3(this.state.editScreenerFilterInfo) == true && (
                                <>
                                    <Label className="quiet">Value</Label>
                                    <Box sx={{ position: 'relative' }} mb={3}>
                                        {this.screenerFiltersDD3Items(this.state.editScreenerFilterInfo)}
                                    </Box>
                                </>
                            )}

                            {this.shouldShowUpdateFilters() == true && (
                                <Box mt={4}>
                                    <Button
                                        type="button"
                                        variant="primary"
                                        // disable if compare is not set
                                        disabled={
                                            this.shouldShowDD2(this.state.editScreenerFilterInfo) == true &&
                                            this.state.editScreenerFilterInfo.filter.compare == null
                                        }
                                        onClick={e => {
                                            //console.log('APPLYING FILTR....');
                                            this.props.onScreenerFiltersChange(this.state.screenerFilters);
                                            this.setState({ showScreenerFiltersEditorIndividual: false });
                                        }}
                                    >
                                        Apply filter
                                    </Button>
                                </Box>
                            )}
                        </Box>
                    )}
                </Box>
            </Box>
        );
    }
}

export default withToastManager(StudyFilters);
