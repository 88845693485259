/*
 * StudiesPage
 *
 * This is the first thing users see of our App, at the '/' route
 */

import React, { useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import xhr from 'xhr.js';
import styled from 'styled-components';

import SurveyView from 'components/Survey/SurveyView';
import H1 from 'components/H1';
import H2 from 'components/H2';

import { Flex, Box, Button } from 'rebass';
import { Label, Input } from '@rebass/forms';
import config from 'config';
import { FcGoogle } from 'react-icons/fc';
import NiceWrapper from 'components/NiceWrapper';
import auth from 'services/auth';

import { MarketingPageWrapper } from 'components/Marketing';

const Sepratator = styled.div`
    width: 1px;
    height: 16px;
    background: #d9d2e8;
    margin: 8px 12px 0;
    display: inline-block;
    vertical-align: top;
`;

class SurveyViewPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
        /* xhr.get(`/me`, { withCredentials: true }).then(res => {
            const user = res.data;

            this.setState({ user });
        }); */
    }

    render() {
        return (
            <MarketingPageWrapper>
                <Helmet>
                    <title>Survey</title>
                </Helmet>

                <SurveyView />
            </MarketingPageWrapper>
        );
    }
}

export default SurveyViewPage;
