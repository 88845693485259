import React, { useMemo } from 'react';
import ReactTooltip from 'react-tooltip';
import { Box } from 'rebass';
import helpers from 'utils/helpers';
import { FiLoader, FiAlertTriangle, FiFastForward, FiCheckCircle, FiPauseCircle } from 'react-icons/fi';
import moment from 'moment-timezone';

import Badge from 'components/Badge';

/**
 * Sync status badge. Displays a badge with an icon and text.
 *
 * @param {Object} props
 * @param {0 | 1 | 2 | 3 | 4} props.status Sync status.
 * @param {string?} props.lastExecuted Last sync time.
 * @param {string?} props.note Sync error note.
 * 0 = STATUS_READY, 1 = STATUS_IN_PROGRESS, 2 = STATUS_FINISHED, 3 = STATUS_PAUSED, 4 = STATUS_ERROR
 */
export const SyncStatusBadge = ({ status, lastExecuted, note }) => {
    const id = useMemo(() => helpers.ID(), []);

    const config = useMemo(() => {
        switch (status) {
            case 0: // STATUS_READY = 0;
                return {
                    icon: <FiFastForward />,
                    type: 'pure',
                    content: 'Ready To Sync',
                    tooltip: 'Please click refresh sync to start sync'
                };
            case 1: //STATUS_IN_PROGRESS = 1;
                return {
                    icon: <FiLoader />,
                    type: 'info',
                    content: 'Sync Running'
                };
            case 2: //STATUS_FINISHED = 2;
                return {
                    icon: <FiCheckCircle />,
                    type: 'success',
                    content: 'Sync Finished',
                    tooltip: lastExecuted ? `Last sync: ${moment.utc(lastExecuted).fromNow()}` : undefined
                };
            case 3: //STATUS_PAUSED = 3;
                return {
                    icon: <FiPauseCircle />,
                    type: 'warn',
                    content: 'Sync Paused',
                    tooltip: 'Integration sync is paused'
                };
            case 4: //STATUS_ERROR = 4;
                return {
                    icon: <FiAlertTriangle />,
                    type: 'danger',
                    content: 'Sync Error',
                    tooltip: `Sync had an error ${note ? `: ${note}` : ''}`
                };
        }
    }, [status]);

    return (
        <>
            <Badge data-for={id} data-tip type={config.type}>
                {config.icon}
                {config.content}
            </Badge>
            {config.tooltip && (
                <ReactTooltip css={{ maxWidth: '400px', zIndex: 10 }} id={id} effect="solid" place="top">
                    <Box>{config.tooltip}</Box>
                </ReactTooltip>
            )}
        </>
    );
};

export default SyncStatusBadge;
