import styled from 'styled-components';

const H1 = styled.h1`
    font-size: 20px;
    margin-bottom: 16px;
    margin-top: 0;
    font-weight: 600;
    //height: 32px;
    //text-overflow: ellipsis;
    //white-space: nowrap;
    //overflow: hidden;
    color: #000;

    svg {
        vertical-align: top;
        margin: 4px 4px 0 0;
    }

    a {
        color: #000;
    }

    @media (max-width: 768px) {
        font-size: 1em;
    }
`;

export default H1;
