import React from 'react';
import { Box, Text } from 'rebass';
import { Label, Checkbox } from '@rebass/forms';

const TermsOfServiceCheckbox = props => (
    <>
        <Label mb={props.showError ? 3 : 4}>
            <Checkbox defaultChecked={props.accepted} onChange={e => props.checkboxOnClick(e.target.checked)} />
            <Box mt="4px" pl="8px">
                <Text textAlign="left" color="#001233" fontSize="15px" fontWeight={500}>
                    I agree to the{' '}
                    <a href="https://docs.panelfox.io/article/52-terms-of-service" target="_blank">
                        Terms of Service
                    </a>
                </Text>
                <Text textAlign="left" color="#606E85" fontSize="13px" mt="6px" lineHeight="1.4">
                    Learn about how we use and protect your data in our{' '}
                    <a href="https://docs.panelfox.io/article/51-privacy-policy" target="_blank">
                        Privacy Policy
                    </a>
                </Text>
                {props.showError && (
                    <Text mt="6px" textAlign="left" color="red" fontSize="13px">
                        Please agree to the Terms of Service
                    </Text>
                )}
            </Box>
        </Label>
    </>
);

export default TermsOfServiceCheckbox;
