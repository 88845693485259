import React from 'react';
import classNames from 'classnames';

export default props => (
    <div
        style={{
            position: 'fixed',
            top: '32px',
            left: 0,
            right: 0,
            zIndex: 99,
            textAlign: 'center',
            margin: '0 auto'
        }}
        {...props}
    >
        <div
            className={classNames(
                'text-primary rounded-8 border',
                props.version == 'danger' ? 'color-bg-danger-light border-danger' : 'bg-white'
            )}
            style={{
                display: 'inline-block',
                maxWidth: '800px',
                padding: '24px',
                fontWeight: 500,
                textAlign: 'left'
            }}
        >
            {props.children}
        </div>
    </div>
);
