import React from 'react';
import { Box } from 'rebass';
import classNames from 'classnames';

/**
 * StatusIndicator component
 *
 * @param {Object} props
 * @param {'gray'|'green'|'red'|'yellow'} props.color The color of the status indicator
 * @param {boolean?} props.isFullyColored Whether the status indicator should be fully colored
 * @param {React.ReactNode} props.children The content of the status indicator
 * @param {string?} props.className The class name of the status indicator
 */
export function StatusIndicator({ color, children, className, isFullyColored }) {
    return (
        <Box
            as="p"
            display="inline-block"
            className={classNames(
                'status-indicator fs-title-12',
                `status-indicator-${color}`,
                isFullyColored && 'status-indicator-fully-colored',
                className
            )}
        >
            <Box className="status-indicator-dot" as="span" marginRight="4px" />
            {children}
        </Box>
    );
}

export default StatusIndicator;
