import React from 'react';
import { useToasts } from 'react-toast-notifications';
import { FiLayers } from 'react-icons/fi';

import useQuery from 'hooks/useQuery';
import studyService from 'services/studies';
import services from 'services/services';
import useMutation from 'hooks/useMutation';
import SelectModal from 'components/SelectModal';
import ToastSuccess from 'containers/ParticipantsPage/components/ToastSuccess';

/**
 * The modal for selecting a study to add participants to.
 *
 * @param {Object} param0 props
 * @param {() => void} param0.onClose A callback for when the modal closes
 * @param {string} param0.title The title of the modal
 * @param {number[]} param0.participantIds The ids of the participants to add to the study
 * @param {boolean} param0.isOpen Whether the modal is open
 * @returns {AddToStudyModal} The component
 */
export function AddToStudyModal({ onClose, participantIds, isOpen, title }) {
    const { addToast } = useToasts();
    const { data: studies, isLoading: isStudiesLoading } = useQuery({
        queryFn: () => studyService.getStudiesSimple(),
        onError: error => {
            const errorText = services.parseAndTrackXhrErrors(error);
            addToast(errorText, {
                appearance: 'error',
                autoDismiss: true
            });
        }
    });
    const { mutate: createStudy, isLoading: isStudyCreating } = useMutation({
        mutationFn: ({ studyTitle }) =>
            services.createStudyAddPeople({
                title: studyTitle,
                people_ids: participantIds.map(participantId => ({
                    id: participantId
                })),
                all: 0
            }),
        onSuccess: study => {
            addToast(<ToastSuccess studyId={study.id} studyName={study.title} />, {
                appearance: 'success',
                big: true
            });
            onClose();
        },
        onError: (_, errorText) => {
            addToast(errorText, {
                appearance: 'error',
                autoDismiss: false
            });
        }
    });
    const { mutate: addToStudy, isLoading: isAddingToStudy } = useMutation({
        mutationFn: async ({ studyId }) => {
            await services.addPeopleToStudy(studyId, {
                people_ids: participantIds.map(participantId => ({
                    id: participantId
                })),
                all: 0
            });

            // add people to study doesn't return anything important so we just return the studyId
            return studyId;
        },
        onSuccess: studyId => {
            addToast(<ToastSuccess studyId={studyId} />, {
                appearance: 'success',
                big: true
            });
            onClose();
        },
        onError: (_, errorText) => {
            addToast(errorText, {
                appearance: 'error',
                autoDismiss: false
            });
        }
    });

    if (isStudiesLoading) return null;

    return (
        <SelectModal
            type="study"
            title={title}
            icon={<FiLayers />}
            items={studies}
            isOpen={isOpen}
            isLoading={isStudiesLoading || isStudyCreating || isAddingToStudy}
            onRequestClose={onClose}
            onSelect={studyId => addToStudy({ studyId })}
            onCreate={studyTitle => createStudy({ studyTitle })}
        />
    );
}

export default AddToStudyModal;
