import React from 'react';
import { Box, Button } from 'rebass';
import { Label, Input } from '@rebass/forms';
import { useToasts } from 'react-toast-notifications';

import NiceModal from 'components/NiceModal';
import { Portal } from 'components/Portal';
import useForm from 'hooks/useForm';
import useMutation from 'hooks/useMutation';
import services from 'services/services';

/**
 * Modal to rename a panel properties group.
 *
 * @param {Object} props
 * @param {Object} props.group Group to be renamed
 * @param {number} props.group.id Group ID
 * @param {string} props.group.name Group name
 * @param {Function} props.onClose Function to be called when the modal is closed
 * @param {Function} props.onSuccess Function to be called when the group is renamed successfully
 */
export function RenamePanelPropertiesGroupModal({ group, onClose, onSuccess }) {
    const { addToast } = useToasts();
    const { handleSubmit, register } = useForm({
        initialForm: {
            name: group.name
        }
    });
    const { mutate, isLoading } = useMutation({
        mutationFn: data => services.updatePanelPropertiesGroup(group.id, data),
        onSuccess: () => {
            addToast('Group updated successfully', { appearance: 'success', autoDismiss: true });
            onSuccess && onSuccess();
            onClose && onClose();
        },
        onError: (_, message) => {
            addToast(message, { appearance: 'error', autoDismiss: true });
        }
    });

    const onSubmit = handleSubmit(async data => mutate(data));

    return (
        <Portal>
            <NiceModal isOpen shouldCloseOnOverlayClick onRequestClose={onClose} title="Rename a group">
                <form id={`update-group-${group.id}-form`} onSubmit={onSubmit}>
                    <Box>
                        <Label>Group title</Label>
                        <Input {...register('name')} autoFocus placeholder="Contact Information" />
                    </Box>
                </form>
                <Box className="modal-actions">
                    <Button type="button" mr={2} onClick={onClose} variant="secondary-gray">
                        Cancel
                    </Button>
                    <Button
                        type="submit"
                        variant="primary"
                        mr={0}
                        disabled={isLoading}
                        className="modal-primary"
                        form={`update-group-${group.id}-form`}
                    >
                        Save
                    </Button>
                </Box>
            </NiceModal>
        </Portal>
    );
}

export default RenamePanelPropertiesGroupModal;
