import React from 'react';
import { Box } from 'rebass';
import { Input } from '@rebass/forms';
import { FiSearch } from 'react-icons/fi';

export default props => (
    <Box style={{ position: 'relative', height: '32px', display: props.displayHidden ? 'none' : 'block' }}>
        <Input
            {...props}
            style={{ padding: '6px 0px 6px 36px' }}
            type="text"
            className="input-darker"
            autoFocus={props.autoFocus}
            placeholder={props.placeholder ? props.placeholder : 'Search'}
        />
        <FiSearch
            className="text-primary"
            style={{
                position: 'absolute',
                left: '12px',
                top: '8px',
                strokeWidth: '1.5px',
                fontSize: '16px'
            }}
        />
    </Box>
);
