import React from 'react';
import { Button } from 'rebass';
import { FiZap } from 'react-icons/fi';
import styled from 'styled-components';
import PropTypes from 'prop-types';

import helpers from 'utils/helpers';
import { PAID_FEATURE } from 'utils/constants';

const message = {
    [PAID_FEATURE.SMS_MASS]: {
        subject: 'Upgrade SMS',
        text: 'Hi Panelfox! We would like to get more information about the SMS upgrade.'
    },
    [PAID_FEATURE.SURVEY_VIDEO]: {
        subject: 'Upgrade Survey Video',
        text: 'Hi Panelfox! We would like to get more information about the Survey Video upgrade.'
    },
    [PAID_FEATURE.DSCOUT_INTEGRATION]: {
        subject: 'Upgrade dscout',
        text: 'Hi Panelfox! We would like to get more information about the dscout integration upgrade.'
    },
    [PAID_FEATURE.MULTIPLE_PANELS]: {
        subject: 'Upgrade Multiple Panels',
        text: 'Hi Panelfox! We would like to get more information about the Multiple Panels upgrade.'
    },
    [PAID_FEATURE.API_ACCESS]: {
        subject: 'Upgrade API Access',
        text: 'Hi Panelfox! We would like to get more information about the API Access upgrade.'
    }
};

const StyledButton = styled(Button)`
    .upgrade-prefix {
        color: #606e85;
    }

    .upgrade-prefix::after {
        content: '|';
        display: inline-block;
        color: #d4d9dd;
        font-weight: 500;
        text-align: center;
        margin: 0 6px;
    }
`;

/**
 * A button that opens the support chat with a message about upgrading
 *
 * @param {Object} props
 * @param {PAID_FEATURE} props.type - The type of upgrade button to show
 * @param {React.CSSProperties} [props.style] - The style of the button
 * @param {String} [props.prefix] - The prefix to show before the button text
 * @returns Upgrade Button
 */
export function UpgradeButton({ type, style, prefix }) {
    return (
        <StyledButton
            style={{ marginRight: 0, ...style }}
            type="button"
            onClick={() => helpers.openSupportChat(message[type])}
            variant="secondary"
        >
            {prefix && <span className="upgrade-prefix">{prefix}</span>}
            <FiZap /> Upgrade
        </StyledButton>
    );
}

UpgradeButton.propTypes = {
    type: PropTypes.oneOf(Object.values(PAID_FEATURE)).isRequired,
    style: PropTypes.object,
    prefix: PropTypes.string
};

export default UpgradeButton;
