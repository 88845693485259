import React from 'react';
import { Helmet } from 'react-helmet';
import ReactModal from 'react-modal';
// import Board from 'components/Board';
import 'styles/react-kanban.css';
import { withToastManager } from 'react-toast-notifications';
import { withRouter } from 'react-router-dom';
import moment from 'moment-timezone';
import services from 'services/services';
import service_studies from 'services/studies';
import service_accounts from 'services/accounts';
import styled from 'styled-components';
import UploadParticipants from 'components/Participant/UploadParticipants';
import config from 'config';
import auth from 'services/auth';
import { withTheme } from 'emotion-theming';
import store from 'store';
import DatePicker from 'react-datepicker';
import ReactModalActions from 'components/ReactModalActions';
import SelectModal from 'components/SelectModal';
import analytics from 'utils/analytics';

import {
    FiXCircle,
    FiChevronLeft,
    FiTrash2,
    FiCalendar,
    FiDollarSign,
    FiPlus,
    FiSun,
    FiSend,
    FiFileText,
    FiArrowDown,
    FiArrowUp,
    FiColumns,
    FiServer,
    FiSettings,
    FiSave,
    FiArrowRight,
    FiArrowLeft,
    FiX,
    FiCheckCircle,
    FiDownloadCloud,
    FiCopy,
    FiChevronDown,
    FiCheckSquare,
    FiMinus,
    FiCheck,
    FiPlayCircle,
    FiEdit,
    FiFilter,
    FiSliders,
    FiMinusCircle,
    FiMoreVertical,
    FiUserPlus,
    FiUser
} from 'react-icons/fi';

import StudyFilters from 'components/Study/StudyFilters';
import AddCandidates from 'components/Study/AddCandidates';
import VariablesHelper from 'components/EmailTools/VariablesHelper';
import ListWrapper from 'components/ListWrapper';
import { ReactFormGenerator } from 'components/ReactFormBuilder';
import NiceWrapper from 'components/NiceWrapper';
import helpers from 'utils/helpers';
import helpersStudy from 'utils/helpers-study';
import helpersScreener from 'utils/helpers-screener';
import { Link } from 'react-router-dom';
import H1 from 'components/H1';
import { Flex, Box, Button } from 'rebass';
import { Label, Input, Select, Textarea, Switch } from '@rebass/forms';
import StudyScript from 'components/Study/StudyScript';
import ParticipantNotes from 'components/Participant/ParticipantNotes';
import ParticipantPay from 'components/Participant/ParticipantPay';
import LoadingIndicator from 'components/LoadingIndicator';
import LoadingWrapper from 'components/LoadingIndicator/LoadingWrapper';
import EmailEditor from 'components/EmailTools/EmailEditor';

import StudyParticipantsList from 'components/Study/StudyParticipantsList';
import AppPageWrapper from 'components/AppPageWrapper';
import AppPageSubnav from 'components/AppPageWrapper/AppPageSubnav';
import AppPageBreadcrumbs from 'components/AppPageWrapper/AppPageBreadcrumbs';
import AppPageWrapperSectionHeader from 'components/AppPageWrapper/AppPageWrapperSectionHeader';
import AppPageWrapperSectionBody from 'components/AppPageWrapper/AppPageWrapperSectionBody';
import AppPageWrapperSectionSubHeader from 'components/AppPageWrapper/AppPageWrapperSectionSubHeader';
import StudyPageSubNav from 'components/Study/StudyPageSubNav';
import StyledParticipantItem from 'components/Participant/StyledParticipantItem';
// import CardAdder from 'components/Board/components/Column/components/CardAdder';
import ActionPopup from 'components/ActionPopup';
import NiceDropdown from 'components/NiceDropdown';
import CreateParticipant from 'components/Participant/CreateParticipant';
import { HeaderSubnavButton } from 'components/Header/HeaderSubnavButton';
import SideOverlay from 'components/SideOverlay';

import {
    SP_STATUS_KEY_TITLE,
    COMPARE_FILTERS,
    SCREENER_STUDY_ONLY,
    CURRENCY_SYMBOL,
    SP_PARTICIPANT_STATUS
} from 'utils/constants';

const STORE_PREFIX_SEGMENT = 'pfx_study_segment:';
const STORE_PREFIX_CSV_EXPORT_COLUMNS = 'pfx_study_csv_export:';

const CardMetadata = styled.div`
    position: relative;
    margin: 16px 0 0 0;
    color: #aaa;

    div {
        color: #555;
    }
`;

class StudyNewTablePage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            study: {}
        };
    }

    render() {
        return (
            <Flex style={{ width: '100%' }}>
                <AppPageWrapper>
                    <Helmet>
                        <title>{this.state.study.title}</title>
                    </Helmet>

                    <AppPageSubnav>
                        <AppPageBreadcrumbs
                            items={[
                                { url: `/studies`, title: `Studies` },
                                { url: null, title: 'test' }
                            ]}
                        ></AppPageBreadcrumbs>
                        <StudyPageSubNav study_id={this.props.match.params.id} />
                    </AppPageSubnav>

                    <AppPageWrapperSectionSubHeader style={{ background: '#fff', flexDirection: 'column' }}>
                        <Flex
                            mt={4}
                            sx={{
                                boxShadow: 'rgba(36, 18, 77, 0.08) 0px 1px 0px 0px',
                                justifyContent: 'space-between',
                                gridGap: 4
                            }}
                        >
                            TABS GO HERE | ADF | ASFD | ASFDAS
                        </Flex>

                        <Flex mt={4} mb={0} justifyContent="space-between">
                            Results go here, and search [ ]
                        </Flex>
                    </AppPageWrapperSectionSubHeader>

                    <AppPageWrapperSectionBody style={{ margin: '0 32px', width: 'calc(100% - 64px)' }}>
                        <div style={{ overflowX: 'scroll' }}>
                            LIST OF PEOPLE
                            <div style={{ width: '2200px' }}>
                                Person 1 long row long row long row long row long row long row long row long row long
                                row long row long row long row long row long row long row long row long row long row
                                long row long row long row long row long row long row long row long row long row long
                                row long row long row
                                <br />
                            </div>
                            Person 1<br />
                            Person 1<br />
                            Person 1<br />
                            Person 1<br />
                            Person 1<br />
                            Person 1<br />
                            Person 1<br />
                            Person 1<br />
                            Person 1<br />
                            Person 1<br />
                            Person 1<br />
                            Person 1<br />
                            Person 1<br />
                            Person 1<br />
                            Person 1<br />
                            Person 1<br />
                            Person 1<br />
                            Person 1<br />
                            Person 1<br />
                            Person 1<br />
                            Person 1<br />
                            Person 1<br />
                            Person 1<br />
                            Person 1<br />
                            Person 1<br />
                            Person 1<br />
                            Person 1<br />
                        </div>
                    </AppPageWrapperSectionBody>
                </AppPageWrapper>
            </Flex>
        );
    }
}

export default withRouter(withTheme(withToastManager(StudyNewTablePage)));
