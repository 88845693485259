import React, { useState } from 'react';
import { FiPlus } from 'react-icons/fi';
import styled from 'styled-components';
import { Box } from 'rebass';

import NiceModal from 'components/NiceModal';

export const AddButton = styled.button`
    border-radius: 18px;
    border: 1px dashed #495f781f;
    color: #000;
    background-color: transparent;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    min-height: 450px;
    max-height: 500px;
    cursor: pointer;
    font-size: 14px;
    font-weight: 500px;
    border-width: 2px;
    transition: all 0.3s ease-in-out;

    &:hover {
        border-color: #6200ff;
        color: #6200ff;
    }
`;

export const Option = styled.li`
    width: 100%;
    font-size: 14px;
    line-height: 40px;
    height: 40px;
    background-color: transparent;
    border: none;
    font-weight: 400;
    margin: 0;
    padding-left: 16px;
    cursor: pointer;

    &:hover {
        background-color: #f8f9ff;
    }
`;

/**
 * Button to add a property
 *
 * @param {Object} props props
 * @param {Function} props.onChange onChange
 * @param {Array} props.options options
 * @param {Object} props.options.value value
 * @param {string} props.options.label label
 * @returns {React.Component} The component
 */
export function AddPropertyButton({ onChange, options }) {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <AddButton onClick={() => setIsOpen(true)}>
                <FiPlus fontSize={20} color="#6200ff" />
                <span>Add Metric</span>
            </AddButton>
            <NiceModal isOpen={isOpen} onRequestClose={() => setIsOpen(false)} title="Add Metric">
                <Box height="300px" maxHeight="500px" overflow="auto">
                    <Box as="ul" sx={{ listStyleType: 'none', borderRadius: '8px' }}>
                        {options.map(option => (
                            <Option
                                key={option.value}
                                onClick={() => {
                                    if (onChange) onChange(option.value);
                                    setIsOpen(false);
                                }}
                            >
                                {option.label}
                            </Option>
                        ))}
                    </Box>
                </Box>
            </NiceModal>
        </>
    );
}

export default AddPropertyButton;
