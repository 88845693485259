import React, { useEffect, useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { Box, Button } from 'rebass';
import { FiMenu } from 'react-icons/fi';
import { MarketingPageWrapper, MarketingPageSection } from 'components/Marketing';

export default props => {
    const [isSticky, setSticky] = useState(false);
    const [isMobile, setIsMobile] = useState(false);
    const [showMobileNav, setShowMobileNav] = useState(false);
    const ref = useRef(null);

    const handleScroll = () => {
        if (ref.current) {
            // console.log('yesss....', window.scrollY, ref.current.getBoundingClientRect().bottom)
            // setSticky(ref.current.getBoundingClientRect().top <= 0);

            if (!props.notSticky) {
                // eslint-disable-next-line
                window.scrollY > ref.current.getBoundingClientRect().bottom ? setSticky(true) : setSticky(false);
            }
        }
    };

    useEffect(() => {
        window.addEventListener('scroll', handleScroll);
        window.addEventListener('resize', handleResize);
        handleResize();

        return () => {
            window.removeEventListener('scroll', () => handleScroll);
            window.removeEventListener('resize', () => handleResize);
        };
    }, []);

    const handleResize = () => {
        const newIsMobile = window.innerWidth <= 760;
        setIsMobile(newIsMobile);
    };

    const navLinks = (
        <Box
            style={{
                verticalAlign: 'top',
                textAlign: isMobile ? 'center' : 'left',
                marginTop: isMobile ? '16px' : '0'
            }}
        >
            {/*<Link to="/product">
                <Button variant="transparent" sx={{ color: 'black', marginRight: '16px' }}>
                    Product
                </Button>
            </Link>
            <Link to="/pricing">
                <Button variant="transparent" sx={{ color: 'black', marginRight: '16px' }}>
                    Pricing
                </Button>
            </Link>
            <Link to="/docs">
                <Button variant="transparent" sx={{ color: 'black', marginRight: '16px' }}>
                    Docs
                </Button>
            </Link>
            <Link to="/about">
                <Button variant="transparent" sx={{ color: 'black', marginRight: '16px' }}>
                    About
                </Button>
            </Link> 
            <div
                style={{
                    verticalAlign: 'top',
                    display: isMobile ? 'none' : 'inline-block',
                    margin: '8px 16px 0 0',
                    borderLeft: '1px solid #ddd',
                    height: '16px'
                }}
            >
                &nbsp;
            </div>*/}

            {props.auth.isAuthenticated ? (
                <Link to="/studies">
                    <Button variant="primary">Go to app</Button>
                </Link>
            ) : (
                <>
                    <Link to="/login">
                        <Button variant="transparent" sx={{ color: 'black', marginRight: '16px' }}>
                            Log in
                        </Button>
                    </Link>
                    <Link to="/signup">
                        <Button variant="transparent" sx={{ color: 'black' }}>
                            Sign up
                        </Button>
                    </Link>
                </>
            )}
            {/* <Link to="login"><Button variant='transparent' sx={{color:'black'}}>Sign up</Button></Link> */}
        </Box>
    );

    return (
        <Box
            sx={{
                width: '100%',
                mx: 'auto',
                lineHeight: '25px',
                position: props.notSticky ? 'relative' : 'sticky',
                top: 0,
                background: '#fffcff',
                borderBottom: isSticky || showMobileNav ? '1px solid #eee' : '1px solid transparent',
                padding: isSticky || isMobile ? '12px 0' : '24px 0',
                zIndex: 2,
                transition: '0.15s padding ease'
            }}
            ref={ref}
        >
            <MarketingPageWrapper isMobile={props.isMobile}>
                <MarketingPageSection>
                    <Box
                        sx={{
                            display: 'flex',
                            justifyContent: 'space-between'
                        }}
                    >
                        <Box style={{ verticalAlign: 'top', marginTop: '0' }}>
                            <a href="/">
                                {/* <img src="/b-48.png" style={{width: '24px', height: '24px', margin: '4px 8px 0 0', verticalAlign: 'top'}} />
                                <span style={{color: 'black', display: 'inline-block', verticalAlign: 'top', margin: '4px 24px 0 0', fontSize: '14px', fontWeight: '500'}}>Panelfox</span> */}

                                <img
                                    src="/fox2.png"
                                    style={{
                                        width: '32px',
                                        height: '32px',
                                        margin: '0px 8px 0 0',
                                        verticalAlign: 'top'
                                    }}
                                    alt="Logo"
                                />
                                <span
                                    style={{
                                        color: 'black',
                                        display: 'inline-block',
                                        verticalAlign: 'top',
                                        margin: '3px 24px 0 0',
                                        fontSize: '18px',
                                        fontWeight: '500'
                                    }}
                                >
                                    Panelfox
                                </span>
                            </a>
                        </Box>
                        {!props.hideNav && isMobile ? (
                            <Button
                                variant="tertiary-icon"
                                mr={0}
                                onClick={() => {
                                    setShowMobileNav(!showMobileNav);
                                }}
                            >
                                <FiMenu />
                            </Button>
                        ) : (
                            ''
                        )}
                        {!props.hideNav && !isMobile ? navLinks : ''}
                    </Box>
                    {!props.hideNav && isMobile && showMobileNav ? navLinks : ''}
                </MarketingPageSection>
            </MarketingPageWrapper>
        </Box>
    );
};
