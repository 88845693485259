import React from 'react';
import ReactModal from 'react-modal';
import { Box, Button } from 'rebass';
import H1 from 'components/H1';
import ReactQuill from 'react-quill';
import { QUILL_MODULES } from 'utils/constants';
import ReactModalActions from '../ReactModalActions';

const quillFormats = [
    'header',
    'bold',
    'italic',
    'underline',
    'strike',
    'blockquote',
    'list',
    'bullet',
    'indent',
    'link',
    'image'
];

const RichTextEditorModal = React.forwardRef(
    (
        {
            isOpen,
            onClose,
            modalTitle,
            editorValue,
            onChangeEditor,
            actionCancel,
            actionConfirm,
            actionCancelLabel,
            actionConfirmLabel
        },
        quillRef
    ) => {
        return (
            // quillRef
            <ReactModal
                isOpen={isOpen}
                shouldCloseOnOverlayClick
                onRequestClose={onClose}
                style={{ content: { width: '800px', overflow: 'inherit' } }}
            >
                <Box fontSize={1}>
                    <Box m={4}>
                        <H1>{modalTitle}</H1>
                        <ReactQuill
                            ref={quillRef}
                            theme="snow"
                            className="quill-render"
                            modules={QUILL_MODULES}
                            value={editorValue}
                            onChange={html => {
                                console.log('htmlqqq', html);
                                onChangeEditor(html);
                            }}
                        />
                    </Box>
                    <ReactModalActions>
                        <div style={{ textAlign: 'right', width: '100%' }}>
                            <Button type="button" variant="secondary-gray" mr={3} onClick={actionCancel}>
                                {actionCancelLabel}
                            </Button>

                            <Button type="submit" mr={0} onClick={actionConfirm}>
                                {actionConfirmLabel}
                            </Button>
                        </div>
                    </ReactModalActions>
                </Box>
            </ReactModal>
        );
    }
);

export default RichTextEditorModal;
