import React from 'react';
import { Box, Button } from 'rebass';
import { Input } from '@rebass/forms';
import { useToasts } from 'react-toast-notifications';

import helpers from 'utils/helpers';
import NiceModal from 'components/NiceModal';
import { Portal } from 'components/Portal';
import { useForm } from 'hooks/useForm';
import { useKeyValidator } from 'hooks/useKeyValidator';
import useOnPasteBlocker from 'hooks/useOnPasteBlocker';

const lock = 'permanently delete';

/**
 * Delete panel property modal
 *
 * @param {Object} props
 * @param {Function} props.onDelete Delete action
 * @param {Function} props.onClose Close action
 * @param {boolean} props.isOpen Modal open state
 * @param {string} props.panelPropertyName Panel property name to delete
 */
export function DeletePanelPropertyModal({ onDelete, onClose, isOpen, panelPropertyName }) {
    const { addToast } = useToasts();
    const { register, handleSubmit, reset, watch } = useForm();
    const watchedKey = watch('key');
    const isKeyValid = useKeyValidator(watchedKey, lock);
    const blockOnPaste = useOnPasteBlocker();

    const onSubmit = handleSubmit(async ({ key }) => {
        if (!helpers.validateKey(key, lock))
            return addToast('You did not type the correct confirmation - delete action was NOT taken.', {
                appearance: 'info',
                autoDismiss: true
            });

        // perform delete action
        await onDelete();

        // close modal and reset form
        onCancel();
    });

    /**
     * Close modal and reset form
     */
    const onCancel = () => {
        reset();
        onClose();
    };

    return (
        <Portal>
            <NiceModal
                isOpen={isOpen}
                shouldCloseOnOverlayClick
                onRequestClose={onCancel}
                title="Delete Panel Property"
            >
                <Box as="p" mb="24px" className="color-text-secondary">
                    Are you sure you want to delete the "{panelPropertyName}" property and all of the associated
                    panelist data?
                </Box>
                <form id="delete-panelists-form" onSubmit={onSubmit}>
                    <Box as="p" mb="10px">
                        To continue, type "{lock}":
                    </Box>
                    <Input
                        {...register('key')}
                        onPaste={blockOnPaste}
                        autoFocus
                        autoComplete="off"
                        placeholder={lock}
                    />
                </form>
                <Box className="modal-actions">
                    <Button type="button" mr={2} onClick={onCancel} variant="secondary-gray">
                        Cancel
                    </Button>
                    <Button
                        type="submit"
                        variant="danger-filled"
                        disabled={!isKeyValid}
                        mr={0}
                        className="modal-primary"
                        form="delete-panelists-form"
                    >
                        Delete property
                    </Button>
                </Box>
            </NiceModal>
        </Portal>
    );
}

export default DeletePanelPropertyModal;
