import styled from 'styled-components';
import React from 'react';
import { FiChevronRight } from 'react-icons/fi';

const StyledBreadcrumbDivider = styled.span`
    margin: 3px 4px 0;
    display: inline-block;
    vertical-align: top;
`;

export default () => (
    <StyledBreadcrumbDivider className="text-primary">
        <FiChevronRight style={{ verticalAlign: 'top' }} />
    </StyledBreadcrumbDivider>
);
