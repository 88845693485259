import React, { useState, useEffect, useMemo } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useToasts } from 'react-toast-notifications';
import H1 from 'components/H1';
import H2 from 'components/H2';
import { Flex, Box, Button } from 'rebass';
import { Select, Input } from '@rebass/forms';
import peopleServices from 'services/people';
import services from 'services/services';
import helpers from 'utils/helpers';
import { CURRENCY_SYMBOL } from 'utils/constants';
import { FiExternalLink, FiBookOpen } from 'react-icons/fi';

import AppPageWrapper from 'components/AppPageWrapper';
import AppPageWrapperSectionHeader from 'components/AppPageWrapper/AppPageWrapperSectionHeader';
import AppPageWrapperSectionBody from 'components/AppPageWrapper/AppPageWrapperSectionBody';
import ParticipantsPageSubNav from 'containers/ParticipantsPage/ParticipantsPageSubNav';
import AppPageWrapperSectionSubHeader from 'components/AppPageWrapper/AppPageWrapperSectionSubHeader';
import AppPageSubnav from 'components/AppPageWrapper/AppPageSubnav';
import ListWrapper from 'components/ListWrapper';
import ItemWrapper from 'components/ItemWrapper';
import LoadingIndicator from 'components/LoadingIndicator';
import LoadingWrapper from 'components/LoadingIndicator/LoadingWrapper';
import NiceSelect from 'components/NiceSelect';
import CSVClipboard from 'components/CSVClipboard';

function ParticipantsIncentivesPage(props) {
    let currentYear = new Date().getFullYear();

    const [studyPeoplePaid, setStudyPeoplePaid] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [selectedYear, setSelectedYear] = useState(currentYear);
    const [minimumAmount, setMinimumAmount] = useState(600);
    const { addToast } = useToasts();
    const clipboardData = useMemo(() => {
        return [
            ['Panelist Name', 'Email', 'Annual Studies', 'Annual Total Paid'],
            ...studyPeoplePaid.map(p_i_data => [
                helpers.personGetFullName(p_i_data.person),
                helpers.personGetEmail(p_i_data.person),
                p_i_data.num_payments,
                helpers.renderCurrencyAmount(props.auth.account.incentive_currency, p_i_data.annual_paid)
            ])
        ];
    }, [studyPeoplePaid]);

    let years = [];
    let years_valueToLabel = {};
    for (let i = 0; i <= 1; i++) {
        const nextYear = currentYear - i;
        years.push({
            title: nextYear,
            id: nextYear
        });
        years_valueToLabel[nextYear] = nextYear;
    }

    //console.log(years, years_valueToLabel)

    useEffect(() => {
        getPanelIncentivesXHR(currentYear, minimumAmount);
    }, []);

    function renderData() {
        const render = [];
        if (isLoading) {
            render.push(
                <Box m={3} style={{ minHeight: '24px' }}>
                    <LoadingWrapper>
                        <LoadingIndicator />
                    </LoadingWrapper>
                </Box>
            );
        } else {
            studyPeoplePaid.forEach(p_i_data => {
                render.push(
                    <ItemWrapper style={{ color: 'black' }} key={p_i_data.person.id}>
                        <Box width={2 / 7}>
                            {helpers.personGetFullName(p_i_data.person)}
                            <a href={`/participants/${p_i_data.person.id}`} target="_blank">
                                <Button
                                    variant="link"
                                    type="button"
                                    className="link-small bold va-top"
                                    ml={2}
                                    sx={{ marginTop: '2px' }}
                                >
                                    <FiExternalLink />
                                </Button>
                            </a>
                        </Box>
                        <Box width={2 / 7}>{helpers.personGetEmail(p_i_data.person)}</Box>
                        <Box width={2 / 7}>{p_i_data.num_payments}</Box>
                        <Box width={1 / 7}>
                            {helpers.renderCurrencyAmount(props.auth.account.incentive_currency, p_i_data.annual_paid)}
                        </Box>
                    </ItemWrapper>
                );
            });

            render.push(
                <ItemWrapper style={{ border: 0 }}>
                    {studyPeoplePaid.length} result{studyPeoplePaid.length == 1 ? '' : 's'}
                </ItemWrapper>
            );
        }
        return render;
    }

    function getPanelIncentivesXHR(currentYear, minimumAmount) {
        setIsLoading(true);

        peopleServices
            .getPanelIncentives2(currentYear, minimumAmount)
            .then(spp => {
                setStudyPeoplePaid(spp);
                setIsLoading(false);
            })
            .catch(error => {
                setIsLoading(false);
                setStudyPeoplePaid([]);

                const errorText = services.parseAndTrackXhrErrors(error);
                addToast(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }
    return (
        <Flex style={{ width: '100%' }}>
            <AppPageWrapper>
                <Helmet>
                    <title>Panel - Incentives Paid</title>
                </Helmet>

                <Flex>
                    <ParticipantsPageSubNav />
                </Flex>

                <AppPageWrapperSectionHeader className="experience">
                    <Box>
                        <H1>Incentive Reports</H1>
                        <Box className="experience-description">
                            <Box mb={2}>
                                Find the sum of incentives paid to each respondent per year.
                                <br />
                                <a href="#" data-beacon-article="631606544cde766bbe142065">
                                    <FiBookOpen /> How to get started
                                </a>
                            </Box>
                        </Box>
                    </Box>
                </AppPageWrapperSectionHeader>
                <AppPageWrapperSectionBody>
                    <Box mx={4} my={3} width="100%">
                        <form
                            onSubmit={e => {
                                e.preventDefault();
                                getPanelIncentivesXHR(selectedYear, minimumAmount);
                            }}
                        >
                            <Flex mb={3} sx={{ gridGap: 3 }}>
                                <Box sx={{ position: 'relative' }}>
                                    <Box className="form-label">Year</Box>
                                    <NiceSelect
                                        //defaultLabel='Year'
                                        defaultValue={selectedYear}
                                        //positionRight="0px"
                                        items={years}
                                        mapValueToLabel={years_valueToLabel}
                                        onChange={newYear => {
                                            setSelectedYear(newYear);
                                        }}
                                    />
                                </Box>
                                <Box>
                                    <Box className="form-label">
                                        Minimum Amount ({CURRENCY_SYMBOL[props.auth.account.incentive_currency]})
                                    </Box>
                                    <Input
                                        type="text"
                                        value={minimumAmount}
                                        onChange={e => {
                                            setMinimumAmount(e.target.value);
                                        }}
                                    />
                                </Box>
                                <Box>
                                    <Box className="form-label">&nbsp;</Box>
                                    <Button type="submit">Filter</Button>
                                </Box>
                                <Box sx={{ flex: 1 }} />
                                <CSVClipboard css={{ alignSelf: 'flex-end' }} data={clipboardData} id="copy-to-csv" />
                            </Flex>
                        </form>
                        <ListWrapper
                            style={{
                                position: 'relative',
                                border: '1px solid #eee',
                                borderRadius: '4px'
                            }}
                        >
                            <ItemWrapper className="fs-12">
                                <Box width={2 / 7}>Panelist Name</Box>
                                <Box width={2 / 7}>Email</Box>
                                <Box width={2 / 7}>Annual Studies</Box>
                                <Box width={1 / 7}>Annual Total Paid</Box>
                            </ItemWrapper>
                            {renderData()}
                        </ListWrapper>
                        <br />
                        <br />
                    </Box>
                </AppPageWrapperSectionBody>
            </AppPageWrapper>
        </Flex>
    );
}

export default ParticipantsIncentivesPage;
