import React, { useState } from 'react';
import { FiMoreHorizontal, FiEdit3, FiTrash2 } from 'react-icons/fi';
import { Button } from 'rebass';

import { Portal } from 'components/Portal';
import NiceDropdown from 'components/NiceDropdown';
import Positioner from 'components/Positioner';

/**
 * Button to show a dropdown with options to rename or remove a property group.
 *
 * @param {Object} props
 * @param {Function} props.onRenameClick Function to be called when the rename option is clicked
 * @param {Function} props.onRemoveClick Function to be called when the remove option is clicked
 */
export function PanelPropertyGroupMenuButton({ onRenameClick, onRemoveClick }) {
    const controllerRef = React.useRef(null);
    const [isOpen, setIsOpen] = useState(false);

    return (
        <>
            <Button variant="transparent-icon" ref={controllerRef} onClick={() => setIsOpen(true)}>
                <FiMoreHorizontal className="color-primary" />
            </Button>
            <Portal>
                <Positioner controllerRef={controllerRef} isVisible={isOpen}>
                    <NiceDropdown
                        onClose={() => setIsOpen(false)}
                        style={{ inset: 'auto', position: 'static' }}
                        items={[
                            {
                                id: 'rename',
                                title: 'Rename group',
                                icon: <FiEdit3 />,
                                onClick: onRenameClick
                            },
                            {
                                id: 'remove',
                                title: 'Remove group',
                                icon: <FiTrash2 />,
                                onClick: onRemoveClick
                            }
                        ]}
                        width="190px"
                    />
                </Positioner>
            </Portal>
        </>
    );
}

export default PanelPropertyGroupMenuButton;
