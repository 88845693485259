import React from 'react';
import { FiArrowRight } from 'react-icons/fi';
import { Flex, Image } from 'rebass';

import { INTEGRATION_DIRECTION_IN, INTEGRATION_DIRECTION_OUT } from 'utils/constants';

/**
 * Wrapper
 *
 * @param {Object} props
 * @param {React.ReactNode} props.children
 */
const Wrapper = ({ children }) => (
    <Flex minWidth="max-content" as="p" alignItems="center" className="fs-accent-14" sx={{ gap: 2, height: '100%' }}>
        {}
        {children}
    </Flex>
);

/**
 * SalesforceDirection
 *
 * @param {Object} props
 * @param {string} props.direction - The direction of the integration
 */
export function SalesforceDirection({ direction }) {
    // If the integration is incoming
    if (direction === INTEGRATION_DIRECTION_IN)
        return (
            <Wrapper>
                <Image src="/salesforce.svg" alt="Salesforce" width="16px" />
                Salesforce
                <FiArrowRight className="text-secondary" style={{ margin: 0 }} />
                <Image src="/fox2-purple.svg" alt="Panelfox logo" width="16px" />
                Panelfox
            </Wrapper>
        );

    // If the integration is outgoing
    if (direction === INTEGRATION_DIRECTION_OUT)
        return (
            <Wrapper>
                <Image src="/fox2-purple.svg" alt="Panelfox logo" width="16px" />
                Panelfox
                <FiArrowRight className="text-secondary" style={{ margin: 0 }} />
                <Image src="/salesforce.svg" alt="Salesforce" width="16px" />
                Salesforce
            </Wrapper>
        );

    // If the integration is two-way
    return (
        <Wrapper>
            <Image src="/reverse.svg" alt="Panelfox logo" width="16px" />
            Two Way
        </Wrapper>
    );
}

export default SalesforceDirection;
