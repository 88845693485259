import React from 'react';

const DragIcon = props => (
    <svg {...props} width="18" height="18" viewBox="0 0 18 18" xmlns="http://www.w3.org/2000/svg">
        <path d="M10 4C10 4.82843 10.6716 5.5 11.5 5.5C12.3284 5.5 13 4.82843 13 4C13 3.17157 12.3284 2.5 11.5 2.5C10.6716 2.5 10 3.17157 10 4Z" />
        <path d="M10 9C10 9.82843 10.6716 10.5 11.5 10.5C12.3284 10.5 13 9.82843 13 9C13 8.17157 12.3284 7.5 11.5 7.5C10.6716 7.5 10 8.17157 10 9Z" />
        <path d="M10 14C10 14.8284 10.6716 15.5 11.5 15.5C12.3284 15.5 13 14.8284 13 14C13 13.1716 12.3284 12.5 11.5 12.5C10.6716 12.5 10 13.1716 10 14Z" />
        <path d="M5 4C5 4.82843 5.67157 5.5 6.5 5.5C7.32843 5.5 8 4.82843 8 4C8 3.17157 7.32843 2.5 6.5 2.5C5.67157 2.5 5 3.17157 5 4Z" />
        <path d="M5 9C5 9.82843 5.67157 10.5 6.5 10.5C7.32843 10.5 8 9.82843 8 9C8 8.17157 7.32843 7.5 6.5 7.5C5.67157 7.5 5 8.17157 5 9Z" />
        <path d="M5 14C5 14.8284 5.67157 15.5 6.5 15.5C7.32843 15.5 8 14.8284 8 14C8 13.1716 7.32843 12.5 6.5 12.5C5.67157 12.5 5 13.1716 5 14Z" />
    </svg>
);

export default DragIcon;
