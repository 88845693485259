import React from 'react';
import { FiXCircle } from 'react-icons/fi';
import { Button, Flex } from 'rebass';

export const CountryItem = ({ country, handleRemove }) => (
    <Flex
        sx={{
            justifyContent: 'space-between',
            position: 'relative'
        }}
        className="nice-element rounded fs-13 black"
        mb={3}
    >
        <Button
            variant="link"
            className="link-small red"
            sx={{
                position: 'absolute',
                top: '-11px',
                right: '-7px'
            }}
            onClick={() => handleRemove(country.value)}
        >
            <FiXCircle className="circle" style={{ fontSize: '16px', background: '#fff' }} />
        </Button>

        <div className="">{country.label}</div>
        {/*<Button
            type="button"
            variant="link"
            className="link-small red"
            sx={{ position: 'absolute', top: '10px', right: '0px' }}
            onClick={() => handleRemove(country.value)}
        >
            <FiXCircle className="circle" style={{ fontSize: '16px', background: '#fff' }} />
        </Button>*/}
    </Flex>
);
