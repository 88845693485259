import React from 'react';
import { Link } from 'react-router-dom';
import { FiArrowLeft } from 'react-icons/fi';

import H1 from 'components/H1';

export const BreadcrumbBack = ({ children, ...props }) => {
    return (
        <Link {...props} className="breadcrumb-back">
            <FiArrowLeft />
            {children}
        </Link>
    );
};

/**
 * BreadcrumbTitle
 *
 * @param {Object} param0
 * @param {Boolean} param0.isLoading Loading state
 * @param {String?} param0.title Title to display
 */
export const BreadcrumbTitle = ({ isLoading, title }) => (
    <H1 style={{ fontSize: '20px' }}>{title ? title : isLoading ? <>&nbsp;</> : 'Not Found'}</H1>
);

export default BreadcrumbBack;
