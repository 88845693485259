import axios from 'axios/dist/browser/axios.cjs';
import config from 'config';
import EventEmitter from 'utils/events';
import { EVENT_API_DOWN, EVENT_API_LOGGED_OUT } from 'utils/constants';
import helpers from 'utils/helpers';

const baseURL = config.API_URL;
const axiosInstance = axios.create({
    baseURL
});

const responseHandler = response => {
    // do nothing
    // console.log('axios intercept responseHandler', response.status)
    return response;
};

const errorHandler = error => {
    //console.log('axios intercept errorHandler', error.response.status)
    let errorDetails = '';

    // check if API is down
    if (error && error.response && error.response.status && [502, 503, 504].includes(error.response.status)) {
        errorDetails = { details: 'sending event API is temporarily down' };
        EventEmitter.dispatch(EVENT_API_DOWN, {});
    }

    // check if user is logged out
    if (
        error &&
        error.response &&
        error.response.status &&
        [401].includes(error.response.status) &&
        error.response.data.message &&
        error.response.data.message.search('logged out') != -1
    ) {
        errorDetails = { details: 'user is logged out?' };
        EventEmitter.dispatch(EVENT_API_LOGGED_OUT, {});
    }

    // do not notify for 4xx errors
    if (![400, 401, 403, 404].includes(error.response.status)) {
        helpers.trackError(error, errorDetails);
    }

    return Promise.reject(error);
};

axiosInstance.interceptors.response.use(
    response => responseHandler(response),
    error => errorHandler(error)
);

export default axiosInstance;
