import React, { useCallback, useState } from 'react';
import { FiEyeOff } from 'react-icons/fi';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import helpers from 'utils/helpers';
import ParticipantMaskedValue from './ParticipantMaskedValue';

/**
 * This component displays the participant data value in a user-friendly format, if column is not pii_masked,
 * or shows ParticipantMaskedValue component
 *
 * @param {Object} props props
 * @param {Object} props.col column
 * @param {Number} props.colIndex index of the column
 * @param {Number} props.participantId ID of the participant
 * @param {'admin' | 'user' | 'view'} props.role auth account_role type
 * @param {Object} props.user auth user object
 * @param {string} props.value default unformatted value
 * @param {string} props.className class name
 * @returns {React.ReactNode} ParticipantFormattedValue component
 */
export function ParticipantFormattedValue({ col, colIndex, participantId, role, value, user, className }) {
    const [foundValue, setFoundValue] = useState(value);
    const [unmasked, setUnmasked] = useState(false);

    const timezone = user.timezone;

    const setValue = useCallback(value => {
        setUnmasked(true);
        setFoundValue(value);
    }, []);

    const mask = useCallback(() => {
        setUnmasked(false);
    }, []);

    const displayValue = helpers.getDisplayValue(col, foundValue, timezone);

    return (
        <>
            {!col.pii_masked || unmasked ? (
                <>
                    <Link className={classNames('muted', className)} to={`/participants/${participantId}`}>
                        {colIndex == 0 ? <>{displayValue || <em>missing name</em>}</> : displayValue}
                    </Link>
                    {!!col.pii_masked && (
                        <FiEyeOff
                            onClick={mask}
                            style={{
                                color: '#929db0',
                                marginLeft: '6px',
                                cursor: 'pointer'
                            }}
                        />
                    )}
                </>
            ) : (
                <ParticipantMaskedValue
                    role={role}
                    participantId={participantId}
                    setValue={setValue}
                    css={{ display: 'inline-block', maxWidth: '80px' }}
                    keyId={col.id}
                />
            )}
        </>
    );
}

export default ParticipantFormattedValue;
