import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import qs from 'qs';
import { FiChevronDown } from 'react-icons/fi';

import services from 'services/services';
import scheduling_service from 'services/scheduling';

import { Box, Flex, Button } from 'rebass';

import LoadingIndicator from 'components/LoadingIndicator';
import LoadingWrapper from 'components/LoadingIndicator/LoadingWrapper';
import SchedulingPageError from 'containers/PublicSchedulingPage/components/SchedulingPageError';
import SchedulingPageWrapper from 'containers/PublicSchedulingPage/components/SchedulingPageWrapper';
import SchedulingPageAvailability from 'containers/PublicSchedulingPage/components/SchedulingPageAvailability';
import SchedulingPageNiceDateTime from 'containers/PublicSchedulingPage/components/SchedulingPageNiceDateTime';
import SchedulingPageAdminNote from 'containers/PublicSchedulingPage/components/SchedulingPageAdminNote';
import NiceDropdown from 'components/NiceDropdown';
import Positioner from 'components/Positioner';
import Portal from 'components/Portal';

const PublicSchedulingPage = props => {
    const history = useHistory();
    const [isLoading, setIsLoading] = useState(true);
    const [isPreview, setIsPreview] = useState(null);
    const [isReschedule, setIsReschedule] = useState(null);
    const [timezones, setTimezones] = useState();
    const [rescheduleCurrentSpot, setRescheduleCurrentSpot] = useState();
    const [data, setData] = useState();
    const [timezone, setTimezone] = useState();
    const [selectedSpotHash, setSelectedSpotHash] = useState('');
    const [selectedSpotUTC, setSelectedSpotUTC] = useState('');
    const [selectedSpotDuration, setSelectedSpotDuration] = useState('');
    const [submittingSchedule, setSubmittingSchedule] = useState(false);
    const [adminNotifications, setAdminNotifications] = useState([]);
    const [errorText, setErrorText] = useState(null);
    const [isTimezoneOpen, setIsTimezoneOpen] = useState(false);
    const timezoneControllerRef = useRef(null);

    const redirectToResultPage = (
        is_preview = false,
        spot = '',
        clientTimezone = '',
        duration = '',
        logo = '',
        location = '',
        allow_session_changes = 0
    ) => {
        const queryParams = qs.parse(props.location.search, { ignoreQueryPrefix: true });
        const base_url = `/scheduling/session?token=${queryParams.token}&study_uuid=${queryParams.study_uuid}`;

        if (is_preview) {
            const url = `${base_url}&spot=${spot}&timezone=${clientTimezone}&duration=${duration}&logo=${logo}&location=${location}&allow_session_changes=${allow_session_changes}&is_preview=1`;
            history.push(url);
        } else {
            const sp_uuid = queryParams.sp_uuid;
            const url = `${base_url}&sp_uuid=${sp_uuid}&is_preview=0`;
            history.push(url);
        }
    };

    const getSession = () => {
        const queryParams = qs.parse(props.location.search, { ignoreQueryPrefix: true });

        if (isPreview) {
            if (isReschedule) {
                setRescheduleCurrentSpot({
                    spot: queryParams.spot,
                    timezone: queryParams.timezone,
                    duration: queryParams.duration
                });
            }
            getTimezones();
            getAvailability(queryParams.study_uuid);
        } else {
            const study_uuid = queryParams.study_uuid;
            const sp_uuid = queryParams.sp_uuid;
            scheduling_service
                .getScheduledStudyPerson(study_uuid, sp_uuid)
                .then(res_data => {
                    if (isReschedule) {
                        // if session is not scheduled, redirect to the schedule page
                        if (res_data.spot === null) {
                            const url = `/scheduling/schedule${props.location.search}`;
                            history.push(url);
                        }
                        setRescheduleCurrentSpot({
                            spot: res_data.spot,
                            timezone: res_data.timezone,
                            duration: res_data.study.duration
                        });
                        getTimezones();
                        getAvailability(study_uuid);
                    } else if (res_data.spot === null) {
                        getTimezones();
                        getAvailability(study_uuid);
                    } else {
                        redirectToResultPage();
                    }
                })
                .catch(error => {
                    const errorText = services.parseAndTrackXhrErrors(error);
                    setErrorText(errorText);
                });
        }
    };

    const getTimezones = () => {
        scheduling_service
            .getAllTimezones()
            .then(timezones => {
                setTimezones(timezones);
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);
                setErrorText(errorText);
            });
    };

    const getAvailability = study_uuid => {
        scheduling_service
            .getAvailabilityPublic(study_uuid)
            .then(res_data => {
                setData(res_data);

                // display some notifications for admins
                if (res_data.is_authed && res_data && res_data.freebusy && res_data.freebusy.length > 0) {
                    const freebusy_notification = (
                        <SchedulingPageAdminNote>
                            Calendar integration for free/busy is <span className="fs-title-14 color-success">ON</span>.
                            Session availability is adjusted if your calendars have existing meetings.
                        </SchedulingPageAdminNote>
                    );
                    setAdminNotifications(prev => [...prev, { id: prev.length, text: freebusy_notification }]);
                }
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);
                setErrorText(errorText);
            });
    };

    const confirmTime = () => {
        setSubmittingSchedule(true);

        const queryParams = qs.parse(props.location.search, { ignoreQueryPrefix: true });
        const token = queryParams.token;
        const study_uuid = queryParams.study_uuid;
        const sp_uuid = isPreview ? '' : queryParams.sp_uuid;

        scheduling_service
            .confirmSchedule(token, study_uuid, sp_uuid, selectedSpotUTC, timezone, isPreview)
            .then(res_data => {
                setSubmittingSchedule(false);
                if (res_data.is_preview) {
                    redirectToResultPage(
                        true,
                        res_data.spot,
                        res_data.timezone,
                        res_data.duration,
                        data.account.logo || '',
                        res_data.location || '',
                        data.account.allow_session_changes
                    );
                } else {
                    redirectToResultPage();
                }
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);
                setErrorText(errorText);
            });
    };

    const selectSpot = (spotHash, spotBeginUTC, spotDuration) => {
        setSelectedSpotHash(spotHash);
        setSelectedSpotUTC(spotBeginUTC);
        setSelectedSpotDuration(spotDuration);
    };

    const rescheduleGoBack = () => {
        if (isPreview) {
            const queryParams = qs.parse(props.location.search, { ignoreQueryPrefix: true });
            redirectToResultPage(
                true,
                queryParams.spot,
                queryParams.timezone,
                queryParams.duration,
                queryParams.logo,
                queryParams.location
            );
        } else {
            redirectToResultPage();
        }
    };

    useEffect(() => {
        const queryParams = qs.parse(props.location.search, { ignoreQueryPrefix: true });

        if (parseInt(queryParams.is_preview)) {
            setIsPreview(true);
        } else {
            setIsPreview(false);
        }

        if (props.match.path === '/scheduling/reschedule') {
            setIsReschedule(true);
        } else {
            setIsReschedule(false);
        }
    }, []);

    useEffect(() => {
        if (isPreview !== null && isReschedule !== null) {
            getSession();
        }
    }, [isPreview, isReschedule]);

    useEffect(() => {
        if (!timezones || Object.keys(timezones).length === 0) return;

        const detectedTimezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        if (!!timezones[detectedTimezone]) {
            setTimezone(detectedTimezone);
        } else {
            setTimezone('America/Los_Angeles');
        }
    }, [timezones]);

    useEffect(() => {
        if (
            isLoading &&
            (errorText !== null || (data && timezones && timezone && (!isReschedule || rescheduleCurrentSpot)))
        ) {
            setIsLoading(false);
        }
    }, [errorText, data, timezones, timezone, rescheduleCurrentSpot]);

    if (isLoading) {
        return (
            <div style={{ width: '100%', height: '200px' }}>
                <LoadingWrapper>
                    <LoadingIndicator />
                </LoadingWrapper>
            </div>
        );
    }

    if (errorText !== null) {
        return <SchedulingPageError> {errorText} </SchedulingPageError>;
    }

    return (
        <SchedulingPageWrapper
            isPreview={isPreview}
            status={isReschedule ? 'session-reschedule' : 'session-new'}
            logo={data.account.logo}
            account={data.account}
        >
            {adminNotifications.map(notification => (
                <div key={notification.id}>{notification.text}</div>
            ))}
            {isReschedule && (
                <>
                    <Box>
                        <div className="fs-accent-12 color-warning" style={{ marginBottom: '12px' }}>
                            Current scheduled session
                        </div>
                        <SchedulingPageNiceDateTime
                            spot={rescheduleCurrentSpot.spot}
                            timezone={rescheduleCurrentSpot.timezone}
                            duration={rescheduleCurrentSpot.duration}
                        />
                    </Box>
                    <div className="divider color-bg-line-strong" />
                </>
            )}
            <div className="fs-title-16 color-text-primary">Choose a session</div>
            <Box>
                <div className="fs-accent-14 color-text-primary" style={{ marginBottom: '4px' }}>
                    Select time zone
                </div>
                <Button
                    width="100%"
                    display="flex"
                    className="flex items-center justify-between"
                    ref={timezoneControllerRef}
                    variant="secondary-gray"
                    onClick={() => setIsTimezoneOpen(true)}
                >
                    {timezones[timezone]} <FiChevronDown style={{ margin: 0 }} />
                </Button>
                <Portal>
                    <Positioner isVisible={isTimezoneOpen} controllerRef={timezoneControllerRef}>
                        <NiceDropdown
                            height="240px"
                            width={timezoneControllerRef.current ? timezoneControllerRef.current.clientWidth : 200}
                            onClose={() => setIsTimezoneOpen(false)}
                            style={{ inset: 'auto', position: 'static' }}
                            showSearch
                            items={Object.entries(timezones).map(([tz, tz_name]) => ({ id: tz, title: tz_name }))}
                            value={timezone}
                            onChange={value => setTimezone(value)}
                        />
                    </Positioner>
                </Portal>
            </Box>
            <Flex style={{ flexDirection: 'column', gap: '16px' }}>
                <SchedulingPageAvailability
                    data={data}
                    timezone={timezone}
                    selectedSpotHash={selectedSpotHash}
                    onSelectSpot={selectSpot}
                />
            </Flex>
            <div className="divider color-bg-line-strong" />
            {selectedSpotUTC && selectedSpotDuration && (
                <Box>
                    <div className="fs-accent-12 color-primary" style={{ marginBottom: '12px' }}>
                        New session selected
                    </div>
                    <SchedulingPageNiceDateTime
                        spot={selectedSpotUTC}
                        timezone={timezone}
                        duration={selectedSpotDuration}
                    />
                </Box>
            )}
            <Flex style={{ flexDirection: 'column', gap: '16px' }}>
                <Button
                    variant="primary"
                    height="48px"
                    margin="0"
                    disabled={selectedSpotUTC === '' || submittingSchedule}
                    onClick={confirmTime}
                >
                    {submittingSchedule ? 'Confirming session' : 'Confirm session'}
                </Button>
                {isReschedule && (
                    <Button variant="secondary-gray" height="48px" onClick={rescheduleGoBack}>
                        Go back
                    </Button>
                )}
            </Flex>
        </SchedulingPageWrapper>
    );
};

export default PublicSchedulingPage;
