import React, { useState, useEffect } from 'react';
import ReactModal from 'react-modal';
import { Helmet } from 'react-helmet';
import { useToasts } from 'react-toast-notifications';
import H1 from 'components/H1';
import H2 from 'components/H2';
import { Flex, Box, Button } from 'rebass';
import { Label, Input, Select } from '@rebass/forms';
import services from 'services/services';
import people_service from 'services/people';
import { FiMove, FiEdit, FiBookOpen, FiColumns, FiTrash2 } from 'react-icons/fi';
import helpers from 'utils/helpers';
import { PERSON_KEY_TYPES, TAG_TYPE_PERSON, TAG_TYPE_STUDY, TAG_TYPES } from 'utils/constants';
import moment from 'moment-timezone';
import NiceModal from 'components/NiceModal';

import AppPageWrapper from 'components/AppPageWrapper';
import AppPageSubnav from 'components/AppPageWrapper/AppPageSubnav';
import AppPageBreadcrumbs from 'components/AppPageWrapper/AppPageBreadcrumbs';
import AppPageWrapperSectionHeader from 'components/AppPageWrapper/AppPageWrapperSectionHeader';
import AppPageWrapperSectionSubHeader from 'components/AppPageWrapper/AppPageWrapperSectionSubHeader';
import AppPageWrapperSectionBody from 'components/AppPageWrapper/AppPageWrapperSectionBody';
import SettingsPageSubNav from 'containers/SettingsPage/SettingsPageSubNav';
import ListWrapper from 'components/ListWrapper';
import ItemWrapper from 'components/ItemWrapper';
import InputSearch from 'components/InputSearch';
import { CreatePersonDataKey } from 'components/PersonDataKey';
import { List, arrayMove } from 'react-movable';
import { ActionButton } from 'components/Button/ActionButton';

function TagsPage() {
    const [allTags, setAllTags] = useState([]);
    const { addToast } = useToasts();
    const [selectedTag, setSelectedTag] = useState(false);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [showModalAddTag, setShowModalAddTag] = useState(false);
    const [onSubmitLoading, setOnSubmitLoading] = useState(false);

    useEffect(() => {
        getTagsXHR();
    }, []);

    function renderTags() {
        let pkt_map = {};
        PERSON_KEY_TYPES.forEach(pkt => {
            pkt_map[pkt.type] = pkt.title;
        });

        let itemsList = [];
        allTags.forEach(ac => {
            if (!searchKeyword || helpers.findInString(searchKeyword, ac.title)) {
                itemsList.push(ac);
            }
        });

        return itemsList.map(ac => (
            <ItemWrapper style={{ color: 'black' }} key={ac.id}>
                <Box width={1 / 4}>{ac.title}</Box>
                <Box width={2 / 8}>{ac.type}</Box>
                <Box width={1 / 4}>
                    <Button
                        type="button"
                        mr={2}
                        variant="secondary-gray"
                        onClick={e => {
                            setSelectedTag(ac);
                            setShowModalAddTag(true);
                        }}
                    >
                        <FiEdit /> Edit
                    </Button>
                    <Button
                        type="button"
                        variant="secondary-gray"
                        className="secondary-icon"
                        onClick={() => {
                            if (
                                // eslint-disable-next-line
                                confirm(`Are you sure you want to delete the "${ac.title}" tag and associated data?`)
                            ) {
                                deleteTagXHR(ac);
                            }
                        }}
                    >
                        <FiTrash2 />
                    </Button>
                </Box>
            </ItemWrapper>
        ));
    }

    function onCreateTag(e) {
        e.preventDefault();
        const tag_title = document.getElementsByName('create_tag_title')[0].value;
        const tag_type = document.getElementsByName('create_tag_type')[0].value;
        console.log(tag_title, tag_type);

        if (!tag_title || !tag_type) {
            alert('Tag title and type are required.');
            return;
        }

        setOnSubmitLoading(true);
        services
            .createTag(tag_type, tag_title)
            .then(() => {
                addToast('Successfully created', {
                    appearance: 'success',
                    autoDismiss: true
                });
                getTagsXHR();
                handleCloseModalCreateTag();
                setOnSubmitLoading(false);
            })
            .catch(error => {
                setOnSubmitLoading(false);
                const errorText = services.parseAndTrackXhrErrors(error);

                addToast(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    function onEditTag(e) {
        e.preventDefault();
        const tag_title = document.getElementsByName('create_tag_title')[0].value;
        const tag_type = document.getElementsByName('create_tag_type')[0].value;
        console.log(tag_title, tag_type);

        if (!tag_title || !tag_type) {
            alert('Tag title and type are required.');
            return;
        }

        setOnSubmitLoading(true);
        services
            .updateTag(selectedTag.id, tag_type, tag_title)
            .then(() => {
                addToast('Updated created', {
                    appearance: 'success',
                    autoDismiss: true
                });
                getTagsXHR();
                handleCloseModalCreateTag();
                setOnSubmitLoading(false);
            })
            .catch(error => {
                setOnSubmitLoading(false);
                const errorText = services.parseAndTrackXhrErrors(error);

                addToast(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    function deleteTagXHR(tag) {
        services
            .deleteTag(tag.id)
            .then(() => {
                addToast('Successfully deleted', {
                    appearance: 'success',
                    autoDismiss: true
                });
                getTagsXHR();
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);

                addToast(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    function getTagsXHR() {
        services
            .getTags()
            .then(tags => {
                // sort by the "order"
                tags.sort((a, b) => {
                    if (a.title.toLowerCase() < b.title.toLowerCase()) return -1;
                    if (a.title.toLowerCase() > b.title.toLowerCase()) return 1;
                    return 0;
                });
                setAllTags(tags);
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);

                addToast(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    function onChangeKey(key, val) {
        setSelectedTag(prev => ({ ...prev, [key]: val }));
    }

    function onSearchKeywordChange(e) {
        setSearchKeyword(e.target.value);
    }

    function handleCloseModalCreateTag() {
        setShowModalAddTag(false);
        setSelectedTag(false);
    }

    const num_matched = allTags.length;

    return (
        <Flex style={{ width: '100%' }}>
            <AppPageWrapper>
                <Helmet>
                    <title>Panel - Tags</title>
                </Helmet>

                <Flex>
                    <SettingsPageSubNav />
                </Flex>

                <AppPageWrapperSectionHeader className="experience">
                    <Box>
                        <H1>Tags</H1>
                        <Box className="experience-description">
                            Create tags for panelists and studies.
                            {/*<br/>
                            <a href="#" data-beacon-article="63032877abed727489465a04">
                                <FiBookOpen /> How to get started
                            </a>*/}
                        </Box>
                    </Box>
                    <Box mt={2}>
                        <ActionButton
                            title="New Tag"
                            action={() => {
                                setSelectedTag(false);
                                setShowModalAddTag(true);
                            }}
                            keyLabel="N"
                            mr={0}
                        />
                    </Box>
                </AppPageWrapperSectionHeader>
                <AppPageWrapperSectionBody>
                    <Box mx={4} my={4} width="100%">
                        <Flex style={{ width: '100%' }} justifyContent="space-between">
                            <Box style={{ color: 'black', marginTop: '4px' }} mb={4}>
                                <strong>
                                    {num_matched} {num_matched == 1 ? 'tag' : 'tags'} matched
                                </strong>
                            </Box>
                            <Box>
                                <InputSearch value={searchKeyword} onChange={onSearchKeywordChange} />
                            </Box>
                        </Flex>
                        <ListWrapper
                            style={{
                                position: 'relative',
                                border: '1px solid #eee',
                                borderRadius: '4px'
                            }}
                        >
                            <ItemWrapper className="header-row">
                                <Box width={1 / 4}>Title</Box>
                                <Box width={2 / 8}>Type</Box>
                                <Box width={1 / 4}>Actions</Box>
                            </ItemWrapper>
                            {renderTags()}
                            <ItemWrapper style={{ border: 0 }}>
                                {num_matched} result{num_matched == 1 ? '' : 's'}
                            </ItemWrapper>
                        </ListWrapper>
                        <br />
                        <br />
                    </Box>

                    <NiceModal
                        isOpen={showModalAddTag}
                        shouldCloseOnOverlayClick
                        onRequestClose={() => handleCloseModalCreateTag()}
                        title={`${selectedTag.id ? 'Edit' : 'Create a new'} tag`}
                    >
                        <Box>
                            <form onSubmit={selectedTag.id ? onEditTag : onCreateTag}>
                                {/*<Box mb={3} className='text-secondary-dark'>
                                    Enter the domain of your email. For john@abc-research.com, the domain
                                    would be abc-research.com.
                                </Box>*/}
                                <Box className="form-label">Title</Box>
                                <Input
                                    type="text"
                                    name="create_tag_title"
                                    value={selectedTag.title}
                                    required
                                    autoFocus
                                    onChange={e => {
                                        onChangeKey('title', e.target.value);
                                    }}
                                />
                                <Box className="form-label form-row-margin">Type</Box>
                                <Select
                                    name="create_tag_type"
                                    required
                                    value={selectedTag.type}
                                    onChange={e => {
                                        onChangeKey('type', e.target.value);
                                    }}
                                >
                                    {TAG_TYPES.map(type => {
                                        return <option value={type}>{type}</option>;
                                    })}
                                </Select>
                                <Box style={{ textAlign: 'right' }} mt={4}>
                                    <Button
                                        variant="secondary-gray"
                                        type="button"
                                        onClick={handleCloseModalCreateTag}
                                        mr={3}
                                    >
                                        Cancel
                                    </Button>
                                    <Button type="submit" mr={0} disabled={onSubmitLoading}>
                                        {selectedTag.id ? 'Update' : 'Create'} tag
                                    </Button>
                                </Box>
                            </form>
                        </Box>
                    </NiceModal>
                </AppPageWrapperSectionBody>
            </AppPageWrapper>
        </Flex>
    );
}

export default TagsPage;
