import React from 'react';
import classNames from 'classnames';

import helpers from 'utils/helpers';

/**
 * Dispaly a counter badge.
 *
 * @param {Object} props
 * @param {number} props.count The number of activities.
 * @param {boolean} props.isActive Whether the counter is active (highlighted).
 * @param {React.CSSProperties} props.style Additional styles to apply.
 */
export const CounterBadge = ({ count, isActive, style }) => (
    <span
        style={{ height: '18px', lineHeight: '14px', ...style }}
        className={classNames('label-highlight-number', isActive && 'active')}
    >
        {typeof count === 'number' ? helpers.numberFormat(count) : count}
    </span>
);

export default CounterBadge;
