import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

const activeClassName = 'nav-item-active';

const style = `display: flex;
  height: 48px;
  padding: 12px 0;
  margin: 0;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -webkit-touch-callout: none;
  user-select: none;
  cursor: pointer;
  outline: 0;
  color: #222222;
  text-transform: uppercase;
  transition: background-color 250ms, color 250ms;
  position: relative;
  font-weight: 600;
  font-size: 12px;
  line-height: 22px;

  &.headerLinkDropdown {
      padding: 8px 16px;
      color: #001233;
      height: auto;
  }

  &.headerLinkDropdown:hover {
    color: #0A73EB;
  }
  &.headerLinkDropdown.nav-item-active {
    color: #0A73EB;
  }

  &:hover {
      color: #0A73EB;
  }

  &.${activeClassName}:not(.headerLinkDropdown) {
      color: #0A73EB;
      border-bottom: 2px solid #0A73EB;
  }

  svg, img {
      margin: 0;
      width: 20px;
      height: 20px;
      height: auto;
  }

  .textStyle {
    flex: 1 1 0%;
  }

  .companyName {
      color: rgba(46, 31, 130, 1);
  }

  .iconWrapper {
      flex: 0 0 10px;
      pointer-events: none;
  }

  .iconEnd {
      position: absolute;
      right: 0;

      svg {
          margin-right: 8px;
      }
  }

  &.link-accountSwitcher .accountSwitcher {
      background: #fff;
      display: block;
      position: absolute;
      top: 0;
      right: calc(100% + 8px + 8px);
      word-break: keep-all;
      padding: 0;
      border-radius: 4px;

      div {
          display: block;
          text-overflow: ellipsis;
          white-space: nowrap;
          overflow: hidden;
          width: 200px;
      }
  }

`;

export const HeaderLink = styled(NavLink).attrs({ activeClassName })`
    ${style}
`;
export const HeaderButton = styled.div.attrs({ activeClassName })`
    ${style}
`;
export const HeaderAccount = styled.div`
    display: flex;
    padding: 0;
    margin: 8px;
    border-radius: 4px;
    text-decoration: none;
    -webkit-font-smoothing: antialiased;
    -webkit-touch-callout: none;
    user-select: none;
    cursor: pointer;
    outline: 0;
    background: rgba(0, 0, 0, 0);
    transition: background-color 250ms, color 250ms, opacity 250ms;
    white-space: nowrap;
    text-align: right;

    .accountInitial {
        overflow: hidden;
        text-align: center;
        font-weight: 500;
        color: #fff;
        width: 32px;
        height: 32px;
        border-radius: 4px;
        margin: 3px 6px 0 0;
        padding: 0 0 0 0;
        flex: 0 0 32px;
        pointer-events: none;
    }
`;
