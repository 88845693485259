import React from 'react';
import { Flex } from 'rebass';

const ReactModalActions = props => (
    <Flex
        justifyContent="space-between"
        px={4}
        py={3}
        style={{
            position: 'sticky',
            background: '#f7f7f7',
            borderTop: '1px solid #eee',
            zIndex: 100,
            borderBottomLeftRadius: '8px',
            borderBottomRightRadius: '8px'
        }}
    >
        {props.children}
    </Flex>
);

export default ReactModalActions;
