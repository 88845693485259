import React from 'react';
import LoginPageButton from 'containers/LoginPage/components/LoginPageButton';
import { FcGoogle } from 'react-icons/fc';

const SocialAuthButtons = props => (
    <>
        <LoginPageButton variant="secondary-gray" onClick={props.googleOnClick} mb={3}>
            <FcGoogle style={{ marginRight: '8px', fontSize: '20px', marginTop: '1px', color: 'transparent' }} />{' '}
            {props.isSignUpPage ? 'Sign up ' : 'Log in '}
            with Google
        </LoginPageButton>

        <LoginPageButton variant="secondary-gray" onClick={props.microsoftOnClick}>
            <img
                src="/microsoft.png"
                style={{
                    width: '16px',
                    height: '16px',
                    verticalAlign: 'top',
                    margin: '4px 14px 0 0'
                }}
                alt="Microsoft"
            />
            {props.isSignUpPage ? 'Sign up ' : 'Log in '} with Microsoft
        </LoginPageButton>
    </>
);

export default SocialAuthButtons;
