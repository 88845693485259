import React, { lazy } from 'react';
import { Box, Button, Flex } from 'rebass';
import xhr from 'xhr.js';
import { withToastManager } from 'react-toast-notifications';
import { FiChevronDown, FiChevronsDown, FiChevronsUp, FiPlus } from 'react-icons/fi';

import { CreatePersonDataKey } from 'components/PersonDataKey';
import services from 'services/services';
import helpers from 'utils/helpers';
import NiceModal from 'components/NiceModal';
import FatigueStatusNotification from 'components/FatigueStatusNotification';
import EmailIssueNotification from 'components/EmailIssueNotification';
import IncentiveProgress from 'components/IncentiveProgress';
import ListWrapper from 'components/ListWrapper';
import ParticipantAttrubuteItemWrapper from './ParticipantAttrubuteItemWrapper';
import Badge from 'components/Badge';
import CreatePanelPropertiesGroupModal from 'components/CreatePanelPropertiesGroupModal';

class ParticipantSettings extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            participant: this.props.participant,
            showModalAddCustomColumn: {
                isOpen: false,
                groupId: null
            },
            isCreateGroupModalOpen: false,
            collapsedGroups: []
        };

        this.onFormChange = this.onFormChange.bind(this);
        this.onSubmit = this.onSubmit.bind(this);
        this.saveParticipantXHR = this.saveParticipantXHR.bind(this);
        this.saveParticipantAttributeXHR = this.saveParticipantAttributeXHR.bind(this);
        this.createParticipantXHR = this.createParticipantXHR.bind(this);
        this.onPersonCustomDataChange = this.onPersonCustomDataChange.bind(this);
        this.onPersonCustomDataUnmask = this.onPersonCustomDataUnmask.bind(this);
        this.handleOpenModalAddCustomColumn = this.handleOpenModalAddCustomColumn.bind(this);
        this.handleCloseModalAddCustomColumn = this.handleCloseModalAddCustomColumn.bind(this);
        this.handleAddColumnSuccess = this.handleAddColumnSuccess.bind(this);
        this.getPersonXHR = this.getPersonXHR.bind(this);
    }

    /**
     * Open modal to add custom column
     *
     * @param {number?} groupId panel properties group ID
     */
    handleOpenModalAddCustomColumn(groupId) {
        this.setState({
            showModalAddCustomColumn: {
                isOpen: true,
                groupId
            }
        });
    }

    /**
     * Close modal to add custom column
     */
    handleCloseModalAddCustomColumn() {
        this.setState({
            showModalAddCustomColumn: {
                isOpen: false,
                groupId: null
            }
        });
    }

    /**
     * Handle add group success
     */
    onCreateGroupSuccess() {
        this.getPersonXHR(participant => this.props.parentCallback(participant));
    }

    handleAddColumnSuccess() {
        // e.preventDefault();
        this.getPersonXHR(participant => {
            // window.scrollTo(0,document.body.scrollHeight);
            // couldnt get this to focus on the last element, nor scroll tobottom

            this.props.parentCallback(participant);
        });

        const { toastManager } = this.props;
        toastManager.add('Column successfully added.', {
            appearance: 'success',
            autoDismiss: true
        });

        this.handleCloseModalAddCustomColumn();
    }

    getPersonXHR(cb) {
        services.getPerson(this.state.participant.id).then(person => {
            const participant = person;

            this.setState({
                participant
            });

            cb(participant);
        });
    }

    onFormChange(e) {
        if (!e.target.name) {
            return;
        }
        const participant = { ...this.state.participant };
        participant[e.target.name] = e.target.value;
        // console.log(e.target.name);
        this.setState({ participant });

        if (this.props.create) {
            // don't save yet
        } else {
            // detect change, and clear timeout if exists
            if (this.saveDelay) {
                clearTimeout(this.saveDelay);
            }

            // set timer
            this.saveDelay = setTimeout(
                function() {
                    this.saveParticipantXHR(participant);
                }.bind(this),
                500
            );
        }
    }

    /**
     * Toggle group collapse state
     */
    toggleGroup(groupId) {
        this.setState(prevState => {
            const isCollapsed = prevState.collapsedGroups.includes(groupId);

            if (isCollapsed) {
                return {
                    collapsedGroups: prevState.collapsedGroups.filter(collapsedGroupId => collapsedGroupId !== groupId)
                };
            } else {
                return {
                    collapsedGroups: [...prevState.collapsedGroups, groupId]
                };
            }
        });
    }

    onPersonCustomDataChange(e, update = true) {
        const customDataKeyId = e.target.name;
        //console.log(e, e.target.type)

        let customDataValue;
        if (e.target.type == 'checkbox') {
            customDataValue = helpers.getCheckboxValuesAsArray(customDataKeyId);
            //console.log(customDataValue);
        } else {
            customDataValue = e.target.value;
        }

        if (!e.target.name) {
            return;
        }
        const participant = { ...this.state.participant };

        let panelProperties = [];
        if (this.props.participant.data_keys_groups) {
            panelProperties = this.props.participant.data_keys_groups.reduce((acc, group) => {
                return acc.concat(group.person_data_keys);
            }, []);
        }

        if (panelProperties.length > 0) {
            panelProperties.forEach(cdk => {
                if (cdk.id == customDataKeyId) {
                    if (participant.custom_data_values2.hasOwnProperty(customDataKeyId)) {
                        participant.custom_data_values2[customDataKeyId] = customDataValue;
                    } else {
                        participant.custom_data_values2[customDataKeyId] = customDataValue;
                    }
                }
            });
        }

        this.setState({ participant });

        if (!update) {
            if (this.props.parentCallback) {
                this.props.parentCallback(participant);
            }
            return;
        }

        if (this.props.create) {
            // don't save yet
        } else {
            // detect change, and clear timeout if exists
            if (this.saveDelay) {
                clearTimeout(this.saveDelay);
            }

            // set timer for 200ms
            this.saveDelay = setTimeout(
                function() {
                    const attribute = {
                        account_id: this.state.participant.account_id,
                        key_id: customDataKeyId,
                        key_value: customDataValue
                    };
                    this.saveParticipantAttributeXHR(this.state.participant.id, attribute);
                }.bind(this),
                300
            );
        }
    }

    onPersonCustomDataUnmask(e) {
        return this.onPersonCustomDataChange(e, false);
    }

    saveParticipantXHR(participant) {
        // console.log('SAVED!!!', participant);

        const { toastManager } = this.props;

        xhr.put(`/people/${participant.id}`, participant, { withCredentials: true })
            .then(response => {
                toastManager.add('Saved', {
                    appearance: 'info',
                    autoDismiss: true
                });

                if (this.props.parentCallback) {
                    this.props.parentCallback(response.data);
                }
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);

                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    saveParticipantAttributeXHR(participantId, attribute) {
        const { toastManager } = this.props;

        xhr.put(`/people/${participantId}/custom-data-value`, attribute, {
            withCredentials: true
        })
            .then(response => {
                toastManager.add('Saved', {
                    appearance: 'info',
                    autoDismiss: true
                });
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);

                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    onSubmit(e) {
        e.preventDefault();

        if (this.props.create) {
            this.createParticipantXHR();
        } else {
            this.saveParticipantXHR();
        }
    }

    createParticipantXHR() {
        const { toastManager } = this.props;

        xhr.post(`/people?account_id=${this.props.auth.account.id}`, this.state.participant, {
            withCredentials: true
        })
            .then(res => {
                const p = res.data;
                console.log(res, p.id);

                window.location = `/participants/${p.id}`;
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);

                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    /**
     * Collapse all groups
     */
    collapseAllGroups() {
        const panelPropertiesGroups = this.props.participant.data_keys_groups || [];
        const groupIds = panelPropertiesGroups.map(group => group.id);

        this.setState({ collapsedGroups: groupIds });
    }

    /**
     * Expand all groups
     */
    expandAllGroups() {
        this.setState({ collapsedGroups: [] });
    }

    componentWillReceiveProps(nextProps) {
        // You don't have to do this check first, but it can help prevent an unneeded render
        if (nextProps.participant !== this.state.participant) {
            this.setState({ participant: nextProps.participant });
        }
    }

    render() {
        if (!this.state.participant.id && !this.props.create) return null;

        const panelPropertiesGroups = this.props.participant.data_keys_groups || [];

        return (
            <Box>
                {this.props.isPreview && (
                    <Box mb="16px">
                        <IncentiveProgress
                            css={{ color: '#000' }}
                            currency={this.state.participant.account.incentive_currency}
                            paid={this.state.participant.annual_incentive_amount}
                            limit={this.state.participant.account.settings.panelist_fatigue_incentive}
                        />
                        <FatigueStatusNotification
                            css={{ marginTop: '16px !important' }}
                            panelist={this.state.participant}
                        />
                        <EmailIssueNotification
                            panelist={this.state.participant}
                            css={{ marginTop: '16px !important' }}
                        />
                    </Box>
                )}
                <CreatePanelPropertiesGroupModal
                    isOpen={this.state.isCreateGroupModalOpen}
                    onClose={() => this.setState({ isCreateGroupModalOpen: false })}
                    onSuccess={() => this.onCreateGroupSuccess()}
                />
                <ParticipantAttrubuteItemWrapper
                    participantId={this.state.participant.id}
                    cdk={{ id: 0, type: 'uuid', title: 'Panelist UUID', pii_masked: false }}
                    cdv={{ value: this.state.participant.uuid }}
                    user={this.props.user}
                    role={this.props.role}
                    onChange={this.onPersonCustomDataChange}
                    onUnmask={this.onPersonCustomDataUnmask}
                    css={{ marginBottom: '16px', borderBottom: 'none' }}
                />
                <Box mb="10px">
                    <Button variant="link" mr={3} onClick={() => this.setState({ isCreateGroupModalOpen: true })}>
                        <FiPlus /> Add group
                    </Button>
                    <Button
                        variant="transparent"
                        className="color-text-secondary transparent-muted-button"
                        mr={3}
                        onClick={() => this.expandAllGroups()}
                    >
                        <FiChevronsDown /> Expand all groups
                    </Button>
                    <Button
                        variant="transparent"
                        className="color-text-secondary transparent-muted-button"
                        onClick={() => this.collapseAllGroups()}
                    >
                        <FiChevronsUp /> Collapse all groups
                    </Button>
                </Box>
                {panelPropertiesGroups.map(panelPropertiesGroup => {
                    const isCollapsed = this.state.collapsedGroups.includes(panelPropertiesGroup.id);

                    return (
                        <Box key={panelPropertiesGroup.id} mb="16px">
                            <Flex
                                alignItems="center"
                                py="10px"
                                sx={{ gap: '8px', cursor: 'pointer' }}
                                onClick={() => this.toggleGroup(panelPropertiesGroup.id)}
                            >
                                <Box as="p" className="fs-title-16 color-dark">
                                    {panelPropertiesGroup.name}
                                </Box>
                                <Badge
                                    type="pure"
                                    className="fs-accent-12 color-text-secondary--important"
                                    css={{
                                        padding: '0px 4px',
                                        minHeight: '16px'
                                    }}
                                >
                                    {panelPropertiesGroup.person_data_keys.length}
                                </Badge>
                                <Box flexGrow={1} />
                                <Button variant="transparent-icon">
                                    <FiChevronDown
                                        style={{
                                            transform: isCollapsed ? 'rotate(-90deg)' : 'rotate(0deg)'
                                        }}
                                    />
                                </Button>
                            </Flex>
                            <ListWrapper style={{ display: isCollapsed ? 'none' : 'block' }}>
                                {panelPropertiesGroup.person_data_keys.map(cdk => {
                                    const foundCDV = this.state.participant.custom_data_values2[cdk.id];

                                    return (
                                        <ParticipantAttrubuteItemWrapper
                                            key={cdk.id}
                                            participantId={this.state.participant.id}
                                            cdk={cdk}
                                            cdv={{ value: foundCDV }}
                                            user={this.props.user}
                                            role={this.props.role}
                                            onChange={this.onPersonCustomDataChange}
                                            onUnmask={this.onPersonCustomDataUnmask}
                                        />
                                    );
                                })}
                            </ListWrapper>
                            <Button
                                display={isCollapsed ? 'none' : 'block'}
                                type="button"
                                my="10px"
                                variant="link"
                                onClick={() => this.handleOpenModalAddCustomColumn(panelPropertiesGroup.id)}
                            >
                                + Add Panel Property
                            </Button>
                        </Box>
                    );
                })}
                <form onSubmit={this.onSubmit} className="new-input-wrapper">
                    {this.props.create ? (
                        <Button mt={3} type="submit" onClick={this.onSubmit}>
                            Create
                        </Button>
                    ) : (
                        ''
                    )}
                </form>
                {!this.props.create && (
                    <NiceModal
                        isOpen={this.state.showModalAddCustomColumn.isOpen}
                        shouldCloseOnOverlayClick
                        onRequestClose={this.handleCloseModalAddCustomColumn}
                        title={`Add a panelist property`}
                    >
                        <CreatePersonDataKey
                            defaultGroupId={this.state.showModalAddCustomColumn.groupId}
                            onSuccess={this.handleAddColumnSuccess}
                            onClose={this.handleCloseModalAddCustomColumn}
                        />
                    </NiceModal>
                )}
            </Box>
        );
    }
}

export default withToastManager(ParticipantSettings);
