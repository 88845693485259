/**
 * <Toolbar />
 */

import React from 'react';
import H1 from 'components/H1';
import ToolbarItem from './toolbar-draggable-item';
import ID from './UUID';
import ReactModal from 'react-modal';
import store from './stores/store';
import { Label, Select, Textarea } from '@rebass/forms';
import { Flex, Box, Button } from 'rebass';
import NiceDropdown from 'components/NiceDropdown';
import services from 'services/services';
import studies_service from 'services/studies';
import {
    COL_FIRST_NAME,
    COL_LAST_NAME,
    COL_EMAIL,
    COL_PHONE_NUMBER,
    COL_OPT_IN,
    VALIDATION_TYPE_EMAIL,
    GENERIC_CONSENT,
    SCREENER_QDQS_ACCEPT,
    SCREENER_QDQS_REJECT,
    PAID_FEATURE
} from 'utils/constants';
import EventEmitter from 'utils/events';
import cloneDeep from 'lodash/cloneDeep';
import { withToastManager } from 'react-toast-notifications';
import AvailableElements from 'components/ReactFormBuilder/config/available-elements.js';
import { FiChevronDown, FiPlus, FiToggleLeft, FiUser, FiFileText, FiDownload } from 'react-icons/fi';
import helpersScreener from 'utils/helpers-screener';
import helpers from 'utils/helpers';
import auth from 'services/auth';
import NiceModal from 'components/NiceModal';
import NiceSelect from 'components/NiceSelect';
import moment from 'moment-timezone';
import UpgradeButton from 'components/UpgradeButton';

class Toolbar extends React.Component {
    constructor(props) {
        super(props);

        const items = this.props.items ? this.props.items : AvailableElements;
        this.state = {
            items,
            showAddElementDropdown: false,
            showScreenerMassPaste: false,
            showScreenerImportQuestions: false,
            screenerMassPasteText: '',
            screenerMassPasteJson: [],
            allAccountScreeners: [],
            copyFromPastScreenerId: false,
            copyFromPastScreenerObject: {}
        };
        store.subscribe(state => this.setState({ store: state }));
        this.create = this.props.create;
        this._onAdd = this._onAdd.bind(this);
        this.onChangeTextareaScreenerMassPaste = this.onChangeTextareaScreenerMassPaste.bind(this);
        this.screenerMassPasteSubmit = this.screenerMassPasteSubmit.bind(this);
        this.insertCopiedQuestions = this.insertCopiedQuestions.bind(this);
        this.getCopyScreenerDropdownItems = this.getCopyScreenerDropdownItems.bind(this);
        this.getCopyScreenerDropdownValueMap = this.getCopyScreenerDropdownValueMap.bind(this);
        this.hasVideoEnabled = this.hasVideoEnabled.bind(this);
    }

    static _copyItemOptions(item) {
        let allNewOptions = [];
        if (item.options) {
            item.options.forEach(option => {
                const copiedOption = cloneDeep(option);
                copiedOption.key = copiedOption.key + ID.uuid();
                allNewOptions.push(copiedOption);
            });
        }

        return allNewOptions;
    }

    addContactInfo() {
        this._onAdd('TextInput', { label: 'First Name', required: true }, COL_FIRST_NAME);
        this._onAdd('TextInput', { label: 'Last Name', required: true }, COL_LAST_NAME);
        const email_validation_rules = [
            {
                validation_type: VALIDATION_TYPE_EMAIL,
                validation_value: '',
                validation_message: ''
            }
        ];
        this._onAdd(
            'TextInput',
            { label: 'Email', required: true, validation: true, validation_rules: email_validation_rules },
            COL_EMAIL
        );
        this._onAdd('TextInput', { label: 'Phone Number', required: true }, COL_PHONE_NUMBER);
    }

    addConsent() {
        this._onAdd('RadioButtons', {
            label: 'Do you consent to ',
            required: true,
            options: [
                { value: 1, text: 'Yes', key: ID.uuid(), qualification: SCREENER_QDQS_ACCEPT, type: 'row' },
                { value: 0, text: 'No', key: ID.uuid(), qualification: SCREENER_QDQS_REJECT, type: 'row' }
            ],
            qual_disqual: true
        });
    }

    onClickImportQuestion() {
        this.xhrGetAllAccountScreeners();

        this.setState({ showScreenerImportQuestions: true });
    }

    _onAdd(elementKey, itemDefaults, customColumnLabel, pageNumber) {
        const new_item_blank = this.state.items.find(item => item.key == elementKey);
        if (new_item_blank) {
            const element = this.create(new_item_blank, itemDefaults, customColumnLabel);

            const addOnPageNumber = pageNumber === undefined ? this.props.page_number : pageNumber;

            // just for clarity, we're actually using page index, not number
            const addOnPageIndex = addOnPageNumber;
            console.log('_onAdd', elementKey, addOnPageIndex);
            store.dispatch('create', {
                element,
                page_index: addOnPageIndex
            });

            this.findAndFocusElement(element.id);
        } else {
            alert('Unfortunately the element was not found. Please contact support.');
        }
    }

    findAndFocusElement(id) {
        try {
            var x = 0;
            var intervalID = window.setInterval(function() {
                const html_input_element_id = helpersScreener.getScreenerBuilderInputHtmlId(id);
                const html_input_element = document.getElementById(html_input_element_id);

                if (html_input_element) {
                    html_input_element.focus();
                }

                if (++x === 5 || html_input_element) {
                    //console.log('found and stopping', html_input_element);
                    window.clearInterval(intervalID);
                } else {
                    //console.log('NOT found ', html_input_element);
                }
            }, 200);
        } catch (e) {
            helpers.trackError(e);
        }
    }
    componentWillUnmount() {
        EventEmitter.unsubscribe('screener.element.create');
    }
    componentDidMount() {
        const { toastManager } = this.props;

        const that = this;
        EventEmitter.subscribe('screener.element.create', function(eventPayload) {
            console.log('event payload', eventPayload, that.props.page_number);
            const page_number = eventPayload.page_number;

            if (that.props.page_number != eventPayload.page_number) {
                return;
            }

            let new_item = cloneDeep(eventPayload.item);

            // console.log('screener.element.create', new_item);

            if (new_item.options) {
                new_item.options = Toolbar._copyItemOptions(new_item);
            }

            that._onAdd(new_item.element, new_item);

            toastManager.add('Duplicate question added to the end of the page', {
                appearance: 'success',
                autoDismiss: true
            });
        });
    }

    screenerMassPasteSubmit() {
        const { toastManager } = this.props;

        this.state.screenerMassPasteJson.map((page, idx) => {
            page.map(q => {
                if (q.type == 'TextInput') {
                    this._onAdd('TextInput', { label: q.label }, undefined, this.props.page_number + idx);
                } else if (q.type == 'RadioButtons') {
                    let new_item = {
                        label: q.label,
                        options: []
                    };

                    if (q.options) {
                        q.options.forEach((option_text, key) => {
                            if (option_text) {
                                let newOption = {};
                                newOption.type = 'row';
                                newOption.text = option_text;
                                newOption.value = option_text;
                                newOption.key = `option_${ID.uuid()}`;

                                new_item.options.push(newOption);
                            }
                        });
                    }

                    this._onAdd('RadioButtons', new_item, undefined, this.props.page_number + idx);
                }
            });
        });

        this.setState({ showScreenerMassPaste: false, screenerMassPasteText: '', screenerMassPasteJson: [] });

        toastManager.add(`Successfully added ${this.state.screenerMassPasteJson.length} questions`, {
            appearance: 'success',
            autoDismiss: true
        });
    }

    onChangeTextareaScreenerMassPaste(e) {
        const { toastManager } = this.props;
        const textval = e.target.value;
        if (helpers.containsHtml(textval)) {
            this.setState({ getScreenerLoading: false });
            toastManager.add('Adding HTML is not allowed in the bulk question editor', {
                appearance: 'error',
                autoDismiss: true
            });
            return false;
        }
        const pastedPages = textval.split(/\n{3,}/g);
        const pagesData = [];
        pastedPages.forEach(page => {
            const jsonval = page.split('\n\n');
            const new_questions = [];
            jsonval.forEach(question => {
                const question_rows_all = question.split('\n');
                // filter out empty rows
                const question_rows = question_rows_all.filter(val => val);
                if (question_rows.length == 0) {
                    // do nothing
                } else if (question_rows[0]) {
                    const question_label_trimmed = question_rows[0].trim();
                    if (question_rows.length == 1) {
                        if (question_label_trimmed.length > 0) {
                            new_questions.push({
                                label: question_label_trimmed,
                                type: 'TextInput'
                            });
                        }
                    } else {
                        new_questions.push({
                            label: question_label_trimmed,
                            type: 'RadioButtons',
                            options: question_rows.slice(1)
                        });
                    }
                }
            });
            pagesData.push(new_questions);
        });

        const massPastePageData = pagesData.filter(page => page);

        this.setState({ screenerMassPasteText: textval });
        // this.setState({ screenerMassPasteJson: new_questions });
        this.setState({ screenerMassPasteJson: massPastePageData });
    }

    getCopyScreenerDropdownItems() {
        let items = [];
        this.state.allAccountScreeners.forEach(s => {
            if (s.screener_id != this.props.screener_id) {
                items.push({
                    id: s.screener_id,
                    title: `[${s.study_title}] ${s.screener_title}`
                });
            }
        });
        return items;
    }

    getCopyScreenerDropdownValueMap() {
        let items = {};
        this.state.allAccountScreeners.forEach(s => {
            if (s.screener_id != this.props.screener_id) {
                items[s.screener_id] = `[${s.study_title}] ${s.screener_title}`;
            }
        });
        return items;
    }

    xhrGetAllAccountScreeners() {
        const { toastManager } = this.props;

        this.setState({ getAllScreenersLoading: true });

        studies_service
            .getAllAccountScreeners()
            .then(screeners => {
                screeners.sort(function(a, b) {
                    return b.screener_id - a.screener_id;
                });
                //console.log(screeners);
                this.setState({ allAccountScreeners: screeners, getAllScreenersLoading: false });
            })
            .catch(error => {
                this.setState({ getAllScreenersLoading: false });
                const errorText = services.parseAndTrackXhrErrors(error);

                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    xhrGetScreenerById(screener_id) {
        const { toastManager } = this.props;
        if (screener_id) {
            // need to get studyid for the xhr route

            this.setState({ getScreenerLoading: true });

            const screener = this.state.allAccountScreeners.find(s => s.screener_id == screener_id);
            const study_id = screener.study_id;

            studies_service
                .getScreener(study_id, screener_id)
                .then(screener => {
                    //console.log(screener)
                    this.setState({
                        copyFromPastScreenerObject: screener,
                        getScreenerLoading: false
                    });
                })
                .catch(error => {
                    alert('Error getting screener');
                    this.setState({ getScreenerLoading: false });
                    const errorText = services.parseAndTrackXhrErrors(error);
                    toastManager.add(errorText, {
                        appearance: 'error',
                        autoDismiss: true
                    });
                });
        }
    }

    insertCopiedQuestions() {
        const { toastManager } = this.props;
        const that = this;

        const checkedBoxElements = document.querySelectorAll('input[name=copy_question_ids]:checked');
        const question_ids = [];
        checkedBoxElements.forEach(cbe => {
            if (cbe && cbe.value) {
                question_ids.push(cbe.value);
            }
        });

        if (question_ids.length) {
            console.log(question_ids);
            let copied_questions = [];
            this.state.copyFromPastScreenerObject.form.forEach(page_questions => {
                page_questions.forEach(pq => {
                    if (question_ids.includes(pq.id)) {
                        copied_questions.push(pq);
                    }
                });
            });

            if (copied_questions.length) {
                copied_questions.forEach(item => {
                    let new_item = cloneDeep(item);

                    // console.log('screener.element.create', new_item);

                    if (new_item.options) {
                        new_item.options = Toolbar._copyItemOptions(new_item);
                    }

                    that._onAdd(new_item.element, new_item);
                });

                toastManager.add('Inserted questions', {
                    appearance: 'success',
                    autoDismiss: true
                });

                this.setState({
                    showScreenerImportQuestions: false,
                    copyFromPastScreenerObject: {},
                    copyFromPastScreenerId: false
                });
            }
        } else {
            alert('0 questions selected. Pease select at least 1 question.');
        }
    }

    hasVideoEnabled() {
        let enabled = false;
        try {
            let auth_state = auth.getAuthState();
            enabled = helpers.hasFeatureEnabled(auth_state.account, PAID_FEATURE.SURVEY_VIDEO);
        } catch (e) {
            console.log(e);
        }
        return enabled;
    }

    render() {
        let addAnotherElementItems = this.state.items.map(item => {
            return { id: item.key, title: item.name, icon: item.icon };
        });
        const contactInfoHelper = {
            id: 'bulk-contact-info',
            title: 'Contact Info',
            icon: <FiUser />,
            onClick: () => {
                this.addContactInfo();
            }
        };
        const consentHelper = {
            id: 'consent-helper',
            title: 'Consent',
            icon: <FiToggleLeft />,
            onClick: () => {
                this.addConsent();
            }
        };

        const importQuestionsHelper = {
            id: 'import-questions-helper',
            title: 'Import questions',
            icon: <FiDownload />,
            onClick: () => {
                this.onClickImportQuestion();
            }
        };

        const divider = { id: 'divider', divider: true };

        addAnotherElementItems.splice(2, 0, contactInfoHelper);
        addAnotherElementItems.splice(3, 0, consentHelper);

        // after page titles
        addAnotherElementItems.splice(2, 0, divider);
        // after consent/contact info
        addAnotherElementItems.splice(5, 0, divider);
        // after the multiple choices
        addAnotherElementItems.splice(10, 0, divider);
        // after the text inputs
        addAnotherElementItems.splice(15, 0, divider);

        // after range/rating
        addAnotherElementItems.splice(18, 0, divider);

        // after date
        addAnotherElementItems.splice(20, 0, divider);

        // check if addpipe token is present, if not, disable it
        if (!this.props.addpipe_token && !this.hasVideoEnabled()) {
            const addpipe_index = 21;
            const verify_addpipe_index = addAnotherElementItems.findIndex(item => item.id == 'AddPipeInput');
            if (verify_addpipe_index && verify_addpipe_index == addpipe_index) {
                // its "title" instead of "name" for the nice dropdown
                addAnotherElementItems[verify_addpipe_index].title;
                addAnotherElementItems[verify_addpipe_index].suffix = (
                    <UpgradeButton style={{ marginLeft: '8px' }} type="survey-video" />
                );
                addAnotherElementItems[verify_addpipe_index].disabled = true;
            }
        }
        addAnotherElementItems.push(divider);
        addAnotherElementItems.push(importQuestionsHelper);

        //console.log(addAnotherElementItems)

        /*
      can be re-used for other Elements in beta
      try {
          const account = auth.getAccount();
          if (account && (account.features).includes('survey-hiddenvalue')) {
              // skip
          } else {
              //
              const found_hiddenvalue = addAnotherElementItems.findIndex(item => item.id == 'HiddenValue');
              if (found_hiddenvalue) {
                  addAnotherElementItems.splice(found_hiddenvalue, 1);
              }
          }
      } catch(e){
          console.log(e)
      }*/

        // FileUpload
        //addAnotherElementItems.splice(19, 0);

        return (
            <div className="react-form-builder-toolbar">
                <Box style={{ textAlign: 'center', width: '100%' }}>
                    <span style={{ position: 'relative', textAlign: 'left' }}>
                        <Button
                            mr={0}
                            variant="secondary"
                            className="louder"
                            type="button"
                            onClick={() => this.setState({ showAddElementDropdown: true })}
                        >
                            Add element{' '}
                            <FiChevronDown
                                style={{
                                    marginRight: 0,
                                    marginLeft: 0,
                                    marginTop: '3px'
                                }}
                            />
                        </Button>
                        {this.state.showAddElementDropdown && (
                            <NiceDropdown
                                showSearch
                                adjustHeight
                                //positionTop="auto"
                                //positionBottom={'32px'}
                                positionLeft={'-1px'}
                                onClose={() => {
                                    //console.log('CLOSING dropdown');
                                    this.setState({ showAddElementDropdown: false });
                                    //setShowDisplayedCustomColumn(false);
                                }}
                                items={addAnotherElementItems}
                                onChange={key => {
                                    //updateHeaderTitlesToColumnIds(showDisplayedCustomColumn, columnId);
                                    //console.log(id)
                                    this._onAdd(key);
                                }}
                            />
                        )}
                        <Button
                            fontSize={1}
                            variant="secondary"
                            type="button"
                            ml={3}
                            onClick={() => {
                                this.setState({ showScreenerMassPaste: true });
                            }}
                        >
                            <FiFileText /> Paste screener
                        </Button>
                    </span>
                </Box>

                <NiceModal
                    isOpen={this.state.showScreenerImportQuestions}
                    shouldCloseOnOverlayClick
                    onRequestClose={() => {
                        this.setState({ showScreenerImportQuestions: false });
                    }}
                    style={{ content: { width: '980px', minHeight: '300px' } }}
                    title="Import questions from another screener"
                >
                    <Box>
                        <Label htmlFor="description">Choose a screener</Label>
                        {this.state.getAllScreenersLoading == true ? (
                            'Loading...'
                        ) : (
                            <NiceSelect
                                showSearch
                                defaultValue={0}
                                mimicSelectStyle={true}
                                items={this.getCopyScreenerDropdownItems()}
                                mapValueToLabel={this.getCopyScreenerDropdownValueMap()}
                                onChange={screener_id => {
                                    this.setState({ copyFromPastScreenerId: screener_id });
                                    this.xhrGetScreenerById(screener_id);
                                    //var s = document.getElementById("new-form-copy-screener-id");
                                    //s.value = screener_id;
                                }}
                            />
                        )}
                        {this.state.copyFromPastScreenerId > 0 && (
                            <Box>
                                <Label mt={3} htmlFor="description">
                                    Choose questions
                                </Label>
                                {this.state.getScreenerLoading == true
                                    ? 'Loading...'
                                    : this.state.copyFromPastScreenerObject &&
                                      this.state.copyFromPastScreenerObject.form
                                    ? this.state.copyFromPastScreenerObject.form.map(
                                          (screener_page_questions, page_index) => {
                                              let page_items = [];
                                              page_items.push(
                                                  <Box className="strong text-primary" mb={1}>
                                                      Page {page_index + 1}
                                                  </Box>
                                              );
                                              screener_page_questions.forEach(q => {
                                                  let label = q.static
                                                      ? q.content
                                                          ? q.content.substr(0, 30)
                                                          : ''
                                                      : q.label
                                                      ? q.label.substr(0, 30)
                                                      : '';
                                                  // get element name
                                                  const ae_found = AvailableElements.find(ae => ae.key == q.element);
                                                  if (ae_found) {
                                                      page_items.push(
                                                          <Box>
                                                              <label>
                                                                  <input
                                                                      type="checkbox"
                                                                      name="copy_question_ids"
                                                                      value={q.id}
                                                                  />{' '}
                                                                  &nbsp;[{ae_found.name}] {label}
                                                              </label>
                                                          </Box>
                                                      );
                                                  }
                                              });

                                              page_items.push(<br />);

                                              return page_items;
                                          }
                                      )
                                    : ''}
                            </Box>
                        )}
                    </Box>
                    <Box className="modal-actions">
                        <Button
                            type="button"
                            variant="secondary-gray"
                            mr={3}
                            onClick={() => this.setState({ showScreenerImportQuestions: false })}
                        >
                            Cancel
                        </Button>
                        <Button
                            mr={0}
                            type="submit"
                            variant="primary"
                            className="modal-primary"
                            disabled={!this.state.copyFromPastScreenerId}
                            onClick={this.insertCopiedQuestions}
                        >
                            Import questions
                        </Button>
                    </Box>
                </NiceModal>

                <NiceModal
                    isOpen={this.state.showScreenerMassPaste}
                    shouldCloseOnOverlayClick
                    onRequestClose={() => {
                        this.setState({ showScreenerMassPaste: false });
                    }}
                    style={{ content: { width: '980px', minHeight: '500px' } }}
                    title="Add questions in bulk"
                >
                    <Box>
                        <Flex sx={{ gridGap: '24px' }}>
                            <Box width={2 / 3}>
                                <Label mb={0}>One blank line starts a new question.</Label>
                                <Label mb={3}>
                                    {helpers.newFeatureTag(moment('07-08-2022', 'MM-DD-YYYY'), {
                                        margin: '2px 8px 0 0'
                                    })}
                                    Two blank lines start a new page.
                                </Label>
                                <Textarea
                                    value={this.state.screenerMassPasteText}
                                    onChange={this.onChangeTextareaScreenerMassPaste}
                                    autoFocus
                                    style={{ height: '400px' }}
                                    placeholder="What is your eye color?&#10;Green&#10;Blue&#10;Brown"
                                />
                            </Box>
                            <Box width={1 / 3} sx={{ position: 'relative' }}>
                                <Label mb={3}>
                                    {this.state.screenerMassPasteJson.length == 0 ? 'Try this example' : 'Preview'}
                                </Label>
                                <Box style={{ overflow: 'scroll', height: '350px' }}>
                                    {this.state.screenerMassPasteJson.length == 0 && (
                                        <Box
                                            className="rounded"
                                            fontSize={1}
                                            mb={3}
                                            p={'12px 16px'}
                                            style={{ background: '#f6f6f6' }}
                                        >
                                            First Name
                                            <br />
                                            <br />
                                            Last Name
                                            <br />
                                            <br />
                                            What is your eye color?
                                            <br />
                                            Blue
                                            <br />
                                            Green
                                            <br />
                                            Brown
                                            <br />
                                            Hazel
                                            <br />
                                            <br />
                                            <br />
                                            Page 2, first question
                                        </Box>
                                    )}
                                    {this.state.screenerMassPasteJson.map((page, idx) => (
                                        <Box key={`pasted-page-${idx}`}>
                                            <H1
                                                className="text-primary"
                                                style={{ fontSize: '18px', marginTop: '16px', marginBottom: '8px' }}
                                            >
                                                Page {Number(this.props.page_number) + idx + 1}
                                            </H1>

                                            {page.map((q, qidx) => (
                                                <Box
                                                    className="rounded"
                                                    fontSize={1}
                                                    mb={3}
                                                    key={`page-question-${qidx}`}
                                                    p="12px 16px"
                                                    style={{ background: '#F6F6F6' }}
                                                >
                                                    <Box className="gray">
                                                        <small>{q.type}</small>
                                                    </Box>
                                                    <Box>{q.label}</Box>
                                                    <Box className="gray">
                                                        <small>
                                                            {q.options && q.options.length ? (
                                                                <Box mt={3}>
                                                                    Options:
                                                                    <ul className="screener-mass-options">
                                                                        {q.options.map((o, qoidx) => (
                                                                            <li key={`page-question-option-${qoidx}`}>
                                                                                {o}
                                                                            </li>
                                                                        ))}
                                                                    </ul>
                                                                </Box>
                                                            ) : (
                                                                ''
                                                            )}
                                                        </small>
                                                    </Box>
                                                </Box>
                                            ))}
                                        </Box>
                                    ))}
                                </Box>

                                <Button
                                    type="button"
                                    variant="primary"
                                    onClick={this.screenerMassPasteSubmit}
                                    sx={{ width: '100%', position: 'absolute', bottom: 0 }}
                                >
                                    Submit
                                </Button>
                            </Box>
                        </Flex>
                    </Box>
                </NiceModal>
            </div>
        );
    }
}

export default withToastManager(Toolbar);
