import React, { useState, useRef } from 'react';
import classNames from 'classnames';
import { FiUploadCloud } from 'react-icons/fi';
import { Box } from 'rebass';

export function UploadDnD({ onDrop, className, accept }) {
    const [isDragging, setIsDragging] = useState(false);
    const fileInputRef = useRef();

    const handleDragOver = event => {
        event.preventDefault();
        setIsDragging(true);
    };

    const handleDragLeave = () => {
        setIsDragging(false);
    };

    const handleDrop = event => {
        event.preventDefault();
        setIsDragging(false);

        // Transform DataTransferItemList to Array
        let files = Array.from(event.dataTransfer.files);

        // Filter files by accepted types
        if (accept && accept.length > 0) {
            files = files.filter(file => accept.includes(file.type));
        }

        // Fire onDrop function
        if (files && files.length > 0) {
            if (onDrop) onDrop(files);
        }
    };

    const handleFileInput = event => {
        if (onDrop) onDrop(Array.from(event.target.files));
    };

    return (
        <div
            className={classNames('upload-dnd', isDragging && 'upload-dnd-dragged', className)}
            onDragOver={handleDragOver}
            onDragLeave={handleDragLeave}
            onDrop={handleDrop}
            onClick={() => fileInputRef.current.click()}
        >
            <input
                ref={fileInputRef}
                accept={accept && accept.join(',')}
                type="file"
                multiple
                style={{ display: 'none' }}
                onChange={handleFileInput}
            />
            <FiUploadCloud css={{ fontSize: '32px', marginBottom: '8px' }} />
            <Box as="p" className="color-primary fs-14" fontWeight="500" lineHeight="20px" mb="4px">
                Browse files
            </Box>
            <Box as="p" color="#606E85" fontSize="12px" lineHeight="16px">
                or drag & drop it here
            </Box>
        </div>
    );
}

export default UploadDnD;
