import Store from 'beedle';
import { get, put } from './requests';
import EventEmitter from 'utils/events';
import { TIMETRAVEL_REDO, TIMETRAVEL_UNDO } from 'utils/constants';
import { cloneDeep } from 'lodash';
import helpers from 'utils/helpers';

let _saveUrl;
let _onPost;
let _onLoad;

const store = new Store({
    actions: {
        setData(context, data, saveData) {
            context.commit('setData', data);
            if (saveData) {
                this.save(data);
            }
        },

        timelineAction(context, type) {
            context.commit('timelineMutation', type);
            this.save(context.state.timeline[context.state.cursor]);
        },

        cleanTimeLine(context) {
            context.commit('cleanTimeLineMutation');
        },

        updateSaveUrl(context, saveUrl) {
            _saveUrl = saveUrl;
            //console.log('updated saveUrl', saveUrl)
        },

        load(context, { loadUrl, saveUrl, data }) {
            _saveUrl = saveUrl;
            if (_onLoad) {
                _onLoad().then(x => this.setData(context, x));
            } else if (loadUrl) {
                get(loadUrl).then(x => {
                    if (data && data.length > 0 && x.length === 0) {
                        data.forEach(y => x.push(y));
                    }
                    this.setData(context, x);
                });
            } else {
                this.setData(context, data);
            }
        },

        create(context, { element, page_index }) {
            //console.log(context, element, page_index);
            console.log('create...');
            const { data } = cloneDeep(context.state);

            if (data && data[page_index]) {
                data[page_index].push(element);
            } else {
                data[page_index] = [];
                data[page_index].push(element);
            }

            this.setData(context, data, true);
        },

        // "page_number" is likely "page_index" (e.g. if its page 1, then index is 0)
        delete(context, { page_number, element }) {
            console.log('delete...', page_number);
            //console.log('receive delete', page_number, element);

            if (!element) {
                //console.log('DID NOT SEND ELEMENT TO DELETE');
                return;
            }

            // don't dare x_x
            //var audio = new Audio("https://www.zapsplat.com/wp-content/uploads/2015/sound-effects-46416/zapsplat_explosions_gas_boom_airy_designed_001_54857.mp3");
            //audio.play();

            const { data } = cloneDeep(context.state);

            const indexToDelete = data[page_number].findIndex(pq => pq.id == element.id);
            //console.log('to delete element index', indexToDelete)
            if (indexToDelete >= 0) {
                data[page_number].splice(indexToDelete, 1);
                this.setData(context, data, true);
            }
        },

        updateOrder(context, { elements, saveData = true }) {
            this.setData(context, elements, saveData);
        },

        save(data) {
            // console.log('%c ON PUT OR POST ','color:red', data);
            //console.log("store.js save.. saveurl", _saveUrl)
            if (_onPost) {
                //console.log('....onPost?');
                _onPost({ task_data: data });
            } else if (_saveUrl) {
                if (this.saveDelay) {
                    //console.log('..screener save delay')
                    clearTimeout(this.saveDelay);
                }

                this.saveDelay = setTimeout(() => {
                    // set counter for how long this should take
                    const slowDurationDetect = 10000; // 10 seconds
                    let xhrDuration = setTimeout(() => {
                        console.log('this took some time! ' + slowDurationDetect / 1000);
                        EventEmitter.dispatch('screener.save.slow');
                    }, slowDurationDetect);

                    put(_saveUrl, { task_data: data })
                        .then(screenerObject => {
                            clearTimeout(xhrDuration);
                            //console.log('..saved ', screenerObject)
                            EventEmitter.dispatch('screener.save.success', screenerObject);
                        })
                        .catch(err => {
                            clearTimeout(xhrDuration);
                            console.log('..error ', err);
                            EventEmitter.dispatch('screener.save.error', err);
                        });
                }, 1000);
            } else {
                helpers.trackError('missing _onPost and saveUrl in store.js');
            }
        }
    },

    mutations: {
        setData(state, payload) {
            //console.log('before mutation: ', state.timeline);
            const timeLine = cloneDeep(state.timeline);

            if (state.cursor > 0) {
                timeLine.splice(0, state.cursor);
                state.cursor = 0;
            }
            state.timeline = [payload, ...timeLine];

            const max_timeline_length = 20;
            if (state.timeline.length > max_timeline_length) {
                state.timeline.splice(max_timeline_length, state.timeline.length - max_timeline_length);
            }

            //console.log('after mutation: ', state.timeline);

            // eslint-disable-next-line no-param-reassign
            state.data = payload;
            return state;
        },

        timelineMutation(state, payload) {
            if (payload === TIMETRAVEL_UNDO.type && state.cursor < state.timeline.length - 1) state.cursor += 1;
            if (payload === TIMETRAVEL_REDO.type && state.cursor > 0) state.cursor -= 1;
            state.data = [...state.timeline[state.cursor]];
            return state;
        },

        cleanTimeLineMutation(state) {
            state.timeline = [];
            return state;
        }
    },

    initialState: {
        data: [],
        cursor: 0,
        timeline: []
    }
});

store.setExternalHandler = (onLoad, onPost) => {
    _onLoad = onLoad;
    _onPost = onPost;
};

export default store;
