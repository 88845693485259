import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import {
    MarketingPageWrapper,
    MarketingHeader,
    MarketingFooter,
    MarketingPageSection,
    MarketingHeadline
} from 'components/Marketing';
import { Flex, Box, Button, Heading } from 'rebass';
import { isMobile } from 'react-device-detect';
import xhr from 'xhr.js';
import services from 'services/services';
import { Label, Input } from '@rebass/forms';
import { FiArrowRight, FiPlayCircle } from 'react-icons/fi';
import NiceWrapper from 'components/NiceWrapper';

function DemoSchedulingPage(props) {
    const [submitEmailError, setSubmitEmailError] = useState(false);
    const [showDemoVideo, setShowDemoVideo] = useState(false);

    useEffect(() => {
        const script = document.createElement('script');

        script.src = 'https://assets.calendly.com/assets/external/widget.js';
        script.async = true;

        document.body.appendChild(script);

        return () => {
            document.body.removeChild(script);
        };
    }, []);

    function submitForDemo() {
        const fname = document.getElementById('fname').value;
        const lname = document.getElementById('lname').value;
        const email = document.getElementById('email').value;

        setSubmitEmailError(null);

        xhr.post(`/collect`, { email, fname, lname }, { withCredentials: true })
            .then(res => {
                const user = res.data;

                setShowDemoVideo(true);
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);

                setSubmitEmailError(errorText);
            });
    }

    return (
        <Flex flexDirection="column" style={{ margin: '0px auto', width: '100%', position: 'relative' }}>
            <MarketingHeader auth={props.auth} />
            <MarketingPageWrapper isMobile={isMobile}>
                <Helmet>
                    <title>Watch Demo</title>
                </Helmet>
                <MarketingPageSection style={{ textAlign: 'center' }}>
                    <Heading
                        fontSize={[5, 6, 6]}
                        color="black"
                        style={{ margin: isMobile ? '64px 0 0 0' : '100px 0 0 0' }}
                    >
                        It's demo time
                    </Heading>
                    <Box style={{ margin: isMobile ? '16px 0 32px 0' : '16px 0 32px 0' }}>
                        Tell us a bit about yourself, and watch the demo.
                    </Box>

                    {showDemoVideo ? (
                        <Box mt={4}>
                            <iframe
                                width="560"
                                height="315"
                                src="https://www.youtube.com/embed/XCeNiPM4lZU"
                                title="YouTube video player"
                                frameborder="0"
                                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
                                allowfullscreen
                            ></iframe>
                        </Box>
                    ) : (
                        <NiceWrapper style={{ width: '320px', background: 'white', color: 'black', padding: '32px' }}>
                            <>
                                <form
                                    onSubmit={e => {
                                        e.preventDefault();
                                        submitForDemo();
                                    }}
                                >
                                    <Label htmlFor="fname">First Name</Label>
                                    <Input id="fname" type="text" name="fname" required autoFocus />
                                    <Label mt={3} htmlFor="lname">
                                        Last Name
                                    </Label>
                                    <Input id="lname" type="text" name="lname" required />
                                    <Label mt={3} htmlFor="fname">
                                        Work Email
                                    </Label>
                                    <Input id="email" type="text" name="email" required />

                                    {submitEmailError && (
                                        <Box className="error" fontSize={1} mt={3}>
                                            {submitEmailError}
                                        </Box>
                                    )}

                                    <Button type="submit" className="huge" mr={0} mt={4} sx={{ width: '100%' }}>
                                        Watch Now <FiPlayCircle />
                                    </Button>
                                </form>
                            </>
                        </NiceWrapper>
                    )}
                </MarketingPageSection>

                <MarketingFooter props={props} />
            </MarketingPageWrapper>
        </Flex>
    );
}

export default DemoSchedulingPage;
