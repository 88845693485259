import React, { useState, useEffect, useMemo, useRef } from 'react';
import H1 from 'components/H1';
import { Flex, Box, Button } from 'rebass';
import { Label, Checkbox, Select } from '@rebass/forms';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import styled from 'styled-components';
import services from 'services/services.js';
import studies_service from 'services/studies.js';
import helpers from 'utils/helpers.js';
import helpersScreener from 'utils/helpers-screener.js';
import { useToasts } from 'react-toast-notifications';
import ListWrapper from 'components/ListWrapper';
import { ReactFormGenerator } from 'components/ReactFormBuilder';
import ReactModalActions from 'components/ReactModalActions';

function ScreenerVersions(props) {
    const [allScreenerVersions, setAllScreenerVersions] = useState([]);
    const [selectedScreenerVersion, setSelectedScreenerVersion] = useState({});
    const [allScreenerVersionsLoading, setAllScreenerVersionsLoading] = useState(true);
    const [selectedScreenerVersionLoading, setSelectedScreenerVersionLoading] = useState(false);
    const [isUpdatingScreener, setIsUpdatingScreener] = useState(false);
    const didMount = useRef(false);
    const { addToast } = useToasts();

    useEffect(() => {
        xhrGetAllVersions();
    }, []);

    function xhrGetAllVersions() {
        const study_id = props.study_id;
        const screener_id = props.screener_id;

        if (study_id && screener_id) {
            studies_service
                .getAllScreenerVersions(study_id, screener_id)
                .then(d => {
                    setAllScreenerVersions(d);
                    setAllScreenerVersionsLoading(false);
                })
                .catch(error => {
                    setAllScreenerVersionsLoading(false);
                    const errorText = services.parseAndTrackXhrErrors(error);

                    addToast(errorText, {
                        appearance: 'error',
                        autoDismiss: true
                    });
                });
        }
    }

    function xhrGetScreenerVersion(screener_version_id) {
        const study_id = props.study_id;
        const screener_id = props.screener_id;

        if (study_id && screener_id && screener_version_id) {
            setSelectedScreenerVersionLoading(true);
            studies_service
                .getScreenerVersionById(study_id, screener_id, screener_version_id)
                .then(d => {
                    setSelectedScreenerVersion(d);
                    setSelectedScreenerVersionLoading(false);
                })
                .catch(error => {
                    setSelectedScreenerVersionLoading(false);
                    const errorText = services.parseAndTrackXhrErrors(error);

                    addToast(errorText, {
                        appearance: 'error',
                        autoDismiss: true
                    });
                });
        }
    }

    function renderAllVersions() {
        let elements = [];
        if (allScreenerVersions.length) {
            let select_options = [];
            select_options.push(<option value={''}></option>);
            allScreenerVersions.forEach(sv => {
                try {
                    select_options.push(
                        <option value={sv.id}>
                            {moment.utc(sv.created_at).fromNow()} with {sv.num_pages} pages by {sv.user.name}
                            &nbsp;[
                            {moment
                                .utc(sv.created_at)
                                .local()
                                .format('ddd, MMM Do h:mm:ss a')}
                            ]
                        </option>
                    );
                } catch (e) {
                    helpers.trackError(e);
                }
            });
            elements.push(
                <>
                    <Label>Select a version</Label>
                    <select onChange={e => xhrGetScreenerVersion(e.target.value)}>{select_options}</select>
                </>
            );
        } else {
            elements.push('No data');
        }
        return elements;
    }

    function renderSelectedVersion() {
        let render;
        if (selectedScreenerVersionLoading) {
            render = <Box mt={3}>Loading version...</Box>;
        } else {
            if (selectedScreenerVersion && selectedScreenerVersion.form) {
                //console.log("rendering form..", selectedScreenerVersion.form)
                render = (
                    <>
                        <Label mt={3}>Version preview</Label>
                        <Box p={3} className={'rounded border'}>
                            <ReactFormGenerator
                                data={selectedScreenerVersion.form}
                                read_only={true}
                                hide_actions={true}
                                show_qualification={true}
                                answer_data={[]}
                            />
                        </Box>
                    </>
                );
            }
        }

        return render;
    }

    function replaceScreenerWithVersion(screener_version_id) {
        const study_id = props.study_id;
        const screener_id = props.screener_id;

        setIsUpdatingScreener(true);
        studies_service
            .replaceScreenerWithVersion(study_id, screener_id, screener_version_id)
            .then(() => {
                location.reload();
            })
            .catch(error => {
                setIsUpdatingScreener(false);
                let errorText = services.parseAndTrackXhrErrors(error);

                addToast(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    return (
        <Box>
            <Box p={4} fontSize={1} sx={{ overflow: 'scroll' }}>
                <H1>Previous screener versions</H1>
                <Flex sx={{ flexDirection: 'column' }}>
                    {allScreenerVersionsLoading ? 'Loading all versions...' : renderAllVersions()}
                    {renderSelectedVersion()}
                </Flex>
            </Box>
            <ReactModalActions>
                <div style={{ textAlign: 'right', width: '100%' }}>
                    <Button type="button" variant="transparent" mr={3} onClick={() => props.onClose()}>
                        Cancel
                    </Button>
                    <Button
                        type="button"
                        variant="primary"
                        mr={0}
                        disabled={!selectedScreenerVersion || !selectedScreenerVersion.id || isUpdatingScreener}
                        onClick={() => {
                            if (selectedScreenerVersion && selectedScreenerVersion.id) {
                                if (
                                    !confirm(
                                        'Are you sure you want to overwrite your screener with the selected version?'
                                    )
                                ) {
                                    return;
                                }
                                replaceScreenerWithVersion(selectedScreenerVersion.id);
                            } else {
                                addToast(errorText, {
                                    appearance: 'error',
                                    autoDismiss: true
                                });
                            }
                        }}
                    >
                        {isUpdatingScreener ? 'Reverting...' : 'Revert to this version'}
                    </Button>
                </div>
            </ReactModalActions>
        </Box>
    );
}

export default ScreenerVersions;
