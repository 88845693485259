import React, { Children } from 'react';
import { Box } from 'rebass';
import classnames from 'classnames';

/**
 * Basic card component.
 *
 * @param {Object} props
 * @param {string} props.className Class name
 * @param {React.Component} props.children Children
 */
export const Card = ({ className, children, ...props }) => {
    const hasIcon = Children.toArray(children).some(child => child.type === CardIcon);
    const hasFooter = Children.toArray(children).some(child => child.type === CardFooter);
    const hasHeader = Children.toArray(children).some(child => child.type === CardHeader);

    return (
        <Box
            className={classnames(
                'basic-card card-grid',
                {
                    'grid-no-icon': !hasIcon,
                    'grid-no-footer': !hasFooter,
                    'grid-no-header': !hasHeader
                },
                className
            )}
            p="24px"
            {...props}
        >
            {children}
        </Box>
    );
};

/**
 * Card body component.
 *
 * @param {Object} props
 * @param {string} props.className Class name
 * @param {React.Component} props.children Children
 */
export const CardBody = ({ className, ...props }) => <Box className={classnames('card-body', className)} {...props} />;

/**
 * Card header component.
 *
 * @param {Object} props
 * @param {string} props.className Class name
 * @param {React.Component} props.children Children
 */
export const CardHeader = ({ className, ...props }) => (
    <Box className={classnames('card-header', className)} {...props} />
);

/**
 * Card footer component.
 *
 * @param {Object} props
 * @param {string} props.className Class name
 * @param {React.Component} props.children Children
 */
export const CardFooter = ({ className, ...props }) => (
    <Box className={classnames('card-footer', className)} {...props} />
);

/**
 * Card icon component.
 *
 * @param {Object} props
 * @param {string} props.className Class name
 * @param {React.Component} props.icon Icon component
 */
export const CardIcon = ({ className, icon, ...props }) => (
    <Box className={classnames('card-icon', className)} {...props}>
        {icon}
    </Box>
);

export default Card;
