import React, { useMemo, useRef } from 'react';
import sortBy from 'lodash/sortBy';
import cloneDeep from 'lodash/cloneDeep';
import { Box, Flex } from 'rebass';
import { FiClock, FiX, FiPlusCircle } from 'react-icons/fi';
import classNames from 'classnames';

import helpers from 'utils/helpers';
import moment from 'utils/moment';

/**
 * Function to calculate positions for a given set of event ranges.
 * The main goal is to determine the width and left position of each event
 * based on overlapping groups, ensuring no overlaps visually.
 *
 * @param {Array<{ start: number; end: number }>} ranges - An array of event objects with 'start' and 'end' properties.
 * @returns {Array<{ start: number; end: number; width: number; left: number; index: number, n: number }>} An array of event objects with added 'width' and 'left' properties.
 */
export const calculatePositions = ranges => {
    /**
     * Helper function to find overlapping events within the given array of events.
     * It groups events that overlap with each other into separate sub-arrays.
     *
     * @param {Array<{ start: number; end: number }>} events - An array of event objects with 'start' and 'end' properties.
     * @returns {Array<Array<{ start: number; end: number }>>} An array of arrays, where each sub-array contains overlapping events.
     */
    const findOverlappingEvents = events => {
        // Create a copy of the input array to avoid modifying the original array.
        const eventsCopy = [...events];

        // Sort events by their start time in ascending order.
        eventsCopy.sort((a, b) => a.start - b.start);

        // Initialize an array to hold groups of overlapping events.
        const overlappingGroups = [];
        // Initialize a temporary array to hold the current group of overlapping events.
        let currentGroup = [];

        // Iterate through each event in the sorted list.
        for (let event of eventsCopy) {
            // If the current group is empty or the current event overlaps with the last event in the group,
            // add the current event to the current group.
            if (!currentGroup.length || event.start < currentGroup[currentGroup.length - 1].end) {
                currentGroup.push(event);
            } else {
                // If the current event does not overlap with the last event in the group,
                // push the current group to overlappingGroups and start a new group with the current event.
                overlappingGroups.push(currentGroup);
                currentGroup = [event];
            }
        }

        // After the loop, if there are any remaining events in the current group, add it to overlappingGroups.
        if (currentGroup.length) {
            overlappingGroups.push(currentGroup);
        }

        // Return the array of overlapping event groups.
        return overlappingGroups;
    };

    // Find all groups of overlapping events in the input ranges.
    const overlappingGroups = findOverlappingEvents(ranges);

    // Initialize an array to hold the result with updated event properties.
    const result = [];

    // Iterate through each group of overlapping events.
    for (let group of overlappingGroups) {
        // Determine the number of overlapping events in the current group.
        const groupEventCount = group.length;
        // Calculate the width each event should have to fit within a single time slot without overlapping.
        const width = 1 / groupEventCount;

        // Iterate through each event in the group.
        group.forEach((event, index) => {
            // Assign the calculated width to the event.
            event.width = width;
            // Assign the left position based on the event's index in the group.
            event.left = index * width;
            // Assign the index of the event within the original array of events.
            event.index = index;
            // Assign the number of overlapping events to the event.
            event.groupEventCount = groupEventCount;
            // Add the event to the result array.
            result.push(event);
        });
    }

    // Return the array of events with updated width and left properties.
    return result;
};

/**
 * Finds the closest key to the target key in the given object.
 * If the target key exists in the object, it is returned immediately.
 * Otherwise, the function finds the key with the smallest absolute distance to the target key.
 * In case of a tie (two keys equidistant to the target key), the larger key is chosen.
 *
 * @param {Record<number, any>} obj - The object containing key-value pairs.
 * @param {number} targetKey - The target key to find the closest key to.
 * @returns {number} - The closest key to the target key.
 *
 * @example
 * const obj = {
 *   1: 'a',
 *   2: 'b',
 *   4: 'd'
 * };
 * const targetKey = 3;
 * const closestKey = findClosestKey(obj, targetKey);
 * console.log(closestKey); // Outputs: 4
 */
export function findClosestKey(obj, targetKey) {
    // Check if the target key exists in the object.
    if (obj.hasOwnProperty(targetKey)) {
        return Number(targetKey);
    }

    // Get an array of all keys in the object as numbers.
    const keys = Object.keys(obj).map(Number);
    let closestKey = null;
    let closestDistance = Infinity;

    // Iterate through each key in the object.
    keys.forEach(key => {
        // Calculate the absolute distance between the current key and the target key.
        const distance = Math.abs(key - targetKey);

        if (distance < closestDistance || (distance === closestDistance && key > closestKey)) {
            closestDistance = distance;
            closestKey = key;
        }
    });

    return closestKey;
}

/**
 * A simple time slot component that displays the given text. It's used to represent time slots in the calendar.
 *
 * @param {Object} props
 * @param {string} props.text The text to display in the time slot.
 * @param {string|number} props.timestamp The timestamp associated with the time slot.
 * @param {Object} props.style The custom styles to apply to the time slot.
 * @param {string} props.className The custom class name to apply to the time slot.
 */
export const CalendarTimeSlot = ({ text, timestamp, style, className }) => (
    <Box className={className} style={style}>
        {text}
    </Box>
);

/**
 * A time slot range component that represents a range of time slots in the calendar.
 *
 * @param {Object} props
 * @param {number} props.duration The duration of the time slot range in minutes.
 * @param {(range: { start: number|string, end: number|string }) => void} props.onRemoveRange The callback function to remove the time slot range.
 * @param {Element} props.columnElement The reference to the column element containing the time slot range.
 * @param {{ start: number|string, end: number|string, width: number, left: number, index: number, n: number }} props.range The time slot range object.
 * @param {Record<number, Element>} props.slots The object containing references to the time slot elements.
 * @param {string} props.timezone The timezone to use for time formatting.
 */
export function CalendarEvent({ duration, onRemoveRange, columnElement, range, slots, timezone }) {
    const itemBounds = useMemo(() => {
        const startElementRef = slots[findClosestKey(slots, range.start)];
        const endElementRef = slots[findClosestKey(slots, range.end)];

        if (!startElementRef || !endElementRef) return null;
        const startBounds = startElementRef.getBoundingClientRect();
        const endBounds = endElementRef.getBoundingClientRect();
        const columnBounds = columnElement.getBoundingClientRect();

        const OVERLAP_PADDING = 3;
        const HEIGHT_SHIFT = 4; // it's make the event slightly shorter to make it look better
        return {
            height: Math.floor(endBounds.top - startBounds.top) - HEIGHT_SHIFT + 'px',
            width: `calc(100% * ${range.width} + ${range.groupEventCount === 1 ? 0 : OVERLAP_PADDING}px)`, // make event wider if there are overlaps. Because we move it slightly to the left.
            left: `calc(100% * ${range.left} - (${range.index === 0 ? 0 : OVERLAP_PADDING}px))`, // move event slightly to the left if there are overlaps. It makes event look like it's on top of the previous one.
            zIndex: range.index,
            top: startBounds.top - columnBounds.top + 'px'
        };
    }, [range]);

    if (!itemBounds) return null;

    return (
        <Flex
            flexDirection="column"
            className="bg-main color-border-primary rounded-small overflow-hidden absolute calendar-time-slot-range"
            style={{
                left: itemBounds.left,
                top: itemBounds.top,
                width: itemBounds.width,
                height: itemBounds.height,
                maxHeight: itemBounds.height,
                zIndex: itemBounds.zIndex
            }}
        >
            <FiX
                className="calendar-time-slot-range-remove absolute pointer rounded-small"
                tabIndex={0}
                onClick={() => onRemoveRange(range)}
            />
            <Box as="p" className="fs-accent-12 text-primary nowrap">
                {moment(range.start * 1000)
                    .tz(timezone)
                    .format('h:mm A')}
            </Box>
            <Box flexGrow={1} />
            <Box as="p" className="fs-body-12 color-primary nowrap">
                <FiClock /> {helpers.minutesToHoursPretty(duration)}
            </Box>
        </Flex>
    );
}

/**
 * Calendar indicator that allows users to add events to the calendar.
 *
 * @param {Object} props
 * @param {number} props.timestamp
 * @param {() => void} props.onClick
 * @param {string} props.timezone
 * @param {boolean} props.isDisabled
 */
export function CalendarIndicator({ onClick, timestamp, timezone, isDisabled }) {
    return (
        <Box className={classNames('absolute calendar-indicator', isDisabled && 'calendar-indicator-disabled')}>
            <FiPlusCircle onClick={() => !isDisabled && onClick()} className="calendar-indicator-button" />
            <Box className="calendar-indicator-line" />
            <Box as="span" className="calendar-indicator-timestamp">
                {moment(timestamp * 1000)
                    .tz(timezone)
                    .format('h:mm A')}
            </Box>
        </Box>
    );
}

/**
 *
 * @param {Object} props
 * @param {Array<{ [string|number]: boolean }>} props.selectedChildren
 * @param {string} props.date
 * @param {boolean} props.enabled
 * @param {Array<{ start: number | string, end: number | string }>} props.ranges
 * @param {(selectedChildren: Array<{ [string|number]: boolean }>, timestamp: string | number)} props.onSelectionChange
 * @param {React.ReactNode} props.children
 * @param {(timestamp: string | number) => void} props.onRemoveRange
 * @param {number} props.duration
 * @param {string} props.timezone
 */
export function CalendarTrack({ ranges: rawRanges, onSelectionChange, children, onRemoveRange, duration, timezone }) {
    const columnRef = useRef(null);
    const slots = useRef({});
    const preparedChildren = useMemo(
        () =>
            React.Children.toArray(children).map(child => {
                const timestamp = +child.props.timestamp;
                const isIndicatorDisabled = rawRanges.some(range => timestamp === +range.start);

                const indicator = React.createElement(CalendarIndicator, {
                    type: 'button',
                    key: `${timestamp}-indicator`,
                    timestamp,
                    timezone,
                    isDisabled: isIndicatorDisabled,
                    onClick: () => selectItem(timestamp)
                });

                const wrappedChild = React.createElement(
                    'div',
                    {
                        ref: ref => (slots.current[timestamp] = ref),
                        key: timestamp,
                        onClick: () => !isIndicatorDisabled && selectItem(timestamp),
                        className: classNames(
                            'text-secondary select-box relative calendar-time-slot-wrapper',
                            !isIndicatorDisabled && 'pointer'
                        )
                    },
                    React.cloneElement(child),
                    indicator
                );

                return wrappedChild;
            }),
        [children, rawRanges]
    );
    const ranges = useMemo(() => {
        const rawRangesCopy = sortBy(cloneDeep(rawRanges), ['start', 'end']).map(range => ({
            start: +range.start,
            end: +range.end
        }));

        return calculatePositions(rawRangesCopy);
    }, [rawRanges, duration]);

    const selectItem = timestamp => {
        onSelectionChange({
            start: Number(timestamp),
            end: Number(timestamp) + duration * 60 // end is the start + duration in seconds
        });
    };

    return (
        <div ref={columnRef} style={{ position: 'relative' }}>
            {preparedChildren}
            {columnRef.current && // render ranges after children
                ranges.map((range, index) => (
                    <CalendarEvent
                        key={`${range.start}-${range.end}-range-${index}`}
                        duration={duration}
                        range={range}
                        slots={slots.current}
                        onRemoveRange={onRemoveRange}
                        columnElement={columnRef.current}
                        timezone={timezone}
                    />
                ))}
        </div>
    );
}

export default CalendarTrack;
