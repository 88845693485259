import React, { useEffect, useState } from 'react';
import { Box, Button } from 'rebass';
import moment from 'moment-timezone';
import DatePicker from 'react-datepicker';

import NiceModal from 'components/NiceModal';
import Portal from 'components/Portal';

/**
 * A modal for selecting a date and time.
 *
 * @param {Object} param0
 * @param {boolean} param0.isOpen - Whether the modal is open
 * @param {() => void} param0.onClose - Function to call when the modal is closed
 * @param {(date: string, raw: Date) => void} param0.onSubmit - Function to call when the date is submitted
 * @param {string} param0.timezone - The timezone to use for the date picker
 * @param {Date} param0.defaultValue - The default value for the date picker
 */
export function SessionDatepickerModal({ isOpen, onClose, onSubmit, timezone, defaultValue = new Date() }) {
    const [value, setValue] = useState(defaultValue);

    return (
        <Portal>
            <NiceModal
                isOpen={isOpen}
                onRequestClose={() => onClose()}
                style={{ content: { width: 'auto', maxWidth: '1100px' } }}
                title="Choose a date & time"
            >
                <Box>
                    <DatePicker
                        inline
                        className="theme-input"
                        style={{ width: '100%' }}
                        showTimeInput
                        onChange={date => setValue(date)}
                        selected={value}
                        timeInputLabel={`Time (${moment.tz(timezone).format('z')}):`}
                    />
                </Box>
                <Box className="modal-actions">
                    <Button type="button" variant="secondary-gray" mr={3} onClick={onClose}>
                        Cancel
                    </Button>
                    <Button
                        type="submit"
                        variant="primary"
                        sx={{ marginRight: 0 }}
                        onClick={() => {
                            const formattedDate = moment(value).format('YYYY-MM-DD HH:mm');
                            onSubmit(formattedDate, value);
                            onClose();
                        }}
                    >
                        Save
                    </Button>
                </Box>
            </NiceModal>
        </Portal>
    );
}
