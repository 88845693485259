import React from 'react';
import ReactTooltip from 'react-tooltip';
import { Image } from 'rebass';
import { Label } from '@rebass/forms';
import { INTEGRATION_SALESFORCE } from 'utils/constants';

const IntegrationLabel = ({ integration, labelText, tooltipId, tooltipContent }) => {
    let icon;
    switch (integration) {
        case INTEGRATION_SALESFORCE:
            icon = <Image src={'/salesforce.svg'} alt="Salesforce" width="16px" margin="0 2px 2px 0" />;
            break;
    }

    return (
        <>
            {tooltipId ? (
                <>
                    <Label
                        data-tip
                        data-for={tooltipId}
                        ml={2}
                        className={`label-basic fs-body-12`}
                        style={{ color: '#000', backgroundColor: '#f5f9fd' }}
                    >
                        {icon} {labelText}
                    </Label>
                    <ReactTooltip id={tooltipId} effect="solid" place="left" multiline>
                        {tooltipContent}
                    </ReactTooltip>
                </>
            ) : (
                <Label
                    ml={2}
                    className={`label-basic fs-body-12`}
                    style={{ color: '#000', backgroundColor: '#f5f9fd' }}
                >
                    {icon} {labelText}
                </Label>
            )}
        </>
    );
};

export default IntegrationLabel;
