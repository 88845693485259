import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { FiChevronUp, FiChevronDown } from 'react-icons/fi';
import { Flex, Box } from 'rebass';

const Wrapper = styled.div`
    max-width: 600px;
    width: 100%;
    border-bottom: 1px solid #e7e8f1;
    margin: 0 0 24px 0;
    padding: 0 0 24px 0;

    &:last-child {
        border-bottom: 0;
        margin: 0;
        padding: 0;
    }
`;

const El = styled.div`
    font-weight: 600;
    cursor: pointer;
    font-size: 14px;

    padding: 8px 0;

    .collapsible-toggle svg {
        vertical-align: top;
        margin: 3px 4px 0 0;
        font-size: 16px;
    }

    .show-hide {
        display: inline-block;
        margin: -1px 0 0 6px;
        vertical-align: top;
    }

    .show-hide svg {
        font-size: 18px;
    }
`;

function CollapsibleContentContainer(props) {
    const [show, setShow] = useState(props.show);

    useEffect(() => {
        setShow(props.show);
    }, [props.show]);
    //console.log('collapse?', props.show)
    return (
        <Wrapper className={show && 'active'} style={props.style} id={props.id}>
            <CollapsibleContentHeader
                show={show}
                title={props.title}
                subtitle={props.subtitle}
                end={props.end}
                onClick={() => {
                    const new_var = !show;
                    if (props.onToggle) {
                        props.onToggle(new_var);
                    }
                    setShow(new_var);
                }}
            />
            <CollapsibleContentBody show={show}>{props.children}</CollapsibleContentBody>
        </Wrapper>
    );
}

function CollapsibleContentHeader(props) {
    return (
        <El onClick={props.onClick} style={{ position: 'relative' }} className="text-primary">
            <Flex justifyContent={'space-between'} sx={{ width: '100%', background: '' }}>
                <Flex justifyContent={'space-between'} sx={{ width: '100%', background: '' }} className="link muted">
                    <Box className="ellipsis">{props.title}</Box>
                    <Box className="collapsible-toggle">{props.show ? <FiChevronUp /> : <FiChevronDown />}</Box>
                </Flex>
                {props.end ? (
                    <>
                        <span className={'vertical-line'} style={{ padding: '3px 16px 0 12px' }}>
                            |
                        </span>
                        {props.end}
                    </>
                ) : (
                    ''
                )}
            </Flex>
            {props.subtitle}
        </El>
    );
}

const CollapsibleContentBody = styled.div.attrs(props => ({
    display: props.show ? 'block' : 'none'
}))`
    display: ${props => props.display};
`;

export { CollapsibleContentContainer, CollapsibleContentHeader, CollapsibleContentBody };
