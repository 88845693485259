import React from 'react';
import styled from 'styled-components';
import classNames from 'classnames';

const BadgeContainer = styled.span`
    padding: 4px 8px;
    box-sizing: border-box;
    font-size: 14px;
    line-height: 19px;
    min-height: 28px;
    font-weight: 500;
    border-radius: 4px;
    display: inline-flex;
    vertical-align: middle;
    justify-content: center;

    svg {
        vertical-align: top;
        margin: 2px 6px 0 2px;
        padding: 0;
        font-size: 16px;
    }
`;

/**
 * Badge component
 *
 * @param {Object} props props
 * @param {string} props.className className
 * @param {'main' | 'danger' | 'success' | 'warn' | 'info' | 'pure'} props.type color type
 * @param {React.CSSProperties} props.style style
 * @returns {React.ReactNode} Badge component
 */
export const Badge = ({ className, type, ...props }) => {
    return <BadgeContainer className={classNames(`bg-${type} text-${type}`, className)} {...props} />;
};

export default Badge;
