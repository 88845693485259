import React from 'react';
import { Link } from 'react-router-dom';
import { Flex, Box } from 'rebass';
import { isMobile } from 'react-device-detect';

export default function MarketingFooter() {
    return (
        <div style={{ marginTop: '32px' }}>
            <Box
                sx={{
                    // maxWidth: 'calc(800px + 48px)',
                    mx: 'auto',
                    textAlign: 'left',
                    margin: '24px 0',
                    fontSize: '14px',
                    padding: isMobile ? '0 24px' : '24px 24px'
                }}
            >
                <Flex sx={{ justifyContent: 'space-between' }}>
                    <Box sx={{ marginTop: '14px' }}>
                        <a href="/">
                            <img
                                src="/fox2.png"
                                style={{
                                    width: '24px',
                                    height: '24px',
                                    margin: '-2px 8px 0 0',
                                    /* padding: '4px', border: '2px solid black',borderRadius: '6px', */ verticalAlign:
                                        'top'
                                }}
                                alt="Logo"
                            />
                        </a>
                        <span style={{ fontWeight: 500, color: 'black' }}>
                            &copy; {new Date().getFullYear()} Panelfox
                        </span>
                        <span style={{ margin: '0 8px' }}></span>
                        {/* <Link to="/docs/legal/privacy" className="muted">
                            Privacy
                        </Link>
                        <span style={{ margin: '0 8px' }}></span>
                        <Link to="/docs/legal/terms" className="muted">
                            Terms
                        </Link>
                        <span style={{ margin: '0 8px' }}></span>
                        <Link to="/security" className="muted">
                            Security
                        </Link>
                        <span style={{ margin: '0 8px' }}></span>
                        <Link to="/docs" className="muted">
                            Docs
                        </Link>
                        <span style={{ margin: '0 8px' }}></span>
                        <a href="mailto:support@panelfox.io" className="muted">
                            Contact Us
                        </a> */}
                    </Box>
                    <Box>
                        <Link to="/docs/legal/gdpr">
                            <img
                                src="/gdpr.png"
                                style={{
                                    width: '48px',
                                    height: '48px',
                                    margin: '0 32px 0 0',
                                    /* padding: '4px', border: '2px solid black',borderRadius: '6px', */ verticalAlign:
                                        'top'
                                }}
                                alt="Logo"
                            />
                        </Link>
                        <Link to="/docs/legal/ccpa">
                            <img
                                src="/ccpa.png"
                                style={{
                                    width: '48px',
                                    height: '48px',
                                    margin: '0',
                                    /* padding: '4px', border: '2px solid black',borderRadius: '6px', */ verticalAlign:
                                        'top'
                                }}
                                alt="Logo"
                            />
                        </Link>
                    </Box>
                </Flex>
            </Box>
        </div>
    );
}
