import React, { useEffect, useRef } from 'react';
import { Box } from 'rebass';
import classNames from 'classnames';

import helpersScreener from 'utils/helpers-screener';

import myxss from './myxss';
import { getElementLabel } from './getElementLabel';

/**
 * Label component
 *
 * @param {Object} props
 * @param {Object} props.data
 * @param {boolean?} props.isPopup if true, then it's a popup
 */
const ComponentLabel = props => {
    const labelRef = useRef(null);
    const hasLabel = props.data.hasOwnProperty('label') && props.data.label;
    const hasImage = props.data.hasOwnProperty('label-image') ? props.data['label-image'] : false;
    let insert_html_sanitized = myxss.process(
        helpersScreener.insertPiping(props.data.label, props.all_questions, props.answers_by_form_element_name)
    );

    // Assign the asterisk to the label if it's required
    useEffect(() => {
        if (!hasLabel && !hasImage) return;

        if (labelRef.current) {
            // find all elements that that are text
            const elements = labelRef.current.querySelectorAll('h1, h2, h3, h4, h5, h6, p, blockquote');

            // filter out elements that contain only <br> tag
            const filteredElements = Array.from(elements).filter(el => !el.querySelector('br'));

            // get the last element
            const lastElement = filteredElements[filteredElements.length - 1];

            if (lastElement) {
                // add class that indicates that this element is the last one
                lastElement.classList.add('last-question-element');
            }
        }
    }, []);

    if (!hasLabel && !hasImage) {
        return null;
    }

    const isText = helpersScreener.getElementNamesStatic().includes(props.data.element);
    return (
        <Box
            mb={props.isPopup ? '4px' : 3}
            className={classNames('color-text-primary', props.isPopup ? 'fs-accent-14' : 'fs-title-16')}
        >
            {!props.data.static ? helpersScreener.showQuestionNumber(props.all_questions, props.data) : ''}
            <div
                ref={labelRef}
                id={getElementLabel(props.data.id)}
                style={{ whiteSpace: 'pre-wrap' }}
                className={classNames('quill-render', props.data.required && !isText && 'required-question')}
                // eslint-disable-next-line react/no-danger
                dangerouslySetInnerHTML={{ __html: insert_html_sanitized }}
            />
            {hasImage && <img src={hasImage} style={{ maxWidth: '100%', margin: '16px 0 0 0' }} />}
        </Box>
    );
};

export default ComponentLabel;
