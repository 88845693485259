import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';
import NiceWrapper from 'components/NiceWrapper';
import { Flex, Box, Heading, Button } from 'rebass';
import { Label, Switch, Input } from '@rebass/forms';
import helpers from 'utils/helpers';
import services from 'services/services';
import service_studies from 'services/studies';
import service_accounts from 'services/accounts';
import LoadingWrapper from 'components/LoadingIndicator/LoadingWrapper';
import LoadingIndicator from 'components/LoadingIndicator';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { FiCheckCircle } from 'react-icons/fi';

function EmailCampaignPage(props) {
    const [emailCampaignHtml, setEmailCampaignHtml] = useState(null);
    const { addToast } = useToasts();

    useEffect(() => {
        getEmailCampaignPublicXHR(props.match.params.email_campaign_uuid);
    }, [props.match.params.email_campaign_uuid]);

    function getEmailCampaignPublicXHR(uuid) {
        service_studies
            .getEmailCampaignPublic(uuid)
            .then(response => {
                setEmailCampaignHtml(response);
            })
            .catch(e => {
                const errorText = services.parseAndTrackXhrErrors(e);
                addToast(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    return (
        <div style={{ margin: 'auto', width: '100%', background: 'transparent' }}>
            {emailCampaignHtml ? <Box dangerouslySetInnerHTML={{ __html: emailCampaignHtml }}></Box> : <Box></Box>}
        </div>
    );
}

export default EmailCampaignPage;
