/*
 * StudiesPage
 *
 * This is the first thing users see of our App, at the '/' route
 */

import React, { useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import xhr from 'xhr.js';
import styled from 'styled-components';

import {
    MarketingPageWrapper,
    MarketingHeader,
    MarketingFooter,
    MarketingPageSection,
    MarketingHeadline
} from 'components/Marketing';
import H1 from 'components/H1';
import H2 from 'components/H2';

import { Flex, Box, Button, Heading } from 'rebass';
import { Label, Input } from '@rebass/forms';
import config from 'config';
import { FcGoogle } from 'react-icons/fc';
import NiceWrapper from 'components/NiceWrapper';
import auth from 'services/auth';
import { isMobile } from 'react-device-detect';

const Sepratator = styled.div`
    width: 1px;
    height: 16px;
    background: #d9d2e8;
    margin: 8px 12px 0;
    display: inline-block;
    vertical-align: top;
`;

class OurStoryPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};
    }

    componentDidMount() {
        /* xhr.get(`/me`, { withCredentials: true }).then(res => {
            const user = res.data;

            this.setState({ user });
        }); */
    }

    render() {
        return (
            <Flex flexDirection="column" style={{ margin: '0px auto', width: '100%', position: 'relative' }}>
                <MarketingHeader auth={this.props.auth} />
                <MarketingPageWrapper isMobile={isMobile}>
                    <Helmet>
                        <title>Our Story</title>
                    </Helmet>
                    <MarketingPageSection style={{ textAlign: 'center' }}>
                        <Heading
                            fontSize={[5, 6, 6]}
                            color="black"
                            style={{ margin: isMobile ? '64px 0 0 0' : '160px 0 0 0' }}
                        >
                            Our Story
                        </Heading>
                        <Box style={{ margin: isMobile ? '32px 0 32px 0' : '32px 0 32px 0' }}>
                            Small teams, big results
                        </Box>

                        <Box style={{ maxWidth: '800px', margin: 'auto' }}>
                            <p style={{ textAlign: 'left' }}>
                                <br />
                                We've been running User Research studies for may companies and start-ups, but too much
                                time was being wasted by the "management" of these studies. We were not satisfied with
                                context switching between different tools for e-mailing, screening, scheduling,
                                payments, etc.
                                <br />
                                <br />
                                <em>We lost too much energy and momentum doing operations work.</em>
                                <br />
                                <br />
                                With Panelfox, we want to make it faster and easier to run and manage User Research for
                                your product, so that you can get all those insights without all the lost time.
                                <br />
                                <br />
                                If you have any feedback or ideas you can reach us at{' '}
                                <a href="mailto:support@panelfox.io">support@panelfox.io</a>. We would love to hear from
                                you!
                            </p>
                            <br />
                        </Box>
                    </MarketingPageSection>

                    <MarketingFooter props={this.props} />
                </MarketingPageWrapper>
            </Flex>
        );
    }
}

export default OurStoryPage;
