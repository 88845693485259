import React from 'react';
import Redirect from 'components/Redirect';

function ZoomSupportPage() {
    return (
        <Redirect
            location="https://panelfox.freshdesk.com/support/solutions/articles/69000112732-zoom-integration"
            text="Redirecting to Freshdesk..."
        />
    );
}

export default ZoomSupportPage;
