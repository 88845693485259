import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useToasts } from 'react-toast-notifications';
import H1 from 'components/H1';
import { Flex, Box, Button } from 'rebass';
import studies_service from 'services/studies';
import services from 'services/services';
import { FiTrash2 } from 'react-icons/fi';
import moment from 'moment-timezone';

import AppPageWrapper from 'components/AppPageWrapper';
import AppPageWrapperSectionHeader from 'components/AppPageWrapper/AppPageWrapperSectionHeader';
import AppPageWrapperSectionBody from 'components/AppPageWrapper/AppPageWrapperSectionBody';
import StudyPageSubNav from 'components/Study/StudyPageSubNav';
import ItemWrapper from 'components/ItemWrapper';
import { SCREENER_WHO_CAN_TAKE } from 'utils/constants';
import BreadcrumbBack, { BreadcrumbTitle } from 'components/BreadcrumbBack';
import useQuery from 'hooks/useQuery';
import cache from 'utils/cache';
import NewScreener from 'components/Screener/NewScreener';
import helpersScreener from 'utils/helpers-screener';

function StudyScreenersList(props) {
    const studyId = props.match.params.id;
    const [screeners, setScreeners] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const { addToast } = useToasts();
    const { data: study, isLoading: isStudyLoading } = useQuery({
        queryFn: ({ studyId }) => studies_service.getStudy(studyId),
        variables: {
            studyId: studyId
        },
        onSuccess: study => cache.set(`study.${studyId}`, study),
        defaultData: cache.get(`study.${studyId}`),
        onError: (_, errorText) => {
            addToast(errorText, {
                appearance: 'error',
                autoDismiss: true
            });
        }
    });

    useEffect(() => {
        xhrGetStudyScreeners();
    }, []);

    function xhrGetStudyScreeners() {
        studies_service
            .getScreeners(studyId)
            .then(screeners => {
                setScreeners(screeners);
                setIsLoading(false);
            })
            .catch(error => {
                setIsLoading(false);
                const errorText = services.parseAndTrackXhrErrors(error);

                addToast(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    function deleteScreenerXHR(screener) {
        console.log(screener);
        if (!confirm('Are you sure you want to delete this screener?')) {
            return;
        }

        studies_service
            .deleteScreener(screener.study_id, screener.id)
            .then(study => {
                xhrGetStudyScreeners();
                addToast('Successfully deleted', {
                    appearance: 'success',
                    autoDismiss: true
                });
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);

                addToast(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    return (
        <Flex style={{ width: '100%' }}>
            <AppPageWrapper>
                <Helmet>
                    <title>Screeners</title>
                </Helmet>
                <AppPageWrapperSectionHeader className="experience experience-no-subnav">
                    <Box>
                        <BreadcrumbBack to="/studies">Back to all studies</BreadcrumbBack>
                        <BreadcrumbTitle title={study && study.title} isLoading={isStudyLoading} />
                    </Box>
                </AppPageWrapperSectionHeader>
                <Flex>
                    <StudyPageSubNav study_id={studyId} study={study} />
                </Flex>
                <AppPageWrapperSectionHeader className="experience">
                    <Box mt={3}>
                        <H1>Study Screeners</H1>

                        <Box className="experience-description">
                            Create pre-screeners, phone-screeners, confirmations, and more.
                        </Box>
                    </Box>
                    <Box mt={3}>
                        <NewScreener studyId={studyId} auth={props.auth} />
                    </Box>
                </AppPageWrapperSectionHeader>
                <AppPageWrapperSectionBody>
                    <Box m={4} width="100%">
                        <Box className="text-primary medium fs-18">
                            {!isLoading && (
                                <>
                                    {screeners.length} {screeners.length == 1 ? 'screener' : 'screeners'} matched
                                </>
                            )}
                        </Box>

                        <ItemWrapper className="header-row">
                            <Box width={1 / 2}>Title & Description</Box>
                            <Box width={1 / 4}>Who can take the screener?</Box>
                            <Box width={1 / 8}>Created</Box>
                            <Box width={1 / 8} sx={{ textAlign: 'right' }}>
                                Actions
                            </Box>
                        </ItemWrapper>
                        {!isLoading && screeners && screeners.length == 0 && (
                            <Box mt={4} mx={3}>
                                No results, create your first screener!
                            </Box>
                        )}
                        {screeners.map(screener => {
                            return (
                                <Link to={`/studies/${studyId}/screeners/${screener.id}`}>
                                    <ItemWrapper key={screener.id}>
                                        <Box width={1 / 2}>
                                            <Link to={`/studies/${studyId}/screeners/${screener.id}`} className="muted">
                                                {helpersScreener.isExternalSurveyConnected(screener) && (
                                                    <img
                                                        src="/qualtrics-16x16.svg"
                                                        alt="Qualtrics"
                                                        style={{ marginRight: '6px' }}
                                                    />
                                                )}
                                                {screener.title ? screener.title : '(no title)'}
                                            </Link>
                                            <Box
                                                style={{ width: '100%' }}
                                                mt={1}
                                                className="ellipsis text-secondary fs-12"
                                            >
                                                {screener.description}
                                            </Box>
                                        </Box>
                                        <Box width={1 / 4} className="text-primary">
                                            {SCREENER_WHO_CAN_TAKE[screener.participant_external]}
                                        </Box>

                                        <Box width={1 / 8} className="text-primary">
                                            {moment.utc(screener.created_at).format('MMMM D, YYYY')}
                                        </Box>
                                        <Box width={1 / 8} style={{ textAlign: 'right' }}>
                                            {/*<Link to={`/studies/${studyId}/screeners/${screener.id}`}><Button type='button' variant='secondary-gray' mr={2} ><FiEdit /> Edit</Button></Link>*/}
                                            <Button
                                                type="button"
                                                variant="secondary-gray"
                                                className="secondary-icon delete-screener"
                                                onClick={e => {
                                                    e.preventDefault();
                                                    e.stopPropagation();
                                                    deleteScreenerXHR(screener);
                                                }}
                                            >
                                                <FiTrash2 />
                                            </Button>
                                        </Box>
                                    </ItemWrapper>
                                </Link>
                            );
                        })}
                    </Box>
                </AppPageWrapperSectionBody>
            </AppPageWrapper>
        </Flex>
    );
}

export default StudyScreenersList;
