import React from 'react';
import styled, { css } from 'styled-components';

const StyledGrid = styled.div`
    display: grid;
    grid-template-columns: repeat(${props => props.col || 2}, 1fr);
    gap: 32px;
    margin-bottom: 32px;

    ${props =>
        props.sm &&
        css`
            @media screen and (max-width: 768px) {
                grid-template-columns: repeat(${props => props.sm || 1}, 1fr);
            }
        `}

    ${props =>
        props.md &&
        css`
            @media screen and (min-width: 768px) {
                grid-template-columns: repeat(${props => props.md || 4}, 1fr);
            }
        `}

    ${props =>
        props.xs &&
        css`
            @media screen and (max-width: 576px) {
                grid-template-columns: repeat(${props => props.xs || 1}, 1fr);
            }
        `}
`;

/**
 * Grid
 *
 * @param {Object} props Props
 * @param {number} props.col number of columns
 * @param {number} props.md number of columns for medium screen
 * @param {number} props.sm number of columns for small screen
 * @param {number} props.xs number of columns for extra small screen
 * @param {Object} props.css styles
 * @returns {React.Component} The component
 */
function Grid(props) {
    return <StyledGrid {...props} />;
}

export default Grid;
