import services from 'services/services';
import { withToastManager } from 'react-toast-notifications';
import people_service from 'services/people';
import React from 'react';
import { Flex, Box, Button } from 'rebass';
import { Textarea, Label, Input, Select, Checkbox } from '@rebass/forms';
import {
    PERSON_KEY_TYPES,
    INTEGRATION_SALESFORCE,
    PERSON_KEY_TYPE_TEXT,
    PERSON_KEY_TYPE_CHECKBOX,
    PERSON_KEY_TYPE_DROPDOWN
} from 'utils/constants';
import { FiXCircle, FiPlus, FiUsers } from 'react-icons/fi';
import ListWrapper from 'components/ListWrapper';
import ItemWrapper from 'components/ItemWrapper';
import TooltipIcon from 'components/TooltipIcon';
import IntegrationLabel from 'components/IntegrationLabel';
import ReactTooltip from 'react-tooltip';
import ReactModal from 'react-modal';
import ReactModalActions from 'components/ReactModalActions';
import H1 from 'components/H1';
import NiceModal from 'components/NiceModal';
import { MovableOptions } from './MovableOptions';
class CreatePersonDataKey extends React.Component {
    /**
     * @param {Object} props
     * @param {string} props.defaultGroupId Default group ID
     */
    constructor(props) {
        super(props);

        this.state = {
            title: props.placeholder ? props.placeholder : '',
            groupId: props.defaultGroupId,
            type: 'string',
            pii_masked: false,
            definition: [],
            error_text: '',
            ...this.props.edit,
            bulkProperties: [],
            showBulkModal: false,
            panelPropertiesGroups: [],
            salesforceIntegration: this.props.edit
                ? this.props.edit.integrations.find(i => i.external === INTEGRATION_SALESFORCE)
                : undefined
        };

        this.submit = this.submit.bind(this);
    }

    // show only allowed property types
    isAllowedType(pkt) {
        return (
            !this.state.id || // adding new property
            this.state.type === pkt.type || // is current property type
            (this.state.type != PERSON_KEY_TYPE_TEXT && pkt.type != PERSON_KEY_TYPE_TEXT) // prevent changing property type to/from long text
        );
    }

    isValid() {
        const title = this.state.title.trim();

        if (title.length == 0 || !this.state.type) {
            return false;
        }
        return true;
    }

    isInt(value) {
        const number = Number(value);
        return !isNaN(number) && Number.isInteger(number);
    }

    // generate unique value for defenition
    generateUniqueValue(existing_definition) {
        // get existing integer values and map them to numbers
        const all_values = existing_definition.filter(item => this.isInt(item.value)).map(item => Number(item.value));

        // sort numerically
        all_values.sort(function(a, b) {
            return a - b;
        });

        let unique_value = 1;
        all_values.forEach(val => {
            if (val === unique_value) {
                unique_value++;
            }
        });

        return `${unique_value}`;
    }

    addDefinitionItem(label, value) {
        let d = this.state.definition;
        if (!value) {
            value = this.generateUniqueValue(d);
        }
        d.push({ label: label, value: value, creating: 1 });
        this.setState({ definition: d });
    }

    /**
     * Fetch panel properties groups
     */
    async fetchPanelPropertiesGroups() {
        const { toastManager } = this.props;

        try {
            // Fetch panel properties groups
            const panelPropertiesGroups = await services.getPanelPropertiesGroups();

            // Set default group ID if not set
            this.setState(prevState => {
                let groupId = prevState.groupId;

                // check if group is not set and there are groups
                if (groupId == null && panelPropertiesGroups.length > 0) {
                    groupId = panelPropertiesGroups[0].id;
                }

                return {
                    panelPropertiesGroups,
                    groupId
                };
            });
        } catch (error) {
            const error_text = services.parseAndTrackXhrErrors(error);
            toastManager.add(error_text, {
                appearance: 'error',
                autoDismiss: true
            });
        }
    }

    addOptionsBulk(e) {
        e.preventDefault();
        this.setState({ showBulkModal: false });

        const that = this;
        let new_options = [];

        if (this.state.bulkProperties) {
            let arr = this.state.bulkProperties.split(/\r?\n/);
            if (arr.length) {
                let d = this.state.definition;
                arr.forEach(text_line => {
                    text_line = text_line.trim();
                    if (text_line && text_line.length > 0) {
                        new_options.push({
                            label: text_line,
                            value: this.generateUniqueValue(d.concat(new_options)),
                            creating: 1
                        });
                    }
                });

                d = d.concat(new_options);
                //console.log(d, new_options);
                this.setState({ definition: d, bulkProperties: [] });
            }
        }
    }

    insertUniqueColumnValuesXHR() {
        const { toastManager } = this.props;
        const that = this;
        //setEditColumnLoading(true);
        people_service
            .getUniqueColumnValues(this.state.id)
            .then(columnValues => {
                toastManager.add('Successfully found column values', {
                    appearance: 'success',
                    autoDismiss: true
                });
                //console.log(columnValues);
                columnValues.forEach(unique_column_value => {
                    const found_existing = that.state.definition.find(d => d.value == unique_column_value);
                    if (!found_existing) {
                        that.addDefinitionItem(unique_column_value, unique_column_value);
                    } else {
                        console.log('skipping ', found_existing);
                    }
                });
            })
            .catch(error => {
                const error_text = services.parseAndTrackXhrErrors(error);
                toastManager.add(error_text, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    updateColumnXHR(column) {
        const { toastManager } = this.props;
        const that = this;
        //setEditColumnLoading(true);
        people_service
            .updateColumn({
                id: column.id,
                title: column.title,
                type: column.type,
                group_id: this.state.groupId,
                pii_masked: column.pii_masked,
                definition: column.definition,
                options_az: this.state.options_az
            })
            .then(savedColumn => {
                toastManager.add('Successfully saved column', {
                    appearance: 'success',
                    autoDismiss: true
                });
                that.props.onSuccess(savedColumn);
            })
            .catch(error => {
                const error_text = services.parseAndTrackXhrErrors(error);
                toastManager.add(error_text, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    submit(e) {
        e.preventDefault();
        this.setState({ error_text: '' });
        const that = this;
        const { toastManager } = this.props;

        if (this.state.id) {
            this.updateColumnXHR(this.state);
        } else {
            services
                .postCustomDataColumnsXHR({
                    title: this.state.title,
                    type: this.state.type,
                    group_id: this.state.groupId,
                    pii_masked: this.state.pii_masked,
                    definition: this.state.definition,
                    options_az: this.state.options_az
                })
                .then(customDataColumn => {
                    that.props.onSuccess(customDataColumn);
                    toastManager.add('Successfully created property', {
                        appearance: 'success',
                        autoDismiss: true
                    });
                })
                .catch(error => {
                    const error_text = services.parseAndTrackXhrErrors(error);
                    toastManager.add(error_text, {
                        appearance: 'error',
                        autoDismiss: true
                    });
                });
        }
    }

    componentDidMount() {
        this.fetchPanelPropertiesGroups();
    }

    render() {
        let definition = this.state.definition;
        /* if (this.state.options_az && definition && definition.length) {
            definition.sort(function(a, b) {
                a = a.label.toUpperCase();
                b = b.label.toUpperCase();

                // equal items sort equally
                if (a === b) {
                    return 0;
                }
                // nulls sort after anything else
                else if (!a) {
                    return 1;
                }
                else if (!b) {
                    return -1;
                }
                // otherwise, if we're ascending, lowest sorts first
                else {
                    return a < b ? -1 : 1;
                }
            });
        } */
        const propertyManagedBySalesforce = this.state.salesforceIntegration !== undefined;
        const isEditable =
            !this.props.edit || (this.props.edit && this.props.edit.can_delete && !propertyManagedBySalesforce);

        return (
            <form onSubmit={this.submit}>
                {this.state.error_text && (
                    <Box mb={2} style={{ color: 'red' }}>
                        {this.state.error_text}
                    </Box>
                )}
                <Flex justifyContent="space-between">
                    <Label htmlFor="add_custom_column_title">Title</Label>
                    {propertyManagedBySalesforce && (
                        <IntegrationLabel
                            integration={INTEGRATION_SALESFORCE}
                            labelText={this.state.salesforceIntegration.external_id}
                            tooltipId={`tooltip-edit-salesforce-${this.state.id}`}
                            tooltipContent={
                                <Box>
                                    This property is linked to the <br />
                                    Salesforce {this.state.salesforceIntegration.external_id}. <br />
                                    {(this.state.type === PERSON_KEY_TYPE_DROPDOWN ||
                                        this.state.type === PERSON_KEY_TYPE_CHECKBOX) && (
                                        <>
                                            You are not able to edit the <br />
                                            Type and Options.
                                        </>
                                    )}
                                </Box>
                            }
                        />
                    )}
                </Flex>
                <Input
                    type="text"
                    mb={3}
                    required
                    autoFocus
                    autoComplete="off"
                    name="add_custom_column_title"
                    placeholder="Nationality"
                    value={this.state.title}
                    disabled={!isEditable}
                    onChange={e => this.setState({ title: e.target.value })}
                />
                <Label>Type</Label>
                <Select
                    mb={3}
                    name="add_custom_column_type"
                    value={this.state.type}
                    onChange={e => this.setState({ type: e.target.value })}
                    disabled={!isEditable}
                >
                    {PERSON_KEY_TYPES.filter(pkt => this.isAllowedType(pkt)).map(pkt => (
                        <option value={pkt.type}>{pkt.title}</option>
                    ))}
                </Select>
                <Label htmlFor="group-id-select">Group</Label>
                <Select
                    id="group-id-select"
                    mb={3}
                    name="add_custom_column_type"
                    value={this.state.groupId}
                    onChange={e => this.setState({ groupId: e.target.value })}
                >
                    {this.state.panelPropertiesGroups.map(group => (
                        <option value={group.id}>{group.name}</option>
                    ))}
                </Select>
                {(this.state.type === PERSON_KEY_TYPE_DROPDOWN || this.state.type === PERSON_KEY_TYPE_CHECKBOX) && (
                    <>
                        <Label>Options</Label>
                        <ListWrapper
                            style={{
                                position: 'relative',
                                border: '1px solid #eee',
                                borderRadius: '4px'
                            }}
                        >
                            <ItemWrapper className="header-row-dark" style={{ padding: '8px 16px', minHeight: '32px' }}>
                                <Box width={1 / 2}>
                                    Label
                                    <TooltipIcon for={`tooltip-label`} margin="0px 0 0 4px" />
                                </Box>
                                <Box width={1 / 2}>
                                    ID
                                    {/*<TooltipIcon for={`tooltip-value`} margin="0px 0 0 4px" />*/}
                                </Box>
                            </ItemWrapper>
                            <MovableOptions
                                options={this.state.definition}
                                onChange={defs => this.setState({ definition: defs })}
                                isMovable={!this.state.options_az && isEditable}
                                isEditable={isEditable}
                            />
                            {isEditable && (
                                <ItemWrapper style={{ border: 0, flexDirection: 'column', alignItems: 'flex-start' }}>
                                    <Button
                                        type="button"
                                        variant="transparent"
                                        onClick={e => {
                                            this.addDefinitionItem('', '');
                                        }}
                                    >
                                        <FiPlus /> Add option
                                    </Button>
                                    <Button
                                        type="button"
                                        variant="transparent"
                                        onClick={e => this.setState({ showBulkModal: true })}
                                    >
                                        <FiPlus /> Add bulk
                                    </Button>

                                    {this.state.id && (
                                        <>
                                            <br />
                                            <Button
                                                type="button"
                                                variant="transparent"
                                                onClick={e => this.insertUniqueColumnValuesXHR()}
                                            >
                                                <FiUsers /> Populate options using panelist data
                                            </Button>
                                        </>
                                    )}
                                </ItemWrapper>
                            )}

                            <ReactTooltip
                                id="tooltip-label"
                                effect="solid"
                                place="top"
                                place="right"
                                multiline={true}
                                style={{ maxWidth: '200px' }}
                            >
                                This will show up as the selectable value.
                            </ReactTooltip>
                            <ReactTooltip
                                id="tooltip-value"
                                effect="solid"
                                place="top"
                                multiline={true}
                                style={{ maxWidth: '200px' }}
                            >
                                This is the actual value stored in the DB,
                                <br />
                                and it can be different from the "Label".
                                <br />
                                (e.g. Label = California, then Value = CA)
                            </ReactTooltip>
                        </ListWrapper>

                        <Box mt={3}>
                            {/* <Input
                                mb={3}
                                required
                                autoFocus
                                autoComplete="off"
                                name="add_custom_column_title"
                                placeholder="Nationality"
                                value={this.state.title}
                                onChange={e => this.setState({ title: e.target.value })}
                                required
                            /> */}
                            {isEditable && (
                                <Label style={{ width: '250px', cursor: 'pointer' }}>
                                    <Checkbox
                                        name="options_az"
                                        checked={this.state.options_az == 1}
                                        onChange={e => this.setState({ options_az: e.target.checked })}
                                    />
                                    <span style={{ padding: '4px 0 0 0' }}>Sort options alphabetically</span>
                                </Label>
                            )}
                        </Box>
                    </>
                )}

                <Label>
                    <Checkbox
                        name="pii_masked"
                        checked={this.state.pii_masked}
                        onChange={e => this.setState({ pii_masked: e.target.checked })}
                        value={this.state.pii_masked}
                    />
                    <span style={{ padding: '4px 0 0 0' }}>
                        PII Masking{' '}
                        <a href="#" data-beacon-article="65256c359c4c0a6240b2ddf8" className="help-question">
                            ?
                        </a>
                    </span>
                </Label>

                <Box className={`modal-actions ${this.state.id ? 'modal-actions-sticky' : ''}`}>
                    <Button type="button" variant="secondary-gray" mr={3} onClick={this.props.onClose}>
                        Cancel
                    </Button>

                    <Button type="submit" mr={0} className="modal-primary">
                        {this.state.id ? 'Update' : 'Create'} property
                    </Button>
                </Box>
                <NiceModal
                    isOpen={this.state.showBulkModal}
                    shouldCloseOnOverlayClick
                    onRequestClose={() => this.setState({ showBulkModal: false })}
                    title="Add bulk properties"
                >
                    <Box>
                        <Label>Use a new line for each property</Label>
                        <Textarea
                            autoFocus
                            value={this.state.bulkProperties}
                            onChange={e => this.setState({ bulkProperties: e.target.value })}
                            height="120px"
                        />
                    </Box>
                    <Box className="modal-actions">
                        <Button
                            type="button"
                            variant="secondary-gray"
                            mr={3}
                            onClick={() => this.setState({ showBulkModal: false })}
                        >
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            variant="primary"
                            className="modal-primary"
                            mr={0}
                            onClick={e => this.addOptionsBulk(e)}
                        >
                            Add properties
                        </Button>
                    </Box>
                </NiceModal>
            </form>
        );
    }
}

export default withToastManager(CreatePersonDataKey);
