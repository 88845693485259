/*
 * MePage
 *
 * This is the first thing users see of our App, at the '/' route
 */

import React, { useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import xhr from 'xhr.js';
import config from 'config';
import { withToastManager } from 'react-toast-notifications';
import { Link } from 'react-router-dom';
import services from 'services/services';

import auth from 'services/auth';
import LoadingIndicator from 'components/LoadingIndicator';
import H1 from 'components/H1';
import H2 from 'components/H2';
import ListWrapper from 'components/ListWrapper';
import { Box, Button, Flex } from 'rebass';
import { Label, Input } from '@rebass/forms';
import InputSearch from 'components/InputSearch';
import NiceWrapper from 'components/NiceWrapper';
import StudyItem from 'components/Study/StudyItem';
import ParticipantItem from 'components/Participant/ParticipantItem';
import ItemWrapper from 'components/ItemWrapper';

import AppPageWrapper from 'components/AppPageWrapper';
import AppPageWrapperSectionHeader from 'components/AppPageWrapper/AppPageWrapperSectionHeader';
import AppPageWrapperSectionBody from 'components/AppPageWrapper/AppPageWrapperSectionBody';

class SearchPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            searchKeyword: this.props.match.params.search_kw ? this.props.match.params.search_kw : '',
            searchResults: []
        };

        this.onSearchKeywordChange = this.onSearchKeywordChange.bind(this);

        if (this.state.searchKeyword.length > 0) {
            this.searchXHR();
        }
    }

    componentDidMount() {
        window.onpopstate = e => {
            if (this.props.match.params.search_kw) {
                this.setState({ searchKeyword: this.props.match.params.search_kw });
                this.searchXHR();
            }
        };
    }

    onSearchKeywordChange(e) {
        //console.log(e);
        this.setState({ searchKeyword: e.target.value });

        // detect change, and clear timeout if exists
        if (this.searchDelay) {
            clearTimeout(this.searchDelay);
        }

        // set timer for 200ms
        this.searchDelay = setTimeout(
            function() {
                this.searchXHR();
            }.bind(this),
            300
        );
    }

    searchXHR() {
        console.log('searching!!!');

        history.pushState(null, null, `/search/${this.state.searchKeyword}`);

        const { toastManager } = this.props;

        xhr.get(`/search?search_kw=${this.state.searchKeyword}&account_id=${this.props.auth.account.id}`, {
            withCredentials: true
        })
            .then(response => {
                const searchResults = response.data;

                console.log(searchResults);

                this.setState({ searchResults });
            })
            .catch(error => {
                services.parseAndTrackXhrErrors(error);
                let errorText = 'Error';
                if (error.response) {
                    errorText = '';

                    for (const property in error.response.data) {
                        errorText += `${error.response.data[property][0]} `;
                    }
                }

                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    render() {
        let renderTags = [];
        if (this.state.searchResults.tags) {
            renderTags.push(<h3>Tags</h3>);
            if (this.state.searchResults.tags.length == 0) {
                renderTags.push(<div>0 results</div>);
            } else {
                this.state.searchResults.tags.forEach(t => {
                    renderTags.push(<ItemWrapper>{t.title}</ItemWrapper>);
                });
            }
        }

        let renderParticipants = [];
        if (this.state.searchResults.people) {
            renderParticipants.push(<h3>Participants</h3>);
            if (this.state.searchResults.people.length == 0) {
                renderParticipants.push(<div>0 results</div>);
            } else {
                this.state.searchResults.people.forEach(i => {
                    renderParticipants.push(
                        <ItemWrapper>
                            <ParticipantItem participant={i} auth={this.props.auth} />
                        </ItemWrapper>
                    );
                });
            }
        }

        let renderStudies = [];
        if (this.state.searchResults.studies) {
            renderStudies.push(<h3>Studies</h3>);
            if (this.state.searchResults.studies.length == 0) {
                renderStudies.push(<div>0 results</div>);
            } else {
                this.state.searchResults.studies.forEach(i => {
                    renderStudies.push(<StudyItem {...i} />);
                });
            }
        }

        let renderNotes = [];
        if (this.state.searchResults.studies_people) {
            renderNotes.push(<h3>Notes</h3>);
            if (this.state.searchResults.studies_people.length == 0) {
                renderNotes.push(<div>0 results</div>);
            } else {
                this.state.searchResults.studies_people.forEach(i => {
                    if (!i.notes) {
                        return;
                    }
                    const person_notes = JSON.parse(i.notes);
                    const person_notes_plain = person_notes.blocks
                        .map(block => (!block.text.trim() && '\n') || block.text)
                        .join('\n');

                    // get search excerpt
                    const location = person_notes_plain.search(this.state.searchKeyword);

                    renderNotes.push(
                        <ItemWrapper>
                            <Link to={`/studies/${i.study_id}/participants/${i.person.id}`}>
                                {i.person.deleted_at ? '(Deleted)' : ''} {i.person.first_name} {i.person.last_name}
                            </Link>
                            <div style={{ background: 'yellow' }}>
                                {person_notes_plain.substring(location - 30, location + 30)}
                            </div>
                        </ItemWrapper>
                    );
                });
            }
        }

        return (
            <AppPageWrapper>
                <Helmet>
                    <title>Search</title>
                </Helmet>

                <AppPageWrapperSectionHeader>
                    <H1>Search (CMD⌘ + K)</H1>
                </AppPageWrapperSectionHeader>
                <AppPageWrapperSectionBody>
                    <Box m={4} flex="1 1 0%">
                        <InputSearch
                            value={this.state.searchKeyword}
                            onChange={this.onSearchKeywordChange}
                            autoFocus="on"
                        />

                        <Flex mt={3} flexWrap="wrap" mx={-2}>
                            <Box width={1 / 4} px={2}>
                                {renderTags}
                            </Box>
                            <Box width={1 / 4} px={2}>
                                {renderParticipants}
                            </Box>
                            <Box width={1 / 4} px={2}>
                                {renderStudies}
                            </Box>
                            <Box width={1 / 4} px={2}>
                                {renderNotes}
                            </Box>
                        </Flex>
                    </Box>
                </AppPageWrapperSectionBody>
            </AppPageWrapper>
        );
    }
}

export default withToastManager(SearchPage);
