/**
 *
 * App
 *
 * This component is the skeleton around the actual pages, and should only
 * contain code that should be seen on all pages. (e.g. navigation bar)
 */

const env = process.env.NODE_ENV;
import React, { Suspense } from 'react';
import { Helmet } from 'react-helmet';
import { Switch, Route } from 'react-router-dom';
import { ThemeProvider } from 'emotion-theming';
import preset from '@rebass/preset';
import merge from 'deepmerge';
import auth from 'services/auth';
import qs from 'qs';
import { Button, Box, Flex } from 'rebass';
import config from 'config';
import analytics from 'utils/analytics';
import helpers from 'utils/helpers';

import { ToastProvider } from 'react-toast-notifications';
import ReactModal from 'react-modal';
import ReactModalDefaultStyles from 'styles/react-modal';
import RequireAuth from 'containers/App/RequireAuth';
import NoAuth from 'containers/App/NoAuth';
import Raw from 'containers/App/Raw';

import MicrosoftVerificationPage from 'containers/VerificationPages/MicrosoftVerificationPage';
import SSLVerificationPage from 'containers/VerificationPages/SSLVerificationPage';
import SurveyViewPage from 'containers/SurveyViewPage';
import SearchPage from 'containers/SearchPage';
import PrivacyPage from 'containers/PrivacyPage';
import StudiesPage from 'containers/StudiesPage';
import StudyPage from 'containers/StudyPage';
import StudyNewTablePage from 'containers/StudyPage/StudyNewTablePage';
import StudyRecruitPage from 'containers/StudyRecruitPage';
import StudyScreenerIndividual from 'containers/StudyScreenersPage/StudyScreenerIndividual';
import StudyScreenersList from 'containers/StudyScreenersPage/StudyScreenersList';
import StudyCampaignsPage from 'containers/StudyCampaignsPage';
import StudyReportsPage from 'containers/StudyReportsPage';
import StudyTagsPage from 'containers/StudyTagsPage';
import StudySettingsPage from 'containers/StudySettingsPage';
import StudyQuotasPage from 'containers/StudyQuotasPage';
import StudySchedulingPage from 'containers/StudySchedulingPage';
import ParticipantsPage from 'containers/ParticipantsPage';
import ParticipantsPanelsPage from 'containers/ParticipantsPage/ParticipantsPanelsPage';
import ParticipantsPanelsEditPage from 'containers/ParticipantsPage/ParticipantsPanelsEditPage';
import PanelDashboardPage from 'containers/PanelsPage/PanelDashboardPage';
import ParticipantsDataPage from 'containers/ParticipantsPage/ParticipantsDataPage';
import TagsPage from 'containers/TagsPage';
import ParticipantsIncentivesPage from 'containers/ParticipantsPage/ParticipantsIncentivesPage';
import PanelSignupForm from 'containers/ParticipantsPage/PanelSignupForm';
import PanelSignupFormsList from 'containers/ParticipantsPage/PanelSignupFormsList';
import ParticipantPage from 'containers/ParticipantPage';
import SettingsPage from 'containers/SettingsPage';
import SettingsUsersPage from 'containers/SettingsPage/SettingsUsersPage';
import SettingsBillingPage from 'containers/SettingsPage/SettingsBillingPage';
import SettingsIntegrationsPage from 'containers/SettingsPage/SettingsIntegrationsPage';
import SettingsEmailTemplatesPage from 'containers/SettingsPage/SettingsEmailTemplatesPage';
import SettingsEmailTemplateIndividualPage from 'containers/SettingsPage/SettingsEmailTemplateIndividualPage';
import SettingsUsagePage from 'containers/SettingsPage/SettingsUsagePage';
import SettingsDeveloperPage from 'containers/SettingsPage/SettingsDeveloperPage';
import NotFoundPage from 'containers/NotFoundPage';
import LoginPage from 'containers/LoginPage';
import ForgotPasswordPage from 'containers/ForgotPasswordPage';
import ProductPage from 'containers/ProductPage';
import OurStoryPage from 'containers/OurStoryPage';
import PricingPage from 'containers/PricingPage';
import MePage from 'containers/MePage';
import PublicScreenPage from 'containers/PublicScreenPage';
import PublicSchedulingPage from 'containers/PublicSchedulingPage';
import PublicSchedulingResultPage from 'containers/PublicSchedulingPage/PublicSchedulingResultPage';
import BetaPage from 'containers/BetaPage';
import TestPage from 'containers/TestPage';
import DemoSchedulingPage from 'containers/DemoSchedulingPage';
import ZoomSetupPage from 'containers/ZoomSetupPage';
import SupportPage from 'containers/SupportPage';
import ZoomSupportPage from 'containers/ZoomSupportPage';
import DashboardPage from 'containers/DashboardPage';
import SecurityPage from 'containers/SecurityPage';
import CaseStudiesPage from 'containers/CaseStudiesPage';
import ConsentManagementPage from 'containers/ConsentManagementPage';
import ClientGrid from 'containers/ClientGrid/ClientGrid';
import PanelistLogin from 'containers/PanelistExperience/PanelistLogin';
import PanelistSignup from 'containers/PanelistExperience/PanelistSignup';
import PanelistForgotPassword from 'containers/PanelistExperience/PanelistForgotPassword';
import SetNewPassword from 'containers/SetNewPassword';
import PanelistProfile from 'containers/PanelistExperience/PanelistProfile';
import EmailCampaignPage from 'containers/MiscPages/EmailCampaignPage';
import DocsRedirect from 'containers/DocsRedirect';
import { CustomToast, CustomToastContainer } from 'components/CustomToast';
import { PromptProvider } from 'components/PromptProvider';
import ParticipantFormPage from 'containers/ParticipantFormPage';
import { isProduction } from 'config';

import GlobalStyle from 'styles/global-styles';
import 'styles/react-form-builder.css';
import 'styles/react-datepicker.css';
import ActionPopup from 'components/ActionPopup';
import EventEmitter from 'utils/events';
//import '../node_modules/react-vis/dist/style.css';
import 'styles/react-vis.css';

const ComponentEncyclopediaPage = React.lazy(() => import('containers/ComponentEncyclopediaPage'));

import Pusher from 'pusher-js';
window.pusher = null;

const theme = merge(preset, {
    fonts: {
        body: 'Inter, sans-serif',
        heading: 'inherit',
        monospace: 'Menlo, monospace'
    },
    lineHeights: {
        body: 1.5,
        heading: 1.1
    },
    colors: {
        black: '#000',
        amber: '#ffa040',
        lightgrey: '#666',
        red: '#b71c1c',
        primary: '#0A73EB'
    },
    forms: {
        input: {
            //background: '#fbfafc',
            background: '#fff',
            boxShadow: 'rgba(36, 18, 77, 0.08) 0px 0px 0px 1px',
            border: '0',
            fontSize: '14px',
            color: '#000',
            height: '32px',
            padding: '8px 12px',
            ':disabled': {
                color: '#687180'
            },
            ':hover': {
                outline: 'none',
                boxShadow: 'rgba(10, 115, 235, 0.5) 0px 0px 0px 1px'
            },
            ':focus': {
                outline: 'none',
                boxShadow: '#0A73EB 0px 0px 0px 1px'
            },
            '::placeholder': {
                color: '#717886'
            },
            '&.nice-underlined': {
                background: 'transparent',
                borderRadius: '4px 4px 0 0',
                //boxShadow: 'none',
                //border: '1px solid #0A73EB',
                padding: '8px 16px'
            },
            '&.huge': {
                padding: '4px 8px',
                height: 'auto',
                fontSize: '16px'
            },
            '&.error': {
                boxShadow: 'rgba(255, 0, 0, .5) 0px 0px 0px 1px !important'
            },
            '&.input-darker, .form-wrapper2 &, .new-input-wrapper &': {
                background: '#FAFAFA',
                border: '1px solid rgba(73, 95, 120, 0.12)',
                boxShadow: 'none',
                ':hover': {
                    outline: 'none',
                    borderColor: '#0A73EB'
                },
                ':focus': {
                    outline: 'none',
                    borderColor: '#0A73EB'
                }
            }
        },
        textarea: {
            //background: '#fbfafc',
            background: '#fff',
            boxShadow: 'rgba(36, 18, 77, 0.08) 0px 0px 0px 1px',
            border: '0',
            fontSize: '14px',
            color: '#000',
            padding: '8px 12px',
            margin: 0,
            '::placeholder': {
                color: '#929DB0'
            },
            ':hover': {
                outline: 'none',
                boxShadow: 'rgba(10, 115, 235, 0.5) 0px 0px 0px 1px'
            },
            ':focus': {
                outline: 'none',
                boxShadow: '#0A73EB 0px 0px 0px 1px'
            },
            '&.input-darker, .form-wrapper2 &, .new-input-wrapper &': {
                background: '#FAFAFA',
                border: '1px solid rgba(73, 95, 120, 0.12)',
                boxShadow: 'none',
                ':hover': {
                    outline: 'none',
                    borderColor: '#0A73EB'
                },
                ':focus': {
                    outline: 'none',
                    borderColor: '#0A73EB'
                }
            }
        },
        label: {
            margin: '0 0 8px 0',
            color: '#001233',
            fontWeight: 500,
            fontSize: '14px',
            width: 'auto',
            lineHeight: '22px',
            cursor: 'inherit',
            input: {
                verticalAlign: 'top'
            },
            svg: {
                margin: '3px 8px 0 0',
                verticalAlign: 'top',
                fontSize: '16px',
                strokeWidth: '1.5px'
            },
            '&.normal': {
                color: 'black'
            },
            '&.loud': {
                color: 'black',
                fontWeight: 600,
                marginBottom: '8px'
            },
            '&.quiet': {
                color: '#929DB0',
                fontSize: '13px',
                fontWeight: 400,
                lineHeight: '20px'
            },
            '&.for-radio': {
                svg: {
                    margin: '2px 4px 0 -4px !important'
                }
            },
            '&.for-checkbox': {
                verticalAlign: 'top',
                margin: 0,
                display: 'inline-block',
                fontWeight: 400,
                color: '#001233'
            },
            '&.label-basic': {
                background: '#eee',
                color: '#888',
                borderRadius: '4px',
                display: 'inline-block',
                padding: '0px 8px',
                fontSize: '12px',
                fontWeight: 400,
                textAlign: 'center'
            }
        },
        radio: {
            height: '20px',
            color: '#E7E8F1',
            '&:hover': {
                color: '#0A73EB'
            }
        },
        select: {
            flexGrow: 1,
            //background: '#fbfafc',
            background: '#fff',
            border: 0,
            boxShadow: 'rgba(36, 18, 77, 0.08) 0px 0px 0px 1px',
            fontSize: '14px',
            color: '#001233',
            height: '32px',
            padding: '2px 30px 2px 12px',
            cursor: 'pointer',
            outline: 'none',
            '&.error': {
                boxShadow: 'rgba(255, 0, 0, 0.5) 0px 0px 0px 1px'
            },
            '&:disabled': {
                opacity: 0.8,
                cursor: 'not-allowed'
            },
            '&.select-simple': {
                boxShadow: 'none',
                padding: '0 24px 0 0',
                height: 'auto'
            },
            svg: {
                fill: 'red'
            }
        },
        checkbox: {
            //color: '#0A73EB',
            //fill: '#0A73EB',
            color: '#E7E8F1',
            '&.size-tiny': {
                width: '19px',
                borderRadius: '8px',
                overflow: 'hidden'
            }
        },
        switch: {
            cursor: 'pointer',
            color: '#0A73EB',
            borderColor: '#0A73EB',
            outline: 0,
            '&[aria-checked=true]': {
                background: '#0A73EB',
                outline: 0
            },
            thumb: {
                borderColor: '#0A73EB',
                outline: 0
            },
            '&.switch-small': {
                height: '16px',
                width: '32px',
                div: {
                    width: '16px',
                    height: '16px'
                }
            },
            '&.size-tiny': {
                '&:focus': {
                    boxShadow: '0 0 0 0 !important'
                },
                '&[aria-checked=true]': {
                    div: {
                        transform: 'translateX(12px) !important'
                    }
                },
                height: '12px',
                width: '24px',
                div: {
                    width: '12px',
                    height: '12px'
                }
            },
            '&:disabled': {
                opacity: 0.4,
                cursor: 'not-allowed'
            }
        }
    },
    buttons: {
        primary: {
            color: 'white',
            bg: '#0A73EB',
            boxSizing: 'border-box',
            fontSize: '14px',
            textOverflow: 'ellipsis',
            verticalAlign: 'middle',
            whiteSpace: 'nowrap',
            //boxShadow: 'rgba(36, 18, 77, 0.08) 0px 0px 0px 1px, rgba(36, 18, 77, 0.32) 0px 4px 4px -4px',
            boxShadow: 'none',
            '&:hover': {
                backgroundColor: '#085bba'
            },
            '&:active': {
                backgroundColor: '#085bba'
            },
            '&:disabled': {
                backgroundColor: '#E5F7FE'
            },
            fontWeight: 500,
            height: '32px',
            textAlign: 'center',
            width: 'auto',
            margin: '0px',
            outline: 'none',
            overflow: 'hidden',
            transition: 'box-shadow 150ms',
            borderRadius: '4px',
            padding: '0px 32px',
            marginRight: '8px',
            userSelect: 'none',
            cursor: 'pointer',
            svg: {
                margin: '4px 2px 0 0',
                verticalAlign: 'top',
                fontSize: '15px'
            },
            '&.huge': {
                padding: '13px 24px 11px',
                height: 'auto',
                fontSize: '16px',
                svg: {
                    margin: '2px 0 0 4px',
                    verticalAlign: 'top',
                    fontSize: '20px'
                }
            },
            '&.modal-primary': {
                boxShadow: 'none',
                backgroundColor: '#0A73EB',
                '&:hover': {
                    backgroundColor: '#0A73EB'
                },
                '&:active': {
                    backgroundColor: '#085bba'
                },
                '&:disabled': {
                    backgroundColor: '#e7e8f1'
                }
            },
            '&.big': {
                padding: '12px 20px 11px',
                height: 'auto',
                fontSize: '16px',
                svg: {
                    margin: '2px 0 0 4px',
                    verticalAlign: 'top',
                    fontSize: '20px'
                }
            },
            ':disabled': {
                opacity: '0.5',
                cursor: 'not-allowed'
            },
            '&.primary-red': {
                backgroundColor: '#FF0000'
            },
            '&.button-small': {
                fontSize: '12px',
                lineHeight: '17px',
                padding: '4px 12px',
                height: 'auto',
                fontWeight: 600
            }
        },
        secondary: {
            lineHeight: '22px',
            boxSizing: 'border-box',
            fontSize: '14px',
            fontWeight: 500,
            textOverflow: 'ellipsis',
            verticalAlign: 'middle',
            whiteSpace: 'nowrap',
            backgroundColor: 'transparent',
            //backgroundColor: 'rgb(255, 255, 255)',
            border: '1px solid #e7e8f1',
            boxShadow: 'none',
            color: '#0a73eb',
            height: '32px',
            textAlign: 'center',
            width: 'auto',
            margin: '0px',
            outline: 'none',
            overflow: 'hidden',
            borderRadius: '4px',
            padding: '0 16px',
            userSelect: 'none',
            cursor: 'pointer',
            '&.red': {
                color: '#EA4335',
                '&:hover': { color: '#C63131' },
                '&:active': { color: '#751010' }
            },
            '&.louder': {
                backgroundColor: 'transparent',
                borderColor: '#0a73eb',
                color: '#0a73eb',
                '&:hover': {
                    backgroundColor: 'transparent',
                    borderColor: '#0A73EB',
                    color: '#0A73EB'
                }
            },
            '&:hover': {
                backgroundColor: 'transparent',
                borderColor: '#0A73EB',
                color: '#0A73EB'
            },
            '&:active': {
                backgroundColor: 'transparent',
                borderColor: '#085bba',
                color: '#085bba'
            },
            '&.success': {
                backgroundColor: 'transparent',
                borderColor: '#36B37E',
                color: '#36B37E',
                svg: {
                    color: '#36B37E'
                }
            },
            svg: {
                margin: '3px 4px 0 0',
                verticalAlign: 'top',
                fontSize: '16px',
                color: '#0a73eb',
                strokeWidth: '1.5px'
            },
            '&.icon svg': {
                margin: '2px 0 0 0'
            },
            ':disabled': {
                opacity: '0.5',
                cursor: 'not-allowed',
                backgroundColor: 'transparent',
                border: '1px solid #e7e8f1',
                color: '#0a73eb'
            },
            '&.modal-secondary': {
                height: '32px',
                padding: '0 16px',
                boxShadow: 'none',
                color: '#0A73EB',
                backgroundColor: '#fff',
                border: '1px solid #84B9F5',
                '&:hover': {
                    backgroundColor: '#fff',
                    borderColor: '#0A73EB',
                    color: '#0A73EB'
                },
                '&:active': {
                    backgroundColor: '#fff',
                    borderColor: '#085bba',
                    color: '#085bba'
                }
            },
            '&.secondary-tiny': {
                backgroundColor: '#fff',
                color: '#0A73EB',
                fontSize: '12px',
                lineHeight: '14px',
                padding: '4px 6px',
                height: '24px', // lineHeight (14px) + padding (4px x 2) + border (1px x 2)
                '&:hover': {
                    borderColor: '#0A73EB',
                    color: '#0A73EB'
                },
                svg: {
                    margin: '0px',
                    fontSize: '14px'
                }
            },
            '&.secondary-tiny.secondary-tiny-icon': {
                padding: '4px',
                width: '24px',
                svg: {
                    height: '14px',
                    width: '100%',
                    color: '#0A73EB',
                    '&:hover': {
                        color: '#0A73EB'
                    }
                }
            },
            '&.secondary-icon': {
                padding: '0 8px',
                svg: {
                    margin: '4px 0 0 0'
                }
            },
            '&.secondary-red': {
                color: '#ff0000',
                '&:hover': {
                    borderColor: '#ff0000'
                },
                svg: {
                    color: '#ff0000'
                }
            },
            '&.secondary-white': {
                height: '32px',
                padding: '0 16px',
                boxShadow: 'none',
                backgroundColor: 'transparent',
                color: 'rgba(255,255,255, 1)',
                borderColor: 'rgba(255,255,255, 1)',
                '&:hover': {
                    opacity: 0.8,
                    backgroundColor: 'transparent'
                },
                '&:active': {
                    opacity: 0.6,
                    backgroundColor: 'transparent'
                },
                '&:disabled': {
                    opacity: 0.5,
                    backgroundColor: 'transparent'
                },
                svg: {
                    color: '#fff'
                }
            }
        },
        'secondary-gray': {
            lineHeight: '22px',
            bg: 'primary',
            boxSizing: 'border-box',
            fontSize: '14px',
            fontWeight: 500,
            textOverflow: 'ellipsis',
            verticalAlign: 'middle',
            whiteSpace: 'nowrap',
            backgroundColor: 'white',
            //boxShadow: 'rgba(36, 18, 77, 0.12) 0px 0px 0px 1px, rgba(36, 18, 77, 0.32) 0px 4px 4px -4px',
            border: '1px solid #e7e8f1',
            boxShadow: 'none',
            //color: 'rgb(36, 18, 77)',
            color: '#001233',
            height: '32px',
            textAlign: 'center',
            width: 'auto',
            margin: '0px',
            outline: 'none',
            overflow: 'hidden',
            transition: 'box-shadow 150ms cubic-bezier(0.2, 0.6, 0.6, 1) 0s',
            borderRadius: '4px',
            padding: '0px 16px',
            userSelect: 'none',
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: 'white',
                borderColor: '#0A73EB',
                color: '#0A73EB'
            },
            '&:active': {
                backgroundColor: 'white',
                borderColor: '#0A73EB',
                color: '#0A73EB'
            },
            svg: {
                margin: '3px 4px 0 0',
                verticalAlign: 'top',
                fontSize: '16px',
                strokeWidth: '1.5px'
            },
            '&.icon svg': {
                margin: '2px 0 0 0'
            },
            ':disabled': {
                opacity: '0.5',
                cursor: 'not-allowed'
            },
            '&.huge': {
                padding: '13px 24px 11px',
                height: 'auto',
                fontSize: '16px',
                svg: {
                    margin: '2px 0 0 4px',
                    verticalAlign: 'top',
                    fontSize: '20px'
                }
            },
            '&.secondary-gray-tiny': {
                fontSize: '12px',
                height: '24px'
            },
            '&.secondary-gray-tiny-icon': {
                padding: '0 2px'
            },
            '&.button-small': {
                fontSize: '12px',
                lineHeight: '17px',
                padding: '4px 8px',
                height: 'auto'
            },

            '&.secondary-icon': {
                padding: '0 8px',
                svg: {
                    margin: '3px 0 0 0'
                }
            },
            '&.secondary-red': {
                color: '#ff0000',
                '&:hover': {
                    borderColor: '#ff0000'
                },
                svg: {
                    color: '#ff0000'
                }
            }
        },
        tertiary: {
            bg: 'primary',
            fontWeight: 400,
            boxSizing: 'border-box',
            fontSize: '14px',
            textOverflow: 'ellipsis',
            verticalAlign: 'middle',
            whiteSpace: 'nowrap',
            backgroundColor: 'rgba(36, 18, 77, 0.04)',
            // boxShadow: 'rgba(36, 18, 77, 0.08) 0px 0px 0px 1px, rgba(36, 18, 77, 0.32) 0px 4px 4px -4px',
            color: 'rgb(60, 66, 87)',
            height: '32px',
            textAlign: 'center',
            width: 'auto',
            margin: '0px',
            outline: 'none',
            overflow: 'hidden',
            transition: 'background-color 150ms',
            borderRadius: '4px',
            padding: '0px 8px',
            userSelect: 'none',
            cursor: 'pointer',
            '&:hover': {
                // boxShadow: 'rgba(36, 18, 77, 0.15) 0px 0px 0px 1px, rgba(36, 18, 77, 0.32) 0px 4px 4px -4px',
                backgroundColor: 'rgba(36, 18, 77, 0.1)',
                transition: 'background-color 350ms'
            },
            svg: {
                margin: '3px 4px 0 0',
                verticalAlign: 'top',
                fontSize: '16px'
            },
            '&.icon svg': {
                margin: '2px 0 0 0'
            },
            '&.active': {
                color: '#0A73EB'
            },
            ':disabled': {
                opacity: '0.5',
                cursor: 'not-allowed'
            },
            '&.light': {
                color: '#777'
            },
            '&.tertiary-small': {
                height: '24px',
                fontSize: '13px'
            },
            '&.dropdown': {
                fontWeight: 400,
                boxShadow: 'rgba(36,18,77,0.08) 0px 0px 0px 1px',
                color: '#000',
                background: '#fbfafc',
                padding: '0 12'
            }
        },
        'tertiary-icon': {
            bg: 'primary',
            boxSizing: 'border-box',
            fontSize: '14px',
            textOverflow: 'ellipsis',
            verticalAlign: 'middle',
            whiteSpace: 'nowrap',
            backgroundColor: 'rgba(36, 18, 77, 0.04)',
            // boxShadow: 'rgba(36, 18, 77, 0.08) 0px 0px 0px 1px, rgba(36, 18, 77, 0.32) 0px 4px 4px -4px',
            color: 'rgb(36, 18, 77)',
            height: '32px',
            textAlign: 'center',
            width: 'auto',
            margin: '0px',
            outline: 'none',
            overflow: 'hidden',
            transition: 'background-color 150ms',
            borderRadius: '4px',
            padding: '0px 8px',
            userSelect: 'none',
            cursor: 'pointer',
            '&:hover': {
                // boxShadow: 'rgba(36, 18, 77, 0.15) 0px 0px 0px 1px, rgba(36, 18, 77, 0.32) 0px 4px 4px -4px',
                backgroundColor: 'rgba(36, 18, 77, 0.1)',
                transition: 'background-color 350ms'
            },
            svg: {
                margin: '2px 0 0 0',
                verticalAlign: 'top',
                fontSize: '16px'
            },
            '&.tertiary-icon-short-narrow': {
                padding: '0 2px',
                height: '20px'
            },
            '&.tertiary-icon-tiny': {
                padding: '0 2px',
                height: '20px',
                width: '20px'
            }
        },
        'transparent-icon': {
            bg: 'primary',
            boxSizing: 'border-box',
            fontSize: '14px',
            textOverflow: 'ellipsis',
            verticalAlign: 'middle',
            whiteSpace: 'nowrap',
            backgroundColor: 'transparent',
            // boxShadow: 'rgba(36, 18, 77, 0.08) 0px 0px 0px 1px, rgba(36, 18, 77, 0.32) 0px 4px 4px -4px',
            color: 'rgb(36, 18, 77)',
            height: '32px',
            textAlign: 'center',
            width: 'auto',
            margin: '0px',
            outline: 'none',
            overflow: 'hidden',
            transition: 'background-color 150ms',
            borderRadius: '4px',
            padding: '0px 8px',
            userSelect: 'none',
            cursor: 'pointer',
            '&:hover': {
                // boxShadow: 'rgba(36, 18, 77, 0.15) 0px 0px 0px 1px, rgba(36, 18, 77, 0.32) 0px 4px 4px -4px',
                backgroundColor: 'rgba(36, 18, 77, 0.05)',
                transition: 'background-color 350ms'
            },
            svg: {
                margin: '3px 0 0 0',
                verticalAlign: 'top',
                fontSize: '16px'
            },
            '&.nice-dd-move': {
                height: '26px',
                padding: '0 6px',
                marginRight: '4px',
                marginTop: 0
            },
            '&.small': {
                fontSize: '13px',
                svg: {
                    margin: '2px 0 0 0',
                    fontSize: '13px'
                }
            }
        },
        'transparent-icon-short': {
            bg: 'primary',
            boxSizing: 'border-box',
            fontSize: '14px',
            textOverflow: 'ellipsis',
            verticalAlign: 'middle',
            whiteSpace: 'nowrap',
            backgroundColor: 'transparent',
            // boxShadow: 'rgba(36, 18, 77, 0.08) 0px 0px 0px 1px, rgba(36, 18, 77, 0.32) 0px 4px 4px -4px',
            color: 'rgb(36, 18, 77)',
            height: '22px',
            textAlign: 'center',
            width: 'auto',
            margin: '0px',
            outline: 'none',
            overflow: 'hidden',
            transition: 'background-color 150ms',
            borderRadius: '4px',
            padding: '0px 8px',
            userSelect: 'none',
            cursor: 'pointer',
            '&:hover': {
                // boxShadow: 'rgba(36, 18, 77, 0.15) 0px 0px 0px 1px, rgba(36, 18, 77, 0.32) 0px 4px 4px -4px',
                backgroundColor: 'rgba(36, 18, 77, 0.05)',
                transition: 'background-color 350ms'
            },
            svg: {
                margin: '3px 0 0 0',
                verticalAlign: 'top',
                fontSize: '14px'
            },
            '&.transparent-icon-narrow': {
                padding: '0 2px',
                height: '24px'
            }
        },
        'transparent-icon-nobg': {
            bg: 'primary',
            boxSizing: 'border-box',
            fontSize: '14px',
            textOverflow: 'ellipsis',
            verticalAlign: 'top',
            whiteSpace: 'nowrap',
            backgroundColor: 'transparent',
            // boxShadow: 'rgba(36, 18, 77, 0.08) 0px 0px 0px 1px, rgba(36, 18, 77, 0.32) 0px 4px 4px -4px',
            //color: 'rgb(36, 18, 77)',
            color: '#0a73eb',
            '&:hover': {
                color: '#0A73EB'
            },
            '&:active': {
                color: '#085bba'
            },
            '&:disabled': {
                color: '#e7e8f1'
            },
            height: '32px',
            textAlign: 'center',
            width: 'auto',
            margin: '0px',
            outline: 'none',
            overflow: 'hidden',
            transition: 'background-color 150ms',
            borderRadius: '4px',
            padding: '0px 8px',
            userSelect: 'none',
            cursor: 'pointer',
            svg: {
                margin: '3px 0 0 0',
                verticalAlign: 'top',
                fontSize: '16px'
            },
            '&.dd-move-toggle': {
                height: '26px',
                padding: '0 6px',
                marginRight: '4px',
                marginTop: '4px'
            },
            '&.dd-move-list': {
                height: '26px',
                padding: '0',
                marginRight: '0',
                marginTop: 0
            }
        },
        // 'transparent-icon-label': {
        //     bg: 'primary',
        //     boxSizing: 'border-box',
        //     fontSize: '14px',
        //     textOverflow: 'ellipsis',
        //     verticalAlign: 'top',
        //     whiteSpace: 'nowrap',
        //     backgroundColor: 'transparent',
        //     // boxShadow: 'rgba(36, 18, 77, 0.08) 0px 0px 0px 1px, rgba(36, 18, 77, 0.32) 0px 4px 4px -4px',
        //     color: 'rgb(36, 18, 77)',
        //     height: '32px',
        //     textAlign: 'center',
        //     width: 'auto',
        //     margin: '0px',
        //     outline: 'none',
        //     overflow: 'hidden',
        //     transition: 'background-color 150ms',
        //     borderRadius: '4px',
        //     padding: '0px',
        //     userSelect: 'none',
        //     cursor: 'default',
        //     '&:hover': {},
        //     svg: {
        //         margin: '3px 0 0 0',
        //         verticalAlign: 'top',
        //         fontSize: '16px'
        //     }
        // },
        'small-icon': {
            bg: 'primary',
            boxSizing: 'border-box',
            fontSize: '14px',
            textOverflow: 'ellipsis',
            verticalAlign: 'top',
            whiteSpace: 'nowrap',
            backgroundColor: 'transparent',
            color: 'rgb(36, 18, 77)',
            textAlign: 'center',
            height: '20px',
            width: '20px',
            padding: 0,
            margin: '0px',
            outline: 'none',
            overflow: 'hidden',
            transition: 'background-color 150ms',
            borderRadius: '4px',
            userSelect: 'none',
            cursor: 'pointer',
            '&:hover': {
                color: '#0A73EB'
            },
            svg: {
                margin: '3px 0 0 0',
                verticalAlign: 'top',
                fontSize: '12px'
            }
        },
        transparent: {
            bg: 'primary',
            boxSizing: 'border-box',
            fontSize: '14px',
            fontWeight: 500,
            textOverflow: 'ellipsis',
            verticalAlign: 'middle',
            whiteSpace: 'nowrap',
            backgroundColor: 'transparent',
            color: '#001233',
            height: '32px',
            textAlign: 'center',
            width: 'auto',
            margin: '0px',
            outline: 'none',
            overflow: 'hidden',
            transition: 'background-color 150ms',
            borderRadius: '4px',
            padding: '0px',
            userSelect: 'none',
            cursor: 'pointer',
            border: '1px solid transparent',
            '&.danger': {
                color: 'red'
            },
            '&.light': {
                color: '#606E85'
            },
            '&.transparent-small': {
                height: '24px',
                fontSize: '13px'
            },
            '&:hover, &.active': {
                color: '#0A73EB'
            },
            '&:focus': {
                color: '#0A73EB'
            },
            svg: {
                margin: '3px 4px 0 0',
                verticalAlign: 'top',
                fontSize: '16px'
            }
        },
        'primary-transparent': {
            boxSizing: 'border-box',
            fontSize: '14px',
            fontWeight: '500',
            textOverflow: 'ellipsis',
            verticalAlign: 'middle',
            whiteSpace: 'nowrap',
            backgroundColor: 'transparent',
            color: '#0A73EB',
            height: '32px',
            textAlign: 'center',
            width: 'auto',
            margin: '0px',
            outline: 'none',
            overflow: 'hidden',
            transition: 'background-color 150ms',
            borderRadius: '4px',
            padding: '0px 8px',
            userSelect: 'none',
            cursor: 'pointer',
            '&:hover, &.active': {
                // boxShadow: 'rgba(36, 18, 77, 0.15) 0px 0px 0px 1px, rgba(36, 18, 77, 0.32) 0px 4px 4px -4px',
                // backgroundColor: 'rgba(36, 18, 77, 0.04)',
                transition: 'background-color 350ms'
            },
            svg: {
                margin: '2px 4px 0 0',
                verticalAlign: 'top',
                fontSize: '16px'
            }
        },
        // tab: {
        //     bg: 'primary',
        //     boxSizing: 'border-box',
        //     fontSize: '15px',
        //     textOverflow: 'ellipsis',
        //     verticalAlign: 'middle',
        //     whiteSpace: 'nowrap',
        //     backgroundColor: 'transparent',
        //     // boxShadow: 'rgba(36, 18, 77, 0.08) 0px 0px 0px 1px, rgba(36, 18, 77, 0.32) 0px 4px 4px -4px',
        //     color: '#999',
        //     fontWeight: '400',
        //     textAlign: 'center',
        //     width: 'auto',
        //     margin: '0px 16px -1px 0',
        //     outline: 'none',
        //     overflow: 'hidden',
        //     transition: 'background-color 150ms',
        //     borderRadius: '0',
        //     padding: '8px',
        //     userSelect: 'none',
        //     border: 0,
        //     borderBottom: '1px solid transparent',
        //     cursor: 'pointer',
        //     '&.active': {
        //         // border: '1px solid #eee',
        //         borderBottom: '1px solid #000',
        //         color: '#333'
        //         // backgroundColor: '#eee'
        //     },
        //     '&:hover': {
        //         // border: '1px solid #eee',
        //         color: '#333'
        //     },
        //     svg: {
        //         margin: '3px 8px 0 0',
        //         verticalAlign: 'top',
        //         fontSize: '16px'
        //         // display: 'none'
        //     }
        // },
        danger: {
            bg: 'primary',
            boxSizing: 'border-box',
            fontSize: '14px',
            textOverflow: 'ellipsis',
            verticalAlign: 'middle',
            border: '1px solid #D42220',
            whiteSpace: 'nowrap',
            backgroundColor: 'white',
            color: '#D42220',
            fontWeight: '500',
            height: '32px',
            textAlign: 'center',
            width: 'auto',
            margin: '0px',
            outline: 'none',
            overflow: 'hidden',
            borderRadius: '4px',
            padding: '0px 16px',
            userSelect: 'none',
            cursor: 'pointer',
            svg: {
                margin: '3px 4px 0 0',
                verticalAlign: 'top',
                fontSize: '16px'
            },
            '&:hover': {
                borderColor: '#971110',
                color: '#971110'
            },
            '&:focus': {
                borderColor: '#971110',
                color: '#971110'
            }
        },
        'danger-filled': {
            bg: 'primary',
            boxSizing: 'border-box',
            fontSize: '14px',
            textOverflow: 'ellipsis',
            verticalAlign: 'middle',
            whiteSpace: 'nowrap',
            backgroundColor: '#D42220',
            fontWeight: '500',
            height: '32px',
            textAlign: 'center',
            width: 'auto',
            margin: '0px',
            outline: 'none',
            overflow: 'hidden',
            borderRadius: '4px',
            padding: '0px 16px',
            userSelect: 'none',
            cursor: 'pointer',
            svg: {
                margin: '3px 4px 0 0',
                verticalAlign: 'top',
                fontSize: '16px'
            },
            '&:hover': {
                backgroundColor: '#971110'
            },
            ':disabled': {
                backgroundColor: '#FFF0F1',
                cursor: 'not-allowed'
            }
        },
        link: {
            fontWeight: 500,
            fontSize: '14px',
            bg: 'transparent',
            margin: 0,
            padding: 0,
            color: '#0A73EB',
            height: '32px',
            verticalAlign: 'middle',
            outline: 'none !important',
            border: 0,
            cursor: 'pointer',
            transition: 'color 150ms',
            '&:hover': {
                color: '#0A73EB'
            },
            '&:active': {
                color: '#085bba'
            },
            '&:disabled': {
                opacity: 0.5
            },
            '&.muted': {
                color: '#001233'
            },
            '&.muted:hover': {
                color: '#0A73EB'
            },
            '&.muted:active': {
                color: '#085bba'
            },
            '&.quiet': {
                color: '#777'
            },
            '&.quiet:hover': {
                color: '#555'
            },
            '&.link-small': {
                height: '18px',
                minHeight: 'auto',
                fontSize: '12px',
                svg: {
                    margin: '3px 3px 0 0',
                    fontSize: '13px',
                    strokeWidth: '2.5px'
                }
            },
            '&.red': {
                color: '#EA4335',
                '&:hover': { color: '#C63131' },
                '&:active': { color: '#751010' }
            },
            svg: {
                margin: '3px 0 0 0',
                verticalAlign: 'top',
                fontSize: '15px'
            },
            '&.link-small-black': {
                height: '20px',
                minHeight: 'auto',
                fontSize: '13px',
                color: 'black',
                '&:hover': {
                    opacity: 0.8
                },
                svg: {
                    fontSize: '13px',
                    margin: '3px 0 0 0',
                    verticalAlign: 'top'
                }
            },
            '&.link-icon-tiny': {
                padding: '0 2px',
                height: '20px',
                width: '20px'
            }
        },
        // tag: {
        //     bg: 'primary',
        //     fontWeight: 500,
        //     boxSizing: 'border-box',
        //     fontSize: '12px',
        //     textOverflow: 'ellipsis',
        //     verticalAlign: 'middle',
        //     whiteSpace: 'nowrap',
        //     backgroundColor: '#EBF2FF',
        //     // boxShadow: 'rgba(36, 18, 77, 0.08) 0px 0px 0px 1px, rgba(36, 18, 77, 0.32) 0px 4px 4px -4px',
        //     color: '#929DB0',
        //     height: '24px',
        //     textAlign: 'center',
        //     width: 'auto',
        //     margin: '0px',
        //     outline: 'none',
        //     overflow: 'hidden',
        //     transition: 'background-color 150ms',
        //     borderRadius: '4px',
        //     padding: '0px 8px',
        //     userSelect: 'none',
        //     cursor: 'pointer',
        //     '&:hover': {
        //         backgroundColor: '#D9E3F4',
        //         color: '#798499'
        //     },
        //     svg: {
        //         margin: '3px 4px 0 0',
        //         verticalAlign: 'top',
        //         fontSize: '16px'
        //     },
        //     '&.icon svg': {
        //         margin: '2px 0 0 0'
        //     },
        //     '&.active': {},
        //     ':disabled': {
        //         opacity: '0.5',
        //         cursor: 'not-allowed'
        //     }
        // },
        circle: {
            boxSizing: 'border-box',
            fontSize: '14px',
            fontWeight: 500,
            textOverflow: 'ellipsis',
            verticalAlign: 'middle',
            whiteSpace: 'nowrap',
            backgroundColor: '#e7e8f1',
            border: '0',
            boxShadow: 'none',
            color: '#001233',
            height: 'auto',
            textAlign: 'center',
            width: 'auto',
            margin: '0px',
            outline: 'none',
            overflow: 'hidden',
            borderRadius: '50%',
            padding: '6px',
            userSelect: 'none',
            cursor: 'pointer',
            '&:hover': {
                backgroundColor: '#d3d5df'
            },
            '&:active': {
                color: '#000'
            }
        }
    }
});

ReactModal.defaultStyles = ReactModalDefaultStyles;

ReactModal.setAppElement('#app');

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            auth: auth.starterObject,
            routeAccess: {
                studies: { title: 'studies', role: 'view' },
                study: { title: 'study', role: 'view' },
                'study-recruit': { title: 'study-recruit', role: 'view' },
                'study-screeners': { title: 'study-screeners', role: 'view' },
                'study-screener-individual': { title: 'study-screener-individual', role: 'view' },
                'study-emails': { title: 'study-emails', role: 'view' },
                'study-reports': { title: 'study-reports', role: 'view' },
                'study-tags': { title: 'study-tags', role: 'view' },
                'study-settings': { title: 'study-settings', role: 'view' },
                'study-quotas': { title: 'study-quotas', role: 'view' },
                'study-availability': { title: 'study-availability', role: 'view' },
                search: { title: 'search', role: 'view' },
                dashboard: { title: 'dashboard', role: 'view' },
                participants: { title: 'participants', role: 'limited' },
                panels: { title: 'panels', role: 'view' },
                'panels-edit': { title: 'panels-edit', role: 'view' },
                'panels-dashboard': { title: 'panels-dashboard', role: 'view' },
                'panel-data': { title: 'panel-data', role: 'view' },
                'panel-incentives': { title: 'panel-incentives', role: 'view' },
                'panel-signup-list': { title: 'panel-signup-list', role: 'view' },
                'panel-signup-single': { title: 'panel-signup-single', role: 'view' },
                participant: { title: 'participant', role: 'view' },
                settings: { title: 'settings', role: 'limited' },
                'settings-users': { title: 'settings-users', role: 'limited' },
                'settings-forms': { title: 'settings-forms', role: 'view' },
                'settings-forms-single': { title: 'settings-forms-single', role: 'view' },
                'settings-billing': { title: 'settings-billing', role: 'admin' },
                'settings-integrations': { title: 'settings-integrations', role: 'user' },
                'settings-tags': { title: 'settings-tags', role: 'view' },
                'settings-email-templates': { title: 'settings-email-templates', role: 'user' },
                'settings-email-template-individual': { title: 'settings-email-template-individual', role: 'user' },
                'settings-developer': { title: 'settings-developer', role: 'admin' },
                usage: { title: 'usage', role: 'user' },
                'zoom-setup': { title: 'zoom-setup', role: 'view' },
                'client-grid': { title: 'client-grid', role: 'view' },
                beta: { title: 'beta', role: 'view' },
                me: { title: 'me', role: 'limited' },
                'test-mmukhin': { title: 'test-mmukhin', role: 'view' },
                'participant-form': { title: 'participant-form', role: 'view', withoutHeader: true }
            }
        };

        const query_strings = qs.parse(window.location.search, { ignoreQueryPrefix: true });
        if (query_strings.notification) {
            this.state.urlNotification = query_strings.notification;
        }
        if (query_strings.justSignedUpViaGoogle) {
            helpers.googleTagManagerEventSignup();
        }
        Array.prototype.removeIf = function(callback) {
            let i = 0;
            while (i < this.length) {
                if (callback(this[i], i)) {
                    this.splice(i, 1);
                } else {
                    ++i;
                }
            }
        };
    }

    checkAuth() {
        auth.checkAuth().then(authObj => {
            try {
                // load these in prod only
                if (authObj && authObj.isAuthenticated && env == 'production') {
                    const pusher = new Pusher(config.PUSHER_KEY, {
                        cluster: config.PUSHER_CLUSTER,
                        encrypted: true
                    });
                    window.pusher = pusher;
                }
            } catch (e) {
                helpers.trackError(e);
            }

            this.setState({
                auth: authObj
            });
        });
    }

    redirectCustomDomains() {
        if (location.hostname === 'community.angelfish-opinions.com' && location.pathname === '/') {
            window.location =
                'https://community.angelfish-opinions.com/panel-profile/04b43fb6-ad01-42bf-a06d-00c5a7385d0e/4';
        } else if (location.hostname === 'research.bunnyfield.com' && location.pathname === '/') {
            window.location = 'https://research.bunnyfield.com/p/panel-signup';
        } else if (location.hostname === 'joinau.research-network.co' && location.pathname === '/') {
            window.location = 'https://joinau.research-network.co/panelist/86084be3-07d0-4157-95e1-32ac33259862/signup';
        } else if (location.hostname === 'joinau.research-network.co' && location.pathname === '/login') {
            window.location = 'https://joinau.research-network.co/panelist/86084be3-07d0-4157-95e1-32ac33259862/login';
        } else if (location.hostname === 'joinsg.research-network.co' && location.pathname === '/') {
            window.location = 'https://joinsg.research-network.co/panelist/87a82ddb-5d6a-4c5c-9e81-6a143edd695c/signup';
        } else if (location.hostname === 'joinsg.research-network.co' && location.pathname === '/login') {
            window.location = 'https://joinsg.research-network.co/panelist/87a82ddb-5d6a-4c5c-9e81-6a143edd695c/login';
        } else if (location.hostname === 'community.opinionsbysync.com' && location.pathname === '/') {
            window.location =
                'https://community.opinionsbysync.com/panelist/792648e3-9485-4289-8d48-8ccbd4dd5126/signup';
        } else if (location.hostname === 'community.opinionsbysync.com' && location.pathname === '/login') {
            window.location =
                'https://community.opinionsbysync.com/panelist/792648e3-9485-4289-8d48-8ccbd4dd5126/login';
        } else if (location.hostname === 'voice.research-collective.com' && location.pathname === '/') {
            window.location = 'https://research-collective.com/';
        }
    }

    componentDidMount() {
        // redirect default panel pages
        this.redirectCustomDomains();

        analytics.init(config.MIXPANEL_TOKEN);
        this.checkAuth();
        const that = this;
        EventEmitter.subscribe('auth.update', function() {
            //console.log('auth.update receive');
            that.checkAuth();
        });

        // check if there is a has to scroll to
        if (location.hash) {
            var scrollToTabIntervalExecutions = 0;
            var scrollToTabIntervalId = setInterval(() => {
                ++scrollToTabIntervalExecutions;
                // tried 5 times but not found
                if (scrollToTabIntervalExecutions === 5) {
                    window.clearInterval(scrollToTabIntervalId);
                }

                try {
                    // remove the "#" first character
                    const fragment = location.hash.substring(1);
                    const fragment_element = document.getElementById(fragment);
                    console.log(fragment, fragment_element);
                    if (fragment_element) {
                        fragment_element.scrollIntoView();
                        console.log('Scrolling...');
                        window.clearInterval(scrollToTabIntervalId);
                    } else {
                        console.log('Not found yet...');
                    }
                } catch (e) {
                    helpers.trackError(e);
                }
            }, 500);
        }
    }

    render() {
        return (
            <ThemeProvider theme={theme}>
                <ToastProvider
                    components={{ Toast: CustomToast, ToastContainer: CustomToastContainer }}
                    placement="bottom-right"
                >
                    <PromptProvider>
                        <Helmet titleTemplate="%s - Panelfox" defaultTitle="Panelfox">
                            {helpers.renderFavicon()}
                        </Helmet>
                        <Switch>
                            {/*
                            AUTH REQUIRED --->
                        */}
                            <Route
                                exact
                                path={['/studies']}
                                component={RequireAuth(StudiesPage, this.state.auth, this.state.routeAccess.studies)}
                            />
                            <Route
                                exact
                                path="/studies/:id"
                                component={RequireAuth(StudyPage, this.state.auth, this.state.routeAccess.study)}
                            />
                            <Route
                                exact
                                path="/study-new-table"
                                component={RequireAuth(
                                    StudyNewTablePage,
                                    this.state.auth,
                                    this.state.routeAccess.study
                                )}
                            />
                            <Route
                                path="/studies/:id/recruit"
                                component={RequireAuth(
                                    StudyRecruitPage,
                                    this.state.auth,
                                    this.state.routeAccess['study-recruit']
                                )}
                            />
                            <Route
                                exact
                                path="/studies/:id/screeners"
                                component={RequireAuth(
                                    StudyScreenersList,
                                    this.state.auth,
                                    this.state.routeAccess['study-screeners']
                                )}
                            />
                            <Route
                                exact
                                path="/studies/:study_id/screeners/:screener_id"
                                component={RequireAuth(
                                    StudyScreenerIndividual,
                                    this.state.auth,
                                    this.state.routeAccess['study-screener-individual']
                                )}
                            />
                            <Route
                                exact
                                path="/studies/:id/reports"
                                component={RequireAuth(
                                    StudyReportsPage,
                                    this.state.auth,
                                    this.state.routeAccess['study-reports']
                                )}
                            />
                            <Route
                                path="/studies/:id/emails"
                                component={RequireAuth(
                                    StudyCampaignsPage,
                                    this.state.auth,
                                    this.state.routeAccess['study-emails']
                                )}
                            />
                            <Route
                                path="/studies/:id/tags"
                                component={RequireAuth(
                                    StudyTagsPage,
                                    this.state.auth,
                                    this.state.routeAccess['study-tags']
                                )}
                            />
                            <Route
                                path="/studies/:id/quotas"
                                component={RequireAuth(
                                    StudyQuotasPage,
                                    this.state.auth,
                                    this.state.routeAccess['study-quotas']
                                )}
                            />
                            <Route
                                path="/studies/:id/settings"
                                component={RequireAuth(
                                    StudySettingsPage,
                                    this.state.auth,
                                    this.state.routeAccess['study-settings']
                                )}
                            />
                            <Route
                                path="/studies/:id/scheduling"
                                component={RequireAuth(
                                    StudySchedulingPage,
                                    this.state.auth,
                                    this.state.routeAccess['study-availability']
                                )}
                            />
                            <Route
                                path="/studies/:id/participants/:study_person_id"
                                component={RequireAuth(StudyPage, this.state.auth, this.state.routeAccess.study)}
                            />
                            <Route
                                exact
                                path="/search"
                                component={RequireAuth(SearchPage, this.state.auth, this.state.routeAccess.search)}
                            />
                            <Route
                                exact
                                path="/search/:search_kw"
                                component={RequireAuth(SearchPage, this.state.auth, this.state.routeAccess.search)}
                            />
                            <Route
                                exact
                                path="/dashboard"
                                component={RequireAuth(
                                    DashboardPage,
                                    this.state.auth,
                                    this.state.routeAccess.dashboard
                                )}
                            />
                            <Route
                                exact
                                path="/panel"
                                component={RequireAuth(
                                    ParticipantsPage,
                                    this.state.auth,
                                    this.state.routeAccess.participants
                                )}
                            />
                            <Route
                                exact
                                path="/panel/:panel_search_id"
                                component={RequireAuth(
                                    ParticipantsPage,
                                    this.state.auth,
                                    this.state.routeAccess.participants
                                )}
                            />
                            <Route
                                exact
                                path="/panels"
                                component={RequireAuth(
                                    ParticipantsPanelsPage,
                                    this.state.auth,
                                    this.state.routeAccess['panels']
                                )}
                            />
                            <Route
                                exact
                                path="/panels/:panel_id"
                                component={RequireAuth(
                                    ParticipantsPanelsEditPage,
                                    this.state.auth,
                                    this.state.routeAccess['panels-edit']
                                )}
                            />
                            <Route
                                exact
                                path="/panels/:panel_id/dashboard"
                                component={RequireAuth(
                                    PanelDashboardPage,
                                    this.state.auth,
                                    this.state.routeAccess['panels-dashboard']
                                )}
                            />
                            <Route
                                exact
                                path="/panel-data"
                                component={RequireAuth(
                                    ParticipantsDataPage,
                                    this.state.auth,
                                    this.state.routeAccess['panel-data']
                                )}
                            />
                            <Route
                                exact
                                path="/settings/tags"
                                component={RequireAuth(
                                    TagsPage,
                                    this.state.auth,
                                    this.state.routeAccess['settings-tags']
                                )}
                            />
                            <Route
                                exact
                                path="/panel-incentives"
                                component={RequireAuth(
                                    ParticipantsIncentivesPage,
                                    this.state.auth,
                                    this.state.routeAccess['panel-incentives']
                                )}
                            />
                            <Route
                                exact
                                path="/panel-signup"
                                component={RequireAuth(
                                    PanelSignupFormsList,
                                    this.state.auth,
                                    this.state.routeAccess['panel-signup-list']
                                )}
                            />
                            <Route
                                exact
                                path="/panel-signup/:panel_form_id"
                                component={RequireAuth(
                                    PanelSignupForm,
                                    this.state.auth,
                                    this.state.routeAccess['panel-signup-single']
                                )}
                            />
                            <Route
                                exact
                                path="/settings/forms/:panel_form_id"
                                component={RequireAuth(
                                    PanelSignupForm,
                                    this.state.auth,
                                    this.state.routeAccess['settings-forms-single']
                                )}
                            />
                            <Route
                                exact
                                path="/participants/:participantId/forms/:formId"
                                component={RequireAuth(
                                    ParticipantFormPage,
                                    this.state.auth,
                                    this.state.routeAccess['participant-form']
                                )}
                            />
                            <Route
                                path="/participants/:id"
                                component={RequireAuth(
                                    ParticipantPage,
                                    this.state.auth,
                                    this.state.routeAccess.participant
                                )}
                            />
                            <Route
                                exact
                                path="/settings"
                                component={RequireAuth(SettingsPage, this.state.auth, this.state.routeAccess.settings)}
                            />
                            <Route
                                exact
                                path="/settings/users"
                                component={RequireAuth(
                                    SettingsUsersPage,
                                    this.state.auth,
                                    this.state.routeAccess['settings-users']
                                )}
                            />
                            <Route
                                exact
                                path="/settings/forms"
                                component={RequireAuth(
                                    PanelSignupFormsList,
                                    this.state.auth,
                                    this.state.routeAccess['settings-forms']
                                )}
                            />
                            <Route
                                path="/settings/billing"
                                component={RequireAuth(
                                    SettingsBillingPage,
                                    this.state.auth,
                                    this.state.routeAccess['settings-billing']
                                )}
                            />
                            <Route
                                path="/settings/integrations"
                                component={RequireAuth(
                                    SettingsIntegrationsPage,
                                    this.state.auth,
                                    this.state.routeAccess['settings-integrations']
                                )}
                            />
                            <Route
                                exact
                                path="/settings/usage"
                                component={RequireAuth(
                                    SettingsUsagePage,
                                    this.state.auth,
                                    this.state.routeAccess['usage']
                                )}
                            />
                            <Route
                                exact
                                path="/settings/developer"
                                component={RequireAuth(
                                    SettingsDeveloperPage,
                                    this.state.auth,
                                    this.state.routeAccess['settings-developer']
                                )}
                            />
                            <Route
                                exact
                                path="/settings/email-templates"
                                component={RequireAuth(
                                    SettingsEmailTemplatesPage,
                                    this.state.auth,
                                    this.state.routeAccess['settings-email-templates']
                                )}
                            />
                            <Route
                                exact
                                path="/settings/email-templates/:id"
                                component={RequireAuth(
                                    SettingsEmailTemplateIndividualPage,
                                    this.state.auth,
                                    this.state.routeAccess['settings-email-template-individual']
                                )}
                            />
                            <Route
                                path="/zoom-setup"
                                component={RequireAuth(
                                    ZoomSetupPage,
                                    this.state.auth,
                                    this.state.routeAccess['zoom-setup']
                                )}
                            />
                            <Route
                                path="/trial"
                                component={RequireAuth(BetaPage, this.state.auth, this.state.routeAccess.beta)}
                            />
                            <Route
                                path="/me"
                                component={RequireAuth(MePage, this.state.auth, this.state.routeAccess.me)}
                            />

                            <Route
                                path="/test-mmukhin"
                                component={RequireAuth(TestPage, this.state.auth, this.state.routeAccess.beta)}
                            />
                            {/*
                            <--- AUTH REQUIRED
                        */}

                            {/*
                            AUTH NOT REQUIRED --->
                        */}
                            <Route
                                path="/.well-known/microsoft-identity-association"
                                component={Raw(MicrosoftVerificationPage, this.state.auth)}
                            />
                            <Route
                                path="/.well-known/pki-validation/fileauth.txt"
                                component={Raw(SSLVerificationPage, this.state.auth)}
                            />
                            <Route
                                path="/emails/:email_campaign_uuid"
                                component={NoAuth(EmailCampaignPage, this.state.auth)}
                            />
                            <Route path="/signup" component={NoAuth(LoginPage, this.state.auth)} />
                            <Route path="/login" component={NoAuth(LoginPage, this.state.auth)} />
                            <Route path="/forgot-password" component={NoAuth(ForgotPasswordPage, this.state.auth)} />
                            <Route exact path="/pricing" component={NoAuth(PricingPage, this.state.auth)} />
                            <Route exact path="/product" component={NoAuth(ProductPage, this.state.auth)} />
                            <Route exact path="/about" component={NoAuth(OurStoryPage, this.state.auth)} />
                            <Route exact path="/demo" component={NoAuth(DemoSchedulingPage, this.state.auth)} />
                            <Route exact path="/security" component={NoAuth(SecurityPage, this.state.auth)} />
                            <Route exact path="/case-studies" component={NoAuth(CaseStudiesPage, this.state.auth)} />
                            <Route exact path="/privacy" component={NoAuth(PrivacyPage, this.state.auth)} />
                            <Route exact path="/survey" component={NoAuth(SurveyViewPage, this.state.auth)} />
                            <Route exact path="/support" component={NoAuth(SupportPage, this.state.auth, 'support')} />
                            <Route
                                exact
                                path="/zoom-support"
                                component={NoAuth(ZoomSupportPage, this.state.auth, 'zoom-support')}
                            />
                            <Route
                                exact
                                path="/panelist/:account_uuid/login"
                                component={NoAuth(PanelistLogin, this.state.auth)}
                            />
                            <Route
                                exact
                                path="/panelist/:account_uuid/forgot-password"
                                component={NoAuth(PanelistForgotPassword, this.state.auth)}
                            />
                            <Route
                                exact
                                path="/new-password/:token"
                                component={NoAuth(SetNewPassword, this.state.auth)}
                            />
                            <Route
                                exact
                                path="/panelist/:account_uuid/signup"
                                component={NoAuth(PanelistSignup, this.state.auth)}
                            />
                            <Route
                                exact
                                path="/panelist/:account_uuid/profile"
                                component={NoAuth(PanelistProfile, this.state.auth)}
                            />
                            <Route
                                exact
                                path="/panelist/:account_uuid/profile/:panel_form_id"
                                component={NoAuth(PanelistProfile, this.state.auth)}
                            />
                            <Route
                                exact
                                path="/consent-management"
                                component={NoAuth(ConsentManagementPage, this.state.auth)}
                            />
                            <Route
                                exact
                                path="/consent-management/:account_uuid/:person_uuid"
                                component={NoAuth(ConsentManagementPage, this.state.auth)}
                            />
                            <Route
                                exact
                                path="/consent-management/:account_uuid/:person_uuid/:cm_action"
                                component={NoAuth(ConsentManagementPage, this.state.auth)}
                            />
                            <Route
                                path="/grids/:study_uuid"
                                component={NoAuth(ClientGrid, this.state.auth, 'client-grid')}
                            />
                            <Route
                                path="/screen/:hash"
                                component={NoAuth(PublicScreenPage, this.state.auth, 'screen')}
                            />
                            <Route
                                path="/study-screen/:screen_uuid"
                                component={NoAuth(PublicScreenPage, this.state.auth, 'screen')}
                            />
                            <Route
                                path="/s/:screen_uuid"
                                component={NoAuth(PublicScreenPage, this.state.auth, 'screen')}
                            />
                            <Route
                                path="/panel-profile/:account_hash/:panel_form_id"
                                component={NoAuth(PublicScreenPage, this.state.auth, 'panel-profile')}
                            />
                            <Route
                                path="/p/:panel_signup_custom_slug"
                                component={NoAuth(PublicScreenPage, this.state.auth, 'panel-profile')}
                            />
                            <Route
                                path="/scheduling/schedule"
                                component={NoAuth(PublicSchedulingPage, this.state.auth)}
                            />
                            <Route
                                path="/scheduling/reschedule"
                                component={NoAuth(PublicSchedulingPage, this.state.auth)}
                            />
                            <Route
                                path="/scheduling/session"
                                component={NoAuth(PublicSchedulingResultPage, this.state.auth)}
                            />
                            <Route exact path="/docs" component={DocsRedirect} />
                            {!isProduction && (
                                <Suspense fallback={<div>Loading...</div>}>
                                    <Route exact path="/docs/components" component={ComponentEncyclopediaPage} />
                                    <Route
                                        exact
                                        path="/docs/components/:component"
                                        component={ComponentEncyclopediaPage}
                                    />
                                </Suspense>
                            )}
                            <Route path="/docs/:section/:page" component={DocsRedirect} />
                            <Route path="" component={NotFoundPage} />
                            {/*
                            <--- END AUTH NOT REQUIRED
                        */}
                        </Switch>

                        {this.state.urlNotification && (
                            <ActionPopup id="app-action-popup">
                                <Flex>
                                    <Box sx={{ fontWeight: 600, marginTop: '6px' }}>{this.state.urlNotification}</Box>
                                    <Button
                                        variant="primary"
                                        ml={3}
                                        mr={0}
                                        // eslint-disable-next-line
                                        onClick={() =>
                                            (document.getElementById('app-action-popup').style.display = 'none')
                                        }
                                    >
                                        OK
                                    </Button>
                                </Flex>
                            </ActionPopup>
                        )}
                        <GlobalStyle />
                    </PromptProvider>
                </ToastProvider>
            </ThemeProvider>
        );
    }
}
export default App;
