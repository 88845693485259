import React, { useState, useEffect, useMemo, useRef } from 'react';
import ReactModal from 'react-modal';
import ReactTooltip from 'react-tooltip';
import { Helmet } from 'react-helmet';
import { useToasts } from 'react-toast-notifications';
import H1 from 'components/H1';
import H2 from 'components/H2';
import { Flex, Box, Button, Image, Text } from 'rebass';
import { Label, Input, Select, Checkbox } from '@rebass/forms';
import services from 'services/services';
import people_service from 'services/people';
import {
    FiMove,
    FiEdit,
    FiBookOpen,
    FiColumns,
    FiTrash2,
    FiEye,
    FiEyeOff,
    FiFolderPlus,
    FiChevronDown,
    FiPlus
} from 'react-icons/fi';
import helpers from 'utils/helpers';
import { PERSON_KEY_TYPES, INTEGRATION_SALESFORCE } from 'utils/constants';
import moment from 'moment-timezone';
import NiceModal from 'components/NiceModal';
import cloneDeep from 'lodash/cloneDeep';

import AppPageWrapper from 'components/AppPageWrapper';
import AppPageSubnav from 'components/AppPageWrapper/AppPageSubnav';
import AppPageBreadcrumbs from 'components/AppPageWrapper/AppPageBreadcrumbs';
import AppPageWrapperSectionHeader from 'components/AppPageWrapper/AppPageWrapperSectionHeader';
import AppPageWrapperSectionSubHeader from 'components/AppPageWrapper/AppPageWrapperSectionSubHeader';
import AppPageWrapperSectionBody from 'components/AppPageWrapper/AppPageWrapperSectionBody';
import ParticipantsPageSubNav from 'containers/ParticipantsPage/ParticipantsPageSubNav';
import ListWrapper from 'components/ListWrapper';
import ItemWrapper from 'components/ItemWrapper';
import InputSearch from 'components/InputSearch';
import IntegrationLabel from 'components/IntegrationLabel';
import { CreatePersonDataKey } from 'components/PersonDataKey';
import { List, arrayMove } from 'react-movable';
import { ActionButton } from 'components/Button/ActionButton';
import { useTheme } from 'emotion-theming';
import auth from 'services/auth';
import useQuery from 'hooks/useQuery';
import useMutation from 'hooks/useMutation';
import Badge from 'components/Badge';
import PanelPropertyGroupMenuButton from 'components/PanelPropertyGroupMenuButton';
import RenamePanelPropertiesGroupModal from 'components/RenamePanelPropertiesGroupModal';
import DeletePanelPropertiesGroupModal from 'components/DeletePanelPropertiesGroupModal';
import PanelPropertiesGroupsDropdown from 'components/PanelPropertiesGroupsDropdown';
import CreatePanelPropertiesGroupModal from 'components/CreatePanelPropertiesGroupModal';
import { DeletePanelPropertyModal } from 'components/DeletePanelPropertyModal';

function ParticipantsDataPage() {
    const theme = useTheme();
    const { addToast } = useToasts();
    const moveToRef = useRef(null);
    const newPropertyRef = useRef(null);
    const isAdmin = auth.getAuthState().account_role.type === 'admin';
    const [isCreateGroupModalOpen, setIsCreateGroupModalOpen] = useState(false);
    const [groupSettingsMap, setGroupSettingsMap] = useState({});
    const [checkedColumns, setCheckedColumns] = useState([]);
    const [isMoveToDropdownOpen, setIsMoveToDropdownOpen] = useState(false);
    const [updatingGroup, setUpdatingGroup] = useState(null);
    const [deletingGroup, setDeletingGroup] = useState(null);
    const [selectedColumn, setSelectedColumn] = useState(false);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [panelPropertyModal, setPanelPropertyModal] = useState({
        isOpen: false,
        groupId: null
    });
    const [panelPropertyToDelete, setPanelPropertyToDelete] = useState(null);
    const {
        data: panelPropertiesGroups,
        refetch: refetchPanelPropertiesGroups,
        optimisticUpdate: updatePanelPropertiesGroupsOptimistically
    } = useQuery({
        queryFn: services.getPanelPropertiesGroups,
        onSuccess: data => {
            const defaultGroupSetting = {
                isExpanded: true
            };

            // update the group settings map
            setGroupSettingsMap(state => {
                data.forEach(group => {
                    state[group.id] = state[group.id] || defaultGroupSetting;
                });
                return state;
            });
        },
        onError: (_, message) => {
            addToast(message, {
                appearance: 'error',
                autoDismiss: true
            });
        }
    });
    const { mutate: reorderPanelPropertiesGroups } = useMutation({
        mutationFn: orderedGroups => {
            // map the ordered groups to the format that the API expects
            const groups = orderedGroups.map((group, index) => ({
                id: group.id,
                order: index
            }));

            return services.reorderPanelPropertiesGroups({ groups });
        },
        onSuccess: refetchPanelPropertiesGroups,
        onError: (_, message) => {
            refetchPanelPropertiesGroups(); // refetch the groups to reset the order (optimistic update)
            addToast(message, {
                appearance: 'error',
                autoDismiss: true
            });
        }
    });
    const { mutate: movePanelProperties } = useMutation({
        mutationFn: groupId => services.movePanelProperties({ to_group_id: groupId, property_ids: checkedColumns }),
        onSuccess: () => {
            setCheckedColumns([]); // clear the checked columns
            refetchPanelPropertiesGroups();
        },
        onError: (_, message) => {
            addToast(message, {
                appearance: 'error',
                autoDismiss: true
            });
        }
    });
    const { mutate: deletePanelProperty } = useMutation({
        mutationFn: panelPropertyId => services.deleteCustomDataColumn(panelPropertyId),
        onSuccess: () => {
            addToast('Successfully deleted', {
                appearance: 'success',
                autoDismiss: true
            });
            refetchPanelPropertiesGroups();
        },
        onError: (_, errorText) => {
            addToast(errorText, {
                appearance: 'error',
                autoDismiss: true
            });
        }
    });

    const numMatched = useMemo(() => {
        if (!panelPropertiesGroups) return 0;

        // count the number of panel properties
        return panelPropertiesGroups.reduce((acc, group) => {
            return acc + group.person_data_keys.length;
        }, 0);
    }, [panelPropertiesGroups]);

    /**
     * Check or uncheck the property
     *
     * @param {boolean} checked Whether the property is checked
     * @param {number} columnId The column ID
     */
    const checkPanelProperty = (checked, columnId) => {
        if (checked) {
            setCheckedColumns(prev => [...prev, columnId]);
        } else {
            setCheckedColumns(prev => prev.filter(id => id !== columnId));
        }
    };

    function renderAvailableColumns(groupId) {
        let pkt_map = {};
        PERSON_KEY_TYPES.forEach(pkt => {
            pkt_map[pkt.type] = pkt.title;
        });

        const panelPropertiesGroup = panelPropertiesGroups.find(group => group.id === groupId);

        if (!panelPropertiesGroup) throw new Error('Panel properties group not found');

        const availableColumns = panelPropertiesGroup.person_data_keys;

        let itemsList = [];
        availableColumns.forEach(ac => {
            if (!searchKeyword || helpers.findInString(searchKeyword, ac.title)) {
                itemsList.push(ac);
            }
        });

        if (itemsList.length === 0) {
            return <Box p="16px 32px">0 properties, create your first.</Box>;
        }

        let render = (
            <List
                values={itemsList}
                onChange={({ oldIndex, newIndex }) => {
                    console.log(oldIndex, newIndex);
                    itemsList = arrayMove(itemsList, oldIndex, newIndex);
                    updatePanelPropertiesGroupsOptimistically(data => {
                        const groupIndex = data.findIndex(group => group.id === groupId);
                        data[groupIndex].person_data_keys = itemsList;
                        return data;
                    });
                    saveAvailableColumnsOrderXHR(itemsList, groupId);
                }}
                renderList={({ children, props }) => <div {...props}>{children}</div>}
                renderItem={({ value, index, props, isDragged, isSelected }) => {
                    let ac = value;

                    let reorderElement = (
                        <Button
                            data-movable-handle
                            onDrag={event => event.preventDefault()}
                            type="button"
                            variant="secondary-gray"
                            className="secondary-icon"
                            tabIndex={-1}
                            mr={2}
                            style={{
                                cursor: isDragged ? 'grabbing' : 'grab'
                            }}
                        >
                            <FiMove />
                        </Button>
                    );

                    return (
                        <div {...props}>
                            <ItemWrapper style={{ color: 'black' }} key={ac.id}>
                                <Label
                                    sx={{
                                        cursor: 'pointer',
                                        margin: '0px !important',
                                        svg: {
                                            margin: '0px !important'
                                        }
                                    }}
                                >
                                    <Checkbox
                                        onChange={event => checkPanelProperty(event.target.checked, ac.id)}
                                        checked={checkedColumns.includes(ac.id)}
                                    />
                                </Label>
                                <Box width={1 / 4}>
                                    {ac.title}
                                    {ac.integrations.map(i => {
                                        switch (i.external) {
                                            case INTEGRATION_SALESFORCE:
                                                return (
                                                    <IntegrationLabel
                                                        key={i.external_id}
                                                        integration={INTEGRATION_SALESFORCE}
                                                        labelText={i.external_id}
                                                    />
                                                );
                                            default:
                                                return null;
                                        }
                                    })}
                                </Box>
                                <Box width={1 / 8}>{pkt_map[ac.type]}</Box>
                                <Box width={1 / 8}>
                                    {ac.pii_masked ? (
                                        <Text style={{ color: '#606e85' }}>
                                            <FiEyeOff style={{ margin: '0px 8px 2px 0px' }} />
                                            Hidden
                                        </Text>
                                    ) : (
                                        <Text>
                                            <FiEye
                                                style={{
                                                    margin: '0px 8px 2px 0px',
                                                    color: theme.colors.primary
                                                }}
                                            />
                                            Visible
                                        </Text>
                                    )}
                                </Box>
                                <Box
                                    width={1 / 8}
                                    style={{ maxHeight: '200px', overflow: 'scroll', userSelect: 'text' }}
                                >
                                    {(ac.type == 'dropdown' || ac.type == 'checkbox') &&
                                        ac.definition.map(d => {
                                            if (!d) {
                                                return;
                                            }
                                            return <div key={d.label}>{d.label}</div>;
                                        })}
                                </Box>
                                <Box width={1 / 4}>
                                    {reorderElement}
                                    {isAdmin && (
                                        <Button
                                            type="button"
                                            mr={2}
                                            variant="secondary-gray"
                                            onClick={e => {
                                                setSelectedColumn(ac);
                                                setPanelPropertyModal({
                                                    isOpen: true,
                                                    groupId: groupId
                                                });
                                            }}
                                        >
                                            <FiEdit /> Edit
                                        </Button>
                                    )}
                                    {isAdmin && ac.can_delete == true && (
                                        <Button
                                            type="button"
                                            variant="secondary-gray"
                                            className="secondary-icon"
                                            onClick={() => setPanelPropertyToDelete(ac)}
                                        >
                                            <FiTrash2 />
                                        </Button>
                                    )}
                                </Box>
                            </ItemWrapper>
                        </div>
                    );
                }}
            />
        );

        return render;
    }

    /**
     * @deprecated
     */
    function getAvailableColumnsXHR(bust_cache) {
        services
            .getCustomDataColumnsXHR(bust_cache)
            .then(columns => {
                // sort by the "order"
                columns.sort((a, b) => {
                    if (a.order < b.order) return -1;
                    if (a.order > b.order) return 1;
                    return 0;
                });
                setAvailableColumns(columns);
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);

                addToast(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    /**
     * Toggle the group expansion
     *
     * @param {number} groupId The group ID
     */
    const toggleGroup = groupId => {
        setGroupSettingsMap(state => {
            return {
                ...state,
                [groupId]: {
                    ...state[groupId],
                    isExpanded: !state[groupId].isExpanded
                }
            };
        });
    };

    function saveAvailableColumnsOrderXHR(itemsList, groupId) {
        itemsList.forEach((item, index) => {
            item.order = index;
        });

        services
            .saveCustomDataColumnsOrder(itemsList, groupId)
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);

                addToast(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            })
            .finally(() => {
                refetchPanelPropertiesGroups(); // refetch the groups to reset the order (optimistic update)
            });
    }

    function onChangeTitle(key, val) {
        setSelectedColumn(prev => ({ ...prev, [key]: val }));
    }

    function onSearchKeywordChange(e) {
        setSearchKeyword(e.target.value);
    }

    /**
     * Close the property modal (edit or create)
     */
    const onPropertyModalClose = () => {
        setPanelPropertyModal({ isOpen: false, groupId: null });
        setSelectedColumn(false);
        refetchPanelPropertiesGroups();
    };

    return (
        <Flex style={{ width: '100%' }}>
            <AppPageWrapper>
                <Helmet>
                    <title>Panel - Panelist Properties</title>
                </Helmet>

                <Flex>
                    <ParticipantsPageSubNav />
                </Flex>

                <AppPageWrapperSectionHeader className="experience">
                    <Box>
                        <H1>Panel Properties</H1>
                        <Box className="experience-description">
                            Create as many properties as your panel needs.{' '}
                            <a
                                href="#"
                                data-beacon-article="63032877abed727489465a04"
                                className="help-question"
                                style={{ display: 'inline-block', margin: 0 }}
                            >
                                ?
                            </a>
                        </Box>
                    </Box>
                    <Flex mt={2} sx={{ gap: 2 }}>
                        <Button variant="secondary-gray" onClick={() => setIsCreateGroupModalOpen(true)}>
                            <FiFolderPlus /> New group
                        </Button>
                        <ActionButton
                            ref={newPropertyRef}
                            title="New Property"
                            action={() => {
                                setSelectedColumn(false);
                                setPanelPropertyModal({ groupId: null, isOpen: true });
                            }}
                            keyLabel="N"
                            mr={0}
                        />
                    </Flex>
                </AppPageWrapperSectionHeader>
                <AppPageWrapperSectionBody>
                    <Box mx={4} my={4} width="100%">
                        <Flex mb="12px" py="8px" alignItems="center" sx={{ gap: '24px' }}>
                            <Box as="p" className="color-dark fs-title-16">
                                {numMatched} {numMatched === 1 ? 'property' : 'properties'} matched
                            </Box>
                            {checkedColumns.length > 0 && (
                                <>
                                    <Button
                                        ref={moveToRef}
                                        variant="secondary-gray"
                                        onClick={() => setIsMoveToDropdownOpen(true)}
                                    >
                                        Move to <FiChevronDown />
                                    </Button>
                                    <PanelPropertiesGroupsDropdown
                                        controllerRef={moveToRef}
                                        isVisible={isMoveToDropdownOpen}
                                        onClose={() => setIsMoveToDropdownOpen(false)}
                                        panelPropertiesGroups={panelPropertiesGroups}
                                        onClick={groupId => movePanelProperties(groupId)}
                                        onCreateClick={() => setIsCreateGroupModalOpen(true)}
                                    />
                                </>
                            )}
                            <Box flexGrow={1} />
                            <InputSearch value={searchKeyword} onChange={onSearchKeywordChange} />
                        </Flex>
                        <List
                            values={panelPropertiesGroups || []}
                            renderList={({ children, props, isDragged }) => <div {...props}>{children}</div>}
                            renderItem={({ isDragged, props, value: group }) => {
                                const setting = groupSettingsMap[group.id];

                                // if the group is not expanded, skip rendering
                                if (!setting) return null;

                                return (
                                    <Box key={group.id} {...props} marginBottom="24px">
                                        <Flex alignItems="center" sx={{ gap: 2 }}>
                                            <Button variant="transparent-icon" onClick={() => toggleGroup(group.id)}>
                                                <FiChevronDown
                                                    transform={setting.isExpanded ? undefined : 'rotate(-90)'}
                                                />
                                            </Button>
                                            <Box as="h2" margin={0} className="fs-title-16">
                                                {group.name}
                                            </Box>
                                            <Badge
                                                type="pure"
                                                className="fs-accent-12 color-text-secondary--important"
                                                css={{
                                                    padding: '0px 4px',
                                                    minHeight: '16px'
                                                }}
                                            >
                                                {group.person_data_keys.length}
                                            </Badge>
                                            <Box flexGrow={1} />
                                            <Button
                                                sx={{ display: 'flex', gap: '6px', alignItems: 'center' }}
                                                variant="link"
                                                onClick={() =>
                                                    setPanelPropertyModal({ groupId: group.id, isOpen: true })
                                                }
                                            >
                                                <FiPlus style={{ margin: 0 }} />
                                                New Property
                                            </Button>
                                            <Button
                                                variant="transparent-icon"
                                                data-movable-handle
                                                style={{
                                                    // display: isGeneralGroup ? 'none' : 'block', // hide this for fix the bug of drag and drop
                                                    cursor: isDragged ? 'grabbing' : 'grab'
                                                }}
                                            >
                                                <FiMove className="color-primary" />
                                            </Button>
                                            <PanelPropertyGroupMenuButton
                                                onRemoveClick={() => setDeletingGroup(group)}
                                                onRenameClick={() => setUpdatingGroup(group)}
                                            />
                                        </Flex>
                                        <ListWrapper
                                            style={{
                                                marginTop: '16px',
                                                position: 'relative',
                                                border: '1px solid #eee',
                                                borderRadius: '4px',
                                                display: setting.isExpanded ? 'block' : 'none'
                                            }}
                                            className="bg-white"
                                        >
                                            <ItemWrapper className="header-row">
                                                <Box width="24px" />
                                                <Box width={1 / 4}>Title</Box>
                                                <Box width={1 / 8}>Type</Box>
                                                <Box width={1 / 8}>PII Masking</Box>
                                                <Box width={1 / 8}>Definition</Box>
                                                <Box width={1 / 4}>Actions</Box>
                                            </ItemWrapper>
                                            {renderAvailableColumns(group.id)}
                                        </ListWrapper>
                                    </Box>
                                );
                            }}
                            onChange={({ oldIndex, newIndex }) => {
                                // reorder the groups and update the state
                                const groups = arrayMove(panelPropertiesGroups, oldIndex, newIndex);

                                updatePanelPropertiesGroupsOptimistically(() => groups);
                                reorderPanelPropertiesGroups(groups);
                            }}
                        />
                    </Box>

                    <NiceModal
                        isOpen={panelPropertyModal.isOpen}
                        shouldCloseOnOverlayClick
                        onRequestClose={onPropertyModalClose}
                        title={`${selectedColumn ? 'Edit' : 'Create a new'} property`}
                    >
                        <CreatePersonDataKey
                            edit={selectedColumn}
                            defaultGroupId={panelPropertyModal.groupId}
                            onSuccess={() => {
                                refetchPanelPropertiesGroups();
                                setPanelPropertyModal({ isOpen: false, groupId: null });
                            }}
                            onClose={onPropertyModalClose}
                        />
                    </NiceModal>
                </AppPageWrapperSectionBody>
            </AppPageWrapper>
            <CreatePanelPropertiesGroupModal
                isOpen={isCreateGroupModalOpen}
                onClose={() => setIsCreateGroupModalOpen(false)}
                onSuccess={refetchPanelPropertiesGroups}
            />
            {updatingGroup && (
                <RenamePanelPropertiesGroupModal
                    group={updatingGroup}
                    onClose={() => setUpdatingGroup(null)}
                    onSuccess={refetchPanelPropertiesGroups}
                />
            )}
            {deletingGroup && (
                <DeletePanelPropertiesGroupModal
                    group={deletingGroup}
                    onClose={() => setDeletingGroup(null)}
                    onSuccess={refetchPanelPropertiesGroups}
                />
            )}
            {panelPropertyToDelete && (
                <DeletePanelPropertyModal
                    isOpen
                    panelPropertyName={panelPropertyToDelete.title}
                    onDelete={() => deletePanelProperty(panelPropertyToDelete.id)}
                    onClose={() => setPanelPropertyToDelete(null)}
                />
            )}
        </Flex>
    );
}

export default ParticipantsDataPage;
