import React, { useMemo } from 'react';
import classNames from 'classnames';
import { FiAlertCircle, FiAlertTriangle } from 'react-icons/fi';
import moment from 'moment-timezone';

import NotificationCard from 'components/NotificationCard';

export const explainer = {
    format: 'Bad e-mail format',
    bounce: 'Hard bounce',
    spam: 'Spam complaint'
};

/**
 * Render email issue notification. If panelist has no email issue, render nothing.
 *
 * @param {Object} props
 * @param {Object} props.panelist panelist
 * @param {string | undefined} props.className className
 * @returns {React.ReactNode} EmailIssueNotification component
 */
export function EmailIssueNotification({ className, panelist }) {
    const contents = useMemo(() => {
        if (!(panelist.email_status && panelist.email_status.length > 0)) return [];

        return panelist.email_status.map(
            es =>
                `${explainer[es.status]} for ${es.email} when e-mailed on ${moment
                    .utc(es.created_at)
                    .format('MMM Do, YYYY [at] h:mma')}`
        );
    }, [panelist]);

    return (
        <>
            {contents.map(content => (
                <NotificationCard
                    key={content}
                    className={classNames('color-dark', className)}
                    type="danger"
                    icon={<FiAlertCircle />}
                    title="Email issues"
                    content={content}
                />
            ))}
        </>
    );
}

export default EmailIssueNotification;
