import { useRef, useEffect } from 'react';

/**
 * Custom hook to handle the Beacon button
 *
 * @param options - an object that contains the hook options
 * @param options.onLoaded - callback function to be executed once the button is loaded
 * @returns a reference to the Beacon button
 */
export const useBeaconButton = options => {
    const buttonRef = useRef(null);

    useEffect(() => {
        const checkIfButtonIsLoaded = setInterval(() => {
            buttonRef.current = document.querySelector('.Beacon');

            if (buttonRef.current) {
                // Stop the interval
                clearInterval(checkIfButtonIsLoaded);

                // If a callback was passed, call it
                if (options.onLoaded) {
                    options.onLoaded(buttonRef.current);
                }
            }
        }, 100);

        return () => clearInterval(checkIfButtonIsLoaded);
    }, []);

    return buttonRef;
};

export default useBeaconButton;
