import React from 'react';
import { Box } from 'rebass';
import PropTypes from 'prop-types';

Redirect.propTypes = {
    location: PropTypes.string.isRequired,
    text: PropTypes.string
};

function Redirect({ location, text }) {
    window.location.assign(location);

    return (
        <Box display="grid" height="100vh" width="100vw" sx={{ justifyContent: 'center', alignContent: 'center' }}>
            {text || 'Redirecting...'}
        </Box>
    );
}

export default Redirect;
