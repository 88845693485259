import React from 'react';
import helpers from 'utils/helpers';
import { Flex, Box, Button } from 'rebass';
import ReactDOM from 'react-dom';
import { DndProvider } from 'react-dnd';
import { HTML5Backend } from 'react-dnd-html5-backend';
import Preview from './preview';
import ReactFormGenerator from './form';
import store from './stores/store';

import { Label, Input } from '@rebass/forms';

class ReactFormBuilder extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            editMode: false,
            editElement: null
        };
    }

    editModeOn(data, e) {
        e.preventDefault();
        e.stopPropagation();
        if (this.state.editMode) {
            this.setState({ editMode: !this.state.editMode, editElement: null });
        } else {
            this.setState({ editMode: !this.state.editMode, editElement: data });
        }
    }

    manualEditModeOff() {
        if (this.state.editMode) {
            this.setState({
                editMode: false,
                editElement: null
            });
        }
    }

    componentDidCatch(error, errorInfo) {
        helpers.trackError(error, { errorInfo: errorInfo });
    }

    render() {
        return (
            <DndProvider backend={HTML5Backend}>
                <div className="react-form-builder">
                    <Box>
                        <Preview
                            isEmailRequired={this.props.isEmailRequired}
                            uuid={this.props.uuid}
                            files={this.props.files}
                            manualEditModeOff={this.manualEditModeOff.bind(this)}
                            showCorrectColumn={this.props.showCorrectColumn}
                            parent={this}
                            data={this.props.data}
                            url={this.props.url}
                            saveUrl={this.props.saveUrl}
                            onLoad={this.props.onLoad}
                            onPost={this.props.onPost}
                            onChange={this.props.onChange}
                            editModeOn={this.editModeOn}
                            editMode={this.state.editMode}
                            variables={this.props.variables}
                            editElement={this.state.editElement}
                            read_only={this.props.read_only}
                            page_logic={this.props.page_logic}
                            screener_for={this.props.screener_for}
                            form_updated_at={this.props.form_updated_at}
                            form_type={this.props.form_type}
                        />
                    </Box>
                </div>
            </DndProvider>
        );
    }
}

const FormBuilders = {};
FormBuilders.ReactFormBuilder = ReactFormBuilder;
FormBuilders.ReactFormGenerator = ReactFormGenerator;
FormBuilders.ElementStore = store;

export default FormBuilders;

export { ReactFormBuilder, ReactFormGenerator, store as ElementStore };
