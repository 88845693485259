import React, { useState } from 'react';
import styled from 'styled-components';
import { Box, Button } from 'rebass';
import { FiUserPlus, FiPlus, FiUploadCloud, FiUsers, FiLayers } from 'react-icons/fi';
import NiceDropdown from 'components/NiceDropdown';

function AddCandidates(props) {
    const [showCreateMenu, setShowCreateMenu] = useState(false);

    return (
        <Box style={{ position: 'relative' }}>
            <Button
                variant={props.numStudyPeople > 0 ? 'tertiary' : 'primary'}
                sx={{ width: '100%' }}
                className="react-kanban-card-adder-button"
                onClick={() => setShowCreateMenu(!showCreateMenu)}
            >
                <FiUserPlus /> Add candidates{' '}
                <span
                    className={props.numStudyPeople > 0 ? 'arrow-down-dark' : 'arrow-down'}
                    style={{ marginLeft: '4px' }}
                />
            </Button>
            {showCreateMenu ? (
                <NiceDropdown
                    width="224px"
                    positionLeft="auto"
                    positionRight="0px"
                    onClose={() => setShowCreateMenu(!showCreateMenu)}
                    items={[
                        {
                            title: 'Create new candidate',
                            icon: <FiPlus />,
                            onClick: () => {
                                props.onCreatePerson();
                                setShowCreateMenu(false);
                            }
                        },
                        /*{
                            title: 'Import candidates',
                            icon: <FiUploadCloud />,
                            onClick: () => {
                                props.onShowUploadParticipants();
                                setShowCreateMenu(false);
                            }
                        },*/
                        {
                            title: 'Go to Panel',
                            icon: <FiUsers />,
                            tooltip: (
                                <div>
                                    Filter your panel, then click{' '}
                                    <span style={{ fontWeight: 500 }}>
                                        {' '}
                                        <FiLayers /> Put in study
                                    </span>
                                </div>
                            ),
                            onClick: () => {
                                window.location = '/panel';
                            }
                        }
                    ]}
                />
            ) : (
                ''
            )}
        </Box>
    );
}

export default AddCandidates;
