import styled from 'styled-components';
import React from 'react';
import { Button } from 'rebass';
import { FiX, FiXCircle } from 'react-icons/fi';
import NiceDropdown from 'components/NiceDropdown';
import moment from 'moment-timezone';
import helpers from 'utils/helpers';
import { PANEL_FILTER_TYPES } from 'utils/constants';

const EMAIL_STATUS_AVAILABLE = [
    {
        id: 'spam',
        title: 'Spam Complaint'
    },
    {
        id: 'bounce',
        title: 'Bounce'
    },
    {
        id: 'format',
        title: 'Bad Format'
    }
];

const FilterWrapper = styled.div`
    background: #f6f6f6;
    padding: 0 12px 0 12px;
    border-radius: 4px;
    display: inline-block;
    margin: 0;
    position: relative;
    line-height: 25px;

    &.isOpen {
        background: rgba(10, 115, 235, 0.1);

        .filterLabel {
            //color: #0a73eb !important;
        }
    }

    &.isInvalid {
        background: rgba(255, 0, 0, 0.1);

        .filterLabel {
            color: rgb(255, 0, 0) !important;
        }
    }

    .filter-delete {
        height: 30px;
        display: inline-block;
        padding: 0 0 0 4px;
        svg {
            margin-top: 2px;
            color: #999;
        }
    }
`;
const StyleFilterPiece = {
    display: 'inline-block',
    padding: '0',
    position: 'relative'
};
const StyleFilterLabel = styled.div`
    display: inline-block;
    cursor: pointer;
    padding: 4px 0;

    &:hover {
        color: #0a73eb;
    }
`;

class FilterConditional extends React.Component {
    constructor(props) {
        super(props);

        let tag_ids_included_checked = [];
        if (
            this.props.filter.id == 'panelfox_tag' ||
            this.props.filter.id == 'panelfox_tag_exclude' ||
            this.props.filter.id == 'panelfox_tag_study' ||
            this.props.filter.id == 'panelfox_tag_study_exclude'
        ) {
            tag_ids_included_checked = this.props.filter.values;
        }

        let email_status_checked = [];
        if (this.props.filter.id == 'email_status') {
            email_status_checked = this.props.filter.values;
        }

        this.state = {
            showFilterIdDropdown: false,
            showFilterCompareDropdown: this.props.filter.isOpen,
            tag_ids_included_checked: tag_ids_included_checked,
            email_status_checked: email_status_checked,
            outerRef: null,
            innerRef: null
        };
        this.handleToggleSelect = this.handleToggleSelect.bind(this);
        this.renderFilterPiece = this.renderFilterPiece.bind(this);

        this.contentEditableValues = {
            1: React.createRef(),
            2: React.createRef()
        };

        this.firstFilterPieceRef = React.createRef();
        this.secondFilterPieceRef = React.createRef();
        this.outerFilterWrapperRef = React.createRef();
    }

    getDistanceBetween(first, second) {
        if (first && first.current && second && second.current) {
            const firstPos = first.current.getBoundingClientRect();
            const secondPos = second.current.getBoundingClientRect();
            const diff = firstPos.x - secondPos.x;
            //console.log('thisss', diff);
            return diff;
        }
        return 0;

        // this.outerFilterWrapperRef = React.createRef();
    }

    renderFilterCompareOptions() {
        const renderItems = [];
        this.props.filterCompareOptions.forEach(filterOptgroup => {
            const renderOptions = [];
            filterOptgroup.options.forEach(option => {
                renderOptions.push(<option value={option.key}>{option.label}</option>);
            });
            renderItems.push(<optgroup label={filterOptgroup.optgroup}>{renderOptions}</optgroup>);
        });

        return renderItems;
    }

    getFilterTitleById(id) {
        const f = this.getFilterById(id);
        if (f && f.title) {
            return f.title;
        }
        return '';
    }

    getFilterById(id) {
        if (id == 'panelfox_tag') {
            return { title: PANEL_FILTER_TYPES['panelfox_tag'] };
        } else if (id == 'panelfox_tag_exclude') {
            return { title: PANEL_FILTER_TYPES['panelfox_tag_exclude'] };
        } else if (id == 'panelfox_tag_study') {
            return { title: PANEL_FILTER_TYPES['panelfox_tag_study'] };
        } else if (id == 'panelfox_tag_study_exclude') {
            return { title: PANEL_FILTER_TYPES['panelfox_tag_study_exclude'] };
        } else if (id == 'email_status') {
            return { title: PANEL_FILTER_TYPES['email_status'] };
        } else if (
            id == 'panelfox_study_includes' ||
            id == 'panelfox_study_excludes' ||
            id == 'panelfox_study_added_includes' ||
            id == 'panelfox_study_added_excludes'
        ) {
            const result = this.props.availableColumns.find(obj => obj.id == id);
            return result;
        } else {
            const result = this.props.availableColumns.find(obj => obj.id == id);
            if (result) {
                return result;
            }
            return {};
        }
    }

    getFilterCompareById(id) {
        let text = '';
        const found = this.props.filterCompareOptions.find(obj => obj.id == id);
        if (found) {
            text = found.title;
        }

        if (this.props.filter.compare == 'is_unknown' || this.props.filter.compare == 'has_any_value') {
            // there take no value, so skip!
        } else {
            this.props.filter.values.forEach((value, i) => {
                if (i >= 1) {
                    text += ['between', 'is_not', 'does_not_contain'].includes(this.props.filter.compare)
                        ? ' and'
                        : ' or';
                }

                let val_text = '';

                if (['before_date', 'after_date', 'on_date'].includes(found.id)) {
                    // console.log(found.id, value, moment(value).format('L'))
                    val_text = value ? moment(value).format('ll') : value;
                } else {
                    val_text = this.getFilterLabelByValue(value);
                }
                text += ` ${val_text}`;
            });
        }

        return text;
    }

    getFilterLabelByValue(value) {
        const filter = this.getFilterById(this.props.filter.id);
        if (['checkbox', 'dropdown'].includes(filter.type) && Array.isArray(filter.definition)) {
            const selected = filter.definition.find(opt => opt.value == value);
            if (selected) {
                return selected.label;
            }
        }
        return value;
    }

    getFilterTagById(id) {
        const result = this.props.tags.find(obj => obj.id == id);
        if (result) {
            return result.title;
        }
        return '';
    }

    getFilterStudyById(id) {
        const result = this.props.studies.find(obj => obj.id == id);
        //console.log('getFilterStudyById', id, this.props.studies, result);
        if (result) {
            return result.title;
        }
        return '';
    }

    handleToggleSelect(which_dd) {
        const { state } = this;
        state[which_dd] = !state[which_dd];
        this.setState(state);
    }

    validFilter() {
        if (
            ['is_unknown', 'has_any_value', 'is_true', 'is_false', 'is_duplicate'].includes(this.props.filter.compare)
        ) {
            return true;
        }

        if (this.state.showFilterCompareDropdown == true) {
            // if modal is showing.. assume they're editing
            return true;
        }
        if (!this.props.filter.values || this.props.filter.values.length == 0) {
            return false;
        }
        let hasEmpty = false;
        this.props.filter.values.forEach(val => {
            if (val == '') {
                hasEmpty = true;
            }
        });
        return !hasEmpty;
    }

    getTagsIncludedChecked(tag_type) {
        let tags_with_checked = [];
        this.props.tags.forEach(tag => {
            if (tag_type == tag.type) {
                const tag_found = this.state.tag_ids_included_checked.find(id => id == tag.id);
                tags_with_checked.push({
                    ...tag,
                    checked: tag_found ? true : false
                });
            }
        });

        //console.log('tags_with_checked', tags_with_checked, tag_type)

        return tags_with_checked;
    }

    getStudiesChecked(key) {
        let studies_with_checked = [];
        this.props.studies.forEach(study => {
            const study_found = this.props.filter.values.find(id => id == study.id);
            studies_with_checked.push({
                ...study,
                checked: study_found ? true : false
            });
        });
        return studies_with_checked;
    }

    onChangeCheckbox(studyIds) {
        this.props.handleUpdateFilterValue(this.props.group_index, this.props.index, studyIds);
    }

    renderFilterPiece() {
        if (
            this.props.filter.id == 'panelfox_tag' ||
            this.props.filter.id == 'panelfox_tag_exclude' ||
            this.props.filter.id == 'panelfox_tag_study' ||
            this.props.filter.id == 'panelfox_tag_study_exclude'
        ) {
            let tag_type = 'Person';
            if (this.props.filter.id == 'panelfox_tag_study' || this.props.filter.id == 'panelfox_tag_study_exclude') {
                tag_type = 'Study';
            }

            return (
                <>
                    <StyleFilterLabel
                        className="filterLabel text-secondary"
                        onClick={() => this.handleToggleSelect('showFilterCompareDropdown')}
                    >
                        <span style={{ padding: '0 2px' }}>is</span>
                        {this.props.filter.values && this.props.filter.values.length ? (
                            this.props.filter.values.map((v, index) => {
                                return (
                                    (index > 0 && index < this.props.filter.values.length ? ' or ' : '') +
                                    this.getFilterTagById(v)
                                );
                            })
                        ) : (
                            <em>missing value</em>
                        )}
                    </StyleFilterLabel>

                    {this.state.showFilterCompareDropdown == true && (
                        <NiceDropdown
                            renderDoneButton
                            adjustHeight
                            width={'340px'}
                            onClose={() => {
                                this.setState({ showFilterCompareDropdown: false });
                            }}
                            showSearch
                            listType="checkbox"
                            //items={this.props.tags}
                            items={this.getTagsIncludedChecked(tag_type)}
                            value={this.props.filter.values}
                            onChange={value => {
                                this.onChangeCheckbox(value);
                            }}
                            positionLeft={this.getDistanceBetween(
                                this.outerFilterWrapperRef,
                                this.secondFilterPieceRef
                            )}
                        />
                    )}
                </>
            );
        }

        if (
            [
                'panelfox_study_includes',
                'panelfox_study_excludes',
                'panelfox_study_added_includes',
                'panelfox_study_added_excludes'
            ].includes(this.props.filter.id)
        ) {
            let panelfox_study_includes_HUMAN_READABLE = [];
            if (this.props.filter.values.length) {
                this.props.filter.values.forEach((studyId, key) => {
                    let study_title = this.getFilterStudyById(studyId);
                    if (study_title) {
                        if (key > 0 && key < this.props.filter.values.length) {
                            panelfox_study_includes_HUMAN_READABLE.push(' or ');
                        }
                        panelfox_study_includes_HUMAN_READABLE.push(study_title);
                    }
                });
            } else {
                panelfox_study_includes_HUMAN_READABLE.push(<em>missing value</em>);
            }

            return (
                <>
                    <StyleFilterLabel
                        className="filterLabel text-secondary"
                        onClick={() => this.handleToggleSelect('showFilterCompareDropdown')}
                    >
                        is {panelfox_study_includes_HUMAN_READABLE}
                    </StyleFilterLabel>

                    {this.state.showFilterCompareDropdown == true && (
                        <NiceDropdown
                            renderDoneButton
                            adjustHeight
                            width={'340px'}
                            onClose={() => {
                                this.setState({ showFilterCompareDropdown: false });
                            }}
                            showSearch
                            listType="checkbox"
                            items={this.getStudiesChecked(this.props.filter.id)}
                            value={this.props.filter.values}
                            onChange={value => {
                                this.onChangeCheckbox(value);
                            }}
                            positionLeft={this.getDistanceBetween(
                                this.outerFilterWrapperRef,
                                this.secondFilterPieceRef
                            )}
                        />
                    )}
                </>
            );
        }

        if (this.props.filter.id == 'email_status') {
            let email_status_HUMAN_READABLE = [];
            if (this.props.filter.values.length) {
                this.props.filter.values.forEach((id, key) => {
                    let status_found = EMAIL_STATUS_AVAILABLE.find(esa => esa.id == id);
                    if (status_found) {
                        if (key > 0 && key < this.props.filter.values.length) {
                            email_status_HUMAN_READABLE.push(' or ');
                        }
                        console.log(status_found);
                        email_status_HUMAN_READABLE.push(status_found.title);
                    }
                });
            } else {
                email_status_HUMAN_READABLE.push(<em>missing value</em>);
            }

            //console.log('email_status_checked', this.state.email_status_checked)

            let email_status_with_checked = EMAIL_STATUS_AVAILABLE;
            email_status_with_checked.forEach((obj, id) => {
                //console.log('obj',obj)
                const isChecked = this.state.email_status_checked.find(label => label == obj.id);
                //console.log(isChecked)
                obj.checked = isChecked ? true : false;
            });

            return (
                <>
                    <StyleFilterLabel
                        className="filterLabel text-secondary"
                        onClick={() => this.handleToggleSelect('showFilterCompareDropdown')}
                    >
                        is {email_status_HUMAN_READABLE}
                    </StyleFilterLabel>

                    {this.state.showFilterCompareDropdown == true && (
                        <NiceDropdown
                            renderDoneButton
                            adjustHeight
                            width={'340px'}
                            onClose={() => {
                                this.setState({ showFilterCompareDropdown: false });
                            }}
                            showSearch
                            listType="checkbox"
                            items={email_status_with_checked}
                            value={this.props.filter.values}
                            onChange={value => {
                                this.onChangeCheckbox(value);
                            }}
                            positionLeft={this.getDistanceBetween(
                                this.outerFilterWrapperRef,
                                this.secondFilterPieceRef
                            )}
                        />
                    )}
                </>
            );
        }

        return (
            <>
                <StyleFilterLabel
                    className="filterLabel text-secondary"
                    onClick={() => this.handleToggleSelect('showFilterCompareDropdown')}
                >
                    {this.getFilterCompareById(this.props.filter.compare)}
                </StyleFilterLabel>

                {this.state.showFilterCompareDropdown == true && (
                    <NiceDropdown
                        width={'340px'}
                        adjustHeight
                        listType="panel-filters-radios"
                        onClose={() => {
                            this.setState({ showFilterCompareDropdown: false });
                        }}
                        column={this.getFilterById(this.props.filter.id)}
                        items={this.props.filterCompareOptions}
                        value={this.props.filter.compare}
                        value_secondary={this.props.filter.values}
                        onChange={(compare_value, values) => {
                            //console.log('FilterConditional',compare_value, values)
                            this.props.handleUpdateFilterCompare(
                                this.props.group_index,
                                this.props.index,
                                compare_value
                            );
                            // pass in the values array
                            this.props.handleUpdateFilterValue(this.props.group_index, this.props.index, values);

                            /*if (values && values.length) {
                                console.log('CALLBACK', values)
                                values.forEach((v, i) => {

                                });
                            }*/
                        }}
                        positionLeft={this.getDistanceBetween(this.outerFilterWrapperRef, this.secondFilterPieceRef)}
                    />
                )}
            </>
        );
    }

    componentDidMount() {
        this.setState({ outerRef: this.outerFilterWrapperRef, innerRef: this.firstFilterPieceRef });
    }

    render() {
        return (
            <FilterWrapper
                ref={this.outerFilterWrapperRef}
                className={`${
                    this.state.showFilterIdDropdown || this.state.showFilterCompareDropdown ? 'isOpen' : ''
                } ${!this.validFilter() ? 'isInvalid' : ''}`}
            >
                <div style={StyleFilterPiece} ref={this.firstFilterPieceRef}>
                    <StyleFilterLabel
                        className="filterLabel text-primary"
                        style={{ fontWeight: 500, marginRight: '4px' }}
                        onClick={() => this.handleToggleSelect('showFilterIdDropdown')}
                    >
                        {this.getFilterTitleById(this.props.filter.id)}
                    </StyleFilterLabel>
                    {this.state.showFilterIdDropdown == true && (
                        <NiceDropdown
                            adjustHeight
                            width={'340px'}
                            onClose={() => {
                                this.setState({ showFilterIdDropdown: false });
                            }}
                            showSearch
                            items={this.props.availableColumns}
                            value={this.props.filter.id}
                            onChange={value => {
                                this.props.handleUpdateFilterId(this.props.group_index, this.props.index, value);
                            }}
                            positionLeft={this.getDistanceBetween(this.state.outerRef, this.state.innerRef)}
                        />
                    )}
                </div>
                <div style={StyleFilterPiece} ref={this.secondFilterPieceRef}>
                    {this.renderFilterPiece()}
                </div>

                <Button
                    variant="link"
                    className="muted"
                    onClick={() => {
                        this.props.handleRemoveFilter(this.props.group_index, this.props.index);
                    }}
                    style={{ height: '28px', borderRadius: 0 }}
                >
                    <FiX style={{ margin: '4px 0 0 4px', fontSize: '15px', strokeWidth: '1.5px' }} />
                </Button>
            </FilterWrapper>
        );
    }
}

export default FilterConditional;
