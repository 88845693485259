import NiceModal from 'components/NiceModal';
import React from 'react';
import { Box, Flex, Button } from 'rebass';
import { Input, Textarea } from '@rebass/forms';
import { useToasts } from 'react-toast-notifications';

import { useForm } from 'hooks/useForm';
import useMutation from 'hooks/useMutation';
import services from 'services/services';
import { AttachedFileStrip } from 'components/AttachedFileStrip';
import helpers from 'utils/helpers';

export function EditFileModal({ personId, attachedFile, onClose, isOpen, onUpdate }) {
    const { addToast } = useToasts();
    const { mutate: updateFile, isLoading: isUpdating } = useMutation({
        mutationFn: async variables => services.updatePersonFile(personId, attachedFile.id, variables),
        onSuccess: onUpdate
    });
    const { register, handleSubmit } = useForm({
        initialForm: {
            description: attachedFile.description
        }
    });

    const onSubmit = handleSubmit(async data => {
        await updateFile({
            description: data.description
        });
        addToast('File successfully updated', { appearance: 'success', autoDismiss: true });
        onClose();
    });

    return (
        <NiceModal style={{ content: { width: '620px' } }} isOpen={isOpen} title="Edit file" onRequestClose={onClose}>
            <AttachedFileStrip
                css={{
                    marginBottom: '16px !important',
                    borderBottom: 'none !important',
                    paddingBottom: '0px !important'
                }}
                description={attachedFile.description}
                title={attachedFile.title}
                updatedAt={attachedFile.updated_at}
                isLoading={isUpdating}
                fileExtension={helpers.extractFileExtension(attachedFile.title)}
                study={attachedFile.study}
            />
            <form onSubmit={onSubmit}>
                <Box mb="16px" opacity="0.4">
                    <Box className="form-label">Name file</Box>
                    <Box>
                        <Input disabled type="text" value={attachedFile.title} />
                    </Box>
                </Box>
                <Box mb="24px">
                    <Box className="form-label">Description</Box>
                    <Box>
                        <Textarea
                            {...register('description')}
                            disabled={isUpdating}
                            type="text"
                            placeholder="Description"
                        />
                    </Box>
                </Box>
                <Flex justifyContent="space-between" sx={{ gap: '8px' }}>
                    <Box flexGrow={1} />
                    <Button type="button" onClick={onClose} variant="secondary-gray">
                        Cancel
                    </Button>
                    <Button type="submit" sx={{ marginRight: 0 }} disabled={isUpdating} variant="primary">
                        Save file
                    </Button>
                </Flex>
            </form>
        </NiceModal>
    );
}

export default EditFileModal;
