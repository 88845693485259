import React from 'react';
import { Label } from '@rebass/forms';
import { useToasts } from 'react-toast-notifications';
import { Box } from 'rebass';
import moment from 'moment-timezone';

import useQuery from 'hooks/useQuery';
import NiceSelect from 'components/NiceSelect';
import integrations from 'services/integrations';

/**
 * Generate items for NiceSelect
 *
 * @param {array} surveys
 * @returns
 */
const getSelectItems = surveys => {
    const items = [{ id: '', title: 'None' }];
    surveys
        .sort((a, b) => moment(b.creationDate) - moment(a.creationDate))
        .forEach(survey => {
            items.push({
                id: survey.id,
                title: survey.name
            });
        });
    return items;
};

/**
 * Generate mapValueToLabel for NiceSelect
 *
 * @param {array} surveys
 * @returns
 */
const getValueLabelMap = surveys => {
    const items = { '': 'None' };
    surveys.forEach(survey => {
        items[survey.id] = survey.name;
    });
    return items;
};

/**
 * Display a dropdown with Qualtrics surveys.
 *
 * @param {Object} props
 * @param {string} props.defaultValue Default value.
 * @param {function} props.onChange The function to handle change event.
 **/
function QualtricsSurveySelect({ defaultValue, onChange }) {
    const { addToast } = useToasts();
    const { data: surveys, isLoading } = useQuery({
        queryFn: () => integrations.getQualtricsSurveys(),
        onError: (_, errorText) => {
            addToast(errorText, {
                appearance: 'error',
                autoDismiss: true
            });
        }
    });

    return (
        <>
            <Label mt={3} htmlFor="description">
                Qualtrics survey
            </Label>
            {isLoading ? (
                <Box px={2} py={1}>
                    Loading...
                </Box>
            ) : (
                <NiceSelect
                    showSearch
                    revealEllipsis={true}
                    mimicSelectStyle={true}
                    defaultValue={defaultValue}
                    positionRight="0px"
                    items={getSelectItems(surveys || [])}
                    mapValueToLabel={getValueLabelMap(surveys || [])}
                    onChange={surveyId => {
                        onChange(surveys.find(survey => survey.id == surveyId));
                    }}
                />
            )}
        </>
    );
}

export default QualtricsSurveySelect;
