import React from 'react';
import { Flex, Box, Button } from 'rebass';
import { Label } from '@rebass/forms';
import { FiPlus, FiXCircle, FiTag } from 'react-icons/fi';

const ParticipantTags = ({ tags, onTagDelete, handleOpenModalAddTag }) => (
    <>
        {tags.length ? (
            <Flex className="form-data" sx={{ flexWrap: 'wrap', gap: 2 }}>
                {tags.map(tag => (
                    <Button key={tag.id} variant="tertiary" py={1} sx={{ whiteSpace: 'normal', height: 'auto' }}>
                        <FiTag />
                        {tag.title} <FiXCircle onClick={() => onTagDelete(tag.id)} />
                    </Button>
                ))}
            </Flex>
        ) : (
            <Box className="form-data">
                <Label>This person has no tags.</Label>
            </Box>
        )}
        <Box className="form-data form-row-margin">
            <Button variant="secondary-gray" onClick={handleOpenModalAddTag}>
                <FiPlus /> Add tag
            </Button>
        </Box>
    </>
);

export default ParticipantTags;
