import React from 'react';
import NiceWrapper from 'components/NiceWrapper';
import { Redirect } from 'react-router-dom';
import services from 'services/services';
import { Button } from 'rebass';

function TestPage(props) {
    function sendSmallPayload() {}
    function sendLargePayload() {}

    // If user is granted access, redirec them
    if (props.auth.user.email != 'michael@panelfox.io') {
        return <Redirect to="/studies" />;
    }

    return (
        <div style={{ margin: '32px auto', textAlign: 'center' }}>
            <Button variant="secondary" type="button" onClick={sendSmallPayload}>
                Small payload
            </Button>
            <Button variant="secondary" type="button" onClick={sendLargePayload}>
                Large payload
            </Button>
        </div>
    );
}

export default TestPage;
