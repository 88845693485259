import React from 'react';
import { Box, Flex } from 'rebass';
import { FiAlertCircle } from 'react-icons/fi';

const ErrorLabel = props => (
    <Box style={props.style}>
        <div className="label-error">
            <FiAlertCircle style={{ margin: '0 8px 0 0', fontSize: '14px', strokeWidth: '2px' }} />
            {props.children}
        </div>
    </Box>
);

export default ErrorLabel;
