import React from 'react';
import { Helmet } from 'react-helmet';
import isEmpty from 'lodash/isEmpty';
import deley from 'lodash/delay';
import { withToastManager } from 'react-toast-notifications';
import H1 from 'components/H1';
import ReactTooltip from 'react-tooltip';
import { Flex, Box, Button } from 'rebass';
import { Label, Input } from '@rebass/forms';
import services from 'services/services';
import styled from 'styled-components';
import config from 'config';
import auth from 'services/auth';
import integrations from 'services/integrations';
import consts from 'consts';
import { FiZap, FiPlus, FiX, FiSettings, FiRefreshCw } from 'react-icons/fi';

import peopleServices from 'services/people';
import service_integrations from 'services/integrations';
import NiceModal from 'components/NiceModal';
import AppPageWrapper from 'components/AppPageWrapper';
import AppPageWrapperSectionHeader from 'components/AppPageWrapper/AppPageWrapperSectionHeader';
import AppPageWrapperSectionBody from 'components/AppPageWrapper/AppPageWrapperSectionBody';
import SettingsPageSubNav from 'containers/SettingsPage/SettingsPageSubNav';
import SalesforceMapper from 'containers/SettingsPage/Modals/SalesforceMapper';
import ListWrapper from 'components/ListWrapper';
import ItemWrapper from 'components/ItemWrapper';
import { SyncStatusBadge } from 'components/SyncStatusBadge';
import Portal from 'components/Portal';

const IntegrationItem = styled(Flex)`
    flex-direction: column;
    width: 800px;
    border-radius: 4px;
    border: 1px solid #d9d9d9;
    padding: 32px;
    margin-bottom: 16px !important;
`;

const TextLoader = styled(Box)`
    width: 90%;
    display: inline-block;
    height: 20px;
    border-radius: 4px;
`;

const IntegrationHeader = styled(Flex)`
    flex-direction: row;
    img {
        width: 32px;
        margin-right: 16px;
        object-fit: scale-down;
        object-position: left top;
    }
    a {
        text-decoration: underline;
    }
`;

const IntegrationActionButtons = styled(Flex)`
    flex-direction: column;
    justify-content: space-around;
    align-items: flex-end;
    padding-left: 16px;
    width: 220px;
    button {
        width: fit-content;
    }
`;

const IntegrationDivider = styled.hr`
    margin-top: 24px;
    margin-bottom: 24px;
    border-color: #d4d9dd;
`;

const IntegrationStatus = styled(Flex)`
    justify-content: space-between;
    align-items: center;
`;

const DisconnectButton = ({ onClick }) => (
    <Button variant="danger" onClick={onClick}>
        <FiX style={{ strokeWidth: '2px', fontSize: '15px', margin: '4px 4px 0 0' }} /> Disconnect
    </Button>
);

const ConnectButton = ({ onClick }) => (
    <Button variant="secondary-gray" onClick={onClick}>
        <FiPlus style={{ strokeWidth: '2px' }} /> Connect
    </Button>
);

class SettingsIntegrationsPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            account: this.props.auth.account,
            salesforceDomain: '',
            showModalSalesforceConnect: false,
            salesforceDefaultData: null,
            qualtricsApiToken: '',
            qualtricsDatacenterId: '',
            qualtricsOrganizationId: '',
            showModalQualtricsConnect: false,
            panels: [],
            salesforceListviews: [],
            salesforceIntegrationInfo: null,
            isLoading: {
                salesforce: true,
                panels: true
            }
        };

        this.calendlyAuthorizationUrl = `${
            config.API_URL
        }/integrations/calendly/authorize-redirect?account_id=${auth.getAccountId()}`;
        this.tremendousAuthorizationUrl = `${
            config.API_URL
        }/integrations/tremendous/authorize-redirect?account_id=${auth.getAccountId()}`;
        this.disconnectZoom = this.disconnectZoom.bind(this);
        this.disconnectCalendly = this.disconnectCalendly.bind(this);
        this.disconnectTremendous = this.disconnectTremendous.bind(this);
        this.connectSalesforce = this.connectSalesforce.bind(this);
        this.disconnectSalesforce = this.disconnectSalesforce.bind(this);
        this.runSalesforceSync = this.runSalesforceSync.bind(this);
        this.getPanels = this.getPanels.bind(this);
        this.getSalesforceListviews = this.getSalesforceListviews.bind(this);
        this.getSalesforceInfo = this.getSalesforceInfo.bind(this);
        this.connectQualtrics = this.connectQualtrics.bind(this);
        this.disconnectQualtrics = this.disconnectQualtrics.bind(this);
    }

    /**
     * Fetch salesforce info
     */
    async getSalesforceInfo() {
        const { toastManager } = this.props;

        try {
            const data = await integrations.getSaleforceIntegrationInfo();
            this.setState({ salesforceIntegrationInfo: data });
        } catch (error) {
            const errorText = services.parseAndTrackXhrErrors(error);
            toastManager.add(errorText, {
                appearance: 'error',
                autoDismiss: true
            });
        }
    }

    async getPanels() {
        const { toastManager } = this.props;

        try {
            const data = await peopleServices.getPanels();
            this.setState(state => ({ panels: data, isLoading: { ...state.isLoading, panels: false } }));
        } catch (error) {
            const errorText = services.parseAndTrackXhrErrors(error);

            toastManager.add(errorText, {
                appearance: 'error',
                autoDismiss: true
            });
        }
    }

    async getSalesforceListviews() {
        const { toastManager } = this.props;

        try {
            const data = await service_integrations.getSaleforceListviews();
            this.setState(state => ({
                salesforceListviews: data,
                isLoading: { ...state.isLoading, salesforce: false }
            }));
        } catch (error) {
            const errorText = services.parseAndTrackXhrErrors(error);

            toastManager.add(errorText, {
                appearance: 'error',
                autoDismiss: true
            });
        }
    }

    disconnectZoom() {
        const { toastManager } = this.props;

        integrations
            .disconnectZoomAccount()
            .then(() => {
                toastManager.add('Disconnected Zoom', {
                    appearance: 'success',
                    autoDismiss: true
                });

                auth.checkAuth();

                const prevAccount = this.state.account;

                this.setState({
                    account: {
                        ...prevAccount,
                        zoom_account_id: null
                    }
                });
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);

                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    disconnectCalendly() {
        const { toastManager } = this.props;

        integrations
            .disconnectCalendlyAccount()
            .then(account => {
                toastManager.add('Disconnected Calendly', {
                    appearance: 'success',
                    autoDismiss: true
                });

                auth.checkAuth();

                this.setState({
                    account
                });
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);

                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    disconnectTremendous() {
        const { toastManager } = this.props;

        integrations
            .disconnectTremendousAccount()
            .then(account => {
                toastManager.add('Disconnected Tremendous', {
                    appearance: 'success',
                    autoDismiss: true
                });

                auth.checkAuth();

                this.setState({
                    account
                });
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);

                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    connectSalesforce() {
        const { toastManager } = this.props;

        integrations
            .getSalesforceAuthRedirectUrl(this.state.salesforceDomain)
            .then(redirect_url => {
                window.location = redirect_url;
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);

                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    disconnectSalesforce() {
        const { toastManager } = this.props;

        integrations
            .disconnectSalesforceAccount()
            .then(account => {
                toastManager.add('Disconnected Salesforce', {
                    appearance: 'success',
                    autoDismiss: true
                });

                auth.checkAuth();

                this.setState({
                    account
                });
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);

                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    /**
     * Run salesforce sync
     */
    async runSalesforceSync() {
        const { toastManager } = this.props;

        toastManager.add('Salesforce sync started', {
            appearance: 'success',
            autoDismiss: true
        });

        try {
            // run sync
            const runner = integrations.runSalesforceSync();
            // wait 500ms and fetch salesforce info
            deley(async () => {
                // fetch salesforce info
                await this.getSalesforceInfo();
                // wait until sync is finished
                await runner;
                // get the latest salesforce info
                await this.getSalesforceInfo();
            }, 500);
        } catch (error) {
            const errorText = services.parseAndTrackXhrErrors(error);

            toastManager.add(errorText, {
                appearance: 'error',
                autoDismiss: true
            });
        }
    }

    renderSalesforceDomain() {
        try {
            const domain = this.state.account.salesforce_domain.replace('https://', '').replace('/', '');
            if (domain.endsWith('my.salesforce.com')) {
                return (
                    <Box textAlign="right" className="fs-body-12">
                        <span className="color-text-primary">{domain.slice(0, -17)}</span>
                        <span className="color-text-secondary">my.salesforce.com</span>
                    </Box>
                );
            } else {
                return (
                    <Box textAlign="right" className="fs-body-12">
                        <span className="color-text-primary">{domain}</span>
                    </Box>
                );
            }
        } catch (e) {
            console.log(e);
            return <>Error rendering domain</>;
        }
    }

    renderSalesforceIntegrationInfo() {
        if (this.state.salesforceIntegrationInfo === null) return null;

        // if no integration info, show button to configure sync
        if (isEmpty(this.state.salesforceIntegrationInfo))
            return (
                <Box sx={{ marginLeft: '48px' }}>
                    <hr />
                    <Box mt={3}>
                        <Box className="text-primary medium">Continue setting up Salesforce:</Box>
                        <Button variant="primary" mt={3} onClick={() => this.setState({ salesforceDefaultData: {} })}>
                            <FiZap /> Configure Sync
                        </Button>
                    </Box>
                </Box>
            );

        // use array for future integrations
        const integrations = [this.state.salesforceIntegrationInfo];

        return (
            <ListWrapper sx={{ mb: '24px', margin: '0px -32px' }}>
                <ItemWrapper className="header-row">
                    <Box width={5 / 16}>Target panel</Box>
                    <Box width={5 / 16}>Salesforce list</Box>
                    <Box width={4 / 16}>Status</Box>
                    <Box width={2 / 16}>Actions</Box>
                </ItemWrapper>
                {integrations.map(ii => {
                    const panel = this.state.panels.find(panel => panel.id === ii.panel_id);
                    const listview = this.state.salesforceListviews.find(
                        listview => listview.id === ii.metadata.salesforce_listview_id
                    );
                    const panelText = panel ? panel.title : ii.panel_id;
                    const listviewText = listview ? listview.label : ii.metadata.salesforce_listview_id;

                    return (
                        <ItemWrapper>
                            <Box width={5 / 16} className="fs-title-14 color-text-primary">
                                {this.state.isLoading.panels ? <TextLoader className="bg-pure" /> : panelText}
                            </Box>
                            <Box width={5 / 16} className="fs-title-14 color-text-primary">
                                {this.state.isLoading.salesforce ? <TextLoader className="bg-pure" /> : listviewText}
                            </Box>
                            <Box width={4 / 16}>
                                <SyncStatusBadge status={ii.status} lastExecuted={ii.last_executed} note={ii.note} />
                            </Box>
                            <Box width={2 / 16}>
                                <Flex alignItems="center" sx={{ gap: '8px' }}>
                                    <Button
                                        data-tip
                                        variant="secondary-gray"
                                        className="secondary-icon"
                                        data-for="configure-sync-tooltip"
                                        onClick={() =>
                                            this.setState({
                                                salesforceDefaultData: {
                                                    panelId: ii.panel_id,
                                                    listviewId: ii.metadata.salesforce_listview_id
                                                }
                                            })
                                        }
                                    >
                                        <FiSettings />
                                    </Button>
                                    <Button
                                        data-tip
                                        data-for="run-sync-manually-tooltip"
                                        variant="secondary-gray"
                                        className="secondary-icon"
                                        onClick={this.runSalesforceSync}
                                    >
                                        <FiRefreshCw />
                                    </Button>
                                </Flex>
                            </Box>
                        </ItemWrapper>
                    );
                })}
                <ReactTooltip
                    css={{ maxWidth: '400px', zIndex: 10 }}
                    id="configure-sync-tooltip"
                    effect="solid"
                    place="top"
                >
                    <Box>Configure sync</Box>
                </ReactTooltip>
                <ReactTooltip
                    css={{ maxWidth: '400px', zIndex: 10 }}
                    id="run-sync-manually-tooltip"
                    effect="solid"
                    place="top"
                >
                    <Box>Run sync manually</Box>
                </ReactTooltip>
            </ListWrapper>
        );
    }

    async connectQualtrics() {
        const { toastManager } = this.props;
        const { qualtricsApiToken, qualtricsDatacenterId, qualtricsOrganizationId } = this.state;

        try {
            const account = await integrations.connectQualtrics(
                qualtricsApiToken,
                qualtricsDatacenterId,
                qualtricsOrganizationId
            );

            toastManager.add('Connected Qualtrics', {
                appearance: 'success',
                autoDismiss: true
            });

            auth.checkAuth();

            this.setState({
                account,
                showModalQualtricsConnect: false
            });
        } catch (error) {
            const errorText = services.parseAndTrackXhrErrors(error);
            toastManager.add(errorText, {
                appearance: 'error',
                autoDismiss: true
            });
        }
    }

    async disconnectQualtrics() {
        const { toastManager } = this.props;

        try {
            const account = await integrations.disconnectQualtrics();

            toastManager.add('Disconnected Qualtrics', {
                appearance: 'success',
                autoDismiss: true
            });

            this.setState({
                account
            });
        } catch (error) {
            const errorText = services.parseAndTrackXhrErrors(error);
            toastManager.add(errorText, {
                appearance: 'error',
                autoDismiss: true
            });
        }
    }

    componentDidMount() {
        if (this.state.account.salesforce_at) {
            this.getSalesforceInfo();
            this.getPanels();
            this.getSalesforceListviews();
        }
    }

    render() {
        return (
            <Flex style={{ width: '100%' }}>
                <AppPageWrapper>
                    <Helmet>
                        <title>Integrations</title>
                    </Helmet>

                    <Flex>
                        <SettingsPageSubNav />
                    </Flex>

                    <AppPageWrapperSectionHeader className="experience">
                        <Box>
                            <H1>Integrations</H1>
                            <Box className="experience-description">Automate tasks with our integrations.</Box>
                        </Box>
                    </AppPageWrapperSectionHeader>
                    <AppPageWrapperSectionBody>
                        <Box mx={4} mt="24px" mb={6}>
                            <IntegrationItem>
                                <IntegrationHeader>
                                    <img src="/zoom.png" alt="Zoom" />
                                    <Box flex={1}>
                                        <Box className="fs-title-18 color-text-primary" mb={2}>
                                            Zoom
                                        </Box>
                                        <Box className="fs-body-14 color-text-secondary" mb={3}>
                                            Automatically add a Zoom meeting to scheduled sessions.
                                        </Box>
                                    </Box>
                                    <IntegrationActionButtons>
                                        {this.state.account.zoom_account_id ? (
                                            <DisconnectButton onClick={this.disconnectZoom} />
                                        ) : (
                                            <ConnectButton
                                                onClick={() => {
                                                    window.location = consts.zoomAuthorizationUrl;
                                                }}
                                            />
                                        )}
                                    </IntegrationActionButtons>
                                </IntegrationHeader>
                            </IntegrationItem>

                            <IntegrationItem>
                                <IntegrationHeader>
                                    <img src="/calendly.png" alt="Calendly" />
                                    <Box flex={1}>
                                        <Box className="fs-title-18 color-text-primary" mb={2}>
                                            Calendly Pro
                                        </Box>
                                        <Box className="fs-body-14 color-text-secondary" mb={3}>
                                            Enable Calendly to handle the scheduling. Ensure to update Study &gt;
                                            Scheduling Availability &gt; Scheduling Tool to "Calendly".
                                        </Box>
                                    </Box>
                                    <IntegrationActionButtons>
                                        {this.state.account.calendly_access_token ? (
                                            <DisconnectButton onClick={this.disconnectCalendly} />
                                        ) : (
                                            <ConnectButton
                                                onClick={() => {
                                                    window.location = this.calendlyAuthorizationUrl;
                                                }}
                                            />
                                        )}
                                    </IntegrationActionButtons>
                                </IntegrationHeader>
                                {this.state.account.calendly_access_token && (
                                    <>
                                        <IntegrationDivider />
                                        <IntegrationStatus>
                                            <Flex></Flex>
                                            <Flex className="fs-body-12 color-text-secondary">
                                                Integrated user role:{' '}
                                                {this.state.account.calendly_integrating_membership_role}
                                                {/*! ['owner','admin'].includes(this.state.account.calendly_integrating_membership_role) ? 'This membership does not allow the integration to view all users events': '' */}
                                            </Flex>
                                        </IntegrationStatus>
                                    </>
                                )}
                            </IntegrationItem>

                            <IntegrationItem>
                                <IntegrationHeader>
                                    <img src="/tremendous.png" alt="Tremendous" />
                                    <Box flex={1}>
                                        <Box className="fs-title-18 color-text-primary" mb={2}>
                                            Tremendous
                                        </Box>
                                        <Box className="fs-body-14 color-text-secondary" mb={3}>
                                            Enable Tremendous to handle incentive payments.
                                        </Box>
                                        <Box className="fs-body-14">
                                            <a href="#" data-beacon-article="62f686cc829a3853b69263db">
                                                How to set up Tremendous.
                                            </a>
                                        </Box>
                                    </Box>
                                    <IntegrationActionButtons>
                                        {this.state.account.tremendous_access_token ? (
                                            <DisconnectButton onClick={this.disconnectTremendous} />
                                        ) : (
                                            <ConnectButton
                                                onClick={() => {
                                                    window.location = this.tremendousAuthorizationUrl;
                                                }}
                                            />
                                        )}
                                    </IntegrationActionButtons>
                                </IntegrationHeader>
                            </IntegrationItem>

                            <IntegrationItem>
                                <IntegrationHeader mb="24px">
                                    <img src="/salesforce.png" alt="Salesforce" style={{ marginTop: '4px' }} />
                                    <Box flex={1}>
                                        <Box className="fs-title-18 color-text-primary" mb={2}>
                                            Salesforce
                                        </Box>
                                        <Box className="fs-body-14 color-text-secondary" mb={3}>
                                            Import Contacts from Salesforce into Panelfox automatically.
                                        </Box>
                                        <Box className="fs-body-14">
                                            <a href="#" data-beacon-article="64822120ca8d057c06530cd9">
                                                How to set up Salesforce.
                                            </a>
                                        </Box>
                                    </Box>
                                    <IntegrationActionButtons>
                                        {this.state.account.salesforce_at ? (
                                            <DisconnectButton onClick={this.disconnectSalesforce} />
                                        ) : (
                                            <ConnectButton
                                                onClick={() => this.setState({ showModalSalesforceConnect: true })}
                                            />
                                        )}
                                        {this.state.account.salesforce_at &&
                                            this.state.account.salesforce_domain &&
                                            this.renderSalesforceDomain()}
                                    </IntegrationActionButtons>
                                </IntegrationHeader>
                                {this.state.account.salesforce_at && this.renderSalesforceIntegrationInfo()}
                            </IntegrationItem>

                            <IntegrationItem>
                                <IntegrationHeader>
                                    <img src="/qualtrics.svg" alt="Qualtrics" />
                                    <Box flex={1}>
                                        <Box className="fs-title-18 color-text-primary" mb={2}>
                                            Qualtrics
                                        </Box>
                                        <Box className="fs-body-14 color-text-secondary" mb={3}>
                                            Create your Screeners in Qualtrics and send it out through Panelfox.
                                        </Box>
                                        <Box className="fs-body-14">
                                            <a href="#" data-beacon-article="">
                                                How to set up Qualtrics.
                                            </a>
                                        </Box>
                                    </Box>
                                    <IntegrationActionButtons>
                                        {this.state.account.qualtrics_api_token ? (
                                            <DisconnectButton onClick={this.disconnectQualtrics} />
                                        ) : (
                                            <ConnectButton
                                                onClick={() => this.setState({ showModalQualtricsConnect: true })}
                                            />
                                        )}
                                    </IntegrationActionButtons>
                                </IntegrationHeader>
                            </IntegrationItem>
                        </Box>
                        {this.state.salesforceDefaultData && (
                            <SalesforceMapper
                                isOpen
                                onRequestClose={() => this.setState({ salesforceDefaultData: null })}
                                defaultValue={this.state.salesforceDefaultData}
                                onSave={this.getSalesforceInfo}
                                onNewPanel={this.getPanels}
                            />
                        )}
                        <Portal>
                            <NiceModal
                                isOpen={this.state.showModalSalesforceConnect}
                                onRequestClose={() => this.setState({ showModalSalesforceConnect: false })}
                                style={{ content: { width: '640px' } }}
                                title="Salesforce connect"
                            >
                                <form
                                    onSubmit={event => {
                                        event.preventDefault();
                                        this.connectSalesforce();
                                    }}
                                >
                                    <Label>Salesforce domain</Label>
                                    <Input
                                        type="text"
                                        name="salesforce_connect_domain"
                                        placeholder="https://mycompany.my.salesforce.com"
                                        autoComplete="off"
                                        required
                                        autoFocus
                                        value={this.state.salesforceDomain}
                                        onChange={e => this.setState({ salesforceDomain: e.target.value })}
                                    />
                                    <p className="text-secondary fs-body-12">
                                        The URL must start with https:// and must end with .my.salesforce.com <br />
                                        (e.g. https://data-connect-12345.my.salesforce.com):
                                    </p>
                                    <Box className="modal-actions">
                                        <Button
                                            type="button"
                                            variant="secondary-gray"
                                            mr={3}
                                            onClick={() => this.setState({ showModalSalesforceConnect: false })}
                                        >
                                            Cancel
                                        </Button>
                                        <Button type="submit" variant="primary" mr={0} className="modal-primary">
                                            Connect
                                        </Button>
                                    </Box>
                                </form>
                            </NiceModal>
                            <NiceModal
                                isOpen={this.state.showModalQualtricsConnect}
                                onRequestClose={() => this.setState({ showModalQualtricsConnect: false })}
                                style={{ content: { width: '640px' } }}
                                title="Connect Qualtrics"
                            >
                                <form
                                    onSubmit={event => {
                                        event.preventDefault();
                                        this.connectQualtrics();
                                    }}
                                >
                                    <p className="text-secondary fs-body-12">
                                        This information can be found on your Account Settings page under the Qualtrics
                                        IDs tab.
                                        <br />
                                    </p>
                                    <Box className="form-row-margin">
                                        <Label>API Token</Label>
                                    </Box>
                                    <Box className="form-data">
                                        <Input
                                            type="text"
                                            name="qualtrics_api_token"
                                            autoComplete="off"
                                            placeholder="FC7LnYVw9u74synhvsANFCmhoBqcvVEUAJB3Kgf7"
                                            required
                                            autoFocus
                                            value={this.state.qualtricsApiToken}
                                            onChange={e => this.setState({ qualtricsApiToken: e.target.value })}
                                        />
                                    </Box>
                                    <Box className="form-row-margin">
                                        <Label>Organization ID</Label>
                                    </Box>
                                    <Box className="form-data">
                                        <Input
                                            type="text"
                                            name="qualtrics_organization_id"
                                            placeholder="qualtricsxmqwertyuio"
                                            required
                                            value={this.state.qualtricsOrganizationId}
                                            onChange={e => this.setState({ qualtricsOrganizationId: e.target.value })}
                                        />
                                    </Box>
                                    <Box className="form-row-margin">
                                        <Label>Datacenter ID</Label>
                                    </Box>
                                    <Box className="form-data">
                                        <Input
                                            type="text"
                                            name="qualtrics_datacenter_id"
                                            placeholder="yul1"
                                            required
                                            value={this.state.qualtricsDatacenterId}
                                            onChange={e => this.setState({ qualtricsDatacenterId: e.target.value })}
                                        />
                                    </Box>
                                    <Box className="modal-actions">
                                        <Button
                                            type="button"
                                            variant="secondary-gray"
                                            mr={3}
                                            onClick={() => this.setState({ showModalQualtricsConnect: false })}
                                        >
                                            Cancel
                                        </Button>
                                        <Button type="submit" variant="primary" mr={0} className="modal-primary">
                                            Connect
                                        </Button>
                                    </Box>
                                </form>
                            </NiceModal>
                        </Portal>
                    </AppPageWrapperSectionBody>
                </AppPageWrapper>
            </Flex>
        );
    }
}

export default withToastManager(SettingsIntegrationsPage);
