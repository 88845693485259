import React, { useRef, useState } from 'react';
import { FiChevronDown, FiMoreVertical, FiPlus } from 'react-icons/fi';
import { Box, Button, Flex } from 'rebass';
import { useToasts } from 'react-toast-notifications';
import OutsideClickHandler from 'react-outside-click-handler';

import useQuery from 'hooks/useQuery';
import useMutation from 'hooks/useMutation';
import services from 'services/services';
import helpers from 'utils/helpers';
import { SmallLink, activeClassName } from 'components/Header/SmallLink';
import NiceDropdown from 'components/NiceDropdown';
import Portal from 'components/Portal';
import Positioner from 'components/Positioner';
import classNames from 'classnames';

/**
 * Panel saved searches (Segments) component
 *
 * @param {Object} props props
 * @param {function} props.onChange onChange
 * @param {Object} props.urlState urlState object
 * @param {Array} props.urlState.filters filters
 * @param {string} props.urlState.showFatigued show fatigued
 * @param {string} props.urlState.panelIdSelected selected panel id
 * @param {string} props.urlState.dataSortColumnId data sort column id
 * @param {string} props.urlState.dataSortDirection data sort direction
 */
function PanelSearchSelect({ selectedId, onChange, urlState, disabled = false }) {
    const controllerRef = useRef();
    const { addToast } = useToasts();
    const [showDropdown, setShowDropdown] = useState(false);
    const [showMore, setShowMore] = useState(false);

    /**
     * Get panel searches query
     */
    const { data: savedSearches, refetch: refetchSavedSearches } = useQuery({
        queryFn: async () => {
            const response = await services.getPanelSearch();
            helpers.sortAlpha(response, 'title');
            return response;
        }
    });

    /**
     * Create panel search mutation
     */
    const { mutate: createPanelSearchMutatation } = useMutation({
        mutationFn: async ({ title, panelUrlState }) => {
            return services.createPanelSearch(title, panelUrlState);
        },
        onSuccess: newPanel => {
            onChange(newPanel);
            refetchSavedSearches();
            closeDropdown();
            addToast('Filters successfully saved', {
                appearance: 'success',
                autoDismiss: true
            });
        },
        onError: (error, errorText) => {
            addToast(errorText, {
                appearance: 'error',
                autoDismiss: true
            });
        }
    });

    /**
     * Update panel search mutation
     */
    const { mutate: updatePanelSearchMutatation } = useMutation({
        mutationFn: ({ id, title, panelUrlState }) => services.updatePanelSearch(id, title, panelUrlState),
        onSuccess: () => {
            refetchSavedSearches();
        },
        onError: (_, errorText) => {
            addToast(errorText, {
                appearance: 'error',
                autoDismiss: true
            });
        }
    });

    /**
     * Delete panel search mutation
     */
    const { mutate: deletePanelSearchMutatation } = useMutation({
        mutationFn: ({ id }) => services.deletePanelSearch(id),
        onSuccess: () => {
            onChange(null);
            refetchSavedSearches();
            addToast('Saved filter deleted successfully', {
                appearance: 'success',
                autoDismiss: true
            });
        },
        onError: (_, errorText) => {
            addToast(errorText, {
                appearance: 'error',
                autoDismiss: true
            });
        }
    });

    /**
     * Open list of saved searches
     */
    const openDropdown = () => {
        setShowDropdown(true);
    };

    /**
     * Close list of saved searches
     */
    const closeDropdown = () => {
        setShowDropdown(false);
    };

    /**
     * Create panel search
     */
    const createPanelSearch = () => {
        const title = prompt('What would you like to title this segment?');
        if (title) {
            createPanelSearchMutatation({ title, panelUrlState: urlState });
        }
    };

    /**
     * Rename panel search
     *
     * @param {Object} panelSearch panel search object
     */
    const renamePanelSearch = panelSearch => {
        const title = prompt('What would you like to change the title to?', panelSearch.title);
        if (title) {
            updatePanelSearchMutatation(
                { id: panelSearch.id, title },
                {
                    onSuccess: () => {
                        addToast('Saved filters successfully renamed', {
                            appearance: 'success',
                            autoDismiss: true
                        });
                    }
                }
            );
        }
    };

    /**
     * Update panel search
     *
     * @param {Object} panelSearch panel search object
     */
    const updatePanelSearch = panelSearch => {
        updatePanelSearchMutatation(
            { id: panelSearch.id, title: panelSearch.title, panelUrlState: urlState },
            {
                onSuccess: () => {
                    addToast('Saved filters successfully updated', {
                        appearance: 'success',
                        autoDismiss: true
                    });
                }
            }
        );
    };

    /**
     * Delete panel search
     *
     * @param {Object} panelSearch panel search object
     */
    const deletePanelSearch = panelSearch => {
        // eslint-disable-next-line
        if (confirm('Are you sure you want to delete this segment?')) {
            deletePanelSearchMutatation({ id: panelSearch.id });
        }
    };

    if (!savedSearches) return null;

    const selectedSearch = savedSearches.find(search => search.id == selectedId);

    return (
        <>
            <Button
                disabled={disabled}
                type="button"
                variant="secondary-gray"
                onClick={openDropdown}
                ref={controllerRef}
            >
                {selectedSearch ? selectedSearch.title : 'Segments'}
                <FiChevronDown
                    style={{
                        margin: '3px 0 0 4px'
                    }}
                />
            </Button>
            <Portal>
                <Positioner controllerRef={controllerRef} isVisible={showDropdown}>
                    <Box
                        style={{ width: '240px', maxHeight: '100%', overflow: 'auto' }}
                        p={3}
                        className="nice-boxshadow bg-white"
                    >
                        <OutsideClickHandler onOutsideClick={closeDropdown}>
                            {urlState && urlState.filters.length > 0 && (
                                <Button
                                    type="button"
                                    variant="secondary"
                                    mb={2}
                                    onClick={createPanelSearch}
                                    style={{ width: '100%' }}
                                >
                                    <FiPlus /> New Segment
                                </Button>
                            )}

                            <SmallLink
                                as="div"
                                onClick={() => {
                                    onChange(null);
                                    closeDropdown();
                                }}
                                className={classNames(!selectedSearch && activeClassName)}
                                style={{ fontSize: '13px', fontWeight: 400 }}
                            >
                                All panelists
                            </SmallLink>

                            {savedSearches.map(panelSearch => (
                                <SmallLink
                                    key={panelSearch.id}
                                    as="div"
                                    onClick={() => {
                                        onChange(panelSearch);
                                        closeDropdown();
                                    }}
                                    className={classNames(
                                        selectedSearch && selectedSearch.id === panelSearch.id && activeClassName
                                    )}
                                    style={{ fontSize: '13px', fontWeight: 400 }}
                                >
                                    <Flex className="panel-search-item" sx={{ justifyContent: 'space-between' }}>
                                        <Box className="ellipsis" style={{ marginTop: '2px' }}>
                                            {panelSearch.title}
                                        </Box>
                                        {urlState && (
                                            <Box className="shrink-0" ml={2}>
                                                <Button
                                                    variant="secondary"
                                                    className="secondary-tiny secondary-tiny-icon"
                                                    mr={0}
                                                    onClick={e => {
                                                        e.preventDefault();
                                                        e.stopPropagation();
                                                        setShowMore(panelSearch.id);
                                                    }}
                                                >
                                                    <FiMoreVertical />
                                                </Button>
                                                {showMore == panelSearch.id && (
                                                    <NiceDropdown
                                                        width={120}
                                                        positionRight="0px"
                                                        onClose={() => setShowMore(false)}
                                                        items={[
                                                            {
                                                                title: 'Rename',
                                                                onClick: e => {
                                                                    e.preventDefault();
                                                                    e.stopPropagation();
                                                                    renamePanelSearch(panelSearch);
                                                                }
                                                            },
                                                            {
                                                                title: 'Save current filters',
                                                                onClick: e => {
                                                                    e.preventDefault();
                                                                    e.stopPropagation();
                                                                    updatePanelSearch(panelSearch);
                                                                }
                                                            },
                                                            { id: 'divider', divider: true },
                                                            {
                                                                title: 'Delete',
                                                                color: 'red',
                                                                onClick: e => {
                                                                    e.preventDefault();
                                                                    e.stopPropagation();
                                                                    deletePanelSearch(panelSearch);
                                                                }
                                                            }
                                                        ]}
                                                    />
                                                )}
                                            </Box>
                                        )}
                                    </Flex>
                                </SmallLink>
                            ))}

                            {savedSearches.length == 0 && (
                                <Box style={{ textAlign: 'center' }} className="medium" mt={2} mb={1}>
                                    0 saved segments
                                </Box>
                            )}
                        </OutsideClickHandler>
                    </Box>
                </Positioner>
            </Portal>
        </>
    );
}

export default PanelSearchSelect;
