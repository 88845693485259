import React from 'react';
import styled, { css } from 'styled-components';
import { DefaultToast, DefaultToastContainer } from 'react-toast-notifications';

/**
 * The default toast component with custom styles
 * @param {Object} props DefaultToast props
 * @param {boolean} props.big If true, the toast will be bigger
 */
export const CustomToast = styled(DefaultToast)`
    background-color: #fff !important;
    border-radius: 8px !important;

    ${props =>
        props.big &&
        css`
            &&& {
                width: 720px;
                max-width: calc(100vw - 32px);

                .react-toast-notifications__toast__icon-wrapper {
                    width: 71px;
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    padding: 0 !important;
                }
                .react-toast-notifications__toast__dismiss-button {
                    display: flex;
                    align-items: center;
                    padding: 0 16px !important;
                }
                .react-toast-notifications__toast__content {
                    padding: 16px 8px 16px 25px;
                }
            }
        `}
`;

export const CustomToastContainer = styled(DefaultToastContainer)`
    display: flex;
    align-items: flex-end;
    flex-direction: column;
    z-index: 1050 !important;

    & > div {
        height: auto !important;
    }
`;

export default CustomToast;
