import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';

import service_docs from 'services/docs';

export function DocsRedirect() {
    const { section, page } = useParams();

    const redirect = url => {
        window.location.href = url;
    };

    const runRedirection = async () => {
        // basic redirect url to the docs homepage
        let url = 'https://docs.panelfox.io';

        // check if there is a section and page
        if (!section || !page) return redirect(url);

        // get the documentation data
        const documentationData = await service_docs.getDocumentationDataBySlug(`${section}/${page}`);

        // if there is documentation data, get the query
        if (documentationData && documentationData.items && documentationData.items.length) {
            const page_data = documentationData.items[0];
            const query = page_data.fields.title;

            // if there is a query, set the URL to the search page
            if (query) {
                url = `https://docs.panelfox.io/search?query=${query}`;
            }
        }

        return redirect(url);
    };

    useEffect(() => {
        runRedirection();
    }, []);

    return null;
}

export default DocsRedirect;
