import React from 'react';
import { Flex, Button } from 'rebass';
import { Input, Label } from '@rebass/forms';

import { Link } from 'react-router-dom';

const LoginPageInput = props => (
    <>
        <Flex justifyContent="space-between">
            {props.label && (
                <Label color="#001233" fontWeight={500} mb="4px">
                    {props.label}
                </Label>
            )}
            {props.linkTo && (
                <Link to={props.linkTo}>
                    <Button
                        type="button"
                        variant="link"
                        fontWeight={400}
                        fontSize="12px"
                        height="auto"
                        paddingBottom="2px"
                    >
                        {props.linkLabel}
                    </Button>
                </Link>
            )}
        </Flex>
        <Input
            sx={{
                color: '#001233',
                height: '38px',
                width: '100%'
            }}
            className="input-darker"
            autoComplete="off"
            mb="24px"
            {...props}
        />
    </>
);

export default LoginPageInput;
