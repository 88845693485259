import React, { useState } from 'react';
import { Flex, Box, Button } from 'rebass';
import { Label, Input, Select } from '@rebass/forms';
import { FiPlus, FiTrash2 } from 'react-icons/fi';
import cloneDeep from 'lodash/cloneDeep';
import helpers from 'utils/helpers';

function CustomAttributeEdit(props) {
    // const [isNew, setIsNew] = useState(props.isNew)
    const [cspa, setCspa] = useState(props.cspa);

    function onFormChange(e) {
        const { name, value } = e.target;
        setCspa(prevState => ({
            ...prevState,
            [name]: value
        }));
    }

    function updateDefinition(e, d, field_name) {
        const { definition } = cloneDeep(cspa);
        const index = definition.findIndex(def_loc => def_loc && def_loc.id === d.id);
        if (index > -1) {
            definition[index][field_name] = e.target.value;
            setCspa(prevState => ({
                ...prevState,
                definition
            }));
        }
    }

    function addDefinitionItem(label) {
        let { definition } = cloneDeep(cspa);
        if (!Array.isArray(definition)) {
            definition = [];
        }

        const id = helpers.ID();

        definition.push({
            id,
            label,
            value: id,
            creating: 1
        });

        setCspa(prevState => ({
            ...prevState,
            definition
        }));
    }

    return (
        <form
            onSubmit={e => {
                e.preventDefault();
                props.onSave(cspa);
                props.onClose();
            }}
        >
            {cspa.id && (
                <Box mb={3}>
                    <Label>ID</Label>
                    <Box className="text-secondary-dark">{cspa.id}</Box>
                </Box>
            )}
            <Label>Title</Label>
            <Input type="text" name="title" value={cspa.title} onChange={onFormChange} autoFocus required />
            <Label mt={3}>Type</Label>
            <Select name="type" value={cspa.type} onChange={onFormChange} disabled={!!cspa.id}>
                <option value="text">Text</option>
                <option value="dropdown">Single-select (Dropdown)</option>
                <option value="checkbox">Multi-select (Checkbox)</option>
            </Select>

            {(cspa.type === 'dropdown' || cspa.type === 'checkbox') && (
                <>
                    <Label mt={3}>Options</Label>

                    {cspa.definition &&
                        cspa.definition.map(d => (
                            <Box>
                                <Flex mb={3}>
                                    <Input
                                        value={d.label}
                                        onChange={e => {
                                            updateDefinition(e, d, 'label');
                                        }}
                                        required
                                    />
                                    <Button
                                        type="button"
                                        variant="secondary-gray"
                                        className="secondary-icon"
                                        ml={3}
                                        onClick={() => {
                                            const { definition } = cloneDeep(cspa);
                                            const index = definition.findIndex(
                                                def_loc => def_loc && def_loc.id === d.id
                                            );

                                            if (index > -1) {
                                                definition.splice(index, 1);
                                                setCspa(prevState => ({
                                                    ...prevState,
                                                    definition
                                                }));
                                            }
                                        }}
                                    >
                                        <FiTrash2 style={{ marginRight: '2px' }} />{' '}
                                    </Button>
                                </Flex>
                            </Box>
                        ))}
                    <Box>
                        <Button
                            type="button"
                            variant="secondary"
                            onClick={() => {
                                addDefinitionItem('');
                            }}
                        >
                            <FiPlus /> Add option
                        </Button>
                    </Box>
                </>
            )}
            <Box className="modal-actions">
                <Button
                    type="button"
                    variant="secondary-gray"
                    mr={3}
                    onClick={() => {
                        props.onClose();
                    }}
                >
                    Cancel
                </Button>

                <Button type="submit" variant="primary" className="modal-primary" mr={0}>
                    Save
                </Button>
            </Box>
        </form>
    );
}

export default CustomAttributeEdit;
