import Select from 'components/Select';
import classNames from 'classnames';
import React, { useMemo } from 'react';
import { FiArrowLeft, FiArrowRight } from 'react-icons/fi';
import { Box, Button, Flex } from 'rebass';

import helpers from 'utils/helpers';

/**
 * NumberedPagination. Component to display a numbered pagination.
 * It also includes a select to change the number of items per page.
 *
 * @param {Object} props
 * @param {Number} props.total total items
 * @param {Array} props.links links to the pages
 * @param {Number} props.from from item
 * @param {Number} props.to to item
 * @param {(page: number) => void} props.onPageChange Callback to change the page
 * @param {Number} props.perPage How many items per page
 * @param {Number} props.page Current page
 * @param {(perPage: number) => void} props.onPerPageChange Callback to change the number of items per page
 * @param {String?} props.className class name
 */
export function NumberedPagination({
    total,
    links,
    from,
    to,
    onPageChange,
    perPage,
    page,
    onPerPageChange,
    className
}) {
    const navigation = useMemo(() => {
        const previousLink = links[0];
        const nextLink = links[links.length - 1];

        // get the next and previous page numbers
        const next = nextLink && nextLink.url ? Number(helpers.getQueryParam(nextLink.url, 'page')) : null;
        const prev = previousLink && previousLink.url ? Number(helpers.getQueryParam(previousLink.url, 'page')) : null;

        return {
            next,
            prev,
            // remove the first and last links (previous and next)
            links: links.slice(1, -1)
        };
    }, [links]);

    return (
        <Flex
            height="44px"
            sx={{ position: 'relative' }}
            alignItems="center"
            p="12px 12px"
            className={classNames('bg-pure', className)}
        >
            <Select
                variant="transparent"
                className="text-pure strong"
                label={value => `${value} per page`}
                value={perPage}
                onChange={onPerPageChange}
                options={[
                    {
                        value: 20,
                        label: '20 per page'
                    },
                    {
                        value: 100,
                        label: '100 per page'
                    },
                    {
                        value: 500,
                        label: '500 per page'
                    }
                ]}
            />
            <Box as="span" className="vertical-line divider-color" px={3}>
                |
            </Box>
            <Box as="p" className="fs-title-14 color-text-primary">
                {helpers.numberFormat(Number(from))}-{helpers.numberFormat(Number(to))} of{' '}
                {helpers.numberFormat(Number(total))}
            </Box>
            <Flex sx={{ gap: 2, position: 'absolute', left: '50%', transform: 'translateX(-50%)' }}>
                <Button
                    variant="secondary-gray"
                    onClick={() => onPageChange(navigation.prev)}
                    disabled={!navigation.prev}
                >
                    <FiArrowLeft css={{ marginRight: '6px' }} />
                    Prev
                </Button>
                {navigation.links.map((link, index) => (
                    <Button
                        key={`${link.label}-${index}`}
                        variant={page === Number(link.label) ? 'primary' : 'secondary-gray'}
                        className="pagination-button"
                        disabled={!link.url}
                        onClick={() => onPageChange(Number(link.label))}
                    >
                        {!link.url ? '...' : helpers.numberFormat(Number(link.label))}
                    </Button>
                ))}
                <Button
                    variant="secondary-gray"
                    onClick={() => onPageChange(navigation.next)}
                    disabled={!navigation.next}
                >
                    Next
                    <FiArrowRight css={{ marginRight: '0px !important', marginLeft: '6px !important' }} />
                </Button>
            </Flex>
        </Flex>
    );
}

export default NumberedPagination;
