import React, { useEffect, useState } from 'react';
import OutsideClickHandler from 'react-outside-click-handler';
import { FiArrowUp, FiArrowDown, FiArrowLeft, FiArrowRight, FiChevronLeft, FiChevronRight } from 'react-icons/fi';
import { Button, Box } from 'rebass';
import store from 'store';
import helpers from 'utils/helpers';
import moment from 'moment-timezone';

export default props => {
    const store_iE_key = 'SideOverlay_isExpanded';
    const store_isExpanded = props.isExpanded == 1 ? 1 : store.get(store_iE_key);

    const [isExpanded, setIsExpanded] = useState(store_isExpanded);

    const svgStyle = { fontSize: '22px' };

    return (
        <OutsideClickHandler
            onOutsideClick={() => {
                props.onClose();
            }}
        >
            <div
                style={{
                    position: 'fixed',
                    right: 0,
                    top: 0,
                    bottom: 0,
                    zIndex: 999,
                    textAlign: 'left',
                    width: isExpanded ? '800px' : '600px',
                    borderLeft: '1px solid #eee',
                    background: '#fefefe',
                    boxShadow: 'rgba(36, 18, 77, 0.1) -10px -8px 12px -6px',
                    overflow: 'scroll'
                }}
                {...props}
            >
                {props.children}

                <Box
                    sx={{ position: 'fixed', top: '15px', right: isExpanded ? '772px' : '572px', zIndex: 10 }}
                    fontSize={1}
                >
                    <Button
                        variant="link"
                        className="muted"
                        onClick={() => {
                            const newVal = !isExpanded;
                            setIsExpanded(newVal);
                            store.set(store_iE_key, newVal);
                        }}
                    >
                        {helpers.newFeatureTag(moment('12-08-2021', 'MM-DD-YYYY'), { margin: '0 8px 0 0' })}
                        {isExpanded ? (
                            <>
                                <FiChevronRight style={svgStyle} />
                            </>
                        ) : (
                            <>
                                <FiChevronLeft style={svgStyle} />
                            </>
                        )}
                    </Button>
                </Box>
            </div>
        </OutsideClickHandler>
    );
};
