import React from 'react';

import { CreatePersonDataKey } from 'components/PersonDataKey';
import NiceModal from 'components/NiceModal';

/**
 * Modal to edit a panel property
 *
 * @param {Object} props
 * @param {Object} props.panelProperty - The panel property to edit
 * @param {Function} props.onClose - Callback to close the modal
 * @param {(data) => void} props.onSuccess - Callback to call when the edit is successful
 */
export function EditPanelPropertyModal({ panelProperty, onClose, onSuccess }) {
    return (
        <NiceModal isOpen shouldCloseOnOverlayClick title={`Edit ${panelProperty.title}`} onRequestClose={onClose}>
            <CreatePersonDataKey edit={panelProperty} onSuccess={onSuccess} onClose={onClose} />
        </NiceModal>
    );
}

export default EditPanelPropertyModal;
