import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import NiceWrapper from 'components/NiceWrapper';
import { Flex, Box, Heading, Button } from 'rebass';
import { Label, Switch, Input } from '@rebass/forms';
import helpers from 'utils/helpers';
import services from 'services/services';
import service_people from 'services/people';
import service_accounts from 'services/accounts';
import LoadingWrapper from 'components/LoadingIndicator/LoadingWrapper';
import LoadingIndicator from 'components/LoadingIndicator';
import { useHistory } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { FiCheckCircle } from 'react-icons/fi';

function PanelistForgotPassword(props) {
    const [loading, setLoading] = useState(true);
    const [tokenEmailSent, setTokenEmailSent] = useState(false);
    const [personData, setPersonData] = useState({});
    const [account, setAccount] = useState({});
    const history = useHistory();
    const { addToast } = useToasts();

    useEffect(() => {
        getAccountXHR(props.match.params.account_uuid);
    }, [props.match.params.account_uuid]);

    function getAccountXHR(account_uuid) {
        console.log(account_uuid);

        service_accounts
            .getAccountBySlug(account_uuid)
            .then(account => {
                setAccount(account);
                setLoading(false);
            })
            .catch(e => {
                helpers.trackError(e);
                setLoading(false);
            });
    }

    function sendForgotPasswordEmailXHR() {
        const email = document.getElementById('email').value;

        service_people
            .sendForgotPasswordEmailPanelistExperience(account.uuid, email)
            .then(account => {
                console.log('succes....', account);
                setLoading(false);
                setTokenEmailSent(true);
            })
            .catch(e => {
                console.log('ERROR', e);
                setLoading(false);

                const errorText = services.parseAndTrackXhrErrors(e);
                addToast(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    return (
        <div style={{ margin: 'auto' }}>
            <Helmet titleTemplate="%s">
                <title>{account && account.title ? account.title : 'Panel Profile'}</title>
                {helpers.renderFavicon(account)}
            </Helmet>
            {loading ? (
                <Box my={4} style={{ width: '100%', height: '80px', position: 'relative' }}>
                    <LoadingWrapper>
                        <LoadingIndicator />
                    </LoadingWrapper>
                </Box>
            ) : account && account.title ? (
                <>
                    <Box style={{ textAlign: 'center' }} mt={4} mb={3}>
                        {account.logo ? (
                            <img
                                src={account.logo}
                                style={{ maxWidth: '100%', maxHeight: helpers.getPanelistLogoHeight(account) }}
                                alt="Account Logo"
                            />
                        ) : (
                            <div style={{ height: '32px' }}></div>
                        )}
                        <Heading fontSize={[3, 4]} mt={2}>
                            <br />
                            Forgot Password
                        </Heading>
                    </Box>
                    <NiceWrapper style={{ background: 'white', color: 'black', padding: '0' }}>
                        <Box pt={4} px={4} pb={3} sx={{ textAlign: 'center' }}>
                            <Box fontSize={1} style={{ minWidth: '320px' }}>
                                {tokenEmailSent == false ? (
                                    <form
                                        onSubmit={e => {
                                            e.preventDefault();
                                            e.stopPropagation();
                                            sendForgotPasswordEmailXHR();
                                        }}
                                    >
                                        <Input type="email" placeholder="E-mail" id="email" required autoFocus />
                                        <Box mt={3}>
                                            <Button
                                                type="submit"
                                                variant="primary"
                                                mr={0}
                                                mb={3}
                                                style={helpers.accountThemeButtonStyle(account, { width: '100%' })}
                                            >
                                                Send Email
                                            </Button>
                                        </Box>
                                    </form>
                                ) : (
                                    <Box my={4} mx={3}>
                                        <FiCheckCircle style={{ color: 'green', fontSize: '24px' }} /> <br />
                                        <br />
                                        The reset password link will be sent to the provided email address if the
                                        account exists.
                                        <br />
                                        Please check your email, and follow the instructions.
                                    </Box>
                                )}
                            </Box>
                        </Box>
                        <Box fontSize={1} p={3} sx={{ textAlign: 'center', background: '#f7f7f7' }}>
                            <Link
                                to={`/panelist/${props.match.params.account_uuid}/login`}
                                style={helpers.accountThemeLinkStyle(account)}
                            >
                                Back to Sign In page.
                            </Link>
                        </Box>
                    </NiceWrapper>
                </>
            ) : (
                <Box mt={4}>Account not found.</Box>
            )}
        </div>
    );
}

export default PanelistForgotPassword;
