import styled from 'styled-components';

export default styled.div.attrs(props => ({
    padding: props.padding || '16px 32px',
    width: props.width || 'auto'
}))`
    padding: ${props => props.padding};
    width: ${props => props.width};
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    align-items: center;
    min-height: 48px;
    border-bottom: 1px solid rgba(73, 95, 120, 0.12);
    color: #929db0;
    &:hover {
        background: rgba(209, 233, 255, 0.3);
    }
    &:hover input[type='checkbox'] {
        display: block;
    }
    input[type='checkbox'] {
        display: none;
        &:checked {
            display: block;
        }
    }
    &.header-row {
        font-weight: 500;
        font-size: 12px;
        min-height: auto;
        padding: 12px 32px;
        color: #6b7a94;
        //background: #F6F6F6;
    }
    &:hover.header-row {
        //background: none;
    }
    &.header-row-dark {
        font-weight: 500;
        background: #fbfafc;
    }
    &:hover.header-row-dark {
        background: #fbfafc;
    }
    &.lean {
        padding: 4px 0;
    }
`;
