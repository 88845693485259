import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useToasts } from 'react-toast-notifications';
import H1 from 'components/H1';
import { Flex, Box, Button, Heading } from 'rebass';
import { Label } from '@rebass/forms';
import services from 'services/services';
import studyServices from 'services/studies';
import helpers from 'utils/helpers';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';
import { useTheme } from 'emotion-theming';

import helpersStudy from 'utils/helpers-study';
import NiceWrapper from 'components/NiceWrapper';
import AppPageWrapper from 'components/AppPageWrapper';
import AppPageWrapperSectionHeader from 'components/AppPageWrapper/AppPageWrapperSectionHeader';
import AppPageWrapperSectionBody from 'components/AppPageWrapper/AppPageWrapperSectionBody';
import ListWrapper from 'components/ListWrapper';
import ItemWrapper from 'components/ItemWrapper';
import StudyParticipantsList from 'components/Study/StudyParticipantsList';

import { MarketingPageWrapper, MarketingPageSection } from 'components/Marketing';
import { isMobile } from 'react-device-detect';

function ClientGrid(props) {
    // array of StudyPeople
    const [study, setStudy] = useState({});
    const [studyPeople, setStudyPeople] = useState({});
    const [p_checked, setPChecked] = useState([]);
    const [checkAllOrVisibleStatus, setCheckAllOrVisibleStatus] = useState('none');
    const { addToast } = useToasts();
    const theme = useTheme();

    //console.log(props.match.params.study_uuid)

    useEffect(() => {
        getStudyPublicXHR(props.match.params.study_uuid);
    }, []);

    function renderScreenerQuestionHeaders() {
        const headers = [];
        headers.push(
            <th style={{ position: 'sticky', left: 0 }}>
                <span style={{ display: 'inline-block', marginLeft: '8px' }}>Name</span>
            </th>
        );

        headers.push(<th>Session</th>);
        headers.push(<th>Confirmed</th>);
        headers.push(<th>Location</th>);
        headers.push(<th>Paid</th>);

        const questions = helpersStudy.getAllScreenerQuestionsList(study.screener, study.screeners);
        questions.forEach(question => {
            if (!question.static) {
                headers.push(
                    <th style={{ maxWidth: '300px' }}>{helpersStudy.renderTableHeader(question.label, question.id)}</th>
                );
            }
        });
        return headers;
    }

    function renderStudyPeople() {
        const render = [];
        if (study && study.study_people) {
            study.study_people.forEach(sp => {
                const participantElement = helpersStudy.renderStudyPersonItemInnards(sp, study);
                render.push(participantElement);

                /*render.push(
                    <ItemWrapper>
                        <Box width={1 / 4}>{helpers.personGetFullName(sp.person).trim() ? helpers.personGetFullName(sp.person) : 'Name missing'}</Box>
                        <Box width={1 / 4}></Box>
                        <Box width={1 / 4}>{sp.spot ? sp.spot : ''}</Box>
                        <Box width={1 / 4}></Box>
                    </ItemWrapper>
                );*/
            });
        }
        //console.log(render);
        return render;
    }

    function getStudyPublicXHR(study_uuid) {
        studyServices
            .getStudyPublic(study_uuid)
            .then(study => {
                setStudy(study);
                getStudyPeoplePublicXHR(study.id, 'all');
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);

                addToast(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    function getStudyPeoplePublicXHR(study_id, segment, page, export_csv) {
        const that = this;

        // this.setState({study_people_loading: true});

        const currentRequest = studyServices.getStudyPeople({
            study_id: study_id,
            segment: segment,
            page: page
            //search_keyword: this.state.search_keyword,
            //filters: JSON.stringify(this.state.screenerFilters),
            //export_csv: export_csv
        });

        /*if (export_csv) {
            currentRequest.then(url => {
                window.location = `${config.API_URL}${url}`;
            });
        } else {*/
        currentRequest
            .then(study_people_xhr => {
                //if (that.getStudyPeople_lastRequest === currentRequest) {

                // if this is page 2, 3, 4, etc then lets add the response to the current dataset
                // if its page 1, then just render this data
                if (page > 1) {
                    const new_data = study_people_xhr.data;
                    study_people.data = study_people.data.concat(new_data);
                }

                setStudyPeople(study_people_xhr);

                /*this.setState({
                            study_people: study_people_xhr,
                            study_people_loading: false,
                            study_people_loading_more: false
                        });*/
                /*} else {
                        console.log('IGNORING OLD')
                    }*/
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);
                addToast(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
        //}
    }
    return (
        <Flex flexDirection="column" style={{ width: '100%' }}>
            <Helmet>
                <title>Client Grid</title>
            </Helmet>
            <MarketingPageWrapper isMobile={isMobile}>
                <MarketingPageSection>
                    {study.account && study.account.logo && (
                        <Box mt={4}>
                            <img
                                src={study.account.logo}
                                style={{ maxWidth: '100%', maxHeight: '80px' }}
                                alt="Account Logo"
                            />
                        </Box>
                    )}

                    <Heading mt={4} mb={2} color="black">
                        {study.title}
                    </Heading>
                    <Box fontSize={1} mb={4}>
                        Some descriptive stuff for the client.
                    </Box>

                    <Label mb={2} className="loud">
                        Participants
                    </Label>
                </MarketingPageSection>
            </MarketingPageWrapper>

            <MarketingPageWrapper isMobile={isMobile} fullwidth background="#fff">
                <MarketingPageSection style={{ textAlign: 'left' }}>
                    {studyPeople && studyPeople.data && (
                        <>
                            <Box style={{ background: '#fff' }}>
                                <StudyParticipantsList
                                    adminFacing={false}
                                    study={study}
                                    study_people={studyPeople}
                                    theme={theme}
                                    p_checked={p_checked}
                                    checkAllOrVisibleStatus={checkAllOrVisibleStatus}
                                    onCheckParticipant={sp => {
                                        console.log(sp);
                                    }}
                                    handleCheckAllDropdownChange={change => {
                                        console.log(change);
                                    }}
                                    deselectAll={deselect => {
                                        console.log(deselect);
                                    }}
                                    onChangeSort={sort => {
                                        //console.log('NEW SORT DIR', sort);
                                    }}
                                />
                            </Box>
                        </>
                    )}
                </MarketingPageSection>
            </MarketingPageWrapper>

            <Box>
                <br />
                <br />
                <br />
                <br />
            </Box>
        </Flex>
    );
}

export default ClientGrid;
