import React from 'react';
import xhr from 'xhr.js';
import config from 'config';
import auth from 'services/auth';
import services from 'services/services';
import styled from 'styled-components';
import { Box } from 'rebass';
import { Label, Input, Select, Textarea, Switch } from '@rebass/forms';
import moment from 'moment-timezone';
import helpers from 'utils/helpers';
import { FiCheckCircle } from 'react-icons/fi';
import {
    SP_STATUS_KEY_TITLE,
    STANDARD_FILTERS,
    COMPARE_FILTERS,
    SCREENER_ANYONE,
    SCREENER_STUDY_ONLY
} from 'utils/constants';
import helpersScreener from 'utils/helpers-screener';
import cloneDeep from 'lodash/cloneDeep';

const helpersStudy = {
    /**
     * Translate study person status to color
     *
     * @param {1|2|3|4|5|6|7|unknown} status
     * @returns {gray|green|red|yellow} The color of the status
     */
    translateStudyPersonStatusToColor(status) {
        switch (status) {
            case 1:
            case 4:
                return 'green';
            case 2:
            case 3:
            case 7:
                return 'red';
            case 5:
            case 6:
                return 'yellow';
            default:
                return 'gray';
        }
    },
    shouldShowDupeIpWarning(screener, screener_answers) {
        if (screener && screener_answers) {
            if ([SCREENER_ANYONE, SCREENER_STUDY_ONLY].includes(parseInt(screener.participant_external))) {
                if (screener.show_dupe_ip && screener_answers.dupe_ip) {
                    return true;
                }
            }
        }
    },
    didLatestEmailFail(emails_to) {
        let isFailed = false;
        try {
            if (emails_to && Array.isArray(emails_to) && emails_to.length > 0) {
                emails_to.sort((a, b) => {
                    return b.id - a.id;
                });
                if (emails_to[0].status == 'failed') {
                    isFailed = true;
                }
            }
        } catch (e) {
            helpers.trackError(e);
        }

        return isFailed;
    },
    isPhoneNumberValid(study, phone_number) {
        const phone_just_numbers = helpers.extractNumbers(phone_number);
        if (phone_just_numbers && phone_just_numbers.length >= 10) {
            return true;
        }
        return false;
    },
    shouldShowEmailAttachmentButton(account) {
        return true;
        // check later
        if (account && account.email_settings && account.email_settings.provider == 'amazon') {
            return true;
        } else {
            return false;
        }
    },
    getCspaTableColumnId(cspa) {
        if (cspa) {
            return 'cspa_' + cspa.id;
        }
    },
    getScreenerAnsweredTableColumnId(screener_id) {
        if (screener_id) {
            return 'screener_answered' + screener_id;
        }
    },
    getScreenerCustomVariabledTableColumnId(screener_id) {
        if (screener_id) {
            return 'screener_custom_variables' + screener_id;
        }
    },
    getScreenerQuestionTableColumnId(question_id) {
        if (question_id) {
            return 'screener_question' + question_id;
        }
    },
    isSpLocked(study_person) {
        return study_person && study_person.users_viewing && study_person.users_viewing.id;
    },
    getAllScreenerQuestionsList(screener, screeners) {
        const q = [];
        // this is the now-deprecated attribute for a study
        if (screener) {
            screener.forEach(page => {
                page.forEach(question => {
                    question.screener_id = screener.id;
                    q.push(question);
                });
            });
        }
        if (screeners) {
            screeners.forEach(s => {
                s.form.forEach(page => {
                    page.forEach(question => {
                        question.screener_id = s.id;
                        q.push(question);
                    });
                });
            });
        }
        return q;
    },
    getQuestionsByScreener(s) {
        const q = [];
        if (s) {
            s.form.forEach(page => {
                page.forEach(question => {
                    q.push(question);
                });
            });
        }
        return q;
    },
    getScreenerQuestions(s) {
        const q = [];
        s.form.forEach(page => {
            page.forEach(question => {
                q.push(question);
            });
        });
        return q;
    },
    renderTableHeader(title, column_id) {
        const addedStylesTitle = {
            marginRight: '4px'
        };
        /*if (column_id == this.state.dataSortColumnId) {
            // addedStylesTitle.fontWeight = '600';
            addedStylesTitle.color = 'black';
        }*/

        const THWrapper = styled(Box)`
            //cursor: pointer;
            text-overflow: ellipsis;
            overflow: hidden;
            white-space: nowrap;
            padding: 0 16px 0 0;

            .movebutton: {
                display: none !important;
                :hover {
                    display: block;
                }
            }
        `;

        return (
            <THWrapper
            /* onClick={() => {
                    this.setSort(column_id, 'asc');
                }} */
            >
                <span
                    style={{
                        display: 'inline-block',
                        verticalAlign: 'top',
                        color: 'black',
                        marginTop: '-1px',
                        float: 'right'
                    }}
                >
                    {/* // eslint-disable-next-line no-nested-ternary
                    column_id == this.state.dataSortColumnId ? (
                        this.state.dataSortDirection == 'desc' ? (
                            <FiArrowDown />
                        ) : (
                            <FiArrowUp />
                        )
                    ) : (
                        ''
                    )*/}
                </span>
                <span style={addedStylesTitle}>{title}</span>
            </THWrapper>
        );
    },
    getAllScreenerAnswersList(answers) {
        const a = [];
        if (answers) {
            answers.forEach(page => {
                page.forEach(answer => {
                    a.push(answer);
                });
            });
        }
        return a;
    },

    generateMatrixAnswerCombinations(options) {
        let pairs = [];
        if (options) {
            options.forEach(o_row_loop => {
                if (o_row_loop.type == 'row') {
                    options.forEach(o_col_loop => {
                        if (o_col_loop.type == 'column') {
                            const row_col_key = o_row_loop.key + '_' + o_col_loop.key;
                            const row_col_text =
                                (o_row_loop.reporting_value ? o_row_loop.reporting_value : o_row_loop.text) +
                                ' - ' +
                                (o_col_loop.reporting_value ? o_col_loop.reporting_value : o_col_loop.text);

                            pairs.push({
                                row_col_key: row_col_key,
                                row_col_text: row_col_text
                            });
                        }
                    });
                }
            });
        }
        return pairs;
    },

    getScreenerAnswerValue(question, value, value_add_other, all_questions, all_answers_for_sp) {
        const answer_values = [];
        let all_options = [];
        //console.log('getScreenerAnswerValue_x', question.options)
        if (helpersScreener.getElementNamesWithOptions().includes(question.element)) {
            all_options = cloneDeep(question.options);
            if (question.pipe_options_from) {
                const answers_by_form_element_name = {};
                all_answers_for_sp.forEach(answer => {
                    answers_by_form_element_name[answer.name] = answer.value;
                });

                let question_piped_from = all_questions.find(q => q.field_name == question.pipe_options_from);
                let question_piped_from_cloned = cloneDeep(question_piped_from);

                const all_questions_not_flat = [all_questions];
                question_piped_from_cloned.options = helpersScreener.getOriginalAndPipedAnswerOptions(
                    question_piped_from_cloned,
                    all_questions_not_flat,
                    answers_by_form_element_name
                );

                const piped_answer_options = helpersScreener.getPipedAnswerOptions(
                    question_piped_from_cloned,
                    answers_by_form_element_name
                );

                if (question.element == 'Matrix') {
                    piped_answer_options.forEach(o => {
                        o.type = 'row';
                    });
                }

                all_options = all_options.concat(piped_answer_options);

                //console.log(question.label, piped_answer_options);

                //question.options = cloneDeep(all_options);
            }
        }
        //console.log('getScreenerAnswerValue_x', all_options)

        // value can be an array lol
        if (Array.isArray(value)) {
            //console.log('getScreenerAnswerValue - is array', question.label, value)
            value.forEach(single_value => {
                // all_options
                //console.log('looking for anwers', all_options, value)
                if (question.element == 'Matrix') {
                    if (all_options) {
                        let matrix_options = this.generateMatrixAnswerCombinations(all_options);
                        //console.log('matrix_options', matrix_options)
                        if (matrix_options) {
                            //console.log(single_value, matrix_options)

                            // if this is a "short answer" matrix response
                            if (typeof single_value === 'object') {
                                answer_values.push(single_value.value);
                            } else {
                                const answer_option_found = matrix_options.find(
                                    m_o => m_o.row_col_key === single_value
                                );
                                //console.log(matrix_options, answer_option_found);
                                //console.log('single_value', single_value)
                                if (answer_option_found) {
                                    answer_values.push(answer_option_found.row_col_text);
                                }
                            }
                        }
                    }
                } else {
                    if (all_options) {
                        const answer_option_found = all_options.find(option => option.key === single_value);
                        if (answer_option_found) {
                            //console.log('answer-found', single_value, answer_option_found);
                            if (answer_option_found.is_add_other) {
                                // console.log('need is_add_other value', value)
                                answer_values.push(value_add_other);
                            } else if (answer_option_found.is_none_of_the_above) {
                                // console.log('need is_add_other value', value)
                                answer_values.push('None of the above');
                            } else {
                                const text_or_reporting_value =
                                    answer_option_found && answer_option_found.reporting_value
                                        ? answer_option_found.reporting_value
                                        : answer_option_found.text;
                                answer_values.push(text_or_reporting_value);
                            }
                        } else {
                            console.log('radio/chx ans NOT found', single_value);
                        }
                    } else {
                        console.log('missing options', question.label);
                    }
                }
            });
        } else {
            if (question.element == 'Dropdown') {
                if (all_options) {
                    const answer_option_found = all_options.find(
                        option => option.value === value || option.text === value
                    );
                    if (answer_option_found) {
                        const text_or_reporting_value =
                            answer_option_found && answer_option_found.reporting_value
                                ? answer_option_found.reporting_value
                                : answer_option_found.text;
                        answer_values.push(text_or_reporting_value);
                        console.log('OK ans found', all_options, value);
                    } else {
                        console.log('ans not found!', all_options, value);
                    }
                }
            } else {
                answer_values.push(value);
            }
        }
        return answer_values;
    },
    mapQuestionsToAnswers(all_questions, all_answers_for_sp) {
        const map_q_a = {};
        all_questions.forEach(q => {
            if (q) {
                // map q.field_name to answer.name
                // console.log('..mapping', q, )
                const answer_found = all_answers_for_sp.find(
                    answer =>
                        // console.log('..mapping', q, answer)
                        answer.name === q.field_name
                );
                if (answer_found) {
                    //console.log('answer_found', answer_found);
                    let gsav = this.getScreenerAnswerValue(
                        q,
                        answer_found.value,
                        answer_found.value_add_other,
                        all_questions,
                        all_answers_for_sp
                    );
                    //console.log('gsav', gsav);
                    map_q_a[q.field_name] = gsav;
                }
            }
        });
        return map_q_a;
    },
    displayStudyPersonSpot(sp, study) {
        return sp.spot
            ? moment
                  .utc(sp.spot)
                  .tz(study.timezone)
                  .format('MMM Do, YYYY [at] h:mma (z)')
            : '';
    },
    displayStudyPersonSpotLocal(sp) {
        return sp.spot
            ? moment
                  .utc(sp.spot)
                  .local()
                  .format('MMM Do, YYYY [at] h:mma')
            : '';
    },
    displayConfirmedAt(sp, study) {
        return sp.confirmation_accepted_at ? (
            <>
                <FiCheckCircle />{' '}
                {moment
                    .utc(sp.confirmation_accepted_at)
                    .tz(study.timezone)
                    .format('MMM Do, YYYY [at] h:mma (z)')}
            </>
        ) : (
            ''
        );
    },
    displayStudyLastContactedAt(sp, study) {
        return sp.last_contacted_at
            ? moment
                  .utc(sp.last_contacted_at)
                  .tz(study.timezone)
                  .format('MMM Do, YYYY [at] h:mma (z)')
            : '';
    },
    getHumanReadableSPStatus(status_id) {
        if (SP_STATUS_KEY_TITLE.hasOwnProperty(status_id)) {
            return SP_STATUS_KEY_TITLE[status_id];
        }
        return '';
    },
    getCompareTitleFromKey(filter_id) {
        let compare_found;
        Object.keys(COMPARE_FILTERS).forEach(key => {
            const is_found = COMPARE_FILTERS[key].find(compare => compare.id == filter_id);
            if (is_found) {
                compare_found = is_found;
            }
        });
        return compare_found;
    },
    renderScreenerFilterIndividual(sf, study, panel_columns, users, availability) {
        const el = [];
        try {
            let compare_found;
            if (sf.compare) {
                compare_found = this.getCompareTitleFromKey(sf.compare);
            }
            if (sf.type == 'screener') {
                const all_questions = this.getAllScreenerQuestionsList(study.screener, study.screeners);
                const q = helpersScreener.getQuestionByFieldName(all_questions, sf.field_name);

                if (q) {
                    // q attach options
                    let all_options = [];
                    //console.log('getScreenerAnswerValue_x', question.options)
                    if (helpersScreener.getElementNamesWithOptions().includes(q.element)) {
                        all_options = cloneDeep(q.options);
                        if (q.pipe_options_from) {
                            let piped_answer_options = [];

                            let found_piped_q = all_questions.find(aqq => aqq.field_name == q.pipe_options_from);
                            if (found_piped_q) {
                                piped_answer_options = found_piped_q.options;
                                if (q.element == 'Matrix') {
                                    piped_answer_options.forEach(o => {
                                        o.type = 'row';
                                    });
                                }
                            }

                            all_options = all_options.concat(piped_answer_options);
                        }
                    }

                    el.push(<Label className="quiet">Screener answer</Label>);
                    el.push(<span className="black medium">{helpers.removeHtml(q.label)} </span>);

                    if (helpersScreener.isElementTextMatrix(q)) {
                        //console.log('sfff', sf)
                        if (sf.id_child) {
                            // find the child question
                            let matrix_options = helpersStudy.generateMatrixAnswerCombinations(all_options);
                            if (matrix_options) {
                                console.log('matrix_options', all_options);
                                const option_to_show = matrix_options.filter(
                                    option => option.row_col_key == sf.id_child
                                );
                                if (option_to_show && option_to_show.length) {
                                    el.push(
                                        <>
                                            field <span className="black medium">{option_to_show[0].row_col_text}</span>
                                        </>
                                    );
                                }
                            }
                        }
                    }

                    if (compare_found) {
                        el.push(<span> {compare_found.title} </span>);
                    }

                    if (sf.values && !['is_known', 'is_unknown'].includes(sf.compare)) {
                        let temp_q_with_additional_options = cloneDeep(q);
                        if (temp_q_with_additional_options.options) {
                            temp_q_with_additional_options.options = all_options;
                        }
                        const filter_ind_answers = sf.values.map((v, index) => {
                            const ans_obj = {};
                            ans_obj[sf.field_name] = v;
                            return helpersScreener.getItemValueHumanReadable(
                                temp_q_with_additional_options,
                                ans_obj,
                                all_questions
                            );
                        });

                        if (helpersScreener.getElementNamesWithOptions().includes(q.element)) {
                            if (q.element == 'Matrix') {
                                // console.log('matrix filter_ind_answers' , all_options, filter_ind_answers)

                                if (helpersScreener.isElementTextMatrix(q)) {
                                    let matrix_text_ans;
                                    if (sf.values && sf.values.length) {
                                        matrix_text_ans = sf.values[0];
                                    }
                                    el.push(matrix_text_ans);
                                } else {
                                    let matrix_answers = [];
                                    filter_ind_answers.forEach(ansArray => {
                                        matrix_answers = matrix_answers.concat(ansArray);
                                    });

                                    const matrix_options_human_readable = helpersStudy.generateMatrixAnswerCombinations(
                                        all_options
                                    );

                                    if (matrix_options_human_readable) {
                                        el.push(
                                            <ul
                                                className="screener-mass-options"
                                                style={{ fontSize: '13px', marginTop: '4px' }}
                                            >
                                                {matrix_answers.map(m_a => {
                                                    const found_m_hr = matrix_options_human_readable.find(
                                                        mohr => mohr.row_col_key == m_a
                                                    );
                                                    if (found_m_hr) {
                                                        return <li>{found_m_hr.row_col_text}</li>;
                                                    }
                                                })}
                                            </ul>
                                        );
                                    }
                                }
                            } else {
                                el.push(
                                    <ul
                                        className="screener-mass-options"
                                        style={{ fontSize: '13px', marginTop: '4px' }}
                                    >
                                        {filter_ind_answers.map((v, index) => (
                                            <li>{v}</li>
                                        ))}
                                    </ul>
                                );
                            }
                        } else {
                            if (helpersScreener.getElementNamesDates().includes(q.element)) {
                                let date_val = filter_ind_answers[0];
                                if (date_val) {
                                    if (date_val instanceof Date == false) {
                                        const mom = moment(date_val);
                                        if (mom.isValid()) {
                                            date_val = mom.toDate();
                                        }
                                    }

                                    if (date_val instanceof Date) {
                                        el.push(
                                            <span className="black">
                                                {moment
                                                    .utc(date_val)
                                                    .local()
                                                    .format('MM[/]DD[/]YYYY')}
                                            </span>
                                        );
                                    }
                                }
                            } else {
                                el.push(
                                    <span className="black">
                                        {filter_ind_answers.join(', ')}
                                        {/*filter_ind_answers.map((v, index) => v)*/}
                                    </span>
                                );
                            }
                        }
                    }
                    // el.push();
                }
            } else if (sf.type == 'standard') {
                const standard_filter_found = STANDARD_FILTERS.find(standard_filter => standard_filter.id == sf.id);
                el.push(<Label className="quiet">Study participation</Label>);
                if (standard_filter_found) {
                    el.push(standard_filter_found.title);
                }
                // this is not longer a standard filter, its custom
                // so let's make sure it still renders for old studies
                else if (sf.id == 'paid') {
                    el.push('Paid');
                }
            } else if (sf.type == 'screener_answered') {
                const screener_found = study.screeners.find(s => s.id == sf.id);
                if (screener_found) {
                    el.push(<Label className="quiet">Screener was completed</Label>);
                    el.push(
                        <>
                            <span className="black">{screener_found.title}</span>
                        </>
                    );
                }
            } else if (sf.type == 'screener_qualified') {
                const screener_found = study.screeners.find(s => s.id == sf.id);
                if (screener_found) {
                    el.push(<Label className="quiet">Qualified in screener</Label>);
                    el.push(
                        <>
                            <span className="black">{screener_found.title}</span>
                        </>
                    );
                }
            } else if (sf.type == 'screener_disqualified') {
                const screener_found = study.screeners.find(s => s.id == sf.id);
                if (screener_found) {
                    el.push(<Label className="quiet">Disqualified in screener</Label>);
                    el.push(
                        <>
                            <span className="black">{screener_found.title}</span>
                        </>
                    );
                }
            } else if (sf.type == 'screener_incomplete') {
                const screener_found = study.screeners.find(s => s.id == sf.id);
                if (screener_found) {
                    el.push(<Label className="quiet">Screener was incomplete</Label>);
                    el.push(
                        <>
                            <span className="black">{screener_found.title}</span>
                        </>
                    );
                }
            } else if (sf.type == 'custom_attribute') {
                const custom_attr_found = study.custom_sp_attributes.find(cspa => cspa.id == sf.id);
                if (custom_attr_found) {
                    el.push(<Label className="quiet">Custom attribute</Label>);
                    el.push(<span className="black medium">{custom_attr_found.title} </span>);

                    if (compare_found) {
                        el.push(<span> {compare_found.title} </span>);
                    }

                    if (sf.values) {
                        el.push(
                            <span className="black">
                                {sf.values.map((v, index) => {
                                    if (
                                        custom_attr_found.type &&
                                        (custom_attr_found.type == 'dropdown' || custom_attr_found.type == 'checkbox')
                                    ) {
                                        const cspa_d_found = custom_attr_found.definition.find(d => d.value == v);
                                        if (cspa_d_found) {
                                            return cspa_d_found.label + (sf.values.length > index + 1 ? ', ' : '');
                                        }
                                    } else {
                                        return v;
                                    }
                                })}
                            </span>
                        );
                    }
                }
            } else if (sf.type == 'assigned') {
                //console.log(sf);
                //const custom_attr_found = users.find(u => u.id == sf.id);

                el.push(<Label className="quiet">Study operations</Label>);
                el.push(<span className="black medium">Assigned </span>);

                if (compare_found) {
                    el.push(<span> {compare_found.title} </span>);
                }

                if (sf.values) {
                    el.push(
                        <span className="black">
                            {sf.values.map((v, index) => {
                                const user_found = users.find(u => u.id == v);
                                if (user_found) {
                                    return user_found.name + (sf.values.length > index + 1 ? ', ' : '');
                                }
                            })}
                        </span>
                    );
                }
            } else if (sf.type == 'scheduled') {
                //console.log(sf);
                //const custom_attr_found = users.find(u => u.id == sf.id);

                el.push(<Label className="quiet">Study operations</Label>);
                el.push(<span className="black medium">Scheduled </span>);

                if (compare_found) {
                    el.push(<span> {compare_found.title} </span>);
                }

                if (sf.values) {
                    el.push(
                        <span className="black">
                            {sf.values.map((v, index) => {
                                const avail_found = availability.find(avail => avail && avail.id && avail.id == v);
                                if (avail_found) {
                                    return avail_found.title + (sf.values.length > index + 1 ? ', ' : '');
                                }
                            })}
                        </span>
                    );
                }
            } else if (sf.type == 'paid') {
                //console.log(sf);
                //const custom_attr_found = users.find(u => u.id == sf.id);

                el.push(<Label className="quiet">Study participation</Label>);
                el.push(<span className="black medium">Paid</span>);

                if (compare_found) {
                    el.push(<span> {compare_found.title} </span>);
                }

                if (sf.values) {
                    el.push(<span className="black">{sf.values.join(', ')}</span>);
                }
            } else if (sf.type == 'sp_status') {
                //console.log(sf);
                //const custom_attr_found = users.find(u => u.id == sf.id);

                el.push(<Label className="quiet">Study participation</Label>);
                el.push(<span className="black medium">Participant Status</span>);

                if (compare_found) {
                    el.push(<span> {compare_found.title} </span>);
                }

                if (sf.values) {
                    el.push(
                        <span className="black">
                            {sf.values.map((v, index) => {
                                if (SP_STATUS_KEY_TITLE.hasOwnProperty(v)) {
                                    const sps_label = SP_STATUS_KEY_TITLE[v] ? SP_STATUS_KEY_TITLE[v] : '(not set)';
                                    return sps_label + (sf.values.length > index + 1 ? ', ' : '');
                                }
                            })}
                        </span>
                    );
                }
            } else if (sf.type == 'panel') {
                //console.log('*** TO DO ***** ')
                const panel_col_found = panel_columns.find(panel_col => panel_col.id == sf.id);
                if (panel_col_found) {
                    el.push(<Label className="quiet">Panelist property</Label>);
                    el.push(<span className="black medium">{panel_col_found.title} </span>);

                    if (compare_found) {
                        el.push(<span> {compare_found.title} </span>);
                    }

                    //console.log('RENDERING FILT' ,sf)

                    if (sf.values) {
                        if (panel_col_found.type == 'date') {
                            let date_val = sf.values[0];
                            if (date_val) {
                                if (date_val instanceof Date == false) {
                                    const mom = moment(date_val);
                                    if (mom.isValid()) {
                                        date_val = mom.toDate();
                                    }
                                }

                                if (date_val instanceof Date) {
                                    el.push(
                                        <span className="black">
                                            {moment
                                                .utc(date_val)
                                                .local()
                                                .format('MM[/]DD[/]YYYY')}
                                        </span>
                                    );
                                }
                            }
                        } else {
                            el.push(<span className="black">{sf.values.join(', ')}</span>);
                        }
                    }
                }
            } else if (sf.type == 'last_contacted_at') {
                el.push(<Label className="quiet">Study participation</Label>);
                el.push(<span className="black medium">Last Contacted At (In Study)</span>);
                if (compare_found) {
                    el.push(<span> {compare_found.title} </span>);
                }

                let date_val = sf.values ? sf.values[0] : null;

                if (date_val instanceof Date == false) {
                    const mom = moment(date_val);
                    if (mom.isValid()) {
                        date_val = mom.toDate();
                    }
                }

                if (date_val instanceof Date) {
                    el.push(
                        <span className="black">
                            {moment
                                .utc(date_val)
                                .local()
                                .format('MM[/]DD[/]YYYY [at] h:mma')}
                        </span>
                    );
                }
            } else {
                el.push(`unknown type`);
            }
        } catch (e) {
            el.push(`Error rendering filter`);
            helpers.trackError(e);
        }

        return el;
    },
    generateStudyAvailabilityArray(study_availability, study_tz, sp_tz) {
        let study_availability_array = [];
        study_availability.days.forEach(day => {
            //console.log(day.date, study_tz, sp_tz)
            // there's a bug where when we show the "month & date" since it starts at midnight, western timezones go back 1 day
            if (day && day.spots) {
                const first_spot = day.spots[0];
                study_availability_array.push({
                    title:
                        day.day +
                        ', ' +
                        moment
                            .tz(first_spot.begin, study_tz)
                            .tz(sp_tz)
                            .format('MMM Do'),
                    label: true
                });
            }

            day.spots.forEach(spot => {
                study_availability_array.push({
                    id: spot.begin,
                    title:
                        (spot.title ? `(${spot.title}) ` : '') +
                        moment
                            .tz(spot.begin, study_tz)
                            .tz(sp_tz)
                            .format('h:mma z')
                });
            });
        });
        return study_availability_array;
    },
    renderCspaEdit(study_cspa, sp_cs_found_value, customAttributeChanged) {
        let render_edit;

        if (study_cspa.type && study_cspa.type == 'checkbox') {
            if (study_cspa.definition) {
                render_edit = study_cspa.definition.map(d => {
                    if (!d) {
                        return;
                    }
                    return (
                        <Label className="for-checkbox ellipsis bold text-primary" style={{ display: 'block' }}>
                            <input
                                type="checkbox"
                                //className={`screener_id_question_${screener.id}`}
                                name={`study_cspa_${study_cspa.id}`}
                                style={{ margin: '5px 2px 0 0' }}
                                value={d.value}
                                checked={sp_cs_found_value && sp_cs_found_value.includes(d.value)}
                                onClick={e => {
                                    customAttributeChanged(e, study_cspa);
                                }}
                            />{' '}
                            {d.label}
                        </Label>
                    );
                });
            }
        } else if (study_cspa.type && study_cspa.type == 'dropdown') {
            render_edit = (
                <Select
                    value={sp_cs_found_value ? sp_cs_found_value : ''}
                    onChange={e => customAttributeChanged(e, study_cspa)}
                >
                    <option></option>
                    {study_cspa.definition &&
                        study_cspa.definition.map(d => {
                            if (d && d.value && d.label) {
                                return <option value={d.value}>{d.label}</option>;
                            }
                        })}
                </Select>
            );
        } else {
            render_edit = (
                <Input
                    value={sp_cs_found_value ? sp_cs_found_value : ''}
                    onChange={e => customAttributeChanged(e, study_cspa)}
                />
            );
        }

        return render_edit;
    }
};

export default helpersStudy;
