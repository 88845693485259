import React, { useState } from 'react';
import { HeaderSubnavLink } from 'components/Header/HeaderSubnavLink';
import { Flex, Box, Button } from 'rebass';
import { SmallLink } from 'components/Header/SmallLink';
import H1 from 'components/H1';
import {
    FiTFiMoreHorizontal,
    FiChevronDown,
    FiChevronRight,
    FiUsers,
    FiColumns,
    FiDollarSign,
    FiFileText,
    FiLayers
} from 'react-icons/fi';
import styled from 'styled-components';
import NiceDropdown from 'components/NiceDropdown';
import { useToasts } from 'react-toast-notifications';
import services from 'services/services';
import helpers from 'utils/helpers';
import auth from 'services/auth';

function ParticipantsPageSubNav(props) {
    const { addToast } = useToasts();
    const [showMoreById, setShowMoreById] = useState(false);
    const authState = auth.getAuthState();
    const hasAdminPrivileges = authState.account_role.type === 'admin';

    return (
        <Flex
            px={4}
            sx={{
                flexDirection: 'row',
                lineHeight: '24px',
                borderBottom: '1px solid #e9ecef',
                width: '100%',
                margin: '8px 0 24px'
            }}
        >
            <HeaderSubnavLink to={`/panel`} className="basic-subnav">
                <FiUsers /> Panelists
            </HeaderSubnavLink>
            <HeaderSubnavLink to={`/panels`} className="basic-subnav">
                <FiLayers /> All Panels
            </HeaderSubnavLink>
            <HeaderSubnavLink to={`/panel-data`} className="basic-subnav">
                <FiColumns /> Panel Properties
            </HeaderSubnavLink>
            <HeaderSubnavLink to={`/settings/forms`} className="basic-subnav">
                <FiFileText /> Panel Sign-up
            </HeaderSubnavLink>
            <HeaderSubnavLink to={`/panel-incentives`} className="basic-subnav">
                <FiDollarSign /> Incentive Reports
            </HeaderSubnavLink>
        </Flex>
    );
}

export default ParticipantsPageSubNav;
