import xss from 'xss';

const myxss = new xss.FilterXSS({
    whiteList: {
        u: [],
        br: [],
        b: [],
        blockquote: [],
        s: [],
        i: [],
        ol: ['style'],
        ul: ['style'],
        li: ['class'],
        p: ['style'],
        sub: [],
        sup: [],
        div: ['style'],
        em: [],
        strong: [],
        span: ['style'],
        h1: ['style'],
        h2: ['style'],
        h3: ['style'],
        a: ['style', 'href', 'target'],
        video: ['controls', 'style'],
        source: ['src', 'type'],
        iframe: ['class', 'allowfullscreen', 'src', 'frameborder']
    },
    onIgnoreTag: function(tag, html, options) {
        if (tag === 'img') {
            if (html.includes('track.gif')) {
                // this is panelfox's tracking gif.. so just hide this
                return '';
            } else {
                return `[${tag}]`;
            }
        }
    }
});

export default myxss;
