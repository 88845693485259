import styled from 'styled-components';

export default styled.div`
    //position: sticky;
    top: 0px;
    z-index: 20;
    padding: 16px 32px 0 32px;
    display: flex;
    justify-content: space-between;
    //box-shadow: rgba(36, 18, 77, 0.08) 0px 1px 0px 0px;
    height: 64px;

    h1 {
        position: relative;
        margin: 8px 0;
    }
    &.experience-border {
        border-bottom: 1px solid #d4d9dd;
        padding-bottom: 16px !important;
    }
    &.experience.experience-no-subnav {
        padding-top: 24px;

        h1 {
            font-size: 24px;
            line-height: 34px;
        }
    }

    &.experience {
        position: relative;
        height: auto;
        padding-top: 0;
        padding-bottom: 0;
        //border-bottom: 1px solid #eee;

        h1 {
            margin: 0 0 4px 0;
            font-size: 28px;
            font-weight: 600;
            line-height: 40px;
        }
        .experience-description {
            color: #6b7a94;
            font-size: 14px;
            line-height: 18px;
            margin: 8px 0 0 0;
            max-width: 700px;

            svg {
                font-size: 16px;
                margin-right: 4px;
                stroke-width: 1.5px;
            }

            [data-beacon-article] {
                display: table;
                margin: 8px 0 0 0;
            }
        }
    }
`;
