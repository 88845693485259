import styled from 'styled-components';

export default styled.div`
    display: flex;
    //position: sticky;
    top: 0px;
    z-index: 10;
    padding: 0 32px 16px;
    justify-content: space-between;
`;
