/*
 * StudyCampaignsPage
 *
 * This is the first thing users see of our App, at the '/' route
 */

import React from 'react';
import { Helmet } from 'react-helmet';
import xhr from 'xhr.js';
import 'styles/react-kanban.css';
import styled from 'styled-components';
import { withToastManager } from 'react-toast-notifications';
import helpers from 'utils/helpers';
import { FiSidebar } from 'react-icons/fi';

import H1 from 'components/H1';
import { Flex, Box, Button } from 'rebass';
import LoadingIndicator from 'components/LoadingIndicator';
import LoadingWrapper from 'components/LoadingIndicator/LoadingWrapper';
import services from 'services/services';
import services_studies from 'services/studies';

import AppPageWrapper from 'components/AppPageWrapper';
import AppPageWrapperSectionHeader from 'components/AppPageWrapper/AppPageWrapperSectionHeader';
import AppPageWrapperSectionBody from 'components/AppPageWrapper/AppPageWrapperSectionBody';
import StudyPageSubNav from 'components/Study/StudyPageSubNav';
import ListWrapper from 'components/ListWrapper';
import ItemWrapper from 'components/ItemWrapper';
import {
    EMAIL_STATUS_SENT,
    EMAIL_STATUS_BOUNCED,
    EMAIL_STATUS_COMPLAINT,
    EMAIL_STATUS_OPEN,
    MOMENT_HUMAN_DATE_NO_TZ_SHORT,
    EMAIL_CAMPAIGN_STATUS_SENT,
    EMAIL_CAMPAIGN_STATUS_READY,
    EMAIL_CAMPAIGN_STATUS_SCHEDULED,
    EMAIL_CAMPAIGN_STATUS_CANCELLED,
    EMAIL_STATUS_FAILED
} from 'utils/constants';
import StatusLabel from 'components/StatusLabel';
import CampaignsModal from 'components/CampaignsModal';
import moment from 'utils/moment';
import BreadcrumbBack, { BreadcrumbTitle } from 'components/BreadcrumbBack';
import studyService from 'services/studies';
import cache from 'utils/cache';

class StudyCampaignsPage extends React.Component {
    constructor(props) {
        super(props);
        const studyId = this.props.match.params.id;
        const study = cache.get(`study.${studyId}`);

        this.state = {
            isStudyLoading: !study,
            loading: true,
            campaign: null,
            study: {
                title: '',
                description: '',
                ...study
            },
            campaigns: [],
            smsCampaigns: []
        };
    }

    onSwitchChange(e) {
        console.log(e);

        const study = { ...this.state.study };
        const newStatus = study.status == 'active' ? 'paused' : 'active';

        console.log(newStatus);

        study.status = newStatus;
        this.setState({ study });

        this.updateStatusXhr(newStatus);
    }

    async getStudy() {
        const { toastManager } = this.props;
        const studyId = this.props.match.params.id;

        try {
            const study = await studyService.getStudy(studyId);
            this.setState({ study });
            cache.set(`study.${studyId}`, study);
        } catch (error) {
            const errorText = services.parseAndTrackXhrErrors(error);
            toastManager.add(errorText, {
                appearance: 'error',
                autoDismiss: true
            });
        }
    }

    componentDidMount() {
        this.getStudy();
        this.getCampaigns();
        this.getSmsCampaigns();
    }

    async getCampaigns() {
        const { toastManager } = this.props;

        try {
            const data = await services_studies.getEmailCampaigns(this.props.match.params.id);
            this.setState({
                campaigns: data,
                loading: false
            });
        } catch (error) {
            const errorText = services.parseAndTrackXhrErrors(error);
            toastManager.add(errorText, {
                appearance: 'error',
                autoDismiss: true
            });
        }
    }

    async getSmsCampaigns() {
        const { toastManager } = this.props;

        try {
            const data = await services_studies.getSmsCampaigns(this.props.match.params.id);
            this.setState({
                smsCampaigns: data,
                loading: false
            });
        } catch (error) {
            const errorText = services.parseAndTrackXhrErrors(error);
            toastManager.add(errorText, {
                appearance: 'error',
                autoDismiss: true
            });
        }
    }

    cancelCampaign(campaign_id) {
        if (!confirm('Are you sure you want to cancel this campaign?')) {
            return;
        }

        const { toastManager } = this.props;

        xhr.post(
            `/studies/${this.props.match.params.id}/campaigns/${campaign_id}/cancel?account_id=${this.props.auth.account.id}`,
            {},
            {
                withCredentials: true
            }
        )
            .then(res => {
                this.getCampaigns();

                toastManager.add('Successfully cancelled campaign', {
                    appearance: 'success',
                    autoDismiss: true
                });
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);

                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    getStats(campaign, status, denominatorTotal = false) {
        if (campaign && campaign.stats2) {
            const campaign_stats = campaign.stats2;

            if (campaign_stats.hasOwnProperty(status)) {
                const denominator = denominatorTotal ? campaign.recipients_to_count : campaign_stats[EMAIL_STATUS_SENT];
                const percent = parseFloat((campaign_stats[status] * 100) / denominator).toFixed(2);
                return (
                    <>
                        <span className="color-text-primary"> {helpers.numberFormat(campaign_stats[status])} </span>
                        <span className="fs-body-12 color-text-secondary"> {`(${percent}%)`} </span>
                    </>
                );
            } else {
                return '-';
            }
        } else {
            return '-';
        }
    }

    getSmsStats(campaign, status) {
        if (campaign && campaign.stats) {
            const campaign_stats = campaign.stats;

            if (campaign_stats.hasOwnProperty(status)) {
                const percent = parseFloat((campaign_stats[status] * 100) / campaign.recipients_to_count).toFixed(2);
                return (
                    <>
                        <span className="color-text-primary"> {helpers.numberFormat(campaign_stats[status])} </span>
                        <span className="fs-body-12 color-text-secondary"> {`(${percent}%)`} </span>
                    </>
                );
            } else {
                return '-';
            }
        } else {
            return '-';
        }
    }

    renderStatusLabel(status) {
        const statusName = helpers.capitalize(status);
        switch (status) {
            case EMAIL_CAMPAIGN_STATUS_SENT:
            case EMAIL_CAMPAIGN_STATUS_READY:
                return <StatusLabel status={statusName} color="green" />;
            case EMAIL_CAMPAIGN_STATUS_SCHEDULED:
                return <StatusLabel status={statusName} color="orange" />;
            case EMAIL_CAMPAIGN_STATUS_CANCELLED:
                return <StatusLabel status={statusName} color="gray" />;
            default:
                return <StatusLabel status={statusName} color="danger" />;
        }
    }

    renderCampaigns2() {
        const campaigns = [];
        if (!this.state.campaigns.length) {
            return <Box p={4}>0 Email campaigns sent</Box>;
        }

        this.state.campaigns.forEach((c, index) => {
            // skip "test emails" which were sent
            if (!c.test_email_address) {
                campaigns.push(
                    <ItemWrapper
                        style={{ color: 'black', padding: '16px 8px' }}
                        key={c.id}
                        className="show-on-hover-container"
                    >
                        <Flex width={1} alignItems="center" className="fs-body-14">
                            <Flex width={1} alignItems="center">
                                <Box width={3 / 7} pr="8px" style={{ position: 'relative' }}>
                                    <div
                                        style={{ maxWidth: '100%' }}
                                        className="ellipsis fs-title-14 color-text-primary table-link link inline-block"
                                        onClick={() => this.setState({ campaign: c })}
                                    >
                                        {c.subject}
                                    </div>
                                    <div className="ellipsis color-text-secondary fs-body-12">
                                        Sent by {c.user ? c.user.email : 'Unknown'}
                                    </div>
                                    <Button
                                        type="button"
                                        variant="secondary"
                                        className="secondary-tiny show-on-hover-element"
                                        sx={{
                                            position: 'absolute',
                                            right: 0,
                                            top: '50%',
                                            transform: 'translateY(-50%)'
                                        }}
                                        onClick={() => this.setState({ campaign: c })}
                                    >
                                        <FiSidebar /> View
                                    </Button>
                                </Box>
                                <Flex width={1 / 7} minWidth="150px" style={{ flexDirection: 'column', gap: '2px' }}>
                                    {this.renderStatusLabel(c.status)}
                                    {c.status === EMAIL_CAMPAIGN_STATUS_SCHEDULED && (
                                        <>
                                            {c.future_send_date && (
                                                <div className="fs-body-12 color-text-secondary">
                                                    {moment
                                                        .utc(c.future_send_date)
                                                        .local()
                                                        .format(MOMENT_HUMAN_DATE_NO_TZ_SHORT)}
                                                </div>
                                            )}
                                            <div
                                                className="link-danger fs-body-12"
                                                onClick={() => this.cancelCampaign(c.id)}
                                            >
                                                Cancel Campaign
                                            </div>
                                        </>
                                    )}
                                </Flex>
                                <Flex width={2 / 7} pr="8px" style={{ flexDirection: 'column', gap: '4px' }}>
                                    <div className="ellipsis color-text-secondary">{c.from_name}</div>
                                    <div className="ellipsis color-text-secondary">{c.from_email}</div>
                                </Flex>
                                <Box width={1 / 7} minWidth="120px" className="ellipsis color-text-secondary">
                                    {moment(c.created_at)
                                        .locale('short-relative')
                                        .fromNow()}
                                </Box>
                            </Flex>
                            <Box minWidth="80px">{helpers.numberFormat(c.recipients_to_count)}</Box>
                            <Box minWidth="110px">{this.getStats(c, EMAIL_STATUS_SENT, true)}</Box>
                            <Box minWidth="110px">{this.getStats(c, EMAIL_STATUS_OPEN)}</Box>
                            <Box minWidth="110px">{this.getStats(c, EMAIL_STATUS_BOUNCED)}</Box>
                            <Box minWidth="110px">{this.getStats(c, EMAIL_STATUS_COMPLAINT)}</Box>
                            <Box minWidth="110px">{this.getStats(c, EMAIL_STATUS_FAILED, true)}</Box>
                        </Flex>
                    </ItemWrapper>
                );
            }
        });

        return campaigns;
    }

    renderSmsCampaigns() {
        const campaigns = [];
        if (!this.state.smsCampaigns.length) {
            return <Box p={4}>0 SMS campaigns sent</Box>;
        }

        this.state.smsCampaigns.forEach((smsCampaign, index) => {
            const title = smsCampaign.message_raw.toString().substr(0, 20);

            campaigns.push(
                <ItemWrapper
                    className="show-on-hover-container"
                    style={{ color: 'black', padding: '16px 8px' }}
                    key={smsCampaign.id}
                >
                    <Flex width={1} alignItems="center" className="fs-body-14">
                        <Flex width={1} alignItems="center">
                            <Box width={3 / 7} pr="8px" style={{ position: 'relative' }}>
                                <div
                                    style={{ maxWidth: '100%' }}
                                    className="ellipsis fs-title-14 color-text-primary table-link link inline-block"
                                    onClick={() => this.setState({ campaign: smsCampaign })}
                                >
                                    {title}
                                </div>
                                <div className="ellipsis color-text-secondary fs-body-12">
                                    Sent by {smsCampaign.user ? smsCampaign.user.email : 'Unknown'}
                                </div>
                                <Button
                                    type="button"
                                    variant="secondary"
                                    className="secondary-tiny show-on-hover-element"
                                    sx={{
                                        position: 'absolute',
                                        right: 0,
                                        top: '50%',
                                        transform: 'translateY(-50%)'
                                    }}
                                    onClick={() => this.setState({ campaign: smsCampaign })}
                                >
                                    <FiSidebar /> View
                                </Button>
                            </Box>
                            <Flex width={2 / 7} minWidth="150px" style={{ flexDirection: 'column', gap: '2px' }}>
                                {this.renderStatusLabel(smsCampaign.status)}
                            </Flex>
                            <Box width={2 / 7} minWidth="120px" className="ellipsis color-text-secondary">
                                {moment(smsCampaign.created_at)
                                    .locale('short-relative')
                                    .fromNow()}
                            </Box>
                        </Flex>
                        <Box minWidth="80px">{helpers.numberFormat(smsCampaign.recipients_to_count)}</Box>
                        <Box minWidth="110px">{this.getSmsStats(smsCampaign, 'sent')}</Box>
                        <Box minWidth="110px">{this.getSmsStats(smsCampaign, 'failed')}</Box>
                    </Flex>
                </ItemWrapper>
            );
        });

        return campaigns;
    }

    render() {
        return (
            <Flex style={{ width: '100%' }}>
                <AppPageWrapper>
                    <Helmet>
                        <title>{this.state.study.title} - Emails</title>
                    </Helmet>
                    <AppPageWrapperSectionHeader className="experience experience-no-subnav">
                        <Box>
                            <BreadcrumbBack to="/studies">Back to all studies</BreadcrumbBack>
                            <BreadcrumbTitle isLoading={this.state.isStudyLoading} title={this.state.study.title} />
                        </Box>
                    </AppPageWrapperSectionHeader>
                    <Flex>
                        <StudyPageSubNav study_id={this.props.match.params.id} study={this.state.study} />
                    </Flex>

                    <AppPageWrapperSectionHeader className="experience">
                        <Box mt={3}>
                            <H1>Email & SMS Reports</H1>
                            <Box className="experience-description">Evaluate your email performance.</Box>
                        </Box>
                    </AppPageWrapperSectionHeader>

                    <AppPageWrapperSectionBody>
                        {this.state.loading ? (
                            <div style={{ width: '100%' }}>
                                <LoadingWrapper>
                                    <LoadingIndicator />
                                </LoadingWrapper>
                            </div>
                        ) : (
                            <Box mx={4} my={4} sx={{ position: 'relative', width: '100%', overflow: 'auto' }}>
                                <Box mb={3} className="text-primary fs-headline-20">
                                    Email Campaigns
                                </Box>
                                <ListWrapper
                                    style={{
                                        position: 'relative',
                                        borderRadius: '4px',
                                        minWidth: '1200px'
                                    }}
                                >
                                    <ItemWrapper style={{ padding: '16px 8px' }} className="header-row">
                                        <Flex width={1}>
                                            <Flex width={1}>
                                                <Box width={3 / 7}>Subject</Box>
                                                <Box width={1 / 7} minWidth="150px">
                                                    Status
                                                </Box>
                                                <Box width={2 / 7}>From</Box>
                                                <Box width={1 / 7} minWidth="120px">
                                                    Created
                                                </Box>
                                            </Flex>
                                            <Box minWidth="80px">Total</Box>
                                            <Box minWidth="110px">Sent</Box>
                                            <Box minWidth="110px">Opened</Box>
                                            <Box minWidth="110px">Bounced</Box>
                                            <Box minWidth="110px">Spam</Box>
                                            <Box minWidth="110px">Failed</Box>
                                        </Flex>
                                    </ItemWrapper>

                                    {this.renderCampaigns2()}
                                </ListWrapper>

                                <Box mt={3} mb={3} className="text-primary fs-headline-20">
                                    SMS Campaigns
                                </Box>
                                <ListWrapper
                                    style={{
                                        position: 'relative',
                                        borderRadius: '4px',
                                        minWidth: '1200px'
                                    }}
                                >
                                    <ItemWrapper style={{ padding: '16px 8px' }} className="header-row">
                                        <Flex width={1}>
                                            <Flex width={1}>
                                                <Box width={3 / 7}>Subject</Box>
                                                <Box width={2 / 7} minWidth="150px">
                                                    Status
                                                </Box>
                                                <Box width={2 / 7} minWidth="120px">
                                                    Created
                                                </Box>
                                            </Flex>
                                            <Box minWidth="80px">Total</Box>
                                            <Box minWidth="110px">Sent</Box>
                                            <Box minWidth="110px">Failed</Box>
                                        </Flex>
                                    </ItemWrapper>

                                    {this.renderSmsCampaigns()}
                                </ListWrapper>
                            </Box>
                        )}
                    </AppPageWrapperSectionBody>
                </AppPageWrapper>
                <CampaignsModal
                    isOpen={!!this.state.campaign}
                    campaign={this.state.campaign}
                    onClose={() => this.setState({ campaign: null })}
                />
            </Flex>
        );
    }
}

export default withToastManager(StudyCampaignsPage);
