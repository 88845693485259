import React from 'react';
import { Box } from 'rebass';

import helpers from 'utils/helpers';
import Progress from 'components/Progress';

/**
 * Data bar component. Displays a data with a progress bar and percentage.
 *
 * @param {Object} param0
 * @param {number} param0.total Total number
 * @param {number} param0.value This number will be used to calculate the percentage.
 * @param {string} param0.title Title of the value
 */
export function DataBar({ total, value, title }) {
    const percentage = helpers.getPercentage(value, total, 2);

    return (
        <Box
            className="report-log grid items-end"
            style={{ gap: '8px', gridTemplateColumns: '1fr minmax(60px, auto)' }}
            py="8px"
            minHeight="62px"
        >
            <Box>
                <Box as="p" className="fs-accent-14 color-text-secondary ellipsis">
                    {title}
                </Box>
                <Progress style={{ margin: '6px 0' }} value={percentage} />
            </Box>
            <Box className="flex items-end flex-col">
                <Box as="span" mb="2px" className="fs-body-12 nowrap color-text-secondary data-bar-actual-number">
                    ({helpers.numberFormat(value)})
                </Box>
                <Box as="p" className="fs-accent-14 text-primary nowrap">
                    {percentage}%
                </Box>
            </Box>
        </Box>
    );
}

export default DataBar;
