import React from 'react';
import { withToastManager } from 'react-toast-notifications';

import { Box, Flex } from 'rebass';
import NiceModal from 'components/NiceModal';

import services from 'services/services';
import auth from 'services/auth';
import helpers from 'utils/helpers';

import LoginPageHeader from 'containers/LoginPage/components/LoginPageHeader';
import SocialAuthButtons from 'containers/LoginPage/components/SocialAuthButtons';
import LoginPageOrDivider from 'containers/LoginPage/components/LoginPageOrDivider';
import LoginPageButton from 'containers/LoginPage/components/LoginPageButton';
import LoginPageLink from 'containers/LoginPage/components/LoginPageLink';
import TermsOfServiceCheckbox from 'containers/LoginPage/components/TermsOfServiceCheckbox';
import LoginPageInput from 'containers/LoginPage/components/LoginPageInput';
import RegionSwitch from 'components/RegionSwitch';

class SignUpPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            name: '',
            email: '',
            password: '',
            signUpWithEmail: false,
            showSocialAuthModal: false,
            socialAuthModalProvider: '',
            loadingEmailAuth: false,
            termsOfServiceAccepted: false,
            showTermsOfServiceError: false
        };

        this.termsOfServiceNotAccepted = this.termsOfServiceNotAccepted.bind(this);
        this.termsOfServiceCheckboxOnClick = this.termsOfServiceCheckboxOnClick.bind(this);
        this.modalClose = this.modalClose.bind(this);
        this.modalAccept = this.modalAccept.bind(this);
        this.signUpWithEmailCancel = this.signUpWithEmailCancel.bind(this);
        this.createViaEmail = this.createViaEmail.bind(this);
    }

    termsOfServiceNotAccepted() {
        // Feature added on 02-22-2023
        if (this.state.termsOfServiceAccepted) return false;
        this.setState({ showTermsOfServiceError: true });
        return true;
    }

    termsOfServiceCheckboxOnClick(accepted) {
        this.setState({
            termsOfServiceAccepted: accepted,
            showTermsOfServiceError: false
        });
    }

    modalClose() {
        this.setState({
            showSocialAuthModal: false,
            termsOfServiceAccepted: false,
            showTermsOfServiceError: false
        });
    }

    modalAccept() {
        if (this.termsOfServiceNotAccepted()) return;

        if (this.state.socialAuthModalProvider === 'google') {
            this.props.googleAuth();
        } else if (this.state.socialAuthModalProvider === 'microsoft') {
            this.props.microsoftAuth();
        }
    }

    signUpWithEmailCancel() {
        this.setState({
            signUpWithEmail: false,
            termsOfServiceAccepted: false,
            showTermsOfServiceError: false
        });
    }

    createViaEmail() {
        if (this.termsOfServiceNotAccepted()) return;
        const { toastManager } = this.props;
        this.setState({ loadingEmailAuth: true });

        // timezone
        const browser_timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;

        auth.createAccountEmail(this.state.email, this.state.password, this.state.name, browser_timezone)
            .then(response => {
                helpers.googleTagManagerEventSignup();

                this.props.dispatchAuthEvent();
                this.setState({ loadingEmailAuth: false });
            })
            .catch(error => {
                this.setState({ loadingEmailAuth: false });
                const errorText = services.parseAndTrackXhrErrors(error);

                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    render() {
        return (
            <Flex flexDirection="column">
                {!this.state.signUpWithEmail ? (
                    <>
                        <LoginPageHeader>Welcome to Panelfox</LoginPageHeader>
                        <RegionSwitch to="/signup" />
                        <Box as="hr" my="24px" sx={{ borderColor: '#D4D9DD' }} />
                        <SocialAuthButtons
                            isSignUpPage
                            googleOnClick={() =>
                                this.setState({ showSocialAuthModal: true, socialAuthModalProvider: 'google' })
                            }
                            microsoftOnClick={() =>
                                this.setState({ showSocialAuthModal: true, socialAuthModalProvider: 'microsoft' })
                            }
                        />
                        <LoginPageOrDivider />
                        <LoginPageButton
                            variant="primary"
                            mb="24px"
                            onClick={() => this.setState({ signUpWithEmail: true })}
                        >
                            Sign Up with Email
                        </LoginPageButton>
                        <LoginPageLink to="/login">I have an account already</LoginPageLink>
                    </>
                ) : (
                    <>
                        <LoginPageHeader>Sign up with Email</LoginPageHeader>
                        <form
                            onSubmit={e => {
                                e.preventDefault();
                                this.createViaEmail();
                            }}
                        >
                            <LoginPageInput
                                label="Your full name"
                                type="text"
                                name="name"
                                required
                                placeholder="Cameron Williamson"
                                value={this.state.name}
                                onChange={e => this.setState({ name: e.target.value })}
                            />
                            <LoginPageInput
                                label="Your company email"
                                type="email"
                                name="email"
                                required
                                placeholder="example@mail.domain"
                                value={this.state.email}
                                onChange={e => this.setState({ email: e.target.value })}
                            />
                            <LoginPageInput
                                label="Create password"
                                type="password"
                                name="password"
                                required
                                placeholder="12 symbols at least"
                                value={this.state.password}
                                onChange={e => this.setState({ password: e.target.value })}
                            />
                            <TermsOfServiceCheckbox
                                accepted={this.state.termsOfServiceAccepted}
                                showError={this.state.showTermsOfServiceError}
                                checkboxOnClick={this.termsOfServiceCheckboxOnClick}
                            />
                            <LoginPageButton
                                type="submit"
                                variant="primary"
                                mb="24px"
                                disabled={this.state.loadingEmailAuth}
                            >
                                {this.state.loadingEmailAuth ? 'Signing up...' : 'Sign up'}
                            </LoginPageButton>
                        </form>
                        <LoginPageLink onClick={this.signUpWithEmailCancel}>Cancel</LoginPageLink>
                    </>
                )}
                <NiceModal
                    isOpen={this.state.showSocialAuthModal}
                    shouldCloseOnOverlayClick
                    onRequestClose={this.modalClose}
                    title="Before we continue"
                    style={{ content: { width: '360px' } }}
                >
                    <TermsOfServiceCheckbox
                        accepted={this.state.termsOfServiceAccepted}
                        showError={this.state.showTermsOfServiceError}
                        checkboxOnClick={this.termsOfServiceCheckboxOnClick}
                    />
                    <Flex sx={{ gap: '16px' }}>
                        <LoginPageButton variant="secondary-gray" small onClick={this.modalClose}>
                            Decline
                        </LoginPageButton>
                        <LoginPageButton variant="primary" small onClick={this.modalAccept}>
                            Accept
                        </LoginPageButton>
                    </Flex>
                </NiceModal>
            </Flex>
        );
    }
}

export default withToastManager(SignUpPage);
