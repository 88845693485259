import React from 'react';
import NiceWrapper from 'components/NiceWrapper';
import { Redirect } from 'react-router-dom';
import services from 'services/services';
import { Flex, Box, Heading, Button } from 'rebass';

function BetaPage(props) {
    function resend() {
        services
            .resendVerificationEmail()
            .then(response => {
                alert('Successfully re-sent verification email');
            })
            .catch(e => {
                alert('Error sending verification email');
            });
    }

    // If user is granted access, redirect them
    if (props.auth.user.allow_access == 1 && props.auth.user.email_verified_at) {
        return <Redirect to="/studies" />;
    }
    return (
        <div style={{ margin: '32px auto', textAlign: 'center' }}>
            <NiceWrapper style={{ background: 'white', color: 'black', padding: '32px' }}>
                <h1>Verification email sent</h1>
                <div>
                    Please check your inbox and follow the instructions.
                    <br />
                    <a href="#" onClick={resend}>
                        Resend
                    </a>{' '}
                    verification email.
                </div>
                <br />
                <div className="text-secondary">
                    If you have any questions please email <a href="mailto:support@panelfox.io">support@panelfox.io</a>.
                </div>
            </NiceWrapper>
        </div>
    );
}

export default BetaPage;
