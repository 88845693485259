import React from 'react';
import { Box, Flex } from 'rebass';
import { FiEye } from 'react-icons/fi';
import styled from 'styled-components';

const PreviewIndicator = styled(Flex)`
    flex-direction: column;
    justify-content: center;
    text-align: center;
    gap: 4px;
    width: 100%;
    max-width: 800px;
    padding: 12px 24px;
    border-radius: 0 0 8px 8px;
    margin: 0 auto !important;
`;

const PublicScreenPagePreviewIndicator = props => (
    <PreviewIndicator className="color-bg-primary-light no-print">
        <Box className="fs-title-14 color-primary-dark no-print">
            <FiEye style={{ margin: '0 8px 2px 0', fontSize: '18px' }} />
            Preview mode
        </Box>
        <Box className="fs-body-12 color-text-secondary no-print">
            This is a preview, so your submission will not be saved. Do not share this link with participants.
        </Box>
    </PreviewIndicator>
);

export default PublicScreenPagePreviewIndicator;
