import React, { useState, useRef } from 'react';
import { Box, Button } from 'rebass';
import { FiMoreVertical, FiRefreshCw } from 'react-icons/fi';

import { Card, CardBody, CardFooter, CardHeader } from 'components/Card';
import { InformationTooltip } from 'components/InformationTooltip';
import helpers from 'utils/helpers';
import moment from 'utils/moment';
import Portal from 'components/Portal';
import NiceDropdown from 'components/NiceDropdown';
import Positioner from 'components/Positioner';

/**
 * Dashboard statistic
 *
 * @param {Object} props Props
 * @param {string} props.title Title of the statistic
 * @param {number} props.value Value of the statistic
 * @param {string} props.extraInfo Extra info to be displayed in a tooltip
 * @param {Date|string} props.updatedAt Last update date of the statistic
 * @param {() => void|Promise<void>} props.onRefresh Refresh handler
 */
export function Statistic({ title, value, extraInfo, updatedAt, isSearchResult, onRefresh }) {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const controllerRef = useRef(null);

    return (
        <Card className="relative">
            {!isSearchResult && (
                <Button
                    className="absolute"
                    style={{ right: '12px', top: '12px' }}
                    variant="transparent-icon"
                    ref={controllerRef}
                    onClick={() => setIsDropdownOpen(true)}
                >
                    <FiMoreVertical />
                </Button>
            )}
            <CardHeader>
                <Box
                    as="p"
                    sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                    className="color-text-secondary fs-accent-14"
                >
                    {title}
                </Box>
                <InformationTooltip id={`${title.split(' ').join('-')}-tooltip`}>{extraInfo}</InformationTooltip>
                <Box flexGrow={1} />
                <Portal>
                    <Positioner controllerRef={controllerRef} isVisible={isDropdownOpen}>
                        <NiceDropdown
                            style={{ inset: 'auto', position: 'static' }}
                            width="120px"
                            onClose={() => setIsDropdownOpen(false)}
                            items={[
                                {
                                    title: 'Re-calculate',
                                    icon: <FiRefreshCw />,
                                    onClick: onRefresh
                                }
                            ]}
                        />
                    </Positioner>
                </Portal>
            </CardHeader>
            <CardBody as="p" sx={{ fontSize: '36px', lineHeight: '40px' }} className="bold text-primary">
                {helpers.numberWithCommas(value)}
            </CardBody>
            <CardFooter>
                {!isSearchResult && (
                    <Box as="p" className="color-text-secondary fs-body-12">
                        Last calculated: {moment(updatedAt).fromNow()}
                    </Box>
                )}
            </CardFooter>
        </Card>
    );
}

export default Statistic;
