import React from 'react';
import { withRouter } from 'react-router-dom';
import { Redirect } from 'react-router';
import { Button, Heading, Box } from 'rebass';
import { Label, Input, Textarea } from '@rebass/forms';
import xhr from 'xhr.js';
import services from 'services/services';
import studies_service from 'services/studies';
import { withToastManager } from 'react-toast-notifications';
import NiceSelect from 'components/NiceSelect';
class NewStudy extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            study: {
                title: '',
                description: ''
            },
            submitting: false,
            redirect: null
        };
        this.onFormChange = this.onFormChange.bind(this);
        this.onFormSubmit = this.onFormSubmit.bind(this);
    }

    onFormSubmit(e) {
        const { toastManager } = this.props;
        //console.log('submittting');
        e.preventDefault();

        //console.log(e);
        const copyStudyId = document.getElementById('new-form-copy-study-id').value;

        const study = { ...this.state.study };
        if (copyStudyId && copyStudyId > 0) {
            this.setState({ submitting: true });
            studies_service
                .duplicateStudy(copyStudyId, this.state.study.title, this.state.study.description)
                .then(study_response => {
                    toastManager.add('Created', {
                        appearance: 'success',
                        autoDismiss: true
                    });

                    console.log(study_response);

                    this.props.history.push(`/studies/${study_response.id}`);
                })
                .catch(error => {
                    const errorText = services.parseAndTrackXhrErrors(error);
                    toastManager.add(errorText, {
                        appearance: 'error',
                        autoDismiss: true
                    });
                    this.setState({ submitting: false });
                });
        } else {
            this.saveStudyXHR(study);
        }
    }

    onFormChange(e) {
        if (!e.target.name) {
            return;
        }
        const study = { ...this.state.study };
        study[e.target.name] = e.target.value;
        //console.log(e.target.name);
        this.setState({ study });
    }

    saveStudyXHR(study) {
        console.log('SAVED!!!', study);

        const { toastManager } = this.props;

        this.setState({ submitting: true });

        xhr.post(`/studies?account_id=${this.props.auth.account.id}`, study, { withCredentials: true })
            .then(response => {
                console.log(response);
                toastManager.add('New study created', {
                    appearance: 'success',
                    autoDismiss: true
                });
                const study_xhr = response.data;
                this.setState({
                    submitting: false,
                    redirect: `/studies/${study_xhr.id}`
                });
            })
            .catch(error => {
                this.setState({ submitting: false });
                const errorText = services.parseAndTrackXhrErrors(error);
                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    render() {
        if (this.state.redirect) {
            return <Redirect to={this.state.redirect} />;
        }

        return (
            <form onSubmit={this.onFormSubmit}>
                <Label htmlFor="title">Title</Label>

                <Input
                    id="new-form-study__title"
                    type="text"
                    name="title"
                    placeholder="New Qual Study"
                    value={this.state.study.title}
                    onChange={this.onFormChange}
                    autoComplete="off"
                    required
                    autoFocus
                />

                <Label mt={3} htmlFor="description">
                    Description
                </Label>

                <Textarea
                    id="new-form-study__description"
                    type="text"
                    name="description"
                    placeholder="Recruit 20 for 2 focus groups"
                    value={this.state.study.description}
                    onChange={this.onFormChange}
                />

                <Label mt={3} htmlFor="description">
                    Copy Study
                </Label>

                <input type="hidden" id="new-form-copy-study-id" />
                <NiceSelect
                    showSearch
                    revealEllipsis={true}
                    mimicSelectStyle={true}
                    defaultValue={0}
                    items={this.props.items}
                    mapValueToLabel={this.props.mapValueToLabel}
                    onChange={this.props.handleChange}
                />

                <Box className="modal-actions">
                    <Button variant="secondary-gray" type="button" mr={3} onClick={this.props.onClose}>
                        Cancel
                    </Button>
                    <Button
                        mr={0}
                        type="submit"
                        variant="primary"
                        className="modal-primary"
                        disabled={this.state.submitting}
                    >
                        Create study
                    </Button>
                </Box>
            </form>
        );
    }
}

export default withRouter(withToastManager(NewStudy));
