import styled from 'styled-components';
import React from 'react';
import { Box } from 'rebass';

/*const MarketingPageSection = styled(Box).attrs(props => ({
        textAlign: props.textAlign || 'left',
    }))`
    text-align: ${props => props.textAlign};
    maxWidth: 1200,
    mx: 'auto'
`;*/

const MarketingPageSection = props => (
    <Box
        {...props}
        sx={{
            width: '100%',
            mx: 'auto',
            padding: '0 24px'
        }}
    />
);

export default MarketingPageSection;
