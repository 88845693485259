/**
 * Documentation
 *
 * `useQuery` is a custom hook tailored to simplify the process of making asynchronous
 * queries, along with handling their respective loading and error states. This hook
 * enables one to perform a query by utilizing a provided query function and offers
 * a consistent interface for accessing the loading state, data, and any errors
 * that might occur during the process.
 *
 * The main advantage of this hook lies in its flexibility. You can control when the
 * query is skipped, pass dynamic variables, and also hook into successful or erroneous
 * executions of the query via callback functions.
 *
 * The hook internally manages the loading state and automatically tracks errors, reducing
 * the boilerplate code required to implement such patterns manually.
 *
 * @example
 * const myApiFunction = async (variables) => {
 *   const response = await fetch(`https://api.example.com/user/${variables.userId}`);
 *   if (!response.ok) throw new Error('Failed to fetch data');
 *   return response.json();
 * };
 *
 * const MyComponent = () => {
 *   const { isLoading, data, error, refetch } = useQuery({
 *     variables: { userId: 123 },
 *     skip: false,
 *     queryFn: myApiFunction
 *   });
 *
 *   if (isLoading) return <LoadingComponent />;
 *   if (error) return <ErrorComponent error={error} />;
 *
 *   return <DataComponent data={data} />;
 * }
 *
 * Note: Ensure that `myApiFunction` is a function that takes the variables object
 * as its argument and returns a promise that resolves with the data.
 */

import React, { useEffect, useState, useMemo } from 'react';
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';

import services from 'services/services';

/**
 * Custom hook to perform a query and handle loading and error states
 *
 * @param {Object} props
 * @param {Object} props.variables variables to pass to the query
 * @param {boolean} props.skip whether to skip the query
 * @param {Function} props.onSuccess callback to be called on success
 * @param {(error: Error, errorText: string) => Promis<void> | void} props.onError callback to be called on error
 * @param {Function} props.queryFn function to call to perform the query
 * @param {any} props.defaultData default data to be used before the query is resolved
 * @returns fetched data, error, loading state and refetch function
 */
export const useQuery = ({ variables, skip, onSuccess, onError, queryFn, defaultData = null }) => {
    const memorizedVariables = useMemo(() => JSON.stringify(variables), [variables]);
    const [isLoading, setIsLoading] = useState(!defaultData);
    const [error, setError] = useState(null);
    const [data, setData] = useState(defaultData);
    const [backup, setBackup] = useState(null);
    const [called, setCalled] = useState(false);

    /**
     * This function is used to update the data optimistically before the query is resolved
     * It is useful when you want to update the UI immediately and then update it again
     * when the query is resolved. If the query fails, the optimistic update is reverted.
     */
    const optimisticUpdate = updateFn => {
        const optimisticData = updateFn(cloneDeep(data));
        setData(optimisticData);
        return optimisticData;
    };

    const query = async () => {
        setIsLoading(true);

        try {
            const responseData = await queryFn(variables);
            setData(responseData);
            setBackup(responseData); // backup data for optimistic updates

            onSuccess && (await onSuccess(responseData));
        } catch (error) {
            const errorText = services.parseAndTrackXhrErrors(error) || error.message;
            setError(errorText);
            onError && (await onError(error, errorText));

            // check if backup data is different from the current data, if yes,
            // it means that the optimistic update was used and we need to revert it
            if (!isEqual(data, backup)) {
                setData(cloneDeep(backup));
            }
        } finally {
            setCalled(true);
        }

        setIsLoading(false);
    };

    useEffect(() => {
        if (skip) return;
        query();
    }, [memorizedVariables, skip]);

    return { isLoading, error, data, refetch: query, called, optimisticUpdate };
};

export default useQuery;
