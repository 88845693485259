import React, { useMemo, useState } from 'react';
import { Box, Button } from 'rebass';
import { Label, Input, Select } from '@rebass/forms';
import { Link } from 'react-router-dom';
import moment from 'moment';

import helpers from 'utils/helpers';
import Portal from 'components/Portal';
import NiceModal from 'components/NiceModal';
import useForm from 'hooks/useForm';
import { INCENTIVE_PAYMENT_METHOD, INCENTIVE_PAYMENT_METHOD_LABEL, CURRENCY_SYMBOL } from 'utils/constants';

/**
 * Modal for making payments. Support for First Party, Tremendous, and Mark as Paid.
 *
 * @param {Object} props
 * @param {boolean} props.isOpen Modal open state
 * @param {() => void} props.onClose Close modal callback
 * @param {string} props.title Modal title
 * @param {(data: { amount: number, paymentType: string, date: string }) => Promise<void>} props.onPay Payment callback
 * @param {Object} props.auth Auth object
 * @param {Object} props.study Study object
 * @param {Array?} props.payees People (Person) to be paid
 * @param {{ amount?: number, paymentType?: string, paymentDate?: string }} props.defaultValues Default form values
 */
export function PaymentModal({ isOpen, onClose, title, onPay, auth, study, payees, defaultValues }) {
    const { register, handleSubmit, watch } = useForm({
        initialForm: {
            amount: defaultValues.amount || 0,
            paymentType: defaultValues.paymentType || '',
            paymentDate: moment(defaultValues.paymentDate).format('YYYY-MM-DDTHH:mm')
        }
    });
    const firstPartyEnabled = useMemo(() => helpers.hasFirstPartyIncentivesEnabled(auth), [auth]);
    const tremendousEnabled = useMemo(() => helpers.hasTremendousIntegration(auth), [auth]);
    const [isProcessing, setIsProcessing] = useState(false);
    const paymentType = watch('paymentType');
    const amount = watch('amount');
    const isDisabled = useMemo(() => {
        // if type or amount is empty
        if (!paymentType || !amount || amount <= 0) return true;

        // if Tremendous is selected but the study doesn't have a campaign ID
        if (paymentType === INCENTIVE_PAYMENT_METHOD.TREMENDOUS && !study.tremendous_campaign_id) return true;

        return false;
    }, [paymentType, amount, study]);

    const onSubmit = handleSubmit(async data => {
        setIsProcessing(true);
        await onPay(data);
        setIsProcessing(false);
    });

    return (
        <Portal>
            <NiceModal
                isOpen={isOpen}
                shouldCloseOnOverlayClick
                onRequestClose={onClose}
                style={{ content: { width: '420px' } }}
                title={title}
            >
                <form onSubmit={onSubmit}>
                    <Box>
                        {payees && (
                            <Box mb={3}>
                                <Label>To be paid ({payees.length} total)</Label>
                                <Box>
                                    {payees.map(payee => (
                                        <Box key={payee.id} as="p">
                                            {helpers.personGetFullName(payee)}
                                        </Box>
                                    ))}
                                </Box>
                            </Box>
                        )}
                        <Label>Payment method</Label>
                        <Select {...register('paymentType')} required>
                            <option value=""></option>
                            {firstPartyEnabled && (
                                <option value={INCENTIVE_PAYMENT_METHOD.FIRST_PARTY}>
                                    {INCENTIVE_PAYMENT_METHOD_LABEL.FIRST_PARTY}
                                </option>
                            )}
                            {tremendousEnabled && (
                                <option value={INCENTIVE_PAYMENT_METHOD.TREMENDOUS}>
                                    {INCENTIVE_PAYMENT_METHOD_LABEL.TREMENDOUS}
                                </option>
                            )}
                            <option value={INCENTIVE_PAYMENT_METHOD.MARK_AS_PAID}>
                                {INCENTIVE_PAYMENT_METHOD_LABEL.MARK_AS_PAID}
                            </option>
                        </Select>
                        {tremendousEnabled &&
                            paymentType === INCENTIVE_PAYMENT_METHOD.TREMENDOUS &&
                            !study.tremendous_campaign_id && (
                                <Box mt={3} p={3} className="error border">
                                    Missing Tremendous campaign - please update in{' '}
                                    <Link to={`/studies/${study.id}/settings`}>Study Settings</Link>.
                                </Box>
                            )}
                        <Label mt={3}>
                            Amount ({CURRENCY_SYMBOL[study.incentive_currency]} {study.incentive_currency})
                        </Label>
                        <Input
                            {...register('amount', { formatter: parseInt })}
                            type="number"
                            step="1"
                            min="0"
                            placeholder="e.g. 50"
                            required
                        />
                        {paymentType === INCENTIVE_PAYMENT_METHOD.MARK_AS_PAID && (
                            <>
                                <Label mt={3}>Payment date</Label>
                                <Input
                                    {...register('paymentDate')}
                                    type="datetime-local"
                                    required
                                    style={{ width: '100%' }}
                                />
                            </>
                        )}
                    </Box>
                    <Box className="modal-actions">
                        <Button type="button" variant="secondary-gray" mr={3} onClick={onClose}>
                            Cancel
                        </Button>
                        <Button
                            type="submit"
                            variant="primary"
                            sx={{ marginRight: 0 }}
                            disabled={isProcessing || isDisabled}
                        >
                            {isProcessing
                                ? 'Processing...'
                                : [INCENTIVE_PAYMENT_METHOD.TREMENDOUS, INCENTIVE_PAYMENT_METHOD.FIRST_PARTY].includes(
                                      paymentType
                                  )
                                ? `Confirm, pay ${CURRENCY_SYMBOL[study.incentive_currency]}${(
                                      amount * payees.length
                                  ).toFixed(0)}`
                                : INCENTIVE_PAYMENT_METHOD_LABEL.MARK_AS_PAID_BUTTON}
                        </Button>
                    </Box>
                </form>
            </NiceModal>
        </Portal>
    );
}

export default PaymentModal;
