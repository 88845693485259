import React from 'react';

export default function(ComposedComponent, auth) {
    // eslint-disable-next-line react/prefer-stateless-function
    class Raw extends React.Component {
        // Otherwise render the original component
        render() {
            return <ComposedComponent {...this.props} auth={auth} />;
        }
    }

    return Raw;
}
