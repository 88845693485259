import React, { useCallback, useEffect, useMemo, useRef, useState } from 'react';
import NiceModal from 'components/NiceModal';
import { Box, Button, Flex } from 'rebass';
import { Select, Label } from '@rebass/forms';
import { countryListData } from 'utils/constants';
import { CountryList } from './CountryList';
import helpers from 'utils/helpers';

const allowCountryMenu = [
    { label: 'Allow all countries', value: 'all' },
    { label: 'Specific countries', value: 'specific' }
];
export const AllowedCountries = ({ isOpen, onSave, onRequestClose, selectedCountries = [] }) => {
    const [allowCountryState, setAllowCountryState] = useState('');
    const [selectedCountryList, setSelectedCountryList] = useState([]);
    useEffect(() => {
        const list = [];
        selectedCountries &&
            selectedCountries.map(countryValue => {
                const filtered = countryListData.filter(item => item.value === countryValue);
                list.push(filtered[0]);
            });
        setSelectedCountryList(list);
        setAllowCountryState(selectedCountries.length === 0 ? allowCountryMenu[0].value : allowCountryMenu[1].value);
    }, []);

    const countryListRef = useRef('');
    const handleAllowAll = useCallback(status => {
        setAllowCountryState(status);
        setSelectedCountryList([]);
    }, []);
    const showCountryList = useMemo(() => {
        if (allowCountryState === allowCountryMenu[0].value) return false;
        else if (!selectedCountries && selectedCountries.length === 0) return false;
        return true;
    }, [allowCountryState, selectedCountries]);

    const handleAdd = () => {
        if (countryListRef.current.value) {
            const selected = countryListData.filter(item => item.value === countryListRef.current.value);
            if (selectedCountryList.includes(selected[0])) return;
            let combined_arr = [...selectedCountryList, selected[0]];
            //console.log('combined_arr',combined_arr)
            helpers.sortAlpha(combined_arr, 'label');
            setSelectedCountryList(combined_arr);
            countryListRef.current.value = '';
        }
    };
    const handleRemoveCountry = country => {
        const filteredList = selectedCountryList.filter(item => item.value !== country);
        setSelectedCountryList(filteredList);
    };
    const handleSave = () => {
        if (showCountryList === true && selectedCountryList.length === 0) {
            alert('Please select more than one country');
            return;
        }
        const list = selectedCountryList.length === 0 ? [] : selectedCountryList.map(({ value }) => value);
        onSave(list);
        onRequestClose();
    };
    return (
        <NiceModal
            isOpen={isOpen}
            shouldCloseOnOverlayClick
            onRequestClose={onRequestClose}
            title="Respondent country restrictions"
        >
            <form
                onSubmit={e => {
                    e.preventDefault();
                    handleSave();
                }}
            >
                <Box mt={1} mb={3}>
                    <Label>Allow All or Specific</Label>
                    <Select
                        // style={{ width: '100% !important', display: 'block' }}
                        onChange={e => handleAllowAll(e.target.value)}
                        value={allowCountryState}
                    >
                        {allowCountryMenu.map(option => (
                            <option key={option.value} value={option.value}>
                                {option.label}
                            </option>
                        ))}
                    </Select>
                </Box>
                {showCountryList && (
                    <>
                        <Label>Select a country to add</Label>

                        <Select ref={countryListRef} onChange={handleAdd}>
                            <option value=""></option>
                            {countryListData.map(option => (
                                <option key={option.value} value={option.value}>
                                    {option.label}
                                </option>
                            ))}
                        </Select>

                        {/*<Button
                            fontSize={1}
                            variant="secondary"
                            type="button"
                            ml={3}
                            onClick={handleAdd}
                            flexShrink={0}
                            mr={0}
                        >
                            + Add
                        </Button>*/}

                        <Box mt={3}>
                            <Label>Allowed Countries</Label>
                            {selectedCountryList && selectedCountryList.length ? (
                                <CountryList countryList={selectedCountryList} handleRemove={handleRemoveCountry} />
                            ) : (
                                <>0 countries selected</>
                            )}
                        </Box>
                    </>
                )}
                <Box className="modal-actions">
                    <Button type="button" variant="secondary-gray" mr={3} onClick={onRequestClose}>
                        Cancel
                    </Button>

                    <Button type="submit" variant="primary" mr={0}>
                        Save
                    </Button>
                </Box>
            </form>
        </NiceModal>
    );
};
