import React, { useState, useEffect } from 'react';
import ReactModal from 'react-modal';
import { Flex, Box, Button } from 'rebass';
import { Input } from '@rebass/forms';
import { FiInfo } from 'react-icons/fi';

import H1 from 'components/H1';
import LoadingIndicator from 'components/LoadingIndicator';
import LoadingWrapper from 'components/LoadingIndicator/LoadingWrapper';
import NiceModal from '../NiceModal';

function SelectModal(props) {
    const [keyword, setKeyword] = useState('');
    const [list, setList] = useState([]);

    useEffect(() => {
        generateList();
    }, [keyword, props.items]);

    function generateList() {
        const tempList = [];

        // generate a list of buttons filtered by the keyword
        props.items.forEach(item => {
            if (!keyword || (keyword && item.title.toLowerCase().includes(keyword.toLowerCase()))) {
                let mainButton = (
                    <Button
                        key={item.id}
                        variant="transparent"
                        sx={{
                            flexGrow: 1,
                            textAlign: 'left'
                            //display: 'block',
                            //width: '100%',
                        }}
                        onClick={() => {
                            props.onSelect(item.id);
                        }}
                    >
                        {item.title}
                    </Button>
                );

                let removeButton;
                if (props.allowClickRemove) {
                    removeButton = (
                        <Button
                            className="select-modal-item-remove"
                            key={item.id + `-remove`}
                            variant="transparent"
                            onClick={() => {
                                if (props.onRemove) {
                                    props.onRemove(item.id);
                                }
                            }}
                        >
                            {props.allowClickRemove}
                        </Button>
                    );
                }

                tempList.push(
                    <Flex
                        key={item.id}
                        className="select-modal-item"
                        sx={{ width: '100%', justifyContent: 'space-between' }}
                    >
                        {mainButton}
                        {removeButton}
                    </Flex>
                );
            }
        });

        setList(tempList);
    }

    return (
        <NiceModal
            isOpen={props.isOpen}
            shouldCloseOnOverlayClick
            onRequestClose={props.onRequestClose}
            title={props.title ? props.title : `Select ${props.type}`}
        >
            <Box>
                {props.isLoading ? (
                    <LoadingWrapper style={{ height: '200px' }}>
                        <LoadingIndicator />
                    </LoadingWrapper>
                ) : (
                    <Box>
                        {props.subtitle}
                        <Box mb={3}>
                            <Input
                                marginTop={'1px'}
                                autoFocus
                                className="selectModal_keyword"
                                placeholder={
                                    props.onCreate ? `Type to search or create a ${props.type}` : `Type to search`
                                }
                                value={keyword}
                                onChange={e => {
                                    setKeyword(e.target.value);
                                }}
                                onKeyDown={e => {
                                    if (e.key === 'Enter') {
                                        if (list.length === 0 && keyword.length > 0) {
                                            props.onCreate(keyword);
                                            setKeyword('');
                                        }
                                    }
                                }}
                            />
                        </Box>

                        <Box fontSize={1}>
                            {list.length === 0 && keyword.length === 0 && props.onCreate ? (
                                // TODO: consider making this a component?
                                <div
                                    style={{
                                        textAlign: 'center',
                                        color: '#666',
                                        marginTop: '32px'
                                    }}
                                >
                                    <FiInfo style={{ margin: '4px 4px 0 0', verticalAlign: 'top' }} />{' '}
                                    {`Begin typing to create a ${props.type}`}
                                </div>
                            ) : (
                                list
                            )}
                        </Box>
                        {list.length === 0 &&
                            (props.onCreate && keyword.length > 0 ? (
                                <Box>
                                    <Button
                                        variant="primary"
                                        sx={{
                                            display: 'block',
                                            width: '100%',
                                            textAlign: 'left'
                                        }}
                                        onClick={() => {
                                            props.onCreate(keyword);
                                        }}
                                    >
                                        {props.icon ? props.icon : ''}
                                        {`Create new ${props.type}`}: &ldquo;
                                        {keyword}&rdquo;
                                    </Button>
                                </Box>
                            ) : (
                                <Box
                                    fontSize={1}
                                    className="gray medium"
                                    mt={4}
                                    sx={{ width: '100%', textAlign: 'center' }}
                                >
                                    0 results
                                </Box>
                            ))}
                    </Box>
                )}
            </Box>
        </NiceModal>
    );
}

export default SelectModal;
