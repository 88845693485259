import React, { useState, useEffect } from 'react';
import { Box, Button } from 'rebass';
import { Input, Label } from '@rebass/forms';
import NiceModal from 'components/NiceModal';
import CreatePanel from 'components/Participant/CreatePanel';
import PanelSelect from 'components/PanelSelect';
import services from 'services/services';
import service_people from 'services/people';
import { useToasts } from 'react-toast-notifications';
import helpers from 'utils/helpers';

export default function CreateParticipant(props) {
    const { addToast } = useToasts();
    const [panelIdSelected, setPanelIdSelected] = useState(null);
    const [showModalCreatePanel, setShowModalCreatePanel] = useState(false);

    useEffect(() => {
        if (props.defaultPanelIdSelected) {
            setPanelIdSelected(props.defaultPanelIdSelected);
        } else if (props.panels && props.panels.length) {
            setPanelIdSelected(props.panels[0].id);
        }
    }, [props.defaultPanelIdSelected, props.panels]);

    function getPanelsXHR(selectId) {
        service_people
            .getPanels()
            .then(panels_response => {
                props.onUpdatePanels(panels_response);
                if (selectId) {
                    setPanelIdSelected(selectId);
                }
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);
                addToast(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    function onCreatePanel(panel) {
        addToast('Successfully created a new panel', {
            appearance: 'success',
            autoDismiss: true
        });
        getPanelsXHR(panel.id);
    }

    function onSubmit(e) {
        e.preventDefault();

        const first_name_values = document.getElementsByName('create_person_first_name');
        const last_name_values = document.getElementsByName('create_person_last_name');
        const email_values = document.getElementsByName('create_person_email');

        const person = {
            first_name: first_name_values[0].value,
            last_name: last_name_values[0].value,
            email: email_values[0].value,
            panel_id: panelIdSelected
        };

        if (props.study_id) {
            services
                .addPersonToStudy(props.study_id, person)
                .then(study_person_xhr => {
                    props.onClose();
                    props.onCreate(study_person_xhr);
                })
                .catch(error => {
                    const errorText = services.parseAndTrackXhrErrors(error);
                    addToast(errorText, {
                        appearance: 'error',
                        autoDismiss: true
                    });
                });
        } else {
            services
                .createPerson(person)
                .then(person_xhr => {
                    props.onClose();
                    props.onCreate(person_xhr);
                })
                .catch(error => {
                    const errorText = services.parseAndTrackXhrErrors(error);
                    addToast(errorText, {
                        appearance: 'error',
                        autoDismiss: true
                    });
                });
        }
    }

    return (
        <>
            <form onSubmit={onSubmit}>
                <Box>
                    <Box mb={3}>
                        <Label>Panel</Label>
                        <PanelSelect
                            width="100%"
                            items={props.panels}
                            value={panelIdSelected}
                            onChange={panel_id => setPanelIdSelected(panel_id)}
                            onCreatePanel={() => setShowModalCreatePanel(true)}
                        />
                    </Box>
                    <Label>First Name</Label>
                    <Input type="text" name="create_person_first_name" placeholder="John" autoComplete="off" required />
                    <Label mt={3}>Last Name</Label>
                    <Input type="text" name="create_person_last_name" placeholder="Smith" autoComplete="off" required />
                    <Label mt={3}>Email</Label>
                    <Input
                        type="text"
                        name="create_person_email"
                        placeholder="jsmith@gmail.com"
                        autoComplete="off"
                        required
                    />
                </Box>
                <Box className="modal-actions">
                    <Button variant="secondary-gray" type="button" mr={3} onClick={props.onClose}>
                        Cancel
                    </Button>
                    <Button type="submit" variant="primary" mr={0} className="modal-primary">
                        Create panelist
                    </Button>
                </Box>
            </form>
            <NiceModal
                isOpen={showModalCreatePanel}
                shouldCloseOnOverlayClick
                onRequestClose={() => setShowModalCreatePanel(false)}
                title={`Create new panel`}
            >
                <CreatePanel onCreate={onCreatePanel} onClose={() => setShowModalCreatePanel(false)} />
            </NiceModal>
        </>
    );
}
