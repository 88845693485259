export default {
    overlay: {
        position: 'fixed',
        top: 0,
        left: 0,
        right: 0,
        bottom: 0,
        backgroundColor: 'rgba(0, 18, 51, 0.6)',
        padding: '40px 0'
    },
    content: {
        display: 'flex',
        flexDirection: 'column',
        position: 'absolute',
        background: '#fff',
        overflow: 'scroll',
        WebkitOverflowScrolling: 'touch',
        borderRadius: '8px',
        outline: 'none',
        width: '480px',
        maxHeight: 'calc(100vh - 80px)',
        top: '50%',
        bottom: 'none',
        left: '50%',
        transform: 'translateY(-50%) translateX(-50%)',
        maxWidth: '100%',
        margin: '0 auto',
        padding: '0',
        border: 0,
        boxShadow: 'rgba(36, 18, 77, 0.08) 0px 0px 0px 1px, rgba(36, 18, 77, 0.32) 0px 16px 24px -8px'
    }
};
