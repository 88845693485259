import styled from 'styled-components';

const H2 = styled.h2`
    font-size: 16px;
    margin-bottom: 6px;
    margin-top: 0;
    font-weight: 500;
    line-height: 20px;
    height: 24px;
    color: #001233;

    svg {
        vertical-align: top;
        margin: 2px 4px 0 0;
    }
`;

export default H2;
