import React from 'react';
import { Flex, Box } from 'rebass';
import moment from 'moment-timezone';
import { FiClock, FiLayers } from 'react-icons/fi';

/**
 * This component displays the strip with the attached file
 *
 * @param {Object} props
 * @param {Object} props.extra extra content
 * @param {String} props.fileExtension file extension
 * @param {Boolean} props.isLoading is loading
 * @param {String} props.title title
 * @param {String} props.description description
 * @param {String} props.updatedAt updated at
 * @param {String} props.className class name
 * @param {Object} props.study study object
 * @param {String} props.study.title study title
 * @param {String} props.study.id study id
 */
export function AttachedFileStrip({
    extra,
    fileExtension,
    isLoading,
    title,
    description,
    updatedAt,
    className,
    study
}) {
    return (
        <Flex
            className={className}
            marginBottom="24px"
            paddingBottom="16px"
            sx={{
                borderBottom: '1px solid #D4D9DD',
                ...(isLoading ? { opacity: 0.6, pointerEvents: 'none', cursor: 'not-allowed' } : {})
            }}
        >
            <Flex
                sx={{
                    borderRadius: '4px',
                    width: '56px',
                    height: '56px',
                    minHeight: '56px',
                    minWidth: '56px',
                    alignItems: 'center',
                    justifyContent: 'center',
                    marginRight: '12px'
                }}
                backgroundColor="#F6F6F6"
            >
                <Box as="span" fontWeight={500} color="#000">
                    .{fileExtension}
                </Box>
            </Flex>
            <Box flexGrow={1}>
                <Box as="p" color="#000" fontWeight={500} lineHeight="20px" marginBottom="2px">
                    {title}
                </Box>
                <Box as="p" fontSize="12px" lineHeight="16px" marginBottom="2px">
                    {description ? description : 'No description'}
                </Box>
                <Flex
                    as="p"
                    className="text-primary"
                    fontSize="12px"
                    lineHeight="16px"
                    alignItems="center"
                    sx={{ gap: '8px' }}
                >
                    <FiClock fontSize="12px" />
                    <Box as="span">{moment.utc(updatedAt).format('MMM D [at] hh:mm A')}</Box>
                    {study && study.title && (
                        <>
                            <Box as="span" className="vertical-line" p="0">
                                |
                            </Box>
                            <FiLayers fontSize="12px" />
                            <Box as="span">{study.title}</Box>
                        </>
                    )}
                </Flex>
            </Box>
            <Flex sx={{ gap: '8px', alignItems: 'center', minWidth: 'max-content' }}>{extra}</Flex>
        </Flex>
    );
}

export default AttachedFileStrip;
