import React from 'react';
import { Box, Button } from 'rebass';
import { Input, Label } from '@rebass/forms';

import NiceModal from 'components/NiceModal';

export const PromptContext = React.createContext({
    /**
     *
     * @param {string} question
     * @param {Object} config
     * @param {string} config._default default value
     * @param {Function} config.onConfirm callback when user confirms
     * @param {Function} config.onCancel callback when user cancels
     * @param {string} config.title modal title
     * @param {string} config.confirmText confirm button text
     * @param {string} config.cancelText cancel button text
     */
    ask: (question, config) => {}
});

/**
 * PromptProvider, provides a context for prompting the user with a modal
 *
 * @param {Object} props
 * @param {React.ReactNode} props.children children
 */
export function PromptProvider({ children }) {
    const [response, setResponse] = React.useState(null);
    const [prompt, setPrompt] = React.useState(null);

    /**
     * Ask the user a question
     *
     * @param {string} question
     * @param {Object} config
     * @param {string} config._default default value
     * @param {Function} config.onConfirm callback when user confirms
     * @param {Function} config.onCancel callback when user cancels
     * @param {string} config.title modal title
     * @param {string} config.confirmText confirm button text
     * @param {string} config.cancelText cancel button text
     */
    const ask = (
        question,
        {
            _default = null,
            onConfirm = value => {},
            onCancel = () => {},
            title = '',
            confirmText = 'ok',
            cancelText = 'cancel'
        }
    ) => {
        setResponse(_default);
        setPrompt({
            question,
            onConfirm,
            onCancel,
            title,
            confirmText,
            cancelText
        });
    };

    /**
     * Cancel the prompt
     */
    const cancel = () => {
        // call onCancel callback
        prompt.onCancel();

        // reset prompt
        setPrompt(null);
        setResponse(null);
    };

    /**
     * Confirm the prompt
     */
    const confirm = () => {
        // call onConfirm callback
        prompt.onConfirm(response);

        // reset prompt
        setPrompt(null);
        setResponse(null);
    };

    return (
        <>
            <PromptContext.Provider
                value={{
                    ask
                }}
            >
                {children}
            </PromptContext.Provider>
            {prompt && (
                <NiceModal shouldCloseOnOverlayClick isOpen title={prompt.title} onRequestClose={cancel}>
                    <Label htmlFor="prompt">{prompt.question}</Label>
                    <Input id="prompt" value={response} onChange={e => setResponse(e.target.value)} autoFocus={1} />
                    <Box className="modal-actions">
                        <Button type="button" variant="secondary-gray" mr={3} onClick={cancel}>
                            {prompt.cancelText}
                        </Button>
                        <Button type="submit" variant="primary" sx={{ marginRight: 0 }} onClick={confirm}>
                            {prompt.confirmText}
                        </Button>
                    </Box>
                </NiceModal>
            )}
        </>
    );
}

/**
 * withPrompt, provides a prompt prop to the wrapped component
 *
 * @param {React.ReactNode} Component
 */
export const withPrompt = Component => props => (
    <PromptContext.Consumer>{context => <Component {...props} prompt={context} />}</PromptContext.Consumer>
);

/**
 * usePrompt, provides a prompt context
 */
export const usePrompt = () => {
    const context = React.useContext(PromptContext);

    if (context === undefined) {
        throw new Error('usePrompt must be used within a PromptProvider');
    }

    return context;
};

export default {
    PromptProvider,
    withPrompt,
    PromptContext,
    usePrompt
};
