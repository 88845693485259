import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Flex, Button } from 'rebass';
import { Label, Select } from '@rebass/forms';
import moment from 'moment-timezone';
import services from 'services/services';
import helpers from 'utils/helpers';
import helpersStudy from 'utils/helpers-study';
import LoadingIndicator from 'components/LoadingIndicator';
import LoadingWrapper from 'components/LoadingIndicator/LoadingWrapper';
import { Editor } from 'draft-js';
import { FiClock, FiExternalLink } from 'react-icons/fi';
import { CURRENCY_SYMBOL } from 'utils/constants';
import { CollapsibleContentContainer } from 'components/CollapsibleContent';
import { withToastManager } from 'react-toast-notifications';
import { FiPaperclip, FiChevronRight } from 'react-icons/fi';

import { StatusIndicator } from 'components/StatusIndicator';
import { StudyFiles } from './StudyFiles';

class PastParticipation extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            person_id: this.props.person_id,
            person: this.props.person,
            loading: this.props.fetch_person == true ? true : false,
            participation_all: [],
            participation_currentXhr: [],
            current_page: 1,
            participationType: 'scheduled'
        };

        this.onClickLoadMore = this.onClickLoadMore.bind(this);
        this.onChangeParticipationType = this.onChangeParticipationType.bind(this);
    }

    componentDidMount() {
        if (this.props.fetch_person == true) {
            //this.getPersonXHR();
            this.getPersonParticipationXHR(this.state.current_page, this.state.participationType);
        }
    }

    getPersonXHR() {
        console.log('fetching person!');
        const { toastManager } = this.props;

        services
            .getPerson(this.props.person_id)
            .then(personResponse => {
                this.setState({
                    person: personResponse,
                    loading: false
                });
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);

                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }
    getPersonParticipationXHR(page, participationType) {
        console.log('fetching getPersonParticipationXHR!');
        const { toastManager } = this.props;

        if (!this.props.person_id) {
            console.log('person id not sent!!!');
            return;
        }

        services
            .getPersonParticipation(this.props.person_id, page, participationType)
            .then(personParticipationResponse => {
                console.log(personParticipationResponse.data);
                let { participation_all } = this.state;
                participation_all = participation_all.concat(personParticipationResponse.data);
                console.log(participation_all);
                this.setState({
                    participation_all,
                    loading: false,
                    participation_currentXhr: personParticipationResponse,
                    current_page: personParticipationResponse.current_page
                });
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);

                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    onChangeParticipationType(e) {
        this.setState(
            {
                participationType: e.target.value,
                current_page: 1,
                participation_all: [],
                loading: true
            },
            () => {
                this.getPersonParticipationXHR(this.state.current_page, this.state.participationType);
            }
        );
    }

    onClickLoadMore() {
        let { current_page } = this.state;
        const new_page = current_page + 1;
        this.getPersonParticipationXHR(new_page, this.state.participationType);
    }

    renderComponent() {
        const which_studies = [];

        which_studies.push(
            <Flex sx={{ gridGap: 2 }} mb={3}>
                <Box className="text-secondary">Type:</Box>
                <Select className="select-simple" onChange={e => this.onChangeParticipationType(e)}>
                    <option value="scheduled">Scheduled Only</option>
                    <option value="all">All Studies</option>
                </Select>
            </Flex>
        );

        if (this.state.loading) {
            which_studies.push(
                <div style={{ width: '100%' }}>
                    <LoadingWrapper>
                        <LoadingIndicator />
                    </LoadingWrapper>
                    <br />
                </div>
            );

            return which_studies;
        }

        if (this.state.participation_all.length) {
            this.state.participation_all.forEach(studyPerson => {
                // check if paid
                let paidAmount = 0;
                if (studyPerson.incentive_status == 'paid') {
                    paidAmount = helpers.renderCurrencyAmount(
                        studyPerson.incentive_currency,
                        studyPerson.incentive_total_paid
                    );
                }
                //console.log(studyPerson.study, studyPerson.id, studyPerson)
                which_studies.push(
                    <CollapsibleContentContainer
                        show={0}
                        end={
                            <>
                                <Link
                                    to={`/studies/${studyPerson.study.id}/participants/${studyPerson.id}`}
                                    target="_blank"
                                    className="va-top muted-light"
                                    style={{ margin: '-1px 0 0 0' }}
                                    onClick={e => e.stopPropagation()}
                                >
                                    <FiExternalLink />
                                </Link>
                            </>
                        }
                        title={
                            <>
                                <Flex>
                                    <Box className="muted bold" mr={2}>
                                        {studyPerson.study.title}
                                    </Box>
                                </Flex>
                            </>
                        }
                        subtitle={
                            <Box fontSize={1}>
                                <Flex sx={{ marginTop: '4px', gridGap: 2 }} alignItems="center">
                                    {studyPerson.status > 0 ? (
                                        <StatusIndicator
                                            color={helpersStudy.translateStudyPersonStatusToColor(studyPerson.status)}
                                        >
                                            {helpersStudy.getHumanReadableSPStatus(studyPerson.status)}
                                        </StatusIndicator>
                                    ) : (
                                        <Box className="bold fs-12 text-secondary va-top" sx={{ margin: '2px 0 0 0' }}>
                                            Status is not set
                                        </Box>
                                    )}

                                    {studyPerson.spot ? (
                                        <Box>
                                            <span className={'vertical-line'}>|</span>
                                            <span className={'fs-12 text-primary bold'}>
                                                <FiClock
                                                    style={{
                                                        verticalAlign: 'top',
                                                        margin: '6px 6px 0 0',
                                                        strokeWidth: '3px'
                                                    }}
                                                />
                                                {moment
                                                    .utc(studyPerson.spot)
                                                    .tz(studyPerson.timezone)
                                                    .format('MMM Do, YYYY [at] h:mma')}
                                            </span>
                                        </Box>
                                    ) : (
                                        ''
                                    )}

                                    {paidAmount ? (
                                        <Box>
                                            <span className={'vertical-line'}>|</span>
                                            <span className="green-dark bold fs-12">Paid {paidAmount}</span>
                                        </Box>
                                    ) : (
                                        ''
                                    )}
                                </Flex>
                            </Box>
                        }
                    >
                        <Box key={studyPerson.study.id} justifyContent="space-between">
                            <Box className="text-secondary-dark fs-12" mt={'16px'}>
                                <Box mt={1}>Added to study</Box>
                                {studyPerson.created_at && (
                                    <Box mt={1}>
                                        Added on{' '}
                                        {moment
                                            .utc(studyPerson.created_at)
                                            .local()
                                            .format('MMM Do, YYYY')}
                                    </Box>
                                )}
                                {studyPerson.emails_sent.map(es => {
                                    return (
                                        <Box mt={1}>
                                            Email sent on{' '}
                                            {moment
                                                .utc(es.created_at)
                                                .local()
                                                .format('MMM Do, YYYY')}
                                        </Box>
                                    );
                                })}
                                {studyPerson.screener_answers.map(sa => {
                                    return (
                                        <Box mt={1}>
                                            Screener answered on{' '}
                                            {moment
                                                .utc(sa.created_at)
                                                .local()
                                                .format('MMM Do, YYYY')}
                                        </Box>
                                    );
                                })}
                                {studyPerson.notes && (
                                    <Box mt={'24px'}>
                                        <Box className="blockquote-notes">
                                            <Box className={'fs-12 bold text-primary'}>Notes</Box>
                                            <Box sx={{ marginTop: '-8px' }}>
                                                <Editor
                                                    editorState={helpers.getEditorStateFromNotes(studyPerson.notes)}
                                                    readOnly={true}
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                )}
                                <Box
                                    sx={{
                                        marginTop: '16px',
                                        paddingTop: '16px',
                                        borderTop: '1px solid #E7E8F1'
                                    }}
                                >
                                    <Box fontSize="14px" mb="16px" display="flex" alignItems="center">
                                        <FiPaperclip css={{ width: '16px', height: '16px', marginRight: '8px' }} />
                                        <Box as="span" color="#000" fontWeight="600" lineHeight="20px">
                                            Files
                                        </Box>
                                    </Box>
                                    <StudyFiles personId={this.state.person_id} attachedFiles={studyPerson.files} />
                                    <Link
                                        target="_blank"
                                        to={`/studies/${studyPerson.study.id}/participants/${studyPerson.id}`}
                                    >
                                        <Button padding="0px 16px" height="40px" variant="secondary-gray">
                                            Manage study files <FiChevronRight />
                                        </Button>
                                    </Link>
                                </Box>
                            </Box>
                        </Box>
                    </CollapsibleContentContainer>
                );
            });

            if (
                this.state.participation_currentXhr &&
                this.state.participation_currentXhr.last_page > this.state.participation_currentXhr.current_page
            ) {
                which_studies.push(
                    <Button type="button" variant="secondary-gray" onClick={this.onClickLoadMore}>
                        Load More
                    </Button>
                );
            }
        } else {
            which_studies.push(<Box fontSize={1}>No results.</Box>);
        }

        return which_studies;
    }

    render() {
        try {
            return this.renderComponent();
        } catch (e) {
            helpers.trackError(e);
            return <>Error rendering component</>;
        }
    }
}

export default withToastManager(PastParticipation);
