import React from 'react';
import {
    FiEyeOff,
    FiUploadCloud,
    FiPenTool,
    FiGitCommit,
    FiType,
    FiCheckSquare,
    FiList,
    FiAlignLeft,
    FiCalendar,
    FiHash,
    FiStar,
    FiTag,
    FiChevronDown,
    FiGrid,
    FiVideo,
    FiUserX
} from 'react-icons/fi';
import { BiRadioCircleMarked } from 'react-icons/bi';
import { OPTION_ORDER_OFF } from '../../../utils/constants';

export default [
    {
        key: 'Header',
        name: 'Page Title',
        icon: <FiAlignLeft />,
        static: true,
        content: ''
    },
    /*{
    key: 'Label',
    name: 'Label',
    static: true,
    icon: 'fas fa-font',
    content: 'Placeholder Text...',
  },*/
    {
        key: 'Paragraph',
        name: 'Page Paragraph',
        static: true,
        icon: <FiAlignLeft />,
        content: ''
    },
    /*{
    key: 'LineBreak',
    name: 'Line Break',
    static: true,
    icon: 'fas fa-arrows-alt-h',
  },
  {
    key: 'Tags',
    canHaveAnswer: true,
    name: 'Tags',
    icon: <FiTag />,
    label: 'Placeholder Label',
    field_name: 'tags_',
    options: [],
  },*/
    {
        key: 'Checkboxes',
        canHaveAnswer: true,
        name: 'Checkboxes',
        icon: <FiCheckSquare />,
        label: '',
        field_name: 'checkboxes_',
        options: [],
        num_columns: 1,
        option_order: OPTION_ORDER_OFF,
        qual_disqual: true
    },
    {
        key: 'RadioButtons',
        canHaveAnswer: true,
        name: 'Single Select',
        icon: <BiRadioCircleMarked />,
        label: '',
        field_name: 'radiobuttons_',
        options: [],
        num_columns: 1,
        option_order: OPTION_ORDER_OFF,
        qual_disqual: true
    },

    {
        key: 'Dropdown',
        canHaveAnswer: true,
        name: 'Dropdown',
        icon: <FiChevronDown />,
        label: '',
        field_name: 'dropdown_',
        options: [],
        option_order: OPTION_ORDER_OFF,
        qual_disqual: true
    },
    {
        key: 'Matrix',
        canHaveAnswer: true,
        name: 'Matrix',
        icon: <FiGrid />,
        label: '',
        field_name: 'matrix_',
        options: [],
        option_order: OPTION_ORDER_OFF
    },
    {
        key: 'TextInput',
        canHaveAnswer: true,
        name: 'Short Answer',
        label: '',
        icon: <FiType />,
        field_name: 'text_input_'
    },
    {
        key: 'TextArea',
        canHaveAnswer: true,
        name: 'Long Answer',
        label: '',
        icon: <FiType />,
        field_name: 'text_area_'
    },
    {
        key: 'NumberInput',
        canHaveAnswer: true,
        name: 'Number Input',
        label: '',
        icon: <FiHash />,
        field_name: 'number_input_'
    },

    {
        key: 'HiddenValue',
        canHaveAnswer: true,
        name: 'Hidden Value',
        label: '',
        icon: <FiEyeOff />,
        field_name: 'hidden_value_',
        default_value: ''
    },
    /*{
    key: 'Image',
    name: 'Image',
    label: '',
    icon: 'far fa-image',
    field_name: 'image_',
    src: '',
  },*/
    {
        key: 'Rating',
        canHaveAnswer: true,
        name: 'Rating',
        label: '',
        icon: <FiStar />,
        field_name: 'rating_'
    },

    {
        key: 'RangeElement',
        name: 'Range',
        label: '',
        field_name: 'range_',
        step: 1,
        default_value: 3,
        min_value: 1,
        max_value: 5,
        min_label: 'Strongly Disagree',
        max_label: 'Strongly Agree',
        icon: <FiGitCommit />
    },
    {
        key: 'DatePicker',
        canDefaultToday: true,
        canReadOnly: true,
        dateFormat: 'MM/dd/yyyy',
        timeFormat: 'hh:mm aa',
        showTimeSelect: false,
        showTimeSelectOnly: false,
        name: 'Date',
        icon: <FiCalendar />,
        label: '',
        field_name: 'date_picker_'
    },
    {
        key: 'AddPipeInput',
        canHaveAnswer: true,
        name: 'Video Recording',
        label: '',
        icon: <FiVideo />,
        field_name: 'addpipe_input_',
        video_recording_time: 120 // default recording time is 2 minutes
    },
    {
        key: 'Signature',
        canReadOnly: true,
        name: 'Signature',
        icon: <FiPenTool />,
        label: 'Signature',
        field_name: 'signature_'
    },
    {
        key: 'FileUpload',
        canHaveAnswer: true,
        name: 'File Upload',
        icon: <FiUploadCloud />,
        label: '',
        field_name: 'fileupload_',
        upload_files_cnt: 1 //default upload files count is 1 file
    },
    {
        key: 'Captcha',
        canHaveAnswer: true,
        name: 'Captcha',
        icon: <FiUserX />,
        label: 'Captcha',
        field_name: 'captcha_',
        options: [
            {
                text: 'Captcha OK',
                key: '1'
            },
            {
                text: 'Captcha Failed',
                key: '0'
            }
        ]
    } /*
  {
    key: 'Camera',
    name: 'Camera',
    icon: 'fas fa-camera',
    label: 'Placeholder Label',
    field_name: 'camera_',
  },*/

    /*{
    key: 'HyperLink',
    name: 'Web site',
    icon: 'fas fa-link',
    static: true,
    content: 'Placeholder Web site link ...',
    href: 'http://www.example.com',
  },
  {
    key: 'Download',
    name: 'File Attachment',
    icon: 'fas fa-file',
    static: true,
    content: 'Placeholder file name ...',
    field_name: 'download_',
    file_path: '',
    _href: '',
  },*/
];
