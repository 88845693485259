/**
 * <HeaderBar />
 */

import React from 'react';
import { FiEdit, FiXCircle } from 'react-icons/fi';
import { Button } from 'rebass';

export default class HeaderBar extends React.Component {
    render() {
        const hasRequiredLabel =
            this.props.data.hasOwnProperty('required') && this.props.data.required === true && !this.props.read_only;

        const missingMappedPanelColumn =
            this.props.data.element != 'Header' &&
            this.props.data.element != 'Paragraph' &&
            (!this.props.data.hasOwnProperty('personCustomDataId') || !this.props.data.personCustomDataId);

        return (
            <div>
                {missingMappedPanelColumn && (
                    <div className="screener-data-error">
                        Missing Data: Select how this should map to your Panel data
                    </div>
                )}
                <div className="toolbar-header">
                    <div className="toolbar-header-type" style={{ fontSize: '14px' }}>
                        <span style={{ fontWeight: 600 }}>{this.props.data.text}</span>
                        {hasRequiredLabel && <span style={{ marginLeft: '8px' }}>(Required)</span>}
                    </div>
                    <div className="toolbar-header-buttons">
                        {this.props.data.element !== 'LineBreak' && (
                            <Button
                                variant="transparent-icon"
                                onClick={this.props.editModeOn.bind(this.props.parent, this.props.data)}
                            >
                                <FiEdit />
                            </Button>
                        )}
                        {this.props.onDestroy && (
                            <Button
                                variant="transparent-icon"
                                onClick={this.props.onDestroy.bind(this, this.props.data)}
                            >
                                <FiXCircle />
                            </Button>
                        )}
                    </div>
                </div>
            </div>
        );
    }
}
