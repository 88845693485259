import styled from 'styled-components';

const StyledParticipantItem = styled.div`
    border-bottom: 1px solid #eee;
    padding: 12px 0;
    background: #fff;
    &:hover {
        background: #fdfdfd;
    }
    &:hover input[type='checkbox'] {
        display: block;
    }
    input[type='checkbox'] {
        display: none;
        &:checked {
            display: block;
        }
    }
    .sticky-row-style {
        background: #fff;
    }
    &:hover .sticky-row-style {
        background: #fdfdfd;
    }
`;

export default StyledParticipantItem;
