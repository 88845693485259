import React from 'react';
import { withToastManager } from 'react-toast-notifications';

import { Flex, Box } from 'rebass';

import services from 'services/services';
import auth from 'services/auth';

import LoginPageHeader from 'containers/LoginPage/components/LoginPageHeader';
import SocialAuthButtons from 'containers/LoginPage/components/SocialAuthButtons';
import LoginPageOrDivider from 'containers/LoginPage/components/LoginPageOrDivider';
import LoginPageButton from 'containers/LoginPage/components/LoginPageButton';
import LoginPageLink from 'containers/LoginPage/components/LoginPageLink';
import LoginPageInput from 'containers/LoginPage/components/LoginPageInput';
import RegionSwitch from 'components/RegionSwitch';

class SignInPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            email: '',
            password: '',
            two_fa_code: null,
            loadingEmailAuth: false
        };

        this.loginViaEmail = this.loginViaEmail.bind(this);
    }

    loginViaEmail() {
        const { toastManager } = this.props;
        const that = this;
        this.setState({ loadingEmailAuth: true });

        auth.loginAccountEmail(this.state.email, this.state.password, this.state.two_fa_code)
            .then(response => {
                if (response && response.sent_two_fa) {
                    const two_fa_code = prompt('Please enter the 2FA code we sent to your phone via SMS:');
                    if (two_fa_code) {
                        this.setState({ two_fa_code }, () => {
                            that.loginViaEmail();
                        });
                    } else {
                        this.setState({ loadingEmailAuth: false, two_fa_code: null });
                    }
                } else {
                    this.props.dispatchAuthEvent();
                    this.setState({ loadingEmailAuth: false, two_fa_code: null });
                }
            })
            .catch(error => {
                this.setState({ loadingEmailAuth: false, two_fa_code: null });
                const errorText = services.parseAndTrackXhrErrors(error);

                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    render() {
        return (
            <Flex flexDirection="column">
                <LoginPageHeader>Welcome back</LoginPageHeader>
                <RegionSwitch to="/login" />
                <Box as="hr" my="24px" sx={{ borderColor: '#D4D9DD' }} />
                <SocialAuthButtons
                    isSignUpPage={false}
                    googleOnClick={this.props.googleAuth}
                    microsoftOnClick={this.props.microsoftAuth}
                />
                <LoginPageOrDivider />
                <form
                    onSubmit={e => {
                        e.preventDefault();
                        this.loginViaEmail();
                    }}
                >
                    <LoginPageInput
                        label="Email"
                        type="email"
                        name="email"
                        required
                        placeholder="example@mail.domain"
                        value={this.state.email}
                        onChange={e => this.setState({ email: e.target.value })}
                    />
                    <LoginPageInput
                        label="Password"
                        linkTo="/forgot-password"
                        linkLabel="Forgot password?"
                        type="password"
                        name="password"
                        required
                        placeholder="12 symbols at least"
                        value={this.state.password}
                        onChange={e => this.setState({ password: e.target.value })}
                        mb="32px"
                    />
                    <LoginPageButton type="submit" variant="primary" mb="24px" disabled={this.state.loadingEmailAuth}>
                        {this.state.loadingEmailAuth ? 'Logging In...' : 'Log In with Email'}
                    </LoginPageButton>
                </form>

                <LoginPageLink to="/signup">I don't have an account yet</LoginPageLink>
            </Flex>
        );
    }
}

export default withToastManager(SignInPage);
