import React, { useState } from 'react';
import { Switch } from '@rebass/forms';
import { Box } from 'rebass';

import services from 'services/services';
import studyService from 'services/studies';

/**
 * A switch component that toggles a user's free-busy status in a study.
 *
 * @param {Object} props
 * @param {string} props.userId - The ID of the user
 * @param {boolean} props.isFreeBusy - The current free-busy status of the user
 * @param {string} props.studyId - The ID of the study
 * @param {Function} props.onUpdate - A callback function to be called after the free-busy status is updated
 * @param {Object} props.toastManager - A toast manager for showing error messages
 * @param {boolean} props.isConnected - Whether the user is connected to the external calendar
 */
export function FreeBusySwitch({ userId, isFreeBusy, studyId, onUpdate, toastManager, isConnected }) {
    const [isLoading, setIsLoading] = useState(false);

    const onFreeBusyChange = async () => {
        setIsLoading(true);

        try {
            // Update user freebusy
            await studyService.studyUpdateUser({ studyId, userId, data: { freebusy: !isFreeBusy } });

            // Fire update callback, probably to update the study
            if (onUpdate && typeof onUpdate === 'function') await onUpdate();
        } catch (error) {
            const errorText = services.parseAndTrackXhrErrors(error);
            toastManager.add(errorText, {
                appearance: 'error',
                autoDismiss: true
            });
        }

        setIsLoading(false);
    };

    return (
        <>
            <Switch
                mr="10px"
                disabled={!isConnected || isLoading}
                className="switch-small"
                checked={isConnected && isFreeBusy}
                onClick={onFreeBusyChange}
            />
            <Box>{!isConnected ? 'Not connected' : isFreeBusy ? 'Respect meetings' : 'Ignore meetings'}</Box>
        </>
    );
}

export default FreeBusySwitch;
