import React, { useMemo } from 'react';
import { Flex } from 'rebass';
import { FiMousePointer, FiCode } from 'react-icons/fi';
import classNames from 'classnames';

/**
 * Display read-only label for email template type
 *
 * @param {Object} props - props
 * @param {1 | 2 | 0} props.type - email template type (EMAIL_TEMPLATE_TYPE)
 * @param {string} props.className - className
 */
export function EmailTemplateTypeLabel({ type, className }) {
    // I've tried to use external variable instead of useMemo, but it didn't work and threw error
    const content = useMemo(() => {
        console.log('type', type);

        if (type === 1) {
            return {
                icon: <FiMousePointer css={{ fontSize: '16px' }} />,
                label: 'WYSIWYG View'
            };
        }

        if (type === 2) {
            return {
                icon: <FiCode css={{ fontSize: '16px' }} />,
                label: 'HTML View'
            };
        }

        return null;
    }, [type]);

    // if type is not equal to 1 or 2, return null
    if (!content) return null;

    return (
        <Flex
            className={classNames('items-center justify-center rounded fs-accent-14 bg-pure text-pure', className)}
            p="6px 16px"
            sx={{
                gap: '6px'
            }}
        >
            {content.icon}
            {content.label}
        </Flex>
    );
}

export default EmailTemplateTypeLabel;
