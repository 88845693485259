import xhr from 'xhr.js';
import auth from 'services/auth';

const service_users = {
    getNotificationSettings: async () => {
        const account_id = auth.getAccountId();
        const res = await xhr.get(`/me/notification-settings?account_id=${account_id}`, {
            withCredentials: true
        });
        return res.data;
    },
    updateNotificationSettings: async notificationSettings => {
        const account_id = auth.getAccountId();
        const res = await xhr.put(
            `/me/notification-settings?account_id=${account_id}`,
            { notification_settings: notificationSettings },
            {
                withCredentials: true
            }
        );

        return res.data;
    },
    updateUserTimezone: async timezone => {
        const res = await xhr.put(
            `/me/timezone`,
            { timezone },
            {
                withCredentials: true
            }
        );

        return res.data;
    },
    /**
     * Get account preview. Information about account statistics.
     *
     * @returns {{ paid_users: number, allowed_users: number, total_users: number }}
     */
    getAccountUsersSummary: async () => {
        const accountId = auth.getAccountId();
        const response = await xhr.get(`/accounts/${accountId}/users-summary`, {
            withCredentials: true
        });
        return response.data;
    }
};

export default service_users;
