import React, { useMemo } from 'react';
import { useLocation, useHistory } from 'react-router-dom';
import mergeWith from 'lodash/mergeWith';
import qs from 'qs';

/**
 * Hook to get query params from url parsed into an object
 */
export const useQueryParams = () => {
    const history = useHistory();
    const { search, location } = useLocation();
    const currentQuery = useMemo(() => qs.parse(search, { ignoreQueryPrefix: true }), [search]);

    const setQuery = newQuery => {
        const mergedQuery = mergeWith(currentQuery, newQuery, (objValue, srcValue) => {
            if (_.isArray(objValue)) {
                return srcValue;
            }
        });

        const newSearch = qs.stringify(mergedQuery, { addQueryPrefix: true });
        history.push({
            ...location,
            search: newSearch
        });
    };

    const removeQuery = keysToRemove => {
        const filteredQuery = _.omit(parsedQuery, keysToRemove);
        const newSearch = qs.stringify(filteredQuery, { addQueryPrefix: true });

        history.push({
            ...location,
            search: newSearch
        });
    };

    return { query: currentQuery, setQuery, removeQuery };
};

export default useQueryParams;
