import React from 'react';
import { Flex } from 'rebass';
import { FiInfo } from 'react-icons/fi';

import SchedulingPageWrapper from 'containers/PublicSchedulingPage/components/SchedulingPageWrapper';

const SchedulingPageError = props => (
    <SchedulingPageWrapper>
        <Flex style={{ flexDirection: 'column', gap: '28px' }}>
            <Flex style={{ justifyContent: 'center' }}>
                <FiInfo className="color-warning" style={{ width: '48px', height: '48px' }} />
            </Flex>
            <div className="fs-headline-20 color-text-primary" style={{ textAlign: 'center' }}>
                {props.children}
            </div>
        </Flex>
    </SchedulingPageWrapper>
);

export default SchedulingPageError;
