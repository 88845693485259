import React from 'react';
import { Helmet } from 'react-helmet';
import { withToastManager } from 'react-toast-notifications';
import helpers from 'utils/helpers';
import H1 from 'components/H1';
import H2 from 'components/H2';
import { Flex, Box, Button } from 'rebass';
import { Label } from '@rebass/forms';
import services from 'services/services';

import AppPageWrapper from 'components/AppPageWrapper';
import AppPageWrapperSectionHeader from 'components/AppPageWrapper/AppPageWrapperSectionHeader';
import AppPageWrapperSectionBody from 'components/AppPageWrapper/AppPageWrapperSectionBody';
import SettingsPageSubNav from 'containers/SettingsPage/SettingsPageSubNav';
import PricingTable from 'containers/PricingPage/PricingTable';

const planIdToTitle = {
    1: 'Starter',
    2: 'Premier',
    3: 'Enterprise'
};

class SettingsBillingPage extends React.Component {
    constructor(props) {
        super(props);

        let currentPlanId;
        switch (this.getActiveSubscriptionPlan(this.props.auth.account)) {
            case 'startup':
                currentPlanId = 2;
                break;
            case 'enterprise':
                currentPlanId = 3;
                break;
            default:
                currentPlanId = 1;
                break;
        }

        this.state = {
            account: this.props.auth.account,
            currentPlanId,
            selectedPlanId: 2,
            numUsers: 1,
            showPlans: false,
            openPaymentLoading: false
        };

        this.openPayment = this.openPayment.bind(this);
        this.cancelSubscription = this.cancelSubscription.bind(this);
    }

    cancelSubscription() {
        alert('Not yet available through the UI, please email support@panelfox.io');
    }

    openPayment() {
        this.setState({ openPaymentLoading: true });
        const { toastManager } = this.props;

        services.openPayment(this.state.numUsers, (error, body) => {
            if (error) {
                this.setState({ openPaymentLoading: false });
                toastManager.add(body, {
                    appearance: 'error',
                    autoDismiss: true
                });
            }
        });
    }

    getActiveSubscriptionQuantity(account) {
        let quantity;
        helpers.getActiveAccountSubscriptions(account).forEach(s => {
            quantity = s.quantity;
        });

        return quantity;
    }

    getActiveSubscriptionPlan(account) {
        let plan;
        helpers.getActiveAccountSubscriptions(account).forEach(s => {
            plan = s.name;
        });

        return plan;
    }

    render() {
        const sub_quantity = this.getActiveSubscriptionQuantity(this.state.account);

        return (
            <Flex style={{ width: '100%' }}>
                <SettingsPageSubNav />
                <AppPageWrapper>
                    <Helmet>
                        <title>Subscription</title>
                    </Helmet>

                    <AppPageWrapperSectionHeader>
                        <H1>Subscription</H1>
                    </AppPageWrapperSectionHeader>
                    <AppPageWrapperSectionBody style={{ flexDirection: 'column' }}>
                        <Flex mx={4} my={3} className="form-wrapper" style={{ width: '900px' }}>
                            <Box width={1 / 4} mb={2}>
                                <H2>Active Plan</H2>
                                <Label className="quiet">This is your active subscription.</Label>
                            </Box>

                            <Box width={3 / 4} mb={2} sx={{ flexWrap: 'wrap', display: 'flex' }}>
                                <Box width={1 / 3} className="form-label"></Box>
                                <Box width={2 / 3} className="form-data">
                                    {this.state.currentPlanId && (
                                        <>
                                            <div>
                                                <u>{planIdToTitle[this.state.currentPlanId]}</u> with{' '}
                                                <u>
                                                    {sub_quantity} user{sub_quantity > 1 ? 's' : ''}
                                                </u>
                                            </div>
                                            <div>
                                                <Button
                                                    mt={3}
                                                    type="button"
                                                    variant="primary"
                                                    onClick={() => this.setState({ showPlans: 1 })}
                                                    disabled={this.state.showPlans}
                                                >
                                                    Change plan
                                                </Button>

                                                {this.state.currentPlanId != 1 && (
                                                    <Button
                                                        mt={3}
                                                        variant="transparent"
                                                        className="danger"
                                                        onClick={() => this.cancelSubscription()}
                                                    >
                                                        Cancel Subscription
                                                    </Button>
                                                )}
                                            </div>
                                        </>
                                    )}
                                </Box>
                            </Box>
                            {this.state.account.card_brand && (
                                <>
                                    <Box width={1 / 4} mb={2}>
                                        <H2>Payment Card</H2>
                                        <Label className="quiet">
                                            This is the credit card we use to pay for your monthly plan.
                                        </Label>
                                    </Box>
                                    <Box width={3 / 4} mb={2} sx={{ flexWrap: 'wrap', display: 'flex' }}>
                                        <Box width={1 / 3} className="form-label"></Box>
                                        <Box width={2 / 3} className="form-data">
                                            {this.state.account.card_brand} / {this.state.account.card_last_four}
                                        </Box>
                                    </Box>
                                </>
                            )}
                        </Flex>
                        {this.state.showPlans && (
                            <Flex mx={4} my={3} className="form-wrapper" style={{ width: '900px', background: '#eee' }}>
                                <Box width={1 / 4} mb={2}>
                                    <H2>Select New Plan</H2>
                                    <Label className="quiet">This will be your new subscription.</Label>
                                </Box>
                                <Box width={3 / 4} mb={2} sx={{ flexWrap: 'wrap', display: 'flex' }}>
                                    <Box width={1 / 3} className="form-label"></Box>
                                    <Box width={2 / 3} className="form-data">
                                        <div>
                                            <u>{planIdToTitle[this.state.selectedPlanId]}</u> with{' '}
                                            <u>
                                                {this.state.numUsers} user{this.state.numUsers > 1 ? 's' : ''}
                                            </u>
                                        </div>
                                        <div>
                                            <Button
                                                mt={3}
                                                type="button"
                                                variant="primary"
                                                onClick={() => this.openPayment()}
                                                disabled={
                                                    !this.state.selectedPlanId ||
                                                    this.state.selectedPlanId == this.state.currentPlanId ||
                                                    this.state.selectedPlanId == 3 ||
                                                    this.state.numUsers < 1 ||
                                                    this.state.openPaymentLoading
                                                }
                                            >
                                                {this.state.openPaymentLoading
                                                    ? 'Redirecting...'
                                                    : 'Proceed to checkout'}
                                            </Button>
                                        </div>
                                    </Box>
                                </Box>

                                <Box>
                                    <PricingTable
                                        billing
                                        numUsers={this.state.numUsers}
                                        currentPlanId={this.state.currentPlanId}
                                        selectedPlanId={this.state.selectedPlanId}
                                        onPlanSelectedChange={ps => this.setState({ selectedPlanId: ps })}
                                        onNumUsersChange={nu => this.setState({ numUsers: nu })}
                                    />
                                    <br />
                                </Box>
                            </Flex>
                        )}
                        <div>
                            <br />
                            <br />
                        </div>
                    </AppPageWrapperSectionBody>
                </AppPageWrapper>
            </Flex>
        );
    }
}

export default withToastManager(SettingsBillingPage);
