import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Flex } from 'rebass';
import { Label, Checkbox, Select } from '@rebass/forms';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import styled from 'styled-components';

const DayLabel = styled(Label)`
    &&& {
        align-items: center;
        margin: 0;
        min-width: 150px;
        width: fit-content;
    }
    div > svg {
        margin-top: 2px;
    }
`;

const TimeSelect = styled(Select)`
    &&& {
        min-width: 100px;
    }
`;

AvailabilityForm.propTypes = {
    availability: PropTypes.array,
    onFormChange: PropTypes.func
};

/**
 * @deprecated
 */
function AvailabilityForm(props) {
    const [availability, setAvailability] = useState(props.availability);
    const didMount = useRef(false);

    const SERVER_TIME_FORMAT = 'HH:mm';
    const HUMAN_TIME_FORMAT = 'h:mm A';
    const DROPDOWN_OPTIONS = useMemo(() => generateTimes(), []);

    useEffect(() => {
        if (didMount.current) {
            props.onFormChange(availability);
        } else {
            didMount.current = true;
        }
    }, [availability]);

    function toggleDay(index) {
        const updated = [...availability];
        updated[index].enabled = !updated[index].enabled;
        setAvailability(updated);
    }

    function handleTimeChange(index, time) {
        const updated = [...availability];
        const formatTime = t => moment(t, HUMAN_TIME_FORMAT).format(SERVER_TIME_FORMAT);

        if (time.start !== undefined) {
            const formattedStart = formatTime(time.start);
            if (formattedStart >= availability[index].time_end) {
                alert('The start of your availability must be before the end.');
                return;
            }
            updated[index].time_start = formattedStart;
        }
        if (time.end !== undefined) {
            const formattedEnd = formatTime(time.end);
            if (formattedEnd <= availability[index].time_start) {
                alert('The end of your availability must be after the start.');
                return;
            }
            updated[index].time_end = formattedEnd;
        }

        setAvailability(updated);
    }

    function generateTimes() {
        const times = [];

        for (let hour = 0; hour < 24; hour++) {
            times.push(moment({ hour }).format(HUMAN_TIME_FORMAT));
            times.push(
                moment({
                    hour,
                    minute: 30
                }).format(HUMAN_TIME_FORMAT)
            );
        }

        const result = [];

        times.map(time =>
            result.push({
                id: time,
                title: time
            })
        );

        return result;
    }

    return (
        <Flex sx={{ flexDirection: 'column' }}>
            {availability.map((item, index) => (
                <Flex key={item.day} my={1} sx={{ minHeight: '32px' }}>
                    <DayLabel>
                        <Checkbox
                            name="status"
                            checked={item.enabled}
                            onChange={() => toggleDay(index)}
                            value={item.enabled}
                        />
                        {item.day}
                    </DayLabel>
                    {item.enabled && (
                        <>
                            <TimeSelect
                                value={moment(item.time_start, SERVER_TIME_FORMAT)
                                    .local()
                                    .format(HUMAN_TIME_FORMAT)}
                                onChange={e => handleTimeChange(index, { start: e.target.value })}
                            >
                                {DROPDOWN_OPTIONS.map(option => (
                                    <option key={option.id} value={option.title}>
                                        {option.title}
                                    </option>
                                ))}
                            </TimeSelect>
                            <Flex sx={{ fontSize: 1, alignItems: 'center', margin: '0 8px 0 12px' }}>to</Flex>
                            <TimeSelect
                                value={moment(item.time_end, SERVER_TIME_FORMAT)
                                    .local()
                                    .format(HUMAN_TIME_FORMAT)}
                                onChange={e => handleTimeChange(index, { end: e.target.value })}
                            >
                                {DROPDOWN_OPTIONS.map(option => (
                                    <option key={option.id} value={option.title}>
                                        {option.title}
                                    </option>
                                ))}
                            </TimeSelect>
                        </>
                    )}
                </Flex>
            ))}
        </Flex>
    );
}

export default AvailabilityForm;
