import React from 'react';
import { CountryItem } from './CountryItem';

export const CountryList = ({ countryList, handleRemove }) => {
    return (
        <>
            {countryList &&
                countryList.length > 0 &&
                countryList.map(country => (
                    <CountryItem country={country} handleRemove={handleRemove} key={country.value} />
                ))}
        </>
    );
};
