import React from 'react';
export const KeyboardShortcut = ({ keyLabel, theme }) => {
    let backgroundColor;
    let classes;

    switch (theme) {
        case 'secondary':
            classes = 'text-secondary';
            backgroundColor = '#F6F6F6';
            break;
        case 'primary':
        default:
            classes = 'text-purple';
            backgroundColor = '#E5F7FE';
            break;
    }

    return (
        <span
            className={classes}
            style={{
                borderRadius: '3px',
                background: backgroundColor,
                fontSize: '10px',
                verticalAlign: 'top',
                lineHeight: '15px',
                padding: '0 4px',
                margin: '4px 0 0 8px',
                fontWeight: 500,
                display: 'inline-block'
            }}
        >
            {keyLabel}
        </span>
    );
};
