/**
 * Block paste event.
 *
 * @returns {((event: Event) => void)} Block paste function
 */
export const useOnPasteBlocker = () => {
    /**
     * Block paste.
     *
     * @param {Event} event
     */
    const blockOnPaste = event => {
        event.preventDefault();
    };

    return blockOnPaste;
};

export default useOnPasteBlocker;
