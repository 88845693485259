import React, { useMemo } from 'react';
import { Box, Button } from 'rebass';
import { Label, Input } from '@rebass/forms';
import moment from 'moment';

import Portal from 'components/Portal';
import NiceModal from 'components/NiceModal';
import useForm from 'hooks/useForm';
import helpers from 'utils/helpers';

/**
 * Modal to manually add a payment
 *
 * @param {Object} props
 * @param {boolean} props.isOpen - Whether the modal is open
 * @param {string} props.title - The title of the modal
 * @param {() => void} props.onClose - Function to be called when the modal is closed
 * @param {(data: { amount: number, paymentDate: Date }) => void | Promise<void>} props.onSave - Function to be called when the payment is saved
 * @param {Object} props.person - The person to pay
 * @param {{ amount: number, date: string | Date }} props.defaultValues - Default values for the form
 */
export function ManualPaymentModal({ isOpen, title, onClose, onSave, person, defaultValues }) {
    const { register, handleSubmit } = useForm({
        initialForm: {
            amount: defaultValues.amount ? defaultValues.amount : 1,
            paymentDate: moment(defaultValues.date).format('YYYY-MM-DDTHH:mm')
        }
    });
    const name = useMemo(() => {
        const trimmedName = helpers.personGetFullName(person).trim();

        return trimmedName ? trimmedName : 'Name missing';
    }, [person]);
    const email = useMemo(() => helpers.personGetCustomValue(person, 'Email'), [person]);

    const onSubmit = handleSubmit(async data => onSave(data));

    return (
        <Portal>
            <NiceModal isOpen={isOpen} title={title} onRequestClose={onClose}>
                <form id="manual-payment-form" onSubmit={onSubmit}>
                    <Label>Name</Label>
                    <Box as="p" mb={3}>
                        {name}
                    </Box>
                    <Label>E-mail</Label>
                    <Box as="p" mb={3}>
                        {email}
                    </Box>
                    <Box mb={3}>
                        <Label>Amount</Label>
                        <Input
                            {...register('amount', { formatter: parseInt })}
                            type="number"
                            step="1"
                            min="0"
                            required
                            autoFocus
                            placeholder="100"
                        />
                    </Box>
                    <Box>
                        <Label>Payment date</Label>
                        <Input {...register('paymentDate')} type="datetime-local" required style={{ width: '100%' }} />
                    </Box>
                </form>
                <Box className="modal-actions">
                    <Button type="button" mr={2} onClick={onClose} variant="secondary-gray">
                        Cancel
                    </Button>
                    <Button type="submit" variant="primary" mr={0} className="modal-primary" form="manual-payment-form">
                        Save
                    </Button>
                </Box>
            </NiceModal>
        </Portal>
    );
}

export default ManualPaymentModal;
