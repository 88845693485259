import styled from 'styled-components';
import React from 'react';
import { Flex } from 'rebass';

const styles = {
    position: 'relative',
    //top: 0,
    zIndex: 11,
    padding: '12px 32px 0',
    width: '100%',
    flexDirection: 'column',
    //background: '#f6f6f6',
    //borderBottom: '1px solid #eee',
    marginBottom: '8px'
};

/**
 * @deprecated component is not used
 */
const AppPageSubnav = props => (
    <Flex style={styles} {...props}>
        {props.children}
    </Flex>
);

export default AppPageSubnav;
