const cache_key = 'pnlfx_cache';

if (!window[cache_key]) {
    window[cache_key] = {};
}

const cache = {
    get(key) {
        return window[cache_key][key];
    },
    set(key, value) {
        window[cache_key][key] = value;
    }
};

export default cache;
