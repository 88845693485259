import styled from 'styled-components';

export default styled.div`
    //height: 100vh;
    text-align: left;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    //overflow: auto;
    padding: 0;
`;
