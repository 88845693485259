const EventEmitter = {
    _events: {},
    dispatch(event, data) {
        //console.log('dispatch', event);
        if (!this._events[event]) return;
        this._events[event].forEach(callback => callback(data));
    },
    subscribe(event, callback) {
        //console.log('subscribe', event);
        if (!this._events[event]) this._events[event] = [];
        this._events[event].push(callback);
    },
    unsubscribe(event) {
        //console.log('unsubscribe', event);
        if (this._events[event]) {
            delete this._events[event];
        }
    }
};
export default EventEmitter;
