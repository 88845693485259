import React from 'react';
import classNames from 'classnames';
import { Box } from 'rebass';

/**
 * Ensures the value is a number. If the value is not a number, it will try to parse it to a number. If the value is not a number, it will return 0.
 *
 * @param {number | string} value
 * @example
 * ensureNumber('100') // 100
 * ensureNumber(100) // 100
 * ensureNumber('abc') // 0
 */
function ensureNumber(value) {
    if (typeof value === 'number') {
        return value;
    }

    const parsedValue = parseFloat(value);

    if (isNaN(parsedValue)) return 0;

    return parsedValue;
}

/**
 * Progress component to show progress bar.
 *
 * @param {Object} props
 * @param {number | string} props.value - Progress value. (0-100) The value should be between 0 to 100 and can be a number or a string.
 * @returns
 */
export function Progress({ value, className, style }) {
    return (
        <Box
            style={style}
            className={classNames(className, 'progress')}
            sx={{
                '::after': {
                    width: ensureNumber(value) + '%'
                }
            }}
        />
    );
}

export default Progress;
