import React from 'react';
import ReactModal from 'react-modal';
import { Flex, Box, Heading } from 'rebass';
import { FiX } from 'react-icons/fi';

export default props => {
    const styles = { content: {}, ...props.style };
    styles.content.overflow = props.overflow === 'visible' ? 'visible' : 'hidden';

    return (
        <ReactModal
            isOpen={props.isOpen}
            shouldCloseOnOverlayClick={props.shouldCloseOnOverlayClick}
            onRequestClose={props.onRequestClose}
            shouldCloseOnEsc={props.shouldCloseOnEsc}
            style={styles}
        >
            <Box sx={{ overflow: props.overflow ? props.overflow : 'auto' }} className="panelfox-react-modal">
                <Flex
                    sx={{
                        justifyContent: 'space-between',
                        position: 'sticky',
                        top: 0,
                        background: '#fff',
                        zIndex: 5,
                        borderRadius: '8px'
                    }}
                >
                    {props.title ? (
                        <Heading fontSize={2} sx={{ margin: '32px 0 24px 32px', fontWeight: 600 }}>
                            {props.title}
                        </Heading>
                    ) : (
                        <div></div>
                    )}
                    <div onClick={props.onRequestClose} className="modal-x">
                        <FiX />
                    </div>
                </Flex>
                <Box mx={4} mb={4} mt={0} sx={{ position: 'relative' }} style={props.containerStyle}>
                    {props.children}
                </Box>
            </Box>
        </ReactModal>
    );
};
