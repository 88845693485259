import React from 'react';
import { Button } from 'rebass';

const LoginPageButton = props => (
    <Button
        style={{ borderColor: '#D4D9DD' }}
        fontSize={props.small ? '14px' : '16px'}
        height={props.small ? '40px' : '46px'}
        width="100%"
        padding={0}
        mx={0}
        {...props}
    />
);

export default LoginPageButton;
