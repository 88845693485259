import React from 'react';
import { Box } from 'rebass';
import styled from 'styled-components';

const FormContainer = styled(Box)`
    font-size: 14px !important;
    max-width: 800px;
    width: 100%;
    margin: 0 auto !important;
    border-radius: 8px;
    background: #fff;
`;

const FormContainerInner = styled(Box)`
    padding: 20px 40px;
    border-radius: 8px;

    @media (max-width: 768px) {
        padding: 20px 16px;
    }
`;

const PublicScreenPageFormWrapper = props => (
    <FormContainer className="nice-boxshadow-light">
        <FormContainerInner
            style={{
                background: props.screener_bg ? `url(${props.screener_bg}) no-repeat center bottom` : ''
            }}
        >
            {props.children}
        </FormContainerInner>
    </FormContainer>
);

export default PublicScreenPageFormWrapper;
