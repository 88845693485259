import React from 'react';
import { Flex, Button } from 'rebass';
import { useToasts } from 'react-toast-notifications';

import NiceModal from 'components/NiceModal';
import { Portal } from 'components/Portal';
import useMutation from 'hooks/useMutation';
import services from 'services/services';

/**
 *
 * @param {Object} props
 * @param {Object} props.group Group to be deleted
 * @param {number} props.group.id Group ID
 * @param {string} props.group.name Group name
 * @param {Function} props.onClose Function to be called when the modal is closed
 * @param {Function} props.onSuccess Function to be called when the group is deleted successfully
 */
export function DeletePanelPropertiesGroupModal({ group, onClose, onSuccess }) {
    const { addToast } = useToasts();
    const { mutate, isLoading } = useMutation({
        mutationFn: () => services.deletePanelPropertiesGroup(group.id),
        onSuccess: () => {
            addToast('Group deleted successfully', { appearance: 'success', autoDismiss: true });
            onSuccess && onSuccess();
            onClose && onClose();
        },
        onError: (_, message) => {
            addToast(message, { appearance: 'error', autoDismiss: true });
        }
    });

    return (
        <Portal>
            <NiceModal
                isOpen
                shouldCloseOnOverlayClick
                onRequestClose={onClose}
                title={`Remove group ${group.name} from the Panel properties?`}
            >
                <Flex sx={{ gap: 3 }}>
                    <Button
                        onClick={mutate}
                        flexGrow={1}
                        variant="danger-filled"
                        disabled={isLoading}
                        className="color-bg-danger"
                    >
                        Remove
                    </Button>
                    <Button flexGrow={1} onClick={onClose} variant="secondary-gray">
                        Cancel
                    </Button>
                </Flex>
            </NiceModal>
        </Portal>
    );
}

export default DeletePanelPropertiesGroupModal;
