import React, { useEffect, useState } from 'react';
import { Flex, Button } from 'rebass';
import auth from 'services/auth';
import helpers from 'utils/helpers';
import NiceDropdown from 'components/NiceDropdown';
import UpgradeButton from 'components/UpgradeButton';
import { PAID_FEATURE } from 'utils/constants';

function PanelDropdown(props) {
    const [hasAdminPrivileges, setHasAdminPrivileges] = useState(false);
    const [hasMultiplePanelsEnabled, setHasMultiplePanelsEnabled] = useState(false);

    useEffect(() => {
        const auth_state = auth.getAuthState();
        const enabled = helpers.hasFeatureEnabled(auth_state.account, PAID_FEATURE.MULTIPLE_PANELS);
        setHasMultiplePanelsEnabled(enabled);
        setHasAdminPrivileges(auth_state.account_role.type === 'admin');
    }, []);

    return (
        <>
            {props.showDropdown && (
                <NiceDropdown
                    adjustHeight={props.adjustHeight}
                    width={props.width}
                    height={props.height}
                    positionTop={props.positionTop}
                    positionLeft={props.positionLeft}
                    items={props.items}
                    onChange={props.onChange}
                    value={props.value}
                    onClose={props.onClose}
                    showSearch={props.items.length > 5}
                    renderBottomStickyButton={
                        hasAdminPrivileges &&
                        (hasMultiplePanelsEnabled ? (
                            <Flex sx={{ width: '100%' }}>
                                <Button variant="secondary" onClick={props.onCreatePanel} flex={1} mr={0}>
                                    + Create panel
                                </Button>
                            </Flex>
                        ) : (
                            <UpgradeButton type="multiple-panels" style={{ width: '100%' }} />
                        ))
                    }
                />
            )}
        </>
    );
}

export default PanelDropdown;
