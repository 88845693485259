import xhr from 'xhr.js';

export async function put(url, data) {
    const response = await xhr.put(
        url,
        { ...data },
        {
            withCredentials: true
        }
    );
    return response.data;

    /*return fetch(url, {
    method: 'POST',
    headers,
    credentials: 'include',
    body: JSON.stringify(data),
  }).then(response => response);*/
}

export function get(url) {
    return xhr
        .get(url, {
            withCredentials: true
        })
        .then(res => res.data);

    /*return fetch(url, {
    method: 'GET',
    headers,
    credentials: 'include',
  }).then(response => response.json());*/
}
