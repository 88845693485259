import mixpanel from 'mixpanel-browser';
const env = process.env.NODE_ENV;
const track_production_only = true;

const analytics = {
    init(token) {
        if (track_production_only && env != 'production') {
            return;
        }
        mixpanel.init(token);
    },
    track(key, options) {
        if (track_production_only && env != 'production') {
            console.log('analytics.track - skipping local', key, options);
            return;
        }
        return mixpanel.track(key, options);
    },
    identify(user_id) {
        if (track_production_only && env != 'production') {
            return;
        }
        mixpanel.identify(user_id);
    },
    set(value) {
        if (track_production_only && env != 'production') {
            return;
        }
        mixpanel.people.set(value);
    }
};

export default analytics;
