import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import { Flex, Box } from 'rebass';
import { HeaderSubnavLink } from 'components/Header/HeaderSubnavLink';
import { HeaderSubnavButton } from 'components/Header/HeaderSubnavButton';
import { SmallLink } from 'components/Header/SmallLink';
import H1 from 'components/H1';
import { Link } from 'react-router-dom';
import cache from 'utils/cache';
import helpers from 'utils/helpers';
import moment from 'moment-timezone';
import analytics from 'utils/analytics';
import {
    FiLayers,
    FiFileText,
    FiCalendar,
    FiSettings,
    FiPieChart,
    FiMail,
    FiBell,
    FiChevronDown,
    FiActivity
} from 'react-icons/fi';
import StudyActivityDrawer from 'components/Study/StudyActivityDrawer';
import NiceDropdown from 'components/NiceDropdown';
import classNames from 'classnames';

function StudyPageSubNav(props) {
    const [showActivity, setShowActivity] = useState(false);
    const [showReportingDropdown, setShowReportingDropdown] = useState(false);
    const history = useHistory();

    const study_title_key = `study_id_${props.study_id}`;

    // get title from cache
    let study_title = cache.get(study_title_key);

    // if we do have the value from the parent, render this
    if (props.study && props.study.title) {
        // eslint-disable-next-line
        study_title = props.study.title;
        cache.set(study_title_key, study_title);
    }

    const isReportingRouteHighlighted = () => {
        return helpers.startsWithAny(
            [
                `/studies/${props.study_id}/reports`,
                `/studies/${props.study_id}/emails`,
                `/studies/${props.study_id}/quotas`
            ],
            location.pathname
        );
    };

    return (
        <>
            <Flex
                px={4}
                sx={{
                    flexDirection: 'row',
                    lineHeight: '24px',
                    borderBottom: '1px solid #e9ecef',
                    width: '100%',
                    justifyContent: 'space-between'
                }}
            >
                <Flex>
                    <HeaderSubnavLink
                        exact
                        to={`/studies/${props.study_id}`}
                        className="basic-subnav"
                        onClick={() => analytics.track('study_nav_overview-click')}
                    >
                        <FiLayers /> Overview
                    </HeaderSubnavLink>
                    <HeaderSubnavLink
                        to={`/studies/${props.study_id}/screeners`}
                        className="basic-subnav"
                        onClick={() => analytics.track('study_nav_screeners-click')}
                    >
                        <FiFileText /> Screeners
                    </HeaderSubnavLink>
                    <HeaderSubnavLink
                        to={`/studies/${props.study_id}/scheduling`}
                        className="basic-subnav"
                        onClick={() => analytics.track('study_nav_scheduling-click')}
                    >
                        <FiCalendar /> Scheduling
                    </HeaderSubnavLink>

                    <HeaderSubnavButton
                        className={classNames('basic-subnav', isReportingRouteHighlighted() && 'nav-item-active')}
                        onClick={() => {
                            analytics.track('study_nav_reports-click');
                            setShowReportingDropdown(true);
                        }}
                    >
                        <FiActivity /> Reports <FiChevronDown style={{ margin: '5px 0 0 6px' }} />
                        {showReportingDropdown && (
                            <NiceDropdown
                                width="150px"
                                onClose={() => setShowReportingDropdown(false)}
                                items={[
                                    {
                                        title: (
                                            <>
                                                Summary{' '}
                                                {helpers.newFeatureTag(moment('06-07-2024', 'MM-DD-YYYY'), {
                                                    margin: '7px 0 0px 8px'
                                                })}
                                            </>
                                        ),
                                        icon: <FiActivity />,
                                        onClick: () => {
                                            analytics.track('study_nav_reportsSummary-click');
                                            history.push(`/studies/${props.study_id}/reports`);
                                        }
                                    },
                                    {
                                        title: 'Email & SMS',
                                        icon: <FiMail />,
                                        onClick: () => {
                                            analytics.track('study_nav_campaigns-click');
                                            history.push(`/studies/${props.study_id}/emails`);
                                        }
                                    },
                                    {
                                        title: 'Quota Grids',
                                        icon: <FiPieChart />,
                                        onClick: () => {
                                            analytics.track('study_nav_quotaGrids-click');
                                            history.push(`/studies/${props.study_id}/quotas`);
                                        }
                                    }
                                ]}
                            />
                        )}
                    </HeaderSubnavButton>
                    <HeaderSubnavLink
                        to={`/studies/${props.study_id}/settings`}
                        className="basic-subnav"
                        onClick={() => analytics.track('study_nav_settings-click')}
                    >
                        <FiSettings /> Study Settings
                    </HeaderSubnavLink>
                </Flex>

                <HeaderSubnavButton
                    className="basic-subnav"
                    onClick={() => {
                        setShowActivity(true);
                        analytics.track('study_nav_activity-click');
                    }}
                    style={{ marginRight: 0 }}
                >
                    <FiBell /> Activity
                </HeaderSubnavButton>
            </Flex>

            {showActivity === true && (
                <StudyActivityDrawer
                    studyId={props.study_id}
                    onClickParticipant={props.onClickParticipant}
                    onClose={() => setShowActivity(false)}
                />
            )}
        </>
    );
}

export default StudyPageSubNav;
