/*
 * ParticipantsPage
 *
 * This is the first thing users see of our App, at the '/' route
 */

import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import xhr from 'xhr.js';
import styled from 'styled-components';
import { withToastManager } from 'react-toast-notifications';
import { withTheme } from 'emotion-theming';
import { withRouter } from 'react-router-dom';
import NiceModal from 'components/NiceModal';
import cloneDeep from 'lodash/cloneDeep';
import InputSearch from 'components/InputSearch';
import analytics from 'utils/analytics';
import Tooltip from 'react-tooltip';

import PastParticipation from 'components/Participant/PastParticipation';
import ParticipantSettings from 'components/Participant/ParticipantSettings';
import SideOverlay from 'components/SideOverlay';
import store from 'store';
import qs from 'qs';
import helpers from 'utils/helpers';
import services from 'services/services';
import study_services from 'services/studies';
import service_people from 'services/people';
import moment from 'moment-timezone';

import { TableResizer } from 'components/TableResizer';
import { HeaderSubnavLink } from 'components/Header/HeaderSubnavLink';
import ExportPanelists from 'components/Participant/ExportPanelists';
import NiceWrapper from 'components/NiceWrapper';
import H1 from 'components/H1';
import { Box, Button, Flex } from 'rebass';
import { Select, Input, Label, Checkbox, Switch } from '@rebass/forms';
import ListWrapper from 'components/ListWrapper';
import ParticipantItem from 'components/Participant/ParticipantItem';
import LoadingIndicator from 'components/LoadingIndicator';
import LoadingWrapper from 'components/LoadingIndicator/LoadingWrapper';
import SelectModal from 'components/SelectModal';
import CreateParticipant from 'components/Participant/CreateParticipant';
import UploadParticipants from 'components/Participant/UploadParticipants';
import ParticipantsPageSubNav from 'containers/ParticipantsPage/ParticipantsPageSubNav';
import ItemWrapper from 'components/ItemWrapper';
import ParticipantInputElement from 'components/Participant/ParticipantInputElement';
import ParticipantTags from 'components/Participant/ParticipantTags';
import {
    COL_FIRST_NAME,
    COL_LAST_NAME,
    PANEL_FILTER_TYPES,
    PERSON_KEY_TYPE_STRING,
    PERSON_KEY_TYPE_DATE,
    PERSON_KEY_TYPE_BOOLEAN,
    PERSON_KEY_TYPE_NUMBER,
    PERSON_KEY_TYPE_DROPDOWN,
    PERSON_KEY_TYPE_CHECKBOX,
    PERSON_KEY_TYPE_TEXT,
    PROPERTY_TABLE_ORDER,
    FEATURE_CONFIG,
    PAGINATION_TYPE,
    USER_ROLE
} from 'utils/constants';

import {
    FiBookOpen,
    FiEdit,
    FiUploadCloud,
    FiPlus,
    FiChevronDown,
    FiCheckSquare,
    FiSun,
    FiXCircle,
    FiDownloadCloud,
    FiLayers,
    FiUsers,
    FiTag,
    FiMinus,
    FiColumns,
    FiMoreHorizontal,
    FiChevronUp,
    FiClock,
    FiUser,
    FiPieChart,
    FiChevronRight,
    FiTrash2,
    FiCode
} from 'react-icons/fi';

import { InformationTooltip } from 'components/InformationTooltip';
import AppPageWrapper from 'components/AppPageWrapper';
import AppPageWrapperSectionBody from 'components/AppPageWrapper/AppPageWrapperSectionBody';
import AppPageWrapperSectionSubHeader from 'components/AppPageWrapper/AppPageWrapperSectionSubHeader';
import NiceDropdown from 'components/NiceDropdown';
import PanelDropdown from 'components/PanelDropdown';
import { FilterConditional } from 'components/Filters';
import { CreatePersonDataKey } from 'components/PersonDataKey';
import ToastSuccess from './components/ToastSuccess';
import { KeyPressHookWrapper } from '../keyPressHookWrapper';
import { KeyboardShortcut } from '../../components/Shared/KeyboardShortcut';
import classNames from 'classnames';
import AddToStudyButton from 'components/AddToStudyButton';
import NumberedPagination from 'components/NumberedPagination';
import { withPagination } from 'hooks/usePagination';
import { DeletePanelistsModal } from 'components/DeletePanelistsModal';
import SelectPanelDropdown from 'components/SelectPanelDropdown';
import PanelSearchSelect from 'components/PanelSearchSelect';

const STORE_PREFIX_PANEL_COLUMNS_ORDER = 'pfx_panel_columns_order';
const DEFAULT_COLUMNS_HIDDEN = [];
const DEFAULT_COLUMNS_ORDER = {};
const DEFAULT_FILTERS = [
    {
        and_or: 'and',
        groups: []
    }
];

const CACHE_KEY_FILTER_QUERY = 'pfx_panel_filter_query';

class ParticipantsPage extends React.Component {
    constructor(props) {
        super(props);

        let panel_search_id = '';
        const {
            filters,
            showFatigued,
            panelIdSelected,
            dataSortColumnId,
            dataSortDirection
        } = this.getFilterDataFromQuery(this.props.location.search);

        if (this.props.match.params.panel_search_id) {
            // eslint-disable-next-line
            panel_search_id = this.props.match.params.panel_search_id;
        }

        //const tableColumns_hidden = this.getColumnsHidden();
        const tableColumns_order = this.getColumnsOrder();
        this.isAdmin = this.props.auth.account_role.type === USER_ROLE.ADMIN;

        this.state = {
            tableNode: null,
            isDrawerLocked: false,
            bulk_edit_column: {},
            bulk_edit_value: '',
            loading: true,
            loading_more: false,
            people: [],
            pagination_limit: 20,
            pagination: {
                links: [],
                from: 0,
                to: 0,
                total: 0,
                current_page: 1,
                last_page: 0,
                per_page: 0
            },
            // eslint-disable-next-line react/no-unused-state
            search_kw: '',
            showModalAddCustomColumn: false,
            isDeletePanelistsModalOpen: false,
            showModalCreatePanel: false,
            showModalParticipantCreate: false,
            showModalParticipantImport: false,
            showModalAddPeopleToStudy: false,
            showModalAddPeopleToStudy_loading: true,
            showModalAddRemoveFromPanel: false,
            showModalAddRemoveFromPanel_loading: true,
            showAddFilterDropdown: false,
            showDisplayedCustomColumns: false,
            showRespondentPreview: false,
            showRespondentPreview_tab: 'profile',
            showRespondentPreviewModalAddTag: false,
            showMoreDropdown: false,
            showCheckAllDropdown: false,
            checkAllOrVisibleStatus: 'none', // none, all, or visible
            checkAll_custom_count_number: null,
            checkAll_randomize: false,
            checkedCount: 0,
            checkbox_checked: [],
            studies: [],
            dataSortColumnId,
            dataSortDirection,
            filters,
            showFatigued,
            panel_search_id,
            panels: [],
            panelsLoaded: false,
            panelIdSelected,
            panelSearch: null,
            availableColumns: [],
            availableColumns_showing: [],
            tableColumns_order: tableColumns_order,
            tags: [],
            study_tags: [],
            respondentPreviewPerson: {},
            filterCompareOptions: [
                {
                    id: 'contains',
                    title: 'contains',
                    num_values: -1,
                    for_type: [
                        PERSON_KEY_TYPE_STRING,
                        PERSON_KEY_TYPE_TEXT,
                        PERSON_KEY_TYPE_NUMBER,
                        PERSON_KEY_TYPE_CHECKBOX
                    ]
                },
                {
                    id: 'is',
                    title: 'is',
                    num_values: -1,
                    for_type: [
                        PERSON_KEY_TYPE_STRING,
                        PERSON_KEY_TYPE_TEXT,
                        PERSON_KEY_TYPE_NUMBER,
                        PERSON_KEY_TYPE_DROPDOWN
                    ]
                },
                {
                    id: 'is_not',
                    title: 'is not',
                    num_values: -1,
                    for_type: [
                        PERSON_KEY_TYPE_STRING,
                        PERSON_KEY_TYPE_TEXT,
                        PERSON_KEY_TYPE_NUMBER,
                        PERSON_KEY_TYPE_DROPDOWN
                    ]
                },
                {
                    id: 'starts_with',
                    title: 'starts with',
                    num_values: -1,
                    for_type: [PERSON_KEY_TYPE_STRING, PERSON_KEY_TYPE_TEXT, PERSON_KEY_TYPE_NUMBER]
                },
                {
                    id: 'ends_with',
                    title: 'ends with',
                    num_values: -1,
                    for_type: [PERSON_KEY_TYPE_STRING, PERSON_KEY_TYPE_TEXT, PERSON_KEY_TYPE_NUMBER]
                },
                {
                    id: 'does_not_contain',
                    title: 'does not contain',
                    num_values: -1,
                    for_type: [
                        PERSON_KEY_TYPE_STRING,
                        PERSON_KEY_TYPE_TEXT,
                        PERSON_KEY_TYPE_NUMBER,
                        PERSON_KEY_TYPE_CHECKBOX
                    ]
                },
                {
                    id: 'is_unknown',
                    title: 'is unknown',
                    num_values: 0,
                    for_type: [
                        PERSON_KEY_TYPE_STRING,
                        PERSON_KEY_TYPE_TEXT,
                        PERSON_KEY_TYPE_BOOLEAN,
                        PERSON_KEY_TYPE_NUMBER,
                        PERSON_KEY_TYPE_DATE,
                        PERSON_KEY_TYPE_DROPDOWN,
                        PERSON_KEY_TYPE_CHECKBOX
                    ]
                },
                {
                    id: 'has_any_value',
                    title: 'has any value',
                    num_values: 0,
                    for_type: [
                        PERSON_KEY_TYPE_STRING,
                        PERSON_KEY_TYPE_TEXT,
                        PERSON_KEY_TYPE_BOOLEAN,
                        PERSON_KEY_TYPE_NUMBER,
                        PERSON_KEY_TYPE_DATE,
                        PERSON_KEY_TYPE_DROPDOWN,
                        PERSON_KEY_TYPE_CHECKBOX
                    ]
                },
                {
                    id: 'greater_than',
                    title: 'greater than',
                    num_values: 1,
                    for_type: [PERSON_KEY_TYPE_STRING, PERSON_KEY_TYPE_NUMBER]
                },
                {
                    id: 'less_than',
                    title: 'less than',
                    num_values: 1,
                    for_type: [PERSON_KEY_TYPE_STRING, PERSON_KEY_TYPE_NUMBER]
                },
                {
                    id: 'between',
                    title: 'is between',
                    num_values: 2,
                    for_type: [PERSON_KEY_TYPE_STRING, PERSON_KEY_TYPE_NUMBER]
                },
                {
                    id: 'before_date',
                    title: 'before',
                    num_values: 1,
                    for_type: [PERSON_KEY_TYPE_DATE]
                },
                {
                    id: 'after_date',
                    title: 'after',
                    num_values: 1,
                    for_type: [PERSON_KEY_TYPE_DATE]
                },
                {
                    id: 'on_date',
                    title: 'on',
                    num_values: 1,
                    for_type: [PERSON_KEY_TYPE_DATE]
                },
                {
                    id: 'greater_than_days',
                    title: 'more than days ago',
                    num_values: 1,
                    for_type: [PERSON_KEY_TYPE_DATE]
                },
                {
                    id: 'less_than_days',
                    title: 'less than days ago',
                    num_values: 1,
                    for_type: [PERSON_KEY_TYPE_DATE]
                },
                {
                    id: 'is_true',
                    title: 'is true',
                    num_values: 0,
                    for_type: [PERSON_KEY_TYPE_BOOLEAN]
                },
                {
                    id: 'is_false',
                    title: 'is false',
                    num_values: 0,
                    for_type: [PERSON_KEY_TYPE_BOOLEAN]
                },
                {
                    id: 'is_duplicate',
                    title: 'has duplicates',
                    num_values: 0,
                    for_type: [PERSON_KEY_TYPE_STRING, PERSON_KEY_TYPE_NUMBER, PERSON_KEY_TYPE_DATE]
                }
            ]
        };

        this.onBulkEditParticipants = this.onBulkEditParticipants.bind(this);
        this.onBulkEditValueChange = this.onBulkEditValueChange.bind(this);
        this.handleDisplayedCustomColumns = this.handleDisplayedCustomColumns.bind(this);
        this.onChangePanelSearch = this.onChangePanelSearch.bind(this);
        this.onSearchKeywordChange = this.onSearchKeywordChange.bind(this);
        this.handleCloseModalParticipantCreate = this.handleCloseModalParticipantCreate.bind(this);
        this.handleOpenModalParticipantCreate = this.handleOpenModalParticipantCreate.bind(this);

        this.handleCloseModalParticipantImport = this.handleCloseModalParticipantImport.bind(this);
        this.handleOpenModalParticipantImport = this.handleOpenModalParticipantImport.bind(this);

        this.handleOpenModalAddPeopleToStudy = this.handleOpenModalAddPeopleToStudy.bind(this);
        this.handleCloseModalAddPeopleToStudy = this.handleCloseModalAddPeopleToStudy.bind(this);
        this.handleOpenModalAddTag = this.handleOpenModalAddTag.bind(this);
        this.handleCloseModalAddTag = this.handleCloseModalAddTag.bind(this);
        this.handleOpenModalAddRemoveFromPanel = this.handleOpenModalAddRemoveFromPanel.bind(this);
        this.handleCloseModalAddRemoveFromPanel = this.handleCloseModalAddRemoveFromPanel.bind(this);
        this.onSubmitAddRemoveFromPanel = this.onSubmitAddRemoveFromPanel.bind(this);
        this.onSubmitCreateAddRemoveFromPanel = this.onSubmitCreateAddRemoveFromPanel.bind(this);
        this.handleOpenModalAddCustomColumn = this.handleOpenModalAddCustomColumn.bind(this);
        this.handleCloseModalAddCustomColumn = this.handleCloseModalAddCustomColumn.bind(this);
        this.handleAddColumnSuccess = this.handleAddColumnSuccess.bind(this);
        this.handleMoreDropdownChange = this.handleMoreDropdownChange.bind(this);

        this.onSubmitCreateStudyAddPeople = this.onSubmitCreateStudyAddPeople.bind(this);
        this.onSubmitAddPeopleToStudy = this.onSubmitAddPeopleToStudy.bind(this);

        this.resetTableColumns = this.resetTableColumns.bind(this);

        this.handleLoadMore = this.handleLoadMore.bind(this);
        this.handleExportCsv = this.handleExportCsv.bind(this);
        this.setSort = this.setSort.bind(this);

        this.toggleCheckbox = this.toggleCheckbox.bind(this);
        this.handleClearFilters = this.handleClearFilters.bind(this);
        this.handleCopyFilters = this.handleCopyFilters.bind(this);
        this.handleClickFilter = this.handleClickFilter.bind(this);
        this.handleClickAddFilter = this.handleClickAddFilter.bind(this);
        this.handleAddFilterCreate = this.handleAddFilterCreate.bind(this);
        this.handleRemoveFilter = this.handleRemoveFilter.bind(this);
        this.handleUpdateFilterId = this.handleUpdateFilterId.bind(this);
        this.handleUpdateFilterCompare = this.handleUpdateFilterCompare.bind(this);
        this.handleUpdateFilterValue = this.handleUpdateFilterValue.bind(this);
        this.handleCheckAllDropdownChange = this.handleCheckAllDropdownChange.bind(this);

        this.deleteParticipants = this.deleteParticipants.bind(this);
        this.getParticipantsXHR = this.getParticipantsXHR.bind(this);

        this.getParticipantsWithLocalstoredFilterData = this.getParticipantsWithLocalstoredFilterData.bind(this);

        this.onChangePaginationLimit = this.onChangePaginationLimit.bind(this);

        this.onNewPerson = this.onNewPerson.bind(this);
        this.onClickPreviewPanelist = this.onClickPreviewPanelist.bind(this);

        this.onChangeSelectPanelDropdown = this.onChangeSelectPanelDropdown.bind(this);

        this.onCreatePanel = this.onCreatePanel.bind(this);
        this.onUpdatePanels = this.onUpdatePanels.bind(this);

        this.onPersonPreviewTagAdd = this.onPersonPreviewTagAdd.bind(this);
        this.onPersonPreviewTagDelete = this.onPersonPreviewTagDelete.bind(this);
        this.onPersonPreviewTagCreate = this.onPersonPreviewTagCreate.bind(this);

        this.participantSettingsRef = React.createRef();
        this.lastGetParticipantsPromise = React.createRef();
    }

    handleClearFilters() {
        //theres a race condition..
        setTimeout(() => {
            console.log('handleClearFilters', DEFAULT_FILTERS);
            this.setState(
                {
                    filters: cloneDeep(DEFAULT_FILTERS),
                    panel_search_id: '',
                    panelSearch: null,
                    checkAllOrVisibleStatus: 'none',
                    showFilterOptions: false
                },
                () => {
                    this.handleClickFilter();
                }
            );
        }, 200);
    }

    handleCopyFilters() {
        const { toastManager } = this.props;
        helpers.copyToClipboard(JSON.stringify(this.state.filters));
        toastManager.add('Copied to clipboard', {
            appearance: 'success',
            autoDismiss: true
        });
        this.setState({ showFilterOptions: false });
    }

    checkFilterValidity(filters) {
        let filtersAreValid = true;
        filters.forEach(filter => {
            if (filter.id && filter.compare && filter.values) {
                // cool
            } else {
                filtersAreValid = false;
            }
        });

        // maybe v3!
        try {
            if (!filtersAreValid) {
                filters.forEach(parent => {
                    if (parent.and_or && parent.groups) {
                        filtersAreValid = true;
                    } else {
                        filtersAreValid = false;
                    }
                });
            }
        } catch (e) {
            helpers.trackError(e);
        }

        return filtersAreValid;
    }

    // show/hide individual column
    handleDisplayedCustomColumns(columnId, isChecked) {
        //console.log('handleDisplayedCustomColumns', columnId, isChecked);
        const { availableColumns_showing } = this.state;
        const columnIndex = availableColumns_showing.findIndex(column => column.id === columnId);

        if (columnIndex > -1) {
            availableColumns_showing[columnIndex].checked = isChecked;
            this.setState({ availableColumns_showing });
            this.updateColumnsXHR(availableColumns_showing);
        }
    }

    updateColumnsXHR(availableColumns_showing, cb) {
        //console.log('updateColumnsXHR', availableColumns_showing)
        const { toastManager } = this.props;

        services
            .updatePanelColumnShow(availableColumns_showing)
            .then(() => {
                if (cb) {
                    cb();
                }
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);

                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    onChangePanelSearch(panelSearch) {
        if (JSON.stringify(this.state.panelSearch) != JSON.stringify(panelSearch)) {
            if (panelSearch) {
                let filters = panelSearch.url_state.filters;
                if (!this.isFiltersV3Format(filters)) {
                    console.log('not v3');
                    filters = this.updateFiltersV2ToV3(filters);
                    console.log(filters);
                }

                this.setState(
                    {
                        panelSearch,
                        filters: filters,
                        showFatigued: panelSearch.url_state.showFatigued,
                        panelIdSelected: panelSearch.url_state.panelIdSelected,
                        dataSortColumnId: panelSearch.url_state.dataSortColumnId,
                        dataSortDirection: panelSearch.url_state.dataSortDirection,
                        panel_search_id: panelSearch.id
                    },
                    () => {
                        this.getParticipantsXHR();
                        this.props.history.push(`/panel/${panelSearch.id}`);
                    }
                );
            } else {
                this.setState(
                    {
                        panelSearch: null,
                        filters: cloneDeep(DEFAULT_FILTERS),
                        showFatigued: false,
                        panelIdSelected: '',
                        dataSortColumnId: '',
                        dataSortDirection: '',
                        panel_search_id: ''
                    },
                    () => {
                        this.getParticipantsXHR();
                        this.props.history.push(`/panel`);
                    }
                );
            }
        }
    }

    onChangePaginationLimit(e) {
        const paginationLimit = e.target.value;

        // if we're using numbered pagination, we need to use the pagination object
        if (FEATURE_CONFIG.PAGINATION_TYPE === PAGINATION_TYPE.NUMBERED) {
            this.setState({ checkAllOrVisibleStatus: 'none' }, () => {
                this.props.pagination.changePageSize(paginationLimit);
                this.getParticipantsXHR();
            });
        } else {
            this.setState({ pagination_limit: paginationLimit, checkAllOrVisibleStatus: 'none' }, () => {
                this.getParticipantsXHR();
            });
        }
    }

    getFilterDataFromQuery(query) {
        let filters = [];
        let showFatigued = false;
        let panelIdSelected = '';
        let dataSortColumnId = '';
        let dataSortDirection = '';

        const queryParams = qs.parse(query, {
            ignoreQueryPrefix: true
        });

        if (queryParams) {
            if (queryParams.filters) {
                const queryParamsFilters = JSON.parse(queryParams.filters);

                if (this.checkFilterValidity(queryParamsFilters)) {
                    filters = queryParamsFilters;

                    if (!this.isFiltersV3Format(filters)) {
                        filters = this.updateFiltersV2ToV3(filters);
                    }
                }
            }
            if (queryParams.showFatigued === 'true') {
                showFatigued = true;
            }
            if (helpers.isNumber(queryParams.panelIdSelected)) {
                panelIdSelected = parseInt(queryParams.panelIdSelected);
            }
            if (queryParams.dataSortColumnId) {
                // eslint-disable-next-line prefer-destructuring
                dataSortColumnId = queryParams.dataSortColumnId;
            }
            if (queryParams.dataSortDirection == 'asc' || queryParams.dataSortDirection == 'desc') {
                // eslint-disable-next-line prefer-destructuring
                dataSortDirection = queryParams.dataSortDirection;
            }
        }

        return { filters, showFatigued, panelIdSelected, dataSortColumnId, dataSortDirection };
    }

    onChangeSelectPanelDropdown(value) {
        if (this.state.panelIdSelected != value) {
            this.setState({ panelIdSelected: value }, () => {
                this.handleClickFilter();
            });
        }
    }

    onCreatePanel(panel) {
        const { toastManager } = this.props;
        toastManager.add('Successfully created a new panel', {
            appearance: 'success',
            autoDismiss: true
        });
        this.props.history.push(`/panels/${panel.id}`);
    }

    onUpdatePanels(new_panels) {
        this.setState({ panels: new_panels });
    }

    onNewPerson(person) {
        const { toastManager } = this.props;
        toastManager.add('Successfully created a new person', {
            appearance: 'success',
            autoDismiss: true
        });
        console.log(person);
        this.props.history.push(`/participants/${person.id}`);
    }

    handleExportCsv(exportCsvCustomColumnsIncludedOrdered) {
        this.getParticipantsXHR({
            export_csv: true,
            exportCsvCustomColumnsIncludedOrdered: exportCsvCustomColumnsIncludedOrdered
        });
    }

    handleOpenModalParticipantCreate() {
        this.setState({ showModalParticipantCreate: true });
    }

    handleCloseModalParticipantCreate() {
        this.setState({ showModalParticipantCreate: false });
    }

    getAvailableColumns_showingXHR() {
        //console.log('getAvailableColumns_showingXHR');
        const { toastManager } = this.props;

        services
            .getPanelColumnShow()
            .then(user_columns_showing => {
                // user_columns_showing are the saved column order & show/hide in the DB

                //console.log('user_columns_showing', user_columns_showing)
                // start with availableColumns, and spit out availableColumns_showing
                const availableColumns_showing = this.state.availableColumns.map(col => {
                    const found_col = user_columns_showing.find(ucs => ucs.id === col.id);
                    if (found_col) {
                        col.checked = !!found_col.checked;
                        // FYI table_order instead of order, because order is used for ordering
                        // properties on the panelist profile page
                        col[PROPERTY_TABLE_ORDER] = found_col.hasOwnProperty(PROPERTY_TABLE_ORDER)
                            ? found_col[PROPERTY_TABLE_ORDER]
                            : undefined;
                    } else {
                        const isFirstName = col.title === COL_FIRST_NAME;
                        const isLastName = col.title === COL_LAST_NAME;
                        col.checked = !isFirstName && !isLastName;
                        col[PROPERTY_TABLE_ORDER] = undefined;
                    }
                    return col;
                });

                this.setState({
                    availableColumns_showing
                });
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);

                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    getAvailableColumnsXHR() {
        //console.log('getAvailableColumnsXHR');
        const { toastManager } = this.props;

        services
            .getCustomDataColumnsXHR()
            .then(columns => {
                this.setState({
                    availableColumns: columns
                });

                this.getAvailableColumns_showingXHR();
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);

                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    handleOpenModalAddCustomColumn() {
        this.setState({ showModalAddCustomColumn: true });
    }

    handleCloseModalAddCustomColumn() {
        this.setState({ showModalAddCustomColumn: false });
    }

    handleAddColumnSuccess() {
        // e.preventDefault();
        this.getAvailableColumnsXHR();
        this.getParticipantsXHR();

        const { toastManager } = this.props;
        toastManager.add('Column successfully added.', {
            appearance: 'success',
            autoDismiss: true
        });

        this.handleCloseModalAddCustomColumn();
    }

    handleOpenModalParticipantImport() {
        this.setState({ showModalParticipantImport: true });
    }

    handleCloseModalParticipantImport() {
        this.setState({ showModalParticipantImport: false });
    }

    handleMoreDropdownChange(value) {
        if (value == 'export') {
            this.setState({ showModalExportCsv: true });
        } else if (value === 'delete') {
            this.onDeletePanelistsModalOpen();
        } else if (value == 'edit') {
            this.handleOpenModalBulkEdit();
        } else if (value == 'tag') {
            this.handleOpenModalAddTag();
        } else if (value == 'panel') {
            this.handleOpenModalAddRemoveFromPanel();
        }
    }

    checkIfGroupAndFilterExist(filters, group_index, filter_index) {
        let exists = false;
        if (
            filters &&
            filters[0] &&
            filters[0].groups &&
            filters[0].groups[group_index] &&
            filters[0].groups[group_index].filters &&
            filters[0].groups[group_index].filters[filter_index]
        ) {
            exists = true;
        }

        return exists;
    }

    handleRemoveFilter(group_index, filter_index) {
        //console.log('handleRemoveFilter', group_index, filter_index)
        const { filters } = this.state;
        if (!this.checkIfGroupAndFilterExist(filters, group_index, filter_index)) {
            return;
        }

        filters[0].groups[group_index].filters.splice(filter_index, 1);

        if (filters[0].groups[group_index].filters.length == 0) {
            filters[0].groups.splice(group_index, 1);
        }

        this.setState({ filters, panel_search_id: '', panelSearch: null }, () => {
            this.updateUrlState();
            this.getParticipantsXHR();
        });
        this.setState({ checkAllOrVisibleStatus: 'none' });
    }

    handleUpdateFilterId(group_index, filter_index, id) {
        const { filters } = this.state;
        if (!this.checkIfGroupAndFilterExist(filters, group_index, filter_index)) {
            return;
        }
        filters[0].groups[group_index].filters[filter_index].id = id;

        this.setState({ filters, panel_search_id: '', panelSearch: null }, () => {
            this.handleClickFilter();
        });
    }

    handleUpdateFilterCompare(group_index, filter_index, compare) {
        const { filters } = this.state;
        if (!this.checkIfGroupAndFilterExist(filters, group_index, filter_index)) {
            return;
        }
        filters[0].groups[group_index].filters[filter_index].compare = compare;

        this.setState({ filters, panel_search_id: '', panelSearch: null }, () => {
            this.handleClickFilter();
        });
    }

    handleUpdateFilterValue(group_index, filter_index, new_values) {
        //console.log('handleUpdateFilterValue', filter_index, value_index, value)
        console.log('new_values', new_values);
        const filters = cloneDeep(this.state.filters);
        if (!this.checkIfGroupAndFilterExist(filters, group_index, filter_index)) {
            return;
        }

        // reset (in case some were deleted)
        filters[0].groups[group_index].filters[filter_index].values = [];

        if (new_values && new_values.length) {
            new_values.forEach((new_val, new_val_index) => {
                filters[0].groups[group_index].filters[filter_index].values[new_val_index] = new_val;
            });
        }

        console.log('handleUpdateFilterValue filters', filters);

        this.setState({ filters, panel_search_id: '', panelSearch: null }, () => {
            this.handleClickFilter();
        });
    }

    updateFiltersV2ToV3(filtersV2) {
        let filtersV3 = cloneDeep(DEFAULT_FILTERS);

        // each "v2" filter becomes its own group
        filtersV2.forEach(filter => {
            filtersV3[0].groups.push({
                and_or: 'and',
                filters: [filter]
            });
        });

        return filtersV3;
    }

    updateUrlState() {
        const { filters } = this.state;
        filters.forEach(f => {
            if (f.isOpen) {
                // eslint-disable-next-line no-param-reassign
                delete f.isOpen;
            }
        });
        // v3

        if (filters && filters[0] && filters[0].groups) {
            filters[0].groups.forEach(group => {
                if (group && group.filters) {
                    group.filters.forEach(f => {
                        if (f.isOpen) {
                            // eslint-disable-next-line no-param-reassign
                            delete f.isOpen;
                        }
                    });
                }
            });
        }

        let urlState = `filters=${encodeURIComponent(JSON.stringify(filters))}&showFatigued=${
            this.state.showFatigued
        }&panelIdSelected=${this.state.panelIdSelected}&dataSortColumnId=${
            this.state.dataSortColumnId
        }&dataSortDirection=${this.state.dataSortDirection}`;

        // if we're using numbered pagination, we need to add the page and pageSize to the URL
        // when updating the URL state it means we're changing the filters, so we should reset the page to 1
        if (FEATURE_CONFIG.PAGINATION_TYPE === PAGINATION_TYPE.NUMBERED) {
            urlState += `&page=1&pageSize=${this.props.pagination.pageSize}`;
        }

        store.set(this.getFiltersCacheKey(), urlState);

        this.props.history.replace(`/panel?${urlState}`);
    }

    handleClickFilter() {
        const { pagination } = this.state;
        pagination.current_page = 1;
        this.setState({ pagination }, () => {
            this.updateUrlState();
            this.getParticipantsXHR();
        });
        this.setState({ checkAllOrVisibleStatus: 'none' });
    }

    handleClickAddFilter() {
        this.setState({ showAddFilterDropdown: true });
    }

    handleFiltersParentChangeAndOr(newVal) {
        let { filters } = this.state;
        filters[0].and_or = newVal;
        this.setState({ filters }, () => {
            this.handleClickFilter();
        });
    }

    handleFiltersGroupChangeAndOr(group_index, newVal) {
        let { filters } = this.state;
        filters[0].groups[group_index].and_or = newVal;
        this.setState({ filters }, () => {
            this.handleClickFilter();
        });
    }

    handleAddFilterCreate(id, group_index) {
        let { filters } = this.state;
        if (!filters || filters.length == 0) {
            filters = [
                {
                    and_or: 'and',
                    groups: []
                }
            ];
        }

        const newFilter = {
            id,
            compare: 'contains',
            values: [],
            isOpen: true
        };

        // added to a group
        if (group_index !== undefined) {
            filters[0].groups[group_index].filters.push(newFilter);
        } else {
            // create a new filters group
            filters[0].groups.push({
                and_or: 'and',
                filters: [newFilter]
            });
        }
        //filters.push();
        this.setState({ filters });
    }

    toggleCheckbox(e, index) {
        const { checkbox_checked } = this.state;
        checkbox_checked[index] = e.target.checked;

        let checkAllOrVisibleStatus = 'visible';

        if (this.getCheckedPeople().length === 0 && !e.target.checked) {
            checkAllOrVisibleStatus = 'none';
            console.log('none????');
        }

        this.setState({ checkbox_checked, checkAllOrVisibleStatus });
    }

    /**
     * Open the delete panelists modal and set checkedCount. If no panelists are checked, check all.
     */
    onDeletePanelistsModalOpen() {
        const checkedCount = this.getCheckedCount();

        // if checked panelists are 0, then we need to check all
        if (!checkedCount) {
            return this.setState({ checkAllOrVisibleStatus: 'all' }, () => {
                this.onDeletePanelistsModalOpen();
            });
        }

        this.setState({ isDeletePanelistsModalOpen: true, checkedCount });
    }

    /**
     * Close the delete panelists modal.
     */
    onDeletePanelistsModalClose() {
        this.setState({ isDeletePanelistsModalOpen: false });
    }

    async deleteParticipants() {
        const { toastManager } = this.props;

        const checkedIds = this.getCheckedPeople();

        let payload = {};

        payload.account_id = this.props.auth.account.id;

        if (this.state.checkAllOrVisibleStatus == 'visible') {
            payload.people_ids = checkedIds.join(',');
        }

        toastManager.add('Checking bulk delete...', {
            appearance: 'info',
            autoDismiss: true
        });

        const qs_as_object = this.generatePeopleFilteringQueryString({ as_object: true });
        payload = { ...qs_as_object, ...payload };
        payload.all = this.state.checkAllOrVisibleStatus === 'all' && this.state.filters.length === 0 ? 1 : 0;

        xhr.delete(`/people`, {
            withCredentials: true,
            data: payload
        })
            .then(() => {
                this.getParticipantsXHR();
                this.setState({ checkAllOrVisibleStatus: 'none' });
                toastManager.add('Bulk delete has been started. You do not need to stay on this page.', {
                    appearance: 'success',
                    autoDismiss: false
                });
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);
                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: false
                });
            });
    }

    async onBulkEditParticipants(e) {
        e.preventDefault();

        const { toastManager } = this.props;
        let checkedIds;
        const people = [];
        const checkedCount = await this.getCheckedCount();

        if (this.state.checkAllOrVisibleStatus == 'visible') {
            checkedIds = this.getCheckedPeople();
            if (checkedIds.length === 0) {
                alert('You have 0 people checked.');
                return;
            }

            checkedIds.forEach(person_id => {
                people.push({ id: person_id });
            });
        }

        const bulk_edit_column_id = this.state.bulk_edit_column.id;
        const bulk_edit_column_value = this.state.bulk_edit_value;

        if (
            // eslint-disable-next-line no-restricted-globals
            !confirm(`Are you sure you want to update ${checkedCount} ${checkedCount === 1 ? 'person' : 'people'}?`)
        ) {
            return;
        }

        const xhr_options = {
            bulk_edit_column_id: bulk_edit_column_id,
            bulk_edit_column_value: bulk_edit_column_value
        };
        if (this.state.checkAllOrVisibleStatus == 'visible') {
            xhr_options.people_ids = checkedIds.join(',');
        } else if (this.state.filters.length === 0 && this.state.checkAllOrVisibleStatus == 'all') {
            xhr_options.all = 1;
        }
        xhr_options.filters_query_string = this.generatePeopleFilteringQueryString();

        toastManager.add('Starting bulk edit', {
            appearance: 'info',
            autoDismiss: true
        });

        this.setState({ onBulkEditParticipants_loading: true });

        service_people
            .bulkEditPeople(xhr_options)
            .then(() => {
                this.getParticipantsXHR();
                this.setState({
                    checkAllOrVisibleStatus: 'none',
                    showModalBulkEdit: false,
                    onBulkEditParticipants_loading: false,
                    bulk_edit_column: {},
                    bulk_edit_value: ''
                });
                toastManager.add('Successfully edited.', {
                    appearance: 'success',
                    autoDismiss: true
                });
            })
            .catch(error => {
                this.setState({ onBulkEditParticipants_loading: false });
                const errorText = services.parseAndTrackXhrErrors(error);
                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    onSearchKeywordChange(kw, onChange) {
        const that = this;

        // eslint-disable-next-line react/no-unused-state
        this.setState({ search_kw: kw }, () => {
            // detect change, and clear timeout if exists
            if (that.saveDelay_search_kw) {
                clearTimeout(that.saveDelay_search_kw);
            }

            // only search if there are 2 letters (query is really expensive with 1)
            if (kw.length >= 2 || kw.length == 0) {
                that.saveDelay_search_kw = setTimeout(() => {
                    onChange && onChange();
                }, 500);
            }
        });
    }

    getStudiesXHR() {
        const { toastManager } = this.props;

        study_services
            .getStudiesSimple()
            .then(studies_response => {
                this.setState({
                    studies: studies_response,
                    showModalAddPeopleToStudy_loading: false
                });
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);

                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    async getPanelsXHR() {
        const { toastManager } = this.props;
        const isAllPanelsSelected = this.state.panelIdSelected === '';
        // We update the panelIdSelected when all panels are selected, user is not an admin, and no segment is selected
        const shouldUpdatePanelId = isAllPanelsSelected && !this.isAdmin && !this.state.panel_search_id;

        try {
            const panels = await service_people.getPanels();

            /**
             * @type {Partial<typeof this.state>}
             */
            const state = {
                panels,
                panelsLoaded: true,
                showModalAddRemoveFromPanel_loading: false
            };

            // if all panels option is selected, and user is not an admin, select the first panel
            // it will allow to show panel dropdown
            if (shouldUpdatePanelId) {
                this.onChangeSelectPanelDropdown(panels[0].id);
            }

            this.setState(state);
        } catch (error) {
            const errorText = services.parseAndTrackXhrErrors(error);
            toastManager.add(errorText, {
                appearance: 'error',
                autoDismiss: true
            });
        }
    }

    componentDidMount() {
        const { toastManager } = this.props;

        this.getAvailableColumnsXHR();
        this.getPersonTagsXHR();
        this.getStudyTagsXHR();
        this.getStudiesXHR();
        this.getPanelsXHR();

        if (this.state.panel_search_id) {
            services
                .getPanelSearch(this.state.panel_search_id)
                .then(panelSearch => {
                    let filters = panelSearch.url_state.filters;
                    if (!this.isFiltersV3Format(filters)) {
                        filters = this.updateFiltersV2ToV3(filters);
                    }

                    this.setState({
                        panelSearch,
                        filters: filters,
                        showFatigued: panelSearch.url_state.showFatigued,
                        panelIdSelected: panelSearch.url_state.panelIdSelected
                        // don't set these 2 yet, there are some edge cases with the API
                        // not being able to sort by people who have an empty custom value
                        // dataSortColumnId: panelSearch.url_state.dataSortColumnId,
                        // dataSortDirection: panelSearch.url_state.dataSortDirection
                    });
                })
                .catch(error => {
                    const errorText = services.parseAndTrackXhrErrors(error);

                    toastManager.add(errorText, {
                        appearance: 'error',
                        autoDismiss: true
                    });
                });
        }

        const { filters, dataSortColumnId, dataSortDirection } = this.state;
        //console.log('this.props.location.pathname',this.props.location.pathname)
        if (
            filters.length === 0 &&
            !dataSortColumnId &&
            !dataSortDirection &&
            this.props.location.pathname === '/panel'
        ) {
            console.log('getting filters from storage');
            this.getParticipantsWithLocalstoredFilterData();
        } else {
            console.log('normal filters xhr');
            this.getParticipantsXHR();
        }
    }

    getFiltersCacheKey() {
        return CACHE_KEY_FILTER_QUERY + '_' + this.props.auth.account.id;
    }

    getParticipantsWithLocalstoredFilterData() {
        const filterQuery = store.get(this.getFiltersCacheKey()) || '';
        const {
            filters,
            showFatigued,
            panelIdSelected,
            dataSortColumnId,
            dataSortDirection
        } = this.getFilterDataFromQuery(filterQuery);

        this.setState(
            {
                filters,
                showFatigued,
                panelIdSelected,
                dataSortColumnId,
                dataSortDirection
            },
            () => {
                this.updateUrlState();
                this.getParticipantsXHR();
            }
        );
    }

    setSort(dataSortColumnId, dataSortDirection) {
        let direction = dataSortDirection;

        // flip direction if new title is the same as state title
        if (dataSortColumnId == this.state.dataSortColumnId) {
            direction = this.state.dataSortDirection == 'asc' ? 'desc' : 'asc';
        }

        this.setState(
            {
                dataSortColumnId,
                dataSortDirection: direction
            },
            () => {
                this.updateUrlState();
                this.getParticipantsXHR();
            }
        );
    }

    handleLoadMore() {
        const { pagination } = this.state;
        pagination.current_page += 1;
        this.setState({ pagination, checkAllOrVisibleStatus: 'none', loading_more: true }, () => {
            this.getParticipantsXHR();
        });
    }

    /**
     * Handle page change. This function is called when the user clicks on the pagination buttons.
     * Supported by numbered pagination.
     *
     * @param {number} page - the page number
     */
    onPageChange(page) {
        this.setState({ checkAllOrVisibleStatus: 'none' }, () => {
            this.props.pagination.changePage(page);
            this.getParticipantsXHR();
        });
    }

    generatePeopleFilteringQueryString(options) {
        options = options || {};
        const export_csv = options && options.export_csv == true ? true : '';

        // only set the custom count
        const custom_count_number =
            this.state.checkAllOrVisibleStatus === 'custom_count' ? this.state.checkAll_custom_count_number : null;
        const randomize =
            this.state.checkAllOrVisibleStatus === 'custom_count' && this.state.checkAll_randomize ? 1 : 0;
        const ignore_fatigue = this.state.showFatigued ? 1 : 0;

        let url_object = {};
        url_object.account_id = this.props.auth.account.id;
        url_object.page = this.state.pagination.current_page;
        url_object.export_csv = export_csv;
        url_object.custom_count_number = custom_count_number;
        url_object.randomize = randomize;
        url_object.ignore_fatigue = ignore_fatigue;
        // improve performance
        url_object.without_cdk = 1;
        url_object.panel_id = this.state.panelIdSelected ? this.state.panelIdSelected : '';

        if (this.state.panel_search_id > 0) {
            url_object.panel_search_id = this.state.panel_search_id;
            url_object.dataSortColumnId = this.state.dataSortColumnId;
            url_object.dataSortDirection = this.state.dataSortDirection;
        } else {
            url_object.dataSortColumnId = this.state.dataSortColumnId;
            url_object.dataSortDirection = this.state.dataSortDirection;
            const filters_for_url = this.state.filters;
            //console.log('filters_for_url', filters_for_url);
            url_object.filters = options.as_object
                ? this.state.filters
                : encodeURIComponent(JSON.stringify(this.state.filters));
        }

        if (this.state.pagination_limit) {
            url_object.pagination_limit = this.state.pagination_limit;
        }

        if (FEATURE_CONFIG.PAGINATION_TYPE === PAGINATION_TYPE.NUMBERED) {
            url_object.pagination_limit = this.props.pagination.pageSize;
            url_object.page = this.props.pagination.page;
        }

        if (this.state.search_kw) {
            url_object.search_kw = this.state.search_kw;
        }

        if (options.as_object) {
            return url_object;
        } else {
            let url_string = '';
            Object.keys(url_object).forEach(key => {
                url_string += `&${key}=${url_object[key]}`;
            });
            return url_string;
        }
    }

    getParticipantsXHR(options) {
        // panel with empty id is 'All Panelists' and only admins can see it
        // that's why we need to check if the panelIdSelected is empty and the user is not an admin
        // when it happens, we should clear the people list and don't make the xhr request
        // we can omit this only in the case of searching by panel_search_id (segment) because segment has panel id and we wanna show the results
        if (
            this.props.auth.account_role.type !== 'admin' &&
            this.state.panelIdSelected === '' &&
            !this.state.panel_search_id
        ) {
            return this.setState({ people: [], loading: false, loading_more: false });
        }

        //console.log('CALLING.. getParticipantsXHR');
        this.setState({ loading: true });

        const { toastManager } = this.props;
        const export_csv = options && options.export_csv == true ? true : '';

        if (this.state.filters.length > 0) {
            let filtersValid = this.checkFilterValidity(this.state.filters);
            if (!filtersValid) {
                toastManager.add('Filters are missing data', {
                    appearance: 'error',
                    autoDismiss: true
                });
                return;
            }
        }
        let url = `/people2?${this.generatePeopleFilteringQueryString(options)}`;

        if (export_csv) {
            if (options.exportCsvCustomColumnsIncludedOrdered) {
                url += `&exportCsvCustomColumnsIncludedOrdered=${JSON.stringify(
                    options.exportCsvCustomColumnsIncludedOrdered
                )}`;
            }

            xhr.get(url, { withCredentials: true })
                .then(() => {
                    toastManager.add('CSV export started, you will be emailed a download link.', {
                        appearance: 'success',
                        autoDismiss: false
                    });
                })
                .catch(error => {
                    const errorText = services.parseAndTrackXhrErrors(error);

                    toastManager.add(errorText, {
                        appearance: 'error',
                        autoDismiss: true
                    });
                })
                .finally(() => {
                    this.setState({
                        loading: false,
                        loading_more: false,
                        showModalExportCsv: false
                    });
                });
        } else {
            // detect change, and clear timeout if exists
            if (this.getParticipantsDelay) {
                clearTimeout(this.getParticipantsDelay);
            }

            // set timer for 200ms
            this.getParticipantsDelay = setTimeout(() => {
                const currentGetParticipantsPromise = xhr.get(url, { withCredentials: true });

                this.lastGetParticipantsPromise.current = currentGetParticipantsPromise;

                currentGetParticipantsPromise
                    .then(async res => {
                        if (currentGetParticipantsPromise !== this.lastGetParticipantsPromise.current) {
                            return;
                        }

                        const xhr_response = res.data;
                        const current_people = this.state.people;
                        let people = [];

                        if (FEATURE_CONFIG.PAGINATION_TYPE === PAGINATION_TYPE.NUMBERED) {
                            people = xhr_response.data;
                        }

                        if (FEATURE_CONFIG.PAGINATION_TYPE === PAGINATION_TYPE.LOAD_MORE_BUTTON) {
                            if (this.state.pagination.current_page > 1) {
                                people = people.concat(current_people).concat(xhr_response.data);
                            } else {
                                people = xhr_response.data;
                            }
                        }

                        this.setState({
                            people,
                            loading: false,
                            loading_more: false,
                            checkbox_checked: [],
                            pagination: {
                                links: xhr_response.links,
                                from: xhr_response.from,
                                to: xhr_response.to,
                                total: xhr_response.total,
                                current_page: xhr_response.current_page,
                                last_page: xhr_response.last_page,
                                per_page: xhr_response.per_page
                            }
                        });
                    })
                    .catch(error => {
                        const errorText = services.parseAndTrackXhrErrors(error);

                        toastManager.add(errorText, {
                            appearance: 'error',
                            autoDismiss: true
                        });
                    });
            }, 300);
        }
    }

    getPersonTagsXHR() {
        const that = this;
        services.getPersonTags(this.props.auth.account.id, (err, tags) => {
            if (err) {
                alert('There was an error please try again.');
            } else {
                that.setState({ tags });
            }
        });
    }

    getStudyTagsXHR() {
        study_services
            .getStudyTags()
            .then(study_tags => {
                this.setState({ study_tags: study_tags });
            })
            .catch(error => {
                services.parseAndTrackXhrErrors(error);
            });
    }

    renderPagination() {
        const pages = [];
        for (let i = 1; i <= this.state.pagination.last_page; i++) {
            pages.push(<Button variant="transparent">{i}</Button>);
        }
        return pages;
    }

    handleCloseModalAddPeopleToStudy() {
        this.setState({ showModalAddPeopleToStudy: false });
    }

    getCheckedPeople() {
        const checked_ids = [];
        this.state.checkbox_checked.forEach((value, id) => {
            if (value == true) {
                checked_ids.push(id);
            }
        });

        return checked_ids;
    }

    getCheckedCount() {
        let count = 0;

        if (this.state.checkAllOrVisibleStatus === 'all') {
            count = this.state.pagination.total;
        } else if (this.state.checkAllOrVisibleStatus === 'custom_count') {
            count = this.state.checkAll_custom_count_number;
            console.log('custom count!!!', count);
        } else {
            const visibleCheckedCount = this.getCheckedPeople().length;
            count = visibleCheckedCount;
        }

        return count;
    }

    deselectAll() {
        this.setState({
            checkAllOrVisibleStatus: 'none',
            checkbox_checked: []
        });
    }

    async handleOpenModalBulkEdit() {
        const checkedCount = await this.getCheckedCount();
        if (!checkedCount) {
            this.setState({ checkAllOrVisibleStatus: 'all' }, () => {
                this.handleOpenModalBulkEdit();
            });
            return;
        }

        // modal depends on checkedCount for its title so set its state first
        this.setState({ checkedCount }, () => {
            this.setState({
                showModalBulkEdit: true
            });
        });
    }

    async handleOpenModalAddPeopleToStudy() {
        const checkedCount = await this.getCheckedCount();
        if (!checkedCount) {
            this.setState({ checkAllOrVisibleStatus: 'all' }, () => {
                this.handleOpenModalAddPeopleToStudy();
            });
            return;
        }

        // modal depends on checkedCount for its title so set its state first
        this.setState({ checkedCount }, () => {
            this.setState({
                showModalAddPeopleToStudy: true
            });
        });
    }

    async handleOpenModalAddTag() {
        const checkedCount = await this.getCheckedCount();
        if (!checkedCount) {
            this.setState({ checkAllOrVisibleStatus: 'all' }, () => {
                this.handleOpenModalAddTag();
            });
            return;
        }

        // modal depends on checkedCount for its title so set its state first
        this.setState({ checkedCount }, () => {
            this.setState({
                showModalAddTag: true,
                showModalAddTag_loading: true
            });
        });

        const that = this;

        services.getPersonTags(this.props.auth.account.id, (err, tags) => {
            if (err) {
                alert('There was an error please try again.');
            } else {
                that.setState({ tags, showModalAddTag_loading: false });
            }
        });
    }

    handleCloseModalAddTag() {
        this.setState({ showModalAddTag: false });
    }

    async handleOpenModalAddRemoveFromPanel() {
        const checkedCount = await this.getCheckedCount();
        if (!checkedCount) {
            this.setState({ checkAllOrVisibleStatus: 'all' }, () => {
                this.handleOpenModalAddRemoveFromPanel();
            });
            return;
        }

        // modal depends on checkedCount for its title so set its state first
        this.setState({ checkedCount }, () => {
            this.setState({
                showModalAddRemoveFromPanel: true
            });
        });
    }

    handleCloseModalAddRemoveFromPanel() {
        this.setState({ showModalAddRemoveFromPanel: false });
    }

    onSubmitAddPeopleToStudy(studyId) {
        const { toastManager } = this.props;

        let checkedIds;
        let payload = {};
        const people_ids = [];

        if (this.state.checkAllOrVisibleStatus == 'visible') {
            checkedIds = this.getCheckedPeople();
            if (checkedIds.length === 0) {
                alert('You have 0 people checked.');
                return;
            }

            checkedIds.forEach(person_id => {
                people_ids.push({ id: person_id });
            });

            payload = { people_ids };
        }

        this.setState({ showModalAddPeopleToStudy_loading: true });

        const qs_as_object = this.generatePeopleFilteringQueryString({ as_object: true });
        payload = { ...qs_as_object, ...payload };
        payload.all = this.state.checkAllOrVisibleStatus === 'all' && this.state.filters.length === 0 ? 1 : 0;

        services
            .addPeopleToStudy(studyId, payload)
            .then(() => {
                toastManager.add(
                    <ToastSuccess newCount={helpers.numberFormat(this.state.checkedCount)} studyId={studyId} />,
                    {
                        appearance: 'success',
                        big: true,
                        placement: 'bottom-center'
                    }
                );

                this.setState({ showModalAddPeopleToStudy: false });
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);

                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: false
                });
            })
            .finally(() => this.setState({ showModalAddPeopleToStudy_loading: false }));
    }

    onSubmitAddTag(tagId, remove) {
        const { toastManager } = this.props;
        let checkedIds;
        let payload = {};
        let people_ids = [];

        if (this.state.checkAllOrVisibleStatus == 'visible') {
            checkedIds = this.getCheckedPeople();
            if (checkedIds.length === 0) {
                alert('You have 0 people checked.');
                return;
            }

            checkedIds.forEach(person_id => {
                people_ids.push({ id: person_id });
            });

            payload = { people_ids };
        }

        if (remove) {
            payload.remove = true;
        }

        toastManager.add('Tagging started', {
            appearance: 'info',
            autoDismiss: true
        });

        const qs_as_object = this.generatePeopleFilteringQueryString({ as_object: true });
        payload = { ...qs_as_object, ...payload };
        payload.all = this.state.checkAllOrVisibleStatus === 'all' && this.state.filters.length === 0 ? 1 : 0;
        //console.log(qs_as_object);
        xhr.post(`/tags/${tagId}/people`, payload, {
            withCredentials: true
        })
            .then(() => {
                let toastMessage;
                if (remove) {
                    toastMessage = 'Tag removed successfully';
                    this.getParticipantsXHR();
                } else {
                    toastMessage = 'Tag added successfully';
                }
                toastManager.add(toastMessage, {
                    appearance: 'success',
                    autoDismiss: true
                });

                this.setState({
                    checkbox_checked: [],
                    checkAllOrVisibleStatus: 'none',
                    showModalAddTag: false
                });
            })
            .catch(err => {
                services.parseAndTrackXhrErrors(err);
                toastManager.add('There was an error tagging', {
                    appearance: 'error',
                    autoDismiss: false
                });
            });
    }

    onSubmitAddRemoveFromPanel(panelId, remove) {
        const { toastManager } = this.props;
        let checkedIds;
        let payload = {};
        let people_ids = [];

        if (this.state.checkAllOrVisibleStatus == 'visible') {
            checkedIds = this.getCheckedPeople();
            if (checkedIds.length === 0) {
                alert('You have 0 people checked.');
                return;
            }

            checkedIds.forEach(person_id => {
                people_ids.push({ id: person_id });
            });

            payload = { people_ids };
        }

        if (remove) {
            payload.remove = true;
        }

        toastManager.add((remove ? 'Removing from' : 'Adding to') + ' panel started', {
            appearance: 'info',
            autoDismiss: true
        });

        const qs_as_object = this.generatePeopleFilteringQueryString({ as_object: true });
        payload = { ...qs_as_object, ...payload };
        payload.all = this.state.checkAllOrVisibleStatus === 'all' && this.state.filters.length === 0 ? 1 : 0;
        //console.log(qs_as_object);
        xhr.post(`/panels/${panelId}/people`, payload, {
            withCredentials: true
        })
            .then(() => {
                toastManager.add((remove ? 'Panelists removed from' : 'Panelists added to') + ' panel successfully', {
                    appearance: 'success',
                    autoDismiss: true
                });
                this.setState({
                    checkbox_checked: [],
                    checkAllOrVisibleStatus: 'none',
                    showModalAddRemoveFromPanel: false
                });
                this.getParticipantsXHR();
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);
                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: false
                });
            });
    }

    onSubmitCreateStudyAddPeople(keyword) {
        const { toastManager } = this.props;
        let checkedIds;
        const people_ids = [];
        let study_payload = {};

        if (this.state.checkAllOrVisibleStatus == 'visible') {
            checkedIds = this.getCheckedPeople();
            if (checkedIds.length === 0) {
                alert('You have 0 people checked.');
                return;
            }

            checkedIds.forEach(person_id => {
                people_ids.push({ id: person_id });
            });

            study_payload = {
                title: keyword,
                people_ids
            };
        } else {
            study_payload = { title: keyword };
        }

        this.setState({ showModalAddPeopleToStudy_loading: true });

        const qs_as_object = this.generatePeopleFilteringQueryString({ as_object: true });
        study_payload = { ...qs_as_object, ...study_payload };
        study_payload.all = this.state.checkAllOrVisibleStatus === 'all' && this.state.filters.length === 0 ? 1 : 0;

        services
            .createStudyAddPeople(study_payload)
            .then(study => {
                toastManager.add(
                    <ToastSuccess newCount={study.people_counts.new} studyId={study.id} studyName={study.title} />,
                    {
                        appearance: 'success',
                        big: true
                    }
                );

                this.setState({ showModalAddPeopleToStudy: false });
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);
                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: false
                });
            })
            .finally(() => this.setState({ showModalAddPeopleToStudy_loading: false }));
    }

    onSubmitCreateAddTag(title) {
        const { toastManager } = this.props;

        const tag = {
            title,
            account_id: this.props.auth.account.id,
            type: 'Person'
        };

        xhr.post(`/tags`, tag, { withCredentials: true }).then(response => {
            toastManager.add('Tag created', {
                appearance: 'success',
                autoDismiss: true
            });

            const t = response.data;
            this.onSubmitAddTag(t.id);
            this.getPersonTagsXHR();
        });
    }

    onSubmitCreateAddRemoveFromPanel(title) {
        const { toastManager } = this.props;
        // set a default RBAC value
        const rbac_restricted = 0;
        service_people
            .createPanel(title, rbac_restricted)
            .then(new_panel => {
                this.onSubmitAddRemoveFromPanel(new_panel.id);
                this.getPanelsXHR();
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);
                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    onBulkEditValueChange(e) {
        const customDataKeyId = e.target.name;
        let customDataValue;
        if (e.target.type == 'checkbox') {
            customDataValue = helpers.getCheckboxValuesAsArray(customDataKeyId);
        } else {
            customDataValue = e.target.value;
        }
        this.setState({ bulk_edit_value: customDataValue });
    }

    async handleCheckAllDropdownChange(checkAllOrVisibleStatus, checkAll_custom_count_number, checkAll_randomize) {
        const checkboxes = document.getElementsByName('person_id');
        const checkbox_checked = [];

        for (let i = 0, n = checkboxes.length; i < n; i++) {
            if (checkAllOrVisibleStatus === 'none') {
                checkbox_checked[checkboxes[i].dataset.personid] = false;
            } else {
                checkbox_checked[checkboxes[i].dataset.personid] = true;
            }
        }

        this.setState({
            checkAllOrVisibleStatus,
            checkAll_custom_count_number: parseInt(checkAll_custom_count_number),
            checkAll_randomize,
            showCheckAllDropdown: false
        });

        // getCheckedCount() depends on checkbox_checked, so we wait for state to update
        // before calling it
        this.setState({ checkbox_checked }, async () => {
            if (checkAllOrVisibleStatus !== 'none') {
                const checkedCount = await this.getCheckedCount();
                this.setState({ checkedCount });
            }
        });
    }

    isFiltersV3Format(filters) {
        try {
            return filters && filters.length && filters[0] && filters[0]['and_or'] && filters[0]['groups'];
        } catch (e) {
            helpers.trackError(e);
        }
    }

    renderFilters(availableColumnsAndTags) {
        const filtersRender = [];

        // console.log(availableColumnsAndTags)

        // determine if its filters2 or filters3
        let filters = this.state.filters;

        //console.log(filters);

        // test if v3
        if (this.isFiltersV3Format(filters)) {
            // filters 3
            //filtersRender.push(<>filters 3</>);

            // some weird bug
            const group_andOr = cloneDeep(filters[0].and_or);
            //console.log('group_andOr', group_andOr, filters);
            const filter_groups = filters[0]['groups'];

            filter_groups.forEach((group, group_index) => {
                if (group && group.filters) {
                    //console.log(group_index, 'group_andOr', group_andOr);
                    let group_filters_andOr = group.and_or;
                    let groupFilters = [];
                    group.filters.forEach((filter, filter_index) => {
                        groupFilters.push(
                            <FilterConditional
                                // eslint-disable-next-line react/no-array-index-key
                                key={`${group_index}_${filter_index}`}
                                filter={filter}
                                group_index={group_index}
                                index={filter_index}
                                availableColumns={availableColumnsAndTags}
                                filterCompareOptions={this.state.filterCompareOptions}
                                tags={this.state.tags.concat(this.state.study_tags)}
                                studies={this.state.studies}
                                handleRemoveFilter={this.handleRemoveFilter}
                                handleUpdateFilterId={this.handleUpdateFilterId}
                                handleUpdateFilterCompare={this.handleUpdateFilterCompare}
                                handleUpdateFilterValue={this.handleUpdateFilterValue}
                            />
                        );

                        if (filter_index + 1 < group.filters.length) {
                            groupFilters.push(
                                <Button
                                    key={`${group_index}_${filter_index}_btn`}
                                    variant="link"
                                    px={2}
                                    className="muted"
                                    style={{ borderRadius: 0, fontWeight: 400 }}
                                    onClick={e => {
                                        let newAndOr = group_filters_andOr == 'and' ? 'or' : 'and';
                                        this.handleFiltersGroupChangeAndOr(group_index, newAndOr);
                                    }}
                                >
                                    <u>{group_filters_andOr}</u>
                                </Button>
                            );
                        }
                    });
                    groupFilters.push(
                        <Box sx={{ position: 'relative' }} key={group_index}>
                            <Button
                                variant="link"
                                className="muted hover-bg-gray"
                                mr={0}
                                sx={{
                                    padding: '6px 8px 6px 8px',
                                    borderLeft: '1px solid white',
                                    borderRadius: '0 4px 4px 0'
                                }}
                                onClick={e => {
                                    // add another filter to a group
                                    // this.handleAddFilterCreate('panelfox_tag', group_index);
                                    this.setState({ showAddFilterDropdownForGroup: group_index });
                                }}
                            >
                                <FiPlus style={{ strokeWidth: '1.5px', fontSize: '17px', margin: '2px 0 0 0' }} />
                            </Button>

                            {this.state.showAddFilterDropdownForGroup === group_index && (
                                <NiceDropdown
                                    showSearch
                                    adjustHeight
                                    onClose={() => {
                                        this.setState({
                                            showAddFilterDropdownForGroup: false
                                        });
                                    }}
                                    items={availableColumnsAndTags}
                                    onChange={value => {
                                        this.handleAddFilterCreate(value, group_index);
                                    }}
                                />
                            )}
                        </Box>
                    );

                    filtersRender.push(
                        <Flex key={group_index} sx={{ background: '#f7f7f7', borderRadius: '4px' }}>
                            {groupFilters}
                        </Flex>
                    );

                    if (group_index + 1 < filter_groups.length) {
                        //console.log('INSIDE.. group_andOr', group_andOr);
                        let group_andOr_text = group_andOr == 'and' ? 'and' : 'or';
                        //console.log('INSIDE..2 group_andOr_text', group_andOr_text);
                        //console.log('filters[0].and_or', filters[0].and_or);
                        filtersRender.push(
                            <Button
                                key={`${group_index}_btn`}
                                variant="link"
                                px={2}
                                mr={2}
                                className="muted"
                                sx={{ fontWeight: 400 }}
                                onClick={e => {
                                    let newAndOr = group_andOr == 'and' ? 'or' : 'and';
                                    this.handleFiltersParentChangeAndOr(newAndOr);
                                }}
                            >
                                <u>{group_andOr_text}</u>
                            </Button>
                        );
                    }
                }
            });

            return filtersRender;
        }

        filters.forEach((filter, index) => {
            filtersRender.push(
                <FilterConditional
                    // eslint-disable-next-line react/no-array-index-key
                    key={index}
                    filter={filter}
                    index={index}
                    availableColumns={availableColumnsAndTags}
                    filterCompareOptions={this.state.filterCompareOptions}
                    tags={this.state.tags.concat(this.state.study_tags)}
                    studies={this.state.studies}
                    handleRemoveFilter={this.handleRemoveFilter}
                    handleUpdateFilterId={this.handleUpdateFilterId}
                    handleUpdateFilterCompare={this.handleUpdateFilterCompare}
                    handleUpdateFilterValue={this.handleUpdateFilterValue}
                />
            );
        });

        return filtersRender;
    }

    renderTableHeader(title, column_id) {
        const addedStylesTitle = {
            marginRight: '4px'
        };

        let addedClasses = 'text-secondary';
        if (column_id == this.state.dataSortColumnId) {
            // addedStylesTitle.fontWeight = '600';
            addedClasses = 'text-primary';
        }

        const THWrapper = styled(Flex)`
            cursor: pointer;
            text-overflow: ellipsis;
            //overflow: hidden;
            white-space: nowrap;
            font-size: 12px;
        `;

        return (
            <THWrapper
                onClick={() => {
                    this.setSort(column_id, 'asc');
                }}
            >
                <span style={addedStylesTitle} className={`ellipsis ${addedClasses}`}>
                    {title}
                </span>

                <span
                    style={{
                        display: 'inline-block',
                        verticalAlign: 'top',
                        fontSize: '14px',
                        margin: '-2px 0 0 0'
                    }}
                    className="text-primary"
                >
                    {// eslint-disable-next-line no-nested-ternary
                    column_id == this.state.dataSortColumnId ? (
                        this.state.dataSortDirection == 'desc' ? (
                            <FiChevronDown />
                        ) : (
                            <FiChevronUp />
                        )
                    ) : (
                        ''
                    )}
                </span>
            </THWrapper>
        );
    }

    getColumnsOrder() {
        let cols_order = store.get(STORE_PREFIX_PANEL_COLUMNS_ORDER);

        if (!cols_order) {
            cols_order = DEFAULT_COLUMNS_ORDER;
        }

        return cols_order;
    }

    saveColumnsOrder(newOrderColumns) {
        this.setState(
            state => {
                const { availableColumns_showing } = state;

                // find first and last name columns
                const firstNameColumn = availableColumns_showing.find(column => column.title === COL_FIRST_NAME);
                const lastNameColumn = availableColumns_showing.find(column => column.title === COL_LAST_NAME);

                // add first and last name columns to the beginning of the array
                if (firstNameColumn) newOrderColumns.unshift(firstNameColumn);
                if (lastNameColumn) newOrderColumns.unshift(lastNameColumn);

                // update the order property of each column
                return { availableColumns_showing: newOrderColumns };
            },
            () => {
                this.updateColumnsXHR(newOrderColumns);
            }
        );
    }
    resetTableColumns() {
        // dont need both resets, just the update column one
        // this.saveColumnsOrder(DEFAULT_COLUMNS_ORDER);
        this.updateColumnsXHR(DEFAULT_COLUMNS_HIDDEN, () => {
            location.reload();
        });
    }
    tableColumnsOrdered() {
        const { availableColumns_showing, tableColumns_order } = this.state;

        /* this is now handled when order is updated and saved

        availableColumns_showing.forEach(tc => {
            // see if there are any ordered columns
            if (tableColumns_order && tableColumns_order[tc.id] !== undefined) {
                // set order, otherwise null
                tc.order = tableColumns_order[tc.id];
            } else {
                tc.order = undefined;
            }
        })*/

        availableColumns_showing.sort(function(a, b) {
            if (a[PROPERTY_TABLE_ORDER] === b[PROPERTY_TABLE_ORDER]) {
                return 0;
            } else {
                return a[PROPERTY_TABLE_ORDER] - b[PROPERTY_TABLE_ORDER];
            }
        });

        //console.log('availableColumns_showing', availableColumns_showing);

        return availableColumns_showing;
    }

    getRespondentPreviewPersonXHR(person_id) {
        services
            .getPerson(person_id)
            .then(person => {
                this.setState({ respondentPreviewPerson: person });
            })
            .catch(error => {
                helpers.trackError(error);
            });
    }

    onClickPreviewPanelist(panelist) {
        console.log('hi! onClickPreviewPanelist');
        this.setState({ showRespondentPreview: true, respondentPreviewPerson: {} });

        // xhr to get individual
        this.getRespondentPreviewPersonXHR(panelist.id);

        analytics.track('panel-preview_panelist');
    }

    areFiltersAdded() {
        return (
            this.state.filters &&
            this.state.filters[0] &&
            this.state.filters[0].groups &&
            this.state.filters[0].groups.length > 0
        );
    }

    onPersonPreviewTagAdd(tag_id, person_id) {
        const { toastManager } = this.props;

        xhr.post(
            `/tags/${tag_id}/people?account_id=${this.props.auth.account.id}`,
            { people_ids: { id: person_id } },
            {
                withCredentials: true
            }
        )
            .then(res => {
                this.setState({ showRespondentPreviewModalAddTag: false });
                this.getRespondentPreviewPersonXHR(person_id);

                toastManager.add('Tag added successfully', {
                    appearance: 'success',
                    autoDismiss: true
                });
            })
            .catch(err => {
                const errorText = services.parseAndTrackXhrErrors(err);
                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    onPersonPreviewTagDelete(tag_id, person_id) {
        const { toastManager } = this.props;

        xhr.delete(`/people/${person_id}/tags/${tag_id}?account_id=${this.props.auth.account.id}`, {
            withCredentials: true
        })
            .then(res => {
                this.getRespondentPreviewPersonXHR(person_id);

                toastManager.add('Tag deleted', {
                    appearance: 'success',
                    autoDismiss: true
                });
            })
            .catch(err => {
                const errorText = services.parseAndTrackXhrErrors(err);
                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    onPersonPreviewTagCreate(title, person_id) {
        const { toastManager } = this.props;

        let tag = {
            title,
            account_id: this.props.auth.account.id,
            type: 'Person'
        };

        xhr.post(`/tags`, tag, { withCredentials: true })
            .then(response => {
                toastManager.add('Tag created', {
                    appearance: 'success',
                    autoDismiss: true
                });

                tag = response.data;
                this.onPersonPreviewTagAdd(tag.id, person_id);
            })
            .catch(err => {
                const errorText = services.parseAndTrackXhrErrors(err);
                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    componentDidUpdate(prevProps) {
        // if pagination type is load more button, we dont need to do anything here
        if (FEATURE_CONFIG.PAGINATION_TYPE === PAGINATION_TYPE.LOAD_MORE_BUTTON) return;

        if (prevProps.pagination.page !== this.props.pagination.page) {
            this.getParticipantsXHR();
        }

        if (prevProps.pagination.pageSize !== this.props.pagination.pageSize) {
            this.getParticipantsXHR();
        }
    }

    render() {
        const all_columns = this.tableColumnsOrdered();
        const columnsToSwitch = all_columns.filter(column => ![COL_FIRST_NAME, COL_LAST_NAME].includes(column.title));
        const headers = [];
        const elements = [];
        const panel = this.state.panels.find(panel => panel.id === this.state.panelIdSelected);

        const firstNameColumn = all_columns.find(column => column.title === COL_FIRST_NAME);
        headers.push(
            <th
                key="full_name_header"
                style={{ position: 'sticky', left: 0, zIndex: 100 }}
                className="sticky-row-style"
            >
                <Box style={{ paddingLeft: '8px', position: 'relative' }}>
                    {this.state.checkAllOrVisibleStatus === 'none' && (
                        <Button
                            variant="transparent-icon-nobg"
                            mr={2}
                            style={{
                                position: 'absolute',
                                top: '-8px',
                                left: '-24px'
                            }}
                            onClick={() => {
                                const curr = this.state.showCheckAllDropdown;
                                this.setState({
                                    showCheckAllDropdown: !curr
                                });
                            }}
                        >
                            <FiCheckSquare />
                        </Button>
                    )}
                    {this.state.checkAllOrVisibleStatus !== 'none' && (
                        <Button
                            variant="small-icon"
                            mr={2}
                            style={{
                                position: 'absolute',
                                top: '0',
                                left: '-16px',
                                border: `1px solid ${this.props.theme.colors.amber}`,
                                color: `${this.props.theme.colors.amber}`
                            }}
                            onClick={() => {
                                this.deselectAll();
                            }}
                        >
                            <FiMinus />
                        </Button>
                    )}
                    {this.state.showCheckAllDropdown && (
                        <NiceDropdown
                            positionLeft="-14px"
                            positionTop="24px"
                            width="180px"
                            onClose={() => {
                                this.setState({
                                    showCheckAllDropdown: false
                                });
                            }}
                            items={[
                                {
                                    id: 'visible',
                                    title: 'Select visible'
                                },
                                {
                                    id: 'custom_count',
                                    title: 'Select count',
                                    feature_launched_at: moment('02-12-2022', 'MM-DD-YYYY')
                                },
                                {
                                    id: 'all',
                                    title: `Select all${this.state.filters.length ? ' results' : ''}`
                                }
                            ]}
                            onChange={value => {
                                if (value === 'custom_count') {
                                    this.setState({ showModalSelectCount: true });
                                } else {
                                    this.handleCheckAllDropdownChange(value);
                                }
                            }}
                        />
                    )}
                    {firstNameColumn ? this.renderTableHeader('Full Name', firstNameColumn.id) : 'Full Name'}
                </Box>
            </th>
        );

        all_columns.forEach(col => {
            if (!col.checked) return;

            headers.push(
                <th key={col.id} className="ellipsis">
                    <Box style={{ position: 'relative' }} className="th-inner-content">
                        {this.renderTableHeader(col.title, col.id)}
                    </Box>
                </th>
            );
        });

        this.state.people.forEach(person => {
            elements.push(
                /*<ItemWrapper key={person.id} style={{ position: 'relative', width: '100%' }}>*/
                <tr key={person.id} style={{ position: 'relative' }} onClick={e => helpers.clickTableRowAddStyle(e)}>
                    <ParticipantItem
                        key={person.id}
                        horizontal
                        participant={person}
                        availableColumns_showing={all_columns}
                        auth={this.props.auth}
                        checkAllOrVisibleStatus={this.state.checkAllOrVisibleStatus}
                        checkbox_checked={this.state.checkbox_checked}
                        onChange={e => {
                            this.toggleCheckbox(e, person.id);
                        }}
                        onClickPreviewPanelist={this.onClickPreviewPanelist}
                    />
                </tr>
            );
        });

        const isOnboarding =
            elements.length == 0 && !this.state.filters.length && !this.state.search_kw.length && !this.state.loading;

        const additionalColumns = [
            { id: 'panelfox_tag', title: PANEL_FILTER_TYPES['panelfox_tag'], type: 'tag' },
            { id: 'panelfox_tag_exclude', title: PANEL_FILTER_TYPES['panelfox_tag_exclude'], type: 'tag' },
            { id: 'panelfox_tag_study', title: PANEL_FILTER_TYPES['panelfox_tag_study'], type: 'tag' },
            { id: 'panelfox_tag_study_exclude', title: PANEL_FILTER_TYPES['panelfox_tag_study_exclude'], type: 'tag' },
            { id: 'panelfox_study_includes', title: 'Scheduled In Study', type: 'study' },
            { id: 'panelfox_study_excludes', title: 'Not Scheduled In Study', type: 'study' },
            {
                id: 'panelfox_study_added_includes',
                title: 'Added To Study',
                type: 'study',
                feature_launched_at: moment('12-20-2021', 'MM-DD-YYYY')
            },
            {
                id: 'panelfox_study_added_excludes',
                title: 'Not Added To Study',
                type: 'study',
                feature_launched_at: moment('12-20-2021', 'MM-DD-YYYY')
            },
            {
                id: 'email_status',
                title: PANEL_FILTER_TYPES['email_status'],
                type: 'email',
                feature_launched_at: moment('12-19-2021', 'MM-DD-YYYY')
            }
        ];

        const availableColumnsAndTags = additionalColumns.concat(this.state.availableColumns);
        //console.log(availableColumnsAndTags)

        const isFatigueOn =
            this.props.auth.account.settings.panelist_fatigue_email > 0 ||
            this.props.auth.account.settings.panelist_fatigue_scheduling > 0 ||
            this.props.auth.account.settings.panelist_fatigue_sms > 0;

        let respondentPreviewPersonTags = [];
        let respondentPreviewPersonTagIds = new Set();
        let respondentPreviewPersonTagSuggestions = [];
        if (this.state.showRespondentPreview && this.state.showRespondentPreview_tab === 'tags') {
            respondentPreviewPersonTags = this.state.respondentPreviewPerson.tags
                ? this.state.respondentPreviewPerson.tags.map(tag => {
                      return {
                          id: tag.tag.id,
                          title: tag.tag.title
                      };
                  })
                : [];
            respondentPreviewPersonTagIds = new Set(respondentPreviewPersonTags.map(t => t.id));
            respondentPreviewPersonTagSuggestions = this.state.tags.filter(
                tag => !respondentPreviewPersonTagIds.has(tag.id)
            );
        }

        return (
            <Flex style={{ width: '100%' }}>
                <AppPageWrapper>
                    <Helmet>
                        <title>Panel</title>
                    </Helmet>

                    <Flex>
                        {/*<H1>Panel</H1>
                        <Box mb={3}>
                            <small>
                                Grow your panel, find the right segment people, and manage their data.<br/>
                                <a href="/docs/panel/introduction" target="_blank"><FiBookOpen /> How to get started</a>
                            </small>
                        </Box>*/}
                        <ParticipantsPageSubNav />
                    </Flex>

                    {!isOnboarding ? (
                        <AppPageWrapperSectionSubHeader
                            style={{
                                background: '#fff',
                                padding: '0 32px 0',
                                margin: '0 0 0',
                                flexDirection: 'column'
                            }}
                        >
                            <Flex sx={{ width: '100%', justifyContent: 'space-between', margin: '0 0 16px 0' }}>
                                <Box mb={3}>
                                    <Flex>
                                        {this.state.panelsLoaded ? (
                                            <SelectPanelDropdown
                                                panels={this.state.panels}
                                                auth={this.props.auth}
                                                selectedId={this.state.panelIdSelected}
                                                onChange={this.onChangeSelectPanelDropdown}
                                                onCreatePanel={this.onCreatePanel}
                                            />
                                        ) : (
                                            <H1 className="experience-like-h1">
                                                <span style={{ visibility: 'hidden' }}>.</span>
                                            </H1>
                                        )}
                                    </Flex>

                                    <Flex sx={{ flexWrap: 'wrap', gap: 3 }}>
                                        {this.renderFilters(availableColumnsAndTags)}
                                        <Box
                                            style={{
                                                display: 'inline-block',
                                                position: 'relative'
                                            }}
                                        >
                                            <Button
                                                variant="primary"
                                                /*{this.state.filters.length || this.getCheckedPeople().length ? 'secondary':'primary'}*/ mr={
                                                    0
                                                }
                                                onClick={this.handleClickAddFilter}
                                            >
                                                <FiPlus />
                                                Add Filter
                                            </Button>
                                            {this.state.showAddFilterDropdown == true && (
                                                <NiceDropdown
                                                    width={`340px`}
                                                    showSearch
                                                    adjustHeight
                                                    onClose={() => {
                                                        this.setState({
                                                            showAddFilterDropdown: false
                                                        });
                                                    }}
                                                    items={availableColumnsAndTags}
                                                    onChange={value => {
                                                        this.handleAddFilterCreate(value);
                                                    }}
                                                />
                                            )}
                                            <Box style={{ position: 'relative', display: 'inline-block' }} ml={3}>
                                                <PanelSearchSelect
                                                    selectedId={this.state.panel_search_id}
                                                    onChange={this.onChangePanelSearch}
                                                    urlState={{
                                                        filters: this.state.filters,
                                                        showFatigued: this.state.showFatigued,
                                                        panelIdSelected: this.state.panelIdSelected,
                                                        dataSortColumnId: this.state.dataSortColumnId,
                                                        dataSortDirection: this.state.dataSortDirection
                                                    }}
                                                />
                                            </Box>
                                        </Box>
                                        {this.areFiltersAdded() == true && (
                                            <Box style={{ position: 'relative', display: 'inline-block' }}>
                                                <Button
                                                    type="button"
                                                    variant="secondary-gray"
                                                    onClick={() => {
                                                        this.setState({ showFilterOptions: true });
                                                    }}
                                                    style={{
                                                        padding: '8px'
                                                    }}
                                                >
                                                    <FiMoreHorizontal
                                                        style={{
                                                            margin: '0px'
                                                        }}
                                                    />
                                                </Button>

                                                {this.state.showFilterOptions == true && (
                                                    <NiceDropdown
                                                        onClose={() => this.setState({ showFilterOptions: false })}
                                                        items={[
                                                            {
                                                                id: 'clear',
                                                                title: 'Clear all',
                                                                icon: <FiTrash2 />,
                                                                color: 'red',
                                                                onClick: this.handleClearFilters
                                                            },
                                                            {
                                                                id: 'copy',
                                                                title: (
                                                                    <>
                                                                        Copy filters for API
                                                                        <Box
                                                                            style={{
                                                                                fontSize: 12,
                                                                                color: 'gray',
                                                                                lineHeight: '16px',
                                                                                marginLeft: '25px',
                                                                                overflow: 'hidden',
                                                                                textOverflow: 'ellipsis'
                                                                            }}
                                                                        >
                                                                            {JSON.stringify(this.state.filters)}
                                                                        </Box>
                                                                    </>
                                                                ),
                                                                icon: <FiCode />,
                                                                onClick: this.handleCopyFilters
                                                            }
                                                        ]}
                                                    />
                                                )}
                                            </Box>
                                        )}
                                    </Flex>
                                </Box>
                                <Box flexShrink={0}>
                                    {panel ? (
                                        <>
                                            <Button
                                                variant="transparent"
                                                as={Link}
                                                onClick={() =>
                                                    analytics.track('panelists-click-panel_dashboard_discovery')
                                                }
                                                data-tip="View dashboard"
                                                data-for="active-panelists-tooltip"
                                                style={{ padding: '5px 16px' }}
                                                to={`/panels/${panel.id}/dashboard`}
                                            >
                                                <span className="color-text-secondary" style={{ marginRight: '4px' }}>
                                                    Active panelists
                                                </span>
                                                <Box
                                                    as="span"
                                                    className="text-primary bold"
                                                    style={{ marginRight: '9px' }}
                                                >
                                                    {helpers.numberWithCommas(panel.active_dashboard.results)}
                                                </Box>
                                                <FiPieChart
                                                    className="color-primary"
                                                    style={{ margin: 0, verticalAlign: 'sub' }}
                                                />
                                            </Button>
                                            <Tooltip id="active-panelists-tooltip" effect="solid" place="bottom" />
                                        </>
                                    ) : (
                                        <Button
                                            variant="transparent"
                                            as={Link}
                                            style={{ padding: '5px 16px' }}
                                            to="/panels"
                                            onClick={() => analytics.track('panelists-click-panel_dashboard_discovery')}
                                        >
                                            <span className="color-text-secondary" style={{ marginRight: '9px' }}>
                                                View dashboards
                                            </span>
                                            <FiPieChart
                                                className="color-primary"
                                                style={{ margin: 0, verticalAlign: 'sub' }}
                                            />
                                        </Button>
                                    )}

                                    <div style={{ position: 'relative', display: 'inline-block' }}>
                                        <Button
                                            variant="secondary-gray"
                                            mr={0}
                                            onClick={() => this.setState({ showAddParticipantDropdown: true })}
                                        >
                                            <FiUsers /> New Panelists
                                            <FiChevronDown style={{ margin: '3px 0 0 4px' }} />
                                        </Button>
                                        <KeyPressHookWrapper
                                            actions={[
                                                { keyLabel: 'n', actionCB: this.handleOpenModalParticipantCreate },
                                                { keyLabel: 'u', actionCB: this.handleOpenModalParticipantImport }
                                            ]}
                                        />
                                        {this.state.showAddParticipantDropdown == true && (
                                            <NiceDropdown
                                                width={220}
                                                positionRight="0px"
                                                onClose={() => this.setState({ showAddParticipantDropdown: false })}
                                                items={[
                                                    {
                                                        title: 'Create new panelist',
                                                        icon: <FiPlus />,
                                                        suffix: <KeyboardShortcut keyLabel="N" theme="secondary" />,
                                                        onClick: this.handleOpenModalParticipantCreate
                                                    },
                                                    {
                                                        title: 'Upload panelists',
                                                        icon: <FiUploadCloud />,
                                                        suffix: <KeyboardShortcut keyLabel="U" theme="secondary" />,
                                                        onClick: this.handleOpenModalParticipantImport
                                                    }
                                                ]}
                                            />
                                        )}
                                    </div>
                                </Box>
                            </Flex>

                            <Flex
                                mt={2}
                                mb={3}
                                justifyContent="space-between"
                                style={
                                    this.state.loading == false || this.state.loading_more == true
                                        ? {}
                                        : { opacity: '0.5' }
                                }
                            >
                                <Box>
                                    <Box
                                        mr={3}
                                        style={{
                                            display: 'inline-block',
                                            marginTop: '4px',
                                            verticalAlign: 'top',
                                            color: 'black'
                                        }}
                                    >
                                        <span className="medium">
                                            Found {helpers.numberFormat(this.state.pagination.total)} panelists
                                        </span>
                                        <InformationTooltip
                                            id="tooltip-results"
                                            style={{ margin: '4px 0 0 6px', verticalAlign: 'top' }}
                                        >
                                            Results exclude fatigued panelists, opt-outs, bounces, spam complaints, and
                                            mis-formatted emails.
                                        </InformationTooltip>
                                    </Box>
                                    {elements.length > 0 && (
                                        <>
                                            <Button
                                                variant="secondary-gray"
                                                //{this.getCheckedPeople().length || this.state.filters.length ? 'primary':'secondary-gray'}
                                                mr={3}
                                                onClick={() => {
                                                    this.handleOpenModalAddPeopleToStudy();
                                                }}
                                                disabled={!this.getCheckedPeople().length && !this.state.filters.length}
                                            >
                                                <FiLayers /> Add To Study
                                            </Button>

                                            <div
                                                style={{
                                                    display: 'inline-block',
                                                    position: 'relative'
                                                }}
                                            >
                                                <Button
                                                    variant="secondary-gray"
                                                    mr={2}
                                                    onClick={() => {
                                                        this.setState({
                                                            showMoreDropdown: true
                                                        });
                                                    }}
                                                    disabled={
                                                        !this.getCheckedPeople().length && !this.state.filters.length
                                                    }
                                                >
                                                    Bulk Actions{' '}
                                                    <FiChevronDown
                                                        style={{
                                                            marginRight: 0
                                                        }}
                                                    />
                                                </Button>
                                                {this.state.showMoreDropdown == true && (
                                                    <NiceDropdown
                                                        onClose={() => {
                                                            this.setState({
                                                                showMoreDropdown: false
                                                            });
                                                        }}
                                                        items={[
                                                            {
                                                                id: 'edit',
                                                                title: 'Edit panelists',
                                                                icon: <FiEdit />
                                                            },
                                                            {
                                                                id: 'tag',
                                                                title: 'Tag panelists',
                                                                icon: <FiTag />
                                                            },
                                                            {
                                                                id: 'panel',
                                                                title: 'Add/Remove from panel',
                                                                icon: <FiLayers />
                                                            },
                                                            {
                                                                id: 'export',
                                                                title: 'Export to CSV',
                                                                disabled: !this.isAdmin,
                                                                icon: <FiDownloadCloud />
                                                            },
                                                            { id: 'divider', divider: true },
                                                            {
                                                                id: 'delete',
                                                                title: 'Delete from panel',
                                                                icon: <FiXCircle />,
                                                                color: 'red'
                                                            }
                                                        ]}
                                                        onChange={value => {
                                                            this.handleMoreDropdownChange(value);
                                                        }}
                                                        width="190px"
                                                    />
                                                )}
                                            </div>
                                        </>
                                    )}
                                    {isFatigueOn && (
                                        <div
                                            style={{
                                                display: 'inline-block',
                                                position: 'relative',
                                                marginLeft: '12px'
                                            }}
                                        >
                                            <Flex style={{ alignItems: 'center', gap: '10px' }}>
                                                <Box className="fs-accent-14 color-text-primary">Show fatigued</Box>
                                                <Switch
                                                    mt="2px"
                                                    className="switch-small"
                                                    name="show-fatigued"
                                                    checked={this.state.showFatigued}
                                                    onClick={() => {
                                                        this.setState(
                                                            prevState => ({
                                                                showFatigued: !prevState.showFatigued
                                                            }),
                                                            () => {
                                                                this.handleClickFilter();
                                                            }
                                                        );
                                                    }}
                                                />
                                            </Flex>
                                        </div>
                                    )}
                                </Box>
                                <Box sx={{ position: 'relative' }}>
                                    <Flex>
                                        <Box>
                                            <InputSearch
                                                mr={3}
                                                type="text"
                                                value={this.state.search_kw}
                                                onChange={event => {
                                                    // move to a variable so as not to lose the value of the event
                                                    const kw = event.target.value;
                                                    this.onSearchKeywordChange(kw, () => {
                                                        if (
                                                            FEATURE_CONFIG.PAGINATION_TYPE === PAGINATION_TYPE.NUMBERED
                                                        ) {
                                                            this.props.pagination.changePage(1);
                                                        }
                                                        this.getParticipantsXHR();
                                                    });
                                                }}
                                                placeholder="Search Name, Email, or Phone"
                                                sx={{ width: '250px' }}
                                            />
                                        </Box>
                                        <Box>
                                            <Button
                                                variant="secondary-gray"
                                                className="secondary-icon"
                                                onClick={() => this.setState({ showDisplayedCustomColumns: true })}
                                            >
                                                <FiColumns />
                                                <FiChevronDown style={{ marginRight: 0 }} />
                                            </Button>
                                            {this.state.showDisplayedCustomColumns && (
                                                <NiceDropdown
                                                    showSearch
                                                    adjustHeight
                                                    listType="toggle"
                                                    positionRight="0px"
                                                    height="290px"
                                                    width="410px"
                                                    onClose={() => {
                                                        if (this.state.showModalAddCustomColumn) return;
                                                        this.setState({
                                                            showDisplayedCustomColumns: false
                                                        });
                                                    }}
                                                    items={columnsToSwitch}
                                                    onChange={(columnId, isChecked) => {
                                                        this.handleDisplayedCustomColumns(columnId, isChecked);
                                                    }}
                                                    renderBottomStickyButton={
                                                        <Flex sx={{ width: '100%' }}>
                                                            <Button
                                                                variant="secondary"
                                                                className=""
                                                                onClick={this.handleOpenModalAddCustomColumn}
                                                                mr={3}
                                                                flex={1}
                                                            >
                                                                + Add Property
                                                            </Button>
                                                            <Button
                                                                variant="secondary"
                                                                onClick={this.resetTableColumns}
                                                                mr={0}
                                                                flex={1}
                                                            >
                                                                Reset Columns
                                                            </Button>
                                                        </Flex>
                                                    }
                                                    canReorder={true}
                                                    revealEllipsis={true}
                                                    onReorder={newOrderColumns => {
                                                        //console.log('newOrderColumns', newOrderColumns)
                                                        this.saveColumnsOrder(newOrderColumns);
                                                    }}
                                                />
                                            )}
                                        </Box>
                                    </Flex>
                                </Box>
                            </Flex>
                        </AppPageWrapperSectionSubHeader>
                    ) : (
                        <></>
                    )}
                    <AppPageWrapperSectionBody>
                        {this.state.loading && this.state.loading_more == false && (
                            <div
                                style={{
                                    pointerEvents: 'none',
                                    position: 'absolute',
                                    top: '46px',
                                    left: '0',
                                    right: 0,
                                    bottom: 0,
                                    background: 'rgba(0,0,0,.01)',
                                    zIndex: 100
                                }}
                            >
                                <div style={{ height: '300px' }}>
                                    <LoadingWrapper>
                                        <LoadingIndicator />
                                    </LoadingWrapper>
                                </div>
                            </div>
                        )}
                        <Box ml={4} mr={4} sx={{ width: '100%' }}>
                            <Flex
                                flexDirection="column"
                                style={{
                                    position: 'relative',
                                    width: '100%',
                                    height: `calc(100vh - ${
                                        FEATURE_CONFIG.PAGINATION_TYPE === PAGINATION_TYPE.NUMBERED ? 296 : 330
                                    }px)`,
                                    minHeight: '200px'
                                }}
                            >
                                {!isOnboarding && elements.length > 0 && (
                                    <>
                                        <ListWrapper
                                            style={{
                                                position: 'relative',
                                                fontSize: '14px',
                                                overflowX: 'scroll',
                                                marginBottom:
                                                    FEATURE_CONFIG.PAGINATION_TYPE === PAGINATION_TYPE.NUMBERED
                                                        ? '44px'
                                                        : undefined
                                            }}
                                        >
                                            <TableResizer tableNode={this.state.tableNode} dependencies={[headers]}>
                                                <table
                                                    className="table-main"
                                                    ref={node => {
                                                        if (!this.state.tableNode) {
                                                            this.setState({ tableNode: node });
                                                        }
                                                    }}
                                                >
                                                    <thead>
                                                        <tr>{headers}</tr>
                                                    </thead>
                                                    <tbody>{elements}</tbody>
                                                </table>
                                            </TableResizer>
                                        </ListWrapper>

                                        {FEATURE_CONFIG.PAGINATION_TYPE === PAGINATION_TYPE.NUMBERED && (
                                            <NumberedPagination
                                                from={this.state.pagination.from}
                                                to={this.state.pagination.to}
                                                links={this.state.pagination.links}
                                                total={this.state.pagination.total}
                                                perPage={this.props.pagination.pageSize}
                                                page={Number(this.props.pagination.page)}
                                                onPerPageChange={value =>
                                                    this.onChangePaginationLimit({ target: { value } })
                                                }
                                                onPageChange={page => this.onPageChange(page)}
                                                css={{
                                                    width: '100vw',
                                                    zIndex: 3,
                                                    position: 'fixed !important',
                                                    bottom: 0,
                                                    left: 0,
                                                    padding: '12px 32px !important'
                                                }}
                                            />
                                        )}
                                        {FEATURE_CONFIG.PAGINATION_TYPE === PAGINATION_TYPE.LOAD_MORE_BUTTON && (
                                            <Box sx={{ textAlign: 'left', paddingTop: '8px' }}>
                                                {this.state.pagination.current_page <
                                                    this.state.pagination.last_page && (
                                                    <Button
                                                        className="va-top"
                                                        variant="secondary-gray"
                                                        mr={3}
                                                        style={{ marginLeft: '1px' }}
                                                        onClick={() => {
                                                            this.handleLoadMore();
                                                        }}
                                                    >
                                                        {this.state.loading_more ? 'Loading...' : 'Load more'}
                                                    </Button>
                                                )}
                                                <span style={{ display: 'inline-block' }} className="va-top">
                                                    <Select
                                                        value={this.state.pagination_limit}
                                                        onChange={e => this.onChangePaginationLimit(e)}
                                                    >
                                                        <option value="20">Show 20 per page</option>
                                                        <option value="100">Show 100 per page</option>
                                                        <option value="500">Show 500 per page</option>
                                                    </Select>
                                                </span>
                                            </Box>
                                        )}
                                    </>
                                )}
                                {isOnboarding && (
                                    <NiceWrapper style={{ padding: '32px', margin: '32px 64px 32px 32px' }}>
                                        <H1>
                                            <FiSun
                                                style={{
                                                    color: '#0A73EB',
                                                    fontSize: '22px'
                                                }}
                                            />{' '}
                                            Single source of truth.
                                        </H1>

                                        <Box>
                                            Stay on top of your panel engagement, interviews, and incentives.
                                            <br />
                                            <br />
                                        </Box>

                                        <Button className="huge" onClick={this.handleOpenModalParticipantImport}>
                                            <FiUploadCloud style={{ marginRight: '8px' }} /> Upload panelists
                                        </Button>

                                        <Button
                                            ml={3}
                                            variant="secondary-gray"
                                            className="huge"
                                            onClick={this.handleOpenModalParticipantCreate}
                                        >
                                            <FiPlus style={{ marginRight: '8px' }} /> Create a panelist
                                        </Button>
                                    </NiceWrapper>
                                )}
                            </Flex>
                        </Box>
                    </AppPageWrapperSectionBody>

                    <NiceModal
                        isOpen={
                            this.state.panels && this.state.panels.length > 0 && this.state.showModalParticipantCreate
                        }
                        shouldCloseOnOverlayClick
                        onRequestClose={this.handleCloseModalParticipantCreate}
                        style={{ content: { width: '510px' } }}
                        title={`Create new panelist`}
                        overflow="visible"
                    >
                        <CreateParticipant
                            onCreate={this.onNewPerson}
                            onClose={this.handleCloseModalParticipantCreate}
                            onUpdatePanels={this.onUpdatePanels}
                            panels={this.state.panels}
                            defaultPanelIdSelected={this.state.panelIdSelected}
                        />
                    </NiceModal>

                    <NiceModal
                        isOpen={
                            this.state.panels && this.state.panels.length > 0 && this.state.showModalParticipantImport
                        }
                        shouldCloseOnOverlayClick={false}
                        shouldCloseOnEsc={false}
                        onRequestClose={this.handleCloseModalParticipantImport}
                        style={{ content: { width: '980px' } }}
                        title={`Upload panelists`}
                    >
                        <UploadParticipants
                            onClose={this.handleCloseModalParticipantImport}
                            onDone={() => {
                                this.handleCloseModalParticipantImport();
                                // its an async job so no need to reload
                                // this.getParticipantsXHR();
                            }}
                            onUpdatePanels={this.onUpdatePanels}
                            panels={this.state.panels}
                            defaultPanelIdSelected={this.state.panelIdSelected}
                        />
                    </NiceModal>

                    <NiceModal
                        isOpen={this.state.showModalAddCustomColumn}
                        shouldCloseOnOverlayClick
                        onRequestClose={this.handleCloseModalAddCustomColumn}
                        title={`Add a panelist property`}
                    >
                        <CreatePersonDataKey
                            onSuccess={this.handleAddColumnSuccess}
                            onClose={this.handleCloseModalAddCustomColumn}
                        />
                    </NiceModal>

                    <NiceModal
                        isOpen={this.state.showModalBulkEdit}
                        shouldCloseOnOverlayClick
                        onRequestClose={() => {
                            this.setState({ showModalBulkEdit: false });
                        }}
                        title={
                            'Bulk edit ' +
                            helpers.numberFormat(this.state.checkedCount) +
                            (this.state.checkedCount === 1 ? ' panelist' : ' panelists')
                        }
                        overflow="visible"
                    >
                        <form onSubmit={this.onBulkEditParticipants}>
                            <Box>
                                <Label>Column to update</Label>

                                <Box sx={{ position: 'relative' }}>
                                    <Button
                                        type="button"
                                        variant="secondary-gray"
                                        onClick={() => this.setState({ showBulkEditColumn: true })}
                                    >
                                        {this.state.bulk_edit_column.id
                                            ? this.state.bulk_edit_column.title
                                            : 'Select a column'}{' '}
                                        <FiChevronDown
                                            style={{
                                                marginRight: 0
                                            }}
                                        />
                                    </Button>
                                    {this.state.showBulkEditColumn && (
                                        <NiceDropdown
                                            showSearch
                                            onClose={() => this.setState({ showBulkEditColumn: false })}
                                            items={this.state.availableColumns.map(ac => {
                                                return { id: ac.id, title: ac.title };
                                            })}
                                            onChange={value => {
                                                const cdk = this.state.availableColumns.find(ac => ac.id == value);
                                                this.setState({ bulk_edit_column: cdk });
                                            }}
                                        />
                                    )}
                                </Box>

                                {this.state.bulk_edit_column.id ? (
                                    <>
                                        <Label mt={3}>New value</Label>
                                        <ParticipantInputElement
                                            cdk={this.state.bulk_edit_column}
                                            cdv={{ value: this.state.bulk_edit_value }}
                                            onChange={this.onBulkEditValueChange}
                                        />
                                    </>
                                ) : (
                                    <Box mt={3} fontSize={1}>
                                        Please select a column
                                    </Box>
                                )}

                                <Box className="modal-actions">
                                    <Button
                                        type="button"
                                        variant="secondary-gray"
                                        mr={3}
                                        onClick={() => {
                                            this.setState({ showModalBulkEdit: false });
                                        }}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        type="submit"
                                        variant="primary"
                                        className="modal-primary"
                                        mr={0}
                                        disabled={
                                            this.state.onBulkEditParticipants_loading || !this.state.bulk_edit_column.id
                                        }
                                    >
                                        Update {helpers.numberFormat(this.state.checkedCount)}{' '}
                                        {this.state.checkedCount === 1 ? 'panelist' : 'panelists'}
                                    </Button>
                                </Box>
                            </Box>
                        </form>
                    </NiceModal>

                    <SelectModal
                        type="study"
                        title={`Put ${helpers.numberFormat(this.state.checkedCount)} ${
                            this.state.checkedCount === 1 ? 'panelist' : 'panelists'
                        } in study`}
                        icon={<FiLayers />}
                        items={this.state.studies}
                        isOpen={this.state.showModalAddPeopleToStudy}
                        isLoading={this.state.showModalAddPeopleToStudy_loading}
                        onRequestClose={this.handleCloseModalAddPeopleToStudy.bind(this)}
                        onSelect={this.onSubmitAddPeopleToStudy.bind(this)}
                        onCreate={this.onSubmitCreateStudyAddPeople.bind(this)}
                    />

                    <SelectModal
                        type="tag"
                        title={`Tag ${this.state.checkedCount} ${
                            this.state.checkedCount === 1 ? 'panelist' : 'panelists'
                        }`}
                        icon={<FiTag />}
                        items={this.state.tags}
                        isOpen={this.state.showModalAddTag}
                        isLoading={this.state.showModalAddTag_loading}
                        onRequestClose={this.handleCloseModalAddTag.bind(this)}
                        onSelect={this.onSubmitAddTag.bind(this)}
                        onCreate={this.onSubmitCreateAddTag.bind(this)}
                        allowClickRemove={`Un-tag`}
                        onRemove={tagId => {
                            const remove = true;
                            this.onSubmitAddTag(tagId, remove);
                        }}
                    />

                    <SelectModal
                        type="panel"
                        title={`Add/Remove 
                            ${this.state.checkedCount} 
                            ${this.state.checkedCount === 1 ? 'panelist' : 'panelists'} 
                        from panel`}
                        icon={<FiLayers />}
                        items={this.state.panels}
                        isOpen={this.state.showModalAddRemoveFromPanel}
                        isLoading={this.state.showModalAddTag_loading}
                        onRequestClose={this.handleCloseModalAddRemoveFromPanel}
                        onSelect={this.onSubmitAddRemoveFromPanel}
                        onCreate={this.onSubmitCreateAddRemoveFromPanel}
                        allowClickRemove={`Remove from panel`}
                        onRemove={panelId => {
                            const remove = true;
                            this.onSubmitAddRemoveFromPanel(panelId, remove);
                        }}
                    />

                    <SelectModal
                        type="tag"
                        icon={<FiTag />}
                        items={respondentPreviewPersonTagSuggestions}
                        isOpen={this.state.showRespondentPreviewModalAddTag}
                        isLoading={false}
                        onRequestClose={() => this.setState({ showRespondentPreviewModalAddTag: false })}
                        onSelect={id => this.onPersonPreviewTagAdd(id, this.state.respondentPreviewPerson.id)}
                        onCreate={title => this.onPersonPreviewTagCreate(title, this.state.respondentPreviewPerson.id)}
                    />

                    <NiceModal
                        isOpen={this.state.showModalSelectCount}
                        shouldCloseOnOverlayClick
                        onRequestClose={() => this.setState({ showModalSelectCount: false })}
                        title="Select Count"
                        style={{ content: { width: '380px' } }}
                    >
                        <form
                            onSubmit={e => {
                                const count = document.getElementById('select_count').value;
                                if (count > 0 && helpers.isNumber(count)) {
                                    //console.log(count);
                                    this.handleCheckAllDropdownChange(
                                        'custom_count',
                                        count,
                                        this.state.randomIsChecked
                                    );
                                    this.setState({ showModalSelectCount: false });
                                }
                                e.preventDefault();
                            }}
                        >
                            <Label>How many?</Label>
                            <Input type="number" id="select_count" autoFocus min="1" autoComplete="off" />
                            <Label mt={3}>
                                <Checkbox
                                    name="daterange_enabled"
                                    checked={this.state.randomIsChecked == 1}
                                    onChange={e => {
                                        this.setState({ randomIsChecked: e.target.checked ? true : false });
                                    }}
                                />
                                <span style={{ padding: '4px 0 0 0' }}>Randomize</span>
                            </Label>
                            <Box className="modal-actions">
                                <Button
                                    type="button"
                                    variant="secondary-gray"
                                    mr={3}
                                    onClick={() => this.setState({ showModalSelectCount: false })}
                                >
                                    Cancel
                                </Button>
                                <Button type="submit" variant="primary" className="modal-primary" mr={0}>
                                    Continue
                                </Button>
                            </Box>
                        </form>
                    </NiceModal>
                    <NiceModal
                        isOpen={this.state.showModalExportCsv}
                        shouldCloseOnOverlayClick
                        onRequestClose={e => {
                            this.setState({ showModalExportCsv: false });
                        }}
                        title="Export to CSV"
                    >
                        <ExportPanelists
                            total_results={this.state.pagination.total}
                            availableColumns={all_columns}
                            availableColumns_showing_ids={all_columns.map(acs => {
                                //console.log(acs);
                                return acs.checked ? acs.id : null;
                            })}
                            onSubmit={exportCsvCustomColumnsIncludedOrdered => {
                                this.handleExportCsv(exportCsvCustomColumnsIncludedOrdered);
                            }}
                            onClose={() => {
                                this.setState({ showModalExportCsv: false });
                            }}
                        />
                    </NiceModal>

                    {this.state.showRespondentPreview && (
                        <SideOverlay
                            onClose={() => {
                                const participantSettings = this.participantSettingsRef.current;

                                if (participantSettings && participantSettings.state.showModalAddCustomColumn.isOpen)
                                    return;

                                if (this.state.showRespondentPreviewModalAddTag) return;

                                // if drawer is locked don't allow to close
                                if (this.state.isDrawerLocked) return;

                                this.setState({ showRespondentPreview: false });
                            }}
                        >
                            <Box my={3} mx={4}>
                                {this.state.respondentPreviewPerson.id ? (
                                    <>
                                        <Flex>
                                            <H1 className="ellipsis">
                                                <Link to={`/participants/${this.state.respondentPreviewPerson.id}`}>
                                                    {helpers.personGetFullName(this.state.respondentPreviewPerson)}
                                                </Link>
                                            </H1>
                                            <Box flexGrow={1} />
                                            <AddToStudyButton
                                                participantIds={[this.state.respondentPreviewPerson.id]}
                                                onOpen={() => this.setState({ isDrawerLocked: true })}
                                                onClose={() => this.setState({ isDrawerLocked: false })}
                                            />
                                            <Link to={`/participants/${this.state.respondentPreviewPerson.id}`}>
                                                <Button ml={2} variant="secondary-gray">
                                                    <FiUser /> Panelist page <FiChevronRight />
                                                </Button>
                                            </Link>
                                        </Flex>
                                        {/*<Box className='label-info text-secondary' mb={3} px={2} py={1}>
                                            Please suggest improvements{' '}
                                            <span className='link' onClick={helpers.openSupportChat}>via message</span> or{' '}
                                            <a href="https://panelfox.hellozest.io/recorder" target="_blank">record your screen</a>.
                                        </Box>*/}
                                        <Flex
                                            px={4}
                                            lineHeight="24px"
                                            width="calc(100%+64px)"
                                            margin="8px -32px 24px"
                                            sx={{
                                                borderBottom: '1px solid #e9ecef',
                                                gap: '32px'
                                            }}
                                        >
                                            <HeaderSubnavLink
                                                as={Box}
                                                onClick={() => this.setState({ showRespondentPreview_tab: 'profile' })}
                                                css={{ px: 2 }}
                                                className={classNames(
                                                    'basic-subnav',
                                                    this.state.showRespondentPreview_tab === 'profile' &&
                                                        'nav-item-active'
                                                )}
                                            >
                                                <FiUser /> Profile
                                            </HeaderSubnavLink>
                                            <HeaderSubnavLink
                                                as={Box}
                                                onClick={() => this.setState({ showRespondentPreview_tab: 'tags' })}
                                                className={classNames(
                                                    'basic-subnav',
                                                    this.state.showRespondentPreview_tab === 'tags' && 'nav-item-active'
                                                )}
                                            >
                                                <FiTag /> Tags
                                            </HeaderSubnavLink>
                                            <HeaderSubnavLink
                                                as={Box}
                                                onClick={() =>
                                                    this.setState({ showRespondentPreview_tab: 'past_part' })
                                                }
                                                className={classNames(
                                                    'basic-subnav',
                                                    this.state.showRespondentPreview_tab === 'past_part' &&
                                                        'nav-item-active'
                                                )}
                                            >
                                                <FiClock /> Past Participation
                                            </HeaderSubnavLink>
                                        </Flex>

                                        {this.state.showRespondentPreview_tab === 'profile' && (
                                            <Box mt={4}>
                                                <ParticipantSettings
                                                    ref={this.participantSettingsRef}
                                                    parentCallback={person => {
                                                        const sp = { ...this.state.study_person };
                                                        sp.person = person;
                                                        this.setState({ study_person: sp });
                                                        console.log('ParticipantSettings parent callback ');
                                                    }}
                                                    participant={this.state.respondentPreviewPerson}
                                                    user={this.props.auth.user}
                                                    role={this.props.auth.account_role.type}
                                                    isPreview
                                                />
                                            </Box>
                                        )}
                                        {this.state.showRespondentPreview_tab === 'tags' &&
                                            this.state.respondentPreviewPerson.id && (
                                                <Box mt={4}>
                                                    <ParticipantTags
                                                        tags={respondentPreviewPersonTags}
                                                        onTagDelete={id =>
                                                            this.onPersonPreviewTagDelete(
                                                                id,
                                                                this.state.respondentPreviewPerson.id
                                                            )
                                                        }
                                                        handleOpenModalAddTag={() =>
                                                            this.setState({ showRespondentPreviewModalAddTag: true })
                                                        }
                                                    />
                                                </Box>
                                            )}
                                        {this.state.showRespondentPreview_tab === 'past_part' &&
                                            this.state.respondentPreviewPerson.id && (
                                                <Box mt={4}>
                                                    <PastParticipation
                                                        person_id={this.state.respondentPreviewPerson.id}
                                                        fetch_person={true}
                                                    />
                                                </Box>
                                            )}
                                    </>
                                ) : (
                                    <div style={{ marginTop: '22px' }}>
                                        <LoadingWrapper>
                                            <LoadingIndicator />
                                        </LoadingWrapper>
                                    </div>
                                )}
                            </Box>
                        </SideOverlay>
                    )}
                </AppPageWrapper>
                <DeletePanelistsModal
                    count={this.state.checkedCount}
                    isOpen={this.state.isDeletePanelistsModalOpen}
                    onDelete={() => this.deleteParticipants()}
                    onClose={() => this.onDeletePanelistsModalClose()}
                />
            </Flex>
        );
    }
}

export default withRouter(withTheme(withToastManager(withPagination(ParticipantsPage))));
