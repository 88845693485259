import React from 'react';
import { FiInfo } from 'react-icons/fi';

export default props => (
    <FiInfo
        data-tip
        data-for={props.for}
        style={{ fontSize: '16px', color: '#777', margin: props.margin ? props.margin : '3px 0 0 4px' }}
    />
);
