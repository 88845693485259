import { NavLink } from 'react-router-dom';
import styled from 'styled-components';

export const activeClassName = 'nav-item-active';

const style = `display: block;
  margin: 0;
  padding: 6px 0;
  text-decoration: none;
  -webkit-font-smoothing: antialiased;
  -webkit-touch-callout: none;
  user-select: none;
  cursor: pointer;
  outline: 0;
  font-size: 14px;
  color: #000;
  transition: background-color 250ms, color 250ms;
  position: relative;

  &:hover {
      color: #0A73EB;
      transition: background-color 150ms, color 150ms;
  }

  &.${activeClassName} {
    color: #0A73EB;
    transition: background-color 350ms;
  }

  svg, img {
      margin-top: -2px;
      margin-right: 12px;
      width: 20px;
      height: auto;
  }
`;

export const SmallLink = styled(NavLink).attrs({ activeClassName })`
    ${style}
`;
