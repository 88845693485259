import React from 'react';

function MicrosoftVerificationPage(props) {
    const haha = {
        associatedApplications: [
            {
                applicationId: 'da77318d-7a98-4be6-bd93-53ac04d66843'
            }
        ]
    };
    return JSON.stringify(haha);
}

export default MicrosoftVerificationPage;
