const contentful = require('contentful');

const client = contentful.createClient({
    // This is the space ID. A space is like a project folder in Contentful terms
    space: '4uy0fkwgg4d6',
    // This is the access token for this space. Normally you get both ID and the token in the Contentful web app
    accessToken: 'ch_vksUtxv6xtQD1NJaJ9Rmf4NJKPoXz4p9zruxPxrk'
});

const service_docs = {
    getDocumentationNav: async () => {
        if (window.docs_nav_data_cache) {
            console.log('from cache');
            return window.docs_nav_data_cache;
        }
        console.log('NOT from cache');
        const response = client.getEntry('6yP0UGUfODJFmXrlZzF8T');
        const r2 = response;
        r2.then(data => {
            window.docs_nav_data_cache = data;
        });
        return response;
    },
    getDocumentationDataBySlug: async slug => {
        console.log(slug);
        const response = client.getEntries({
            content_type: 'documentationPage',
            'fields.slug': slug
        });
        return response;
    }
};

module.exports = service_docs;
