import React from 'react';
import { Link } from 'react-router-dom';

import H2 from 'components/H2';

/**
 * The content for the success toast in single add to study
 *
 * @param {Object} props
 * @param {String} props.studyName The name of the study
 * @param {Number} props.studyId The id of the study
 * @returns {React.Component} The component
 */
export function ToastSuccess({ studyName, studyId }) {
    return (
        <>
            <H2>Participant has been added {studyName ? `to ${studyName}` : 'to a study'}</H2>
            <Link to={`/studies/${studyId}`}>
                <a style={{ fontSize: '16px' }}>Go to study -&gt;</a>
            </Link>
        </>
    );
}

export default ToastSuccess;
