import React from 'react';
import { Box, Flex } from 'rebass';
import classNames from 'classnames';

import { CURRENCY_SYMBOL } from 'utils/constants';

/**
 * IncentiveProgress component
 *
 * @param {Object} props
 * @param {number} props.limit limit amount
 * @param {number} props.paid paid amount
 * @param {string} props.currency currency
 * @param {string} props.className className
 */
export function IncentiveProgress({ limit, paid, currency, className }) {
    const currencySymbol = CURRENCY_SYMBOL[currency];
    const noLimit = !limit || Number(limit) <= 0;
    const isFatigue = !noLimit && Number(paid) >= Number(limit);

    return (
        <Box
            sx={{ borderWidth: '1px', borderStyle: 'solid', borderRadius: '4px', padding: '8px 16px' }}
            className={classNames(isFatigue ? 'border-danger bg-danger-light' : 'border-info bg-info-light', className)}
        >
            <Flex justifyContent="space-between" alignItems="center" mb="8px">
                <Box
                    as="p"
                    sx={{
                        fontSize: '14px',
                        fontWeight: '500',
                        lineHeight: '20px'
                    }}
                >
                    Total annual incentives paid
                </Box>
                <Box
                    as="p"
                    sx={{
                        fontSize: '14px',
                        fontWeight: '500',
                        lineHeight: '20px'
                    }}
                >
                    <Box as="span">
                        {currencySymbol}
                        {paid}
                    </Box>
                    <Box as="span" mx="3px" color="#606E85">
                        /
                    </Box>
                    <Box as="span" color="#606E85">
                        {noLimit ? 'No limit' : `${currencySymbol}${limit}`}
                    </Box>
                </Box>
            </Flex>
            <Box
                width="100%"
                my="4px"
                height="2px"
                borderRadius="1000"
                sx={{
                    backgroundColor: '#D1E9FF',
                    position: 'relative',
                    overflow: 'hidden',
                    '::after': {
                        content: '""',
                        position: 'absolute',
                        top: '0',
                        left: '0',
                        height: '2px',
                        width: noLimit ? '100%' : `${(Number(paid) / Number(limit)) * 100}%`,
                        backgroundColor: isFatigue ? '#D42220' : '#2A5EEF'
                    }
                }}
            />
        </Box>
    );
}

export default IncentiveProgress;
