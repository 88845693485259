import React, { useMemo, useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';
import { useParams } from 'react-router-dom';
import { Flex, Box } from 'rebass';
import { useToasts } from 'react-toast-notifications';

import AppPageWrapper from 'components/AppPageWrapper';
import AppPageWrapperSectionHeader from 'components/AppPageWrapper/AppPageWrapperSectionHeader';
import AppPageWrapperSectionBody from 'components/AppPageWrapper/AppPageWrapperSectionBody';
import LoadingIndicator from 'components/LoadingIndicator';
import LoadingWrapper from 'components/LoadingIndicator/LoadingWrapper';
import { useQueryParams } from 'hooks/useQueryParams';
import useQuery from 'hooks/useQuery';
import servicePeople from 'services/people';
import services from 'services/services';
import Grid from 'components/Grid';
import BreadcrumbBack from 'components/BreadcrumbBack';
import SelectPanelDropdown from 'components/SelectPanelDropdown';
import PanelSearchSelect from 'components/PanelSearchSelect';
import { PANEL_DASHBOARD_TYPE_PDK } from 'utils/constants';

import { Statistic } from './components/Statistic';
import { DashboardChartCard } from './components/DashboardChartCard';
import { AddPropertyButton } from './components/AddPropertyButton';
import useMutation from 'hooks/useMutation';
import analytics from 'utils/analytics';

const ALLOWED_PROPERTIES = ['dropdown', 'checkbox', 'boolean', 'number'];
const PANELS_LIMIT = 10;
const DEFAULT_STATS = ['total', 'new', 'active', 'participated'];

const statInfo = {
    new: {
        title: 'New Panelists',
        info: 'Number of panelists who joined the panel in the selected time period.'
    },
    active: {
        title: 'Active Panelists',
        info: 'Number of panelists who have completed at least one survey in the selected time period.'
    },
    total: {
        title: 'Total Panelists',
        info: 'Total number of panelists in the panel.'
    },
    participated: {
        title: 'Participated Panelists',
        info: 'Number of panelists who have participated in a study in the selected time period.'
    }
};

/**
 * Get dashboard charts from panel dashboards
 *
 * @param {Object[]} panelDashboards
 * @param {Object[]} panelProperties
 */
export const useDashboardCharts = (panelDashboards, panelProperties) =>
    useMemo(() => {
        if (!panelDashboards || !panelProperties) return [];

        // Choose only table statistics
        const dashboards = panelDashboards.filter(dashboard => dashboard.type === PANEL_DASHBOARD_TYPE_PDK);

        return dashboards.map(dashboard => {
            let data;
            try {
                data = JSON.parse(dashboard.results || '{}');
            } catch (error) {
                data = {};
            }
            const total = data.__panelfox_total || 0;
            delete data.__panelfox_total; // Remove total from data, we don't want to have it in the final data

            const panelProperty = panelProperties.find(panelProperty => panelProperty.title === dashboard.title);

            // Sort by order if panel property is found
            if (panelProperty) {
                const order = panelProperty.definition.map(item => item.label);
                data = Object.entries(data).sort((a, b) => order.indexOf(a[0]) - order.indexOf(b[0]));
            } else {
                data = Object.entries(data);
            }

            return {
                title: dashboard.title,
                id: dashboard.id,
                data,
                updatedAt: dashboard.updated_at,
                total
            };
        });
    }, [panelDashboards, panelProperties]);

function PanelDashboardPage(props) {
    const { panel_id } = useParams();
    const { query } = useQueryParams();
    const { addToast } = useToasts();
    const [panelId, setPanelId] = useState(panel_id);
    const [isPanelChanging, setIsPanelChanging] = useState(false);
    const [panelSearchId, setPanelSearchId] = useState(query.panelSearchId);
    // const [isCustomization, setIsCustomization] = useState(false);
    const { data: panels, refetch: refetchPanels } = useQuery({
        queryFn: () => servicePeople.getPanels()
    });
    const { data: panelProperties } = useQuery({
        queryFn: () => services.getCustomDataColumnsXHR()
    });
    const {
        data: panelDashboards,
        isLoading: isDashboardLoading,
        refetch: refetchPanelDashboards,
        called: isPanelDashboardsCalled
    } = useQuery({
        queryFn: variables => servicePeople.getPanelsDashboard(variables),
        variables: {
            panelId,
            daysAgo: query.daysAgo,
            panelSearchId: panelSearchId
        },
        onSuccess: () => {
            setIsPanelChanging(false);
        }
    });
    const { data: panel, isLoading: isPanelLoading, called: isPanelCalled } = useQuery({
        queryFn: variables => servicePeople.getPanel(variables.panelId),
        variables: {
            panelId
        }
    });
    const { mutate: createPanelDashboard } = useMutation({
        mutationFn: variables => servicePeople.createPanelDashboard(variables),
        onSuccess: async () => {
            await refetchPanelDashboards();
        },
        onError: error => {
            const errorText = services.parseAndTrackXhrErrors(error);
            addToast(errorText, {
                appearance: 'error',
                autoDismiss: true
            });
        }
    });
    const { mutate: deletePanelDashboard } = useMutation({
        mutationFn: variables => servicePeople.deletePanelDashboard(variables),
        onSuccess: async () => {
            await refetchPanelDashboards();
        },
        onError: error => {
            const errorText = services.parseAndTrackXhrErrors(error);
            addToast(errorText, {
                appearance: 'error',
                autoDismiss: true
            });
        }
    });
    const { mutate: reCalculate } = useMutation({
        mutationFn: variables => servicePeople.getPanelDashboard(variables),
        onSuccess: async () => {
            await refetchPanelDashboards();
        },
        onError: error => {
            const errorText = services.parseAndTrackXhrErrors(error);
            addToast(errorText, {
                appearance: 'error',
                autoDismiss: true
            });
        }
    });
    const statistics = useMemo(() => {
        if (!panelDashboards) return [];

        const expectedOrder = ['total', 'new', 'active', 'participated'];

        // Choose only default statistics and sort
        const dashboards = panelDashboards
            .filter(dashboard => DEFAULT_STATS.includes(dashboard.title))
            .sort((a, b) => expectedOrder.indexOf(a.title) - expectedOrder.indexOf(b.title));

        return dashboards.map(dashboard => ({
            ...statInfo[dashboard.title],
            id: dashboard.id,
            value: Number(dashboard.results),
            updatedAt: dashboard.updated_at
        }));
    }, [panelDashboards]);
    const propertiesOptions = useMemo(() => {
        if (!panelProperties || !panelDashboards) return [];

        // exclude already saved dashboards
        const savedDashboardIds = panelDashboards
            .filter(dashboard => dashboard.configuration)
            .map(dashboard => Number(dashboard.configuration));

        return (
            panelProperties
                // only allowed properties
                .filter(property => ALLOWED_PROPERTIES.includes(property.type))
                // exclude already saved dashboards
                .filter(property => !savedDashboardIds.includes(property.id))
                // map to options
                .map(property => ({
                    value: property.id,
                    label: property.title
                }))
        );
    }, [panelProperties, panelDashboards]);
    const dashboardCharts = useDashboardCharts(panelDashboards, panelProperties);
    const isLoading =
        ((isPanelLoading || isDashboardLoading) && (!isPanelDashboardsCalled || !isPanelCalled)) || isPanelChanging;

    const createMetric = async pdkId => {
        await createPanelDashboard({ panelId, pdkId });
    };

    const deletePanel = async dashboardItemId => {
        await deletePanelDashboard({ panelId, dashboardItemId });
    };

    const onChange = newPanelId => {
        if (newPanelId != panelId) {
            setPanelId(newPanelId);
            setIsPanelChanging(true);
            props.history.push(`/panels/${newPanelId}/dashboard`);
        }
    };

    const onCreatePanel = async panel => {
        addToast('Successfully created a new panel', {
            appearance: 'success',
            autoDismiss: true
        });
        await refetchPanels();
        props.history.push(`/panels/${panel.id}/dashboard`);
    };

    const onChangePanelSearch = search => {
        if (search) {
            setPanelSearchId(search.id);
            if (search.url_state.panelIdSelected && search.url_state.panelIdSelected != panelId) {
                onChange(search.url_state.panelIdSelected);
            }
        } else {
            setPanelSearchId();
        }
    };

    useEffect(() => {
        analytics.track('panel_dashboard-view');
    }, []);

    return (
        <Flex style={{ width: '100%' }}>
            <AppPageWrapper>
                <Helmet>
                    <title>Panel dashboard</title>
                </Helmet>

                <AppPageWrapperSectionHeader className="experience experience-border experience-no-subnav section-header-sticky">
                    <Box>
                        <BreadcrumbBack to="/panels">Back to all panels</BreadcrumbBack>
                        {panel && panels && (
                            <SelectPanelDropdown
                                panels={panels}
                                auth={props.auth}
                                selectedId={panel.id}
                                onChange={onChange}
                                onCreatePanel={onCreatePanel}
                                isDashboard
                            />
                        )}
                    </Box>
                </AppPageWrapperSectionHeader>

                <AppPageWrapperSectionBody>
                    {isLoading ? (
                        <div style={{ width: '100%' }}>
                            <LoadingWrapper>
                                <LoadingIndicator />
                            </LoadingWrapper>
                        </div>
                    ) : !panel || !panelDashboards ? (
                        <Flex mt={3} mx={4}>
                            Panel not found.
                        </Flex>
                    ) : (
                        <Box mx={4} my={3} width="100%">
                            <Flex mb="20px" alignItems="center">
                                <Box as="h3" sx={{ fontSize: '16px', fontWeight: 600, lineHeight: '32px', mr: '12px' }}>
                                    Last 30 days
                                </Box>

                                <Box mr={3}>
                                    <PanelSearchSelect
                                        selectedId={panelSearchId}
                                        onChange={onChangePanelSearch}
                                        disabled={isDashboardLoading}
                                    />
                                </Box>
                                {isDashboardLoading && (
                                    <Box mr={3}>
                                        <LoadingIndicator relativePosition />
                                    </Box>
                                )}

                                {/*!panelSearchId && (
                                    <Box as="p" className="color-text-secondary fs-body-12">
                                        Last calculated:{' '}
                                        {lastCalculated === 0 ? 'calculating...' : moment(lastCalculated).fromNow()}
                                    </Box>
                                )*/}

                                {/* <CalendarSelect /> */}
                                <Box flex="1" />
                                {/* <Button
                                    padding="0 16px"
                                    margin="0"
                                    variant={isCustomization ? 'primary' : 'secondary-gray'}
                                    onClick={() => setIsCustomization(isCustomization => !isCustomization)}
                                >
                                    <FiSettings /> Customize
                                </Button> */}
                            </Flex>
                            <Grid md={4} sm={2}>
                                {statistics.map(stat => (
                                    <Statistic
                                        key={stat.id}
                                        title={stat.title}
                                        value={stat.value}
                                        extraInfo={stat.info}
                                        updatedAt={stat.updatedAt}
                                        isSearchResult={!!panelSearchId}
                                        onRefresh={async () => await reCalculate({ panelId, dashboardItemId: stat.id })}
                                    />
                                ))}
                            </Grid>
                            <Grid>
                                {dashboardCharts.map(dashboardChart => (
                                    <DashboardChartCard
                                        key={dashboardChart.id}
                                        panelId={panelId}
                                        data={dashboardChart.data}
                                        title={dashboardChart.title}
                                        isCustomization={false}
                                        onDelete={() => deletePanel(dashboardChart.id)}
                                        updatedAt={dashboardChart.updatedAt}
                                        isSearchResult={!!panelSearchId}
                                        onRefresh={() => reCalculate({ panelId, dashboardItemId: dashboardChart.id })}
                                        total={dashboardChart.total}
                                    />
                                ))}
                                {dashboardCharts.length < PANELS_LIMIT && propertiesOptions.length > 0 && (
                                    <AddPropertyButton options={propertiesOptions} onChange={createMetric} />
                                )}
                            </Grid>
                        </Box>
                    )}
                </AppPageWrapperSectionBody>
            </AppPageWrapper>
        </Flex>
    );
}

export default PanelDashboardPage;
