import React from 'react';
import { FiSlash } from 'react-icons/fi';

import { Badge } from 'components/Badge';

/**
 * Badge with the icon and text "Opted Out"
 *
 * @param {Object} props
 * @param {string?} props.className
 */
export function OptOutBadge({ className }) {
    return (
        <Badge className={className} type="danger">
            <FiSlash
                css={{
                    margin: '2px 4px 0 0 !important',
                    fontSize: '14px !important',
                    padding: '4px',
                    boxSizing: 'content-box'
                }}
            />{' '}
            Opted Out
        </Badge>
    );
}

export default OptOutBadge;
