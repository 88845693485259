import React, { useState } from 'react';
import { Flex, Text } from 'rebass';
import services from 'services/services';
import { useToasts } from 'react-toast-notifications';
import { FiCheckCircle } from 'react-icons/fi';

import LoginPageWrapper from 'containers/LoginPage/LoginPageWrapper';
import LoginPageHeader from 'containers/LoginPage/components/LoginPageHeader';
import LoginPageInput from 'containers/LoginPage/components/LoginPageInput';
import LoginPageButton from 'containers/LoginPage/components/LoginPageButton';
import LoginPageLink from 'containers/LoginPage/components/LoginPageLink';

const ForgotPasswordPage = () => {
    const [loading, setLoading] = useState(false);
    const [email, setEmail] = useState('');
    const [tokenEmailSent, setTokenEmailSent] = useState(false);
    const { addToast } = useToasts();

    const sendForgotPasswordEmailXHR = () => {
        setLoading(true);
        services
            .sendForgotPasswordEmailUser(email)
            .then(response => {
                console.log('succes....', response);
                setLoading(false);
                setTokenEmailSent(true);
            })
            .catch(e => {
                setLoading(false);

                const errorText = services.parseAndTrackXhrErrors(e);
                addToast(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    };

    return (
        <LoginPageWrapper title="Reset password">
            <LoginPageHeader>Reset Password</LoginPageHeader>
            {!tokenEmailSent ? (
                <>
                    <Text textAlign="center" color="#606E85" mb="24px">
                        Enter your account email and we will send you instructions how to reset your password
                    </Text>
                    <form
                        onSubmit={e => {
                            e.preventDefault();
                            e.stopPropagation();
                            sendForgotPasswordEmailXHR();
                        }}
                    >
                        <LoginPageInput
                            type="email"
                            name="email"
                            required
                            placeholder="example@mail.domain"
                            value={email}
                            onChange={e => setEmail(e.target.value)}
                            mb="32px"
                        />
                        <LoginPageButton type="submit" variant="primary" mb="24px" disabled={loading}>
                            {loading ? 'Sending email...' : 'Send Email'}
                        </LoginPageButton>
                    </form>
                    <LoginPageLink to="/login">Cancel</LoginPageLink>
                </>
            ) : (
                <>
                    <Flex justifyContent="center" my={2}>
                        <FiCheckCircle color="green" fontSize="28px" />
                    </Flex>
                    <Text textAlign="center" color="#606E85" mb="24px">
                        The reset password link will be sent to the provided email address if the account exists. Please
                        check your email, and follow the instructions.
                    </Text>
                    <LoginPageLink to="/login">Back to Log In page</LoginPageLink>
                </>
            )}
        </LoginPageWrapper>
    );
};

export default ForgotPasswordPage;
