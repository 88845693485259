import xhr from 'xhr.js';
import auth from 'services/auth';
import helpers from 'utils/helpers';

const people = {
    bulkEditPeople: async options => {
        const account_id = auth.getAccountId();
        console.log(options);

        const res = await xhr.put(
            `/people?${options.filters_query_string}`,
            {
                all: options.all,
                people_ids: options.people_ids,
                account_id,
                bulk_edit_column_id: options.bulk_edit_column_id,
                bulk_edit_column_value: options.bulk_edit_column_value
            },
            {
                withCredentials: true
            }
        );
        return res.data;
    },
    getPeopleCount: async () => {
        const account_id = auth.getAccountId();
        const res = await xhr.get(`/people/count?account_id=${account_id}`, {
            withCredentials: true
        });
        return res.data;
    },
    getPanelIncentives: async () => {
        const account_id = auth.getAccountId();
        const res = await xhr.get(`/people-incentives?account_id=${account_id}`, {
            withCredentials: true
        });
        return res.data;
    },
    getPanelIncentives2: async (year, amount_minimum) => {
        const account_id = auth.getAccountId();
        const res = await xhr.get(`/people-incentives2?account_id=${account_id}`, {
            withCredentials: true,
            params: { year: year, amount_minimum: amount_minimum }
        });
        return res.data;
    },
    getPersonUuid: async (account_uuid, person_uuid) => {
        const res = await xhr.get(`/people-consent/${account_uuid}/${person_uuid}`, {
            withCredentials: true
        });
        return res.data;
    },
    consentOptIn: async (account_uuid, person_uuid, opt_in_value) => {
        const res = await xhr.post(
            `/people-consent/${account_uuid}/${person_uuid}/opt-in`,
            { opt_in_value },
            {
                withCredentials: true
            }
        );
        return res.data;
    },
    consentDeleteData: async (account_uuid, person_uuid) => {
        const res = await xhr.post(
            `/people-consent/${account_uuid}/${person_uuid}/delete`,
            {},
            {
                withCredentials: true
            }
        );
        return res.data;
    },
    getPanelSignupPublic: async (account_hash, panel_form_id, isPreview) => {
        const res = await xhr.get(`/panel-signup-form-public/${account_hash}/${panel_form_id}`, {
            withCredentials: true,
            params: { is_preview: isPreview }
        });
        return res.data;
    },
    getPanelSignupPublicWithSlug: async (panel_signup_custom_slug, isPreview) => {
        const res = await xhr.get(`/panel-signup-form-public-slug/${panel_signup_custom_slug}`, {
            withCredentials: true,
            params: { is_preview: isPreview }
        });
        return res.data;
    },
    getPanelSignupForms: async () => {
        const account_id = auth.getAccountId();
        const res = await xhr.get(`/panel-signup-forms?account_id=${account_id}`, {
            withCredentials: true
        });
        return res.data;
    },
    getPanelSignupForm: async form_id => {
        const account_id = auth.getAccountId();
        const res = await xhr.get(`/panel-signup-forms/${form_id}?account_id=${account_id}`, {
            withCredentials: true
        });
        return res.data;
    },
    deletePanelSignupForm: async form_id => {
        const account_id = auth.getAccountId();
        const res = await xhr.delete(`/panel-signup-forms/${form_id}?account_id=${account_id}`, {
            withCredentials: true
        });
        return res.data;
    },
    createPanelSignupForm: async (title, description, panel_id, copy_form_id, form_type) => {
        const account_id = auth.getAccountId();
        const res = await xhr.post(
            `/panel-signup-forms?account_id=${account_id}`,
            { title, description, panel_id, copy_form_id, form_type },
            {
                withCredentials: true
            }
        );
        return res.data;
    },
    getScreenerTranslation: async panel_form_id => {
        const account_id = auth.getAccountId();
        const res = await xhr.get(`/panel-signup-translation/${panel_form_id}?account_id=${account_id}`, {
            withCredentials: true
        });
        return res.data;
    },
    updateScreenerTranslation: async (panel_form_id, screener_translation) => {
        const account_id = auth.getAccountId();
        const res = await xhr.put(
            `/panel-signup-translation/${panel_form_id}?account_id=${account_id}`,
            { screener_translation },
            {
                withCredentials: true
            }
        );

        return res.data;
    },
    updatePanelForm: async panel_form => {
        const account_id = auth.getAccountId();
        const res = await xhr.put(
            `/panel-signup-forms/${panel_form.id}?account_id=${account_id}`,
            { ...panel_form },
            {
                withCredentials: true
            }
        );

        return res.data;
    },
    updateColumn: async column => {
        const account_id = auth.getAccountId();
        const res = await xhr.put(
            `/columns/${column.id}?account_id=${account_id}`,
            { ...column },
            {
                withCredentials: true
            }
        );

        return res.data;
    },
    getUniqueColumnValues: async column_id => {
        const account_id = auth.getAccountId();
        const res = await xhr.get(`/columns/${column_id}/unique-values?account_id=${account_id}`, {
            withCredentials: true
        });

        return res.data;
    },
    createPersonPanelistExperience: async (account_uuid, person) => {
        const res = await xhr.post(
            `/people-public?account_uuid=${account_uuid}`,
            { ...person },
            {
                withCredentials: true
            }
        );
        return res.data;
    },
    loginPersonPanelistExperience: async (account_uuid, email, password) => {
        const res = await xhr.post(
            `/people-public-login?account_uuid=${account_uuid}`,
            { email, password },
            {
                withCredentials: true
            }
        );
        return res.data;
    },
    checkAuthPanelistExperience: async account_uuid => {
        const token = helpers.getPanelistJWTToken();

        const res = await xhr.post(
            `/me-panelist`,
            { token: token, account_uuid: account_uuid },
            {
                withCredentials: true
            }
        );
        return res.data;
    },
    logoutPanelistExperience: async account_uuid => {
        const token = helpers.getPanelistJWTToken();
        const res = await xhr.post(
            `/logout-panelist`,
            { token: token, account_uuid: account_uuid },
            {
                withCredentials: true
            }
        );
        return res.data;
    },
    sendForgotPasswordEmailPanelistExperience: async (account_uuid, email) => {
        const res = await xhr.post(
            `/people-public-forgot-password?account_uuid=${account_uuid}`,
            { email },
            {
                withCredentials: true
            }
        );
        return res.data;
    },
    useResetPasswordToken: async (token, password, password_confirmation) => {
        const res = await xhr.post(
            `/reset-password-use-token`,
            { token, password, password_confirmation },
            {
                withCredentials: true
            }
        );
        return res.data;
    },
    panelistRedeemPoints: async account_uuid => {
        const token = helpers.getPanelistJWTToken();
        const res = await xhr.post(
            `/me-panelist/redeem`,
            { account_uuid, token },
            {
                withCredentials: true
            }
        );
        return res.data;
    },
    getPanels: async () => {
        const account_id = auth.getAccountId();
        const res = await xhr.get(`/panels?account_id=${account_id}`, {
            withCredentials: true
        });
        return res.data;
    },
    getPanel: async panel_id => {
        const account_id = auth.getAccountId();
        const res = await xhr.get(`/panels/${panel_id}?account_id=${account_id}`, {
            withCredentials: true
        });
        return res.data;
    },
    getPanelsDashboard: async ({ panelId, daysAgo, panelSearchId }) => {
        const account_id = auth.getAccountId();
        const res = await xhr.get(`/panels-dashboard`, {
            params: {
                account_id,
                panel_id: panelId,
                days_ago: daysAgo,
                panel_search_id: panelSearchId
            },
            withCredentials: true
        });

        return res.data;
    },
    getPanelDashboard: async ({ panelId, dashboardItemId }) => {
        const account_id = auth.getAccountId();
        const res = await xhr.get(`/panels-dashboard/${dashboardItemId}`, {
            params: {
                account_id,
                panel_id: panelId
            },
            withCredentials: true
        });

        return res.data;
    },
    createPanelDashboard: async ({ panelId, pdkId }, data) => {
        const account_id = auth.getAccountId();
        const res = await xhr.post(`/panels-dashboard`, data, {
            params: {
                account_id,
                panel_id: panelId,
                pdk_id: pdkId
            },
            withCredentials: true
        });

        return res.data;
    },
    deletePanelDashboard: async ({ panelId, dashboardItemId }) => {
        const account_id = auth.getAccountId();
        const res = await xhr.delete(`/panels-dashboard/${dashboardItemId}`, {
            params: {
                account_id,
                panel_id: panelId
            },
            withCredentials: true
        });

        return res.data;
    },
    createPanel: async (title, rbac_restricted) => {
        const account_id = auth.getAccountId();
        const res = await xhr.post(
            `/panels`,
            { account_id, title, rbac_restricted },
            {
                withCredentials: true
            }
        );
        return res.data;
    },
    updatePanel: async panel => {
        const account_id = auth.getAccountId();
        const { id, title, email_template_id, rbac_restricted } = panel;
        const res = await xhr.put(
            `/panels/${id}`,
            { account_id, title, email_template_id, rbac_restricted },
            {
                withCredentials: true
            }
        );
        return res.data;
    },
    deletePanel: async (panel_id, delete_panelists) => {
        const account_id = auth.getAccountId();
        const res = await xhr.delete(
            `/panels/${panel_id}?account_id=${account_id}&delete_panelists=${delete_panelists}`,
            {
                withCredentials: true
            }
        );
        return res.data;
    },
    addUsersToPanel: async (panel_id, user_ids_array) => {
        const account_id = auth.getAccountId();
        const res = await xhr.post(
            `/panels/${panel_id}/users`,
            { account_id, user_ids_array },
            {
                withCredentials: true
            }
        );
        return res.data;
    },
    removeUsersFromPanel: async (panel_id, user_ids_array) => {
        const account_id = auth.getAccountId();
        const res = await xhr.post(
            `/panels/${panel_id}/users-remove`,
            { account_id, user_ids_array },
            {
                withCredentials: true
            }
        );
        return res.data;
    },
    addPanelistToPanel: async (panelist_id, panel_id) => {
        const account_id = auth.getAccountId();
        const res = await xhr.post(
            `/people/${panelist_id}/panels/${panel_id}`,
            { account_id },
            {
                withCredentials: true
            }
        );
        return res.data;
    },
    removePanelistFromPanel: async (panelist_id, panel_id) => {
        const account_id = auth.getAccountId();
        const res = await xhr.delete(`/people/${panelist_id}/panels/${panel_id}?account_id=${account_id}`, {
            withCredentials: true
        });
        return res.data;
    },
    getMaskedValue: async (panelist_id, data_key_idx) => {
        const account_id = auth.getAccountId();
        const res = await xhr.get(`/people/${panelist_id}/custom-data-value/${data_key_idx}?account_id=${account_id}`, {
            withCredentials: true
        });
        return res.data;
    },
    addFormsToPanel: async (panel_id, form_ids_array) => {
        const account_id = auth.getAccountId();
        const res = await xhr.post(
            `/panels/${panel_id}/forms`,
            { account_id, form_ids_array },
            {
                withCredentials: true
            }
        );
        return res.data;
    },
    removeFormsFromPanel: async (panel_id, form_ids_array) => {
        const account_id = auth.getAccountId();
        const res = await xhr.post(
            `/panels/${panel_id}/forms-remove`,
            { account_id, form_ids_array },
            {
                withCredentials: true
            }
        );
        return res.data;
    }
};

export default people;
