import React, { useEffect, useMemo, useRef, useState } from 'react';

import useQueryParams from 'hooks/useQueryParams';

const DEFAULT_PAGE_SIZE = 20;
const DEFAULT_PAGE = 1;

/**
 * Handle pagination state with automatic handling of query parameters.
 *
 * @param {Object} props
 * @param {Number} props.defaultPageSize default page size
 * @param {Number} props.defaultPage default page
 */
export function usePagination({ defaultPageSize = DEFAULT_PAGE_SIZE, defaultPage = DEFAULT_PAGE }) {
    const { query, setQuery } = useQueryParams();
    const [pagination, setPagination] = useState(() => {
        const pageSize = query.pageSize || defaultPageSize;
        const page = query.page || defaultPage;
        return { pageSize, page };
    });

    /**
     * Change the page size.
     *
     * @param {number} pageSize new page size
     */
    const changePageSize = pageSize => {
        setQuery({ pageSize, page: 1 });
    };

    /**
     * Change the page.
     *
     * @param {number} page new page
     */
    const changePage = page => {
        setQuery({ page });
    };

    /**
     * Update the query parameters when the pagination changes and call the callback if it exists.
     */
    useEffect(() => {
        setPagination({
            pageSize: query.pageSize || DEFAULT_PAGE_SIZE,
            page: query.page || DEFAULT_PAGE
        });
    }, [query]);

    return { ...pagination, changePageSize, changePage };
}

/**
 * Handle pagination state without query parameters.
 *
 * @param {Object?} param0 The default page size and page.
 * @param {Number} param0.defaultPerPage default page size
 * @param {Number} param0.defaultPage default page
 * @returns {{ perPage: number, page: number, changePerPage: (perPage: number) => void, changePage: (page: number) => void }} The pagination object.
 */
export function useBasicPagination({ defaultPerPage = DEFAULT_PAGE_SIZE, defaultPage = DEFAULT_PAGE } = {}) {
    const [pagination, setPagination] = useState({
        perPage: defaultPerPage,
        page: defaultPage
    });

    /**
     * Set the page size and reset the page to 1.
     *
     * @param {number} perPage new page size
     */
    const changePerPage = perPage => {
        setPagination({ ...pagination, perPage, page: 1 });
    };

    /**
     * Set the page.
     *
     * @param {number} page new page
     */
    const changePage = page => {
        setPagination({ ...pagination, page });
    };

    return { ...pagination, changePerPage, changePage };
}

/**
 * HOC to provide the pagination object to a component.
 */
export function withPagination(Component) {
    return function WithPagination(props) {
        const pagination = usePagination(props);
        return <Component {...props} pagination={pagination} />;
    };
}

export default usePagination;
