import styled from 'styled-components';
import React from 'react';

const MarketingPageWrapperStyled = styled.div.attrs(props => ({
    background: props.background || '#fffcff',
    width: props.fullwidth ? '100%' : props.isMobile ? '100%' : '100%',
    maxWidth: props.fullwidth ? '100%' : props.isMobile ? '100%' : '1300px'
}))`
    z-index: 0;
    flex: 1 1 0%;
    background: ${props => props.background};
    width: ${props => props.width};
    max-width: ${props => props.maxWidth};
    margin: 0 auto;
`;

const MarketingPageWrapper100vhStyled = styled.div.attrs(props => ({
    width: props.fullwidth ? (props.isMobile ? '100%' : '1300px') : '100%'
}))`
    display: flex;
    flex-direction: column;
    max-width: ${props => props.width};
    margin: 0 auto;
`;

const MarketingPageWrapper = props => (
    <MarketingPageWrapperStyled {...props}>
        <MarketingPageWrapper100vhStyled {...props}>{props.children}</MarketingPageWrapper100vhStyled>
    </MarketingPageWrapperStyled>
);

export default MarketingPageWrapper;
