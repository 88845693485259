import React, { useMemo, useState, useRef } from 'react';
import { Box, Flex, Button } from 'rebass';
import { FiArrowDown, FiMoreVertical, FiRefreshCw, FiXCircle } from 'react-icons/fi';
import styled from 'styled-components';

import helpers from 'utils/helpers';
import NiceDropdown from 'components/NiceDropdown';
import { Card, CardBody, CardFooter } from 'components/Card';
import moment from 'utils/moment';
import Portal from 'components/Portal';
import Positioner from 'components/Positioner';
import DataBar from 'components/DataBar';

const SortArrow = styled(FiArrowDown)`
    height: 20px;
    font-size: 14px;
    margin-left: 4px;
    color: ${props => (props.active ? '#0A73EB' : '#6B7A94')};
    transition: all 0.2s ease-in-out;
    transform: ${props => (props.direction === 'asc' ? 'rotate(180deg)' : 'rotate(0deg)')};
`;

const sortFunctions = {
    property: {
        asc: (a, b) => {
            if ((typeof a[0] === 'string' && isNaN(a[0])) || (typeof b[0] === 'string' && isNaN(b[0]))) {
                return a[0].localeCompare(b[0]);
            }
            return parseFloat(a[0]) - parseFloat(b[0]);
        },
        desc: (a, b) => {
            if ((typeof a[0] === 'string' && isNaN(a[0])) || (typeof b[0] === 'string' && isNaN(b[0]))) {
                return b[0].localeCompare(a[0]);
            }
            return parseFloat(b[0]) - parseFloat(a[0]);
        }
    },
    quantity: {
        asc: (a, b) => a[1] - b[1],
        desc: (a, b) => b[1] - a[1]
    }
};

const defaultSort = () => null;

/**
 * Component to display bar chart.
 *
 * @param {Object} param0
 * @param {Array<[string, number]>} param0.categories
 * @param {number} param0.total
 * @param {null | (a: any, b: any) => number} param0.sortFn
 * @returns {BarChart}
 */
export function BarChart({ categories, total, sortFn }) {
    // We don't want to lose the default order of the data thats why we are using toSorted
    // When we use this method we don't mutate the original data
    return (
        <Box>
            {categories.toSorted(sortFn).map(([category, value]) => (
                <DataBar key={category} total={total} value={value} title={category} />
            ))}
        </Box>
    );
}

/**
 * @deprecated
 * Component to display result list.
 *
 * @param {Object} param0
 * @param {Array<[string, number]>} param0.categories
 * @param {number} param0.total
 * @param {(a: any, b: any) => number} param0.sortFn
 * @returns {DataList}
 */
export function DataList({ categories, total, sortFn }) {
    return (
        <Box>
            {categories.sort(sortFn).map(([category, value]) => (
                <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    key={category}
                    height="40px"
                    sx={{ borderBottom: '1px solid #495F781F' }}
                >
                    <Box className="medium text-primary">{category}</Box>
                    <Box>
                        <span className="medium text-primary">{helpers.numberWithCommas(value)}</span>
                        &nbsp;&nbsp;
                        <span className="text-secondary-dark">({helpers.getPercentage(value, total, 1)}%)</span>
                    </Box>
                </Flex>
            ))}
        </Box>
    );
}

/**
 * Dashboard statistic chart card
 *
 * @param {Object} props
 * @param {Array<[string, number]>} props.data Record data to display
 * @param {string} props.title Title of the table
 * @param {string} props.panelId Panel id
 * @param {Function} props.onDelete Delete handler
 * @param {Function} props.onRefresh Refresh handler
 * @param {Date|string} props.updatedAt Last update date of the statistic
 * @param {boolean} props.isSearchResult Whether the card is a search result
 * @param {number} props.total Total number of records
 */
export function DashboardChartCard({ data, title, panelId, onDelete, onRefresh, updatedAt, isSearchResult, total }) {
    const [isDropdownOpen, setIsDropdownOpen] = useState(false);
    const [sorter, setSorter] = useState(null);
    const sortFn = useMemo(() => {
        if (!sorter) return defaultSort;

        return sortFunctions[sorter.column][sorter.direction];
    }, [sorter]);
    const controllerRef = useRef(null);

    const changeSorting = column => {
        // If no sorter, set sorter to desc
        if (!sorter) return setSorter({ column, direction: 'desc' });

        // If sorter is not the same column, set sorter to desc
        if (sorter.column !== column) return setSorter({ column, direction: 'desc' });

        // If sorter is the same column, toggle direction
        if (sorter.direction === 'desc') return setSorter({ column, direction: 'asc' });

        // otherwise, set sorter to null (default state)
        return setSorter(null);
    };

    return (
        <Card className="relative data-bar-card">
            {!isSearchResult && (
                <Button
                    ref={controllerRef}
                    className="absolute"
                    style={{ top: '12px', right: '12px' }}
                    variant="transparent-icon"
                    onClick={() => setIsDropdownOpen(true)}
                >
                    <FiMoreVertical />
                </Button>
            )}
            <Portal>
                <Positioner controllerRef={controllerRef} isVisible={isDropdownOpen}>
                    <NiceDropdown
                        style={{ inset: 'auto', position: 'static' }}
                        width="120px"
                        onClose={() => setIsDropdownOpen(false)}
                        items={[
                            {
                                title: 'Re-calculate',
                                icon: <FiRefreshCw />,
                                onClick: onRefresh
                            },
                            {
                                title: 'Delete',
                                icon: <FiXCircle />,
                                color: 'red',
                                onClick: onDelete
                            }
                        ]}
                    />
                </Positioner>
            </Portal>

            <CardBody>
                <Flex justifyContent="space-between" alignItems="center">
                    <Box as="p" className="fs-16 bold text-primary">
                        {title}
                    </Box>
                    {/*<GoLink to={`/panel?panelIdSelected=${panelId}`}>Go to the list</GoLink>*/}
                </Flex>
                <br />
                <Flex
                    justifyContent="space-between"
                    alignItems="center"
                    height="40px"
                    sx={{ borderBottom: '1px solid #495F781F' }}
                >
                    <Box
                        as="span"
                        sx={{ cursor: 'pointer' }}
                        lineHeight="20px"
                        className="text-secondary-dark"
                        onClick={() => changeSorting('property')}
                    >
                        Property{' '}
                        <SortArrow
                            active={sorter && sorter.column === 'property'}
                            direction={sorter && sorter.column === 'property' && sorter.direction}
                        />
                    </Box>
                    <Box
                        sx={{ cursor: 'pointer' }}
                        as="span"
                        lineHeight="20px"
                        className="text-secondary-dark"
                        onClick={() => changeSorting('quantity')}
                    >
                        Quantity{' '}
                        <SortArrow
                            direction={sorter && sorter.column === 'quantity' && sorter.direction}
                            active={sorter && sorter.column === 'quantity'}
                        />
                    </Box>
                </Flex>
                <Box height="320px" overflow="auto">
                    <BarChart categories={data} sortFn={sortFn} total={total} />
                </Box>
            </CardBody>
            <CardFooter>
                {!isSearchResult && (
                    <Box as="p" className="color-text-secondary fs-body-12">
                        Last calculated: {moment(updatedAt).fromNow()}
                    </Box>
                )}
            </CardFooter>
        </Card>
    );
}

export default DashboardChartCard;
