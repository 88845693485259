import { useCallback, useEffect } from 'react';

export const useKeyPress = actions => {
    const handleKeyPress = useCallback(e => {
        const { key } = e;

        actions.map(action => {
            if (
                !['INPUT', 'TEXTAREA'].includes(e.target.nodeName) &&
                !e.target.classList.contains('ql-editor') &&
                key === action.keyLabel.toLowerCase()
            ) {
                action.actionCB();
                e.stopPropagation();
                e.preventDefault();
                return true;
            }
            return false;
        });
    }, []);

    useEffect(() => {
        document.addEventListener('keydown', handleKeyPress);

        return () => {
            document.removeEventListener('keydown', handleKeyPress);
        };
    }, []);
};
