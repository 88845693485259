import React from 'react';
import styled from 'styled-components';
import { Flex } from 'rebass';

const Circle = styled.div.attrs({
    className: 'circle'
})`
    width: 8px;
    height: 8px;
`;

const Container = styled(Flex).attrs({
    className: 'fs-accent-14'
})`
    align-items: center;
    gap: 8px;
`;

const colorClassNames = {
    green: {
        statusCircle: 'color-bg-success',
        text: 'color-text-primary'
    },
    orange: {
        statusCircle: 'color-bg-warning',
        text: 'color-text-primary'
    },
    danger: {
        statusCircle: 'color-bg-danger',
        text: 'color-text-primary'
    },
    gray: {
        statusCircle: 'color-bg-text-muted',
        text: 'color-text-secondary'
    }
};

/**
 * StatusLabel component. Displays a colored circle and a text label.
 *
 * @param {Object} param0
 * @param {keyof colorClassNames} param0.color The color of the circle. If not one of the predefined colors, it will default to red.
 * @param {string} param0.status The text to display
 * @param {string} param0.className
 * @param {Object} param0.style
 */
const StatusLabel = ({ color, status, className, style }) => {
    const colorClassName = colorClassNames[color] || colorClassNames.danger;

    return (
        <Container className={className} style={style}>
            <Circle className={colorClassName.statusCircle} />
            <div className={colorClassName.text}>{status}</div>
        </Container>
    );
};

export default StatusLabel;
