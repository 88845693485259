import React, { useMemo } from 'react';

import DropdownSelect from 'components/DropdownSelect';
import { FALSY_FORM_VALUES, TRUTHY_FORM_VALUES } from 'utils/constants';

/**
 * A list of boolean values for panel properties.
 */
export const BooleanPanelProperty = {
    true: {
        id: 1,
        title: 'Yes'
    },
    false: {
        id: 0,
        title: 'No'
    }
};

/**
 * A dropdown select component for panel property options.
 * This component is basically used in the ReactFormBuilder in the dynamic zone.
 *
 * @param {Object} props
 * @param {string} props.columnId The id of the panel property.
 * @param {Object[]} props.columns The list of panel properties.
 * @param {string} props.columns[].id The id of the panel property.
 * @param {string|number|boolean} props.value The selected item value.
 * @param {(id: string) => void} props.onChange The function to call when the selected item changes.
 * @param {string} props.className The class name to apply to the dropdown.
 */
export function PanelPropertyOptionsSelect({ columnId, columns, value, className, onChange }) {
    const items = useMemo(() => {
        const propertyColumn = columns.find(column => column.id === columnId);

        if (!propertyColumn) return [];

        // If the panel property is a boolean, we need to return a list of items with the values 1 and 0.
        if (propertyColumn.type === 'boolean') return Object.values(BooleanPanelProperty);

        // Otherwise, we return the list of items from the panel property definition.
        // Definition is an array of objects with a value and a label.
        return propertyColumn.definition.map(item => ({
            id: item.value,
            title: item.label
        }));
    }, [columnId, columns]);
    const internalValue = useMemo(() => {
        const propertyColumn = columns.find(column => column.id === columnId);

        if (value == null || items.length <= 0) return null;

        let item = null;
        if (propertyColumn.type === 'boolean') {
            // If the value is not a boolean, we return null.
            if (![...FALSY_FORM_VALUES, ...TRUTHY_FORM_VALUES].includes(value)) return null;

            // Get the item with the id 0 or 1 depending on the value.
            const isFalsy = FALSY_FORM_VALUES.includes(value);
            item = items.find(item => (isFalsy ? item.id === 0 : item.id === 1));
        } else {
            item = items.find(item => item.id === value);
        }

        if (!item) return null;

        return {
            id: value,
            title: item.title
        };
    }, [value, items, columns, columnId]);

    return <DropdownSelect className={className} items={items} value={internalValue} onChange={onChange} />;
}

export default PanelPropertyOptionsSelect;
