import React, { useState } from 'react';
import { Box, Button } from 'rebass';
import { FiChevronDown } from 'react-icons/fi';

import NiceDropdown from 'components/NiceDropdown';

/**
 * A dropdown select component.
 *
 * @param {Object} props
 * @param {string} props.className The class name to apply to the dropdown.
 * @param {Object[]} props.items The items to display in the dropdown.
 * @param {string} props.items[].id The id of the item.
 * @param {string} props.items[].title The title of the item.
 * @param {object|null} props.value The selected item.
 * @param {string} props.value.id The id of the selected item.
 * @param {string} props.value.title The title of the selected item.
 * @param {(id: string) => void} props.onChange The function to call when the selected item changes.
 */
export function DropdownSelect({ className, items, value, onChange }) {
    const [isOpen, setIsOpen] = useState(false);

    return (
        <Box className={className} sx={{ position: isOpen ? 'relative' : undefined }}>
            <Button
                variant="secondary-gray"
                style={{ padding: '0px 12px' }}
                width="100%"
                onClick={() => setIsOpen(true)}
                display="flex"
                justifyContent="space-between"
                alignItems="center"
            >
                <Box flexGrow="1" textAlign="left" fontWeight="400">
                    {value && value.title}
                </Box>
                <FiChevronDown style={{ margin: 0 }} />
            </Button>
            {isOpen && (
                <NiceDropdown
                    width="100%"
                    items={items}
                    value={value && value.id}
                    onChange={onChange}
                    onClose={() => setIsOpen(false)}
                />
            )}
        </Box>
    );
}

export default DropdownSelect;
