import React, { useMemo } from 'react';
import { Box } from 'rebass';
import classNames from 'classnames';

import moment from 'utils/moment';

/**
 * Display metadata about the screener and hide it when not printing
 *
 * @param {boolean} isHiddenPrint Indicates if the metadata should be visible when printing
 * @param {string} ip IP address of the respondent
 * @param {Date | string | number} answeredAt Date and time the screener was answered
 * @param {string?} timezone Timezone of the current user
 * @param {React.CSSProperties?} style
 * @param {string?} className
 */
export function ScreenerAnswersMetadata({ isHiddenPrint, ip, answeredAt, timezone, style, className }) {
    const formattedAnsweredAt = useMemo(() => {
        let value = moment(answeredAt);

        if (timezone) {
            value = value.tz(timezone);
        }

        return value.format('YYYY-MM-DD HH:mm:ss z');
    }, [answeredAt, timezone]);

    return (
        <Box className={className} style={style}>
            {!isHiddenPrint && <Box as="hr" my="8px" />}
            <Box className={classNames('fs-body-14 color-text-secondary', isHiddenPrint && 'hidden-print')} as="p">
                IP Address: {ip}
            </Box>
            <Box className={classNames('fs-body-14 color-text-secondary', isHiddenPrint && 'hidden-print')} as="p">
                Answered at: {formattedAnsweredAt}
            </Box>
        </Box>
    );
}

export default ScreenerAnswersMetadata;
