import React from 'react';
import { useToasts } from 'react-toast-notifications';
import { FiLayers } from 'react-icons/fi';

import useQuery from 'hooks/useQuery';
import studyService from 'services/studies';
import services from 'services/services';
import useMutation from 'hooks/useMutation';
import SelectModal from 'components/SelectModal';
import ToastSuccess from 'containers/ParticipantsPage/components/ToastSuccess';

/**
 * The modal for selecting a study to share study people to another study.
 *
 * @param {Object} param0 props
 * @param {() => void} param0.onClose A callback for when the modal closes
 * @param {string} param0.title The title of the modal
 * @param {any} param0.filterObject filter query object that contains the search query etc.
 * @param {boolean} param0.isOpen Whether the modal is open
 * @param {number} param0.sourceStudyId The id of the source study
 * @returns {ShareStudyPeopleModal} The component
 */
export function ShareStudyPeopleModal({ onClose, filterObject, isOpen, title, sourceStudyId }) {
    const { addToast } = useToasts();
    const { data: studies, isLoading: isStudiesLoading } = useQuery({
        queryFn: () => studyService.getStudiesSimple(),
        onError: (_, errorText) => {
            addToast(errorText, {
                appearance: 'error',
                autoDismiss: true
            });
        }
    });
    const { mutate: shareToStudy, isLoading: isSharing } = useMutation({
        mutationFn: async ({ destinationStudyId }) => {
            await services.shareStudyPeople(sourceStudyId, destinationStudyId, filterObject);

            // add people to study doesn't return anything important so we just return the studyId
            return destinationStudyId;
        },
        onSuccess: destinationStudyId => {
            addToast(<ToastSuccess studyId={destinationStudyId} />, {
                appearance: 'success',
                big: true
            });
            onClose();
        },
        onError: (_, errorText) => {
            addToast(errorText, {
                appearance: 'error',
                autoDismiss: false
            });
        }
    });
    const { mutate: createStudy, isLoading: isStudyCreating } = useMutation({
        mutationFn: async ({ studyTitle }) =>
            services.createStudy({
                title: studyTitle,
                description: ''
            }),
        onSuccess: study => {
            shareToStudy({ destinationStudyId: study.id });
        },
        onError: (_, errorText) => {
            addToast(errorText, {
                appearance: 'error',
                autoDismiss: false
            });
        }
    });

    if (isStudiesLoading || !filterObject) return null;

    return (
        <SelectModal
            type="study"
            title={title}
            icon={<FiLayers />}
            items={studies}
            isOpen={isOpen}
            isLoading={isStudiesLoading || isSharing || isStudyCreating}
            onRequestClose={onClose}
            onSelect={destinationStudyId => shareToStudy({ destinationStudyId })}
            onCreate={studyTitle => createStudy({ studyTitle })}
        />
    );
}

export default ShareStudyPeopleModal;
