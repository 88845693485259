import SortableElement from './sortable-element';
import PlaceHolder from './form-place-holder';
import BaseFormElements from './form-elements';

const {
    Header,
    Paragraph,
    Label,
    LineBreak,
    TextInput,
    NumberInput,
    TextArea,
    Dropdown,
    Checkboxes,
    DatePicker,
    RadioButtons,
    Image,
    Rating,
    Tags,
    Signature,
    HyperLink,
    Download,
    Camera,
    RangeElement,
    Matrix,
    AddPipeInput,
    FileUpload,
    HiddenValue,
    Captcha
} = BaseFormElements;

const FormElements = {};

/*FormElements.Header = SortableElement(Header);
FormElements.Paragraph = SortableElement(Paragraph);
FormElements.Label = SortableElement(Label);
FormElements.LineBreak = SortableElement(LineBreak);
FormElements.TextInput = SortableElement(TextInput);
FormElements.NumberInput = SortableElement(NumberInput);
FormElements.TextArea = SortableElement(TextArea);
FormElements.Dropdown = SortableElement(Dropdown);
FormElements.Signature = SortableElement(Signature);
FormElements.Checkboxes = SortableElement(Checkboxes);
FormElements.Matrix = SortableElement(Matrix);
FormElements.DatePicker = SortableElement(DatePicker);
FormElements.RadioButtons = SortableElement(RadioButtons);
FormElements.Image = SortableElement(Image);
FormElements.Rating = SortableElement(Rating);
FormElements.Tags = SortableElement(Tags);
FormElements.HyperLink = SortableElement(HyperLink);
FormElements.Download = SortableElement(Download);
FormElements.Camera = SortableElement(Camera);
FormElements.RangeElement = SortableElement(RangeElement);
FormElements.PlaceHolder = SortableElement(PlaceHolder);*/

FormElements.Header = Header;
FormElements.Paragraph = Paragraph;
FormElements.Label = Label;
FormElements.LineBreak = LineBreak;
FormElements.TextInput = TextInput;
FormElements.NumberInput = NumberInput;
FormElements.TextArea = TextArea;
FormElements.Dropdown = Dropdown;
FormElements.Signature = Signature;
FormElements.Checkboxes = Checkboxes;
FormElements.Matrix = Matrix;
FormElements.DatePicker = DatePicker;
FormElements.RadioButtons = RadioButtons;
FormElements.Image = Image;
FormElements.Rating = Rating;
FormElements.Tags = Tags;
FormElements.HyperLink = HyperLink;
FormElements.Download = Download;
FormElements.Camera = Camera;
FormElements.RangeElement = RangeElement;
FormElements.PlaceHolder = PlaceHolder;
FormElements.AddPipeInput = AddPipeInput;
FormElements.FileUpload = FileUpload;
FormElements.HiddenValue = HiddenValue;
FormElements.Captcha = Captcha;

export default FormElements;
