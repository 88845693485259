import React, { useState, useEffect } from 'react';

import cloneDeep from 'lodash/cloneDeep';
import { Link } from 'react-router-dom';
import ReactModal from 'react-modal';
import { Helmet } from 'react-helmet';
import { useToasts } from 'react-toast-notifications';
import H1 from 'components/H1';
import H2 from 'components/H2';
import { Flex, Box, Button } from 'rebass';
import { Input, Select, Label } from '@rebass/forms';
import helpers from 'utils/helpers';
import services from 'services/services';
import service_accounts from 'services/accounts';
import config from 'config';
import { BreadcrumbBack } from 'components/BreadcrumbBack';

import { EmailTemplateTypeLabel } from 'components/EmailTemplateTypeLabel';
import VariablesHelper from 'components/EmailTools/VariablesHelper';
import EmailEditor from 'components/EmailTools/EmailEditor';
import AppPageWrapper from 'components/AppPageWrapper';
import AppPageWrapperSectionHeader from 'components/AppPageWrapper/AppPageWrapperSectionHeader';
import AppPageWrapperSectionBody from 'components/AppPageWrapper/AppPageWrapperSectionBody';
import AppPageWrapperSectionSubHeader from 'components/AppPageWrapper/AppPageWrapperSectionSubHeader';
import SettingsPageSubNav from 'containers/SettingsPage/SettingsPageSubNav';
import AppPageSubnav from 'components/AppPageWrapper/AppPageSubnav';
import ListWrapper from 'components/ListWrapper';
import ItemWrapper from 'components/ItemWrapper';
import InputSearch from 'components/InputSearch';
import { EMAIL_TEMPLATE_TYPE } from 'utils/constants';
let saveDelay = null;

function SettingsEmailTemplateIndividualPage(props) {
    const [emailTemplate, setEmailTemplate] = useState({});
    const { addToast } = useToasts();

    useEffect(() => {
        getEmailTemplateXHR();
    }, []);

    function getEmailTemplateXHR() {
        //console.log(props.match)
        service_accounts.getEmailTemplate(props.match.params.id).then(email_template => {
            if (email_template.type === EMAIL_TEMPLATE_TYPE.HTML) {
                email_template.body = helpers.isObject(email_template.body)
                    ? JSON.stringify(email_template.body)
                    : email_template.body || '';
            } else {
                email_template.body = helpers.isObject(email_template.body)
                    ? email_template.body
                    : helpers.makeBlocksFromText(email_template.body);
            }

            setEmailTemplate(email_template);
        });
    }

    function onChangeEmailTemplate(key, val, extra = {}) {
        let et = cloneDeep(emailTemplate);
        et[key] = val;

        // Handle template type change
        if (extra.type) {
            et.type = extra.type;
        }

        setEmailTemplate(et);

        // detect change, and clear timeout if exists
        if (saveDelay) {
            clearTimeout(saveDelay);
        }

        saveDelay = setTimeout(() => {
            updateEmailTemplateXHR(et);
        }, 500);
    }

    /**
     * Update email template with new body and subject when user selects a template
     *
     * @param {{ body: any; subject: string; type: number; id: number }} template
     */
    function onTemplate(template) {
        const emailTemplateCopy = cloneDeep(emailTemplate);

        emailTemplateCopy.body = template.body;
        emailTemplateCopy.subject = template.subject;
        emailTemplateCopy.type = template.type;

        setEmailTemplate(emailTemplateCopy);
        updateEmailTemplateXHR(emailTemplateCopy);
    }

    function updateEmailTemplateXHR(emailTemplate_arg) {
        console.log(emailTemplate_arg);

        //setEmailTemplate(prev => ({...prev, [key]: val }));

        //return;
        service_accounts
            .updateEmailTemplate(emailTemplate_arg)
            .then(email_template => {
                /*addToast('Successfully saved', {
                    appearance: 'success',
                    autoDismiss: true
                });*/
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);

                addToast(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    return (
        <Flex style={{ width: '100%' }}>
            <AppPageWrapper>
                <Helmet>
                    <title>Email Template</title>
                </Helmet>
                <SettingsPageSubNav sx={{ marginBottom: 0 }} />
                <AppPageWrapperSectionHeader
                    css={{ marginBottom: '24px' }}
                    className="experience experience-border experience-no-subnav"
                >
                    <Box>
                        <BreadcrumbBack to="/settings/email-templates">Back to all email templates</BreadcrumbBack>
                        <H1 className="ellipsis">{emailTemplate.title}</H1>
                    </Box>
                </AppPageWrapperSectionHeader>
                <AppPageWrapperSectionBody>
                    {emailTemplate.id > 0 && (
                        <Flex mx={4} mb={4} style={{ width: '100%', flexDirection: 'column' }}>
                            <Flex className="form-wrapper2" width="100%" flexDirection="column">
                                <Flex sx={{ gridGap: '32px' }}>
                                    <Box flex="2">
                                        <Box className="form-label">Email Template Title</Box>
                                        <Box>
                                            <Input
                                                type="text"
                                                name="title"
                                                value={emailTemplate.title}
                                                onChange={e => onChangeEmailTemplate('title', e.target.value)}
                                                placeholder="Title"
                                            />
                                        </Box>
                                    </Box>
                                    <Box flex="1"></Box>
                                </Flex>
                                <Flex sx={{ gridGap: '32px' }} alignItems="flex-end" my="24px">
                                    <Box flex="2">
                                        <Box className="form-label">Email Subject</Box>
                                        <Box>
                                            <Input
                                                type="text"
                                                name="subject"
                                                value={emailTemplate.subject}
                                                onChange={e => onChangeEmailTemplate('subject', e.target.value)}
                                                placeholder="Subject"
                                            />
                                        </Box>
                                    </Box>
                                    <VariablesHelper templateType={emailTemplate.type} />
                                    <EmailTemplateTypeLabel type={emailTemplate.type} />
                                </Flex>
                            </Flex>
                            {emailTemplate.body != null && (
                                <Box sx={{ width: '100%' }}>
                                    <EmailEditor
                                        type={emailTemplate.type}
                                        editorData={emailTemplate.body}
                                        onChange={(editorData, editorType) =>
                                            onChangeEmailTemplate('body', editorData, { type: editorType })
                                        }
                                        onTemplate={onTemplate}
                                        allowScreenerButton={true}
                                        allowDscoutButton={true}
                                    />
                                </Box>
                            )}
                        </Flex>
                    )}
                </AppPageWrapperSectionBody>
            </AppPageWrapper>
        </Flex>
    );
}

export default SettingsEmailTemplateIndividualPage;
