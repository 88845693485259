import React from 'react';
import { Flex, Box } from 'rebass';
import styled from 'styled-components';

const Container = styled(Flex)`
    align-items: center;
`;

const Line = styled(Box)`
    background: #d4d9dd;
    height: 1px;
    flex-grow: 1;
`;

const Text = styled(Box)`
    font-size: 16px;
    font-weight: 500;
    color: #929db0;
    padding: 0 8px 2px 8px;
`;

const LoginPageOrDivider = () => (
    <Container my="24px">
        <Line />
        <Text>or</Text>
        <Line />
    </Container>
);

export default LoginPageOrDivider;
