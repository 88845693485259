import React, { useState, useEffect } from 'react';
import { Box, Button } from 'rebass';
import { FiChevronDown, FiEdit } from 'react-icons/fi';
import NiceDropdown from 'components/NiceDropdown';

function TimezoneDropdown(props) {
    const [timezoneSelected, setTimezoneSelected] = useState(props.timezoneSelected);
    const [showTimezoneDropdown, setShowTimezoneDropdown] = useState(false);

    //console.log('props.timezones', props.timezones)
    //console.log('props.timezoneSelected', props.timezoneSelected)

    return (
        <Box sx={{ position: 'relative' }}>
            {props.displayStyle == 'editIcon' && (
                <span className="text-primary bold" style={{ lineHeight: '32px' }}>
                    {timezoneSelected ? props.timezones[timezoneSelected] : <em>No timezone set</em>}{' '}
                </span>
            )}
            <Button
                type="button"
                variant={props.displayStyle == 'editIcon' ? 'link' : 'secondary-gray'}
                onClick={() => setShowTimezoneDropdown(true)}
            >
                {props.displayStyle == 'editIcon' ? (
                    ''
                ) : timezoneSelected ? (
                    <>{props.timezones[timezoneSelected]} </>
                ) : (
                    <>
                        <em>No timezone set</em>{' '}
                    </>
                )}
                {props.displayStyle == 'editIcon' ? (
                    <FiEdit style={{ marginRight: 0, marginLeft: '6px' }} />
                ) : (
                    <FiChevronDown style={{ marginRight: 0 }} />
                )}
            </Button>
            {showTimezoneDropdown && (
                <NiceDropdown
                    positionRight={props.displayStyle == 'editIcon' ? '0px' : 'auto'}
                    width="180px"
                    maxWidth="180px"
                    showSearch
                    onClose={() => setShowTimezoneDropdown(false)}
                    items={Object.keys(props.timezones).map(tzKey => {
                        return {
                            id: tzKey,
                            title: props.timezones[tzKey]
                        };
                    })}
                    onChange={value => {
                        setTimezoneSelected(value);
                        props.onChange(value);
                    }}
                />
            )}
        </Box>
    );
}

export default TimezoneDropdown;
