import React, { useState } from 'react';
import { Box, Flex, Button } from 'rebass';
import { FiCheckCircle, FiAlertCircle, FiChevronDown } from 'react-icons/fi';
import NiceDropdown from 'components/NiceDropdown';

/**
 * Select that allows to select a property from a list of properties.
 * Characteristic is that it shows a checkmark if a property is selected.
 *
 * @param {Object} props
 * @param {Array} props.items List of items to select from
 * @param {boolean} props.isStatusIcon Whether to show a status icon
 * @param {string} props.selectedItemLabel Label of the selected item
 * @param {Function} props.onSelect Function to call when an item is selected
 * @param {boolean} props.showSearch Whether to show the search bar
 * @param {Function} props.renderBottomStickyButton Function to render a button at the bottom of the dropdown
 */
function PropertySelect({
    items,
    selectedItemLabel,
    onSelect,
    showSearch = true,
    renderBottomStickyButton,
    isStatusIcon = true
}) {
    const [showDropdown, setShowDropdown] = useState(false);

    return (
        <Box sx={{ position: 'relative' }}>
            <Button type="button" variant="transparent" onClick={() => setShowDropdown(true)}>
                <Flex>
                    {selectedItemLabel === null ? (
                        <>
                            {isStatusIcon && (
                                <FiAlertCircle style={{ color: '#F2A001', fontSize: '14px', margin: '4px 10px 0 0' }} />
                            )}
                            <Box style={{ color: '#F2A001' }}>Select value</Box>
                            <FiChevronDown style={{ color: '#F2A001', margin: '4px 8px 0 8px' }} />
                        </>
                    ) : (
                        <>
                            {isStatusIcon && (
                                <FiCheckCircle style={{ color: '#00BA3F', fontSize: '14px', margin: '4px 10px 0 0' }} />
                            )}
                            <Box>{selectedItemLabel}</Box>
                            <FiChevronDown style={{ margin: '4px 8px 0 8px' }} />
                        </>
                    )}
                </Flex>
            </Button>
            {showDropdown && (
                <NiceDropdown
                    height={'138px'}
                    width={'320px'}
                    showSearch={showSearch}
                    revealEllipsis
                    items={items}
                    onChange={onSelect}
                    onClose={() => setShowDropdown(false)}
                    renderBottomStickyButton={renderBottomStickyButton}
                />
            )}
        </Box>
    );
}

export default PropertySelect;
