import React, { useState } from 'react';
import { Box, Flex, Button } from 'rebass';
import { FiChevronDown } from 'react-icons/fi';
import PanelDropdown from 'components/PanelDropdown';

function PanelSelect(props) {
    const [showDropdown, setShowDropdown] = useState(false);

    const selectedItem = props.items.find(item => item.id === props.value);
    const title = selectedItem ? selectedItem.title : '';

    return (
        <Box sx={{ position: 'relative' }}>
            {props.variant === 'transparent' ? (
                <Button
                    type="button"
                    variant="transparent"
                    style={{
                        fontWeight: 400,
                        height: 'auto'
                    }}
                    onClick={() => setShowDropdown(true)}
                >
                    <Flex>
                        <Box>{title}</Box>
                        <Box>
                            <FiChevronDown style={{ margin: '4px 0 0 4px' }} />
                        </Box>
                    </Flex>
                </Button>
            ) : (
                <Button
                    type="button"
                    variant="secondary-gray"
                    style={{
                        width: props.width,
                        fontWeight: 400,
                        padding: '0 12px',
                        backgroundColor: props.variant === 'gray' ? '#fafafa' : '#fff'
                    }}
                    onClick={() => setShowDropdown(true)}
                >
                    <Flex justifyContent="space-between">
                        <Box>{title}</Box>
                        <Box>
                            <FiChevronDown
                                style={{
                                    marginRight: 0,
                                    marginLeft: '4px',
                                    marginTop: '4px'
                                }}
                            />
                        </Box>
                    </Flex>
                </Button>
            )}
            <PanelDropdown
                {...props}
                showDropdown={showDropdown}
                onClose={() => setShowDropdown(false)}
                onCreatePanel={() => {
                    setShowDropdown(false);
                    props.onCreatePanel();
                }}
            />
        </Box>
    );
}

export default PanelSelect;
