import React from 'react';
import styled, { css } from 'styled-components';

export const colors = {
    purple: {
        background: '#eadefc',
        color: '#4003bd'
    },
    yellow: {
        background: '#FFFAF0',
        color: '#F2A001'
    },
    green: {
        background: '#F0FFF3',
        color: '#0A7723'
    },
    blue: {
        background: '#D1E9FF',
        color: '#0035C9'
    }
};

/**
 * Rich icon background. Used to display an icon with a colored background.
 * The icon is centered in the middle of the background and colored according to the color prop.
 */
export const RichIconBackground = styled.span`
    border-radius: 4px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 24px;
    height: 24px;
    min-width: 24px;
    min-height: 24px;
    text-align: center;
    font-size: 12px;
    vertical-align: middle;

    ${props => css`
        background: ${colors[props.color] ? colors[props.color].background : '#eadefc'};
        color: ${colors[props.color] ? colors[props.color].color : '#4003bd'};
    `}
`;

export default RichIconBackground;
