import React from 'react';
import auth from 'services/auth';
import config from 'config';
import EventEmitter from 'utils/events';

import LoginPageWrapper from 'containers/LoginPage/LoginPageWrapper';
import SignUpPage from 'containers/LoginPage/SignUpPage';
import SignInPage from 'containers/LoginPage/SignInPage';

class LoginPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            isSignUpPage: this.props.match.path === '/signup'
        };
    }

    loginGoogle() {
        auth.clearStorage();
        const baseURL = config.API_URL;
        const browser_timezone = Intl.DateTimeFormat().resolvedOptions().timeZone;
        window.location.replace(`${baseURL}/login-provider/google?timezone=${browser_timezone}`);
    }

    loginAzure() {
        auth.clearStorage();
        const baseURL = config.API_URL;
        window.location.replace(`${baseURL}/login-provider/azure`);
    }

    dispatchAuthEvent() {
        EventEmitter.dispatch('auth.update');
    }

    componentDidMount() {}

    render() {
        return (
            <LoginPageWrapper title={this.state.isSignUpPage ? 'Sign up' : 'Log in'}>
                {this.state.isSignUpPage ? (
                    <SignUpPage
                        googleAuth={this.loginGoogle}
                        microsoftAuth={this.loginAzure}
                        dispatchAuthEvent={this.dispatchAuthEvent}
                    />
                ) : (
                    <SignInPage
                        googleAuth={this.loginGoogle}
                        microsoftAuth={this.loginAzure}
                        dispatchAuthEvent={this.dispatchAuthEvent}
                    />
                )}
            </LoginPageWrapper>
        );
    }
}

export default LoginPage;
