import React from 'react';
import moment from 'moment-timezone';
import { Flex } from 'rebass';
import { FiClock, FiGlobe } from 'react-icons/fi';
import { MOMENT_HUMAN_DATE_NO_TZ } from 'utils/constants';

import useIsMobile from 'hooks/useIsMobile';

const SchedulingPageNiceDateTime = props => {
    const isMobile = useIsMobile(520);

    return (
        <Flex style={{ flexDirection: 'column', gap: '12px' }}>
            <Flex style={{ alignItems: 'center', gap: isMobile ? '6px' : '8px' }}>
                <div className="fs-title-16 color-text-primary">
                    {moment
                        .utc(props.spot)
                        .tz(props.timezone)
                        .format(MOMENT_HUMAN_DATE_NO_TZ)}
                </div>
                <div className="horizontal-line color-bg-text-muted" />
                <FiClock className="icon-16 color-text-secondary" />
                <div className="fs-accent-16 color-text-primary" style={{ marginBottom: '1px' }}>
                    {props.duration} {isMobile ? 'min.' : 'minutes'}
                </div>
            </Flex>
            <Flex style={{ alignItems: 'center', gap: '8px' }}>
                <FiGlobe className="icon-16 color-text-primary" />
                <div className="fs-accent-14 color-text-primary">{`${props.timezone} (${moment
                    .tz(props.timezone)
                    .zoneName()})`}</div>
            </Flex>
        </Flex>
    );
};

export default SchedulingPageNiceDateTime;
