import React from 'react';
import { Helmet } from 'react-helmet';
import { withToastManager } from 'react-toast-notifications';
import xhr from 'xhr.js';
import config from 'config';
import auth from 'services/auth';
import ReactTooltip from 'react-tooltip';
import TooltipIcon from 'components/TooltipIcon';
import ReactModal from 'react-modal';
import InputError from 'components/InputError';
import helpers from 'utils/helpers';
import { ACCOUNT_CURRENCIES, CURRENCY_SYMBOL, countryListData } from 'utils/constants';
import QuickCopy from 'components/QuickCopy';
import cloneDeep from 'lodash/cloneDeep';

import { FiEdit, FiImage, FiCopy, FiCheckCircle, FiTrash, FiCamera, FiTrash2, FiXCircle } from 'react-icons/fi';
import H1 from 'components/H1';
import H2 from 'components/H2';
import SideNav from 'components/SideNav';
import { Flex, Box, Button, Heading } from 'rebass';
import { Label, Input, Textarea, Switch, Select } from '@rebass/forms';
import services from 'services/services';
import service_accounts from 'services/accounts';
import service_studies from 'services/studies';
import TextAreaAutosize from 'react-textarea-autosize';

import AppPageWrapper from 'components/AppPageWrapper';
import AppPageSubnav from 'components/AppPageWrapper/AppPageSubnav';
import AppPageWrapperSectionHeader from 'components/AppPageWrapper/AppPageWrapperSectionHeader';
import AppPageWrapperSectionBody from 'components/AppPageWrapper/AppPageWrapperSectionBody';
import SettingsPageSubNav from 'containers/SettingsPage/SettingsPageSubNav';

import LoadingIndicator from 'components/LoadingIndicator';
import LoadingWrapper from 'components/LoadingIndicator/LoadingWrapper';

import NiceModal from 'components/NiceModal';
import ReactQuill from 'react-quill';
import { QUILL_MODULES } from '../../utils/constants';
import { AllowedCountries } from './Modals/AllowedCountries';
import moment from 'moment-timezone';
import { Link } from 'react-router-dom';

class SettingsPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            account: this.props.auth.account,
            uploadingAccountLogo: false,
            uploadingScreenBg: false,
            uploadingFavicon: false,
            showModalCreateDomain: false,
            onCreateDomain_loading: false,
            showModalVerifyDomain: false,
            showModalAllowedCountries: false,
            onVerifyDomain_loading: false,
            domain_selected: {},
            sender_profile_selected: {},
            showModalSenderProfile: false,
            sender_profiles: [],
            sender_profiles_loading: true,
            selectedEt: '',
            tremendous_campaigns: []
        };

        this.onFormChange = this.onFormChange.bind(this);
        this.accountLogoUploadButton = this.accountLogoUploadButton.bind(this);
        this.accountLogoRemoveButton = this.accountLogoRemoveButton.bind(this);
        this.screenerBgUploadButton = this.screenerBgUploadButton.bind(this);
        this.screenerBgRemoveButton = this.screenerBgRemoveButton.bind(this);
        this.faviconUploadButton = this.faviconUploadButton.bind(this);
        this.faviconRemoveButton = this.faviconRemoveButton.bind(this);

        this.handleCloseModalSenderProfile = this.handleCloseModalSenderProfile.bind(this);
        this.onCreateSenderProfile = this.onCreateSenderProfile.bind(this);
        this.onSubmitFormSenderProfile = this.onSubmitFormSenderProfile.bind(this);

        this.handleCloseModalCreateDomain = this.handleCloseModalCreateDomain.bind(this);
        this.onCreateDomain = this.onCreateDomain.bind(this);

        this.handleCloseModalVerifyDomain = this.handleCloseModalVerifyDomain.bind(this);
        this.onVerifyDomain = this.onVerifyDomain.bind(this);

        this.dkimKeyStripDomain = this.dkimKeyStripDomain.bind(this);
        this.returnpathKeyStripDomain = this.returnpathKeyStripDomain.bind(this);
        this.onUpdateSettingsProps = this.onUpdateSettingsProps.bind(this);

        this.organizationRef = React.createRef();
        this.brandingRef = React.createRef();
        this.panelistExperienceRef = React.createRef();
        this.panelistFatigueRef = React.createRef();
        this.emailDomainsRef = React.createRef();
        this.countryRestrictionsRef = React.createRef();
        this.permissionsRef = React.createRef();
    }

    componentDidMount() {
        this.getSenderProfilesXHR();
        const { toastManager } = this.props;

        service_studies
            .getTremendousCampaigns()
            .then(tremendous_campaigns => {
                this.setState({ tremendous_campaigns });
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);
                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });

        // for testing
        /* xhr.get(`/http-response/504`, {
            withCredentials: true
        }); */
    }

    handleCloseModalVerifyDomain() {
        this.setState({ showModalVerifyDomain: false });
    }

    onVerifyDomain(e) {
        e.preventDefault();
        const { toastManager } = this.props;

        this.setState({ onVerifyDomain_loading: true });

        service_accounts
            .verifyDomain(this.state.domain_selected.id)
            .then(domainVerified => {
                this.setState({
                    onVerifyDomain_loading: false,
                    showModalCreateDomain: false,
                    showModalVerifyDomain: false,
                    showModalSenderProfile: true
                });

                let account = this.state.account;

                const found = account.domains.find(d => d.id == domainVerified.id);
                if (found) {
                    const index = account.domains.indexOf(found);
                    account.domains[index] = domainVerified;
                }

                this.setState({ account });

                toastManager.add('Successfully verified domain', {
                    appearance: 'success',
                    autoDismiss: true
                });

                //location.reload();
            })
            .catch(error => {
                this.setState({ onVerifyDomain_loading: false });
                const errorText = services.parseAndTrackXhrErrors(error);

                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    onDeleteDomain(id) {
        if (!confirm('Are you sure? This will delete all related sender profiles.')) {
            return;
        }

        const { toastManager } = this.props;

        service_accounts
            .deleteDomain(id)
            .then(() => {
                toastManager.add('Successfully deleted', {
                    appearance: 'success',
                    autoDismiss: true
                });
                let { account } = this.state;
                const found = account.domains.find(d => d.id == id);
                if (found) {
                    const index = account.domains.indexOf(found);
                    account.domains.splice(index, 1);
                }
                this.setState({ account });
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);

                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    onDeleteSenderProfile(id) {
        if (!confirm('Are you sure?')) {
            return;
        }

        const { toastManager } = this.props;

        service_accounts
            .deleteSenderProfile(id)
            .then(() => {
                toastManager.add('Successfully deleted', {
                    appearance: 'success',
                    autoDismiss: true
                });
                //location.reload();
                this.getSenderProfilesXHR();
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);

                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    handleCloseModalCreateDomain() {
        this.setState({ showModalCreateDomain: false });
    }

    onCreateDomain(e) {
        e.preventDefault();
        const { toastManager } = this.props;

        const domain = document.getElementsByName('create_domain_url');

        this.setState({ onCreateDomain_loading: true });

        service_accounts
            .createDomain(domain[0].value)
            .then(domain => {
                let { account } = this.state;
                account.domains.push(domain);
                this.setState({ account });

                this.setState({
                    domain_selected: domain,
                    showModalCreateDomain: false,
                    onCreateDomain_loading: false,
                    showModalVerifyDomain: true
                });

                toastManager.add('Successfully created domain', {
                    appearance: 'success',
                    autoDismiss: true
                });
            })
            .catch(error => {
                this.setState({ onCreateDomain_loading: false });
                const errorText = services.parseAndTrackXhrErrors(error);

                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    handleCloseModalSenderProfile() {
        this.setState({ showModalSenderProfile: false, sender_profile_selected: {} });
    }

    onSubmitFormSenderProfile(e) {
        e.preventDefault();
        if (this.state.sender_profile_selected.id) {
            this.onUpdateSenderProfile();
        } else {
            this.onCreateSenderProfile();
        }
    }

    onUpdateSenderProfile() {
        const { toastManager } = this.props;

        this.setState({ onCreateSenderProfile_loading: true });

        service_accounts
            .updateSenderProfile(
                this.state.sender_profile_selected.id,
                this.state.sender_profile_selected.email,
                this.state.sender_profile_selected.name
            )
            .then(senderProfile => {
                this.setState({ onCreateSenderProfile_loading: false });
                this.handleCloseModalSenderProfile();

                toastManager.add('Updated sender profile', {
                    appearance: 'success',
                    autoDismiss: true
                });
                this.getSenderProfilesXHR();

                this.setState({
                    sender_profile_selected: {}
                });
            })
            .catch(error => {
                this.setState({ onCreateSenderProfile_loading: false });
                const errorText = services.parseAndTrackXhrErrors(error);

                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    onCreateSenderProfile() {
        const { toastManager } = this.props;

        this.setState({ onCreateSenderProfile_loading: true });

        service_accounts
            .createSenderProfile(this.state.sender_profile_selected.email, this.state.sender_profile_selected.name)
            .then(senderProfile => {
                this.setState({ onCreateSenderProfile_loading: false });

                toastManager.add('Created sender profile', {
                    appearance: 'success',
                    autoDismiss: true
                });

                this.getSenderProfilesXHR();

                this.setState({
                    sender_profile_selected: {}
                });

                this.handleCloseModalSenderProfile();
            })
            .catch(error => {
                this.setState({ onCreateSenderProfile_loading: false });
                const errorText = services.parseAndTrackXhrErrors(error);

                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    isColor(strColor) {
        const s = new Option().style;
        s.color = strColor;
        return s.color !== '';
    }

    accountLogoRemoveButton() {
        const { toastManager } = this.props;

        xhr.delete(`/accounts/${this.props.auth.account.id}/logo`, {
            withCredentials: true
        })
            .then(account_xhr => {
                auth.checkAuth();
                const { account } = this.state;
                account.logo = account_xhr.logo;
                this.setState({
                    account
                });
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);

                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    accountLogoUploadButton() {
        const { toastManager } = this.props;
        document.getElementById('organization_logo').click();
        document.getElementById('organization_logo').onchange = e => {
            this.setState({ uploadingAccountLogo: true });

            const formData = new FormData();
            formData.append('logo', e.target.files[0]);

            console.log(this.props.auth.account.id);

            xhr.post(`/accounts/${this.props.auth.account.id}/logo`, formData, {
                withCredentials: true,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(account_xhr => {
                    auth.checkAuth();
                    const { account } = this.state;
                    account.logo = account_xhr.data.logo;
                    this.setState({
                        uploadingAccountLogo: false,
                        account
                    });
                })
                .catch(error => {
                    this.setState({
                        uploadingAccountLogo: false
                    });

                    const errorText = services.parseAndTrackXhrErrors(error);

                    toastManager.add(errorText, {
                        appearance: 'error',
                        autoDismiss: true
                    });
                });
        };
    }

    screenerBgRemoveButton() {
        const { toastManager } = this.props;

        xhr.delete(`/accounts/${this.props.auth.account.id}/screener-bg`, {
            withCredentials: true
        })
            .then(account_xhr => {
                auth.checkAuth();
                const { account } = this.state;
                account.screener_bg = account_xhr.screener_bg;
                this.setState({
                    account
                });
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);

                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    screenerBgUploadButton() {
        const { toastManager } = this.props;
        document.getElementById('organization_screener_bg').click();
        document.getElementById('organization_screener_bg').onchange = e => {
            this.setState({ uploadingScreenBg: true });

            const formData = new FormData();
            formData.append('screener_bg', e.target.files[0]);

            console.log(this.props.auth.account.id);

            xhr.post(`/accounts/${this.props.auth.account.id}/screener-bg`, formData, {
                withCredentials: true,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(account_xhr => {
                    auth.checkAuth();
                    const { account } = this.state;
                    account.screener_bg = account_xhr.data.screener_bg;
                    this.setState({
                        uploadingScreenBg: false,
                        account
                    });
                })
                .catch(error => {
                    this.setState({
                        uploadingScreenBg: false
                    });
                    const errorText = services.parseAndTrackXhrErrors(error);

                    toastManager.add(errorText, {
                        appearance: 'error',
                        autoDismiss: true
                    });
                });
        };
    }

    faviconRemoveButton() {
        const { toastManager } = this.props;

        xhr.delete(`/accounts/${this.props.auth.account.id}/favicon`, {
            withCredentials: true
        })
            .then(account_xhr => {
                auth.checkAuth();
                const { account } = this.state;
                account.favicon = account_xhr.favicon;
                this.setState({
                    account
                });
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);

                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    faviconUploadButton() {
        const { toastManager } = this.props;
        document.getElementById('organization_favicon').click();
        document.getElementById('organization_favicon').onchange = e => {
            this.setState({ uploadingFavicon: true });

            const formData = new FormData();
            formData.append('favicon', e.target.files[0]);

            //console.log(this.props.auth.account.id);

            xhr.post(`/accounts/${this.props.auth.account.id}/favicon`, formData, {
                withCredentials: true,
                headers: {
                    'Content-Type': 'multipart/form-data'
                }
            })
                .then(account_xhr => {
                    auth.checkAuth();
                    const { account } = this.state;
                    account.favicon = account_xhr.data.favicon;
                    this.setState({
                        uploadingFavicon: false,
                        account
                    });
                })
                .catch(error => {
                    this.setState({
                        uploadingFavicon: false
                    });
                    const errorText = services.parseAndTrackXhrErrors(error);

                    toastManager.add(errorText, {
                        appearance: 'error',
                        autoDismiss: true
                    });
                });
        };
    }

    onDelaySave(account) {
        // detect change, and clear timeout if exists
        if (this.saveDelay) {
            clearTimeout(this.saveDelay);
        }

        // set timer for 200ms
        this.saveDelay = setTimeout(
            function() {
                this.saveAccountXHR(account);
            }.bind(this),
            300
        );
    }

    onFormChange(e) {
        if (!e.target.name) {
            e.preventDefault();
            return;
        }
        const account = { ...this.state.account };
        const key = e.target.name;
        const val = e.target.value;
        if (key.includes('branding.') !== false) {
            const subkey = key.substring(9);
            e.target.setCustomValidity('');
            account.branding[subkey] = val;
        } else if (key == 'allow_session_changes') {
            account['allow_session_changes'] = !account['allow_session_changes'];
        } else {
            account[key] = val;
        }

        this.setState({ account });
        this.onDelaySave(account);
    }

    onSaveAllowedCountries = countryList => {
        const account = { ...this.state.account };
        account['settings']['allowed_countries'] = countryList;
        account['settings']['allow_all_countries'] = !Boolean(countryList.length);
        this.setState({ account });
        this.saveAccountXHR(account);
    };

    onUpdateSettingsProps(e) {
        if (!e.target.name) {
            e.preventDefault();
            return;
        }
        const key = e.target.name;
        const { value } = e.target;
        const account = { ...this.state.account };

        account.settings[key] = value;

        this.setState({ account });
        this.onDelaySave(account);
    }

    renderAllowedCountries = () => {
        const list = [];
        if (this.state.account.settings && this.state.account.settings.allow_all_countries == 1)
            return <div className="text-secondary-dark">Allow all countries</div>;

        if (this.state.account.settings.allowed_countries && this.state.account.settings.allowed_countries.length > 0) {
            this.state.account.settings.allowed_countries.map(countryValue => {
                const filtered = countryListData.filter(item => item.value === countryValue);
                if (filtered) {
                    list.push(filtered[0].label);
                }
            });
            return list.join(', ');
        }
        return false;
    };
    saveAccountXHR(account) {
        const { toastManager } = this.props;

        xhr.put(`/accounts/${account.id}`, account, { withCredentials: true })
            .then(() => {
                console.log('SAVED!!!', account);
                toastManager.add('Saved', {
                    appearance: 'success',
                    autoDismiss: true
                });

                auth.checkAuth();
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);

                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: false
                });
            });
    }

    dkimKeyStripDomain() {
        return this.state.domain_selected.dkimhost.replace('.' + this.state.domain_selected.domain, '');
    }

    returnpathKeyStripDomain() {
        return this.state.domain_selected.returnpathhost.replace('.' + this.state.domain_selected.domain, '');
    }

    getSenderProfilesXHR() {
        const { toastManager } = this.props;

        service_accounts
            .getSenderProfiles()
            .then(sender_profiles => {
                this.setState({ sender_profiles, sender_profiles_loading: false });
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);
                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
                this.setState({ sender_profiles_loading: false });
            });
    }

    renderDomainDns() {
        let jsx = [];

        try {
            if (this.state.domain_selected) {
                this.state.domain_selected.domain_response.forEach(dr => {
                    jsx.push(
                        <Flex
                            justifyContent={'space-between'}
                            sx={{ gridGap: '32px' }}
                            mb={2}
                            className={'text-secondary-dark'}
                        >
                            <Box width={1 / 16}>{dr.type}</Box>
                            <Flex width={8 / 16} className="nowrap">
                                <QuickCopy value={dr.key} />
                                <Box ml={2}>{dr.key}</Box>
                            </Flex>
                            <Flex width={7 / 16} className="nowrap">
                                <QuickCopy value={dr.value} />
                                <Box ml={2}>{dr.value}</Box>
                            </Flex>
                        </Flex>
                    );
                });
            }
        } catch (e) {
            helpers.trackError(e);
            jsx.push('error rendering verification data');
        }

        return (
            <Box mt={4} className="border fs-12" p={3}>
                <Flex justifyContent="space-between" sx={{ gridGap: '32px' }} mb={2} className="text-secondary-dark">
                    <Box width={1 / 16} />
                    <Flex width={8 / 16}>
                        <Box ml={2} className="ellipsis header-row">
                            Key
                        </Box>
                    </Flex>
                    <Flex width={7 / 16}>
                        <Box ml={2} className="ellipsis header-row">
                            Value
                        </Box>
                    </Flex>
                </Flex>
                {jsx}
            </Box>
        );
    }

    render() {
        return (
            <Flex style={{ width: '100%' }}>
                <AppPageWrapper>
                    <Helmet>
                        <title>Organization Settings</title>
                    </Helmet>

                    <Flex>
                        <SettingsPageSubNav />
                    </Flex>

                    <AppPageWrapperSectionHeader className="experience">
                        <Box>
                            <H1>General</H1>
                            <Box className="experience-description text-secondary-dark">
                                Configure your main organization settings.
                            </Box>
                        </Box>
                    </AppPageWrapperSectionHeader>
                    <AppPageWrapperSectionBody>
                        <Flex mt={4} mx={4} flex="1 1 0%" justifyContent="space-between">
                            <Box flex="0 0 auto">
                                <form onSubmit={this.onFormChange}>
                                    <Box
                                        id="organization"
                                        ref={this.organizationRef}
                                        className="form-wrapper2 form-wrapper2-border"
                                    >
                                        <Flex className="settings-header">
                                            <Box className="form-data">
                                                <H2>Organization</H2>
                                                <Box className={`form-section-information`}>
                                                    General organization settings.
                                                </Box>
                                            </Box>
                                        </Flex>
                                        <Box className="form-data">
                                            <Flex
                                                sx={{ gridGap: '24px', width: '100%', justifyContent: 'space-between' }}
                                            >
                                                <Box flexGrow={1}>
                                                    <Box className="form-label">Organization Name</Box>
                                                    <Box className="form-data">
                                                        <Input
                                                            type="text"
                                                            name="title"
                                                            placeholder="Title"
                                                            value={this.state.account.title}
                                                            onChange={this.onFormChange}
                                                        />
                                                    </Box>
                                                </Box>
                                                <Box>
                                                    <Box className="form-label">Default Currency</Box>
                                                    <Box className="form-data">
                                                        <Select
                                                            style={{ maxWidth: '120px' }}
                                                            name="incentive_currency"
                                                            value={this.state.account.incentive_currency}
                                                            onChange={this.onFormChange}
                                                        >
                                                            {ACCOUNT_CURRENCIES.map(c => {
                                                                return (
                                                                    <option value={c}>
                                                                        {CURRENCY_SYMBOL[c]} ({c})
                                                                    </option>
                                                                );
                                                            })}
                                                        </Select>
                                                    </Box>
                                                </Box>
                                            </Flex>
                                        </Box>
                                    </Box>
                                    <Box
                                        id="branding"
                                        ref={this.brandingRef}
                                        className="form-wrapper2 form-wrapper2-border"
                                    >
                                        <Flex className="settings-header">
                                            <Box>
                                                <H2>Branding</H2>
                                                <Box className={`form-section-information`}>
                                                    <span>
                                                        The logo will be used as the header in emails and screeners.
                                                        Primary and secondary colors will be used for buttons and links.{' '}
                                                        <a
                                                            href="#"
                                                            data-beacon-article="6305716886ac2a0f07249b5b"
                                                            className="help-question"
                                                        >
                                                            ?
                                                        </a>
                                                    </span>
                                                </Box>
                                            </Box>
                                        </Flex>
                                        <Box className="form-data">
                                            <Box sx={{ gridGap: '24px' }}>
                                                <Box>
                                                    <Box className="form-label">Logo</Box>
                                                    <Box className="form-data">
                                                        {this.state.account.logo ? (
                                                            <Box mb={2}>
                                                                <img
                                                                    src={this.state.account.logo}
                                                                    style={{
                                                                        width: '48px',
                                                                        borderRadius: '4px',
                                                                        overflow: 'hidden'
                                                                    }}
                                                                    alt="Organization Logo"
                                                                />
                                                            </Box>
                                                        ) : (
                                                            <></>
                                                        )}

                                                        <Button
                                                            type="button"
                                                            variant="link"
                                                            onClick={this.accountLogoUploadButton}
                                                            disabled={this.state.uploadingAccountLogo}
                                                            mr={3}
                                                        >
                                                            {this.state.uploadingAccountLogo ? (
                                                                'Uploading...'
                                                            ) : (
                                                                <>
                                                                    <FiCamera /> Upload image
                                                                </>
                                                            )}
                                                        </Button>
                                                        <input
                                                            type="file"
                                                            id="organization_logo"
                                                            accept=".png, .jpg, .jpeg"
                                                            hidden
                                                        />
                                                        {this.state.account.logo && (
                                                            <Button
                                                                type="button"
                                                                variant="link"
                                                                onClick={this.accountLogoRemoveButton}
                                                            >
                                                                <FiTrash2 /> Remove
                                                            </Button>
                                                        )}
                                                    </Box>
                                                </Box>
                                                <Box className="form-row-margin">
                                                    <Box className="form-label">Screener Background</Box>
                                                    <Box className="form-data">
                                                        {this.state.account.screener_bg ? (
                                                            <Box mb={2}>
                                                                <img
                                                                    src={this.state.account.screener_bg}
                                                                    style={{
                                                                        width: '48px',
                                                                        borderRadius: '4px',
                                                                        overflow: 'hidden'
                                                                    }}
                                                                    alt="Screener Background"
                                                                />
                                                            </Box>
                                                        ) : (
                                                            <></>
                                                        )}

                                                        <Button
                                                            type="button"
                                                            variant="link"
                                                            onClick={this.screenerBgUploadButton}
                                                            disabled={this.state.uploadingScreenBg}
                                                            mr={3}
                                                        >
                                                            {this.state.uploadingScreenBg ? (
                                                                'Uploading...'
                                                            ) : (
                                                                <>
                                                                    <FiCamera /> Upload image
                                                                </>
                                                            )}
                                                        </Button>
                                                        <input
                                                            type="file"
                                                            id="organization_screener_bg"
                                                            accept=".png, .jpg, .jpeg"
                                                            hidden
                                                        />
                                                        {this.state.account.screener_bg && (
                                                            <Button
                                                                type="button"
                                                                variant="link"
                                                                onClick={this.screenerBgRemoveButton}
                                                            >
                                                                <FiTrash2 /> Remove
                                                            </Button>
                                                        )}
                                                    </Box>
                                                </Box>
                                            </Box>
                                            <Flex sx={{ gridGap: '24px', width: '100%' }} className="form-row-margin">
                                                <Box width={1 / 2}>
                                                    <Box className="form-label">Primary Color</Box>
                                                    <Box className="form-data">
                                                        <Flex>
                                                            <Box>
                                                                <Input
                                                                    type="text"
                                                                    name="branding.primary"
                                                                    placeholder="#ffffff"
                                                                    value={this.state.account.branding.primary}
                                                                    onChange={this.onFormChange}
                                                                    style={{ width: '90px' }}
                                                                    mr={3}
                                                                    className={
                                                                        this.state.account.branding.primary &&
                                                                        !this.isColor(
                                                                            this.state.account.branding.primary
                                                                        ) &&
                                                                        'error'
                                                                    }
                                                                />
                                                                {this.state.account.branding.primary &&
                                                                    !this.isColor(
                                                                        this.state.account.branding.primary
                                                                    ) && <InputError>Invalid primary color</InputError>}
                                                            </Box>
                                                            <Box>
                                                                <div
                                                                    style={{
                                                                        display: 'inline-block',
                                                                        width: '32px',
                                                                        height: '32px',
                                                                        borderRadius: '50%',
                                                                        background: this.state.account.branding.primary
                                                                    }}
                                                                />
                                                            </Box>
                                                        </Flex>
                                                    </Box>
                                                </Box>
                                                <Box width={1 / 2}>
                                                    <Box className="form-label">Secondary Color</Box>
                                                    <Box className="form-data">
                                                        <Flex>
                                                            <Box>
                                                                <Input
                                                                    type="text"
                                                                    name="branding.secondary"
                                                                    placeholder="#ffffff"
                                                                    value={this.state.account.branding.secondary}
                                                                    onChange={this.onFormChange}
                                                                    style={{ width: '90px' }}
                                                                    mr={3}
                                                                    className={
                                                                        this.state.account.branding.secondary &&
                                                                        !this.isColor(
                                                                            this.state.account.branding.secondary
                                                                        ) &&
                                                                        'error'
                                                                    }
                                                                />
                                                                {this.state.account.branding.secondary &&
                                                                    !this.isColor(
                                                                        this.state.account.branding.secondary
                                                                    ) && (
                                                                        <InputError>Invalid secondary color</InputError>
                                                                    )}
                                                            </Box>
                                                            <Box>
                                                                <div
                                                                    style={{
                                                                        display: 'inline-block',
                                                                        width: '32px',
                                                                        height: '32px',
                                                                        borderRadius: '50%',
                                                                        background: this.state.account.branding
                                                                            .secondary
                                                                    }}
                                                                />
                                                            </Box>
                                                        </Flex>
                                                    </Box>
                                                </Box>
                                            </Flex>

                                            <Box className="form-row-margin">
                                                <Box>
                                                    <Box className="form-label">Email Footer</Box>
                                                    <Box className="form-data">
                                                        <TextAreaAutosize
                                                            name="email_footer"
                                                            placeholder="Email Footer"
                                                            value={this.state.account.email_footer}
                                                            onChange={this.onFormChange}
                                                            style={{ width: '100%', height: '200px' }}
                                                            className="theme-input"
                                                            mb={4}
                                                        ></TextAreaAutosize>
                                                    </Box>
                                                </Box>
                                                <Box className="form-row-margin">
                                                    <Box className="form-label">Screener Footer</Box>
                                                    <Box className="form-data">
                                                        <TextAreaAutosize
                                                            name="screener_footer"
                                                            placeholder="Screener Footer"
                                                            value={this.state.account.screener_footer}
                                                            onChange={this.onFormChange}
                                                            style={{ width: '100%', height: '200px' }}
                                                            className="theme-input"
                                                            mb={4}
                                                        ></TextAreaAutosize>
                                                    </Box>
                                                </Box>
                                            </Box>

                                            {this.state.account && this.state.account.custom_domain && (
                                                <Flex sx={{ gridGap: '24px' }} className="form-row-margin">
                                                    <Box width={1 / 2}>
                                                        <Box className="form-label">Custom Domain</Box>
                                                        <Box className="form-data">
                                                            <Input
                                                                type="text"
                                                                name="custom_domain"
                                                                placeholder="Custom Domain"
                                                                value={this.state.account.custom_domain}
                                                                disabled
                                                            />
                                                        </Box>
                                                    </Box>
                                                    <Box width={1 / 2}>
                                                        <Box className="form-label">Favicon</Box>
                                                        <Box className="form-data">
                                                            {this.state.account.favicon ? (
                                                                <Box mb={2}>
                                                                    <img
                                                                        src={this.state.account.favicon}
                                                                        style={{
                                                                            width: '16px',
                                                                            borderRadius: '4px',
                                                                            overflow: 'hidden'
                                                                        }}
                                                                        alt="Favicon"
                                                                    />
                                                                </Box>
                                                            ) : (
                                                                <></>
                                                            )}

                                                            <Button
                                                                type="button"
                                                                variant="link"
                                                                onClick={this.faviconUploadButton}
                                                                disabled={this.state.uploadingFavicon}
                                                                mr={3}
                                                            >
                                                                {this.state.uploadingFavicon ? (
                                                                    'Uploading...'
                                                                ) : (
                                                                    <>
                                                                        <FiCamera /> Upload image
                                                                    </>
                                                                )}
                                                            </Button>
                                                            <input
                                                                type="file"
                                                                id="organization_favicon"
                                                                accept="image/x-icon, image/vnd.microsoft.icon"
                                                                hidden
                                                            />
                                                            {this.state.account.favicon && (
                                                                <Button
                                                                    type="button"
                                                                    variant="link"
                                                                    onClick={this.faviconRemoveButton}
                                                                >
                                                                    <FiTrash2 /> Remove
                                                                </Button>
                                                            )}
                                                        </Box>
                                                    </Box>
                                                </Flex>
                                            )}
                                        </Box>
                                    </Box>
                                    <Box
                                        id="panelist-experience"
                                        ref={this.panelistExperienceRef}
                                        className="form-wrapper2 form-wrapper2-border"
                                    >
                                        <Flex className="settings-header">
                                            <Box>
                                                <H2>Panelist Experience</H2>
                                                <Box className={`form-section-information`}>
                                                    Update the look and feel when your panelists login to their
                                                    profiles.{' '}
                                                    <a
                                                        href="#"
                                                        data-beacon-article="63155529037bc877147b5d96"
                                                        className="help-question"
                                                    >
                                                        ?
                                                    </a>
                                                </Box>
                                            </Box>
                                        </Flex>
                                        <Box sx={{ flexWrap: 'wrap', display: 'flex' }}>
                                            <Box width={1 / 3} className="form-label">
                                                Profile Links{' '}
                                                <a
                                                    href="#"
                                                    data-beacon-article="62fb00536d67192dc61bb069"
                                                    className="help-question"
                                                >
                                                    ?
                                                </a>
                                            </Box>
                                            <Box width={2 / 3} className="form-data">
                                                <ul style={{ margin: 0 }}>
                                                    <li>
                                                        <a
                                                            href={`${config.getAccountPrefixUrl(
                                                                this.state.account
                                                            )}/panelist/${this.state.account.uuid}/signup`}
                                                            target="_blank"
                                                        >
                                                            Panel Sign Up
                                                        </a>
                                                    </li>
                                                    <li>
                                                        <a
                                                            href={`${config.getAccountPrefixUrl(
                                                                this.state.account
                                                            )}/panelist/${this.state.account.uuid}/login`}
                                                            target="_blank"
                                                        >
                                                            Panel Log In
                                                        </a>
                                                    </li>
                                                </ul>
                                            </Box>

                                            <Box className="form-label form-row-margin">Profile Introduction</Box>
                                            <Box className="form-data">
                                                <ReactQuill
                                                    theme="snow"
                                                    className="quill-render"
                                                    modules={QUILL_MODULES}
                                                    value={this.state.account.panel_intro_text}
                                                    onChange={html => {
                                                        this.onFormChange({
                                                            target: { name: 'panel_intro_text', value: html }
                                                        });
                                                    }}
                                                />
                                            </Box>
                                            <Box className="form-label form-row-margin">
                                                {helpers.newFeatureTag(moment('09-28-2022', 'MM-DD-YYYY'), {
                                                    margin: '0 8px 0 0px'
                                                })}
                                                Show Panel Points{' '}
                                                <a
                                                    href="#"
                                                    data-beacon-article="6320b8ab90c29a3d732c7242"
                                                    className="help-question"
                                                >
                                                    ?
                                                </a>
                                            </Box>
                                            <Box className="form-data">
                                                <Switch
                                                    mt={2}
                                                    className="switch-small"
                                                    name="pv_points"
                                                    checked={this.state.account.settings.pv_points ? true : false}
                                                    onClick={e => {
                                                        this.onUpdateSettingsProps({
                                                            target: {
                                                                name: 'pv_points',
                                                                // set the opposite
                                                                value: this.state.account.settings.pv_points ? 0 : 1
                                                            }
                                                        });
                                                    }}
                                                />
                                            </Box>
                                            {this.state.account.settings.pv_points ? (
                                                <>
                                                    <Box className="form-label form-row-margin">
                                                        Tremendous Campaign
                                                    </Box>
                                                    <Box className="form-data">
                                                        <Select
                                                            name="points_tremendous_campaign_id"
                                                            value={
                                                                this.state.account.settings
                                                                    .points_tremendous_campaign_id
                                                            }
                                                            onChange={this.onUpdateSettingsProps}
                                                        >
                                                            <option value={''}>None</option>
                                                            {this.state.tremendous_campaigns &&
                                                                this.state.tremendous_campaigns.length > 0 &&
                                                                this.state.tremendous_campaigns.map(tr => {
                                                                    return <option value={tr.id}>{tr.name}</option>;
                                                                })}
                                                        </Select>
                                                    </Box>

                                                    <Box className="form-label form-row-margin">
                                                        Points equal to{' '}
                                                        {helpers.renderCurrencyAmount(
                                                            this.state.account.incentive_currency,
                                                            1
                                                        )}
                                                    </Box>
                                                    <Box className="form-data">
                                                        <Input
                                                            type="number"
                                                            name="points_to_money"
                                                            value={this.state.account.settings.points_to_money}
                                                            onChange={this.onUpdateSettingsProps}
                                                        />
                                                    </Box>

                                                    <Box className="form-label form-row-margin">
                                                        Minimum points to redeem
                                                    </Box>
                                                    <Box className="form-data">
                                                        <Input
                                                            type="number"
                                                            name="points_redeem_min"
                                                            value={this.state.account.settings.points_redeem_min}
                                                            onChange={this.onUpdateSettingsProps}
                                                        />
                                                    </Box>
                                                </>
                                            ) : (
                                                ''
                                            )}

                                            <Box className="form-label form-row-margin">
                                                Allow panelists to reschedule & cancel sessions{' '}
                                                <a
                                                    href="#"
                                                    data-beacon-article="62fadefa61ff5d5f24f9c1ad"
                                                    className="help-question"
                                                >
                                                    ?
                                                </a>
                                            </Box>
                                            <Box className="form-data">
                                                <Switch
                                                    mt={2}
                                                    className="switch-small"
                                                    name="allow_session_changes"
                                                    checked={this.state.account.allow_session_changes ? true : false}
                                                    onClick={e => {
                                                        this.onFormChange({
                                                            target: {
                                                                name: 'allow_session_changes',
                                                                value: e.target.value
                                                            }
                                                        });
                                                    }}
                                                    //onClick={() => this.toggleNotification(key)}
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box
                                        id="panelist-fatigue"
                                        ref={this.panelistFatigueRef}
                                        className="form-wrapper2 form-wrapper2-border"
                                    >
                                        <Flex className="settings-header">
                                            <Box id="panelist-fatigue">
                                                <H2>Panelist Fatigue</H2>
                                                <Box className={`form-section-information`}>
                                                    If you want to prevent adding a panelist to a new study if they've
                                                    participated recently, you can define that here.{' '}
                                                    <a
                                                        href="#"
                                                        data-beacon-article="63ea6daaa2557d37b24bde46"
                                                        className="help-question"
                                                    >
                                                        ?
                                                    </a>
                                                </Box>
                                            </Box>
                                        </Flex>
                                        <Box sx={{ flexWrap: 'wrap', display: 'flex', fontSize: 1 }}>
                                            <Box className="form-label">Email Fatigue</Box>
                                            <Flex className="form-data">
                                                <Input
                                                    type="number"
                                                    name="panelist_fatigue_email"
                                                    placeholder="0"
                                                    value={this.state.account.settings.panelist_fatigue_email}
                                                    style={{ width: '90px' }}
                                                    onChange={this.onUpdateSettingsProps}
                                                />
                                                <Box mt={1} ml={2} className="text-primary">
                                                    days
                                                </Box>
                                            </Flex>
                                            {/*<Box className="form-label form-row-margin">SMS Fatigue</Box>
                                            <Box className="form-data">
                                                <Input
                                                    type="number"
                                                    name="panelist_fatigue_sms"
                                                    placeholder="0"
                                                    value={this.state.account.settings.panelist_fatigue_sms}
                                                    style={{ width: '90px' }}
                                                    onChange={this.onUpdateSettingsProps}
                                                /> Days
                                            </Box>*/}
                                            <Box className="form-label form-row-margin">Scheduling Fatigue</Box>
                                            <Flex className="form-data">
                                                <Input
                                                    type="number"
                                                    name="panelist_fatigue_scheduling"
                                                    placeholder="0"
                                                    value={this.state.account.settings.panelist_fatigue_scheduling}
                                                    style={{ width: '90px' }}
                                                    onChange={this.onUpdateSettingsProps}
                                                />
                                                <Box mt={1} ml={2} className="text-primary">
                                                    days
                                                </Box>
                                            </Flex>
                                            <Box className="form-label form-row-margin">Annual Incentive Fatigue</Box>
                                            <Flex className="form-data">
                                                <Input
                                                    type="number"
                                                    name="panelist_fatigue_incentive"
                                                    placeholder="0"
                                                    value={this.state.account.settings.panelist_fatigue_incentive}
                                                    style={{ width: '90px' }}
                                                    onChange={this.onUpdateSettingsProps}
                                                />
                                                <Box mt={1} ml={2} className="text-primary">
                                                    {this.state.account.incentive_currency}
                                                </Box>
                                            </Flex>
                                        </Box>
                                    </Box>
                                    <Box
                                        id="email-domains"
                                        ref={this.emailDomainsRef}
                                        className="form-wrapper2 form-wrapper2-border"
                                    >
                                        <Flex className="settings-header">
                                            <Box>
                                                <H2>Email Domains</H2>
                                                <Box className={`form-section-information`}>
                                                    Whitelabel and customize the email address you send from.{' '}
                                                    <a
                                                        href="#"
                                                        data-beacon-article="64027d8fe6d6615225475a6d"
                                                        className="help-question"
                                                    >
                                                        ?
                                                    </a>
                                                </Box>
                                            </Box>
                                        </Flex>
                                        <Box sx={{ flexWrap: 'wrap', display: 'flex', fontSize: 1, width: '100%' }}>
                                            <Box className="form-label">Domains</Box>
                                            <Box className="form-data">
                                                {this.state.account.domains && this.state.account.domains.length > 0 ? (
                                                    this.state.account.domains.map(d => {
                                                        return (
                                                            <Flex
                                                                sx={{
                                                                    justifyContent: 'space-between',
                                                                    position: 'relative'
                                                                }}
                                                                className="nice-element rounded fs-13 text-primary"
                                                                mb={3}
                                                            >
                                                                <Button
                                                                    type="button"
                                                                    variant="link"
                                                                    className="link-small red"
                                                                    sx={{
                                                                        position: 'absolute',
                                                                        top: '-11px',
                                                                        right: '-7px'
                                                                    }}
                                                                    onClick={() => this.onDeleteDomain(d.id)}
                                                                >
                                                                    <FiXCircle
                                                                        className="circle"
                                                                        style={{ fontSize: '16px', background: '#fff' }}
                                                                    />
                                                                </Button>
                                                                <Box>{d.domain}</Box>
                                                                <Box sx={{ position: 'relative' }}>
                                                                    {helpers.isDomainVerified(d) ? (
                                                                        <span className="green fs-12">
                                                                            <FiCheckCircle
                                                                                className="va-top fs-13"
                                                                                style={{ marginTop: '4px' }}
                                                                            />{' '}
                                                                            Verified
                                                                        </span>
                                                                    ) : (
                                                                        <>
                                                                            <span className="yellow bold fs-12">
                                                                                Not Verified
                                                                            </span>
                                                                            <Button
                                                                                ml={3}
                                                                                mr={0}
                                                                                variant="primary"
                                                                                className="button-small"
                                                                                onClick={() =>
                                                                                    this.setState({
                                                                                        showModalVerifyDomain: true,
                                                                                        domain_selected: d
                                                                                    })
                                                                                }
                                                                            >
                                                                                Verify
                                                                            </Button>
                                                                        </>
                                                                    )}
                                                                </Box>
                                                            </Flex>
                                                        );
                                                    })
                                                ) : (
                                                    <Box className="text-secondary-dark">
                                                        {/*Please contact support@panelfox.io to set up a custom Email Domain.*/}
                                                    </Box>
                                                )}

                                                <Button
                                                    mt={2}
                                                    type="button"
                                                    variant="secondary"
                                                    onClick={() => this.setState({ showModalCreateDomain: true })}
                                                >
                                                    + Add domain
                                                </Button>
                                            </Box>

                                            {helpers.hasVerifiedDomain(this.state.account) ? (
                                                <>
                                                    <Box width={1 / 3} className="form-label form-row-margin">
                                                        Sender Profiles
                                                    </Box>

                                                    <Box width={2 / 3} className="form-data">
                                                        <>
                                                            {this.state.sender_profiles_loading == true && (
                                                                <div style={{ marginBottom: '44px' }}>
                                                                    <LoadingWrapper>
                                                                        <LoadingIndicator />
                                                                    </LoadingWrapper>
                                                                </div>
                                                            )}

                                                            {this.state.sender_profiles.map(sp => {
                                                                return (
                                                                    <Flex
                                                                        sx={{
                                                                            justifyContent: 'space-between',
                                                                            position: 'relative'
                                                                        }}
                                                                        className="nice-element rounded fs-13 text-primary"
                                                                        mb={3}
                                                                    >
                                                                        <Button
                                                                            variant="link"
                                                                            className="link-small red"
                                                                            sx={{
                                                                                position: 'absolute',
                                                                                top: '-11px',
                                                                                right: '-7px'
                                                                            }}
                                                                            onClick={() =>
                                                                                this.onDeleteSenderProfile(sp.id)
                                                                            }
                                                                        >
                                                                            <FiXCircle
                                                                                className="circle"
                                                                                style={{
                                                                                    fontSize: '16px',
                                                                                    background: '#fff'
                                                                                }}
                                                                            />
                                                                        </Button>
                                                                        <Box>
                                                                            {sp.name}
                                                                            <br />
                                                                            {sp.email}
                                                                        </Box>
                                                                        <Box sx={{ position: 'relative' }}>
                                                                            <Button
                                                                                variant="link"
                                                                                className="link-small va-top"
                                                                                mr={0}
                                                                                onClick={() => {
                                                                                    this.setState({
                                                                                        sender_profile_selected: cloneDeep(
                                                                                            sp
                                                                                        ),
                                                                                        showModalSenderProfile: true
                                                                                    });
                                                                                }}
                                                                            >
                                                                                <FiEdit /> Edit
                                                                            </Button>
                                                                        </Box>
                                                                    </Flex>
                                                                );
                                                            })}

                                                            {!this.state.sender_profiles.length && (
                                                                <Box className="warning-box-small" mb={3}>
                                                                    Create a sender profile to complete email setup.
                                                                </Box>
                                                            )}

                                                            <Button
                                                                type="button"
                                                                variant="secondary"
                                                                onClick={() =>
                                                                    this.setState({ showModalSenderProfile: true })
                                                                }
                                                            >
                                                                + Add sender profile
                                                            </Button>
                                                        </>
                                                    </Box>
                                                </>
                                            ) : (
                                                ''
                                            )}
                                        </Box>
                                        {/* <Label htmlFor="token" mt={3}>Token</Label>
                                        <Box>{this.state.account.token}</Box> */}
                                    </Box>
                                    <Box
                                        id="country-restrictions"
                                        ref={this.countryRestrictionsRef}
                                        className="form-wrapper2 form-wrapper2-border"
                                    >
                                        <Flex className="settings-header">
                                            <Box className="form-data">
                                                <H2>Country Restrictions</H2>
                                                <Box className={`form-section-information`}>
                                                    Allow access to respondents from specific countries.{' '}
                                                    <a
                                                        href="#"
                                                        data-beacon-article="630796be007d42378db77981"
                                                        className="help-question"
                                                    >
                                                        ?
                                                    </a>
                                                </Box>
                                            </Box>
                                        </Flex>
                                        <Box className="form-data">
                                            <Box className="form-label">Allowed Countries</Box>
                                            <Box className="form-data">
                                                <Flex>
                                                    <Box>{this.renderAllowedCountries()}</Box>
                                                    <Box>
                                                        <Button
                                                            className="va-top"
                                                            ml={2}
                                                            mt={'-4px'}
                                                            variant="link"
                                                            type="button"
                                                            onClick={() => {
                                                                this.setState({ showModalAllowedCountries: true });
                                                            }}
                                                        >
                                                            <FiEdit />
                                                        </Button>
                                                    </Box>
                                                </Flex>
                                            </Box>
                                        </Box>
                                    </Box>
                                    <Box
                                        id="permissions"
                                        ref={this.permissionsRef}
                                        className="form-wrapper2 form-wrapper2-border"
                                    >
                                        <Flex className="settings-header">
                                            <Box className="form-data">
                                                <H2>Permissions</H2>
                                                <Box className={`form-section-information`}>
                                                    Organizational permissions.{' '}
                                                    <a
                                                        href="#"
                                                        data-beacon-article="63055617549d66420b99a163"
                                                        className="help-question"
                                                    >
                                                        ?
                                                    </a>
                                                </Box>
                                            </Box>
                                        </Flex>
                                        <Box className="form-data">
                                            <Box className="form-label">
                                                Only an Admin user role can pay incentives{' '}
                                                <a
                                                    href="#"
                                                    data-beacon-article="63055617549d66420b99a163"
                                                    className="help-question"
                                                >
                                                    ?
                                                </a>
                                            </Box>
                                            <Box className="form-data">
                                                <Switch
                                                    mt={2}
                                                    className="switch-small"
                                                    name="rbac_incentives"
                                                    checked={this.state.account.settings.rbac_incentives ? true : false}
                                                    onClick={e => {
                                                        this.onUpdateSettingsProps({
                                                            target: {
                                                                name: 'rbac_incentives',
                                                                // set the opposite
                                                                value: this.state.account.settings.rbac_incentives
                                                                    ? 0
                                                                    : 1
                                                            }
                                                        });
                                                    }}
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                    <br />
                                    <br />
                                </form>
                            </Box>
                            <Box flex="0 0 auto">
                                <SideNav
                                    sections={[
                                        {
                                            id: 'organization',
                                            ref: this.organizationRef,
                                            label: 'Organization',
                                            display: true
                                        },
                                        {
                                            id: 'branding',
                                            ref: this.brandingRef,
                                            label: 'Branding',
                                            display: true
                                        },
                                        {
                                            id: 'panelist-experience',
                                            ref: this.panelistExperienceRef,
                                            label: 'Panelist Experience',
                                            display: true
                                        },
                                        {
                                            id: 'panelist-fatigue',
                                            ref: this.panelistFatigueRef,
                                            label: 'Panelist Fatigue',
                                            display: true
                                        },
                                        {
                                            id: 'email-domains',
                                            ref: this.emailDomainsRef,
                                            label: 'Email Domains',
                                            display: true
                                        },
                                        {
                                            id: 'country-restrictions',
                                            ref: this.countryRestrictionsRef,
                                            label: 'Country Restrictions',
                                            display: true
                                        },
                                        {
                                            id: 'permissions',
                                            ref: this.permissionsRef,
                                            label: 'Permissions',
                                            display: true
                                        }
                                    ]}
                                />
                            </Box>
                        </Flex>

                        <br />
                        <br />
                        <AllowedCountries
                            isOpen={this.state.showModalAllowedCountries}
                            onRequestClose={() => this.setState({ showModalAllowedCountries: false })}
                            onSave={this.onSaveAllowedCountries}
                            selectedCountries={this.state.account.settings.allowed_countries}
                        />

                        <NiceModal
                            isOpen={this.state.showModalSenderProfile}
                            shouldCloseOnOverlayClick
                            onRequestClose={this.handleCloseModalSenderProfile}
                            title={`${this.state.sender_profile_selected.id ? 'Edit' : 'Add'} sender profile`}
                        >
                            <form onSubmit={this.onSubmitFormSenderProfile}>
                                <Box fontSize={1} mb={3} className="text-secondary-dark">
                                    Add the Full Name and E-mail to create a new "From" email.
                                    <br />
                                    <em>Example: John Smith &lt;john.smith@some-company.com&gt;</em>
                                </Box>
                                <Box className="form-label">Full Name</Box>
                                <Input
                                    type="text"
                                    name="sender_profile_name"
                                    value={this.state.sender_profile_selected.name}
                                    onChange={e => {
                                        const { sender_profile_selected } = this.state;
                                        sender_profile_selected.name = e.target.value;
                                        this.setState({ sender_profile_selected });
                                    }}
                                    autoFocus
                                    required
                                />
                                <Box className="form-label" mt={3}>
                                    E-mail
                                </Box>
                                <Input
                                    type="text"
                                    name="sender_profile_email"
                                    value={this.state.sender_profile_selected.email}
                                    onChange={e => {
                                        const { sender_profile_selected } = this.state;
                                        sender_profile_selected.email = e.target.value;
                                        this.setState({ sender_profile_selected });
                                    }}
                                    required
                                    //disabled={this.state.sender_profile_selected.id ? true : false}
                                />
                                <Box className="modal-actions" mt={4}>
                                    <Button
                                        variant="secondary-gray"
                                        type="button"
                                        onClick={this.handleCloseModalSenderProfile}
                                        mr={3}
                                    >
                                        Cancel
                                    </Button>
                                    <Button
                                        type="submit"
                                        variant="primary"
                                        className="modal-primary"
                                        mr={0}
                                        disabled={this.state.onCreateSenderProfile_loading}
                                    >
                                        {this.state.sender_profile_selected.id ? 'Update' : 'Add'} sender profile
                                    </Button>
                                </Box>
                            </form>
                        </NiceModal>

                        <NiceModal
                            isOpen={this.state.showModalCreateDomain}
                            onRequestClose={this.handleCloseModalCreateDomain}
                            title={`Create a domain`}
                        >
                            <Box>
                                <form onSubmit={this.onCreateDomain}>
                                    <Box mb={3} className="text-secondary-dark">
                                        Enter the domain of your email. For john@abc-research.com, the domain would be
                                        abc-research.com.
                                    </Box>
                                    <Box className="form-label">Domain</Box>
                                    <Input
                                        type="text"
                                        name="create_domain_url"
                                        required
                                        autoFocus
                                        placeholder="yourdomain.com"
                                    />
                                    <Box style={{ textAlign: 'right' }} mt={4}>
                                        <Button
                                            variant="secondary-gray"
                                            type="button"
                                            onClick={this.handleCloseModalCreateDomain}
                                            mr={3}
                                        >
                                            Cancel
                                        </Button>
                                        <Button type="submit" mr={0} disabled={this.state.onCreateDomain_loading}>
                                            Create domain
                                        </Button>
                                    </Box>
                                </form>
                            </Box>
                        </NiceModal>

                        <NiceModal
                            isOpen={this.state.showModalVerifyDomain}
                            onRequestClose={this.handleCloseModalVerifyDomain}
                            style={{ content: { minWidth: '980px', width: 'max-content', maxWidth: '100vw' } }}
                            title={`Verify ${
                                this.state.domain_selected ? this.state.domain_selected.domain : 'unknown'
                            }`}
                        >
                            <Box>
                                {this.state.domain_selected.id && (
                                    <form onSubmit={this.onVerifyDomain}>
                                        <Box className="text-primary">
                                            1. Copy each DNS record fully without any alterations, and add it to your
                                            website DNS settings.
                                            <br />
                                            2. After you have added the above DNS entries, click{' '}
                                            <strong>Verify DNS entries</strong> below. It may take 1hr to 24hr for the
                                            verification to complete.
                                        </Box>
                                        {this.renderDomainDns()}

                                        <Box style={{ textAlign: 'right' }} mt={4}>
                                            <Button
                                                variant="secondary-gray"
                                                type="button"
                                                onClick={this.handleCloseModalVerifyDomain}
                                                mr={3}
                                            >
                                                Cancel
                                            </Button>
                                            <Button type="submit" mr={0} disabled={this.state.onVerifyDomain_loading}>
                                                Verify DNS entries
                                            </Button>
                                        </Box>
                                    </form>
                                )}
                            </Box>
                        </NiceModal>
                    </AppPageWrapperSectionBody>
                </AppPageWrapper>
            </Flex>
        );
    }
}

export default withToastManager(SettingsPage);
