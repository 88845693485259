import React from 'react';
import { Button } from 'rebass';
import { Link } from 'react-router-dom';

const LoginPageLink = props =>
    props.to ? (
        <Link {...props}>
            <Button variant="link" fontWeight={500} fontSize="16px" width="100%">
                {props.children}
            </Button>
        </Link>
    ) : (
        <Button variant="link" fontWeight={500} fontSize="16px" width="100%" {...props}>
            {props.children}
        </Button>
    );

export default LoginPageLink;
