/*
 * MePage
 *
 * This is the first thing users see of our App, at the '/' route
 */

import React, { useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import xhr from 'xhr.js';
import config from 'config';
import { Link } from 'react-router-dom';

import auth from 'services/auth';
import LoadingIndicator from 'components/LoadingIndicator';
import H1 from 'components/H1';
import H2 from 'components/H2';
import ListWrapper from 'components/ListWrapper';
import { Box, Button, Flex, Heading } from 'rebass';
import { Label, Input } from '@rebass/forms';

import { isMobile } from 'react-device-detect';
import {
    MarketingHeader,
    MarketingFooter,
    MarketingPageSection,
    MarketingPageWrapper,
    MarketingHeadline
} from 'components/Marketing';

class PrivacyPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {};

        console.log(props);
    }

    render() {
        return (
            <Flex flexDirection="column" style={{ margin: '0px auto', width: '100%' }}>
                <Helmet>
                    <title>Privacy & Terms</title>
                </Helmet>
                <MarketingHeader auth={this.props.auth} />
                <MarketingPageWrapper isMobile={isMobile}>
                    <MarketingPageSection>
                        <Heading fontSize={[5, 6, 6]} color="black" mt={4}>
                            Privacy & Terms
                        </Heading>

                        <Box mt={4}>
                            We have moved the <strong>Terms of Service</strong>{' '}
                            <a href="https://docs.panelfox.io/article/52-terms-of-service" target="_blank">
                                here
                            </a>
                            .<br />
                            <br />
                            We have moved the <strong>Privacy Policy</strong>{' '}
                            <a href="https://docs.panelfox.io/article/51-privacy-policy" target="_blank">
                                here
                            </a>
                            .
                        </Box>
                    </MarketingPageSection>
                </MarketingPageWrapper>
                <MarketingPageWrapper isMobile={isMobile}>
                    <MarketingFooter props={this.props} />
                </MarketingPageWrapper>
            </Flex>
        );
    }
}

export default PrivacyPage;
