import React from 'react';
import classNames from 'classnames';
import { Box, Flex } from 'rebass';
import styled from 'styled-components';

const Card = styled(Box)`
    border-radius: 6px;
    border-width: 1px;
    border-style: solid;
    padding: 16px;
    width: 100%;
    display: flex;
    gap: 12px;
    align-items: flex-start;
`;

/**
 * NotificationCard component
 *
 * @param {Object} props
 * @param {'main' | 'danger' | 'success' | 'warn' | 'info'} props.type color type
 * @param {'light' | 'dark'} props.variant variant
 * @param {string} props.className className
 * @param {React.ReactNode} props.icon icon
 * @param {string} props.title title
 * @param {React.ReactNode} props.content content
 * @param {React.ReactNode} props.extra extra
 * @returns {React.ReactNode} NotificationCard component
 */
export function NotificationCard({
    type = 'main',
    className,
    icon,
    title,
    content,
    variant = 'light',
    extra,
    ...props
}) {
    return (
        <Card
            {...props}
            className={classNames(`bg-${type}${variant === 'light' ? '-light' : ''} border-${type}`, className)}
        >
            <Box
                className={`text-${type}`}
                sx={{
                    fontSize: '16px',
                    height: '20px',
                    width: '20px',
                    textAlign: 'center',
                    svg: {
                        verticalAlign: 'baseline'
                    }
                }}
            >
                {icon}
            </Box>
            <Box flexGrow={1}>
                <Box
                    as="p"
                    sx={{
                        fontSize: '14px',
                        fontWeight: '600',
                        lineHeight: '20px',
                        mb: content ? '8px' : '0'
                    }}
                    className="text-primary"
                >
                    {title}
                </Box>
                {content && (
                    <Box
                        as="p"
                        sx={{
                            fontSize: '12px',
                            fontWeight: '400',
                            lineHeight: '16px'
                        }}
                    >
                        {content}
                    </Box>
                )}
                {extra && <Flex sx={{ gap: '16px', flexWrap: 'wrap' }}>{extra}</Flex>}
            </Box>
        </Card>
    );
}

export default NotificationCard;
