import axios from 'axios/dist/browser/axios.cjs';
import xhr from 'xhr.js';
import config from 'config';
import auth from 'services/auth';

const service_accounts = {
    getAccountBySlug: async slug => {
        const account_id = auth.getAccountId();
        const res = await xhr.get(`/account-slug/${slug}`, {
            withCredentials: true
        });
        return res.data;
    },
    getSenderProfiles: async () => {
        const account_id = auth.getAccountId();
        const res = await xhr.get(`/accounts/${account_id}/sender-profiles`, {
            withCredentials: true
        });
        return res.data;
    },
    createSenderProfile: async (email, name) => {
        const account_id = auth.getAccountId();
        const res = await xhr.post(
            `/accounts/${account_id}/sender-profiles`,
            { email, name },
            { withCredentials: true }
        );

        return res.data;
    },
    updateSenderProfile: async (id, email, name) => {
        const account_id = auth.getAccountId();
        const res = await xhr.put(
            `/accounts/${account_id}/sender-profiles/${id}`,
            { email, name },
            { withCredentials: true }
        );

        return res.data;
    },
    deleteSenderProfile: async (id, email, name) => {
        const account_id = auth.getAccountId();
        const res = await xhr.delete(`/accounts/${account_id}/sender-profiles/${id}`, { withCredentials: true });

        return res.data;
    },
    createDomain: async domain => {
        const account_id = auth.getAccountId();
        const res = await xhr.post(`/accounts/${account_id}/domains`, { domain }, { withCredentials: true });

        return res.data;
    },
    verifyDomain: async id => {
        const account_id = auth.getAccountId();
        const res = await xhr.post(`/accounts/${account_id}/domains/${id}/verify`, {}, { withCredentials: true });

        return res.data;
    },
    deleteDomain: async id => {
        const account_id = auth.getAccountId();
        const res = await xhr.delete(`/accounts/${account_id}/domains/${id}`, { withCredentials: true });

        return res.data;
    },

    getEmailTemplatesAndLatestEmails: async () => {
        const account_id = auth.getAccountId();
        const res = await xhr.get(`/email-templates-and-latest-emails`, {
            withCredentials: true,
            params: {
                account_id
            }
        });
        return res.data;
    },
    getEmailTemplates: async () => {
        const account_id = auth.getAccountId();
        const res = await xhr.get(`/email-templates`, {
            withCredentials: true,
            params: {
                account_id
            }
        });
        return res.data;
    },
    getEmailTemplate: async id => {
        const account_id = auth.getAccountId();
        const res = await xhr.get(`/email-templates/${id}`, {
            withCredentials: true,
            params: {
                account_id
            }
        });
        return res.data;
    },
    /**
     * Create email template
     *
     * @param {Object} emailTemplate Email template object
     * @param {String} emailTemplate.title Email template title
     * @param {String} emailTemplate.subject Email template subject
     * @param {EMAIL_TEMPLATE_TYPE} emailTemplate.type Email template type (html = 2, blocks = 1, plain = 0)
     */
    createEmailTemplate: async emailTemplate => {
        const account_id = auth.getAccountId();
        const res = await xhr.post(`/email-templates`, { account_id, ...emailTemplate }, { withCredentials: true });

        return res.data;
    },
    updateEmailTemplate: async emailTemplate => {
        const account_id = auth.getAccountId();
        const res = await xhr.put(
            `/email-templates/${emailTemplate.id}`,
            { ...emailTemplate, account_id },
            { withCredentials: true }
        );

        return res.data;
    },
    deleteEmailTemplate: async email_template_id => {
        const account_id = auth.getAccountId();
        const res = await xhr.delete(`/email-templates/${email_template_id}`, {
            withCredentials: true,
            params: {
                account_id
            }
        });

        return res.data;
    },
    getUsage: async () => {
        const account_id = auth.getAccountId();
        const res = await xhr.get(`/accounts/${account_id}/usage`, {
            withCredentials: true
        });
        return res.data;
    },
    getPATokens: async () => {
        const account_id = auth.getAccountId();
        const res = await axios.get(config.OAUTH_URL + `/personal-access-tokens`, {
            params: {
                account_id: account_id
            },
            withCredentials: true
        });
        return res.data;
    },
    createPAToken: async options => {
        const axiosInstance = axios.create({
            baseURL: config.OAUTH_URL
        });

        const account_id = auth.getAccountId();
        const res = await axiosInstance.post(
            `/personal-access-tokens`,
            { name: options.name, account_id },
            {
                withCredentials: true
            }
        );

        return res.data;
    },
    deletePAToken: async tokenId => {
        const axiosInstance = axios.create({
            baseURL: config.OAUTH_URL
        });

        const account_id = auth.getAccountId();
        const res = await axiosInstance.delete(`/personal-access-tokens/${tokenId}`, {
            withCredentials: true,
            params: {
                account_id
            }
        });

        return res.data;
    }
};

export default service_accounts;
