import React, { useState } from 'react';
import { Box } from 'rebass';
import { Link } from 'react-router-dom';
import { FiExternalLink, FiPieChart, FiSettings, FiUsers } from 'react-icons/fi';
import qs from 'qs';
import classNames from 'classnames';

import H1 from 'components/H1';
import PanelDropdown from 'components/PanelDropdown';
import CreatePanel from 'components/Participant/CreatePanel';
import NiceModal from 'components/NiceModal';
import Portal from 'components/Portal';

import PanelDropdownTitle from './PanelDropdownTitle';

/**
 * Component displays a list of panels with links to panel dashboard and panel settings
 *
 * @param {Object} props props
 * @param {Object} props.auth auth object
 * @param {array} props.panels list of panels
 * @param {number} props.selectedId id of selected panel
 * @param {function} props.onChange onChange
 * @param {function} props.onCreatePanel onCreatePanel
 * @param {boolean} props.isDashboard isDashboard
 */
function SelectPanelDropdown({ auth, panels, selectedId, onChange, onCreatePanel, isDashboard = false }) {
    const [showSelectPanelDropdown, setShowSelectPanelDropdown] = useState(false);
    const [showCreatePanelModal, setShowCreatePanelModal] = useState(false);

    const hasAdminPrivileges = auth.account_role.type === 'admin';
    const panelIds = panels.map(panel => panel.id);
    const panelNotSelected = selectedId === '' || !panelIds.includes(selectedId);
    const zeroPanelsAvailable = !hasAdminPrivileges && panelNotSelected && panelIds.length === 0;

    /**
     * Generate dropdown items
     */
    const getDropdownItems = () => {
        const customPanelsRows = panels.map(panel => ({
            id: panel.id,
            title: panel.title,
            title_wrapper_style: {
                float: 'left',
                maxWidth: '190px',
                padding: '2px 0 2px 0',
                overflow: 'hidden',
                textOverflow: 'ellipsis'
            },
            suffix: (
                <Box
                    onClick={e => {
                        e.stopPropagation();
                        handleCloseSelectPanelDropdown();
                    }}
                >
                    {isDashboard ? (
                        <Link to={`/panel?filters=[]&panelIdSelected=${panel.id}`}>
                            <FiUsers
                                className="link-navigation-primary"
                                style={{ fontSize: '18px', margin: '4px 12px 0 0' }}
                            />
                        </Link>
                    ) : (
                        <Link to={`/panels/${panel.id}/dashboard`}>
                            <FiPieChart
                                className="link-navigation-primary"
                                style={{ fontSize: '18px', margin: '4px 12px 0 0' }}
                            />
                        </Link>
                    )}
                    {hasAdminPrivileges && (
                        <Link to={`/panels/${panel.id}`}>
                            <FiSettings
                                className="link-navigation-primary"
                                style={{ fontSize: '18px', margin: '4px 12px 0 0' }}
                            />
                        </Link>
                    )}
                    <span
                        onClick={() => {
                            if (isDashboard) {
                                window.open(`/panels/${panel.id}/dashboard`, '_blank');
                            } else {
                                const queryParams = qs.parse(window.location.search, {
                                    ignoreQueryPrefix: true
                                });
                                queryParams.panelIdSelected = JSON.stringify(panel.id);
                                window.open(`/panel?${qs.stringify(queryParams)}`, '_blank');
                            }
                        }}
                    >
                        <FiExternalLink
                            className="link-navigation-primary"
                            style={{ fontSize: '18px', margin: '4px 0 0 0' }}
                        />
                    </span>
                </Box>
            )
        }));

        if (!hasAdminPrivileges || isDashboard) {
            return customPanelsRows;
        }

        return [
            {
                id: '',
                title: 'All panelists',
                title_wrapper_style: {
                    padding: '2px 0 2px 0'
                }
            },
            ...customPanelsRows
        ];
    };

    /**
     * Open panel select dropdown
     */
    const handleOpenSelectPanelDropdown = () => {
        setShowSelectPanelDropdown(true);
    };

    /**
     * Close panel select dropdown
     */
    const handleCloseSelectPanelDropdown = () => {
        setShowSelectPanelDropdown(false);
    };

    /**
     * Open create panel modal
     */
    const handleOpenCreatePanelModal = () => {
        setShowCreatePanelModal(true);
    };

    /**
     * Close create panel modal
     */
    const handleCloseCreatePanelModal = () => {
        setShowCreatePanelModal(false);
    };

    return (
        <>
            <Box style={{ position: 'relative' }}>
                <H1
                    className={classNames('experience-like-h1', !zeroPanelsAvailable && 'link-navigation-primary')}
                    onClick={zeroPanelsAvailable ? null : handleOpenSelectPanelDropdown}
                >
                    <PanelDropdownTitle
                        zeroPanelsAvailable={zeroPanelsAvailable}
                        hasAdminPrivileges={hasAdminPrivileges}
                        panels={panels}
                        selectedId={selectedId}
                        isDashboard={isDashboard}
                    />
                </H1>
                <PanelDropdown
                    adjustHeight
                    showDropdown={showSelectPanelDropdown}
                    width={300}
                    positionTop={42}
                    items={getDropdownItems()}
                    onChange={onChange}
                    onClose={handleCloseSelectPanelDropdown}
                    onCreatePanel={handleOpenCreatePanelModal}
                />
            </Box>
            <Portal>
                <NiceModal
                    isOpen={showCreatePanelModal}
                    shouldCloseOnOverlayClick
                    onRequestClose={handleCloseCreatePanelModal}
                    title={`Create new panel`}
                >
                    <CreatePanel onCreate={onCreatePanel} onClose={handleCloseCreatePanelModal} />
                </NiceModal>
            </Portal>
        </>
    );
}

export default SelectPanelDropdown;
