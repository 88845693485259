import React from 'react';
import { Box } from 'rebass';

import { AttachedFileStrip } from 'components/AttachedFileStrip';
import { DownloadAttachedFileButton } from 'components/DownloadAttachedFileButton';
import helpers from 'utils/helpers';

/**
 * A component to display the attached files for a study
 *
 * @param {Object} props
 * @param {string} props.personId The id of the person
 * @param {Array} props.attachedFiles The attached files
 */
export function StudyFiles({ personId, attachedFiles }) {
    if (attachedFiles.length === 0)
        return (
            <Box
                backgroundColor="#F6F6F6"
                sx={{
                    borderRadius: '4px',
                    padding: '24px 16px',
                    textAlign: 'center',
                    fontSize: '14px',
                    marginBottom: '16px'
                }}
            >
                No files attached
            </Box>
        );

    return (
        <>
            {attachedFiles.map(attachedFile => (
                <AttachedFileStrip
                    css={{
                        fontSize: '14px',
                        borderBottom: 'none !important',
                        paddingBottom: '0px !important',
                        marginBottom: '16px !important'
                    }}
                    key={attachedFile.id}
                    fileExtension={helpers.extractFileExtension(attachedFile.title)}
                    description={attachedFile.description}
                    title={attachedFile.title}
                    updatedAt={attachedFile.updated_at}
                    extra={<DownloadAttachedFileButton fileId={attachedFile.id} personId={personId} />}
                    study={attachedFile.study}
                />
            ))}
        </>
    );
}

export default StudyFiles;
