import React from 'react';
import { Link } from 'react-router-dom';

import H2 from 'components/H2';

/**
 * The content for the success toast
 *
 * @param {Object} props
 * @param {String} props.studyName The name of the study
 * @param {Number} props.studyId The id of the study
 * @param {Number} props.newCount The number of new participants added to the study
 * @returns {React.Component} The component
 */
export function ToastSuccess({ studyName, studyId, newCount }) {
    let info_text = '';
    if (newCount) {
        if (newCount == 1) {
            info_text = 'Participant has been added';
        } else {
            info_text = `${newCount} participants have been added`;
        }
    } else {
        info_text = 'Participants have been added';
    }

    return (
        <>
            <H2>
                {info_text}
                {studyName ? ` to ${studyName}` : ''}
            </H2>
            <a style={{ fontSize: '16px' }} href={`/studies/${studyId}`}>
                Go to study -&gt;
            </a>
        </>
    );
}

export default ToastSuccess;
