/**
 * Documentation:
 *
 * `useForm` is a custom React hook that provides a straightforward approach to handling form states and events
 * without causing unnecessary component re-renders. It is designed for ease of integration with form components,
 * and its main purpose is to streamline form data management and the process of form submissions.
 *
 * The hook uses a React `useRef` to maintain the form data. This ensures that the form state doesn't trigger
 * component re-renders when it updates, offering improved performance for forms, especially those with a larger
 * number of fields.
 *
 * Key Features:
 *  - Initialize form with default values.
 *  - Automatically create `onChange` handlers for individual form fields.
 *  - Provide a convenient `register` function to bind form fields.
 *  - Simplified form submission with `handleSubmit`.
 *  - Reset form values with `reset`.
 *  - Watch for changes in form fields with `watch`.
 *
 * @example
 * const MyFormComponent = () => {
 *   const { register, handleSubmit, watch } = useForm({
 *     initialForm: { name: '', age: '' }
 *   });
 *  const name = watch('name');
 *
 *   const onSubmit = (formData) => {
 *     console.log("Form data submitted:", formData);
 *   };
 *
 *   return (
 *     <form onSubmit={handleSubmit(onSubmit)}>
 *       <input {...register("name")} placeholder="Name" />
 *       <input {...register("age")} placeholder="Age" />
 *       <button type="submit">Submit</button>
 *     </form>
 *   );
 * };
 *
 * Note: The `register` function essentially creates bindings for form fields. It returns properties
 * that need to be spread onto the corresponding form field, including its `onChange` handler and
 * default value.
 */

import React, { useRef, useState } from 'react';

/**
 * Custom hook for handling forms
 * This hook prevents unnecessary re-renders
 *
 * @param {Object} props
 * @param {Object} props.initialForm initial form values
 * @returns Form tools
 */
export const useForm = ({ initialForm = {} } = {}) => {
    const [formState, setFormState] = useState({});
    const form = useRef(initialForm);

    /**
     * Watch for changes in form fields
     *
     * @param {string} name Field name
     */
    const watch = name => {
        if (!(name in formState)) {
            setFormState({ ...formState, [name]: form.current[name] });
        }
        return formState[name];
    };

    /**
     * Create onChange event handler
     *
     * @param {string} name Field name
     * @param {(<T>(value: T) => T) | null} formatter Field formatter
     */
    const createOnChange = (name, formatter = null) => event => {
        event.preventDefault();

        if (formatter) {
            event.target.value = formatter(event.target.value);
        }

        const value = event.target.value;

        // Update form state if the field exists in the form state
        if (name in formState) {
            setFormState({ ...formState, [name]: value });
        }

        form.current[name] = value;
    };

    /**
     * Register form field
     *
     * @param {string} name Field name
     * @param {Object} options Field options
     * @param {(<T>(value: T) => T)?} options.formatter Field formatter
     */
    const register = (name, { formatter } = {}) => {
        return {
            onChange: createOnChange(name, formatter),
            name,
            defaultValue: form.current[name] || undefined
        };
    };

    /**
     * Handle form submission
     *
     * @param {(date: any) => Promise<void>} submitFn
     */
    const handleSubmit = submitFn => async event => {
        event.preventDefault();
        await submitFn(form.current);
    };

    /**
     * Reset form state
     */
    const resetFormState = () => {
        setFormState(state => {
            const newState = {};

            for (const key in state) {
                newState[key] = initialForm[key];
            }

            return newState;
        });
    };

    /**
     * Reset form values
     */
    const reset = () => {
        form.current = initialForm;
        resetFormState();
    };

    return {
        register,
        handleSubmit,
        reset,
        watch
    };
};

export default useForm;
