import React from 'react';
import classNames from 'classnames';
import { Box } from 'rebass';

/**
 * Table component.
 * TODO: Add support for responsive tables.
 * TODO: Add support for bordered tables.
 * TODO: Add support for clickable rows.
 * TODO: Add support for resizable columns.
 *
 * @param {Object} param0
 * @param {React.ReactNode} param0.children
 * @param {string} param0.className
 * @param {React.CSSProperties} param0.style
 */
function Table({ children, className, style }) {
    return (
        <Box style={style} className={classNames('table-container', className)}>
            <Box as="table" className="table">
                {children}
            </Box>
        </Box>
    );
}

/**
 * Table Head component.
 *
 * @param {Object} param0
 * @param {React.ReactNode} param0.children
 * @param {string} param0.className
 * @param {React.CSSProperties} param0.style
 */
function Head({ children, className, style }) {
    return (
        <Box as="thead" style={style} className={classNames('thead', className)}>
            {children}
        </Box>
    );
}

/**
 * Table Row component.
 *
 * @param {Object} param0
 * @param {React.ReactNode} param0.children
 * @param {string} param0.className
 * @param {React.CSSProperties} param0.style
 */
function Tr({ children, className, style }) {
    return (
        <Box as="tr" style={style} className={classNames('tr', className)}>
            {children}
        </Box>
    );
}

/**
 * Table Header Cell component.
 *
 * @param {Object} param0
 * @param {React.ReactNode} param0.children
 * @param {string} param0.className
 * @param {React.CSSProperties} param0.style
 * @param {string} param0.width
 */
function Th({ children, width, className, style }) {
    return (
        <Box as="th" className={classNames('th', className)} style={{ width, ...style }}>
            {children}
        </Box>
    );
}

/**
 * Table Body component.
 *
 * @param {Object} param0
 * @param {React.ReactNode} param0.children
 * @param {string} param0.className
 * @param {React.CSSProperties} param0.style
 */
function Body({ children, className, style }) {
    return (
        <Box as="tbody" style={style} className={classNames('tbody', className)}>
            {children}
        </Box>
    );
}

/**
 * Table Data Cell component.
 *
 * @param {Object} param0
 * @param {React.ReactNode} param0.children
 * @param {string} param0.className
 * @param {React.CSSProperties} param0.style
 */
function Td({ children, className, style }) {
    return (
        <Box as="td" style={style} className={classNames('td', className)}>
            {children}
        </Box>
    );
}

Table.Head = Head;
Table.Tr = Tr;
Table.Th = Th;
Table.Body = Body;
Table.Td = Td;

export { Table, Head, Tr, Th, Body, Td };
export default Table;
