import React, { useState, useEffect, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useToasts } from 'react-toast-notifications';
import TextAreaAutosize from 'react-textarea-autosize';
import H2 from 'components/H2';
import H1 from 'components/H1';
import { Flex, Box, Button } from 'rebass';
import { Input, Label, Textarea, Switch } from '@rebass/forms';
import { FiLink, FiCopy, FiExternalLink, FiFileText, FiType, FiSettings, FiTrash, FiAlertCircle } from 'react-icons/fi';
import people_service from 'services/people';
import services from 'services/services';
import helpers from 'utils/helpers';
import helpersScreener from 'utils/helpers-screener';
import cloneDeep from 'lodash/cloneDeep';
import moment from 'moment-timezone';
import EventEmitter from 'utils/events';

import { ScrollToTopButton } from 'components/ScrollToTopButton';
import { HeaderSubnavButton } from 'components/Header/HeaderSubnavButton';
import AppPageWrapper from 'components/AppPageWrapper';
import AppPageWrapperSectionBody from 'components/AppPageWrapper/AppPageWrapperSectionBody';
import AppPageWrapperSectionHeader from 'components/AppPageWrapper/AppPageWrapperSectionHeader';
import { ReactFormBuilder } from 'components/ReactFormBuilder';
import ReactFormBuilder_store from 'components/ReactFormBuilder/stores/store';
import ScreenerTranslationComponent from 'components/ScreenerTranslationComponent';
import SideNav from 'components/SideNav';
import NiceModal from 'components/NiceModal';
import CreatePanel from 'components/Participant/CreatePanel';
import PanelSelect from 'components/PanelSelect';
import myxss from 'components/ReactFormBuilder/form-elements/myxss';
import LoadingIndicator from 'components/LoadingIndicator';
import LoadingWrapper from 'components/LoadingIndicator/LoadingWrapper';
import Badge from 'components/Badge';
import BreadcrumbBack from 'components/BreadcrumbBack';

let saveDelay;
let saveDelay_translation;

function PanelSignupPage(props) {
    const tab_screener_builder = 'screener-builder';
    const tab_screener_configuration = 'screener-configuration';
    const tab_screener_redirect = 'screener-redirect';
    const tab_screener_configuration_labels = [
        'screener-configuration-basic-details',
        'screener-configuration-custom-javascript'
    ];
    const page_tabs = [
        { title: 'Form Settings', label: tab_screener_configuration, icon: <FiSettings /> },
        { title: 'Form Builder', label: tab_screener_builder, icon: <FiFileText /> },
        { title: 'Redirects & Language', label: tab_screener_redirect, icon: <FiType /> }
    ];

    const [pageTabSelected, setPageTabSelected] = useState(tab_screener_configuration);
    const [panelFormData, setPanelFormData] = useState({});
    const [isPanelFormDataLoading, setIsPanelFormDataLoading] = useState(true);
    const [panels, setPanels] = useState([]);
    const [showModalCreatePanel, setShowModalCreatePanel] = useState(false);
    const [screenerTranslation, setScreenerTranslation] = useState({});
    const [stLoading, setStLoading] = useState(false);

    const { addToast } = useToasts();

    const basicDetailsRef = useRef();
    const customJavascriptRef = useRef();
    const analyticsRef = useRef();
    const panelsRef = useRef();

    const { pathname, hash } = useLocation();
    const history = useHistory();

    useEffect(() => {
        const tabLabel = hash.replace('#', '');
        if (page_tabs.filter(tab => tab.label === tabLabel).length > 0) {
            setPageTabSelected(tabLabel || page_tabs[0].label);
        } else if (tab_screener_configuration_labels.filter(label => label === tabLabel).length > 0) {
            setPageTabSelected(tab_screener_configuration);
        }
    }, [pathname, hash]);

    useEffect(() => {
        xhrGetScreenerTranslation();
        xhrGetForm();
        xhrGetPanels();

        const that = this;
        EventEmitter.subscribe('screener.save.success', screenerObject => {
            emitUpdatedFormSaveUrl(screenerObject);
        });
        EventEmitter.subscribe('screener.save.error', error => {
            const errorText = services.parseAndTrackXhrErrors(error);

            addToast(errorText, {
                appearance: 'error',
                autoDismiss: false
            });
        });
    }, []);

    function xhrGetForm() {
        setIsPanelFormDataLoading(true);

        people_service
            .getPanelSignupForm(props.match.params.panel_form_id)
            .then(sf => {
                setPanelFormData(sf);
                ReactFormBuilder_store.dispatch('updateOrder', { elements: sf.form, saveData: false });
                emitUpdatedFormSaveUrl(sf);
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);

                addToast(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            })
            .finally(() => {
                setIsPanelFormDataLoading(false);
            });
    }

    function xhrGetPanels() {
        people_service
            .getPanels()
            .then(panels_response => {
                setPanels(panels_response);
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);
                addToast(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    function onCreatePanel(panel) {
        addToast('Successfully created a new panel', {
            appearance: 'success',
            autoDismiss: true
        });
        xhrGetPanels();
        onAssociatedPanelChange(panel.id);
    }

    function emitUpdatedFormSaveUrl(screenerObject) {
        const new_url = getScreenerFormSaveUrl(screenerObject);
        console.log('emitting new url', new_url);
        ReactFormBuilder_store.dispatch('updateSaveUrl', new_url);
    }

    function getScreenerFormSaveUrl(screener) {
        return `/panel-signup-forms/${props.match.params.panel_form_id}/screener?account_id=${props.auth.account.id}&form_updated_at=${screener.form_updated_at}`;
    }

    function xhrGetScreenerTranslation() {
        setStLoading(true);
        people_service
            .getScreenerTranslation(props.match.params.panel_form_id)
            .then(st => {
                setScreenerTranslation(st);
                setStLoading(false);
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);
                addToast(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });

                setStLoading(false);
            });
    }

    function xhrUpdateScreenerTranslation(st) {
        people_service
            .updateScreenerTranslation(props.match.params.panel_form_id, st)
            .then(() => {
                console.log('calling toast');
                addToast('Form saved', {
                    appearance: 'success',
                    autoDismiss: true
                });
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);

                addToast(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    function xhrUpdatePanelForm(pf) {
        people_service
            .updatePanelForm(pf)
            .then(() => {
                console.log('I am calling');
                addToast('Form saved', {
                    appearance: 'success',
                    autoDismiss: true
                });
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);

                addToast(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    function handleTranslationChange(e) {
        let st = cloneDeep(screenerTranslation);
        st[e.target.name] = e.target.value;
        setScreenerTranslation(st);

        // detect change, and clear timeout if exists
        if (saveDelay_translation) {
            clearTimeout(saveDelay_translation);
        }

        saveDelay_translation = setTimeout(() => {
            st['confirmation_page_text'] = myxss.process(st['confirmation_page_text']);
            st['disqualification_page_text'] = myxss.process(st['disqualification_page_text']);

            xhrUpdateScreenerTranslation(st);
        }, 500);
    }

    function onFormChange(e) {
        const { name, value } = e.target;
        const newState = cloneDeep(panelFormData);

        if (name === 'custom_js') {
            // deprecated 2023.09.12 because of security concerns
            //newState[name] = [{ when: '', execute: value }];
        } else {
            newState[name] = value;
        }

        // console.log(name, value);
        setPanelFormData(newState);

        // detect change, and clear timeout if exists
        if (saveDelay) {
            clearTimeout(saveDelay);
        }

        saveDelay = setTimeout(() => {
            xhrUpdatePanelForm(newState);
        }, 500);
    }

    function onAssociatedPanelChange(panel_id) {
        const newState = cloneDeep(panelFormData);
        newState.panel_id = panel_id;
        setPanelFormData(newState);
        xhrUpdatePanelForm(newState);
    }

    function removeCustomLink() {
        const isConfirmed = confirm(
            'Are you sure you want to permanently delete this link? If you shared this link with anyone, they will no longer be able to access it.'
        );
        if (isConfirmed) {
            services
                .deletePanelSignupCustomLink(panelFormData.id)
                .then(custom_links => {
                    let pfd = panelFormData;
                    pfd.links = custom_links;
                    setPanelFormData(pfd);

                    addToast('Removed custom link', {
                        appearance: 'success',
                        autoDismiss: true
                    });
                })
                .catch(error => {
                    const errorText = services.parseAndTrackXhrErrors(error);
                    addToast(errorText, {
                        appearance: 'error',
                        autoDismiss: true
                    });
                });
        } else {
            console.log('not deleting');
        }
    }

    function setCustomLink() {
        const newLink = prompt('What would you like the link to be?');
        if (newLink) {
            services
                .setPanelSignupCustomLink(panelFormData.id, newLink)
                .then(custom_links => {
                    let pfd = panelFormData;
                    pfd.links = custom_links;
                    setPanelFormData(pfd);

                    addToast('Added custom link', {
                        appearance: 'success',
                        autoDismiss: true
                    });
                })
                .catch(error => {
                    const errorText = services.parseAndTrackXhrErrors(error);
                    addToast(errorText, {
                        appearance: 'error',
                        autoDismiss: true
                    });
                });
        }
    }

    function deleteForm(e) {
        e.preventDefault();
        if (confirm('Are you sure?')) {
            people_service
                .deletePanelSignupForm(panelFormData.id)
                .then(() => {
                    history.push('/settings/forms');
                    addToast('Form successfully deleted', {
                        appearance: 'success',
                        autoDismiss: true
                    });
                })
                .catch(err => {
                    const errorText = services.parseAndTrackXhrErrors(err);
                    addToast(errorText, {
                        appearance: 'error',
                        autoDismiss: true
                    });
                });
        }
    }

    function isAttachedToPanel(panelFormData) {
        return panelFormData && panelFormData.panels && panelFormData.panels.length;
    }

    let publicPanelProfileUrl = helpersScreener.getPanelSignupLink(
        props.auth.account,
        panelFormData.links,
        props.match.params.panel_form_id
    );

    return (
        <Flex style={{ width: '100%' }}>
            <AppPageWrapper>
                <Helmet>
                    <title>Signup Form</title>
                </Helmet>
                <AppPageWrapperSectionHeader className="experience experience-no-subnav">
                    <Box>
                        <BreadcrumbBack to="/settings/forms">Back to all forms</BreadcrumbBack>
                        <H1 style={{ fontSize: '20px' }}>
                            {panelFormData.title ? (
                                panelFormData.title
                            ) : isPanelFormDataLoading ? (
                                <>&nbsp;</>
                            ) : (
                                'Not Found'
                            )}
                        </H1>
                    </Box>
                </AppPageWrapperSectionHeader>
                <Flex sx={{ flexDirection: 'row' }} px={4} className="subnav-border">
                    {/*tertiary-nav-padded*/}
                    {page_tabs.map(pt => {
                        return (
                            <HeaderSubnavButton
                                //style={{ padding: '0 0 8px 0' }}
                                className={pageTabSelected == pt.label && 'nav-item-active'}
                                onClick={() => history.push(`#${pt.label}`)}
                            >
                                {pt.icon}
                                {pt.title}
                            </HeaderSubnavButton>
                        );
                    })}
                </Flex>
                <AppPageWrapperSectionBody
                    style={{ background: pageTabSelected == tab_screener_builder ? '#f6f6f6' : '#fff' }}
                >
                    <Box mx={4} my={3} width="100%">
                        {pageTabSelected == tab_screener_configuration && (
                            <Flex flex="1 1 0%" justifyContent="space-between">
                                <Box flex="0 0 auto">
                                    <Box
                                        id="screener-configuration-basic-details"
                                        ref={basicDetailsRef}
                                        className="form-wrapper2 form-wrapper2-first"
                                    >
                                        <Box className="settings-header">
                                            <H2>Basic Details</H2>
                                            <Box className="form-section-information">Information about the form.</Box>
                                        </Box>
                                        <Box className="form-data">
                                            {/*<Box className="form-label">Associated Panel</Box>
                                            <Box>
                                                <PanelSelect
                                                    width="100%"
                                                    variant="gray"
                                                    items={panels}
                                                    value={panelFormData.panel_id}
                                                    onChange={onAssociatedPanelChange}
                                                    onCreatePanel={() => setShowModalCreatePanel(true)}
                                                />
                                            </Box>*/}

                                            <Box className="form-label">Form Type</Box>
                                            <Box>
                                                <Badge type="info">
                                                    {helpers.getFormTypeTitle(panelFormData.form_type)}
                                                </Badge>
                                            </Box>
                                            {/*panelFormData.form_type == FORM_TYPE_CONSENT.id && (
                                                <Flex sx={{ gridGap: '24px' }}>
                                                    <Box width={1 / 2}>
                                                        <Box className="form-label form-row-margin">
                                                            Consent Expiration
                                                        </Box>
                                                        <Flex>
                                                            <Switch
                                                                mt={2}
                                                                className="switch-small va-top"
                                                                name="show_in_profile"
                                                                checked={panelFormData.show_in_profile ? true : false}
                                                                onClick={e => {
                                                                    onFormChange({
                                                                        target: {
                                                                            name: 'show_in_profile',
                                                                            value: !panelFormData.show_in_profile
                                                                        }
                                                                    });
                                                                }}
                                                            />
                                                            <Box
                                                                className="fs-12 text-primary va-top"
                                                                mt={'6px'}
                                                                ml={2}
                                                            >
                                                                Never expires
                                                            </Box>
                                                        </Flex>
                                                    </Box>
                                                    <Box width={1 / 2}>
                                                        <Box className="form-label form-row-margin">
                                                            Form Expires On
                                                        </Box>
                                                        <Box>
                                                            <Input
                                                                name="expires_at"
                                                                value={panelFormData.expires_at}
                                                                onChange={onFormChange}
                                                            />
                                                        </Box>
                                                    </Box>
                                                </Flex>
                                            )*/}
                                            <Box className="form-label form-row-margin">Public Title</Box>
                                            <Box>
                                                <Input
                                                    type="text"
                                                    name="title"
                                                    value={panelFormData.title ? panelFormData.title : ''}
                                                    onChange={onFormChange}
                                                />
                                            </Box>
                                            <Box className="form-label form-row-margin">Public Description</Box>
                                            <Box>
                                                <Textarea
                                                    name="description"
                                                    value={panelFormData.description ? panelFormData.description : ''}
                                                    onChange={onFormChange}
                                                />
                                            </Box>
                                            <Box className="form-label form-row-margin">Public Link</Box>
                                            {isAttachedToPanel(panelFormData) ? (
                                                ''
                                            ) : (
                                                <Box mb={3}>
                                                    <Badge type="danger">
                                                        <FiAlertCircle /> Please associate this form with a panel before
                                                        sharing this link.
                                                    </Badge>
                                                </Box>
                                            )}
                                            <Box>
                                                <Flex
                                                    fontSize={1}
                                                    mb={1}
                                                    sx={{ width: '100%' }}
                                                    className="mimic-theme rounded"
                                                >
                                                    <Box p={2} sx={{ borderRight: '1px solid #eee', flexShrink: 0 }}>
                                                        <FiLink
                                                            style={{
                                                                margin: '9px 8px 7px',
                                                                fontSize: '16px',
                                                                color: '#777'
                                                            }}
                                                        />
                                                    </Box>

                                                    <Box
                                                        className="ellipsis quiet"
                                                        sx={{ padding: '14px 16px 12px', flexGrow: 1 }}
                                                    >
                                                        <a
                                                            href={publicPanelProfileUrl}
                                                            target="_blank"
                                                            className="quiet"
                                                        >
                                                            {publicPanelProfileUrl}
                                                        </a>
                                                    </Box>

                                                    <Box p={2} sx={{ flexShrink: 0 }}>
                                                        <Button
                                                            variant="primary"
                                                            mr={0}
                                                            onClick={e => {
                                                                e.stopPropagation();
                                                                helpers.copyToClipboard(publicPanelProfileUrl);
                                                                addToast('Copied link', {
                                                                    appearance: 'success',
                                                                    autoDismiss: true
                                                                });
                                                            }}
                                                        >
                                                            <FiCopy /> Copy
                                                        </Button>
                                                    </Box>
                                                </Flex>

                                                <Box>
                                                    {panelFormData &&
                                                    panelFormData.links &&
                                                    panelFormData.links.length > 0 ? (
                                                        <Button
                                                            variant="link"
                                                            fontSize={1}
                                                            className="quiet"
                                                            onClick={removeCustomLink}
                                                        >
                                                            <u>Remove custom link "{panelFormData.links[0].slug}"</u>
                                                        </Button>
                                                    ) : (
                                                        <Button
                                                            variant="link"
                                                            fontSize={1}
                                                            className="quiet"
                                                            onClick={setCustomLink}
                                                        >
                                                            <u>Create custom link</u>
                                                        </Button>
                                                    )}
                                                    {helpers.newFeatureTag(moment('11-21-2021', 'MM-DD-YYYY'), {
                                                        margin: '7px 0 0 8px'
                                                    })}
                                                </Box>
                                            </Box>
                                            <Box className="form-label form-row-margin">Show In Profile</Box>
                                            <Box>
                                                <Switch
                                                    mt={1}
                                                    className="switch-small"
                                                    name="show_in_profile"
                                                    checked={panelFormData.show_in_profile ? true : false}
                                                    onClick={e => {
                                                        onFormChange({
                                                            target: {
                                                                name: 'show_in_profile',
                                                                value: !panelFormData.show_in_profile
                                                            }
                                                        });
                                                    }}
                                                />
                                                <Label className="quiet">
                                                    Choose whether or not this form should show up on all logged in
                                                    panelist profiles.
                                                </Label>
                                            </Box>
                                        </Box>
                                    </Box>

                                    <Box
                                        id="screener-configuration-panels"
                                        ref={panelsRef}
                                        className="form-wrapper2 form-wrapper2-border"
                                    >
                                        <Box className="settings-header">
                                            <H2>Associated Panels</H2>
                                            <Box className="form-section-information">
                                                Respondents who fill out this form will be added to these panels.{' '}
                                                <a
                                                    href="#"
                                                    data-beacon-article="64a31e2091ec2e7dc66cdd68"
                                                    className="help-question"
                                                >
                                                    ?
                                                </a>
                                            </Box>
                                        </Box>
                                        <Box className="form-data">
                                            {isAttachedToPanel(panelFormData) ? (
                                                <>
                                                    <Flex
                                                        className="border-bottom-gray fs-accent-12 color-text-secondary"
                                                        py={2}
                                                    >
                                                        Panel Title
                                                    </Flex>
                                                    {panelFormData.panels.map(panel => {
                                                        return (
                                                            <Flex
                                                                justifyContent={'space-between'}
                                                                key={panel.id}
                                                                className="border-bottom-gray row-hover"
                                                                alignItems="center"
                                                                p="8px 0"
                                                            >
                                                                <Box className="">
                                                                    <a
                                                                        href={`/panels/${panel.id}`}
                                                                        target="_blank"
                                                                        className="muted medium"
                                                                    >
                                                                        {panel.title} <FiExternalLink />
                                                                    </a>
                                                                </Box>
                                                            </Flex>
                                                        );
                                                    })}
                                                </>
                                            ) : (
                                                <>
                                                    <Badge type="danger">
                                                        <FiAlertCircle /> Not associated with any panels
                                                    </Badge>
                                                    <Box mt={3}>
                                                        <a
                                                            href="https://docs.panelfox.io/article/127-multiple-panels#associate-panel-sign-up-forms"
                                                            target="_blank"
                                                        >
                                                            Help article
                                                        </a>{' '}
                                                        on associating forms. Go to{' '}
                                                        <a href="/panels" target="_blank">
                                                            All Panels
                                                        </a>
                                                        .
                                                    </Box>
                                                </>
                                            )}
                                        </Box>
                                    </Box>

                                    <Box
                                        id="screener-configuration-analytics"
                                        ref={analyticsRef}
                                        className="form-wrapper2 form-wrapper2-border"
                                    >
                                        <Box className="settings-header">
                                            <H2>Analytics</H2>
                                            <Box className="form-section-information">
                                                Track analytics using Google Analytics.
                                            </Box>
                                        </Box>
                                        <Box className="form-data">
                                            <Box className="form-label">Google Analytics Tag Id (gtag.js)</Box>
                                            <Box>
                                                <Input
                                                    name="gtag_id"
                                                    value={panelFormData.gtag_id}
                                                    onChange={onFormChange}
                                                    width="100%"
                                                    minRows={3}
                                                    className="theme-input"
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                    {/*<Box
                                        id="screener-configuration-custom-javascript"
                                        ref={customJavascriptRef}
                                        className="form-wrapper2 form-wrapper2-border"
                                    >
                                        <Box className="settings-header">
                                            <H2>Custom HTML</H2>
                                            <Box className="form-section-information">
                                                Define custom code that will be executed on this form.{' '}
                                                <a
                                                    href="#"
                                                    data-beacon-article="6410db04a0408f7cb1038a44"
                                                    className="help-question"
                                                >
                                                    ?
                                                </a>
                                            </Box>
                                        </Box>
                                        <Box className="form-data">
                                            <Box className="form-label">Code</Box>
                                            <Box>
                                                <TextAreaAutosize
                                                    name="custom_js"
                                                    value={
                                                        panelFormData.custom_js ? panelFormData.custom_js[0].execute : ''
                                                    }
                                                    onChange={onFormChange}
                                                    width="100%"
                                                    minRows={3}
                                                    className="theme-input"
                                                />
                                            </Box>
                                        </Box>
                                    </Box>*/}
                                    <Box
                                        id="screener-configuration-delete"
                                        ref={panelsRef}
                                        className="form-wrapper2 form-wrapper2-border"
                                    >
                                        <Box className="settings-header">
                                            <H2>Delete Form</H2>
                                            <Box className="form-section-information">
                                                Permanently delete this form.
                                            </Box>
                                        </Box>
                                        <Box className="form-data">
                                            {panelFormData && panelFormData.panels && panelFormData.panels.length ? (
                                                <>
                                                    You can not delete this form because it is attached to a Panel.
                                                    <br />
                                                    Please remove it from the panel first.
                                                </>
                                            ) : (
                                                <Button type="button" variant="danger" onClick={deleteForm} mt={2}>
                                                    <FiTrash /> Delete form
                                                </Button>
                                            )}
                                        </Box>
                                    </Box>
                                </Box>
                                <Box flex="0 0 auto">
                                    <SideNav
                                        sections={[
                                            {
                                                id: 'screener-configuration-basic-details',
                                                ref: basicDetailsRef,
                                                label: 'Basic Details',
                                                display: true
                                            },
                                            {
                                                id: 'screener-configuration-panels',
                                                ref: panelsRef,
                                                label: 'Associated Panels',
                                                display: true
                                            },
                                            {
                                                id: 'screener-configuration-analytics',
                                                ref: analyticsRef,
                                                label: 'Analytics',
                                                display: true
                                            },
                                            /*{
                                                id: 'screener-configuration-custom-javascript',
                                                ref: customJavascriptRef,
                                                label: 'Custom HTML',
                                                display: true
                                            }*/
                                            {
                                                id: 'screener-configuration-delete',
                                                ref: panelsRef,
                                                label: 'Delete Form',
                                                display: true
                                            }
                                        ]}
                                    />
                                </Box>
                            </Flex>
                        )}
                        {pageTabSelected == tab_screener_builder && (
                            <Box>
                                {panelFormData.form ? (
                                    <Box sx={{ position: 'relative' }}>
                                        <ReactFormBuilder
                                            isEmailRequired
                                            data={panelFormData.form}
                                            form_type={panelFormData.form_type}
                                            page_logic={panelFormData.page_logic}
                                            saveUrl={getScreenerFormSaveUrl(panelFormData)}
                                            read_only={1}
                                            screener_for="panel"
                                        />
                                        <ScrollToTopButton />
                                    </Box>
                                ) : (
                                    <div>
                                        <LoadingWrapper>
                                            <LoadingIndicator />
                                        </LoadingWrapper>
                                        <br />
                                    </div>
                                )}
                            </Box>
                        )}

                        {pageTabSelected == tab_screener_redirect && (
                            <>
                                {stLoading ? (
                                    <LoadingWrapper>
                                        <LoadingIndicator />
                                    </LoadingWrapper>
                                ) : (
                                    <ScreenerTranslationComponent
                                        screener_translation={screenerTranslation}
                                        handleTranslationChange={handleTranslationChange}
                                    />
                                )}
                            </>
                        )}
                    </Box>
                </AppPageWrapperSectionBody>
            </AppPageWrapper>
            <NiceModal
                isOpen={showModalCreatePanel}
                shouldCloseOnOverlayClick
                onRequestClose={() => setShowModalCreatePanel(false)}
                title={`Create new panel`}
            >
                <CreatePanel onCreate={onCreatePanel} onClose={() => setShowModalCreatePanel(false)} />
            </NiceModal>
        </Flex>
    );
}

export default PanelSignupPage;
