/*
 * StudiesPage
 *
 * This is the first thing users see of our App, at the '/' route
 */

import React, { useEffect, memo } from 'react';
import PropTypes from 'prop-types';
import { Helmet } from 'react-helmet';
import { FormattedMessage } from 'react-intl';
import { connect } from 'react-redux';
import { Redirect, Link } from 'react-router-dom';
import { compose } from 'redux';
import { createStructuredSelector } from 'reselect';
import xhr from 'xhr.js';
import styled from 'styled-components';

import {
    MarketingHeader,
    MarketingFooter,
    MarketingPageSection,
    MarketingHeadline,
    MarketingPageWrapper
} from 'components/Marketing';
import H1 from 'components/H1';
import H2 from 'components/H2';
import H3 from 'components/H3';

import { Flex, Box, Button, Heading } from 'rebass';
import { Label, Input, Select } from '@rebass/forms';
import config from 'config';
import NiceWrapper from 'components/NiceWrapper';
import auth from 'services/auth';
import PricingTable from 'containers/PricingPage/PricingTable';

import { isMobile } from 'react-device-detect';

class PricingPage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            numUsers: 1
        };
    }

    render() {
        return (
            <Flex flexDirection="column" style={{ margin: '0px auto', width: '100%' }}>
                <MarketingHeader auth={this.props.auth} />
                <MarketingPageWrapper isMobile={isMobile}>
                    <Helmet>
                        <title>Pricing</title>
                    </Helmet>

                    <MarketingPageSection style={{ textAlign: 'center' }}>
                        <Heading
                            fontSize={[5, 6, 6]}
                            color="black"
                            style={{ margin: isMobile ? '64px 0 0 0' : '160px 0 0 0' }}
                        >
                            Pricing
                        </Heading>
                        <Box style={{ margin: isMobile ? '32px 0 0 0' : '32px 0 32px 0' }}>
                            Happy researchers. Happy customers.
                        </Box>
                    </MarketingPageSection>

                    <MarketingPageSection style={{ overflow: 'scroll', maxWidth: '100vw' }}>
                        <Box style={{ width: '900px', margin: 'auto' }}>
                            <PricingTable selectedPlanId={2} />
                        </Box>
                    </MarketingPageSection>
                    <MarketingPageSection style={{ textAlign: 'center' }}>
                        <br />
                        <br />
                        <br />
                        <br />
                        <br />
                        <H2 style={{ margin: '0 0 8px 0', color: '#000', fontWeight: 500 }}>
                            What if I have questions?
                        </H2>
                        Ping us at <a href="mailto:support@panelfox.io">support@panelfox.io</a>, we are happy to help.
                        <br />
                        <br />
                        <br />
                        <br />
                    </MarketingPageSection>
                </MarketingPageWrapper>

                <MarketingPageWrapper isMobile={isMobile} fullwidth background="#f9f5f1">
                    <MarketingPageSection style={{ textAlign: 'center' }}>
                        <MarketingFooter props={this.props} />
                    </MarketingPageSection>
                </MarketingPageWrapper>
            </Flex>
        );
    }
}

export default PricingPage;
