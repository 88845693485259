import React, { useMemo, useState } from 'react';
import { Box, Flex, Button } from 'rebass';
import { FiEye, FiLock, FiInbox, FiExternalLink } from 'react-icons/fi';
import classNames from 'classnames';
import { Link } from 'react-router-dom';

import NiceModal from 'components/NiceModal';
import { useQuery } from 'hooks/useQuery';
import Portal from 'components/Portal';
import { TabsProvider, Tab, Tabs } from 'components/Tabs';
import { CounterBadge } from 'components/CounterBadge';
import Table from 'components/Table';
import StatusLabel from 'components/StatusLabel';
import helpers from 'utils/helpers';
import studyService from 'services/studies';
import NumberedPagination from 'components/NumberedPagination';
import { useBasicPagination } from 'hooks/usePagination';
import authService from 'services/auth';
import {
    EMAIL_STATUS_BOUNCED,
    EMAIL_STATUS_COMPLAINT,
    EMAIL_STATUS_FAILED,
    EMAIL_STATUS_OPEN,
    EMAIL_STATUS_SENT,
    EMAIL_STATUS_READY,
    SMS_STATUS_SENT,
    SMS_STATUS_FAILED,
    MOMENT_HUMAN_DATE_NOTZ,
    EMAIL_STATUS_CODE_BOUNCED,
    EMAIL_STATUS_CODE_COMPLAINT,
    EMAIL_STATUS_CODE_FAILED,
    EMAIL_STATUS_CODE_OPEN,
    EMAIL_STATUS_CODE_SENT,
    EMAIL_STATUS_CODE_MAP
} from 'utils/constants';
import moment from 'utils/moment';

/**
 * Extracts text from an HTML string.
 *
 * @param {string} htmlString
 * @returns {string} The extracted text.
 */
function extractTextFromHTML(htmlString) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlString, 'text/html');
    return doc.body.textContent || '';
}

/**
 * Component to mask sensitive data.
 *
 * @param {Object} param0
 * @param {React.ReactNode} param0.children
 * @param {string} param0.placeholder
 * @param {((isMasked: boolean) => void) | undefined} param0.onMask
 * @param {string?} param0.className
 * @param {React.CSSProperties?} param0.style
 * @param {React.CSSProperties?} param0.iconStyle
 */
export const Mask = ({ children, placeholder, onMask, className, style, iconStyle }) => {
    const { account_role: accountRole } = authService.getAuthState();
    const [isMasked, setIsMasked] = useState(true);
    const isAdmin = accountRole.type === 'admin';

    const unmask = () => {
        if (!isAdmin) return;

        setIsMasked(false);
        onMask && onMask(false);
    };

    const mask = () => {
        setIsMasked(true);
        onMask && onMask(true);
    };

    if (isMasked || !isAdmin)
        return (
            <Box
                as="span"
                style={style}
                className={classNames('mask mask-on', !isAdmin && 'mask-disabled', className)}
                onClick={unmask}
            >
                <FiEye className="mask-icon" style={iconStyle} />
                {placeholder}
            </Box>
        );

    return (
        <Box as="span" style={style} className={classNames('mask', className)}>
            <FiLock onClick={mask} className="mask-icon" style={iconStyle} />
            {children}
        </Box>
    );
};

/**
 * Component to display a campaign (email/sms) status label.
 *
 * @param {Object} param0
 * @param {string} param0.status
 */
export function CampaignStatusLabel({ status }) {
    const statusName = helpers.capitalize(status);

    switch (status) {
        case EMAIL_STATUS_SENT:
        case SMS_STATUS_SENT:
        case EMAIL_STATUS_OPEN:
        case EMAIL_STATUS_READY:
            return <StatusLabel className="nowrap" status={statusName} color="green" />;
        case EMAIL_STATUS_BOUNCED:
        case EMAIL_STATUS_FAILED:
        case SMS_STATUS_FAILED:
            return <StatusLabel className="nowrap" status={statusName} color="danger" />;
        case EMAIL_STATUS_COMPLAINT:
            return <StatusLabel className="nowrap" status="Spam" color="danger" />;
        default:
            return <StatusLabel className="nowrap" status="Unknown" color="gray" />;
    }
}

/**
 * Transforms sms individual data to a more structured format.
 *
 * @param {Object} data The sms individual data.
 */
export const transformSmsData = data =>
    data.map(individual => ({
        id: individual.id,
        recipientsTo: {
            value: individual.recipients_to,
            isPII: helpers.isPII(individual.person, 'Phone Number')
        },
        firstName: {
            value: helpers.personGetCustomValue(individual.person, 'First Name'),
            isPII: helpers.isPII(individual.person, 'First Name')
        },
        lastName: {
            value: helpers.personGetCustomValue(individual.person, 'Last Name'),
            isPII: helpers.isPII(individual.person, 'Last Name')
        },
        message: individual.message_parsed,
        link:
            individual.study_person_id && individual.study_id
                ? `/studies/${individual.study_id}/participants/${individual.study_person_id}`
                : null,
        status: individual.status
    }));

const statusCodeOrder = [
    EMAIL_STATUS_CODE_FAILED,
    EMAIL_STATUS_CODE_COMPLAINT,
    EMAIL_STATUS_CODE_BOUNCED,
    EMAIL_STATUS_CODE_OPEN,
    EMAIL_STATUS_CODE_SENT
];

/**
 * Evaluates the email status based on the stats and fallback status.
 *
 * @param {{ status: string }} stats
 * @returns {number}
 */
export const evaluateEmailStatus = (stats, fallbackStatus) => {
    const statusCodes = stats
        .map(stat => stat.status)
        .sort((a, b) => statusCodeOrder.indexOf(a) - statusCodeOrder.indexOf(b));

    // if there is status code, return the first one
    const status = statusCodes[0];
    if (status) return EMAIL_STATUS_CODE_MAP[status];

    // stats has only 5 possible status codes
    // but email individual has them more (ready, cron-ext)
    // so we need to check if fallback status is ready, and if so, return it
    if (fallbackStatus === EMAIL_STATUS_READY) return fallbackStatus;

    return null;
};

/**
 * Transforms email individual data to a more structured format.
 *
 * @param {Object} data The email individual data.
 */
export const transformEmailIndividualData = data =>
    data.map(individual => ({
        id: individual.id,
        recipientsTo: {
            value: individual.recipients_to,
            isPII: helpers.isPII(individual.person, 'Email')
        },
        firstName: {
            value: helpers.personGetCustomValue(individual.person, 'First Name'),
            isPII: helpers.isPII(individual.person, 'First Name')
        },
        lastName: {
            value: helpers.personGetCustomValue(individual.person, 'Last Name'),
            isPII: helpers.isPII(individual.person, 'Last Name')
        },
        message: extractTextFromHTML(individual.message_parsed),
        link:
            individual.study_person_id && individual.study_id
                ? `/studies/${individual.study_id}/participants/${individual.study_person_id}`
                : null,
        status: evaluateEmailStatus(individual.email_campaign_stats, individual.status)
    }));

/**
 * Higher-order component that wraps a given component in a modal layout.
 *
 * @param {CampaignsTabs} Component - The component to be wrapped.
 * @returns {React.FC<{ campaign?: Object, isOpen: boolean, onClose: () => void }>} A component wrapped with a modal layout.
 */
export const withModal = Component => ({ campaign, isOpen, onClose, ...props }) => {
    if (!campaign) return null;

    const type = campaign.hasOwnProperty('subject') ? 'email' : 'sms';

    return (
        <Portal>
            <NiceModal
                style={{ content: { width: '1240px', maxWidth: '90%' } }}
                containerStyle={{ margin: 0 }}
                isOpen={isOpen}
                title={
                    <>
                        <Box as="span" display="block" mb="8px">
                            {type === 'email' ? campaign.subject : 'SMS Campaign'}
                        </Box>
                        <Box as="span" display="block" className="fs-body-14 color-text-secondary">
                            Sent by {campaign.user.name} on {moment(campaign.created_at).format(MOMENT_HUMAN_DATE_NOTZ)}
                        </Box>
                    </>
                }
                onRequestClose={onClose}
            >
                <Flex flexDirection="column" style={{ height: 'calc(80vh - 120px)' }}>
                    <Component campaign={campaign} type={type} {...props} />
                </Flex>
            </NiceModal>
        </Portal>
    );
};

/**
 * Component to display sms/email campaign data.
 *
 * @param {Object} props - The component props.
 * @param {Object} props.campaign - The campaign object.
 * @param {number} props.campaign.id - The campaign id.
 * @param {'email' | 'sms'} props.type - The type of the campaign.
 */
export function CampaignsTabs({ campaign, type }) {
    const [tab, setTab] = useState('total');
    const { page, perPage, changePage, changePerPage } = useBasicPagination();
    const { data, isLoading } = useQuery({
        queryFn: ({ campaignId, page, perPage, status: rawStatus }) => {
            const status = rawStatus === 'total' ? null : rawStatus;

            if (type === 'sms') {
                return studyService.getSmsIndividualsForCampaign(campaignId, { page, perPage, status });
            }

            if (type === 'email') {
                return studyService.getEmailIndividualsForCampaign(campaignId, { page, perPage, status });
            }

            // as a fallback return an empty array
            return null;
        },
        variables: { campaignId: campaign.id, page, perPage, status: tab }
    });
    const adaptedData = useMemo(() => {
        if (!data) return [];

        if (type === 'sms') return transformSmsData(data.data);

        if (type === 'email') return transformEmailIndividualData(data.data);

        return [];
    }, [data]);
    const statuses = useMemo(() => {
        if (type === 'sms')
            return [
                {
                    label: 'Total',
                    value: 'total',
                    count: campaign.stats.total
                },
                {
                    label: 'Sent',
                    value: SMS_STATUS_SENT,
                    count: campaign.stats[SMS_STATUS_SENT] || '-'
                },
                {
                    label: 'Failed',
                    value: SMS_STATUS_FAILED,
                    count: campaign.stats[SMS_STATUS_FAILED] || '-'
                }
            ];

        if (type === 'email')
            return [
                {
                    label: 'Total',
                    value: 'total',
                    count: campaign.stats2.total
                },
                {
                    label: 'Sent',
                    value: EMAIL_STATUS_SENT,
                    count: campaign.stats2[EMAIL_STATUS_SENT] || '-'
                },
                {
                    label: 'Open',
                    value: EMAIL_STATUS_OPEN,
                    count: campaign.stats2[EMAIL_STATUS_OPEN] || '-'
                },
                {
                    label: 'Bounced',
                    value: EMAIL_STATUS_BOUNCED,
                    count: campaign.stats2[EMAIL_STATUS_BOUNCED] || '-'
                },
                {
                    label: 'Spam',
                    value: EMAIL_STATUS_COMPLAINT,
                    count: campaign.stats2[EMAIL_STATUS_COMPLAINT] || '-'
                },
                {
                    label: 'Failed',
                    value: EMAIL_STATUS_FAILED,
                    count: campaign.stats2[EMAIL_STATUS_FAILED] || '-'
                }
            ];

        return [
            {
                label: 'Total',
                value: 'total',
                count: '-'
            }
        ];
    }, [campaign]);

    const onTabChange = newTab => {
        setTab(newTab);
        changePage(1);
    };

    return (
        <TabsProvider value={tab} onChange={onTabChange}>
            <Tabs>
                {statuses.map(status => (
                    <Tab key={status.label} value={status.value}>
                        {status.label}
                        <CounterBadge
                            style={{ marginLeft: '6px' }}
                            count={status.count}
                            isActive={tab === status.value}
                        />
                    </Tab>
                ))}
            </Tabs>
            {isLoading && (
                <Flex w="100%" style={{ flexGrow: 1 }} alignItems="center" justifyContent="center">
                    Loading...
                </Flex>
            )}
            {adaptedData.length === 0 && !isLoading && (
                <Flex
                    w="100%"
                    style={{ flexGrow: 1 }}
                    alignItems="center"
                    justifyContent="center"
                    flexDirection="column"
                >
                    <FiInbox style={{ fontSize: '24px' }} />
                    <Box as="p" mt="2">
                        No data available
                    </Box>
                </Flex>
            )}
            {!isLoading && adaptedData.length > 0 && (
                <Table style={{ flexGrow: 1, overflowY: 'auto', position: 'relative' }}>
                    <Table.Head className="sticky bg-white" style={{ top: 0 }}>
                        <Table.Tr>
                            <Table.Th>Name</Table.Th>
                            <Table.Th>Message</Table.Th>
                            <Table.Th>Contact</Table.Th>
                            <Table.Th>Status</Table.Th>
                            <Table.Th></Table.Th>
                        </Table.Tr>
                    </Table.Head>
                    <Table.Body>
                        {adaptedData.map(row => (
                            <Table.Tr key={row.id}>
                                <Table.Td className="nowrap text-primary">
                                    <Flex alignItems="center">
                                        {row.firstName.isPII ? (
                                            <Mask placeholder="Unmask first name" style={{ marginRight: '4px' }}>
                                                {row.firstName.value}
                                            </Mask>
                                        ) : (
                                            row.firstName.value
                                        )}{' '}
                                        {row.lastName.isPII ? (
                                            <Mask placeholder="Unmask last name" style={{ marginLeft: '4px' }}>
                                                {row.lastName.value}
                                            </Mask>
                                        ) : (
                                            row.lastName.value
                                        )}
                                    </Flex>
                                </Table.Td>
                                <Table.Td style={{ maxWidth: '400px' }} className="ellipsis text-primary">
                                    {row.message}
                                </Table.Td>
                                <Table.Td className="color-text-secondary nowrap">
                                    {row.recipientsTo.isPII ? (
                                        <Mask placeholder="Unmask email">{row.recipientsTo.value}</Mask>
                                    ) : (
                                        row.recipientsTo.value
                                    )}
                                </Table.Td>
                                <Table.Td className="nowrap">
                                    <CampaignStatusLabel status={row.status} />
                                </Table.Td>
                                <Table.Td className="nowrap">
                                    {row.link && (
                                        <Button
                                            as={Link}
                                            to={row.link}
                                            target="_blank"
                                            variant="secondary"
                                            className="va-top secondary-tiny study-person-more-hover"
                                        >
                                            <FiExternalLink /> View
                                        </Button>
                                    )}
                                </Table.Td>
                            </Table.Tr>
                        ))}
                    </Table.Body>
                </Table>
            )}

            {data && (
                <NumberedPagination
                    total={data.total}
                    page={page}
                    perPage={perPage}
                    onPageChange={changePage}
                    onPerPageChange={changePerPage}
                    from={data.from}
                    links={data.links}
                    to={data.to}
                />
            )}
        </TabsProvider>
    );
}

export const CampaignsModal = withModal(CampaignsTabs);

export default CampaignsModal;
