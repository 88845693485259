const deploy_env = process.env.DEPLOY_ENV || 'development';
const SUPPORT_EMAIL = 'support@panelfox.io';
const location_host = window.location.host;

function isLocalEnv() {
    return location_host.includes('localhost');
}

function getProtocol() {
    return isLocalEnv() ? 'http://' : 'https://';
}

function getUIPort() {
    return isLocalEnv() ? ':3000' : '';
}

function getAPIPort() {
    return isLocalEnv() ? ':8888' : '';
}

function getAPIPrefix() {
    return isLocalEnv() ? '' : 'api.';
}

function getBaseUrl() {
    if (deploy_env === 'production') {
        if (isEu(location_host)) {
            return 'eu.panelfox.io';
        } else {
            return 'panelfox.io';
        }
    } else if (deploy_env.startsWith('staging-')) {
        return deploy_env + '.panelfox.io';
    } else {
        return 'localhost';
    }
}

function getDefaultUiUrl() {
    return getProtocol() + getBaseUrl() + getUIPort();
}

function getDefaultApiUrl() {
    return getProtocol() + getAPIPrefix() + getBaseUrl() + getAPIPort() + '/api';
}

function getDefaultOauthUrl() {
    return getProtocol() + getAPIPrefix() + getBaseUrl() + getAPIPort() + '/oauth';
}

/**
 * Get the homepage URL (external link)
 */
function getHomepageUrl() {
    return getProtocol() + getBaseUrl() + getAPIPort();
}

function getAccountPrefixUrl(account) {
    if (account && account.custom_domain) {
        return account.custom_domain;
    } else {
        return getDefaultUiUrl();
    }
}

function isEu(host) {
    if (
        [
            'community.angelfish-opinions.com',
            'research.bunnyfield.com',
            'community.ithoughtsresearch.com',
            'research.missiontranslate.com',
            'expert.smartconnectresearch.com',
            'eu.panelfox.io'
        ].includes(host)
    ) {
        return true;
    } else {
        return false;
    }
}

var configs = {
    production: {
        is_eu_server: isEu(location_host),
        getAccountPrefixUrl: getAccountPrefixUrl,
        UI_URL: getDefaultUiUrl(),
        API_URL: getDefaultApiUrl(),
        HOMEPAGE_URL: getHomepageUrl(),
        OAUTH_URL: getDefaultOauthUrl(),
        WIDGET_URL: 'https://static.panelfox.io/bundle.js',
        BENNY_TOKEN: 'bc0ed2d3-414a-4624-a2e4-ba0852893f6a',
        stripe_key_public: 'pk_live_z7a9zJ53Hkwaamh6gSYtEOaP',
        ZOOM_CLIENT_ID: 'DYll_tSoTHeyOnmQHTcaVQ',
        AWS_BUCKET: 'panelfox-images-prod',
        PUSHER_KEY: 'c95049b5fdeb64dc3ca4',
        PUSHER_CLUSTER: 'us3',
        MIXPANEL_TOKEN: '430792e559caba328f14491bf59c4a89',
        SUPPORT_EMAIL: SUPPORT_EMAIL,
        GOOGLE_RECAPTCHA_SITEKEY: '6Lf_ijkmAAAAAAt7VUXBkULYgukzj5QvBqXkzK3H',
        DSCOUT_APP_URL: 'https://app.dscout.com'
    },
    development: {
        is_eu_server: isEu(location_host),
        getAccountPrefixUrl: getAccountPrefixUrl,
        UI_URL: getDefaultUiUrl(),
        API_URL: getDefaultApiUrl(),
        HOMEPAGE_URL: getHomepageUrl(),
        OAUTH_URL: getDefaultOauthUrl(),
        WIDGET_URL: 'http://localhost:8080/bundle.js',
        BENNY_TOKEN: 'f3926f36-da2c-4f95-a559-0ddd840c36cc',
        stripe_key_public: 'pk_test_9MaBepFjTJE24j6EaCIWzqLa',
        ZOOM_CLIENT_ID: 'YEJViR1WToehDHQdSgCA4w',
        AWS_BUCKET: 'panelfox-images-local',
        PUSHER_KEY: '2b0b36b5a1e86b15063e',
        PUSHER_CLUSTER: 'us3',
        MIXPANEL_TOKEN: 'f4c8754688fc1e4009b2fa3812e64189',
        SUPPORT_EMAIL: SUPPORT_EMAIL,
        GOOGLE_RECAPTCHA_SITEKEY: '6LcekTkmAAAAAC4jRIHfSluWEQXcdXuUzjGHThyr',
        DSCOUT_APP_URL: 'https://staging-4.app.dscout.com'
    },
    test: {
        is_eu_server: isEu(location_host),
        getAccountPrefixUrl: getAccountPrefixUrl,
        UI_URL: getDefaultUiUrl(),
        API_URL: 'http://localhost:8000/api',
        HOMEPAGE_URL: 'http://localhost:8000',
        OAUTH_URL: getDefaultOauthUrl(),
        WIDGET_URL: 'http://localhost:8080/bundle.js',
        BENNY_TOKEN: 'f3926f36-da2c-4f95-a559-0ddd840c36cc',
        stripe_key_public: 'pk_test_9MaBepFjTJE24j6EaCIWzqLa',
        ZOOM_CLIENT_ID: 'YEJViR1WToehDHQdSgCA4w',
        AWS_BUCKET: 'panelfox-images-local',
        PUSHER_KEY: '2b0b36b5a1e86b15063e',
        PUSHER_CLUSTER: 'us3',
        MIXPANEL_TOKEN: 'f4c8754688fc1e4009b2fa3812e64189',
        SUPPORT_EMAIL: SUPPORT_EMAIL,
        GOOGLE_RECAPTCHA_SITEKEY: '6LcbkTkmAAAAAEVOOU1QHgRvOyWtKjeY5PovbBMV',
        DSCOUT_APP_URL: 'https://staging-4.app.dscout.com'
    }
};

if (deploy_env.startsWith('staging-')) {
    configs[deploy_env] = {
        is_eu_server: isEu(location_host),
        getAccountPrefixUrl: getAccountPrefixUrl,
        UI_URL: getDefaultUiUrl(),
        API_URL: getDefaultApiUrl(),
        HOMEPAGE_URL: getHomepageUrl(),
        OAUTH_URL: getDefaultOauthUrl(),
        WIDGET_URL: 'https://static.panelfox.io/bundle.js',
        BENNY_TOKEN: 'f3926f36-da2c-4f95-a559-0ddd840c36cc',
        stripe_key_public: 'pk_test_9MaBepFjTJE24j6EaCIWzqLa',
        ZOOM_CLIENT_ID: 'YEJViR1WToehDHQdSgCA4w',
        AWS_BUCKET: 'panelfox-images-' + deploy_env,
        PUSHER_KEY: '2b0b36b5a1e86b15063e',
        PUSHER_CLUSTER: 'us3',
        MIXPANEL_TOKEN: 'f4c8754688fc1e4009b2fa3812e64189',
        SUPPORT_EMAIL: SUPPORT_EMAIL,
        GOOGLE_RECAPTCHA_SITEKEY: '6LcbkTkmAAAAAEVOOU1QHgRvOyWtKjeY5PovbBMV',
        DSCOUT_APP_URL: 'https://staging-4.app.dscout.com'
    };
}

export const isProduction = deploy_env === 'production';

export default configs[deploy_env];
