import { useMemo } from 'react';

import helpers from 'utils/helpers';

/**
 * Validate key against lock.
 *
 * @param {string?} key
 * @param {string} lock
 * @returns {boolean}
 */
export const useKeyValidator = (key, lock) =>
    useMemo(() => {
        if (!key) return false;

        return helpers.validateKey(key, lock);
    }, [key]);

export default useKeyValidator;
