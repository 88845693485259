import React, { useState, useEffect, useMemo, useRef } from 'react';
import { useHistory, useLocation } from 'react-router-dom';
import styled from 'styled-components';
import { throttle, debounce } from 'lodash';

const StyledDiv = styled.div`
    position: sticky;
    top: ${props => (props.topOffset ? `${props.topOffset + 32}px` : '32px')};
    padding: 4px 32px 4px 32px;
    margin-left: 48px;
    display: flex;
    flex-direction: column;
    gap: 23px;
    border-left: 1px solid #e7e8f1;
`;

const NavLink = styled.a`
    font-size: 14px;
    line-height: 20px;
    color: #001233;
    font-weight: ${props => (props.highlighted ? 500 : 400)};
    color: ${props => (props.highlighted ? '#0A73EB' : '#001233')};
    &:hover {
        color: ${props => (props.highlighted ? '#0A73EB' : '#001233')};
    }
    &:after {
        display: block;
        content: attr(data-text);
        font-weight: 500;
        height: 1px;
        color: transparent;
        overflow: hidden;
        visibility: hidden;
    }
`;

function SideNav(props) {
    const [highlightedId, setHighlightedId] = useState();
    const disableOnScrollHighlightingRef = useRef(false);
    const history = useHistory();
    const location = useLocation();

    const handleScroll = () => {
        if (disableOnScrollHighlightingRef.current) return;

        const currentSections = props.sections.filter(section => section.ref && section.ref.current);

        const firstFromTop = currentSections
            .slice()
            .reverse()
            .find(
                (section, index) =>
                    section.ref.current.getBoundingClientRect().top < 100 || index === currentSections.length - 1
            );
        const firstWithVisibleHeaderFromTop = currentSections.find(section => {
            const yPos = section.ref.current.getBoundingClientRect().top;
            return yPos > -50 && yPos < window.innerHeight - 150;
        });

        if (firstWithVisibleHeaderFromTop) {
            setHighlightedId(firstWithVisibleHeaderFromTop.id);
        } else if (firstFromTop) {
            setHighlightedId(firstFromTop.id);
        }
    };

    const onScrollEnd = () => {
        disableOnScrollHighlightingRef.current = false;
    };

    const handleScrollToSection = section => {
        history.push(`#${section.id}`);
        section.ref.current.scrollIntoView();
    };

    const debouncedOnScrollEnd = useMemo(() => debounce(onScrollEnd, 300), []);

    const throttledHandleScroll = useMemo(() => throttle(handleScroll, 100), []);

    useEffect(() => {
        window.addEventListener('scroll', throttledHandleScroll, { passive: true });
        window.addEventListener('resize', throttledHandleScroll);
        window.addEventListener('scroll', debouncedOnScrollEnd);

        if (
            location.hash === '' ||
            props.sections.filter(section => section.id === location.hash.substring(1)).length === 0
        ) {
            const currentSections = props.sections.filter(section => section.display);
            if (currentSections.length > 0) {
                setHighlightedId(currentSections[0].id);
            }
        }

        return () => {
            window.removeEventListener('scroll', throttledHandleScroll);
            window.removeEventListener('resize', throttledHandleScroll);
            window.removeEventListener('scroll', debouncedOnScrollEnd);
            debouncedOnScrollEnd.cancel();
            throttledHandleScroll.cancel();
        };
    }, []);

    useEffect(() => {
        if (location.hash && props.sections.filter(section => section.id === location.hash.substring(1)).length > 0) {
            disableOnScrollHighlightingRef.current = true;
            setHighlightedId(location.hash.substring(1));
            debouncedOnScrollEnd();
        }
    }, [location]);

    return (
        <StyledDiv topOffset={props.topOffset}>
            {props.sections.map(section =>
                section.display ? (
                    <NavLink
                        highlighted={highlightedId === section.id}
                        data-text={section.label}
                        key={section.id}
                        onClick={() => handleScrollToSection(section)}
                    >
                        {section.label}
                    </NavLink>
                ) : null
            )}
        </StyledDiv>
    );
}

export default SideNav;
