import React from 'react';
import { FiPlus } from 'react-icons/fi';
import { Button } from 'rebass';

import { Portal } from 'components/Portal';
import { Positioner } from 'components/Positioner';
import NiceDropdown from 'components/NiceDropdown';

/**
 * Panel property group dropdown component
 *
 * @param {Object} props
 * @param {Object} props.controllerRef Reference to the controller element
 * @param {boolean} props.isVisible Visibility state
 * @param {() => void} props.onClose Close handler
 * @param {?Array<{ id: number, name: string }>} props.panelPropertiesGroups List of panel property groups
 * @param {(groupId: number) => void} props.onClick Click handler
 * @param {() => void} props.onCreateClick Create click handler
 */
export function PanelPropertiesGroupsDropdown({
    controllerRef,
    isVisible,
    onClose,
    panelPropertiesGroups,
    onClick,
    onCreateClick
}) {
    return (
        <Portal>
            <Positioner controllerRef={controllerRef} isVisible={isVisible}>
                <NiceDropdown
                    onClose={onClose}
                    style={{ inset: 'auto', position: 'static' }}
                    items={(panelPropertiesGroups || []).map(group => ({
                        id: group.id,
                        title: group.name,
                        onClick: () => onClick(group.id)
                    }))}
                    width="190px"
                    renderBottomStickyButton={
                        <Button
                            width="100%"
                            textAlign="left"
                            variant="link"
                            onClick={() => {
                                onClose();
                                onCreateClick();
                            }}
                        >
                            <FiPlus /> Add group
                        </Button>
                    }
                />
            </Positioner>
        </Portal>
    );
}

export default PanelPropertiesGroupsDropdown;
