import helpers from 'utils/helpers';
import auth from 'services/auth';

const CanShow = props => {
    const authObject = auth.getAuthState();
    const couldShow = helpers.doesUserHaveRouteAccess(authObject, props.role);
    return couldShow ? props.children : null;
};

export default CanShow;
