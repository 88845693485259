import React, { useState } from 'react';
import { Button } from 'rebass';
import { FiArrowUp } from 'react-icons/fi';

import helpers from 'utils/helpers';
import useBeaconButton from 'hooks/useBeaconButton';

// Default button position
const DEFAULT_POSITION = {
    bottom: 40, // margin
    right: 40, // margin
    '@media screen and (max-height: 740px)': {
        bottom: 10, // margin
        right: 20 // margin
    }
};

// Position of the button when the beacon is loaded
const BEACON_POSITION = {
    bottom: 40 + 60 + 8, // margin + beacon height + gap
    right: 40 + 9.5, // margin + alignment
    '@media screen and (max-height: 740px)': {
        bottom: 10 + 60 + 8, // margin + beacon height + gap
        right: 20 + 9.5 // margin + alignment
    }
};

/**
 * Component that renders a button to scroll to the top of the page
 */
export function ScrollToTopButton() {
    const [position, setPosition] = useState(DEFAULT_POSITION);

    useBeaconButton({
        onLoaded: () => setPosition(BEACON_POSITION)
    });

    return (
        <Button
            variant="circle"
            sx={{ position: 'fixed', zIndex: 100, ...position }}
            onClick={() => {
                helpers.scrollToTop();
            }}
        >
            <FiArrowUp style={{ fontSize: '29px', strokeWidth: '1px' }} />
        </Button>
    );
}

export default ScrollToTopButton;
