import styled from 'styled-components';
import React from 'react';

const AppPageWrapperStyled = styled.div.attrs(props => ({
    background: props.background || '#fff'
}))`
    z-index: 1;
    flex: 1 1 0%;
    background: ${props => props.background};
    min-width: 0px;
`;

const AppPageWrapper100vhStyled = styled.div`
    display: flex;
    flex-direction: column;
    //height: 100vh;
`;

const AppPageWrapper = props => (
    <AppPageWrapperStyled {...props}>
        <AppPageWrapper100vhStyled>{props.children}</AppPageWrapper100vhStyled>
    </AppPageWrapperStyled>
);

export default AppPageWrapper;
