import React, { useState, useEffect } from 'react';
import { Link } from 'react-router-dom';
import ReactModal from 'react-modal';
import ReactModalActions from 'components/ReactModalActions';
import { Helmet } from 'react-helmet';
import { useToasts } from 'react-toast-notifications';
import H1 from 'components/H1';
import H2 from 'components/H2';
import { Flex, Box, Button } from 'rebass';
import { Input, Select, Label, Radio } from '@rebass/forms';
import helpers from 'utils/helpers';
import services from 'services/services';
import service_accounts from 'services/accounts';
import { FiEdit, FiTrash2 } from 'react-icons/fi';
import moment from 'moment-timezone';

import { EMAIL_TEMPLATE_TYPE } from 'utils/constants';
import AppPageWrapper from 'components/AppPageWrapper';
import AppPageWrapperSectionHeader from 'components/AppPageWrapper/AppPageWrapperSectionHeader';
import AppPageWrapperSectionBody from 'components/AppPageWrapper/AppPageWrapperSectionBody';
import AppPageWrapperSectionSubHeader from 'components/AppPageWrapper/AppPageWrapperSectionSubHeader';
import EmailTemplateTypeLabel from 'components/EmailTemplateTypeLabel';
import SettingsPageSubNav from 'containers/SettingsPage/SettingsPageSubNav';
import AppPageSubnav from 'components/AppPageWrapper/AppPageSubnav';
import ListWrapper from 'components/ListWrapper';
import ItemWrapper from 'components/ItemWrapper';
import InputSearch from 'components/InputSearch';
import NiceModal from 'components/NiceModal';
import LoadingIndicator from 'components/LoadingIndicator';
import LoadingWrapper from 'components/LoadingIndicator/LoadingWrapper';
import { ActionButton } from 'components/Button/ActionButton';

function SettingsEmailTemplatesPage(props) {
    const [showModalCreateEmailTemplate, setShowModalCreateEmailTemplate] = useState(false);
    const [emailTemplates, setEmailTemplates] = useState([]);
    const [searchKeyword, setSearchKeyword] = useState('');
    const [showModalAddCustomColumn, setShowModalAddCustomColumn] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const { addToast } = useToasts();

    useEffect(() => {
        getEmailTemplatesXHR();
    }, []);

    function getEmailTemplatesXHR() {
        service_accounts.getEmailTemplates().then(email_templates => {
            setEmailTemplates(email_templates);
            setIsLoading(false);
        });
    }

    function onSearchKeywordChange(e) {
        setSearchKeyword(e.target.value);
    }

    async function onEmailTemplateSubmit(e) {
        e.preventDefault();

        const title = document.getElementById('email_template_title').value;
        const subject = document.getElementById('email_template_subject').value;
        const type = document.querySelector('#editingMode input[name="type"]:checked').value;

        try {
            const emailTemplate = await service_accounts.createEmailTemplate({
                title,
                subject,
                type
            });

            setShowModalCreateEmailTemplate(false);

            const redirect_url = `/settings/email-templates/${emailTemplate.id}`;

            props.history.push(redirect_url);

            addToast('Successfully created', {
                appearance: 'success',
                autoDismiss: true
            });
        } catch (error) {
            const errorText = services.parseAndTrackXhrErrors(error);

            addToast(errorText, {
                appearance: 'error',
                autoDismiss: true
            });
        }
    }

    function deleteEmailTemplateXHR(email_template_id) {
        service_accounts
            .deleteEmailTemplate(email_template_id)
            .then(() => {
                getEmailTemplatesXHR();
                addToast('Successfully deleted', {
                    appearance: 'success',
                    autoDismiss: true
                });
            })
            .catch(error => {
                //console.log(error);
                const errorText = services.parseAndTrackXhrErrors(error);
                addToast(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    function onUserInviteSubmit(e) {
        e.preventDefault();

        const user_invite_email = document.getElementsByName('user_invite_email');
        const user_invite_role = document.getElementsByName('user_invite_role');
        services
            .createUserInvite(user_invite_email[0].value, user_invite_role[0].value)
            .then(() => {
                getUserInvitesXHR();
                user_invite_email[0].value = '';
                addToast('User successfully invited', {
                    appearance: 'success',
                    autoDismiss: true
                });
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);
                addToast(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    function renderEmailTemplates() {
        const render = [];

        emailTemplates.forEach(et => {
            if (!searchKeyword || helpers.findInString(searchKeyword, et.title)) {
                render.push(
                    <Link to={`/settings/email-templates/${et.id}`}>
                        <ItemWrapper key={et.id}>
                            <Box width={3 / 8}>
                                <Link to={`/settings/email-templates/${et.id}`} className="muted">
                                    {et.title}
                                </Link>
                            </Box>
                            <Box width={2 / 8} className="text-primary">
                                <EmailTemplateTypeLabel
                                    css={{
                                        width: 'max-content',
                                        '& > svg': {
                                            margin: '0'
                                        }
                                    }}
                                    type={et.type}
                                />
                            </Box>
                            <Box width={1 / 8} className="text-primary">
                                {et.user.name}
                            </Box>
                            <Box width={1 / 8} className="text-primary">
                                {moment.utc(et.created_at).format('MMMM D, YYYY')}
                            </Box>
                            <Box width={1 / 8} className="text-primary" style={{ textAlign: 'right' }}>
                                {/*<Link to={`/settings/email-templates/${et.id}`}>
                                    <Button
                                        type="button"
                                        mr={2}
                                        variant="tertiary"
                                    >
                                        <FiEdit/> Edit
                                    </Button>
                                </Link>*/}
                                <Button
                                    type="button"
                                    variant="secondary-gray"
                                    className="secondary-icon"
                                    onClick={e => {
                                        e.preventDefault();
                                        e.stopPropagation();
                                        if (
                                            // eslint-disable-next-line
                                            confirm(`Are you sure you want to delete the "${et.title}" email template?`)
                                        ) {
                                            deleteEmailTemplateXHR(et.id);
                                        }
                                    }}
                                >
                                    <FiTrash2 />
                                </Button>
                            </Box>
                        </ItemWrapper>
                    </Link>
                );
            }
        });
        return render;
    }

    const num_matched = emailTemplates.length;

    return (
        <Flex style={{ width: '100%' }}>
            <AppPageWrapper>
                <Helmet>
                    <title>User Management</title>
                </Helmet>
                <Flex>
                    <SettingsPageSubNav />
                </Flex>
                <AppPageWrapperSectionHeader className="experience">
                    <Box>
                        <H1>Email Templates</H1>
                        <Box className="experience-description">Use these templates to standardize your emails.</Box>
                    </Box>
                    <Box mt={2}>
                        <ActionButton
                            title="New Email Template"
                            action={() => setShowModalCreateEmailTemplate(true)}
                            keyLabel="N"
                            mr={0}
                        />
                    </Box>
                </AppPageWrapperSectionHeader>
                <AppPageWrapperSectionBody>
                    <Box mx={3} my={3} width="100%">
                        <ItemWrapper className="header-row">
                            <Box width={3 / 8}>Title</Box>
                            <Box width={2 / 8}>Template Type</Box>
                            <Box width={1 / 8}>Created By</Box>
                            <Box width={1 / 8}>Created On</Box>
                            <Box width={1 / 8} style={{ textAlign: 'right' }}>
                                Actions
                            </Box>
                        </ItemWrapper>
                        {isLoading ? (
                            <Box m={3} style={{ minHeight: '24px' }}>
                                <LoadingWrapper>
                                    <LoadingIndicator />
                                </LoadingWrapper>
                            </Box>
                        ) : emailTemplates.length > 0 ? (
                            renderEmailTemplates()
                        ) : (
                            <Box m={3} fontSize={1}>
                                0 templates, create your first.
                            </Box>
                        )}
                        <br />
                        <br />
                    </Box>

                    <NiceModal
                        isOpen={showModalCreateEmailTemplate}
                        shouldCloseOnOverlayClick
                        onRequestClose={() => setShowModalCreateEmailTemplate(false)}
                        title={`Create email template`}
                    >
                        <form onSubmit={onEmailTemplateSubmit}>
                            <Box>
                                <Label htmlFor="title">Email Template Title</Label>
                                <Input
                                    id="email_template_title"
                                    type="text"
                                    name="title"
                                    placeholder="Default screener email template"
                                    autoComplete="off"
                                    required
                                    autoFocus
                                />
                            </Box>
                            <Box mt={3}>
                                <Label htmlFor="title">Subject</Label>
                                <Input
                                    id="email_template_subject"
                                    type="text"
                                    name="subject"
                                    placeholder="Default subject"
                                    autoComplete="off"
                                    required
                                />
                            </Box>
                            <Box mt={3} id="editingMode">
                                <Label>
                                    Template type
                                    <a
                                        css={{ marginLeft: '8px', marginTop: '2px' }}
                                        href="#"
                                        data-beacon-article="630eea61c713d51da3eda29e"
                                        className="help-question"
                                    >
                                        ?
                                    </a>
                                </Label>
                                <Label htmlFor="wysiwygEditingMode" className="for-radio">
                                    <Radio
                                        id="wysiwygEditingMode"
                                        name="type"
                                        value={EMAIL_TEMPLATE_TYPE.BLOCKS}
                                        defaultChecked
                                    />{' '}
                                    WYSIWYG
                                </Label>
                                <Label htmlFor="htmlEditingMode" className="for-radio">
                                    <Radio id="htmlEditingMode" name="type" value={EMAIL_TEMPLATE_TYPE.HTML} /> HTML
                                </Label>
                            </Box>

                            <Box className="modal-actions">
                                <Button type="submit" variant="primary" className="modal-primary" mr={0}>
                                    Create email template
                                </Button>
                            </Box>
                        </form>
                    </NiceModal>
                </AppPageWrapperSectionBody>
            </AppPageWrapper>
        </Flex>
    );
}

export default SettingsEmailTemplatesPage;
