import React, { useState, useEffect, useRef } from 'react';
import { Box, Button, Flex, Image, Text } from 'rebass';
import { Input } from '@rebass/forms';
import { FiChevronDown, FiMapPin, FiTrash2, FiEdit } from 'react-icons/fi';
import ReactModal from 'react-modal';
import PropTypes from 'prop-types';
import { useTheme } from 'emotion-theming';

import NiceDropdown from 'components/NiceDropdown';
import H1 from 'components/H1';
import consts from 'consts';
import NiceModal from '../NiceModal';

LocationSettings.propTypes = {
    study: PropTypes.object,
    account: PropTypes.object,
    onChange: PropTypes.func
};

function LocationSettings(props) {
    const theme = useTheme();
    const didMount = useRef(false);

    const [study, setStudy] = useState(props.study);
    const [showLocationModal, setShowLocationModal] = useState();
    const [showLocationDropdown, setShowLocationDropdown] = useState(false);
    const [showLocationModalDropdown, setShowLocationModalDropdown] = useState(false);
    const [locationInput, setLocationInput] = useState(props.study.location || '');
    const [modalLocationType, setModalLocationType] = useState(props.study.location_type || '');

    const isZoomConnected = props.auth.account.zoom_account_id;
    const zoomDropdownTitle = isZoomConnected ? (
        'Zoom'
    ) : (
        <>
            <Text display="inline" sx={{ pointerEvents: 'none', cursor: 'initial' }}>
                Zoom -{' '}
            </Text>
            <a href={consts.zoomAuthorizationUrl} target="_blank">
                Connect
            </a>
        </>
    );

    const isMsteamsConnected = props.auth.user.microsoft_calendar;
    const msteamsDropdownTitle = isMsteamsConnected ? (
        'MS Teams'
    ) : (
        <>
            <Text display="inline" sx={{ pointerEvents: 'none', cursor: 'initial' }}>
                MS Teams -{' '}
            </Text>
            <a href={'/me#calendar-integrations'} target="_blank">
                Connect
            </a>
        </>
    );

    const isMeetConnected = props.auth.user.google_calendar;
    const meetDropdownTitle = isMeetConnected ? (
        'Meet'
    ) : (
        <>
            <Text display="inline" sx={{ pointerEvents: 'none', cursor: 'initial' }}>
                Meet -{' '}
            </Text>
            <a href={'/me#calendar-integrations'} target="_blank">
                Connect
            </a>
        </>
    );

    const dropdownItems = [
        {
            id: 'custom',
            title: 'Custom location',
            icon: <FiMapPin />
        },
        {
            id: 'zoom',
            title: zoomDropdownTitle,
            icon: <Image src="/zoom2.svg" alt="Zoom" height="20px" sx={{ margin: '-2px 4px 0 -2px' }} />,
            disabled: !isZoomConnected
        },
        {
            id: 'msteams',
            title: msteamsDropdownTitle,
            icon: <Image src="/msteams.png" alt="MS Teams" height="20px" sx={{ margin: '-2px 4px 0 -2px' }} />,
            disabled: !isMsteamsConnected
        },
        {
            id: 'meet',
            title: meetDropdownTitle,
            icon: <Image src="/meet.png" alt="Meet" height="20px" sx={{ margin: '-2px 4px 0 -2px' }} />,
            disabled: !isMeetConnected
        }
    ];

    useEffect(() => {
        //console.log('study updated');
        setStudy(props.study);
    }, [props.study]);

    useEffect(() => {
        setModalLocationType(props.study.location_type);
    }, [props.study.location_type]);

    useEffect(() => {
        setLocationInput(props.study.location);
    }, [props.study.location]);

    function handleLocationTypeSelect(id) {
        setShowLocationDropdown(false);

        if (id === 'custom') {
            setModalLocationType(id);
            setShowLocationModal(true);
        } else if (['zoom', 'msteams', 'meet'].includes(id)) {
            study.location_type = id;
            setStudy(study);
            props.onChange(study);
        } else {
            alert('Location type unknown');
        }
    }

    function saveLocation() {
        if (!locationInput.trim()) {
            return;
        }
        study.location_type = modalLocationType;
        study.location = locationInput;
        setStudy(study);
        setShowLocationModal(false);
        props.onChange(study);
    }

    function deleteLocation() {
        study.location = null;
        study.location_type = null;
        setStudy(study);
        props.onChange(study);
    }

    function explainer(message) {
        return (
            <Box mt={1} className={'fs-12 text-secondary'}>
                {message}
            </Box>
        );
    }

    function locationActionButtons() {
        return (
            <Box ml={3}>
                {study.location_type == 'custom' && (
                    <Button variant="secondary-gray" ml={2} onClick={() => setShowLocationModal(true)}>
                        <FiEdit /> Edit
                    </Button>
                )}
                <Button variant="secondary-gray" className="secondary-icon" ml={1} onClick={() => deleteLocation()}>
                    <FiTrash2 />
                </Button>
            </Box>
        );
    }

    return (
        <>
            <Box mb={2}>
                {study.location_type === null && (
                    <Flex sx={{ position: 'relative' }}>
                        <Button
                            name="location"
                            variant="secondary"
                            mr={2}
                            onClick={() => setShowLocationDropdown(true)}
                        >
                            Add a location{' '}
                            <FiChevronDown
                                style={{
                                    margin: '5px 0 0 0'
                                }}
                            />
                        </Button>
                        {showLocationDropdown && (
                            <NiceDropdown
                                onClose={() => setShowLocationDropdown(false)}
                                items={dropdownItems}
                                onChange={value => handleLocationTypeSelect(value)}
                            />
                        )}
                    </Flex>
                )}
                {study.location_type === 'zoom' && (
                    <Box color={theme.colors.black} fontSize={1}>
                        <Flex>
                            <Flex alignItems="center">
                                <Image src="/zoom2.svg" alt="Zoom" height="20px" />
                                <Box ml={2}>Zoom</Box>
                            </Flex>
                            {locationActionButtons()}
                        </Flex>
                        {explainer('Each invite includes a unique meeting link.')}
                    </Box>
                )}
                {study.location_type === 'msteams' && (
                    <Box color={theme.colors.black} fontSize={1}>
                        <Flex>
                            <Flex alignItems="center">
                                <Image src="/msteams.png" alt="MS Teams" height="20px" />
                                <Box ml={2}>MS Teams</Box>
                            </Flex>
                            {locationActionButtons()}
                        </Flex>
                        {explainer('Each invite includes a unique meeting link.')}
                    </Box>
                )}
                {study.location_type === 'meet' && (
                    <Box color={theme.colors.black} fontSize={1}>
                        <Flex>
                            <Flex alignItems="center">
                                <Image src="/meet.png" alt="MS Teams" height="20px" />
                                <Box ml={2}>Meet</Box>
                            </Flex>
                            {locationActionButtons()}
                        </Flex>
                        {explainer('Each invite includes a unique meeting link.')}
                    </Box>
                )}
                {study.location_type === 'custom' && (
                    <Box color={theme.colors.black} fontSize={1}>
                        <Flex>
                            <Flex alignItems="center">
                                <FiMapPin /> <Box ml={2}>{study.location}</Box>
                            </Flex>
                            {locationActionButtons()}
                        </Flex>
                    </Box>
                )}
            </Box>
            <NiceModal
                isOpen={showLocationModal}
                shouldCloseOnOverlayClick
                onRequestClose={() => setShowLocationModal(false)}
                title="Edit Location"
            >
                <Box>
                    <Box mb={3} sx={{ position: 'relative', marginTop: '1px' }}>
                        <Input
                            ref={input => input && input.focus()}
                            placeholder="Location"
                            value={locationInput || ''}
                            onChange={e => setLocationInput(e.target.value)}
                            onKeyDown={e => (e.key === 'Enter' ? saveLocation() : null)}
                            required
                        />
                    </Box>
                    <Box className="modal-actions">
                        <Button variant="secondary-gray" onClick={() => setShowLocationModal(false)}>
                            Cancel
                        </Button>
                        <Button
                            variant="primary"
                            onClick={() => saveLocation()}
                            ml={3}
                            mr={0}
                            disabled={modalLocationType === 'custom' && !locationInput}
                        >
                            Save
                        </Button>
                    </Box>
                </Box>
            </NiceModal>
        </>
    );
}

export default LocationSettings;
