import React, { useState, useEffect, useMemo, useRef } from 'react';
import H1 from 'components/H1';
import { Flex, Box, Button } from 'rebass';
import { Label, Checkbox, Select } from '@rebass/forms';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import styled from 'styled-components';
import services from 'services/services.js';
import studies_service from 'services/studies.js';
import helpers from 'utils/helpers.js';
import helpersScreener from 'utils/helpers-screener.js';
import { useToasts } from 'react-toast-notifications';
import ListWrapper from 'components/ListWrapper';
import { ReactFormGenerator } from 'components/ReactFormBuilder';
import ReactModalActions from 'components/ReactModalActions';
import cloneDeep from 'lodash/cloneDeep';
import { FiCheckSquare } from 'react-icons/fi';

function ExportPanelists(props) {
    //const [availableColumns_showing_ids, setAvailableColumns_showing_ids] = useState(props.availableColumns_showing_ids)
    const [exportCsvCustomColumnsIncluded, setExportCsvCustomColumnsIncluded] = useState(
        props.availableColumns_showing_ids
    );
    const [isExporting, setIsExporting] = useState(false);

    const didMount = useRef(false);
    const { addToast } = useToasts();

    useEffect(() => {}, []);

    return (
        <>
            <Box sx={{ marginBottom: '32px' }}>
                {/*JSON.stringify(exportCsvCustomColumnsIncluded)*/}
                <Box>
                    <Box>
                        <Box mb={1} fontSize={1}>
                            <Button
                                variant="link"
                                type="button"
                                className="muted"
                                mr={3}
                                onClick={e => {
                                    // remove all checkboxes here from the exclude
                                    let exportCsvCustomColumnsIncluded_update = [];
                                    const els = document.getElementsByClassName(`csv_export_custom_columns`);
                                    Array.prototype.forEach.call(els, function(el) {
                                        exportCsvCustomColumnsIncluded_update.push(
                                            parseInt(el.dataset.custom_column_id)
                                        );
                                    });
                                    setExportCsvCustomColumnsIncluded(exportCsvCustomColumnsIncluded_update);
                                    //this.setCachedCsvExportPanelColumns(exportCsvCustomColumnsIncluded);
                                }}
                            >
                                Select All
                            </Button>
                            <Button
                                variant="link"
                                type="button"
                                className="red bold"
                                onClick={e => {
                                    setExportCsvCustomColumnsIncluded([]);
                                    //this.setCachedCsvExportPanelColumns(exportCsvCustomColumnsIncluded);
                                }}
                            >
                                Deselect All
                            </Button>
                        </Box>
                        <Box>
                            {props.availableColumns.map(pc => {
                                return (
                                    <Box mb={1} style={{ display: 'block' }}>
                                        <Label className="for-checkbox ellipsis" style={{ display: 'block' }}>
                                            <input
                                                type="checkbox"
                                                className={`csv_export_custom_columns`}
                                                name="exportCsvCustomColumnsIncluded"
                                                data-custom_column_id={pc.id}
                                                style={{ margin: '4px 2px 0 0' }}
                                                checked={exportCsvCustomColumnsIncluded.includes(pc.id)}
                                                onClick={e => {
                                                    let exportCsvCustomColumnsIncluded_update = cloneDeep(
                                                        exportCsvCustomColumnsIncluded
                                                    );
                                                    if (e.target.checked) {
                                                        exportCsvCustomColumnsIncluded_update.push(pc.id);
                                                    } else {
                                                        helpers.removeArrayElement(
                                                            exportCsvCustomColumnsIncluded_update,
                                                            pc.id
                                                        );
                                                    }
                                                    //console.log(pc.id, exportCsvCustomColumnsIncluded_update)
                                                    setExportCsvCustomColumnsIncluded(
                                                        exportCsvCustomColumnsIncluded_update
                                                    );
                                                    //this.setCachedCsvExportPanelColumns(exportCsvCustomColumnsIncluded);
                                                }}
                                            />{' '}
                                            {pc.title}
                                        </Label>
                                    </Box>
                                );
                            })}
                        </Box>
                    </Box>
                </Box>
            </Box>
            <Box className="modal-actions modal-actions-sticky">
                <Button
                    type="button"
                    variant="secondary-gray"
                    className="modal-secondary"
                    mr={3}
                    onClick={() => props.onClose()}
                >
                    Cancel
                </Button>
                <Button
                    type="button"
                    variant="primary"
                    className="modal-primary"
                    mr={0}
                    disabled={isExporting}
                    onClick={() => {
                        /*if (!confirm(`You will export all  search results. Are you sure?`)) {
                            return;
                        }*/

                        // the exportCsvCustomColumnsIncluded is not ordered, so let's return
                        // an ordered one

                        //console.log(exportCsvCustomColumnsIncluded);
                        let column_ids_ordered = [];
                        const els = document.getElementsByClassName(`csv_export_custom_columns`);
                        Array.prototype.forEach.call(els, function(el) {
                            console.log(el.dataset.custom_column_id);
                            if (el && el.dataset && el.dataset.custom_column_id) {
                                const cc_id_as_integer = parseInt(el.dataset.custom_column_id);
                                if (exportCsvCustomColumnsIncluded.includes(cc_id_as_integer)) {
                                    column_ids_ordered.push(cc_id_as_integer);
                                }
                            }
                        });

                        //console.log(column_ids_ordered);
                        //return;
                        props.onSubmit(column_ids_ordered);
                        setIsExporting(true);
                    }}
                >
                    {isExporting ? 'Exporting...' : `Export ${helpers.numberFormat(props.total_results)} panelists`}
                </Button>
            </Box>
        </>
    );
}

export default ExportPanelists;
