import React, { useMemo } from 'react';
import { Box, Flex, Image, Link } from 'rebass';
import classNames from 'classnames';
import { FiArrowRight } from 'react-icons/fi';

import config from '../../config';

/**
 * Region Switch. Switches between EU and US regions.
 *
 * @param {Object} props
 * @param {String} props.className Class name for the component.
 * @param {String} props.to Path to redirect to.
 */
export function RegionSwitch({ className, to = '' }) {
    const switchConfig = useMemo(() => {
        if (config.is_eu_server)
            return {
                flag: '/eu-flag.svg',
                region: 'Europe Union',
                next: {
                    code: 'US',
                    href: `https://panelfox.io${to}`
                }
            };

        return {
            flag: '/us-flag.svg',
            region: 'United States',
            next: {
                code: 'EU',
                href: `https://eu.panelfox.io${to}`
            }
        };
    }, [to]);

    return (
        <Flex
            padding="16px"
            sx={{ borderRadius: '10px' }}
            alignItems="center"
            justifyContent="space-between"
            className={classNames('bg-info-light', className)}
        >
            <Box>
                <Box
                    as="p"
                    fontSize="12px"
                    mb="4px"
                    lineHeight="16px"
                    fontWeight="500"
                    className="color-text-secondary"
                >
                    Server Region
                </Box>
                <Flex as="p" lineHeight="20px" fontWeight="500" fontSize="14px" color="#000">
                    <Image
                        display="inline-block"
                        verticalAlign="middle"
                        mr="6px"
                        alt={switchConfig.region}
                        src={switchConfig.flag}
                    />
                    <Box>{switchConfig.region}</Box>
                </Flex>
            </Box>
            <Link fontWeight="500" href={switchConfig.next.href}>
                Switch to {switchConfig.next.code}
                <FiArrowRight css={{ marginLeft: '6px' }} />
            </Link>
        </Flex>
    );
}

export default RegionSwitch;
