import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Flex, Box, Heading, Button } from 'rebass';
import styled from 'styled-components';
import { isMobile } from 'react-device-detect';
import {
    MarketingHeader,
    MarketingFooter,
    MarketingPageSection,
    MarketingPageWrapper,
    MarketingHeadline
} from 'components/Marketing';
import NiceWrapper from 'components/NiceWrapper';
import ListWrapper from 'components/ListWrapper';
import ItemWrapperText from 'components/ItemWrapper/ItemWrapperText';
import { FiCheckCircle } from 'react-icons/fi';

function CaseStudiesPage(props) {
    return (
        <Flex flexDirection="column" style={{ margin: '0px auto', width: '100%' }}>
            <Helmet>
                <title>Home</title>
            </Helmet>
            <MarketingHeader auth={props.auth} />
            <MarketingPageWrapper isMobile={isMobile}>
                <MarketingPageSection>
                    <Heading fontSize={[5, 6, 6]} color="black" mt={4}>
                        Case Studies
                    </Heading>
                    <Box mb={3} mt={4}>
                        Panelfox is loveed by customers.
                    </Box>

                    <Heading fontSize={[4, 5, 5]} color="black" mt={4}>
                        Stuff
                    </Heading>
                    <ListWrapper className="border" mt={4}>
                        <ItemWrapperText className="header-row">
                            <Heading fontSize={[3]} color="black">
                                Stuff
                            </Heading>
                        </ItemWrapperText>
                    </ListWrapper>
                </MarketingPageSection>
            </MarketingPageWrapper>
            <MarketingPageWrapper isMobile={isMobile}>
                <MarketingFooter props={props} />
            </MarketingPageWrapper>
        </Flex>
    );
}

export default CaseStudiesPage;
