import React, { useEffect, useState } from 'react';
import ItemWrapper from 'components/ItemWrapper';
import { Input } from '@rebass/forms';
import { List, arrayMove } from 'react-movable';
import { Box, Button, Flex } from 'rebass';
import { FiMove, FiXCircle } from 'react-icons/fi';
import { cloneDeep } from 'lodash';

export const MovableOptions = ({ options, onChange, isMovable, isEditable }) => {
    const [definitions, setDefinitions] = useState(options);

    useEffect(() => {
        setDefinitions(options);
    }, [options]);

    useEffect(() => {
        onChange(definitions);
    }, [definitions]);

    const handleChange = (e, index, property) => {
        const defs = cloneDeep(definitions);
        defs[index][property] = e.target.value;
        setDefinitions(defs);
    };

    return (
        <List
            values={definitions}
            onChange={({ oldIndex, newIndex }) => {
                if (isMovable) {
                    const movedOptions = arrayMove(definitions, oldIndex, newIndex);
                    setDefinitions(movedOptions);
                }
            }}
            renderList={({ children, props }) => <div {...props}>{children}</div>}
            renderItem={({ value, index, props, isDragged }) => {
                const option = value;

                return (
                    <div {...props}>
                        <ItemWrapper style={{ color: 'black' }}>
                            <Box width={1 / 2} style={{ paddingRight: '16px' }}>
                                <Input
                                    value={option.label}
                                    onChange={e => handleChange(e, index, 'label')}
                                    disabled={!isEditable}
                                    required
                                />
                            </Box>
                            <Box width={1 / 2}>
                                <Flex>
                                    <Input
                                        value={option.value}
                                        mr={3}
                                        onChange={e => handleChange(e, index, 'value')}
                                        //disabled={!option.creating}
                                        disabled={true}
                                        required
                                    />
                                    {isMovable && (
                                        <Button
                                            data-movable-handle
                                            type="button"
                                            variant="secondary-gray"
                                            className="secondary-icon"
                                            tabIndex={-1}
                                            mr={2}
                                            style={{
                                                cursor: isDragged ? 'grabbing' : 'grab',
                                                flexShrink: 0
                                            }}
                                        >
                                            <FiMove />
                                        </Button>
                                    )}
                                    {isEditable && (
                                        <Button
                                            type="button"
                                            variant="secondary-gray"
                                            className="secondary-icon"
                                            onClick={() => {
                                                if (confirm('Are you sure?')) {
                                                    const defs = cloneDeep(definitions);
                                                    defs.splice(index, 1);
                                                    setDefinitions(defs);
                                                }
                                            }}
                                            style={{
                                                flexShrink: 0
                                            }}
                                        >
                                            <FiXCircle />
                                        </Button>
                                    )}
                                </Flex>
                            </Box>
                        </ItemWrapper>
                    </div>
                );
            }}
        />
    );
};
