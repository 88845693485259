import styled from 'styled-components';
import React from 'react';
import { Link } from 'react-router-dom';
import BreadcrumbDivider from 'components/Breadcrumb/BreadcrumbDivider';
import { Box, Button } from 'rebass';
import ReactTooltip from 'react-tooltip';
import { FiInfo } from 'react-icons/fi';

/**
 * @deprecated component is not used
 */
const AppPageBreadcrumbs = props => (
    <Box {...props} mt={2}>
        {props.items.map((item, index) => {
            return (
                <React.Fragment key={item.url}>
                    <Link
                        to={item.url || '#'}
                        className={`breadcrumb-link ellipsis ${index + 1 == props.items.length && 'black'}`}
                    >
                        {item.title}
                    </Link>
                    {item.tooltip ? (
                        <>
                            <Button
                                ml={1}
                                variant="transparent-icon-short"
                                className="transparent-icon-narrow va-top"
                                style={{ padding: '0 4px' }}
                            >
                                <FiInfo
                                    data-event="click"
                                    data-tip
                                    data-for={`breadcrumb-tooltip-${index}`}
                                    style={{ color: '#999', margin: '2px 0 0 0' }}
                                />
                            </Button>
                            <ReactTooltip
                                id={`breadcrumb-tooltip-${index}`}
                                effect="solid"
                                place="bottom"
                                style={{ zIndex: 5 }}
                            >
                                <Box style={{ whiteSpace: 'pre-wrap' }}>{item.tooltip}</Box>
                            </ReactTooltip>
                        </>
                    ) : (
                        ''
                    )}
                    {index + 1 < props.items.length ? <BreadcrumbDivider /> : ''}
                </React.Fragment>
            );
        })}
    </Box>
);

export default AppPageBreadcrumbs;
