import React, { useState, useEffect } from 'react';
import { Flex, Box, Button } from 'rebass';
import { FiChevronDown, FiCode } from 'react-icons/fi';
import NiceDropdown from 'components/NiceDropdown';

function NiceSelect(props) {
    const [valueSelected, setValueSelected] = useState(props.defaultValue);
    const [showDropdown, setShowDropdown] = useState(false);

    useEffect(() => {
        setValueSelected(props.defaultValue);
    }, [props.defaultValue]);

    let buttonStyle = {
        //textAlign: 'left'
    };

    if (props.mimicSelectStyle) {
        buttonStyle.width = '100%';
        buttonStyle.fontWeight = 400;
        buttonStyle.padding = '0 12px';
    }

    return (
        <Box sx={{ position: 'relative' }}>
            <Button
                mr={0}
                sx={buttonStyle}
                type="button"
                variant={props.variant ? props.variant : 'secondary-gray'}
                onClick={() => setShowDropdown(true)}
                className="dropdown"
            >
                <Flex justifyContent={'space-between'}>
                    <Box>
                        {props.defaultLabel ? (
                            <span className="text-secondary normal">{props.defaultLabel}: </span>
                        ) : (
                            ''
                        )}
                        {valueSelected != undefined && props.mapValueToLabel
                            ? props.mapValueToLabel[valueSelected]
                            : 'None'}
                    </Box>
                    <Box>
                        <FiChevronDown
                            style={{
                                marginRight: 0,
                                marginLeft: '4px',
                                marginTop: '4px'
                                //rotate: '90deg',
                                //fontSize: '13px'
                            }}
                        />
                    </Box>
                </Flex>
            </Button>
            {showDropdown && (
                <NiceDropdown
                    positionRight={props.positionRight ? props.positionRight : 'auto'}
                    positionLeft={props.positionLeft ? props.positionLeft : 'auto'}
                    width={props.mimicSelectStyle ? '100%' : 'auto'}
                    revealEllipsis={props.revealEllipsis ? true : false}
                    showSearch={props.showSearch ? true : false}
                    onClose={() => setShowDropdown(false)}
                    items={props.items}
                    value={valueSelected}
                    onChange={value => {
                        setValueSelected(value);
                        props.onChange(value);
                    }}
                    adjustHeight={1}
                />
            )}
        </Box>
    );
}

export default NiceSelect;
