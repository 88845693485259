import React from 'react';
import { Box } from 'rebass';

import { Card, CardBody, CardIcon, CardHeader } from 'components/Card';
import { RichIconBackground, colors } from 'components/RichIconBackground';

/**
 * Balance card. Used to display balance information (e.g. Tremendous Balance)
 *
 * @param {Object} props
 * @param {string} props.title Title of the card
 * @param {React.Component} props.icon Icon component
 * @param {keyof colors} props.iconColor Color of the icon
 * @param {React.Component} props.children Children
 */
export function BalanceCard({ title, icon, iconColor, children }) {
    return (
        <Card>
            <CardIcon icon={<RichIconBackground color={iconColor}>{icon}</RichIconBackground>} />
            <CardHeader>
                {title && (
                    <Box
                        as="p"
                        lineHeight="24px"
                        sx={{ whiteSpace: 'nowrap', overflow: 'hidden', textOverflow: 'ellipsis' }}
                        className="bold text-primary fs-16"
                    >
                        {title}
                    </Box>
                )}
            </CardHeader>
            <CardBody as="p" sx={{ fontSize: '48px', lineHeight: '56px' }} className="bold text-primary">
                {children}
            </CardBody>
        </Card>
    );
}

export default BalanceCard;
