import React, { useState, useEffect } from 'react';
import { Link, useHistory } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import { useToasts } from 'react-toast-notifications';
import H1 from 'components/H1';
import { Flex, Box, Button } from 'rebass';
import service_people from 'services/people';
import services from 'services/services';
import helpers from 'utils/helpers';
import moment from 'moment-timezone';
import { FiPieChart, FiAlertCircle, FiFileText } from 'react-icons/fi';

import AppPageWrapper from 'components/AppPageWrapper';
import AppPageSubnav from 'components/AppPageWrapper/AppPageSubnav';
import AppPageWrapperSectionHeader from 'components/AppPageWrapper/AppPageWrapperSectionHeader';
import AppPageWrapperSectionBody from 'components/AppPageWrapper/AppPageWrapperSectionBody';
import ParticipantsPageSubNav from 'containers/ParticipantsPage/ParticipantsPageSubNav';
import ItemWrapper from 'components/ItemWrapper';
import NiceModal from 'components/NiceModal';
import { ActionButton } from 'components/Button/ActionButton';
import LoadingWrapper from 'components/LoadingIndicator/LoadingWrapper';
import LoadingIndicator from 'components/LoadingIndicator';
import CreatePanel from 'components/Participant/CreatePanel';
import UpgradeButton from 'components/UpgradeButton';
import { PAID_FEATURE, FORM_TYPE_STANDARD, FORM_TYPE_CONSENT } from 'utils/constants';
import Badge from 'components/Badge';

function ParticipantsPanelsPage(props) {
    const [panels, setPanels] = useState([]);
    const [showModalCreatePanel, setShowModalCreatePanel] = useState(false);
    const { addToast } = useToasts();
    const history = useHistory();
    const hasMultiplePanelsEnabled = helpers.hasFeatureEnabled(props.auth.account, PAID_FEATURE.MULTIPLE_PANELS);

    useEffect(() => {
        getPanelsXHR();
    }, []);

    const onCreatePanel = panel => {
        addToast('Successfully created a new panel', {
            appearance: 'success',
            autoDismiss: true
        });
        history.push(`/panels/${panel.id}`);
    };

    const handleOpenModalCreatePanel = () => {
        setShowModalCreatePanel(true);
    };

    const handleCloseModalCreatePanel = () => {
        setShowModalCreatePanel(false);
    };

    const getPanelsXHR = () => {
        service_people
            .getPanels()
            .then(panels_response => {
                console.log('panels: ', panels_response);
                setPanels(panels_response);
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);
                addToast(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    };

    const renderNumForms = num_forms => {
        return (
            <>
                {num_forms ? <FiFileText className="text-purple" style={{ marginLeft: 0, marginRight: '6px' }} /> : ''}
                <span className="medium">
                    {num_forms} form{num_forms == 1 ? '' : 's'}
                </span>
            </>
        );
    };

    const renderSignupForms = panel => {
        const num_forms = panel.forms.filter(f => f.form_type == FORM_TYPE_STANDARD.id).length;
        if (!num_forms) {
            return (
                <Badge type="danger">
                    <FiAlertCircle /> Not associated
                </Badge>
            );
        } else {
            return renderNumForms(num_forms);
        }
    };

    const renderConsentForms = panel => {
        const num_forms = panel.forms.filter(f => f.form_type == FORM_TYPE_CONSENT.id).length;
        if (!num_forms) {
            return renderNumForms(0);
        } else {
            return renderNumForms(num_forms);
        }
    };

    return (
        <Flex style={{ width: '100%' }}>
            <AppPageWrapper>
                <Helmet>
                    <title>All Panels</title>
                </Helmet>

                <Flex>
                    <ParticipantsPageSubNav />
                </Flex>

                <AppPageWrapperSectionHeader className="experience">
                    <Box>
                        <H1>All Panels</H1>

                        <Box className="experience-description">
                            <Box mb={2}>
                                Build and manage multiple panels.{' '}
                                <a
                                    href="#"
                                    data-beacon-article="64a31e2091ec2e7dc66cdd68"
                                    className="help-question"
                                    style={{ display: 'inline-block', margin: 0 }}
                                >
                                    ?
                                </a>
                            </Box>
                        </Box>
                    </Box>

                    <Box mt={2}>
                        {hasMultiplePanelsEnabled ? (
                            <ActionButton title="New panel" action={handleOpenModalCreatePanel} keyLabel="N" mr={0} />
                        ) : (
                            <UpgradeButton type="multiple-panels" prefix="New panel" />
                        )}
                    </Box>
                </AppPageWrapperSectionHeader>
                <AppPageWrapperSectionBody>
                    {panels.length === 0 ? (
                        <div style={{ width: '100%', height: '50px' }}>
                            <LoadingWrapper>
                                <LoadingIndicator />
                            </LoadingWrapper>
                        </div>
                    ) : (
                        <Box my={3} width="100%">
                            <ItemWrapper className="header-row">
                                <Box width={3 / 8}>Title</Box>
                                <Box width={1 / 8}>Signup Forms</Box>
                                <Box width={1 / 8}>Consent Forms</Box>
                                <Box width={1 / 8}>Permissions</Box>
                                <Box width={1 / 8}>Created</Box>
                                <Box width={1 / 8}>Actions</Box>
                            </ItemWrapper>
                            {panels.map(panel => {
                                return (
                                    <Link to={`/panels/${panel.id}`}>
                                        <ItemWrapper key={panel.id}>
                                            <Box width={3 / 8}>
                                                <Link to={`/panels/${panel.id}`} className="muted bold">
                                                    {panel.title}
                                                </Link>
                                            </Box>
                                            <Box width={1 / 8} className="text-primary">
                                                {renderSignupForms(panel)}
                                            </Box>
                                            <Box width={1 / 8} className="text-primary">
                                                {renderConsentForms(panel)}
                                            </Box>
                                            <Box width={1 / 8} className="text-primary">
                                                {panel.rbac_restricted ? 'Restricted' : 'Open'}
                                            </Box>
                                            <Box width={1 / 8} className="text-primary">
                                                {moment.utc(panel.created_at).format('MMMM D, YYYY')}
                                            </Box>
                                            <Box width={1 / 8}>
                                                <Link css={{ fontWeight: 500 }} to={`/panels/${panel.id}/dashboard`}>
                                                    <FiPieChart css={{ marginRight: '6px' }} />
                                                    Dashboard
                                                </Link>
                                            </Box>
                                        </ItemWrapper>
                                    </Link>
                                );
                            })}
                        </Box>
                    )}
                </AppPageWrapperSectionBody>
                <NiceModal
                    isOpen={showModalCreatePanel}
                    shouldCloseOnOverlayClick
                    onRequestClose={handleCloseModalCreatePanel}
                    title={`Create new panel`}
                >
                    <CreatePanel onCreate={onCreatePanel} onClose={handleCloseModalCreatePanel} />
                </NiceModal>
            </AppPageWrapper>
        </Flex>
    );
}

export default ParticipantsPanelsPage;
