import React, { useState } from 'react';
import { FiLayers } from 'react-icons/fi';
import { Button } from 'rebass';

import AddToStudyModal from 'components/AddToStudyModal';

/**
 * The button for adding people to a study
 *
 * @param {Obejct} props
 * @param {Function?} props.onOpen A callback for when the modal opens
 * @param {Function?} props.onClose A callback for when the modal closes
 * @param {any[]} props.participantIds The ids of the participants to add to the study
 */
export function AddToStudyButton({ onOpen, onClose, participantIds }) {
    const [isModalOpen, setIsModalOpen] = useState(false);

    /**
     * Opens the modal
     */
    const onModalOpen = () => {
        setIsModalOpen(true);
        onOpen && onOpen();
    };

    /**
     * Closes the modal
     */
    const onModalClose = () => {
        setIsModalOpen(false);
        onClose && onClose();
    };

    return (
        <>
            <Button variant="secondary-gray" onClick={onModalOpen}>
                <FiLayers /> Add to study
            </Button>
            <AddToStudyModal isOpen={isModalOpen} onClose={onModalClose} participantIds={participantIds} />
        </>
    );
}

export default AddToStudyButton;
