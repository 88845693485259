import React from 'react';
import { Box } from 'rebass';
import styled from 'styled-components';

const ListWrapper = styled(Box)`
    flex: 1 1 0%;
    position: relative;
    font-size: 14px;
    &.border {
        border: 1px solid #eee;
        border-radius: 4px;
    }
`;

export default ListWrapper;
