import React from 'react';
import { Box, Button, Flex } from 'rebass';
import { Label, Input, Select, Textarea, Checkbox } from '@rebass/forms';
import xhr from 'xhr.js';
import { withToastManager } from 'react-toast-notifications';
import H1 from 'components/H1';
import styled from 'styled-components';
import config from 'config';
import auth from 'services/auth';

const CodeWrap = styled.pre`
    font-size: 12px;
    background: rgba(46, 31, 130, 0.05);
    border-radius: 4px;
    padding: 16px;
    box-shadow: rgba(10, 115, 235, 0.1) 0px 0px 0px 1px;
    position: relative;
`;

const CopyText = styled(Button)`
    position: absolute;
    top: 8px;
    right: 8px;
    margin-right: 0 !important;
`;

class StudyScript extends React.Component {
    constructor(props) {
        super(props);
        console.log(this.props.auth);
        this.copyCode = this.copyCode.bind(this);
    }

    copyCode() {
        const { toastManager } = this.props;
        const copyText = document.getElementById('code-snippet').textContent;
        const textArea = document.createElement('textarea');
        textArea.textContent = copyText;
        document.body.append(textArea);
        textArea.select();
        document.execCommand('copy');

        toastManager.add('Copied', {
            appearance: 'info',
            autoDismiss: true
        });
    }

    render() {
        return (
            <Box>
                <div style={{ fontSize: '14px' }}>
                    Paste the Benny code into the <b>{`<head>`}</b> of every page you wish to show the intercept, and
                    then verify your installation.
                </div>

                <CodeWrap>
                    <code id="code-snippet">
                        {`<script src="${config.WIDGET_URL}"></script>
<script>Benny.init({ token: '${auth.getAccount().token}' });</script>`}
                    </code>
                    <CopyText variant="secondary" onClick={this.copyCode}>
                        Copy
                    </CopyText>
                </CodeWrap>
            </Box>
        );
    }
}

export default withToastManager(StudyScript);
