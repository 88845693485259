import React, { useState } from 'react';
import { Flex, Text } from 'rebass';
import services from 'services/services';
import service_people from 'services/people';
import { useToasts } from 'react-toast-notifications';
import { FiCheckCircle } from 'react-icons/fi';

import LoginPageWrapper from 'containers/LoginPage/LoginPageWrapper';
import LoginPageHeader from 'containers/LoginPage/components/LoginPageHeader';
import LoginPageInput from 'containers/LoginPage/components/LoginPageInput';
import LoginPageButton from 'containers/LoginPage/components/LoginPageButton';
import LoginPageLink from 'containers/LoginPage/components/LoginPageLink';

const SetNewPassword = props => {
    const [loading, setLoading] = useState(false);
    const [password, setPassword] = useState('');
    const [passwordConfirmation, setPasswordConfirmation] = useState('');
    const [account, setAccount] = useState({});
    const [resetType, setResetType] = useState(false);
    const [passwordResetSuccess, setPasswordResetSuccess] = useState(false);
    const { addToast } = useToasts();

    const useResetPasswordTokenXHR = () => {
        setLoading(true);
        service_people
            .useResetPasswordToken(props.match.params.token, password, passwordConfirmation)
            .then(response => {
                setLoading(false);
                setPasswordResetSuccess(true);
                if (response.type === 'User') {
                    setResetType('User');
                } else {
                    setResetType('Person');
                    setAccount(response.account);
                }
            })
            .catch(e => {
                console.log('ERROR', e);
                setLoading(false);

                const errorText = services.parseAndTrackXhrErrors(e);
                addToast(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    };

    return (
        <LoginPageWrapper title="Reset password" noBackToHomeButton>
            <LoginPageHeader>Reset Password</LoginPageHeader>

            {!passwordResetSuccess ? (
                <>
                    <Text textAlign="center" color="#606E85" mb="24px">
                        Type in your new password, 12 characters minimum.
                    </Text>
                    <form
                        onSubmit={e => {
                            e.preventDefault();
                            e.stopPropagation();
                            useResetPasswordTokenXHR();
                        }}
                    >
                        <LoginPageInput
                            label="Password"
                            type="password"
                            name="password"
                            required
                            placeholder="12 symbols at least"
                            value={password}
                            onChange={e => setPassword(e.target.value)}
                        />
                        <LoginPageInput
                            label="Confirm Password"
                            type="password"
                            name="password_confirmation"
                            required
                            placeholder="Repeat password"
                            value={passwordConfirmation}
                            onChange={e => setPasswordConfirmation(e.target.value)}
                            mb="32px"
                        />
                        <LoginPageButton type="submit" variant="primary" mb="24px" disabled={loading}>
                            {loading ? 'Saving new password...' : 'Save new password'}
                        </LoginPageButton>
                    </form>
                </>
            ) : (
                <>
                    <Flex justifyContent="center" my={2}>
                        <FiCheckCircle color="green" fontSize="28px" />
                    </Flex>
                    <Text textAlign="center" color="#606E85" mb="24px">
                        You have successfully reset your password.
                    </Text>

                    {resetType === 'Person' && account ? (
                        <LoginPageLink to={`/panelist/${account.uuid}/login`}>Continue to Log In page</LoginPageLink>
                    ) : (
                        <LoginPageLink to="/login">Continue to Log In page</LoginPageLink>
                    )}
                </>
            )}
        </LoginPageWrapper>
    );
};

export default SetNewPassword;
