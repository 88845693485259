import React from 'react';
import { Helmet } from 'react-helmet';
import { Link } from 'react-router-dom';
import { Flex, Box, Button } from 'rebass';
import { FiArrowLeft } from 'react-icons/fi';

import config from 'config';

const LoginPageWrapper = props => (
    <Flex
        width="100%"
        height="100vh"
        backgroundColor="#ffffff"
        flexDirection="column"
        justifyContent="space-between"
        alignItems="center"
    >
        <Helmet>
            <title>{props.title}</title>
        </Helmet>
        <Box minHeight="80px" width="100%">
            {!props.noBackToHomeButton && (
                <a href={config.HOMEPAGE_URL}>
                    <Button variant="transparent" color="#001233" margin="24px 0 24px 32px" height="auto">
                        <FiArrowLeft style={{ margin: '3.3px 6px 0 0' }} /> Back to home page
                    </Button>
                </a>
            )}
        </Box>
        <Box width="80%" maxWidth="360px">
            {props.children}
        </Box>
        <Box minHeight="80px" width="100%"></Box>
    </Flex>
);

export default LoginPageWrapper;
