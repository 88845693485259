import styled from 'styled-components';
import React from 'react';
import { Link } from 'react-router-dom';
import { Flex, Box, Button } from 'rebass';
import { Label } from '@rebass/forms';
import moment from 'moment-timezone';
import { FiClock, FiDollarSign, FiUsers, FiCheckCircle, FiFileText, FiSend } from 'react-icons/fi';
import ReactTooltip from 'react-tooltip';
import helpers from 'utils/helpers';
import ItemWrapper from 'components/ItemWrapper';
import Badge from 'components/Badge';

const StyledStudyItem = styled.div`
    border-bottom: 1px solid rgb(238, 238, 238);
    //border-radius: 4px;
    padding: 16px 0;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
`;

const studyStatusBadgeType = {
    active: 'main',
    paused: 'warn',
    complete: 'success'
};

// eslint-disable-next-line
class StudyItem extends React.Component {
    /* constructor(props) {
        super(props);
    } */

    render() {
        return (
            <Link to={`/studies/${this.props.id}`}>
                <ItemWrapper style={{ paddingLeft: '32px', paddingRight: '32px' }}>
                    <Flex style={{ width: '100%', gridGap: 32 }}>
                        <Box width={7 / 20}>
                            <div className="ellipsis">
                                <Link className="muted bold" to={`/studies/${this.props.id}`}>
                                    {this.props.title}
                                </Link>
                            </div>
                            {this.props.description && (
                                <Box style={{ width: '100%' }} mt={1} className="ellipsis text-secondary fs-12">
                                    {this.props.description}
                                </Box>
                            )}

                            {this.props.tags && this.props.tags.length > 0 && (
                                <Box style={{ width: '100%' }}>
                                    {this.props.tags.map(t => (
                                        <Badge
                                            type="info"
                                            css={{ marginTop: '16px', marginRight: '8px', cursor: 'pointer' }}
                                            onClick={e => {
                                                e.preventDefault();
                                                e.stopPropagation();
                                                this.props.onTagClick(t.id);
                                            }}
                                        >
                                            {t.title}
                                        </Badge>
                                    ))}
                                </Box>
                            )}
                            {/* <Box style={{ color: '#777', fontSize: '13px', width: '100%' }} mt={2}>
                                <StudyTldr {...this.props} />
                            </Box>
                            <Box style={{ color: '#777', fontSize: '13px', width: '100%' }} mt={2}>
                                Updated{' '}
                                {moment
                                    .utc(this.props.updated_at)
                                    .tz(this.props.auth.user.timezone)
                                    .fromNow()}
                            </Box> */}
                        </Box>

                        <Box width={3 / 40}>
                            <Box>
                                <Badge
                                    type={studyStatusBadgeType[this.props.status]}
                                    css={{
                                        width: '88px',
                                        textTransform: 'capitalize',
                                        verticalAlign: 'top'
                                    }}
                                >
                                    {this.props.status}
                                </Badge>
                            </Box>
                        </Box>
                        <Box width={5 / 40} className="text-secondary-dark">
                            {moment
                                .utc(this.props.created_at)
                                .tz(this.props.auth.user.timezone)
                                .format('MMMM D, YYYY')}
                        </Box>
                        <Box width={3 / 20} className="text-secondary-dark">
                            <Flex>
                                <Box>
                                    {this.props.collaborators &&
                                        this.props.collaborators.map((c, key) => (
                                            <span>
                                                {c.user.name}
                                                {key + 1 == this.props.collaborators.length ? '' : ', '}
                                            </span>
                                        ))}
                                </Box>
                            </Flex>
                        </Box>

                        <Box width={7 / 20} className="text-primary">
                            <Flex>
                                <Box width={4 / 20}>
                                    <span data-tip data-for={`tooltip-study_people-all-${this.props.id}`}>
                                        <FiUsers /> {helpers.numberFormat(this.props.people_counts.all)}
                                    </span>
                                    <ReactTooltip
                                        id={`tooltip-study_people-all-${this.props.id}`}
                                        effect="solid"
                                        place="top"
                                    >
                                        Total participants
                                    </ReactTooltip>
                                </Box>
                                <Box width={4 / 20}>
                                    <span data-tip data-for={`tooltip-study_people-invited-${this.props.id}`}>
                                        <FiSend /> {helpers.numberFormat(this.props.people_counts.invited)}
                                    </span>

                                    <ReactTooltip
                                        id={`tooltip-study_people-invited-${this.props.id}`}
                                        effect="solid"
                                        place="top"
                                    >
                                        Invite Sent
                                    </ReactTooltip>
                                </Box>
                                <Box width={4 / 20}>
                                    <span data-tip data-for={`tooltip-study_people-answered-${this.props.id}`}>
                                        <FiFileText /> {helpers.numberFormat(this.props.people_counts.answered)}
                                    </span>

                                    <ReactTooltip
                                        id={`tooltip-study_people-answered-${this.props.id}`}
                                        effect="solid"
                                        place="top"
                                    >
                                        Screener Answered
                                    </ReactTooltip>
                                </Box>
                                <Box width={4 / 20}>
                                    <span data-tip data-for={`tooltip-study_people-scheduled-${this.props.id}`}>
                                        <FiClock /> {helpers.numberFormat(this.props.people_counts.scheduled)}
                                    </span>

                                    <ReactTooltip
                                        id={`tooltip-study_people-scheduled-${this.props.id}`}
                                        effect="solid"
                                        place="top"
                                    >
                                        Scheduled
                                    </ReactTooltip>
                                </Box>
                                <Box width={4 / 20}>
                                    <span data-tip data-for={`tooltip-study_people-paid-${this.props.id}`}>
                                        <FiDollarSign /> {helpers.numberFormat(this.props.people_counts.paid)}
                                    </span>

                                    <ReactTooltip
                                        id={`tooltip-study_people-paid-${this.props.id}`}
                                        effect="solid"
                                        place="top"
                                    >
                                        Paid
                                    </ReactTooltip>
                                </Box>
                            </Flex>
                        </Box>
                    </Flex>
                </ItemWrapper>
            </Link>
        );
    }
}

export default StudyItem;
