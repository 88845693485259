import React from 'react';
import { Button } from 'rebass';
import { useKeyPress } from '../../hooks/useKeyPress';
import { KeyboardShortcut } from 'components/Shared/KeyboardShortcut';

export const ActionButton = React.forwardRef(({ title, keyLabel, action, ...props }, ref) => {
    useKeyPress([{ keyLabel, actionCB: action }]);

    return props.disabled ? (
        <Button onClick={action} {...props} ref={ref} variant="secondary-gray" p="0 32px">
            {title}
            <KeyboardShortcut keyLabel={keyLabel} theme="secondary" />
        </Button>
    ) : (
        <Button onClick={action} ref={ref} {...props}>
            {title}
            <KeyboardShortcut keyLabel={keyLabel} theme="primary" />
        </Button>
    );
});
