import React from 'react';

class StudyTldr extends React.Component {
    constructor(props) {
        super(props);
    }

    render() {
        return (
            <span>
                This will recruit <u>{this.props.participants}</u> participant{this.props.participants > 1 ? 's' : ''}{' '}
                <u>{this.props.recurring}</u>, for {this.props.participants > 1 ? '' : 'a'} <u>{this.props.duration}</u>{' '}
                minute session{this.props.participants > 1 ? 's' : ''}, with a <u>${this.props.incentive}</u> incentive.
            </span>
        );
    }
}

export default StudyTldr;
