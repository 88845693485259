import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useToasts } from 'react-toast-notifications';
import { Flex, Box, Button } from 'rebass';
import { Select } from '@rebass/forms';
import auth from 'services/auth';
import service_accounts from 'services/accounts';
import { FiExternalLink, FiGrid } from 'react-icons/fi';
import helpers from 'utils/helpers';
import services from 'services/services';
import { EMAIL_TEMPLATE_TYPE, MAX_EMAIL_ATTACHMENTS_SIZE } from 'utils/constants';

import EditorJs from 'react-editor-js';
import Paragraph from '@editorjs/paragraph';
import Underline from '@editorjs/underline';
import Image from '@editorjs/image';
import AlignmentTuneTool from 'editorjs-text-alignment-blocktune';
import FontSize from 'editorjs-inline-font-size-tool';

import NiceModal from '../NiceModal';
import myxss from 'components/ReactFormBuilder/form-elements/myxss';

let editor_instance = null;

class CustomImage extends Image {
    renderSettings() {
        return [];
    }
}

export class LinkerTune {
    static get isTune() {
        return true;
    }

    constructor({ api, data, config, block }) {
        this.api = api;
        this.block = block;
        this.config = config;
        this.data = data || { link: '' };
    }

    wrap(blockContent) {
        this.wrapper = document.createElement('div');
        this.wrapper.setAttribute('data-link', this.data.link);
        this.wrapper.appendChild(blockContent);
        return this.wrapper;
    }

    render() {
        // init wrapper
        const wrapper = document.createElement('div');

        // create button
        const button = document.createElement('button');
        button.classList.add(this.api.styles.settingsButton);
        button.innerHTML =
            '<svg stroke="currentColor" fill="none" stroke-width="2" style="fill:none;" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M10 13a5 5 0 0 0 7.54.54l3-3a5 5 0 0 0-7.07-7.07l-1.72 1.71"></path><path d="M14 11a5 5 0 0 0-7.54-.54l-3 3a5 5 0 0 0 7.07 7.07l1.71-1.71"></path></svg>';
        button.classList.toggle(this.api.styles.settingsButtonActive, !!this.data.link);

        // add event listener
        this.api.listeners.on(button, 'click', () => {
            const link = prompt('Please enter link', this.data.link);
            this.data.link = link === null ? this.data.link : link;
            button.classList.toggle(this.api.styles.settingsButtonActive, !!this.data.link);
            this.wrapper.setAttribute('data-link', this.data.link);
        });

        // append button to wrapper
        wrapper.appendChild(button);

        return wrapper;
    }

    save() {
        return this.data;
    }
}

class TextColor {
    static get isInline() {
        return true;
    }

    static get title() {
        return 'Text color';
    }

    get state() {
        return this._state;
    }

    static get sanitize() {
        return {
            span: true
        };
    }

    set state(state) {
        this._state = state;

        this.button.classList.toggle(this.api.styles.inlineToolButtonActive, state);
    }

    constructor({ api }) {
        this.api = api;

        this.button = null;
        this._state = false;

        this.tag = 'SPAN';
        this.class = 'cdx-text-color';
        this.defaultColor = '#f50400';
    }

    render() {
        this.button = document.createElement('button');

        this.button.type = 'button';
        this.button.innerHTML =
            '<svg xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" viewBox="0 0 24 24" style=" fill:currentColor;" height="16" width="16">    <path d="M 12 2 C 11.398 2 10.859437 2.3725469 10.648438 2.9355469 L 5.4746094 16.734375 C 5.2456094 17.346375 5.6975625 18 6.3515625 18 C 6.7415625 18 7.0915156 17.758578 7.2285156 17.392578 L 8.875 13 L 15.125 13 L 16.771484 17.392578 C 16.908484 17.757578 17.258437 18 17.648438 18 C 18.302437 18 18.754391 17.346375 18.525391 16.734375 L 13.351562 2.9355469 C 13.140562 2.3725469 12.602 2 12 2 z M 12 4.6679688 L 14.375 11 L 9.625 11 L 12 4.6679688 z M 5.9355469 20 C 5.4185469 20 5 20.4195 5 20.9375 L 5 21.064453 C 5 21.581453 5.4185469 22 5.9355469 22 L 18.064453 22 C 18.581453 22 19 21.5805 19 21.0625 L 19 20.935547 C 19 20.418547 18.5805 20 18.0625 20 L 5.9355469 20 z"></path></svg>';
        this.button.classList.add(this.api.styles.inlineToolButton);

        return this.button;
    }

    surround(range) {
        if (this.state) {
            this.unwrap(range);

            return;
        }

        this.wrap(range);
    }

    wrap(range) {
        const selectedText = range.extractContents();
        const span = document.createElement(this.tag);

        span.classList.add(this.class);
        span.appendChild(selectedText);
        range.insertNode(span);

        this.api.selection.expandToTag(span);
    }

    unwrap(range) {
        const span = this.api.selection.findParentTag(this.tag, this.class);
        const text = range.extractContents();

        span.remove();
        range.insertNode(text);
    }

    renderActions() {
        this.colorPicker = document.createElement('input');

        this.colorPicker.type = 'color';
        this.colorPicker.value = this.defaultColor;
        this.colorPicker.hidden = true;

        return this.colorPicker;
    }

    showActions(span) {
        const { backgroundColor } = span.style;

        this.colorPicker.value = backgroundColor ? this.convertToHex(backgroundColor) : this.defaultColor;

        this.colorPicker.onchange = () => {
            span.style.color = this.colorPicker.value;
        };

        this.colorPicker.hidden = false;
    }

    hideActions() {
        this.colorPicker.onchange = null;
        this.colorPicker.hidden = true;
    }

    convertToHex(color) {
        const rgb = color.match(/(\d+)/g);

        let hexr = parseInt(rgb[0]).toString(16);
        let hexg = parseInt(rgb[1]).toString(16);
        let hexb = parseInt(rgb[2]).toString(16);

        hexr = hexr.length === 1 ? '0' + hexr : hexr;
        hexg = hexg.length === 1 ? '0' + hexg : hexg;
        hexb = hexb.length === 1 ? '0' + hexb : hexb;

        return '#' + hexr + hexg + hexb;
    }

    checkState(selection) {
        const span = this.api.selection.findParentTag(this.tag);

        this.state = !!span;

        if (this.state) {
            this.showActions(span);
        } else {
            this.hideActions();
        }
    }
}

class ScreenerButton {
    static get toolbox() {
        return {
            title: 'Screener Button',
            icon: '<span class="text">Screener Button</span>'
            // icon: '<svg width="17" height="15" stroke="#707684" style="fill:white" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" xmlns="http://www.w3.org/2000/svg"><path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path><polyline points="14 2 14 8 20 8"></polyline><line x1="16" y1="13" x2="8" y2="13"></line><line x1="16" y1="17" x2="8" y2="17"></line><polyline points="10 9 9 9 8 9"></polyline></svg>'
            // icon: '<svg width="17" height="15" viewBox="0 0 336 276" xmlns="http://www.w3.org/2000/svg"><path d="M291 150V79c0-19-15-34-34-34H79c-19 0-34 15-34 34v42l67-44 81 72 56-29 42 30zm0 52l-43-30-56 30-81-67-66 39v23c0 19 15 34 34 34h178c17 0 31-13 34-29zM79 0h178c44 0 79 35 79 79v118c0 44-35 79-79 79H79c-44 0-79-35-79-79V79C0 35 35 0 79 0z"/></svg>'
        };
    }

    constructor({ data, config }) {
        this.data = data && data.buttonText ? data : { buttonText: 'Take Survey' };
        this.wrapper = undefined;
        this.config = config || {};
    }

    save(blockContent) {
        const button = blockContent.querySelector('[contenteditable]');

        return {
            buttonText: button.innerHTML
        };
    }

    render() {
        this.wrapper = document.createElement('div');
        this.wrapper.classList.add('simple-image');

        if (this.data && this.data.buttonText) {
            this.renderbutton(this.data.buttonText);
            return this.wrapper;
        }

        const input = document.createElement('input');
        input.classList.add('buttonText');
        input.placeholder = 'Paste an image buttonText...';
        input.addEventListener('paste', event => {
            this.renderbutton(event.clipboardData.getData('text'));
        });

        this.wrapper.appendChild(input);

        return this.wrapper;
    }

    renderbutton(buttonText) {
        const button = document.createElement('div');
        button.classList.add('theme-button-primary');
        if (this.config.buttonColor) {
            button.style.background = this.config.buttonColor;
        }
        button.innerHTML = buttonText || 'Take Survey';
        button.contentEditable = true;

        this.wrapper.innerHTML = '';
        this.wrapper.appendChild(button);
    }
}
class DscoutMissionButton {
    static get toolbox() {
        return {
            title: 'Dscout Mission Button',
            icon: '<span class="text">Dscout Mission Button</span>'
            // icon: '<svg width="17" height="15" stroke="#707684" style="fill:white" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" xmlns="http://www.w3.org/2000/svg"><path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path><polyline points="14 2 14 8 20 8"></polyline><line x1="16" y1="13" x2="8" y2="13"></line><line x1="16" y1="17" x2="8" y2="17"></line><polyline points="10 9 9 9 8 9"></polyline></svg>'
            // icon: '<svg width="17" height="15" viewBox="0 0 336 276" xmlns="http://www.w3.org/2000/svg"><path d="M291 150V79c0-19-15-34-34-34H79c-19 0-34 15-34 34v42l67-44 81 72 56-29 42 30zm0 52l-43-30-56 30-81-67-66 39v23c0 19 15 34 34 34h178c17 0 31-13 34-29zM79 0h178c44 0 79 35 79 79v118c0 44-35 79-79 79H79c-44 0-79-35-79-79V79C0 35 35 0 79 0z"/></svg>'
        };
    }

    constructor({ data, config }) {
        this.data = data && data.buttonText ? data : { buttonText: 'Go to activity' };
        this.wrapper = undefined;
        this.config = config || {};
    }

    save(blockContent) {
        const button = blockContent.querySelector('[contenteditable]');

        return {
            buttonText: button.innerHTML
        };
    }

    render() {
        this.wrapper = document.createElement('div');
        this.wrapper.classList.add('simple-image');

        if (this.data && this.data.buttonText) {
            this.renderbutton(this.data.buttonText);
            return this.wrapper;
        }

        const input = document.createElement('input');
        input.classList.add('buttonText');
        input.placeholder = 'Paste an image buttonText...';
        input.addEventListener('paste', event => {
            this.renderbutton(event.clipboardData.getData('text'));
        });

        this.wrapper.appendChild(input);

        return this.wrapper;
    }

    renderbutton(buttonText) {
        const button = document.createElement('div');
        button.classList.add('theme-button-primary');
        //button.classList.add('theme-button-dscout-integration');
        if (this.config.buttonColor) {
            button.style.background = this.config.buttonColor;
        }
        button.innerHTML = buttonText || 'Go to activity';
        button.contentEditable = true;

        this.wrapper.innerHTML = '';
        this.wrapper.appendChild(button);
    }
}

class SessionConfirmationButton {
    static get toolbox() {
        return {
            title: 'Session Confirmation Button',
            icon: '<span class="text">Session Confirmation Button</span>'
        };
    }

    constructor({ data, config }) {
        this.data = data && data.buttonText ? data : { buttonText: 'Confirm session' };
        this.wrapper = undefined;
        this.config = config || {};
    }

    save(blockContent) {
        const button = blockContent.querySelector('[contenteditable]');

        return {
            buttonText: button.innerHTML
        };
    }

    render() {
        this.wrapper = document.createElement('div');
        this.wrapper.classList.add('simple-image');

        if (this.data && this.data.buttonText) {
            this.renderbutton(this.data.buttonText);
            return this.wrapper;
        }

        const input = document.createElement('input');
        input.classList.add('buttonText');
        input.placeholder = 'Paste an image buttonText...';
        input.addEventListener('paste', event => {
            this.renderbutton(event.clipboardData.getData('text'));
        });

        this.wrapper.appendChild(input);

        return this.wrapper;
    }

    renderbutton(buttonText) {
        const button = document.createElement('div');
        button.classList.add('theme-button-primary');
        if (this.config.buttonColor) {
            button.style.background = this.config.buttonColor;
        }
        button.innerHTML = buttonText || 'Confirm Session';
        button.contentEditable = true;

        this.wrapper.innerHTML = '';
        this.wrapper.appendChild(button);
    }
}

class SchedulingButton {
    static get toolbox() {
        return {
            title: 'Scheduling Button',
            icon: '<span class="text">Scheduling Button</span>'
            // icon: '<svg width="17" height="15" stroke="#707684" style="fill:white" stroke-width="2" viewBox="0 0 24 24" stroke-linecap="round" stroke-linejoin="round" xmlns="http://www.w3.org/2000/svg"><path d="M14 2H6a2 2 0 0 0-2 2v16a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V8z"></path><polyline points="14 2 14 8 20 8"></polyline><line x1="16" y1="13" x2="8" y2="13"></line><line x1="16" y1="17" x2="8" y2="17"></line><polyline points="10 9 9 9 8 9"></polyline></svg>'
            // icon: '<svg width="17" height="15" viewBox="0 0 336 276" xmlns="http://www.w3.org/2000/svg"><path d="M291 150V79c0-19-15-34-34-34H79c-19 0-34 15-34 34v42l67-44 81 72 56-29 42 30zm0 52l-43-30-56 30-81-67-66 39v23c0 19 15 34 34 34h178c17 0 31-13 34-29zM79 0h178c44 0 79 35 79 79v118c0 44-35 79-79 79H79c-44 0-79-35-79-79V79C0 35 35 0 79 0z"/></svg>'
        };
    }

    constructor({ data, config }) {
        this.data = data && data.buttonText ? data : { buttonText: 'Find a Time' };
        this.wrapper = undefined;
        this.config = config || {};
    }

    save(blockContent) {
        const button = blockContent.querySelector('[contenteditable]');

        return {
            buttonText: button.innerHTML
        };
    }

    render() {
        this.wrapper = document.createElement('div');
        this.wrapper.classList.add('simple-image');

        if (this.data && this.data.buttonText) {
            this.renderbutton(this.data.buttonText);
            return this.wrapper;
        }

        const input = document.createElement('input');
        input.classList.add('buttonText');
        input.placeholder = 'Paste an image buttonText...';
        input.addEventListener('paste', event => {
            this.renderbutton(event.clipboardData.getData('text'));
        });

        this.wrapper.appendChild(input);

        return this.wrapper;
    }

    renderbutton(buttonText) {
        const button = document.createElement('div');
        button.classList.add('theme-button-primary');
        if (this.config.buttonColor) {
            button.style.background = this.config.buttonColor;
        }
        button.innerHTML = buttonText || 'Find a Time';
        button.contentEditable = true;

        this.wrapper.innerHTML = '';
        this.wrapper.appendChild(button);
    }
}

class SessionSpot {
    static get toolbox() {
        return {
            title: 'Session Spot',
            icon: '<span class="text">Session Spot</span>'
        };
    }

    constructor({ data, config }) {
        this.wrapper = undefined;
        this.config = config || {};
    }

    save(blockContent) {
        return {};
    }

    render() {
        this.wrapper = document.createElement('div');
        this.wrapper.innerHTML = 'Will be replaced with session date & time';
        this.wrapper.style.background = '#eee';
        this.wrapper.style.border = '1px solid #ddd';
        this.wrapper.style.borderRadius = '4px';
        this.wrapper.style.padding = '0 2px';
        this.wrapper.style.fontStyle = 'italic';
        return this.wrapper;
    }

    renderbutton() {}
}

class SessionLocation {
    static get toolbox() {
        return {
            title: 'Session Location',
            icon: '<span class="text">Session Location</span>'
        };
    }

    constructor({ data, config }) {
        this.wrapper = undefined;
        this.config = config || {};
    }

    save(blockContent) {
        return {};
    }

    render() {
        this.wrapper = document.createElement('div');
        this.wrapper.innerHTML = 'Will be replaced with session location';
        this.wrapper.style.background = '#eee';
        this.wrapper.style.border = '1px solid #ddd';
        this.wrapper.style.borderRadius = '4px';
        this.wrapper.style.padding = '0 2px';
        this.wrapper.style.fontStyle = 'italic';
        return this.wrapper;
    }

    renderbutton() {}
}

/**
 * EmailEditor.
 *
 * @param {Object} props
 * @param {EMAIL_TEMPLATE_TYPE} props.type The type of the email template
 * @param {Object|string} props.editorData The data to be rendered in the editor
 * @param {(editorData: any, editorType: EMAIL_TEMPLATE_TYPE) => void} props.onChange The callback function to be called when the editor data changes
 * @param {Boolean} props.allowScreenerButton Whether to allow the screener button
 * @param {Boolean} props.allowDscoutButton Whether to allow the dscout button
 * @param {(emailTemplate: { body: any; subject: string; type: number; id: number }) => void} props.onTemplate The callback function to be called when a template is selected
 */
export default function EmailEditor({ type = EMAIL_TEMPLATE_TYPE.BLOCKS, onTemplate, ...props }) {
    const account = auth.getAccount();
    const primaryColor = account.branding && account.branding.primary ? account.branding.primary : null;
    const { addToast } = useToasts();

    const [showModalEmailTemplates, setShowModalEmailTemplates] = useState(false);
    const [emailTemplatesAndLatestEmails, setEmailTemplatesAndLatestEmails] = useState({});
    const [emailTemplatesAndLatestEmails_loading, setEmailTemplatesAndLatestEmails_loading] = useState(true);
    const isBlocksTemplate = type === EMAIL_TEMPLATE_TYPE.BLOCKS;

    const editorTools = {
        alignment: {
            class: AlignmentTuneTool,
            config: {
                default: 'left'
            }
        },
        linker: LinkerTune
    };

    //editorTools['fontsize'] = FontSize;
    editorTools['underline'] = Underline;
    editorTools['textcolor'] = TextColor;
    editorTools['paragraph'] = {
        class: Paragraph,
        inlineToolbar: ['bold', 'italic', 'underline', 'textcolor', 'link'],
        config: {
            placeholder: 'Start typing...',
            preserveBlank: true
        }
    };

    editorTools['screenerButton'] = {
        class: ScreenerButton,
        config: {
            buttonColor: primaryColor,
            isAllowed: props.allowScreenerButton
        }
    };

    editorTools['dscoutMissionButton'] = {
        class: DscoutMissionButton,
        config: {
            buttonColor: primaryColor,
            isAllowed: props.allowDscoutButton
        }
    };

    editorTools['schedulingButton'] = {
        class: SchedulingButton,
        config: {
            buttonColor: primaryColor
        }
    };
    editorTools['sessionSpot'] = {
        class: SessionSpot,
        config: {}
    };
    editorTools['sessionLocation'] = {
        class: SessionLocation,
        config: {}
    };
    editorTools['sessionConfirmationButton'] = {
        class: SessionConfirmationButton,
        config: {
            buttonColor: primaryColor
        }
    };

    editorTools['image'] = {
        class: CustomImage,
        tunes: ['alignment', 'linker'],
        inlineToolbar: ['link'],
        config: {
            types: 'image/*',
            uploader: {
                /**
                 * @param {File} attachment
                 */
                uploadByFile: async attachment => {
                    const formData = new FormData();

                    // check if the file is too big
                    if (attachment.size > MAX_EMAIL_ATTACHMENTS_SIZE) {
                        return {
                            success: 0
                        };
                    }

                    // attach the file
                    formData.append('attachment', attachment);

                    // api call
                    try {
                        const response = await services.uploadEmailAttachment(formData);
                        const url = response.filepath;

                        return {
                            success: 1,
                            file: {
                                url
                            }
                        };
                    } catch (error) {
                        return {
                            success: 0
                        };
                    }
                }
            }
        }
    };

    function getEmailTemplatesAndLatestEmailsXHR() {
        service_accounts.getEmailTemplatesAndLatestEmails().then(xhr_response => {
            setEmailTemplatesAndLatestEmails_loading(false);
            setEmailTemplatesAndLatestEmails(xhr_response);
        });
    }

    function onUseTemplate(e) {
        e.preventDefault();

        const email_template_found = document.getElementById('selected_email_template_id').value;

        if (email_template_found) {
            let et_found = null;
            // the type and the id
            const parts = email_template_found.split('-');
            const email_template_type = parts[0];
            const email_template_id = parts[1];

            //console.log(email_template_type, email_template_id);

            if (email_template_type == 'template') {
                et_found = emailTemplatesAndLatestEmails.templates.find(et => et.id == email_template_id);
            } else if (email_template_type == 'latest_email') {
                et_found = emailTemplatesAndLatestEmails.latest_emails.find(et => et.id == email_template_id);
            }

            if (et_found) {
                let editorData = null;
                if (et_found.type === EMAIL_TEMPLATE_TYPE.HTML) {
                    editorData = helpers.isObject(et_found.body) ? JSON.stringify(et_found.body) : et_found.body;
                } else {
                    editorData = helpers.isObject(et_found.body)
                        ? et_found.body
                        : helpers.makeBlocksFromText(et_found.body);
                }

                // Update the editor instance if it exists
                if (editor_instance && typeof editor_instance.render === 'function') {
                    editor_instance.render(editorData);
                }

                // fire the on template callback
                onTemplate &&
                    onTemplate({
                        body: et_found.body,
                        subject: et_found.subject,
                        type: et_found.type,
                        id: et_found.id
                    });

                addToast('Template imported', {
                    appearance: 'success',
                    autoDismiss: true
                });
                setShowModalEmailTemplates(false);
            }
        } else {
            alert('Email template not selected');
        }
    }

    const linkStyle = {};
    if (primaryColor) {
        linkStyle.color = primaryColor;
    }

    return (
        <>
            <Flex fontSize={1} mb={2} alignItems="center">
                <Box className="form-label" p={0}>
                    Email Body
                </Box>
                <Box height="12px" width="1px" mt="1px" mx="16px" backgroundColor="#D4D9DD"></Box>
                <Button
                    variant="link"
                    onClick={() => {
                        getEmailTemplatesAndLatestEmailsXHR();
                        setShowModalEmailTemplates(true);
                    }}
                >
                    <FiGrid style={{ margin: '4px 8px 0 0' }} />
                    View Email Templates
                </Button>
            </Flex>
            <Box
                sx={{
                    a: linkStyle,
                    background: '#fbfafc',
                    borderRadius: '4px',
                    padding: '32px',
                    boxShadow: 'rgba(36, 18, 77, 0.08) 0px 0px 0px 1px'
                }}
            >
                {account.logo && isBlocksTemplate && (
                    <div style={{ textAlign: 'center', margin: '0 0 32px 0' }}>
                        <img src={account.logo} style={{ height: '80px' }} alt="Account Logo" />
                    </div>
                )}
                <Box style={{ maxWidth: isBlocksTemplate ? '600px' : null, margin: '0 auto' }} fontSize={1}>
                    {isBlocksTemplate ? (
                        <EditorJs
                            minHeight="0"
                            data={props.editorData}
                            tools={editorTools}
                            onChange={(_, editorData) => {
                                editorData.blocks.forEach(block => {
                                    if (block.type == 'paragraph') {
                                        block.data.text = myxss.process(block.data.text);
                                    }
                                });
                                props.onChange(editorData, type);
                            }}
                            onReady={() => {
                                const elems = document.querySelectorAll('.codex-editor--narrow');

                                [].forEach.call(elems, function(el) {
                                    el.classList.remove('codex-editor--narrow');
                                });
                            }}
                            instanceRef={instance => (editor_instance = instance)}
                        />
                    ) : (
                        <Flex sx={{ gap: '32px' }}>
                            <Box
                                as="textarea"
                                placeholder="Type your email template here..."
                                // ref={htmlEditor}
                                sx={{
                                    background: 'transparent',
                                    border: 'none',
                                    flex: 1,
                                    resize: 'none',
                                    minHeight: '600px',
                                    height: '600px',
                                    overflowY: 'scroll',
                                    '&:focus': {
                                        border: 'none'
                                    }
                                }}
                                onChange={event => {
                                    props.onChange(helpers.sanitizeHtmlTemplate(event.target.value), type);
                                }}
                            >
                                {props.editorData}
                            </Box>
                            {props.editorData && (
                                <Box
                                    as="iframe"
                                    style={{ flex: 1, minHeight: '600px', border: 'none' }}
                                    title="template preview"
                                    sandbox="allow-same-origin"
                                    srcDoc={props.editorData}
                                />
                            )}
                            {!props.editorData && (
                                <Flex
                                    p={4}
                                    alignItems="center"
                                    justifyContent="center"
                                    sx={{ flex: 1, minHeight: '600px', border: '2px solid #f1f1f1' }}
                                >
                                    <Box as="p">Once HTML is added this will be a template preview.</Box>
                                </Flex>
                            )}
                        </Flex>
                    )}
                </Box>
                <div
                    style={{
                        textAlign: 'center',
                        margin: '0 auto',
                        marginTop: '32px',
                        color: '#aaa',
                        fontSize: '13px',
                        maxWidth: '600px'
                    }}
                >
                    {account.email_footer && <div style={{ whiteSpace: 'pre-wrap' }}>{account.email_footer}</div>}
                    <Box mt={3} style={{ color: '#777' }}>
                        <u>Opt out</u> from future emails.
                    </Box>
                </div>
            </Box>
            <NiceModal
                isOpen={showModalEmailTemplates}
                shouldCloseOnOverlayClick
                onRequestClose={() => setShowModalEmailTemplates(false)}
                title="Select a template"
                style={{ content: { width: '560px' } }}
            >
                <form onSubmit={onUseTemplate}>
                    <Box className="form-wrapper2" width="100%">
                        {emailTemplatesAndLatestEmails_loading ? (
                            <>Loading...</>
                        ) : (
                            <Box width="100%" mb="8px">
                                {emailTemplatesAndLatestEmails.templates ? (
                                    <Select id="selected_email_template_id" mt={'1px'}>
                                        {emailTemplatesAndLatestEmails.templates.length > 0 ? (
                                            <option disabled>- - - Email Templates - - -</option>
                                        ) : (
                                            <></>
                                        )}
                                        {emailTemplatesAndLatestEmails.templates.map(et => {
                                            return <option value={`template-${et.id}`}>{et.title}</option>;
                                        })}

                                        {emailTemplatesAndLatestEmails.latest_emails.length > 0 ? (
                                            <option disabled>- - - Recently Sent Emails - - -</option>
                                        ) : (
                                            <></>
                                        )}
                                        {emailTemplatesAndLatestEmails.latest_emails.map(et => {
                                            return <option value={`latest_email-${et.id}`}>{et.title}</option>;
                                        })}
                                    </Select>
                                ) : (
                                    <>
                                        0 templates, <Link to={`/settings/email-templates`}>create your first</Link>
                                    </>
                                )}
                            </Box>
                        )}
                    </Box>

                    <Box className="modal-actions">
                        <Flex sx={{ width: '100%' }} justifyContent="space-between">
                            <Box>
                                <Link to={`/settings/email-templates`} target="_blank">
                                    <Button type="button" variant="link">
                                        <FiExternalLink style={{ fontSize: '18px', margin: '2px 4px 0 0' }} /> Go to
                                        templates
                                    </Button>
                                </Link>
                            </Box>
                            <Box style={{ textAlign: 'right' }}>
                                <Button
                                    type="button"
                                    variant="secondary-gray"
                                    mr={3}
                                    onClick={() => setShowModalEmailTemplates(false)}
                                >
                                    Cancel
                                </Button>

                                <Button type="submit" variant="primary" mr={0}>
                                    Use Template
                                </Button>
                            </Box>
                        </Flex>
                    </Box>
                </form>
            </NiceModal>
        </>
    );
}
