import React, { useRef, useState, useEffect } from 'react';
import { Box } from 'rebass';

import { useWindowPositioner } from 'hooks/useWindowPositioner';

/**
 * Component to position a relative to a controller element.
 *
 * @param {Object} props
 * @param {Object} props.controllerRef Reference to the controller element
 * @param {ReactNode} props.children Content to be displayed
 * @param {Boolean} props.isVisible Flag to show or hide the content
 */
export function Positioner({ controllerRef, children, isVisible }) {
    const targetRef = useRef(null);
    const [coordinates, setCoordinates] = useState({ top: 0, left: 0 });
    const position = useWindowPositioner();

    useEffect(() => {
        setCoordinates(position(controllerRef, targetRef));
    }, [isVisible]);

    return (
        <Box
            ref={targetRef}
            style={{
                ...coordinates,
                position: 'absolute',
                visibility: isVisible ? 'visible' : 'hidden',
                height: window.innerHeight - coordinates.top - 32
            }}
        >
            {children}
        </Box>
    );
}

export default Positioner;
