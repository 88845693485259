import styled from 'styled-components';

const H3 = styled.h3`
    font-size: 0.8em;
    margin-bottom: 8px;
    margin-top: 0;
    font-weight: 500;
    height: 24px;
    color: #999;
`;

export default H3;
