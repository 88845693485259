import React from 'react';
import { Helmet } from 'react-helmet';
import xhr from 'xhr.js';
import { withToastManager } from 'react-toast-notifications';
import services from 'services/services';

import BreadcrumbDivider from 'components/Breadcrumb/BreadcrumbDivider';
import { Link } from 'react-router-dom';
import H1 from 'components/H1';
import H2 from 'components/H2';
import SideNav from 'components/SideNav';
import ShowMoreWrapper from 'components/ShowMoreWrapper';
import { Flex, Box, Button } from 'rebass';
import { Label } from '@rebass/forms';
import LoadingIndicator from 'components/LoadingIndicator';
import LoadingWrapper from 'components/LoadingIndicator/LoadingWrapper';
import ParticipantSettings from 'components/Participant/ParticipantSettings';
import ParticipantTags from 'components/Participant/ParticipantTags';
import PastParticipation from 'components/Participant/PastParticipation';
import helpersStudy from 'utils/helpers-study';
import NiceModal from 'components/NiceModal';
import CreatePanel from 'components/Participant/CreatePanel';
import PanelDropdown from 'components/PanelDropdown';
import { ReactFormGenerator } from 'components/ReactFormBuilder';
import BreadcrumbBack from 'components/BreadcrumbBack';
import Badge from 'components/Badge';
import PointsHistory from 'components/PointsHistory';

import {
    FiDollarSign,
    FiPlus,
    FiXCircle,
    FiTag,
    FiTrash,
    FiExternalLink,
    FiSettings,
    FiLayers,
    FiClipboard,
    FiPenTool,
    FiGift,
    FiChevronDown
} from 'react-icons/fi';

import ToastSuccess from './components/ToastSuccess';
import AppPageWrapper from 'components/AppPageWrapper';
import AppPageWrapperSectionHeader from 'components/AppPageWrapper/AppPageWrapperSectionHeader';
import AppPageWrapperSectionBody from 'components/AppPageWrapper/AppPageWrapperSectionBody';
import SelectModal from 'components/SelectModal';
import helpers from 'utils/helpers';
import service_people from 'services/people';
import { COL_FIRST_NAME, COL_LAST_NAME, COL_EMAIL, COL_PHONE_NUMBER, COL_OPT_IN } from 'utils/constants';
import { AttachedFilesSection } from 'components/AttachedFilesSection';
import { IncentiveProgress } from 'components/IncentiveProgress';
import { FatigueStatusNotification } from 'components/FatigueStatusNotification';

import {
    Editor,
    EditorState,
    RichUtils,
    convertToRaw,
    convertFromRaw,
    getVisibleSelectionRect,
    Modifier,
    CompositeDecorator,
    SelectionState
} from 'draft-js';
import { convertFromHTML } from 'draft-convert';
import 'draft-js/dist/Draft.css';
import 'styles/draftjs-rich.css';

import moment from 'moment-timezone';
import EmailIssueNotification from 'components/EmailIssueNotification';
import OptOutBadge from 'components/OptOutBadge';
import AddToStudyButton from 'components/AddToStudyButton';

class ParticipantPage extends React.Component {
    constructor(props) {
        super(props);

        this.participantId = this.props.match.params.id;
        this.state = {
            loading: true,
            participant: {},
            panels: [],
            showSelectPanelDropdown: false,
            showModalCreatePanel: false,
            tags: [],
            tagSuggestions: [],
            showModalAddTag: false,
            panelSignupAnswers: [],
            pointsData: {}
        };

        this.processDeleteData = this.processDeleteData.bind(this);
        this.getPersonXHR = this.getPersonXHR.bind(this);
        this.onDeleteFromPanel = this.onDeleteFromPanel.bind(this);
        this.handleOpenSelectPanelDropdown = this.handleOpenSelectPanelDropdown.bind(this);
        this.handleCloseSelectPanelDropdown = this.handleCloseSelectPanelDropdown.bind(this);
        this.onAddToPanel = this.onAddToPanel.bind(this);
        this.onClickSelectPanelDropdownCreatePanel = this.onClickSelectPanelDropdownCreatePanel.bind(this);
        this.onCreatePanel = this.onCreatePanel.bind(this);
        this.handleOpenModalCreatePanel = this.handleOpenModalCreatePanel.bind(this);
        this.handleCloseModalCreatePanel = this.handleCloseModalCreatePanel.bind(this);

        this.onTagAdd = this.onTagAdd.bind(this);
        this.onTagCreate = this.onTagCreate.bind(this);
        this.onTagDelete = this.onTagDelete.bind(this);
        this.handleOpenModalAddTag = this.handleOpenModalAddTag.bind(this);

        this.propertiesRef = React.createRef();
        this.eligibilityRef = React.createRef();
        this.panelsRef = React.createRef();
        this.pointsRef = React.createRef();
        this.tagsRef = React.createRef();
        this.deletePanelistRef = React.createRef();
        this.studiesRef = React.createRef();
        this.attachedFilesRef = React.createRef();
        this.signUpFormAnswersRef = React.createRef();
    }

    /**
     * Get person points from the server
     */
    async getPersonPointsXHR(personId) {
        const { toastManager } = this.props;
        try {
            const pointsData = await services.getPersonPoints(personId);
            this.setState({ pointsData });
        } catch (error) {
            const errorText = services.parseAndTrackXhrErrors(error);
            toastManager.add(errorText, {
                appearance: 'error',
                autoDismiss: true
            });
        }
    }

    getPersonXHR() {
        const { toastManager } = this.props;

        services
            .getPerson(this.props.match.params.id)
            .then(person => {
                const participant = person;

                this.setState({
                    participant,
                    loading: false
                });

                // return early if not found
                if (!person || !person.id) {
                    return;
                }

                this.getPanelsXHR(person);

                // get points if enabled
                if (this.props.auth.account.settings.pv_points === 1) this.getPersonPointsXHR(person.id);

                const tags = [];

                // parse tags
                participant.tags.forEach(tag => {
                    tags.push({
                        id: tag.tag.id,
                        title: tag.tag.title
                    });
                });

                this.getAllPanelSignupAnswers();

                const that = this;

                this.setState({ tags }, () => {
                    // TODO: refactor this request to be made only when add tag button is clicked
                    services.getPersonTags(this.props.auth.account.id, (err, response) => {
                        if (err) {
                            alert('There was an error please try again.');
                        } else {
                            const suggestions = [];

                            response.forEach(tag => {
                                suggestions.push({
                                    id: tag.id,
                                    title: tag.title
                                });
                            });

                            const tagIds = tags.map(t => t.id);

                            // suggestions should not include tags already applied to the participant
                            // here, we filter them out
                            that.setState({
                                tagSuggestions: suggestions.filter(t => !tagIds.includes(t.id))
                            });
                        }
                    });
                });
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);
                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
                if (errorText.includes('Your user account is unauthorized to view this person')) {
                    this.props.history.push('/panel');
                }
            });
    }

    getPanelsXHR(person) {
        const { toastManager } = this.props;

        service_people
            .getPanels()
            .then(panels_response => {
                if (!person.panels) {
                    this.setState({
                        panels: panels_response
                    });
                } else {
                    const person_panel_ids_set = new Set(person.panels.map(panel => panel.id));
                    const panels = panels_response.filter(panel => !person_panel_ids_set.has(panel.id));
                    this.setState({ panels });
                }
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);
                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    componentDidMount() {
        this.getPersonXHR();
    }

    onDeleteFromPanel(panel_id) {
        const { toastManager } = this.props;

        service_people
            .removePanelistFromPanel(this.state.participant.id, panel_id)
            .then(res => {
                this.getPersonXHR();
                toastManager.add('Panelist successfully removed from panel', {
                    appearance: 'success',
                    autoDismiss: true
                });
            })
            .catch(err => {
                const errorText = services.parseAndTrackXhrErrors(err);
                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    handleOpenSelectPanelDropdown() {
        this.setState({ showSelectPanelDropdown: true });
    }

    handleCloseSelectPanelDropdown() {
        this.setState({ showSelectPanelDropdown: false });
    }

    onAddToPanel(panel_id) {
        const { toastManager } = this.props;

        service_people
            .addPanelistToPanel(this.state.participant.id, panel_id)
            .then(res => {
                this.getPersonXHR();
                toastManager.add('Panelist successfully added to panel', {
                    appearance: 'success',
                    autoDismiss: true
                });
            })
            .catch(err => {
                const errorText = services.parseAndTrackXhrErrors(err);
                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    onClickSelectPanelDropdownCreatePanel() {
        this.handleOpenModalCreatePanel();
        this.setState({
            showSelectPanelDropdown: false
        });
    }

    onCreatePanel(panel) {
        const { toastManager } = this.props;
        toastManager.add('Successfully created a new panel', {
            appearance: 'success',
            autoDismiss: true
        });
        service_people
            .addPanelistToPanel(this.state.participant.id, panel.id)
            .then(res => {
                this.getPersonXHR();
                toastManager.add('Panelist successfully added to panel', {
                    appearance: 'success',
                    autoDismiss: true
                });
            })
            .catch(err => {
                const errorText = services.parseAndTrackXhrErrors(err);
                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    handleOpenModalCreatePanel() {
        this.setState({ showModalCreatePanel: true });
    }

    handleCloseModalCreatePanel() {
        this.setState({ showModalCreatePanel: false });
    }

    onTagAdd(tagId) {
        const { toastManager } = this.props;

        xhr.post(
            `/tags/${tagId}/people?account_id=${this.props.auth.account.id}`,
            { people_ids: { id: this.state.participant.id } },
            {
                withCredentials: true
            }
        )
            .then(res => {
                this.getPersonXHR();

                this.setState({ showModalAddTag: false });

                toastManager.add('Tag added successfully', {
                    appearance: 'success',
                    autoDismiss: true
                });
            })
            .catch(err => {
                const errorText = services.parseAndTrackXhrErrors(err);
                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    onTagDelete(id) {
        const { toastManager } = this.props;

        xhr.delete(`/people/${this.props.match.params.id}/tags/${id}?account_id=${this.props.auth.account.id}`, {
            withCredentials: true
        })
            .then(res => {
                const tag = {
                    id: res.data.id,
                    title: res.data.title
                };

                this.setState({
                    tags: this.state.tags.filter(t => t.id !== id),
                    tagSuggestions: [tag, ...this.state.tagSuggestions]
                });

                toastManager.add('Tag deleted', {
                    appearance: 'success',
                    autoDismiss: true
                });
            })
            .catch(err => {
                const errorText = services.parseAndTrackXhrErrors(err);
                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    onTagCreate(title) {
        const { toastManager } = this.props;

        let tag = {
            title,
            account_id: this.props.auth.account.id,
            type: 'Person'
        };

        xhr.post(`/tags`, tag, { withCredentials: true })
            .then(response => {
                this.setState({ showModalAddTag: false });

                toastManager.add('Tag created', {
                    appearance: 'success',
                    autoDismiss: true
                });

                tag = response.data;
                this.onTagAdd(tag.id);
            })
            .catch(err => {
                const errorText = services.parseAndTrackXhrErrors(err);
                toastManager.add(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }

    // it's not used
    getFirstPanelSignupAnswers(title) {
        const { toastManager } = this.props;

        xhr.get(`/people/${this.props.match.params.id}/panel-signup-answers?account_id=${this.props.auth.account.id}`, {
            withCredentials: true
        }).then(response => {
            this.setState({ panelSignupAnswers: response.data });
            //console.log(response.data)
        });
    }

    getAllPanelSignupAnswers(title) {
        const { toastManager } = this.props;

        xhr.get(
            `/people/${this.props.match.params.id}/panel-signup-answers-all?account_id=${this.props.auth.account.id}`,
            { withCredentials: true }
        ).then(response => {
            this.setState({ panelSignupAnswers: response.data });
        });
    }

    processDeleteData() {
        const { toastManager } = this.props;
        const account_uuid = this.props.auth.account.uuid;
        const person_uuid = this.state.participant.uuid;

        // eslint-disable-next-line
        if (confirm('Deleting this data is irreversible. Would you like to proceed?')) {
            service_people
                .consentDeleteData(account_uuid, person_uuid)
                .then(() => {
                    this.getPersonXHR();
                    toastManager.add('Panelist successfully deleted', {
                        appearance: 'success',
                        autoDismiss: true
                    });
                })
                .catch(err => {
                    const errorText = services.parseAndTrackXhrErrors(err);
                    toastManager.add(errorText, {
                        appearance: 'error',
                        autoDismiss: true
                    });
                });
        }
    }

    handleOpenModalAddTag() {
        this.setState({ showModalAddTag: true });
    }

    render() {
        const which_studies = <PastParticipation person_id={this.state.participant.id} fetch_person={true} />;

        let breadcrumbs_first_name = '';
        let breadcrumbs_last_name = '';
        if (this.state.participant.custom_data_values2) {
            breadcrumbs_first_name = this.state.participant.custom_data_values2[
                helpers.getPdkId(this.state.participant.custom_data_keys, COL_FIRST_NAME)
            ];
            breadcrumbs_last_name = this.state.participant.custom_data_values2[
                helpers.getPdkId(this.state.participant.custom_data_keys, COL_LAST_NAME)
            ];
        }
        let breadcrumb_element = (
            <>
                {breadcrumbs_first_name} {breadcrumbs_last_name}
                <Box sx={{ marginLeft: '16px', paddingBottom: '2px', gap: '8px', display: 'inline-flex' }}>
                    {helpers.renderBlocklistLabel(this.state.participant)}
                    {helpers.personIsOptedOut(this.state.participant) && <OptOutBadge />}
                </Box>
            </>
        );
        const isPointsEnabled = this.props.auth.account.settings.pv_points === 1;

        return (
            <AppPageWrapper>
                <Helmet>
                    <title>Participant</title>
                </Helmet>

                <AppPageWrapperSectionHeader className="experience experience-border experience-no-subnav section-header-sticky">
                    <Flex justifyContent="space-between" alignItems="center" width="100%">
                        <Box>
                            <BreadcrumbBack to="/panel">Back to panel</BreadcrumbBack>
                            <H1 className="ellipsis">
                                {!this.state.loading && (
                                    <>{this.state.participant.id ? breadcrumb_element : 'Panelist not found'}</>
                                )}
                            </H1>
                        </Box>
                        <Box>
                            {!this.state.loading && <AddToStudyButton participantIds={[this.state.participant.id]} />}
                        </Box>
                    </Flex>
                </AppPageWrapperSectionHeader>

                <AppPageWrapperSectionBody className="section-body-sticky-header-scroll-offset">
                    {/* eslint-disable-next-line */}
                    {this.state.loading ? (
                        <div style={{ width: '100%' }}>
                            <LoadingWrapper>
                                <LoadingIndicator />
                            </LoadingWrapper>
                        </div>
                    ) : !this.state.participant || !this.state.participant.id ? (
                        <Flex py={3} mx={4}>
                            Panelist not found.
                        </Flex>
                    ) : (
                        <Flex py={4} mx={4} flex="1 1 0%" justifyContent="space-between">
                            <Box flex="0 0 auto">
                                <Box
                                    id="eligibility"
                                    ref={this.eligibilityRef}
                                    minWidth="950px"
                                    className="form-wrapper2"
                                >
                                    <Flex className="settings-header">
                                        <Box mb="10px">
                                            <H2>Eligibility</H2>
                                            <Box className="form-section-information" marginBottom="10px">
                                                Status of incentive payments, participation fatigue, and email quality.
                                            </Box>
                                            <Link
                                                to="/settings#panelist-fatigue"
                                                target="_blank"
                                                className="medium"
                                                css={{ fontSize: '1' }}
                                            >
                                                Fatigue Settings <FiExternalLink />
                                            </Link>
                                        </Box>
                                    </Flex>
                                    <Box sx={{ flexGrow: 1, marginBottom: '24px' }}>
                                        <IncentiveProgress
                                            css={{ color: '#000' }}
                                            currency={this.state.participant.account.incentive_currency}
                                            paid={this.state.participant.annual_incentive_amount}
                                            limit={this.state.participant.account.settings.panelist_fatigue_incentive}
                                        />
                                        <FatigueStatusNotification
                                            css={{ marginTop: '16px !important' }}
                                            panelist={this.state.participant}
                                        />
                                        <EmailIssueNotification
                                            panelist={this.state.participant}
                                            css={{ marginTop: '16px !important' }}
                                        />
                                    </Box>
                                </Box>
                                <Box
                                    id="properties"
                                    ref={this.propertiesRef}
                                    minWidth="950px"
                                    className="form-wrapper2 form-wrapper2-border"
                                >
                                    <Flex className="settings-header">
                                        <Box>
                                            <H2>Properties</H2>
                                            <Box className="form-section-information">
                                                Information about a panelist.
                                            </Box>
                                            <Box fontSize={1} sx={{ paddingTop: '10px' }}>
                                                <Link to="/panel-data" target="_blank" className="medium">
                                                    Re-order Properties <FiExternalLink />
                                                </Link>
                                            </Box>
                                        </Box>
                                    </Flex>
                                    <Box sx={{ flexGrow: 1 }}>
                                        <ShowMoreWrapper>
                                            <ParticipantSettings
                                                participant={this.state.participant}
                                                user={this.props.auth.user}
                                                role={this.props.auth.account_role.type}
                                                parentCallback={participant => {
                                                    this.setState({ participant });
                                                }}
                                            />
                                        </ShowMoreWrapper>
                                    </Box>
                                </Box>
                                {isPointsEnabled && (
                                    <Box
                                        id="points"
                                        ref={this.pointsRef}
                                        minWidth="950px"
                                        className="form-wrapper2 form-wrapper2-border"
                                    >
                                        <Flex className="settings-header">
                                            <Box>
                                                <H2>Points</H2>
                                                <Box className="form-section-information">
                                                    Panelist's points history.
                                                </Box>
                                            </Box>
                                        </Flex>
                                        {this.state.pointsData.points != null && (
                                            <Box sx={{ flexGrow: 1 }}>
                                                <Flex mb="16px" alignItems="center" justifyContent="space-between">
                                                    <Badge type="main">
                                                        <FiGift />
                                                        {helpers.numberFormat(this.state.pointsData.points)} Points
                                                    </Badge>
                                                </Flex>
                                                <PointsHistory incentives={this.state.pointsData.incentives} />
                                            </Box>
                                        )}
                                    </Box>
                                )}
                                <Box
                                    id="panels"
                                    ref={this.panelsRef}
                                    minWidth="950px"
                                    className="form-wrapper2 form-wrapper2-border"
                                >
                                    <Flex className="settings-header">
                                        <Box>
                                            <H2>Panels</H2>
                                            <Box className="form-section-information">
                                                Panels this person belongs to.
                                            </Box>
                                        </Box>
                                    </Flex>
                                    <Box sx={{ flexGrow: 1 }}>
                                        <Box>
                                            {this.state.participant.panels &&
                                            this.state.participant.panels.length > 0 ? (
                                                <Box>
                                                    {this.state.participant.panels.map(panel => {
                                                        return (
                                                            <Box className="row-hover row-basic-grid">
                                                                <Flex>
                                                                    <span
                                                                        className="black"
                                                                        style={{
                                                                            flexGrow: 1,
                                                                            marginTop: '6px',
                                                                            display: 'inline-block',
                                                                            verticalAlign: 'top'
                                                                        }}
                                                                    >
                                                                        {panel.title}
                                                                    </span>
                                                                    <Link to={`/panels/${panel.id}`}>
                                                                        <Button
                                                                            ml={2}
                                                                            type="button"
                                                                            variant="secondary-gray"
                                                                            className="secondary-icon"
                                                                        >
                                                                            <FiSettings />
                                                                        </Button>
                                                                    </Link>
                                                                    <Button
                                                                        ml={2}
                                                                        type="button"
                                                                        variant="secondary-gray"
                                                                        className="secondary-icon"
                                                                        onClick={() => this.onDeleteFromPanel(panel.id)}
                                                                    >
                                                                        <FiXCircle />
                                                                    </Button>
                                                                </Flex>
                                                            </Box>
                                                        );
                                                    })}
                                                </Box>
                                            ) : (
                                                <Box className="form-data">
                                                    <Label>This person does not belong to any panels.</Label>
                                                </Box>
                                            )}
                                            <Box sx={{ position: 'relative' }} className="form-row-margin">
                                                <Button
                                                    variant="secondary-gray"
                                                    onClick={() => this.handleOpenSelectPanelDropdown()}
                                                >
                                                    <FiPlus /> Add to panel
                                                </Button>
                                                <PanelDropdown
                                                    showDropdown={this.state.showSelectPanelDropdown}
                                                    width={260}
                                                    height={286}
                                                    items={this.state.panels}
                                                    onChange={this.onAddToPanel}
                                                    onClose={this.handleCloseSelectPanelDropdown}
                                                    onCreatePanel={this.onClickSelectPanelDropdownCreatePanel}
                                                />
                                            </Box>
                                        </Box>
                                    </Box>
                                </Box>
                                <Box
                                    id="tags"
                                    ref={this.tagsRef}
                                    minWidth="950px"
                                    className="form-wrapper2 form-wrapper2-border"
                                >
                                    <Flex className="settings-header">
                                        <Box>
                                            <H2>Tags</H2>
                                            <Box className="form-section-information">
                                                Segments this panelist belongs to.
                                            </Box>
                                        </Box>
                                    </Flex>
                                    <Box sx={{ flexGrow: 1 }}>
                                        <ParticipantTags
                                            tags={this.state.tags}
                                            onTagDelete={this.onTagDelete}
                                            handleOpenModalAddTag={this.handleOpenModalAddTag}
                                        />
                                    </Box>
                                </Box>
                                <Box
                                    id="attached-files"
                                    ref={this.attachedFilesRef}
                                    minWidth="950px"
                                    className="form-wrapper2 form-wrapper2-border"
                                >
                                    <Flex className="settings-header">
                                        <Box>
                                            <H2>Files</H2>
                                            <Box className="form-section-information">
                                                Files are related to the panelist
                                            </Box>
                                        </Box>
                                    </Flex>
                                    <Box sx={{ flexGrow: 1 }}>
                                        <AttachedFilesSection personId={this.state.participant.id} />
                                    </Box>
                                </Box>
                                <Box
                                    id="studies-and-past-participation"
                                    ref={this.studiesRef}
                                    minWidth="950px"
                                    className="form-wrapper2 form-wrapper2-border"
                                >
                                    <Flex className="settings-header">
                                        <Box>
                                            <H2>Past Participation</H2>
                                            <Box className="form-section-information">
                                                Studies this panelist was invited to or participated in.
                                            </Box>
                                        </Box>
                                    </Flex>
                                    <Box sx={{ flexGrow: 1 }}>{which_studies}</Box>
                                </Box>
                                {this.state.panelSignupAnswers.length > 0 && (
                                    <Box
                                        id="sign-up-form-answers"
                                        ref={this.signUpFormAnswersRef}
                                        minWidth="950px"
                                        className="form-wrapper2 form-wrapper2-border"
                                    >
                                        <Flex className="settings-header">
                                            <Box className="form-data">
                                                <H2>Panel Sign-Up Answers</H2>
                                                <Box className="form-section-information">
                                                    Original answers this panelist submitted.
                                                </Box>
                                            </Box>
                                        </Flex>
                                        <Box sx={{ flexGrow: 1 }}>
                                            {this.state.panelSignupAnswers
                                                .filter(psa => psa.panel_form)
                                                .map(({ panel_form, id, created_at }, index, panelForms) => (
                                                    <React.Fragment key={panel_form.id}>
                                                        <Box>
                                                            <Flex alignItems="center" mb="8px">
                                                                <Box flexGrow="1">
                                                                    <Box as="h1" mb="4px" className="fs-title-16">
                                                                        {panel_form.title}
                                                                    </Box>
                                                                    <Box as="p" className="fs-body-12">
                                                                        {panel_form.description || 'No description'}
                                                                    </Box>
                                                                </Box>
                                                                <Link
                                                                    className="muted-light"
                                                                    to={`/participants/${this.participantId}/forms/${panel_form.id}`}
                                                                    target="_blank"
                                                                >
                                                                    <FiExternalLink
                                                                        style={{ fontSize: '16px', margin: 0 }}
                                                                    />
                                                                </Link>
                                                            </Flex>
                                                            {/* <Box as="p" lineHeight="20px" mb="8px" display="inline-block">
                                                            <FiClipboard className="inline-block-middle" />
                                                            <Box as="span" mx="8px" className="inline-block-middle">
                                                                [STUDY_NAME]
                                                            </Box>
                                                            <Link
                                                                className="medium"
                                                                to="/studies/35/participants/23022"
                                                                target="_blank"
                                                            >
                                                                <FiExternalLink
                                                                    style={{ margin: 0 }}
                                                                    className="inline-block-middle"
                                                                />
                                                            </Link>
                                                        </Box> */}
                                                            <Box as="p" lineHeight="16px" className="color-dark">
                                                                <FiPenTool className="inline-block-middle" />
                                                                <Box
                                                                    as="span"
                                                                    ml="8px"
                                                                    className="fs-accent-12 inline-block-middle"
                                                                >
                                                                    {moment(created_at).format(
                                                                        'MMM D, YYYY [at] h:mma'
                                                                    )}
                                                                </Box>
                                                            </Box>
                                                        </Box>
                                                        {index < panelForms.length - 1 && <Box as="hr" my="16px" />}
                                                    </React.Fragment>
                                                ))}
                                        </Box>
                                    </Box>
                                )}
                                <Box
                                    id="delete-panelist"
                                    ref={this.deletePanelistRef}
                                    minWidth="950px"
                                    className="form-wrapper2 form-wrapper2-border"
                                >
                                    <Flex className="settings-header">
                                        <Box>
                                            <H2>Delete Panelist</H2>
                                            <Box className="form-section-information">
                                                Permanently delete this profile, and all of the associated data.
                                            </Box>
                                        </Box>
                                    </Flex>
                                    <Box sx={{ flexGrow: 1 }}>
                                        <Box className="form-data">
                                            <Button type="button" variant="danger" onClick={this.processDeleteData}>
                                                <FiTrash /> Delete panelist
                                            </Button>
                                        </Box>
                                    </Box>
                                </Box>
                                <SelectModal
                                    type="tag"
                                    icon={<FiTag />}
                                    items={this.state.tagSuggestions}
                                    isOpen={this.state.showModalAddTag}
                                    isLoading={false}
                                    onRequestClose={() => this.setState({ showModalAddTag: false })}
                                    onSelect={this.onTagAdd}
                                    onCreate={this.onTagCreate}
                                />
                            </Box>
                            <Box flex="0 0 auto">
                                <SideNav
                                    topOffset={64}
                                    sections={[
                                        {
                                            id: 'eligibility',
                                            ref: this.eligibilityRef,
                                            label: 'Eligibility',
                                            display: true
                                        },
                                        {
                                            id: 'properties',
                                            ref: this.propertiesRef,
                                            label: 'Properties',
                                            display: true
                                        },
                                        {
                                            id: 'points',
                                            ref: this.pointsRef,
                                            label: 'Points',
                                            display: isPointsEnabled
                                        },
                                        {
                                            id: 'panels',
                                            ref: this.panelsRef,
                                            label: 'Panels',
                                            display: true
                                        },
                                        {
                                            id: 'tags',
                                            ref: this.tagsRef,
                                            label: 'Tags',
                                            display: true
                                        },
                                        {
                                            id: 'attached-files',
                                            ref: this.attachedFilesRef,
                                            label: 'Files',
                                            display: true
                                        },
                                        {
                                            id: 'studies-and-past-participation',
                                            ref: this.studiesRef,
                                            label: 'Past Participation',
                                            display: true
                                        },
                                        {
                                            id: 'sign-up-form-answers',
                                            ref: this.signUpFormAnswersRef,
                                            label: 'Panel Sign-Up Answers',
                                            display: this.state.panelSignupAnswers.length > 0
                                        },
                                        {
                                            id: 'delete-panelist',
                                            ref: this.deletePanelistRef,
                                            label: 'Delete Panelist',
                                            display: true
                                        }
                                    ]}
                                />
                            </Box>
                            <NiceModal
                                isOpen={this.state.showModalCreatePanel}
                                shouldCloseOnOverlayClick
                                onRequestClose={this.handleCloseModalCreatePanel}
                                title={`Create new panel`}
                            >
                                <CreatePanel onCreate={this.onCreatePanel} onClose={this.handleCloseModalCreatePanel} />
                            </NiceModal>
                        </Flex>
                    )}
                </AppPageWrapperSectionBody>
            </AppPageWrapper>
        );
    }
}

export default withToastManager(ParticipantPage);
