/**
 * A utility module for working with dates and times using the Moment.js library.
 * This module extends the Moment.js library by adding a custom locale for relative time formatting.
 */
import moment from 'moment-timezone';

// Add a custom locale for ultra-short relative time formatting
moment.defineLocale('ultra-short-relative', {
    relativeTime: {
        future: 'in %s',
        past: '%s',
        s: '%dsec',
        ss: '%dsec',
        m: '1min',
        mm: '%dmin',
        h: '1hr',
        hh: '%dhr',
        d: '1d',
        dd: '%dd',
        w: '1wk',
        ww: '%dwk',
        M: '1mo',
        MM: '%dmo',
        y: '1yr',
        yy: '%dyr'
    }
});

moment.defineLocale('short-relative', {
    relativeTime: {
        future: 'in %s',
        past: '%s ago',
        s: '%d sec',
        ss: '%d sec',
        m: '1 min',
        mm: '%d mins',
        h: '1 hr',
        hh: '%d hrs',
        d: '1 day',
        dd: '%d days',
        w: '1 week',
        ww: '%d weeks',
        M: '1 month',
        MM: '%d months',
        y: '1 year',
        yy: '%d years'
    }
});

moment.locale('en');

export default moment;
