import React, { useState } from 'react';
import { Box, Button } from 'rebass';
import { FiChevronDown } from 'react-icons/fi';
import moment from 'moment';
import helpers from 'utils/helpers';

import { INCENTIVE_ACTION } from 'utils/constants';

/**
 * Minimum number of elements to display. The state before expanding the list.
 */
export const MIN_NUMBER_OF_ELEMENTS = 2;

/**
 * Display the list of points history. Used in the Participant page.
 *
 * @param {Object} props props
 * @param {string} props.className className
 * @param {object[]} props.incentives history of points incentives
 */
export function PointsHistory({ className, incentives }) {
    const [isExpanded, setIsExpanded] = useState(false);
    const collapsedIncentives = incentives.slice(0, isExpanded ? incentives.length : MIN_NUMBER_OF_ELEMENTS);

    /**
     * Expand the history list
     */
    const expandHistory = () => {
        setIsExpanded(true);
    };

    return (
        <Box as="ul" sx={{ listStyle: 'none' }} className={className}>
            {collapsedIncentives.map(incentive => {
                const pointsText = incentive.amount === 1 ? 'point' : 'points';
                const actionText = incentive.action === INCENTIVE_ACTION.ADD ? 'added' : 'subtracted';
                const formattedDate = moment(incentive.created_at)
                    .locale('en')
                    .format('MMM Do, YYYY');

                return (
                    <Box mb="8px" key={incentive.id} className="fs-body-12 color-text-secondary" as="li">
                        {helpers.numberFormat(incentive.amount)} {pointsText} {actionText} on {formattedDate}
                    </Box>
                );
            })}
            {!isExpanded && incentives.length > 2 && (
                <Button height="20px" variant="transparent" onClick={expandHistory} fontSize="12px">
                    More history <FiChevronDown />
                </Button>
            )}
        </Box>
    );
}

export default PointsHistory;
