import React, { useState, useEffect, useMemo, useRef } from 'react';
import { Flex, Box } from 'rebass';
import { Label, Checkbox, Select } from '@rebass/forms';
import PropTypes from 'prop-types';
import moment from 'moment-timezone';
import styled from 'styled-components';
import {
    XYPlot,
    LineSeries,
    RadialChart,
    Hint,
    HorizontalBarSeries,
    VerticalBarSeries,
    LabelSeries,
    YAxis,
    XAxis,
    HorizontalGridLines,
    VerticalGridLines
} from 'react-vis';
import services from 'services/services.js';
import studies_service from 'services/studies.js';
import helpers from 'utils/helpers.js';
import helpersScreener from 'utils/helpers-screener.js';
import { useToasts } from 'react-toast-notifications';
import ListWrapper from 'components/ListWrapper';

DataViz.propTypes = {
    availability: PropTypes.array,
    onFormChange: PropTypes.func
};

function DataViz(props) {
    const [xhrData, setXhrData] = useState({});
    const [isLoading, setIsLoading] = useState(true);
    const didMount = useRef(false);
    const { addToast } = useToasts();
    //const [radialHintValue, setRadialHintValue] = useState(false);

    useEffect(() => {
        xhrGetChartData(props.study_id, props.screener_id);
    }, []);

    function xhrGetChartData(study_id, screener_id) {
        if (study_id && screener_id) {
            studies_service
                .getStudyChartData(study_id, screener_id)
                .then(d => {
                    setXhrData(d);
                    setIsLoading(false);
                })
                .catch(error => {
                    setIsLoading(false);
                    const errorText = services.parseAndTrackXhrErrors(error);

                    addToast(errorText, {
                        appearance: 'error',
                        autoDismiss: true
                    });
                });
        }
    }

    function calculatePercentForPie(data, value) {
        let total = 0;
        data.forEach(obj => {
            total += obj.angle;
        });

        return helpers.numberFormat((value / total) * 100, 2);
    }
    function calculatePercentForBar(data, value) {
        let total = 0;
        data.forEach(obj => {
            total += obj.y;
        });

        return helpers.numberFormat((value / total) * 100, 2);
    }

    function getChartType(question_item) {
        //console.log('question_item.element', question_item.element)
        if (['Checkboxes'].includes(question_item.element)) {
            return 'bar';
        } else if (['Dropdown', 'RadioButtons'].includes(question_item.element)) {
            return 'pie';
        }
    }

    function transformSummaryData(question_response_data) {
        let dataForChart = [];
        const question_item = question_response_data.question;
        const data_summary = question_response_data.summary;
        Object.keys(data_summary).forEach(option_name => {
            let answers_by_form_element_name = {};
            let x_value, y_value;
            if (question_item.element === 'Checkboxes' || question_item.element === 'RadioButtons') {
                answers_by_form_element_name[question_response_data.name] = [option_name];
                x_value = helpersScreener.getItemValueHumanReadable(question_item, answers_by_form_element_name);
                y_value = data_summary[option_name];
            } else if (question_item.element === 'Dropdown') {
                answers_by_form_element_name[question_response_data.name] = option_name;
                x_value = helpersScreener.getItemValueHumanReadable(question_item, answers_by_form_element_name);
                y_value = data_summary[option_name];
            }

            x_value = Array.isArray(x_value) ? x_value[0] : x_value;

            if (getChartType(question_item) == 'pie') {
                dataForChart.push({
                    label: x_value,
                    angle: y_value
                });
            } else if (getChartType(question_item) == 'bar') {
                dataForChart.push({
                    x: x_value,
                    y: y_value
                });
            }
        });
        // update with percentages
        dataForChart.forEach(item => {
            switch (getChartType(question_item)) {
                case 'pie':
                    item.percentage = calculatePercentForPie(dataForChart, item.angle);
                    break;
                case 'bar':
                    item.percentage = calculatePercentForBar(dataForChart, item.y);
                    break;
            }
        });
        return dataForChart;
    }

    /*const rawData = [
      {angle: 60, label: 'Female'},
      {angle: 40, label: 'Male'}
    ];
    let chartData = [];
    rawData.forEach(obj => {
        chartData.push({
            angle: obj.angle,
            label: `${obj.label} ${calculatePercent(rawData, obj.angle)}`
        })
    });*/

    const greenData = [
        { x: 'A', y: 10 },
        { x: 'B', y: 5 },
        { x: 'C', y: 15 }
    ];
    const labelData = greenData.map((d, idx) => ({
        x: d.x,
        y: greenData[idx].y
    }));

    function renderGraphs() {
        let elements = [];
        if (xhrData.length) {
            xhrData.forEach(question_response_data => {
                let transformed_data = transformSummaryData(question_response_data);
                //console.log('transformed_data', transformed_data.chartType);
                //console.log('getChartType(question_response_data.question)' ,getChartType(question_response_data.question))
                elements.push(
                    <Box fontSize={1}>
                        <Box>{question_response_data.question.label}</Box>
                        {/*<Box>{question_response_data.question.element}</Box>
                        <Box>{getChartType(question_response_data.question)}</Box>
                        <Box>{JSON.stringify(transformed_data)}</Box>*/}
                        {getChartType(question_response_data.question) == 'pie' ? (
                            <RadialChart
                                height={300}
                                width={300}
                                labelsRadiusMultiplier={0.8}
                                labelsStyle={{ fontSize: 13, fill: '#222' }}
                                data={transformed_data}
                                showLabels
                            ></RadialChart>
                        ) : (
                            <XYPlot xType="ordinal" width={300} height={300}>
                                <XAxis />
                                <YAxis />

                                <VerticalBarSeries data={transformed_data} colorType="literal" />
                            </XYPlot>
                        )}
                        <ListWrapper
                            style={{
                                position: 'relative',
                                border: '1px solid #eee',
                                borderRadius: '4px'
                            }}
                        >
                            <table className="table-main">
                                <thead>
                                    <tr>
                                        <th>Value</th>
                                        <th>Percent</th>
                                        <th>Count</th>
                                    </tr>
                                </thead>
                                <tbody>
                                    {transformed_data.map(item => {
                                        return (
                                            <tr>
                                                <td>{item.label ? item.label : item.x}</td>
                                                <td>{item.percentage}%</td>
                                                <td>{item.angle ? item.angle : item.y}</td>
                                            </tr>
                                        );
                                    })}
                                </tbody>
                            </table>
                        </ListWrapper>
                        <br />
                        <hr />
                        <br />
                    </Box>
                );
            });
        } else {
            elements.push('No data');
        }
        return elements;
    }

    return <Flex sx={{ flexDirection: 'column' }}>{isLoading ? 'Loading...' : renderGraphs()}</Flex>;
}

export default DataViz;
