import React from 'react';
import { Link } from 'react-router-dom';
import { Box, Flex, Button } from 'rebass';
import { FiAlertCircle, FiSidebar } from 'react-icons/fi';
import ReactTooltip from 'react-tooltip';
import ParticipantFormattedValue from './ParticipantFormattedValue';

class ParticipantItem extends React.Component {
    constructor(props) {
        super(props);

        this.renderHorizontal = this.renderHorizontal.bind(this);
        this.renderVertical = this.renderVertical.bind(this);
    }

    renderHorizontal(customDataColumns) {
        return customDataColumns;

        /*return (
            <Flex flexWrap="wrap" style={{ position: 'relative', width: '100%' }}>
                {customDataColumns}
            </Flex>
        );*/
    }

    renderVertical() {
        alert('fix stuff in here');
        return (
            <div>
                <div>
                    <Link to={`/participants/${this.props.participant.id}`}>
                        {this.props.participant.first_name} {this.props.participant.last_name}
                    </Link>
                </div>
                <div>{this.props.participant.email}</div>
                <div>{this.props.participant.phone_number}</div>
            </div>
        );
    }

    render() {
        const customDataColumns = [];
        const that = this;

        const chx = (
            <input
                type="checkbox"
                key={`checkbox-${this.props.participant.id}`}
                data-personid={this.props.participant.id}
                checked={this.props.checkbox_checked[this.props.participant.id]}
                onChange={this.props.onChange}
                name="person_id"
                style={{
                    position: 'absolute',
                    // This allows the checkbox to be centered vertically regardless of the height of the row.
                    // It's important to keep top and transform together.
                    top: '50%',
                    transform: 'translateY(-50%)',
                    left: '0',
                    cursor: 'pointer'
                }}
                onClick={e => {
                    e.stopPropagation();
                }}
                disabled={['all', 'custom_count'].includes(this.props.checkAllOrVisibleStatus)}
            />
        );
        // console.log(this.props.participant.custom_data_keys, this.props.availableColumns_showing);
        //if (this.props.participant.custom_data_keys && this.props.participant.custom_data_keys.length > 0) {

        let tooltip_info = [];
        if (this.props.participant.panelist_eligibility) {
            this.props.participant.panelist_eligibility.forEach(pe => {
                tooltip_info.push(pe);
            });
        }
        let tooltip_element;
        if (tooltip_info.length) {
            tooltip_element = (
                <span>
                    <FiAlertCircle
                        data-tip
                        data-for={`notif-${this.props.participant.id}`}
                        style={{ margin: '5px 8px 0 0' }}
                        className={`va-top red`}
                    />
                    <ReactTooltip
                        id={`notif-${this.props.participant.id}`}
                        effect="solid"
                        place="right"
                        className="react-tooltip-hover-stay"
                        delayHide={500}
                    >
                        <ul className="tight">
                            {tooltip_info.map(ti => (
                                <li>{ti}</li>
                            ))}
                        </ul>
                    </ReactTooltip>
                </span>
            );
        }

        if (this.props.availableColumns_showing) {
            const firstNamePanelPropertyIndex = this.props.availableColumns_showing.findIndex(
                panelProperty => panelProperty.title === 'First Name'
            );
            const lastNamePanelPropertyIndex = this.props.availableColumns_showing.findIndex(
                panelProperty => panelProperty.title === 'Last Name'
            );

            customDataColumns.push(
                <td
                    key={`cdc-${that.props.participant.id}-fullname`}
                    style={{ position: 'sticky', left: 0, zIndex: 2, maxWidth: 0, minWidth: '260px' }}
                    className="sticky-row-style td-row-hover-parent ellipsis"
                >
                    {chx}
                    {tooltip_element}
                    <Box as="span" sx={{ marginLeft: 2 }} className="table-link">
                        {firstNamePanelPropertyIndex >= 0 && (
                            <ParticipantFormattedValue
                                className="strong"
                                user={this.props.auth.user}
                                role={this.props.auth.account_role.type}
                                participantId={that.props.participant.id}
                                col={this.props.availableColumns_showing[firstNamePanelPropertyIndex]}
                                value={
                                    this.props.participant.custom_data_values2[
                                        this.props.availableColumns_showing[firstNamePanelPropertyIndex].id
                                    ]
                                }
                                colIndex={firstNamePanelPropertyIndex}
                            />
                        )}{' '}
                        {lastNamePanelPropertyIndex >= 0 && (
                            <ParticipantFormattedValue
                                className="strong"
                                user={this.props.auth.user}
                                role={this.props.auth.account_role.type}
                                participantId={that.props.participant.id}
                                col={this.props.availableColumns_showing[lastNamePanelPropertyIndex]}
                                value={
                                    this.props.participant.custom_data_values2[
                                        this.props.availableColumns_showing[lastNamePanelPropertyIndex].id
                                    ]
                                }
                                colIndex={lastNamePanelPropertyIndex}
                            />
                        )}
                    </Box>
                    <Button
                        type="button"
                        variant="secondary"
                        className="secondary-tiny td-row-hover-child"
                        sx={{
                            position: 'absolute',
                            right: 0,
                            // This allows the button to be centered vertically regardless of the height of the row.
                            top: '50%',
                            transform: 'translateY(-50%)'
                        }}
                        onClick={e => {
                            e.preventDefault();
                            e.stopPropagation();
                            this.props.onClickPreviewPanelist(this.props.participant);
                        }}
                    >
                        <FiSidebar /> View
                    </Button>
                </td>
            );
        }

        this.props.availableColumns_showing.forEach((col_showing, index) => {
            //console.log('col_showing', col_showing)
            //const col = this.props.participant.custom_data_keys.find(p_cdk => col_showing.id == p_cdk.id);
            const col = col_showing;
            //console.log('col', col)
            //if (!col || !col_showing.checked) {
            if (!col || !col_showing.checked) {
                // skip rendering this column
                return;
            }

            //console.log(this.props.participant.custom_data_values2, col.id)
            const foundValue = this.props.participant.custom_data_values2[col.id];

            customDataColumns.push(
                <td key={`cdc-${that.props.participant.id}-${col.id}`} className="ellipsis">
                    <ParticipantFormattedValue
                        user={this.props.auth.user}
                        role={this.props.auth.account_role.type}
                        participantId={that.props.participant.id}
                        col={col}
                        value={foundValue}
                        colIndex={index}
                    />
                </td>
            );
        });
        //}

        if (this.props.horizontal) {
            return this.renderHorizontal(customDataColumns);
        }
        return this.renderVertical(customDataColumns);
    }
}

export default ParticipantItem;
