import React, { useEffect, useState } from 'react';
import H1 from 'components/H1';
import styled from 'styled-components';
import TextAreaAutosize from 'react-textarea-autosize';
import ReactModal from 'react-modal';
import ReactModalActions from 'components/ReactModalActions';
import { Box, Button } from 'rebass';
import { Label } from '@rebass/forms';
import NiceModal from 'components/NiceModal';

export default props => {
    const [textAreaValue, setTextAreaValue] = useState(props.value);

    React.useEffect(() => {
        setTextAreaValue(props.value);
    }, [props.value]);

    let inputProps = {};

    return (
        <NiceModal isOpen={props.isOpen} onRequestClose={props.onRequestClose} title={props.title}>
            <form
                onSubmit={e => {
                    e.preventDefault();
                    props.onSubmit(textAreaValue);
                    props.onRequestClose();
                }}
            >
                <Box>
                    {props.aboveTextArea ? <Box mb={3}>{props.aboveTextArea}</Box> : null}

                    <Label>{props.textAreaLabel ? props.textAreaLabel : 'Body'}</Label>
                    <TextAreaAutosize
                        className="theme-input"
                        value={textAreaValue}
                        onChange={e => {
                            setTextAreaValue(e.target.value);
                        }}
                        minRows={4}
                        autoFocus
                        {...inputProps}
                    ></TextAreaAutosize>
                </Box>
                <Box className="modal-actions">
                    <Button type="button" variant="secondary-gray" mr={3} onClick={props.onRequestClose}>
                        Cancel
                    </Button>

                    <Button type="submit" variant="primary" mr={0}>
                        Save
                    </Button>
                </Box>
            </form>
        </NiceModal>
    );
};
