import React, { useState, useEffect } from 'react';
import { Helmet } from 'react-helmet';
import { useToasts } from 'react-toast-notifications';
import H1 from 'components/H1';
import { Flex, Box, Button, Heading } from 'rebass';
import { Label } from '@rebass/forms';
import services from 'services/services';
import helpers from 'utils/helpers';
import { Link } from 'react-router-dom';
import moment from 'moment-timezone';

import NiceWrapper from 'components/NiceWrapper';
import AppPageWrapper from 'components/AppPageWrapper';
import AppPageWrapperSectionHeader from 'components/AppPageWrapper/AppPageWrapperSectionHeader';
import AppPageWrapperSectionBody from 'components/AppPageWrapper/AppPageWrapperSectionBody';
import ListWrapper from 'components/ListWrapper';
import ItemWrapper from 'components/ItemWrapper';

function DashboardPage() {
    // array of StudyPeople
    const [sessions, setSessions] = useState([]);
    const { addToast } = useToasts();

    useEffect(() => {
        getSessionsXHR();
    }, []);

    function renderSessions(when) {
        const render = [];
        let location = '';
        sessions.forEach(studyPerson => {
            if (studyPerson.spot) {
                if (
                    (when == 'future' && moment.utc(studyPerson.spot).isAfter()) ||
                    (when == 'past' && moment.utc(studyPerson.spot).isBefore())
                ) {
                    location = helpers.getStudyPersonLocation(studyPerson, studyPerson.study);
                    render.push(
                        <ItemWrapper style={{ color: 'black' }} key={studyPerson.id}>
                            <Box width={1 / 4}>
                                <Link className="muted medium" to={helpers.getStudyPersonLink(studyPerson)}>
                                    {helpers.personGetFullName(studyPerson.person)}
                                </Link>
                            </Box>
                            <Box width={1 / 4}>
                                <Link to={`/studies/${studyPerson.study.id}`}>{studyPerson.study.title}</Link>
                            </Box>
                            <Box width={1 / 4}>{moment.utc(studyPerson.spot).fromNow()}</Box>
                            <Box width={1 / 4}>
                                {helpers.isValidUrl(location) ? (
                                    <a href={location} target="_blank">
                                        <Button variant="primary">Join</Button>
                                    </a>
                                ) : (
                                    <span>{location}</span>
                                )}
                            </Box>
                        </ItemWrapper>
                    );
                }
            }
        });

        if (!render.length) {
            render.push(<ItemWrapper style={{ border: 0 }}>0 sessions</ItemWrapper>);
        }
        return render;
    }

    function getSessionsXHR() {
        services
            .getSessions()
            .then(sessions_xhr => {
                setSessions(sessions_xhr);
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);

                addToast(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    }
    return (
        <Flex style={{ width: '100%' }}>
            <AppPageWrapper>
                <Helmet>
                    <title>Dashboard</title>
                </Helmet>

                <AppPageWrapperSectionHeader>
                    <H1>Dashboard</H1>
                </AppPageWrapperSectionHeader>
                <AppPageWrapperSectionBody>
                    <Flex mx={4} my={3} width="100%" sx={{ gridGap: 4, flexDirection: 'column' }}>
                        <Box width={1}>
                            <NiceWrapper padding="0px">
                                <Heading m={3} color="black">
                                    Your Upcoming Sessions
                                </Heading>
                                <ListWrapper
                                    style={{
                                        position: 'relative'
                                    }}
                                >
                                    <ItemWrapper className="header-row">
                                        <Box width={1 / 4}>Participant</Box>
                                        <Box width={1 / 4}>Study</Box>
                                        <Box width={1 / 4}>When</Box>
                                        <Box width={1 / 4}>Location</Box>
                                    </ItemWrapper>
                                    {renderSessions('future')}
                                </ListWrapper>
                            </NiceWrapper>
                        </Box>
                        <Box width={1}>
                            <ListWrapper
                                className="border"
                                style={{
                                    position: 'relative'
                                }}
                            >
                                <Label className="loud" m={3}>
                                    Your Past Sessions
                                </Label>
                                <ItemWrapper className="header-row">
                                    <Box width={1 / 4}>Participant</Box>
                                    <Box width={1 / 4}>Study</Box>
                                    <Box width={1 / 4}>When</Box>
                                    <Box width={1 / 4}>Location</Box>
                                </ItemWrapper>
                                {renderSessions('past')}
                            </ListWrapper>
                        </Box>
                    </Flex>
                </AppPageWrapperSectionBody>
            </AppPageWrapper>
        </Flex>
    );
}

export default DashboardPage;
