import styled from 'styled-components';

export default styled.div`
    display: flex;
    //overflow-x: auto;
    width: 100%;
    margin: 0;
    padding: 0;
    flex: 1 1 0%;
    box-sizing: border-box;
    position: relative;
`;
