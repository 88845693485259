import React, { useMemo, useState } from 'react';
import { UploadDnD } from 'components/UploadDnD';
import { Box, Button, Flex } from 'rebass';
import { FiEdit, FiTrash2, FiChevronRight } from 'react-icons/fi';
import { Link } from 'react-router-dom';

import services from 'services/services';
import useMutation from 'hooks/useMutation';
import useQuery from 'hooks/useQuery';
import { AttachedFileStrip } from 'components/AttachedFileStrip';
import { LoadingAttachedFileStrip } from 'components/AttachedFileStrip/Loading';
import { DownloadAttachedFileButton } from 'components/DownloadAttachedFileButton';
import helpers from 'utils/helpers';

import { EditFileModal } from './components/EditFileModal';

const ACCEPTED_FILE_TYPES = [];

export function AttachedFilesSection({ personId, studyId }) {
    const [editingFileId, setEditingFileId] = useState(null);
    const [progress, setProgress] = useState(0);
    const [uploadingFiles, setUploadingFiles] = useState([]);
    const { data: attachedFiles, refetch: refetchPersonalFiles } = useQuery({
        queryFn: async variables => services.getPersonFiles(variables.personId, variables.studyId),
        variables: {
            personId,
            studyId
        },
        onSuccess: () => {
            setUploadingFiles([]);
            setProgress(0);
        }
    });
    const { mutate: uploadFiles } = useMutation({
        mutationFn: async files => {
            setUploadingFiles(files);
            const formData = new FormData();

            for (const file of files) {
                formData.append('files[]', file);
            }

            return services.uploadPersonFiles(personId, formData, studyId, {
                onUploadProgress: progress => setProgress(progress)
            });
        },
        onSuccess: refetchPersonalFiles
    });
    const { mutate: deleteFile, isLoading: isDeletionLoading } = useMutation({
        mutationFn: async fileId => {
            return services.deletePersonFile(personId, fileId);
        },
        onSuccess: refetchPersonalFiles
    });
    const editingFile = useMemo(() => {
        if (!attachedFiles) return null;
        return attachedFiles.find(file => file.id === editingFileId);
    }, [editingFileId, attachedFiles]);

    const onDelete = async attachedFileId => {
        const confirmed = confirm('Are you sure you want to delete this file?');

        if (confirmed) {
            await deleteFile(attachedFileId);
        }
    };

    return (
        <>
            {studyId && (
                <Flex alignItems="center" mb="16px" height="32px">
                    <Box fontSize="16px" fontWeight={600} as="p">
                        Study-Related Files
                    </Box>

                    <Box flexGrow={1} />
                    <Link target="_blank" to={`/participants/${personId}#attached-files`}>
                        <Button padding="0px 16px" height="32px" variant="secondary-gray">
                            View all files <FiChevronRight />
                        </Button>
                    </Link>
                </Flex>
            )}
            <UploadDnD accept={ACCEPTED_FILE_TYPES} css={{ marginBottom: '16px' }} onDrop={uploadFiles} />
            <div>
                {attachedFiles &&
                    attachedFiles.map(attachedFile => (
                        <AttachedFileStrip
                            key={attachedFile.id}
                            fileExtension={helpers.extractFileExtension(attachedFile.title)}
                            description={attachedFile.description}
                            title={attachedFile.title}
                            updatedAt={attachedFile.updated_at}
                            isLoading={isDeletionLoading}
                            study={attachedFile.study}
                            extra={
                                <>
                                    <DownloadAttachedFileButton fileId={attachedFile.id} personId={personId} />
                                    <Button
                                        type="button"
                                        variant="secondary-gray"
                                        className="secondary-icon"
                                        onClick={() => setEditingFileId(attachedFile.id)}
                                    >
                                        <FiEdit />
                                    </Button>
                                    <Button
                                        type="button"
                                        variant="secondary-gray"
                                        className="secondary-icon"
                                        onClick={() => onDelete(attachedFile.id)}
                                    >
                                        <FiTrash2 />
                                    </Button>
                                </>
                            }
                        />
                    ))}
                {uploadingFiles.map((file, index) => (
                    <LoadingAttachedFileStrip
                        key={file.name + file.lastModified + index}
                        fileExtension={helpers.extractFileExtension(file.name)}
                        progress={progress}
                        title={file.name}
                    />
                ))}
            </div>
            {editingFile && (
                <EditFileModal
                    isOpen={true}
                    attachedFile={editingFile}
                    onClose={() => setEditingFileId(null)}
                    personId={personId}
                    onUpdate={refetchPersonalFiles}
                />
            )}
        </>
    );
}

export default AttachedFilesSection;
