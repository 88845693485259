import React from 'react';
import { Helmet } from 'react-helmet';
import { Redirect } from 'react-router-dom';
import styled from 'styled-components';
import LoadingWrapper from 'components/LoadingIndicator/LoadingWrapper';
import LoadingIndicator from 'components/LoadingIndicator';
import Header from 'components/Header';
import helpers from 'utils/helpers';
import { Box } from 'rebass';
const AppWrapper = styled.div`
    display: flex;
    position: relative;
    z-index: 0;
    flex-direction: column;
    //overflow: hidden;
    height: 100vh;
`;

export default function(ComposedComponent, auth, routeAccess) {
    class RequireAuth extends React.Component {
        constructor(props) {
            super(props);

            this.onKeyUpDown = this.onKeyUpDown.bind(this);

            this.state = {
                map: {}
            };
        }

        onKeyUpDown(e) {
            const newMap = this.state.map;

            // eslint-disable-next-line
            e = e || event; // to deal with IE
            newMap[e.keyCode] = e.type == 'keydown';
            console.log(newMap);
            /* insert conditional here */
            if (newMap[91] && newMap[75]) {
                // COMMAND + K
                this.setState({ map: {} });
                this.props.history.push('/search'); // or whatever
            } else {
                this.setState({
                    map: newMap
                });
            }
        }

        componentDidMount() {
            // document.addEventListener("keydown", this.onKeyUpDown, false);
            // document.addEventListener("keyup", this.onKeyUpDown, false);
        }

        componentWillMount() {
            this.unlisten = this.props.history.listen((location, action) => {
                //console.log("on route change", location, action);

                try {
                    if (location && location.pathname) {
                        window.Beacon('event', {
                            type: 'page-viewed',
                            url: document.location.href,
                            title: document.title
                        });
                        window.Beacon('suggest');
                    }
                } catch (e) {
                    helpers.trackError(e);
                }
            });
        }

        componentWillUnmount() {
            // document.removeEventListener("keydown", this.onKeyUpDown, false);
            // document.removeEventListener("keyup", this.onKeyUpDown, false);
            this.unlisten();
        }

        // Otherwise render the original component
        render() {
            // console.log('routeName', routeAccess.title, this.props.match);

            if (!auth.isFetchingAuth) {
                if (!auth.isAuthenticated) {
                    return <Redirect to="/login" />;
                }

                if (!auth.user.allow_access && window.location.pathname != '/trial') {
                    return <Redirect to="/trial" />;
                }

                if (!auth.user.email_verified_at && window.location.pathname != '/trial') {
                    return <Redirect to="/trial" />;
                }
            }

            return (
                <>
                    <Helmet>
                        <meta name="viewport" content="width=1200" />
                    </Helmet>

                    {auth.isFetchingAuth ? (
                        <AppWrapper>
                            <div style={{ width: '100%' }}>
                                <br />
                                <LoadingWrapper>
                                    <LoadingIndicator />
                                </LoadingWrapper>
                            </div>
                        </AppWrapper>
                    ) : (
                        <AppWrapper>
                            {!routeAccess.withoutHeader ? (
                                <Header auth={auth} routeName={routeAccess.title} routeMatch={this.props.match} />
                            ) : null}
                            {helpers.doesUserHaveRouteAccess(auth, routeAccess.role) ? (
                                <>
                                    {helpers.isTrialExpired(auth.account) == true && (
                                        <div className="app-blocked-overlay"></div>
                                    )}
                                    <ComposedComponent {...this.props} auth={auth} />
                                </>
                            ) : (
                                <Box m={4}>You do not have access to this page.</Box>
                            )}
                        </AppWrapper>
                    )}
                </>
            );
        }
    }

    return RequireAuth;
}
