import styled from 'styled-components';
import React from 'react';
import { Link } from 'react-router-dom';
import TimeAgo from 'react-timeago';
import { Box } from 'rebass';
import { Label, Input, Textarea, Radio, Checkbox } from '@rebass/forms';

import StudyTldr from 'components/Study/StudyTldr';
import NiceWrapper from 'components/NiceWrapper';
import myxss from 'components/ReactFormBuilder/form-elements/myxss';

class SurveyView extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            survey: {
                intro:
                    '<h1>Introduction</h1>' +
                    '<p>Thank you for your interest in participating in this research study application. All responses are completely confidential and will never be associated with your name or personal details.</p>' +
                    '<p>If you qualify for the study, you will be contacted to schedule a <strong>45-minute video interview for a $30 incentive.</strong></p>' +
                    '<p>The survey should take approximately <u>5 minutes to complete</u>.</p>' +
                    '<p>Thank you again for taking the time to apply for this study!</p>' +
                    '<p>--</p>' +
                    '<p>If you have any questions, please contact the director of this research project:</p>' +
                    '<p>Casie Mattrisch, casie@tetrainsights.com, (347) 354-2969.</p>' +
                    "<p><em>Full terms and conditions are available <a href=''>here</a>.</em></p>",
                thankyou: 'Thank you for participating',
                disqualify: 'You have been disqualified',
                questions: [
                    {
                        type: 'text',
                        question: 'What time do you wake up?'
                    },
                    {
                        type: 'textarea',
                        question: 'What is your morning routine?'
                    },
                    {
                        type: 'checkbox',
                        question: 'Which languages do you speak?',
                        answers: ['English', 'Spanish', 'French', 'Russian'],
                        qualify: [],
                        disqualify: []
                    },
                    {
                        type: 'radio',
                        question: 'What is your gender?',
                        answers: ['Male', 'Female', 'Choose not to identify'],
                        qualify: ['Male'],
                        disqualify: ['Female']
                    }
                ],
                pages: []
            }
        };

        this.renderSurvey = this.renderSurvey.bind(this);
    }

    renderQuestion(q) {
        let answers = [];
        let isDisqualifier = false;
        let isQualifier = false;
        switch (q.type) {
            case 'text':
                return (
                    <div>
                        {q.question}
                        <br />
                        <Input type="text" />
                    </div>
                );
                break;
            case 'textarea':
                return (
                    <div>
                        {q.question}
                        <br />
                        <Textarea />
                    </div>
                );
                break;
            case 'checkbox':
                q.answers.forEach(a => {
                    isQualifier = q.qualify.find(q => {
                        if (a == q) {
                            return q;
                        }
                    })
                        ? true
                        : false;
                    isDisqualifier = q.disqualify.find(dq => {
                        if (a == dq) {
                            return dq;
                        }
                    })
                        ? true
                        : false;

                    answers.push(
                        <div>
                            <Label>
                                <Checkbox name={q.question} />
                                {a} {isQualifier ? 'qualifier' : ''} {isDisqualifier ? 'disqualifier' : ''}
                            </Label>
                        </div>
                    );
                });
                return (
                    <div>
                        {q.question}
                        <br />
                        {answers}
                    </div>
                );
                break;
            case 'radio':
                q.answers.forEach(a => {
                    isQualifier = q.qualify.find(q => {
                        if (a == q) {
                            return q;
                        }
                    })
                        ? true
                        : false;
                    isDisqualifier = q.disqualify.find(dq => {
                        if (a == dq) {
                            return dq;
                        }
                    })
                        ? true
                        : false;

                    answers.push(
                        <div>
                            <Label>
                                <Radio name={q.question} />
                                {a} {isQualifier ? 'qualifier' : ''} {isDisqualifier ? 'disqualifier' : ''}
                            </Label>
                        </div>
                    );
                });
                return (
                    <div>
                        {q.question}
                        <br />
                        {answers}
                    </div>
                );
                break;
        }
    }

    renderSurvey() {
        let output = [];
        output.push(<div dangerouslySetInnerHTML={{ __html: myxss.process(this.state.survey.intro) }}></div>);

        output.push(<h1>Survey</h1>);

        this.state.survey.questions.forEach(q => {
            output.push(this.renderQuestion(q));
        });

        output.push(<div>{this.state.survey.thankyou}</div>);
        output.push(<div>{this.state.survey.disqualify}</div>);

        return output;
    }

    render() {
        return <Box mt={3}>{this.renderSurvey()}</Box>;
    }
}

export default SurveyView;
