import React, { useContext } from 'react';
import { Box } from 'rebass';
import classNames from 'classnames';

import { HeaderSubnavButton } from 'components/Header/HeaderSubnavButton';

/**
 * Context object for managing the state of the tabs.
 * @type {React.Context<{ value: any, onChange: (value) => void }>}
 */
export const TabContext = React.createContext();

/**
 * Provider component for the Tabs context.
 *
 * @param {Object} props - The component props.
 * @param {string} props.value - The value of the active tab.
 * @param {ReactNode} props.children - The child components.
 * @param {function} props.onChange - The function to handle tab change.
 */
export const TabsProvider = ({ value, children, onChange }) => {
    return (
        <TabContext.Provider
            value={{
                value,
                onChange
            }}
        >
            {children}
        </TabContext.Provider>
    );
};

/**
 * Hook for accessing the Tabs context.
 * @throws {Error} Throws an error if used outside of a TabsProvider.
 */
export const useTabs = () => {
    const context = useContext(TabContext);

    if (!context) throw new Error('useTabs must be used within a TabProvider');

    return context;
};

/**
 * Component for rendering a container for tab components.
 *
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The child components.
 */
export function Tabs({ children }) {
    return <Box className="tabs">{children}</Box>;
}

/**
 * Component for rendering a tab panel.
 *
 * @param {Object} props - The component props.
 * @param {string} props.value - The value of the tab panel.
 * @param {ReactNode} props.children - The child components.
 * @param {string} props.className - The CSS class name for the tab panel.
 * @param {Object} props.style - The inline CSS styles for the tab panel.
 */
export function TabPanel({ value, children, className, style }) {
    const { value: activeValue } = useTabs();

    if (activeValue !== value) return null;

    return (
        <Box className={className} style={style}>
            {children}
        </Box>
    );
}

/**
 * Component for rendering a tab.
 *
 * @param {Object} props - The component props.
 * @param {ReactNode} props.children - The content of the tab.
 * @param {string} props.value - The value of the tab.
 */
export function Tab({ children, value }) {
    const { value: activeValue, onChange } = useTabs();

    return (
        <HeaderSubnavButton
            className={classNames('basic-subnav items-center', activeValue === value && 'nav-item-active')}
            onClick={() => onChange(value)}
        >
            {children}
        </HeaderSubnavButton>
    );
}

export default Tabs;
