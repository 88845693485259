import React, { useCallback, useEffect } from 'react';

export const MIN_COL_WIDTH = 140;

/**
 * Wrapper component for a table that adds a resizer to each column
 *
 * @param {Object} props
 * @param {ReactNode} props.children The table component
 * @param {HTMLElement} props.tableNode The table node
 * @param {number?} props.minColWidth The minimum column width
 * @param {Array?} props.dependencies The dependencies to watch for changes
 */
export function TableResizer({ children, tableNode, minColWidth = MIN_COL_WIDTH, dependencies = [] }) {
    /**
     * Creates a resizable column
     */
    const createResizableColumn = useCallback((col, resizer) => {
        // Track the current position of mouse
        let x = 0;
        let w = 0;

        const mouseDownHandler = e => {
            resizer.classList.add('resizing');
            // Get the current mouse position
            x = e.clientX;

            // Calculate the current width of column
            const styles = window.getComputedStyle(col);
            w = parseInt(styles.width, 10);

            // Attach listeners for document's events
            document.addEventListener('mousemove', mouseMoveHandler);
            document.addEventListener('mouseup', mouseUpHandler);
        };

        const mouseMoveHandler = function(e) {
            // Determine how far the mouse has been moved
            const dx = e.clientX - x;

            // Update the width of column
            const newWidth = w + dx;
            if (newWidth >= minColWidth) {
                col.style.minWidth = `${newWidth}px`;
                col.style.maxWidth = `${newWidth}px`;
            }
        };

        // When user releases the mouse, remove the existing event listeners
        const mouseUpHandler = function() {
            resizer.classList.remove('resizing');
            document.removeEventListener('mousemove', mouseMoveHandler);
            document.removeEventListener('mouseup', mouseUpHandler);
        };

        resizer.addEventListener('mousedown', mouseDownHandler);
    }, []);

    /**
     * Adds a resizer to each column and sets the min width of each column
     */
    useEffect(() => {
        if (tableNode) {
            // Query all headers
            const ths = tableNode.querySelectorAll('th');
            const tds = tableNode.querySelectorAll('td');

            // Set the max width of all tds on initial load
            tds.forEach(td => {
                td.style.maxWidth = `${minColWidth}px`;
            });

            // Loop over them
            ths.forEach(th => {
                // skip if there is already a resizer
                if (th.querySelectorAll('.resizer').length > 0) {
                    return;
                }

                // Create a resizer element
                const resizer = document.createElement('div');
                resizer.classList.add('resizer');

                // Create a vertical line element
                const vl = document.createElement('div');
                vl.classList.add('resizer-vl');
                resizer.appendChild(vl);

                // Set the height
                resizer.style.height = `44px`;

                // Set the min width of column
                th.style.minWidth = `${minColWidth}px`;

                // Add a resizer element to the column
                th.appendChild(resizer);

                // Will be implemented in the next section
                createResizableColumn(th, resizer);
            });
        }
    }, [tableNode, ...dependencies]);

    return <>{children}</>;
}

export default TableResizer;
