import React, { useMemo } from 'react';
import classNames from 'classnames';
import { FiAlertTriangle } from 'react-icons/fi';

import NotificationCard from 'components/NotificationCard';
import helpers from 'utils/helpers';

/**
 * Render fatigue status notification. If panelist is not eligible,
 * render notification otherwise render nothing.
 *
 * @param {Object} props
 * @param {Object} props.panelist panelist
 * @param {string | undefined} props.className className
 */
export function FatigueStatusNotification({ className, panelist }) {
    const content = useMemo(() => helpers.getPanelistEligibilityText(panelist), [panelist]);

    // If panelist is eligible, render nothing
    if (!content) return null;

    return (
        <NotificationCard
            className={classNames('color-dark', className)}
            type="warn"
            icon={<FiAlertTriangle />}
            title="Fatigue Status"
            content={content}
        />
    );
}

export default FatigueStatusNotification;
