import React from 'react';
import { HeaderSubnavLink } from 'components/Header/HeaderSubnavLink';
import { Flex, Box } from 'rebass';
import { SmallLink } from 'components/Header/SmallLink';
import CanShow from 'components/AppPermissions/CanShow';
import H1 from 'components/H1';
import { FiSettings, FiUsers, FiZap, FiMail, FiBarChart2, FiTag, FiCode, FiFileText } from 'react-icons/fi';
import helpers from 'utils/helpers';
import auth from 'services/auth';
import { PAID_FEATURE } from 'utils/constants';

function SettingsPageSubNav({ sx, ...props }) {
    const authObject = auth.getAuthState();
    return (
        <Flex
            px={4}
            sx={{ flexDirection: 'row', borderBottom: '1px solid #e9ecef', width: '100%', margin: '8px 0 24px', ...sx }}
            {...props}
        >
            <HeaderSubnavLink exact to={`/settings`} className="basic-subnav">
                <FiSettings /> General
            </HeaderSubnavLink>
            <HeaderSubnavLink to={`/settings/users`} className="basic-subnav">
                <FiUsers /> Users
            </HeaderSubnavLink>
            <HeaderSubnavLink to={`/settings/forms`} className="basic-subnav">
                <FiFileText /> Forms
            </HeaderSubnavLink>
            {/*<CanShow role="user">*/}
            <HeaderSubnavLink to={`/settings/integrations`} className="basic-subnav">
                <FiZap /> Integrations
            </HeaderSubnavLink>
            {/*</CanShow>*/}
            <HeaderSubnavLink to={`/settings/email-templates`} className="basic-subnav">
                <FiMail /> Email Templates
            </HeaderSubnavLink>
            <HeaderSubnavLink to={`/settings/tags`} className="basic-subnav">
                <FiTag /> Tags
            </HeaderSubnavLink>
            <HeaderSubnavLink to={`/settings/usage`} className="basic-subnav">
                <FiBarChart2 style={{ fontSize: '15px' }} /> Usage
            </HeaderSubnavLink>
            {helpers.hasFeatureEnabled(authObject.account, PAID_FEATURE.API_ACCESS) && (
                // eslint-disable-next-line jsx-a11y/aria-role
                <CanShow role={'admin'}>
                    <HeaderSubnavLink to={`/settings/developer`} className="basic-subnav">
                        <FiCode /> Developer
                    </HeaderSubnavLink>
                </CanShow>
            )}
        </Flex>
    );
}

export default SettingsPageSubNav;
