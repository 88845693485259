import React from 'react';
import { Button } from 'rebass';
import { FiDownload } from 'react-icons/fi';
import { useToasts } from 'react-toast-notifications';

import services from 'services/services';

export function DownloadAttachedFileButton({ personId, fileId }) {
    const { addToast } = useToasts();

    const downloadFile = async () => {
        try {
            const url = await services.getPersonFileUrl(personId, fileId);
            window.open(url, '_blank');
        } catch (error) {
            const errorText = services.parseAndTrackXhrErrors(error) || error.message;
            addToast(errorText, {
                appearance: 'error',
                autoDismiss: true
            });
        }
    };

    return (
        <Button onClick={downloadFile} type="button" variant="secondary-gray" className="secondary-icon">
            <FiDownload />
        </Button>
    );
}

export default DownloadAttachedFileButton;
