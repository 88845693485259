import React from 'react';
import { FiChevronDown } from 'react-icons/fi';

export const DropdownArrow = () => (
    <FiChevronDown
        style={{
            margin: '4px 0 0 8px',
            strokeWidth: 1.5
        }}
    />
);

/**
 * Title component
 *
 * @param {Object} props props

 * @param {boolean} props.zeroPanelsAvailable zeroPanelsAvailable
 * @param {boolean} props.hasAdminPrivileges hasAdminPrivileges
 * @param {array} props.panels list of panels
 * @param {number} props.selectedId id of selected panel
 * @param {boolean} props.isDashboard isDashboard
 */
function PanelDropdownTitle({ zeroPanelsAvailable, hasAdminPrivileges, panels, selectedId, isDashboard }) {
    const allPanelists = 'All panelists';
    const notFound = 'Panel not found';

    if (zeroPanelsAvailable) {
        return <>0 panels available</>;
    }

    // Ussually we aspect that the selectedId is always present besides the situation where selectedId is an empty string
    if (selectedId) {
        const panel = panels.find(panel => panel.id === selectedId);

        if (panel) {
            return (
                <>
                    {panel.title} {isDashboard ? 'Dashboard' : ''}
                    <DropdownArrow />
                </>
            );
        }
    }

    // As a fallback, show "All panelists" if the user has admin privileges
    if (hasAdminPrivileges)
        return (
            <>
                {allPanelists}
                <DropdownArrow />
            </>
        );

    // If the user doesn't have admin privileges, show "Panel not found" as a fallback
    return (
        <>
            {notFound}
            <DropdownArrow />
        </>
    );
}

export default PanelDropdownTitle;
