import React, { useMemo, useState } from 'react';
import { Box, Flex } from 'rebass';
import { useToasts } from 'react-toast-notifications';
import { Link } from 'react-router-dom';
import classNames from 'classnames';

import useQuery from 'hooks/useQuery';
import SideOverlay from 'components/SideOverlay';
import services from 'services/services';
import service_studies from 'services/studies';
import helpers from 'utils/helpers';
import H1 from 'components/H1';
import { TabsProvider, Tabs, TabPanel, Tab } from 'components/Tabs';
import moment from 'utils/moment';
import { ACTIVITY_TYPE, SESSION_HISTORY_STATUS, MOMENT_HUMAN_DATE } from 'utils/constants';
import ItemWrapper from 'components/ItemWrapper';
import CounterBadge from 'components/CounterBadge';

/**
 * Generate a message for a session activity.
 *
 * @param {Object} sessionActivity
 * @param {string} sessionActivity.status - The status of the session activity.
 */
export const generateSessionActivityMessage = sessionActivity => {
    switch (sessionActivity.status) {
        case SESSION_HISTORY_STATUS.STATUS_SCHEDULED:
        case SESSION_HISTORY_STATUS.STATUS_RESCHEDULED:
            return `${moment
                .utc(sessionActivity.new_session_date)
                .tz(sessionActivity.study_timezone)
                .format(MOMENT_HUMAN_DATE)}`;
        case SESSION_HISTORY_STATUS.STATUS_CANCELLED:
        case SESSION_HISTORY_STATUS.STATUS_NOSHOW:
        default:
            return '';
    }
};

/**
 * Generate a title for a session activity.
 *
 * @param {Object} sessionActivity
 * @param {string} sessionActivity.status - The status of the session activity.
 * @param {Object} sessionActivity.person_fullname - The person who did the activity.
 */
export const generateSessionActivityTitle = sessionActivity => {
    const fullname = sessionActivity.person_fullname;

    switch (sessionActivity.status) {
        case SESSION_HISTORY_STATUS.STATUS_SCHEDULED:
            return (
                <>
                    <b>{fullname}</b> was scheduled for {generateSessionActivityMessage(sessionActivity)}
                </>
            );
        case SESSION_HISTORY_STATUS.STATUS_CANCELLED:
            return (
                <>
                    <b>{fullname}</b> was cancelled
                </>
            );
        case SESSION_HISTORY_STATUS.STATUS_NOSHOW:
            return (
                <>
                    <b>{fullname}</b> was marked as no-show
                </>
            );
        case SESSION_HISTORY_STATUS.STATUS_RESCHEDULED:
            return (
                <>
                    <b>{fullname}</b> was rescheduled to {generateSessionActivityMessage(sessionActivity)}
                </>
            );
        default:
            return 'Unknown session activity';
    }
};

/**
 * Transform an SMS activity.
 *
 * @param {Object} sms
 * @param {string} sms.created_at - The date the SMS was created.
 * @param {string} sms.message - The message of the SMS.
 * @param {Object} sms.person - The person who sent the SMS.
 * @param {string} sms.study_person_id - The study person ID.
 */
export const transformSmsActivity = sms => ({
    createdAt: sms.created_at,
    message: sms.message,
    person: sms.person,
    studyPersonId: sms.study_person_id,
    title: (
        <>
            <b>{helpers.personGetFullName(sms.person)}</b> replied via SMS:
        </>
    ),
    type: ACTIVITY_TYPE.SMS
});

/**
 * Transform an email activity.
 *
 * @param {Object} email
 * @param {string} email.date_received - The date the email was received.
 * @param {string} email.text_body - The body of the email.
 * @param {Object} email.person - The person who sent the email.
 * @param {string} email.study_person_id - The study person ID.
 */
export const transformEmailActivity = email => ({
    createdAt: email.date_received,
    message: email.text_body,
    person: email.person,
    studyPersonId: email.study_person_id,
    title: (
        <>
            <b>{helpers.personGetFullName(email.person)}</b> replied via E-mail:
        </>
    ),
    type: ACTIVITY_TYPE.EMAIL
});

/**
 * Transform a session activity to the regular format.
 *
 * @param {Obejct} sessionActivity
 * @param {string} sessionActivity.created_at - The date the session activity was created.
 * @param {Object} sessionActivity.person_fullname - The full name of the person who did the activity.
 * @param {string} sessionActivity.study_person_id - The study person ID.
 * @param {string} sessionActivity.status - The status of the session activity.
 * @param {Date?} sessionActivity.new_session_date - The new session date.
 */
export const transformSessionActivity = sessionActivity => ({
    createdAt: sessionActivity.created_at,
    personFullname: sessionActivity.person_fullname,
    studyPersonId: sessionActivity.study_person_id,
    type: ACTIVITY_TYPE.SESSION,
    //message: generateSessionActivityMessage(sessionActivity),
    title: generateSessionActivityTitle(sessionActivity)
});

/**
 * Shows when there are no activities in the drawer.
 */
export const ActivityEmptyState = () => (
    <Box px={4} py={3}>
        No activity yet.
    </Box>
);

/**
 * Display an activity item (log record).
 *
 * @param {Object} props
 * @param {function} props.onClick - The function to handle the click event.
 * @param {string} props.createdAt - The date the activity was created.
 * @param {string} props.studyId - The study ID.
 * @param {string} props.studyPersonId - The study person ID.
 * @param {React.ReactNode} props.title - The title of the activity.
 * @param {string} props.message - The message of the activity.
 */
export function ActivityItem({ onClick, createdAt, studyId, studyPersonId, title, message }) {
    return (
        <Link
            to={`/studies/${studyId}/participants/${studyPersonId}`}
            onClick={event => {
                if (onClick) {
                    event.preventDefault();
                    onClick({ id: studyPersonId });
                }
            }}
        >
            <ItemWrapper className="border-bottom-gray">
                <Flex sx={{ justifyContent: 'space-between', alignItems: 'start', width: '100%' }}>
                    <Box className="color-text-primary" sx={{ flexGrow: 1 }}>
                        <p style={{ margin: 0 }} className="fs-body-14">
                            {title}
                        </p>
                        {message && (
                            <p style={{ margin: 0, height: '20px' }} className="ellipsis fs-body-14">
                                {message}
                            </p>
                        )}
                    </Box>
                    <Box sx={{ minWidth: 'auto' }} className="fs-12 nowrap">
                        {moment(createdAt)
                            .locale('ultra-short-relative')
                            .fromNow()}
                    </Box>
                </Flex>
            </ItemWrapper>
        </Link>
    );
}

function StudyActivityDrawer({ studyId, onClickParticipant }) {
    const { addToast } = useToasts();
    const [tab, setTab] = useState('all');
    const { data: activity, isLoading } = useQuery({
        queryFn: () => service_studies.getStudyActivity(studyId),
        onError: error => {
            const errorText = services.parseAndTrackXhrErrors(error);
            addToast(errorText, {
                appearance: 'error',
                autoDismiss: true
            });
        }
    });
    const messageActivities = useMemo(() => {
        if (!activity) return [];

        const activities = [];

        if (activity.email_received) {
            activities.push(...activity.email_received.map(transformEmailActivity));
        }

        if (activity.sms_received) {
            activities.push(...activity.sms_received.map(transformSmsActivity));
        }

        return activities.sort(helpers.sortByCreatedAt);
    }, [activity]);
    const sessionsActivities = useMemo(() => {
        if (!activity) return [];

        const activities = [];

        if (activity.session_activities) {
            activities.push(...activity.session_activities.map(transformSessionActivity));
        }

        return activities.sort(helpers.sortByCreatedAt);
    }, [activity]);
    const allActivities = useMemo(() => {
        if (!activity) return [];

        const activities = [...messageActivities, ...sessionsActivities];

        return activities.sort(helpers.sortByCreatedAt);
    }, [activity]);

    if (isLoading)
        return (
            <Box px={4} py={3}>
                Loading...
            </Box>
        );

    return (
        <TabsProvider value={tab} onChange={newTab => setTab(newTab)}>
            <Tabs>
                <Tab value="all">All</Tab>
                <Tab value="messages">
                    Messages{' '}
                    <CounterBadge
                        style={{ marginLeft: '6px' }}
                        count={messageActivities.length}
                        isActive={tab === 'messages'}
                    />
                </Tab>
                <Tab value="scheduling">
                    Scheduling{' '}
                    <CounterBadge
                        style={{ marginLeft: '6px' }}
                        count={sessionsActivities.length}
                        isActive={tab === 'scheduling'}
                    />
                </Tab>
            </Tabs>
            <TabPanel value="all">
                {allActivities.length === 0 && <ActivityEmptyState />}
                {allActivities.map(activity => (
                    <ActivityItem
                        key={activity.createdAt}
                        onClick={onClickParticipant}
                        createdAt={activity.createdAt}
                        studyId={studyId}
                        studyPersonId={activity.studyPersonId}
                        title={activity.title}
                        message={activity.message}
                    />
                ))}
            </TabPanel>
            <TabPanel value="messages">
                {messageActivities.length === 0 && <ActivityEmptyState />}
                {messageActivities.map(activity => (
                    <ActivityItem
                        key={activity.createdAt}
                        onClick={onClickParticipant}
                        createdAt={activity.createdAt}
                        studyId={studyId}
                        studyPersonId={activity.studyPersonId}
                        title={activity.title}
                        message={activity.message}
                    />
                ))}
            </TabPanel>
            <TabPanel value="scheduling">
                {sessionsActivities.length === 0 && <ActivityEmptyState />}
                {sessionsActivities.map(activity => (
                    <ActivityItem
                        key={activity.createdAt}
                        onClick={onClickParticipant}
                        createdAt={activity.createdAt}
                        studyId={studyId}
                        studyPersonId={activity.studyPersonId}
                        title={activity.title}
                        message={activity.message}
                    />
                ))}
            </TabPanel>
        </TabsProvider>
    );
}

const withStudyActivityLayout = Component => props => {
    return (
        <SideOverlay onClose={props.onClose}>
            <Box px={4} py={3}>
                <H1 style={{ marginBottom: '0' }}>Activity</H1>
            </Box>
            <Component {...props} />
        </SideOverlay>
    );
};

export default withStudyActivityLayout(StudyActivityDrawer);
