import React, { useEffect, useState, useMemo } from 'react';
import { useToasts } from 'react-toast-notifications';
import { FiX, FiPlus, FiLink } from 'react-icons/fi';
import { Box, Button, Flex } from 'rebass';

import CreatePanel from 'components/Participant/CreatePanel';
import services from 'services/services';
import peopleServices from 'services/people';
import service_integrations from 'services/integrations';
import PanelSelect from 'components/PanelSelect';
import { CreatePersonDataKey } from 'components/PersonDataKey';
import PropertySelect from 'containers/SettingsPage/Modals/SalesforceMapper/PropertySelect';
import NiceModal from 'components/NiceModal';
import LoadingIndicator from 'components/LoadingIndicator';
import LoadingWrapper from 'components/LoadingIndicator/LoadingWrapper';
import SalesforceDirection from 'components/SalesforceDirection';
import {
    INTEGRATION_SALESFORCE,
    INTEGRATION_DIRECTION_IN,
    INTEGRATION_DIRECTION_OUT,
    INTEGRATION_DIRECTION_BOTH,
    COL_EMAIL,
    ALLOWED_SALESFORCE_MAPPERS
} from 'utils/constants';
import useMutation from 'hooks/useMutation';
import useQuery from 'hooks/useQuery';
import DropdownSelect from 'components/DropdownSelect';
import ListWrapper from 'components/ListWrapper';
import ItemWrapper from 'components/ItemWrapper';
import ErrorLabel from 'components/ErrorLabel';

const SalesforceMapper = ({ isOpen, onRequestClose, defaultValue, onSave, onNewPanel }) => {
    const [integrationMap, setIntegrationMap] = useState([]);
    const [panelProperties, setPanelProperties] = useState([]);
    const [isCreatePanelModal, setIsCreatePanelModal] = useState(false);
    const [salesforceProperties, setSalesforceProperties] = useState([]);
    const [isLoading, setIsLoading] = useState(true);
    const [isSaving, setIsSaving] = useState(false);
    const { addToast } = useToasts();
    const [salesforceListviewId, setSalesforceListviewId] = useState(() => {
        if (!defaultValue) return null;

        return defaultValue.listviewId ? defaultValue.listviewId : null;
    });
    const [panelId, setPanelId] = useState(() => {
        if (!defaultValue) return null;

        return defaultValue.panelId ? defaultValue.panelId : null;
    });
    const { data: panels, refetch: refetchPanels } = useQuery({
        queryFn: () => peopleServices.getPanels()
    });
    const { data: saleforceListviews } = useQuery({
        queryFn: () => service_integrations.getSaleforceListviews()
    });
    const { mutate: mutateMapSalesforceToPanelfox } = useMutation({
        mutationFn: ({ map, panelId, salesforceListviewId }) =>
            service_integrations.mapSalesforceToPanelfox({ map }, { panelId, salesforceListviewId })
    });
    const saleforceListviewsItems = useMemo(() => {
        if (!saleforceListviews) return [];

        return saleforceListviews.map(listview => ({
            id: listview.id,
            title: listview.label
        }));
    }, [saleforceListviews]);
    const [propertyMapperIndex, setPropertyMapperIndex] = useState(null); // the index could be 0, so we need to check for null

    const alreadyMappedPanelProperties = new Set(integrationMap.map(item => item.panelProp && item.panelProp.id));
    const panelPropertiesItems = panelProperties
        .filter(pp => !alreadyMappedPanelProperties.has(pp.id))
        .map(pp => {
            return { id: pp.id, title: `${pp.title} (${pp.type})`, type: pp.type };
        });

    const alreadyMappedSalesforceProperties = new Set(
        integrationMap.map(item => item.salesforceProp && item.salesforceProp.name)
    );
    const salesforcePropertiesItems = salesforceProperties
        .filter(sp => !alreadyMappedSalesforceProperties.has(sp.name))
        .map(sp => {
            return { id: sp.name, title: `${sp.label} (${sp.type})` };
        });

    const mappingErrors = useMemo(() => {
        return integrationMap
            .filter(mapper => mapper.salesforceProp && mapper.panelProp)
            .filter(mapper => !ALLOWED_SALESFORCE_MAPPERS[mapper.salesforceProp.type].includes(mapper.panelProp.type))
            .map((mapper, index) => ({
                id: index,
                saleforceLabel: mapper.salesforceProp.label,
                panelLabel: mapper.panelProp.title,
                message: `Unsupported mapping: ${mapper.salesforceProp.label} - ${mapper.panelProp.title}`
            }));
    }, [integrationMap]);

    const directionList = [
        {
            id: INTEGRATION_DIRECTION_IN,
            title: <SalesforceDirection direction={INTEGRATION_DIRECTION_IN} />
        },
        {
            id: INTEGRATION_DIRECTION_OUT,
            title: <SalesforceDirection direction={INTEGRATION_DIRECTION_OUT} />
        },
        {
            id: INTEGRATION_DIRECTION_BOTH,
            title: <SalesforceDirection direction={INTEGRATION_DIRECTION_BOTH} />
        }
    ];

    const getAvailableColumnsXHR = () => {
        services
            .getCustomDataColumnsXHR(true)
            .then(properties => {
                setPanelProperties(properties);
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);
                addToast(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    };

    /**
     * Callback function when a new panel property is created
     *
     * @param {Object} panelProperty new created panel property
     */
    const onPropertyCreate = panelProperty => {
        // udpate panelProperties
        getAvailableColumnsXHR();

        // copy map
        const map = [...integrationMap];

        // set missing integrations field
        panelProperty.integrations = [];

        // update map
        map[propertyMapperIndex].panelProp = panelProperty;
        setIntegrationMap(map);

        // update direction and close modal
        updateDirection(propertyMapperIndex);
        setPropertyMapperIndex(null);
    };

    const getSalesforcePropertiesXHR = () => {
        service_integrations
            .getSalesforceProperties()
            .then(properties => {
                setSalesforceProperties(properties);
            })
            .catch(error => {
                const errorText = services.parseAndTrackXhrErrors(error);
                addToast(errorText, {
                    appearance: 'error',
                    autoDismiss: true
                });
            });
    };

    const saveMapXHR = async () => {
        setIsSaving(true);

        const map = {};
        for (let i = 0; i < integrationMap.length; i++) {
            const item = integrationMap[i];
            if (item.salesforceProp === null || item.panelProp === null || item.direction === null) {
                addToast('Fill empty fields before saving', {
                    appearance: 'error',
                    autoDismiss: true
                });
                setIsSaving(false);
                return;
            }
            map[item.salesforceProp.name] = {
                id: item.panelProp.id,
                direction: item.direction.id
            };
        }

        await mutateMapSalesforceToPanelfox(
            { map, panelId, salesforceListviewId },
            {
                onSuccess: () => {
                    onRequestClose();
                    getAvailableColumnsXHR();
                    addToast('Successfully updated Salesforce integration configuration', {
                        appearance: 'success',
                        autoDismiss: true
                    });
                    onSave && onSave();
                },
                onError: error => {
                    const errorText = services.parseAndTrackXhrErrors(error);
                    addToast(errorText, {
                        appearance: 'error',
                        autoDismiss: true
                    });
                }
            }
        );

        setIsSaving(false);
    };

    const cancelMap = () => {
        getAvailableColumnsXHR();
        onRequestClose();
    };

    const addRow = () => {
        const map = [...integrationMap];
        map.push({
            panelProp: null,
            salesforceProp: null,
            direction: null
        });
        setIntegrationMap(map);
    };

    const deleteRow = index => {
        const map = [...integrationMap];
        map.splice(index, 1);
        setIntegrationMap(map);
    };

    const onSalesforcePropertySelect = (name, index) => {
        const map = [...integrationMap];
        map[index].salesforceProp = salesforceProperties.find(sp => sp.name === name);
        setIntegrationMap(map);
    };

    const onPanelPropertySelect = (id, index) => {
        const map = [...integrationMap];
        map[index].panelProp = panelProperties.find(pp => pp.id === id);
        setIntegrationMap(map);
        updateDirection(index);
    };

    const onDirectionSelect = (id, index) => {
        const map = [...integrationMap];
        map[index].direction = directionList.find(d => d.id === id);
        setIntegrationMap(map);
    };

    const updateDirection = index => {
        // Force INTEGRATION_DIRECTION_IN for COL_EMAIL
        if (
            integrationMap[index].panelProp &&
            integrationMap[index].panelProp.title == COL_EMAIL &&
            integrationMap[index].direction &&
            integrationMap[index].direction.id != INTEGRATION_DIRECTION_IN
        ) {
            const map = [...integrationMap];
            map[index].direction = directionList.find(d => d.id === INTEGRATION_DIRECTION_IN);
            setIntegrationMap(map);
        }
    };

    const getCurrentMapping = () => {
        const map = [];

        const salesforcePropsMap = salesforceProperties.reduce((acc, sp) => {
            acc[sp.name] = sp;
            return acc;
        }, {});

        panelProperties.forEach(pp => {
            const integration = pp.integrations.find(integration => integration.external === INTEGRATION_SALESFORCE);
            if (integration) {
                const salesforceProp = salesforcePropsMap[integration.external_id];
                if (salesforceProp) {
                    map.push({
                        panelProp: pp,
                        salesforceProp: salesforceProp,
                        direction: directionList.find(d => d.id == integration.direction)
                    });
                }
            }
        });

        return map;
    };

    useEffect(() => {
        getAvailableColumnsXHR();
        getSalesforcePropertiesXHR();
    }, []);

    useEffect(() => {
        // Run only once when both panelProperties and salesforceProperties are loaded
        if (panelProperties.length && salesforceProperties.length && isLoading) {
            setIntegrationMap(getCurrentMapping());
            setIsLoading(false);
        }
    }, [panelProperties, salesforceProperties]);

    return (
        <NiceModal
            isOpen={isOpen}
            onRequestClose={onRequestClose}
            style={{ content: { width: '1000px', height: 'auto' } }}
            title="Sync configuration"
        >
            {isLoading ? (
                <LoadingWrapper style={{ height: '100px' }}>
                    <LoadingIndicator />
                </LoadingWrapper>
            ) : (
                <>
                    <Flex sx={{ gap: '24px' }} mb="24px" alignItems="flex-end">
                        <Box flex="1">
                            <Box mb="4px" as="label" className="fs-accent-14">
                                Target panel
                            </Box>
                            {panels && (
                                <PanelSelect
                                    width="100%"
                                    positionTop={28}
                                    positionLeft={0}
                                    items={panels}
                                    value={panelId}
                                    onChange={panelId => setPanelId(panelId)}
                                    onCreatePanel={() => setIsCreatePanelModal(true)}
                                />
                            )}
                        </Box>
                        <Box flex="1">
                            <Box mb="4px" as="label" className="fs-accent-14">
                                Salesforce list
                            </Box>
                            <DropdownSelect
                                items={saleforceListviewsItems}
                                onChange={listviewId => setSalesforceListviewId(listviewId)}
                                value={saleforceListviewsItems.find(listview => listview.id === salesforceListviewId)}
                            />
                        </Box>
                        <Box>
                            <a href="https://docs.panelfox.io/article/126-salesforce-integration" target="_blank">
                                <Button type="button" variant="secondary-gray">
                                    <FiLink style={{ fontSize: '15px', margin: '4px 5.5px 0 0' }} />
                                    Integration Documentation
                                </Button>
                            </a>
                        </Box>
                    </Flex>

                    <Box as="p" className="fs-title-14" mb="8px">
                        Property mapping
                    </Box>
                    <ListWrapper
                        style={{
                            position: 'relative',
                            border: '1px solid #eee',
                            borderRadius: '4px'
                        }}
                    >
                        <ItemWrapper className="header-row">
                            <Box width={4 / 16}>Salesforce field</Box>
                            <Box width={4 / 16}>Panelfox property</Box>
                            <Box width={6 / 16}>Direction</Box>
                            <Box width={2 / 16}></Box>
                        </ItemWrapper>
                        <Box>
                            {integrationMap.map((item, index) => {
                                return (
                                    <ItemWrapper key={index}>
                                        <Box width={4 / 16}>
                                            <PropertySelect
                                                items={salesforcePropertiesItems}
                                                selectedItemLabel={
                                                    item.salesforceProp !== null ? item.salesforceProp.label : null
                                                }
                                                onSelect={name => onSalesforcePropertySelect(name, index)}
                                            />
                                        </Box>
                                        <Box width={4 / 16}>
                                            <PropertySelect
                                                items={panelPropertiesItems.map(panelProperty => ({
                                                    ...panelProperty,
                                                    disabled:
                                                        !!item.salesforceProp &&
                                                        !ALLOWED_SALESFORCE_MAPPERS[item.salesforceProp.type].includes(
                                                            panelProperty.type
                                                        )
                                                }))}
                                                selectedItemLabel={
                                                    item.panelProp !== null ? item.panelProp.title : null
                                                }
                                                onSelect={id => onPanelPropertySelect(id, index)}
                                                renderBottomStickyButton={
                                                    <Button
                                                        type="button"
                                                        variant="primary-transparent"
                                                        onClick={() => setPropertyMapperIndex(index)}
                                                    >
                                                        <FiPlus /> Create Panelist Property
                                                    </Button>
                                                }
                                            />
                                        </Box>
                                        <Box width={6 / 16}>
                                            <PropertySelect
                                                items={
                                                    item.panelProp && item.panelProp.title === COL_EMAIL
                                                        ? directionList.filter(d => d.id === INTEGRATION_DIRECTION_IN)
                                                        : directionList
                                                }
                                                selectedItemLabel={
                                                    item.direction !== null ? item.direction.title : null
                                                }
                                                isStatusIcon={false}
                                                onSelect={id => onDirectionSelect(id, index)}
                                                showSearch={false}
                                            />
                                        </Box>
                                        <Box width={2 / 16}>
                                            <Flex style={{ justifyContent: 'flex-end' }}>
                                                <Button
                                                    type="button"
                                                    variant="transparent"
                                                    onClick={() => deleteRow(index)}
                                                >
                                                    <Flex>
                                                        <FiX
                                                            style={{
                                                                color: '#D42220',
                                                                fontSize: '15px',
                                                                margin: '4px 7px 0 0'
                                                            }}
                                                        />
                                                        <Box style={{ color: '#D42220' }}>Remove</Box>
                                                    </Flex>
                                                </Button>
                                            </Flex>
                                        </Box>
                                    </ItemWrapper>
                                );
                            })}
                        </Box>
                    </ListWrapper>
                    <Flex mt={2} sx={{ gap: 2 }} flexWrap="wrap">
                        {mappingErrors.map(error => (
                            <ErrorLabel key={error.id}>{error.message}</ErrorLabel>
                        ))}
                    </Flex>
                    <Flex
                        margin="24px -32px -32px -32px"
                        padding="32px"
                        justifyContent="space-between"
                        sx={{ borderTop: '1px solid #e0e4e7' }}
                    >
                        <Flex justifyContent="flex-start">
                            <Button type="button" variant="secondary" onClick={addRow}>
                                <FiPlus style={{ fontSize: '15px', margin: '4px 5.5px 0 0' }} />
                                Add Property Mapping
                            </Button>
                        </Flex>
                        <Flex style={{ justifyContent: 'flex-end' }}>
                            <Button type="button" variant="secondary-gray" mr="16px" onClick={cancelMap}>
                                Cancel
                            </Button>
                            <Button type="submit" variant="primary" mr={0} onClick={saveMapXHR} disabled={isSaving}>
                                Save configuration
                            </Button>
                        </Flex>
                    </Flex>
                </>
            )}
            {isCreatePanelModal && (
                <NiceModal
                    isOpen
                    shouldCloseOnOverlayClick
                    onRequestClose={() => setIsCreatePanelModal(false)}
                    title="Create new panel"
                >
                    <CreatePanel
                        onCreate={panel => {
                            addToast('Successfully created a new panel', {
                                appearance: 'success',
                                autoDismiss: true
                            });
                            setPanelId(panel.id);
                            onNewPanel && onNewPanel();
                        }}
                        onClose={() => {
                            refetchPanels();
                            setIsCreatePanelModal(false);
                        }}
                    />
                </NiceModal>
            )}
            <NiceModal
                isOpen={propertyMapperIndex !== null}
                onRequestClose={() => setPropertyMapperIndex(null)}
                title="Add a panelist property"
            >
                <CreatePersonDataKey onSuccess={onPropertyCreate} onClose={() => setPropertyMapperIndex(null)} />
            </NiceModal>
        </NiceModal>
    );
};

export default SalesforceMapper;
